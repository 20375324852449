import { Button, Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX, GRID_DEVICES_MIN } from '../../../../../../utils';

export const Styles = styled.div`
    padding-bottom: 20px;

    @media (${GRID_DEVICES_MAX.md}) {
        text-align: center;
        margin-bottom: 12px;
    }

    @media (${GRID_DEVICES_MIN.md}) {
        text-align: left;
    }
`;

export const SuiteButton = styled(Button)`
    && {
        @media (${GRID_DEVICES_MAX.md}) {
            width: 100%;
        }

        @media (${GRID_DEVICES_MIN.md}) {
            width: auto;
        }

        .anticon {
            margin-left: 8px;
        }
    }
`;

export const StyledHeadline1 = styled(Typography.Title)`
    margin: 12px 0;
`;

export const StyledHeadline2 = styled(Typography.Title)`
    margin-bottom: 6px;
`;

export const InfoButton = styled(Button)`
    width: 100%;
`;
