import React from 'react';
import { ROUTE_PATHS } from '../../../../../shared';
import Order from '../components/Order';
import { OrdersContainer } from '../components/OrdersContainer';

export const ROUTES = [
    {
        path: ROUTE_PATHS.orders,
        index: true,
        element: <OrdersContainer />
    },
    {
        path: ROUTE_PATHS.order,
        element: <Order />
    }
];
