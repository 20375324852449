import { Icon } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledWrap = styled.div`
    display: flex;
    align-items: center;
    margin: 40px 0;
    width: 100%;
`;

export const StyledIcon = styled(Icon)`
    margin: 0 100px;
`;

export const StyledLine = styled.div`
    width: 100%;
    height: ${({ $lineWeight }) => $lineWeight};
    background-color: ${({ $color }) => $color};
`;
