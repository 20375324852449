import React from 'react';
import { CloseXIcon } from '@klickly/front-packages';
import { DialogsCLoseIcon, Header, Styles } from './styles';

export const DialogHeader = ({ onClose, simple, title, subTitle }) => {
    return (
        <Styles>
            {!simple ? (
                <DialogsCLoseIcon size='24px' color='canvas' onClick={onClose} component={() => <CloseXIcon />} />
            ) : null}

            <Header
                title={title}
                subTitle={subTitle}
                color='primary'
                backgroundColor='primaryBackground'
                titleOnTop
                simple={false}
                withoutBorders
                textAlign={simple ? 'left' : 'center'}
                onBack={simple ? onClose : null}
            />
        </Styles>
    );
};
