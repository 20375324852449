import React from 'react';
import { BackArrowIcon, BOTTOM_INDENTS, TOP_INDENTS, Typography } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { $primaryText, ROUTE_PATHS } from '../../../../shared';
import { AuthHeader } from '../AuthHeader';
import { NavigationIcon } from '../NavigationIcon';
import { ResetPasswordForm } from './components/ResetPasswordForm';
import { ResetPasswordContentStyles, Styles } from './styles';

export const ResetPassword = observer(() => {
    return (
        <Styles>
            <AuthHeader />
            <ResetPasswordContentStyles>
                <NavigationIcon size='24px' color={$primaryText} to={ROUTE_PATHS.forgotPassword}>
                    <BackArrowIcon />
                </NavigationIcon>
                <Typography.Title style={{ ...BOTTOM_INDENTS.SM, ...TOP_INDENTS.XL }} data-e2e='auth_checkInbox-text'>
                    Check your inbox
                </Typography.Title>
                <Typography.Text style={BOTTOM_INDENTS.XL} type='body1' color='primaryText'>
                    We’ve sent a password recovery link to your email
                </Typography.Text>
                <ResetPasswordForm />
            </ResetPasswordContentStyles>
        </Styles>
    );
});

export default ResetPassword;
