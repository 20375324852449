import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, Icon, InfoIcon, TOP_INDENTS } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../shared';
import { AlertInfo } from '../../../../shared/components/AlertInfo';

const RewardsSideBar = inject('store')(
    observer(({ store, title, text }) => {
        const navigate = useNavigate();
        const { isLoggedIn, signUpStatusRejected } = store.accountStore;
        const handleClick = () => navigate(ROUTE_PATHS.rewardsInfo);

        if (!isLoggedIn || (isLoggedIn && signUpStatusRejected)) {
            return <AlertInfo showIcon onClick={handleClick} style={TOP_INDENTS.M} icon={<InfoIcon />} />;
        }
        return (
            <AlertInfo
                text={
                    <Fragment>
                        <div style={BOTTOM_INDENTS.S}>
                            <Icon size='24px' component={() => <InfoIcon />} />
                        </div>
                        <div style={BOTTOM_INDENTS.S}>{title}</div>
                        {text}
                    </Fragment>
                }
                afterIcon={null}
                showIcon={false}
                onClick={handleClick}
                style={TOP_INDENTS.M}
                simple
            />
        );
    })
);

export default RewardsSideBar;
