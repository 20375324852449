import styled from 'styled-components';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;

    p {
        margin-left: 16px;
    }
`;
