import React, { Fragment, useCallback, useState } from 'react';
import { BOTTOM_INDENTS, EmojiSadFrownIcon, Icon, Typography, useSimpleInterface } from '@klickly/front-packages';
import { SUBSCRIPTION_PLANS } from '../../../../../../shared';
import { PageHeader } from '../../../../../../shared/components/PageHeader';
import { SubscriptionItems } from '../../../../../../shared/components/SubscriptionItems';
import { GRID_POINTS } from '../../../../../../utils';
import { StyledButton, StyledButtonWrapper, StyledCardsContent, StyledErrorMessage } from './styles';

export const SubscriptionsContainer = () => {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    const [clicked, setClicked] = useState(false);

    const onClick = useCallback(() => {
        setClicked(true);
    }, [setClicked]);
    return (
        <Fragment>
            <PageHeader hasBottomIndent title='Giftly premium' />
            <Typography.Text style={BOTTOM_INDENTS.SM} type='body1'>
                Access $1000s worth of personalized and exclusive Rewards with a Premium membership
            </Typography.Text>
            <StyledCardsContent style={BOTTOM_INDENTS.XXL}>
                <SubscriptionItems items={SUBSCRIPTION_PLANS} />
            </StyledCardsContent>
            <StyledButtonWrapper $simple={simple}>
                <StyledButton type='primary' size='large' onClick={onClick} $simple={simple}>
                    Upgrade to Premium
                </StyledButton>
                {clicked && simple ? (
                    <StyledErrorMessage>
                        <Icon color='error' component={() => <EmojiSadFrownIcon />} />
                        <Typography.Text color='error' type='descriptor1'>
                            We’re sorry, the premium membership beta is currently full. Please try again at a later time
                        </Typography.Text>
                    </StyledErrorMessage>
                ) : (
                    <Typography.Text type='body2' color='secondaryText'>
                        By clicking “Upgrade to Premium”, you agree that we will charge your credit card $29 every 30
                        days, with your 1st charge today
                    </Typography.Text>
                )}
            </StyledButtonWrapper>
            {clicked && !simple ? (
                <StyledErrorMessage>
                    <Icon color='error' component={() => <EmojiSadFrownIcon />} />
                    <Typography.Text color='error' type='descriptor2'>
                        We’re sorry, the premium membership beta is currently full. Please try again at a later time
                    </Typography.Text>
                </StyledErrorMessage>
            ) : null}
        </Fragment>
    );
};
