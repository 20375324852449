import styled from 'styled-components';
import { $white } from '../../../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../../../utils';

export const StyledWrapper = styled.div`
    width: 100%;
    background-color: ${$white};
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1180px) {
        flex-direction: column;
        padding: 24px;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        margin: 0 24px 24px;
        width: calc(100% - 48px);
    }
`;

export const StyledImage = styled.img`
    width: 390px;
    height: 390px;
    object-fit: cover;
    margin-right: 40px;

    @media (max-width: 1300px) {
        width: 300px;
        height: 300px;
    }

    @media (max-width: 1180px) {
        width: 100%;
        height: 280px;
        margin-right: 0;
        margin-bottom: 40px;
    }
`;

export const StyledInfoWrapper = styled.div``;
