import React from 'react';
import { Button, CloseXIcon, Icon, ThumbsUpIcon, Typography } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import HtmlViewer from '../HtmlViewer';
import { ProductImage } from '../ProductImage';
import {
    StyledBrandname,
    StyledButton,
    StyledButtonWrap,
    StyledContent,
    StyledDescription,
    StyledDescriptionWrap,
    StyledDetails,
    StyledHead,
    StyledImageWrap,
    StyledTitle,
    StyledWrap
} from './styles';

export const ProductModalContainer = observer(({ onClose, promotion, onClick, selected, simple }) => {
    const handleClick = () => {
        onClick(promotion, selected);
    };

    const renderButton = () => (
        <StyledButtonWrap $simple={simple}>
            <Button type='primary' size='large' block disabled={selected} onClick={handleClick}>
                <Icon marginRight='20px' size='24px' component={() => <ThumbsUpIcon />} />
                <Typography.Text type='badgeLarge'>{selected ? 'Liked' : 'I like this product'}</Typography.Text>
            </Button>
        </StyledButtonWrap>
    );

    return (
        <StyledWrap $simple={simple}>
            <StyledHead $simple={simple}>
                <StyledButton type='unstyled' onClick={onClose}>
                    <Icon size='24px' component={() => <CloseXIcon />} />
                </StyledButton>
            </StyledHead>
            <StyledContent $simple={simple}>
                <StyledImageWrap $simple={simple}>
                    <ProductImage images={promotion.images} simple={simple} />
                </StyledImageWrap>
                <StyledDetails $simple={simple}>
                    <StyledBrandname type='body1' color='secondaryText'>
                        {promotion.brandName}
                    </StyledBrandname>
                    <StyledTitle level={2} color='primaryText' $simple={simple}>
                        {promotion.title}
                    </StyledTitle>
                    {!simple ? renderButton() : null}
                    {promotion.description ? (
                        <StyledDescriptionWrap>
                            <Typography.Title level={2} style={{ marginBottom: '20px' }}>
                                Product description
                            </Typography.Title>
                            <StyledDescription>
                                <HtmlViewer html={promotion.description} />
                            </StyledDescription>
                        </StyledDescriptionWrap>
                    ) : null}
                </StyledDetails>
                {simple ? renderButton() : null}
            </StyledContent>
        </StyledWrap>
    );
});
