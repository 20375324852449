import React, { Fragment, useContext } from 'react';
import { ArrowChevronForwardIcon, ArrowReplyIcon, DeleteIcon, EmojiHappySmileIcon, TimeIcon } from '../../../icons';
// library components
import { Button } from '../Button';
import { KliIcon } from '../KliIcon';
import { KliThemeContext } from '../KliThemeProvider';
import Typography from '../Typography';
// components
import { KliActiveCouponContent } from './active';
import { KliDeletedCouponContent } from './deleted';
import { KliExpiredCouponContent } from './expired';
import {
    BottomContentWrap,
    CouponButtonsWrap,
    CouponContent,
    CouponExpiredTextWrap,
    CouponWrap,
    StyledText
} from './styles';

// eslint-disable-next-line react/display-name
export const KliCoupon = React.forwardRef(
    (
        {
            primary = false,
            status = 'available',
            img,
            title,
            description,
            showFullDescription = false,
            additionalDetailsContent = null,
            additionalDetailsContentOnTop = false,
            bottomContent = undefined,
            onClick,
            onUseRewardClick,
            onUndoDeletingClick,
            onDeleteClick,
            align,
            ...rest
        },
        ref
    ) => {
        const themeContext = useContext(KliThemeContext);
        const inActiveStatus = status === 'available';
        const inAllocatedStatus = status === 'allocated';
        const inDeletedStatus = status === 'deleted';
        const inExpiredStatus = status === 'expired';
        const inRedeemedStatus = status === 'redeemed';
        const isActive = typeof onClick === 'function' && (inActiveStatus || inAllocatedStatus);

        const renderBottomContent = () => {
            // custom coupon bottom content
            if (bottomContent) {
                return <BottomContentWrap>{bottomContent}</BottomContentWrap>;
            }

            // no coupon bottom content
            if (bottomContent === null) {
                return null;
            }

            // default available coupon bottom content
            if (inActiveStatus || inAllocatedStatus) {
                return (
                    <BottomContentWrap>
                        <CouponButtonsWrap>
                            {typeof onUseRewardClick === 'function' ? (
                                <Button
                                    type='unstyled'
                                    style={{ paddingLeft: 0 }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        return onUseRewardClick();
                                    }}
                                >
                                    <Typography.Text type='badgeSmall'>Use reward</Typography.Text>
                                    <KliIcon size='16px'>
                                        <ArrowChevronForwardIcon />
                                    </KliIcon>
                                </Button>
                            ) : null}

                            {typeof onDeleteClick === 'function' ? (
                                <Button
                                    type='unstyled'
                                    style={{ paddingRight: 0 }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        return onDeleteClick();
                                    }}
                                >
                                    <KliIcon size='16px'>
                                        <DeleteIcon />
                                    </KliIcon>
                                    <Typography.Text type='badgeSmall'>Unclip</Typography.Text>
                                </Button>
                            ) : null}
                        </CouponButtonsWrap>
                    </BottomContentWrap>
                );
            }

            // default deleted coupon bottom content
            if (inDeletedStatus) {
                return (
                    <BottomContentWrap>
                        <CouponButtonsWrap>
                            <StyledText type='badgeSmall' color='secondaryText'>
                                Reward unclipped
                            </StyledText>
                            {typeof onUndoDeletingClick === 'function' ? (
                                <Fragment>
                                    <Button
                                        color='primaryText'
                                        type='unstyled'
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            return onUndoDeletingClick();
                                        }}
                                    >
                                        <KliIcon>
                                            <ArrowReplyIcon />
                                        </KliIcon>
                                        <Typography.Text type='badgeSmall'>Undo</Typography.Text>
                                    </Button>
                                </Fragment>
                            ) : null}
                        </CouponButtonsWrap>
                    </BottomContentWrap>
                );
            }

            // default expired coupon bottom content
            if (inExpiredStatus) {
                return (
                    <BottomContentWrap>
                        <CouponExpiredTextWrap>
                            <KliIcon size='16px' style={{ marginRight: '8px' }}>
                                <TimeIcon />
                            </KliIcon>
                            <Typography.Text type='badgeSmall'>REWARD EXPIRED</Typography.Text>
                        </CouponExpiredTextWrap>
                    </BottomContentWrap>
                );
            }

            // default redeemed coupon bottom content
            if (inRedeemedStatus) {
                return (
                    <BottomContentWrap>
                        <CouponExpiredTextWrap>
                            <KliIcon size='16px' style={{ marginRight: '8px' }}>
                                <EmojiHappySmileIcon />
                            </KliIcon>
                            <Typography.Text type='badgeSmall'>REWARD APPLIED</Typography.Text>
                        </CouponExpiredTextWrap>
                    </BottomContentWrap>
                );
            }
        };

        return (
            <CouponWrap
                $align={align}
                $theme={themeContext}
                $status={status}
                $primary={primary}
                $pointer={isActive}
                onClick={isActive ? onClick : null}
                ref={ref}
                {...rest}
            >
                <CouponContent>
                    {(inActiveStatus || inAllocatedStatus) && (
                        <KliActiveCouponContent
                            primary={primary}
                            img={img}
                            title={title}
                            description={description}
                            showFullDescription={showFullDescription}
                            additionalDetailsContent={additionalDetailsContent}
                            additionalDetailsContentOnTop={additionalDetailsContentOnTop}
                            themeContext={themeContext}
                        />
                    )}
                    {inDeletedStatus && <KliDeletedCouponContent title={title} />}
                    {inExpiredStatus && (
                        <KliExpiredCouponContent
                            img={img}
                            title={title}
                            description={description}
                            showFullDescription={showFullDescription}
                            additionalDetailsContent={additionalDetailsContent}
                            additionalDetailsContentOnTop={additionalDetailsContentOnTop}
                            themeContext={themeContext}
                        />
                    )}
                    {inRedeemedStatus && (
                        <KliExpiredCouponContent
                            img={img}
                            title={title}
                            description={description}
                            showFullDescription={showFullDescription}
                            additionalDetailsContent={additionalDetailsContent}
                            additionalDetailsContentOnTop={additionalDetailsContentOnTop}
                            themeContext={themeContext}
                        />
                    )}
                </CouponContent>
                {renderBottomContent()}
            </CouponWrap>
        );
    }
);
