import React from 'react';
import { $primaryText } from '../../constants';
import { Logo } from '../Logo';
import { LegalHeaderContent, LegalHeaderStyles } from './styles';

export const LegalHeader = () => {
    return (
        <LegalHeaderStyles>
            <LegalHeaderContent>
                <Logo size='32px' color={$primaryText} />
            </LegalHeaderContent>
        </LegalHeaderStyles>
    );
};
