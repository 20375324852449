import React from 'react';
import { Typography, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import {
    StyledCol,
    StyledContent,
    StyledHeadline1,
    StyledHeadline2,
    StyledPoint,
    StyledRow,
    StyledText,
    StyledWrap,
    Task1L,
    Task1S,
    Task2L,
    Task2S,
    Task3L,
    Task3S
} from './styles';

export const TasksSection = observer(() => {
    const simple = useSimpleInterface(1024);
    const mobile = useSimpleInterface(500);

    return (
        <StyledWrap>
            <StyledContent>
                {mobile ? (
                    <Typography.Title align='center' style={{ marginBottom: '30px' }}>
                        How it works?
                    </Typography.Title>
                ) : (
                    <Typography.Text type='em0' align='center' style={{ marginBottom: '60px' }}>
                        How it works?
                    </Typography.Text>
                )}
                <StyledRow $simple={simple} $mobile={mobile}>
                    <StyledCol $simple={simple} $text>
                        <StyledPoint level={2}>1</StyledPoint>
                        {mobile ? (
                            <StyledHeadline2 level={2}>Apply to access your Gifting Suite.</StyledHeadline2>
                        ) : (
                            <StyledHeadline1>Apply to access your Gifting Suite.</StyledHeadline1>
                        )}
                        <StyledText type={mobile ? 'descriptor1' : 'body1'} $simple={simple}>
                            Use your Instagram account and invite code (if you have one) to apply. Open your 1st Gifting
                            Suite & choose a free or highly discounted product from the selection.
                        </StyledText>
                    </StyledCol>
                    <StyledCol $simple={simple}>{mobile ? <Task1S /> : <Task1L />}</StyledCol>
                </StyledRow>
                <StyledRow $simple={simple} $reverted>
                    <StyledCol $simple={simple} $text>
                        <StyledPoint level={2}>2</StyledPoint>
                        {mobile ? (
                            <StyledHeadline2 level={2}>Shop on our exclusive marketplace.</StyledHeadline2>
                        ) : (
                            <StyledHeadline1>Shop on our exclusive marketplace.</StyledHeadline1>
                        )}
                        <StyledText type={mobile ? 'descriptor1' : 'body1'} $simple={simple}>
                            Shop on exclusive marketplace with great deals and products. Earn points to spend the more
                            you shop.
                        </StyledText>
                    </StyledCol>
                    <StyledCol $simple={simple}>{mobile ? <Task2S /> : <Task2L />}</StyledCol>
                </StyledRow>
                <StyledRow $simple={simple}>
                    <StyledCol $simple={simple} $text>
                        <StyledPoint level={2}>3</StyledPoint>
                        {mobile ? (
                            <StyledHeadline2 level={2}>
                                Unlock more points for better deals & future Gifting Suites.
                            </StyledHeadline2>
                        ) : (
                            <StyledHeadline1>
                                Unlock more points for better deals & future Gifting Suites.
                            </StyledHeadline1>
                        )}
                        <StyledText type={mobile ? 'descriptor1' : 'body1'} $simple={simple}>
                            Complete optional tasks, earn additional points, and spend them to try new amazing products!
                        </StyledText>
                    </StyledCol>
                    <StyledCol $simple={simple}>{mobile ? <Task3S /> : <Task3L />}</StyledCol>
                </StyledRow>
            </StyledContent>
        </StyledWrap>
    );
});
