import React from 'react';
import { ROUTE_PATHS } from '../../../shared';
import ProductPage from '../components/ProductPage';
import PromotionsPage from '../components/promotions';

export const ROUTES = [
    {
        element: <PromotionsPage />,
        index: true
    },
    {
        path: ROUTE_PATHS.pdp,
        element: <ProductPage />
    }
];
