import React from 'react';
import { CheckIcon, CloseXIcon, MailIcon } from '@klickly/front-packages';
import { $secondaryText, $success, $systemAlert } from '../../../../../../../../../../../../../../shared';

export const iconStatusMap = {
    accepted: <CheckIcon />,
    pending: <MailIcon />,
    expired: <CloseXIcon />
};

export const colorStatusMap = {
    accepted: $success,
    pending: $systemAlert,
    expired: $secondaryText
};
