export const faq = [
    {
        headerText: 'What is a Gifting Suite?',
        bodyText:
            'A gifting suite is a swanky event where brands give products to celebrities in exchange for the celebrity taking a photo with the product or on a red carpet'
    },
    {
        headerText: 'Why Giftly is giving products away?',
        bodyText:
            'We’re lucky enough to work with some of the world’s top brands, each of which is excited to find amazing new trendsetters like you. Together, we hope you’ll discover a product you love so much you’ll give back by sharing it with your followers, and becoming a lifelong customer.'
    },
    {
        headerText: 'Does it cost to be a member?',
        bodyText: 'No.'
    },
    {
        headerText: 'How do I know if I love Giftly brands?',
        bodyText:
            'We take great care to curate only trendy, exciting, and up-and-coming brands, many of which are focused on community impact and giving back. We’re confident you’ll discover many brands you love on Giftly.'
    }
];
