export const ordersByDate = (orders) => {
    if (!orders) return {};
    return orders.reduce((groups, order) => {
        const date = order.createdAt ? order.createdAt.split('T')[0] : '';

        if (!groups[date]) {
            groups[date] = [];
        }

        groups[date].push(order);

        return groups;
    }, {});
};
