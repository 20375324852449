import React, { useMemo } from 'react';
import axios from 'axios';
import { Provider } from 'mobx-react';
import { RootStore } from './stores/RootStore';
import { App } from './App';
import { GlobalStyle } from './App.styles';

export const CheckoutShoppingCart = ({
    callbacks,
    metadata = [],
    redirects = {},
    customizations = {},
    isLoggedIn = false,
    bottomExtraContent,
    buttonBadge
}) => {
    const store = useMemo(
        () =>
            RootStore.create(
                {
                    view: {
                        customizations
                    }
                },
                {
                    callbacks,
                    metadata,
                    redirects,
                    isLoggedIn
                }
            ),
        []
    );

    return (
        <Provider store={store}>
            <GlobalStyle />
            <App bottomExtraContent={bottomExtraContent} buttonBadge={buttonBadge} />
        </Provider>
    );
};

export const CheckoutShoppingCartHTTPWrapper = ({
    children,
    links,
    onCheckoutButtonClick,
    onBackButtonClick,
    onRewardIconClick = () => null,
    onPointsIconClick = () => null,
    onCartCouponRemove
}) => {
    const { cartFetch, cartItemsManage, buyNow, pointsFetch, pointsApply, pointsRemove } = links;
    const axiosInstance = axios.create({ withCredentials: true });

    const onCartFetch = async () => {
        const { data } = await axiosInstance.get(`${cartFetch}`);
        return data;
    };

    const onGetCheckoutKey = async (cartId, body) => {
        const { data } = await axiosInstance.post(`${cartFetch}/to-checkout`, body);
        return data;
    };

    const onCartSubmit = (cartId, key) => {
        if (typeof onCheckoutButtonClick === 'function') {
            return onCheckoutButtonClick({ cartId, checkoutKey: key, isBuyNow: false });
        }
        return true;
    };

    const onCartItemQtyChange = async (payload) => {
        const { data } = await axiosInstance.post(cartItemsManage, {
            action: 'qtyChange',
            data: payload
        });
        return data;
    };

    const onCartItemRemove = async (payload) => {
        const { data } = await axiosInstance.post(cartItemsManage, {
            action: 'itemDelete',
            data: payload
        });
        return data;
    };

    const onCartItemUndoRemove = async (payload) => {
        const { data } = await axiosInstance.post(cartItemsManage, {
            action: 'itemUndoDelete',
            data: payload
        });
        return data;
    };

    const onBuyNow = async (payload) => {
        const { data } = await axiosInstance.post(buyNow, payload);
        if (typeof onCheckoutButtonClick === 'function') {
            return onCheckoutButtonClick({ cartId: data.cartId, checkoutKey: data.checkoutKey, isBuyNow: true });
        }
    };

    const onPointsFetch = async () => {
        const { data } = await axiosInstance.get(pointsFetch);
        return data;
    };

    const onPointsApply = async (points) => {
        const { data } = await axiosInstance.post(pointsApply, { points });
        return data;
    };

    const onPointsRemove = async () => {
        const { data } = await axiosInstance.post(pointsRemove, {});
        return data;
    };

    return React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                callbacks: {
                    onCartFetch,
                    onCartItemQtyChange,
                    onCartItemRemove,
                    onCartItemUndoRemove,
                    onCartSubmit,
                    onGetCheckoutKey,
                    onBackButtonClick,
                    onBuyNow,
                    onPointsFetch,
                    onPointsApply,
                    onPointsRemove,
                    onRewardIconClick,
                    onPointsIconClick,
                    onCartCouponRemove
                }
            });
        }
        return child;
    });
};
