import React from 'react';
import { Icon, ProfileIcon } from '@klickly/front-packages';
import { $primary, $primaryText } from '../../constants';
import { BoxLoaderWrapperStyles, Wave1Styles, Wave1WrapStyles, Wave2Styles, Wave2WrapStyles } from './BoxLoader.styles';

export const BoxLoader = ({
    size = '160px',
    iconSize = '40px',
    iconColor = $primaryText,
    animationDuration = '8s',
    waveColor = $primary,
    withShadow = true,
    infinite = false,
    icon = <ProfileIcon />
}) => {
    return (
        <BoxLoaderWrapperStyles size={size} $withShadow={withShadow}>
            <Icon size={iconSize} color={iconColor} component={() => icon} />
            <Wave1WrapStyles $infinite={infinite} $animationDuration={animationDuration}>
                <Wave1Styles color={waveColor} />
            </Wave1WrapStyles>
            <Wave2WrapStyles $infinite={infinite} $animationDuration={animationDuration}>
                <Wave2Styles color={waveColor} />
            </Wave2WrapStyles>
        </BoxLoaderWrapperStyles>
    );
};
