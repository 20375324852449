import styled from 'styled-components';
import { $border, $primary } from '../../constants';

export const StyledWrapper = styled.div`
    max-width: 1400px;
    margin: 0 auto;
    padding: 24px 20px;
    border-top: 1px solid ${$border};
`;
export const StyledMenuItem = styled.div`
    &&&&&&& {
        height: auto;
        padding: 20px 24px;
        cursor: pointer;

        .anticon {
            width: 24px;
            height: 24px;

            transform: rotate(0deg);

            > svg {
                font-size: 24px;
                color: ${$primary};
            }
        }
    }
`;
