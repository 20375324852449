import React, { Fragment } from 'react';
import { Col, Row, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../../../utils';
import { $border, $primary } from '../../../../../constants';

const LinkContent = inject('store')(
    observer(({ store, count, text }) => {
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { isLoggedIn } = store.accountStore;

        return simple ? (
            <Row gutter={4}>
                <Col>{text}</Col>
                {isLoggedIn ? (
                    <Fragment>
                        <Col>
                            <Typography.Text color={$border}>|</Typography.Text>
                        </Col>
                        <Col>
                            <Typography.Text color={$primary}>{count || 0}</Typography.Text>
                        </Col>
                    </Fragment>
                ) : null}
            </Row>
        ) : (
            <span>{text}</span>
        );
    })
);

export default LinkContent;
