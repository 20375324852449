import { device } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX, GRID_DEVICES_MIN } from '../../../utils';
import { $bordered, $white } from '../../constants';

const isIos = device.iOS();
const isSafari = device.isSafari();

export const Wrap = styled.div`
    width: 100%;
    height: 100%;
    padding-top: ${({ $singleBar }) => ($singleBar ? 80 : 160)}px;

    position: relative;

    overflow: hidden;

    .ant-drawer-content-wrapper {
        box-shadow: none;
    }

    .ant-drawer {
        top: 80px;

        ul.ant-menu {
            border-inline-end: 0;

            li.ant-menu-item {
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }

    @media (${GRID_DEVICES_MAX.md}) {
        padding-top: 80px;
    }
`;

export const Body = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    overflow: ${({ overflowHidden }) => (overflowHidden ? 'hidden' : 'auto')};
    position: static;
    ${({ $hasFooter }) =>
        $hasFooter &&
        css`
            flex-wrap: wrap;
        `};

    ${({ $hasAfterHeaderContent }) =>
        $hasAfterHeaderContent &&
        css`
            flex-wrap: wrap;
            > * {
                &:first-child {
                    width: 100%;
                }
                &:not(:first-child) {
                    flex: 1;
                }
            }
        `};

    @media (${GRID_DEVICES_MAX.md}) {
        height: ${({ $withStickyBottomElem }) =>
            $withStickyBottomElem && isSafari && isIos ? 'calc(100dvh - 80px)' : '100%'};
        position: ${({ $withStickyBottomElem }) =>
            $withStickyBottomElem && isSafari && isIos ? 'relative' : 'static'};
    }
`;

export const SideBar = styled.aside`
    width: 100%;

    @media (${GRID_DEVICES_MIN.md}) {
        max-width: 315px;
        padding: 20px;
        height: 100%;
        overflow-y: auto;

        position: sticky;
        top: 0;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        height: 0;
        max-width: 100%;
        border-top: 1px solid ${$bordered};
        background: ${$white};
    }

    ${({ $hasFooter }) =>
        $hasFooter &&
        css`
            height: calc(100% - 80px);

            @media (${GRID_DEVICES_MIN.md}) {
                height: calc(100% - 80px);
            }
        `};
`;

export const Content = styled.main`
    width: 100%;
    padding: 20px;

    @media (${GRID_DEVICES_MIN.md}) {
        padding-left: 40px;

        ${({ $withoutPadding }) =>
            $withoutPadding
                ? css`
                      padding: 0;
                  `
                : ''}
    }

    @media (${GRID_DEVICES_MAX.md}) {
        padding-bottom: 0;
        ${({ $withoutBottomPadding }) =>
            $withoutBottomPadding
                ? css`
                      padding-bottom: 0;
                  `
                : ''}
    }

    ${({ $withSideBar }) =>
        $withSideBar
            ? css`
                  @media (${GRID_DEVICES_MIN.md}) {
                      width: calc(100% - 315px);
                      max-width: calc(1400px - 315px);
                  }
              `
            : ''}

    ${({ $contentFullHeight, $hasFooter }) =>
        $contentFullHeight
            ? css`
                  height: ${$hasFooter ? 'calc(100% - 80px)' : '100%'};
                  overflow-y: auto;
              `
            : ''}

    ${({ $withoutPadding }) =>
        $withoutPadding
            ? css`
                  padding: 0;
              `
            : ''}
`;
