import { Row } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MIN } from '../../utils';

export const StyledWrapper = styled.div`
    height: 100%;
    display: none;

    @media (${GRID_DEVICES_MIN.lg}) {
        .thank-you-page-main-content {
            //padding-top: 200px;
        }
    }
`;

export const StyledRow = styled(Row)`
    height: 100%;
`;
