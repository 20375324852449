import React from 'react';
import { Typography } from '@klickly/front-packages';
import { StyledButton } from './styled';

const SignOutBtn = ({ onMenuClose, onLogOut }) => {
    return (
        <StyledButton
            type='unstyled'
            onClick={() => {
                onMenuClose();
                return onLogOut();
            }}
        >
            <Typography.Text type='body2'>Sign out</Typography.Text>
        </StyledButton>
    );
};

export default SignOutBtn;
