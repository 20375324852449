import React, { useEffect } from 'react';
import { GRID_POINTS, Icon, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { SEASONAL_CHALLENGE_ALIASES, useSeasonalChallengeNotifications } from '../../shared';
import CouponBanner from '../../shared/components/CouponBanner';
import ExclusiveDealsBanner from '../../shared/components/ExclusiveDealsBanner';
import { Layout } from '../../shared/components/Layout';
import BestWelcomeGiftOffers from './components/BestWelcomeGiftOffers';
import DayDeals from './components/DayDeals';
import HolidayDeals from './components/HolidayDeals';
import { StyledWrapper } from './styled';

const Deals = inject('store')(
    observer(({ store }) => {
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { isLoggedIn } = store.accountStore;
        const {
            getBestWelcomeGiftOffers,
            bestWelcomeGiftOffers,
            dayDealsPromotions,
            getDayDealsPromotions,
            holidayDealsPromotions,
            getHolidayDealsPromotions
        } = store.dealsStore;
        const { activeChallenge } = store.seasonalChallengeStore;

        const showSeasonalChallengeNotification = useSeasonalChallengeNotifications(store);

        useEffect(() => {
            if (!bestWelcomeGiftOffers.length) getBestWelcomeGiftOffers({ limit: simple ? 8 : 4 });
            if (!dayDealsPromotions.length) getDayDealsPromotions();
            if (!holidayDealsPromotions.length) getHolidayDealsPromotions();

            if (
                activeChallenge?.alias === SEASONAL_CHALLENGE_ALIASES.EARN_POINTS &&
                activeChallenge.challengeAccepted
            ) {
                showSeasonalChallengeNotification(
                    '1',
                    'Earn total of 500 points on Giftly purchase. Points can be found next to the product price.',
                    { duration: null, bottom: simple ? '0' : '8px' }
                );
            }
        }, []);

        const loading =
            store.dealsStore.bestWelcomeGiftOffersLoading ||
            store.dealsStore.holidayDealsPromotionsLoading ||
            store.dealsStore.dayDealsPromotionsLoading;

        return loading ? (
            <Icon.Loader />
        ) : (
            <Layout withoutPadding>
                <StyledWrapper id='deals-container'>
                    <CouponBanner />
                    <ExclusiveDealsBanner />
                    <HolidayDeals />
                    {isLoggedIn ? null : <CouponBanner type='secondBanner' />}
                    <BestWelcomeGiftOffers />
                    <DayDeals />
                </StyledWrapper>
            </Layout>
        );
    })
);

export default Deals;
