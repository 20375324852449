import React, { useContext } from 'react';
import { KliThemeContext } from '../KliThemeProvider';
import {
    CheckboxWrap,
    Checkmark,
    CheckmarkIcon,
    ContentWrap,
    ImageWrap,
    KliSelectedSquareWrap,
    Lablel
} from './styles';

// eslint-disable-next-line react/display-name
export const SelectableItem = React.forwardRef(
    ({ children, selectorOnTop = false, img, fillSelected = false, ...rest }, ref) => {
        const themeContext = useContext(KliThemeContext);

        return (
            <Lablel theme={themeContext} fillSelected={fillSelected}>
                <input type='checkbox' {...rest} ref={ref} />

                <KliSelectedSquareWrap theme={themeContext} withContent={!!children} className='checkmark'>
                    {!!img && <ImageWrap withContent={!!children}>{img}</ImageWrap>}

                    {!!children && <ContentWrap withImg={!!img}>{children}</ContentWrap>}

                    <CheckboxWrap selectorOnTop={selectorOnTop}>
                        <Checkmark size={22} theme={themeContext} className='fake-icon-round' />
                        <CheckmarkIcon theme={themeContext} className='fake-icon' />
                    </CheckboxWrap>
                </KliSelectedSquareWrap>
            </Lablel>
        );
    }
);
