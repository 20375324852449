import React, { Fragment, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { NOTIFICATION_MESSAGES } from '../../../../../../shared';
import { useProductReviewNotification } from '../../../../../../shared/hooks/useProductReviewNotification';
import ChargedInfo from './components/ChargedInfo';
import Header from './components/Header';
import OrderActions from './components/OrderActions';
import OrderPriceDetails from './components/OrderPriceDetails';
import OrderSummary from './components/OrderSummary';
import ProductsList from './components/ProductsList';
import { StyledWrapper } from './styled';

const Order = inject('store')(
    observer(({ store }) => {
        const params = useParams();
        const { search } = useLocation();
        const { getOrderDetails, pending, orderDetails } = store.orderStore;
        const orderNumber = new URLSearchParams(search).get('orderNumber');

        useProductReviewNotification(store, NOTIFICATION_MESSAGES.PRODUCT_REVIEW[1]);

        useEffect(() => {
            getOrderDetails(params.id);
        }, []);
        return (
            <StyledWrapper>
                <Header orderNumber={orderNumber} />
                {pending || !orderDetails ? (
                    <Icon.Loader />
                ) : (
                    <Fragment>
                        <OrderSummary orderNumber={orderNumber} />
                        <ChargedInfo orderNumber={orderNumber} />
                        <OrderActions orderNumber={orderNumber} orderId={params.id} />
                        <ProductsList orderNumber={orderNumber} />
                        <OrderPriceDetails orderNumber={orderNumber} />
                    </Fragment>
                )}
            </StyledWrapper>
        );
    })
);

export default Order;
