import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ArrowChevronForwardIcon,
    BOTTOM_INDENTS,
    Button,
    Icon,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { $primary, ROUTE_PATHS } from '../../../../../../../../../../shared';
import { GRID_POINTS } from '../../../../../../../../../../utils';
import { StyledContent, StyledHeader } from './styled';

const RewardNotAvailableModalContent = ({ onCloseModal }) => {
    const navigate = useNavigate();
    const simple = useSimpleInterface(GRID_POINTS.md);
    const handleGoToChallenges = () => {
        onCloseModal();
        return navigate(ROUTE_PATHS.challenges);
    };

    return (
        <div>
            <StyledHeader>
                <Typography.Title color={$primary} level={simple ? 2 : 1} align={simple ? 'left' : 'center'}>
                    Sorry, this reward is <br />
                    not available to you
                </Typography.Title>
            </StyledHeader>
            <StyledContent>
                <Typography.Text type='body1' style={BOTTOM_INDENTS.M} align={simple ? 'left' : 'center'}>
                    But you can earn other rewards{simple ? <br /> : null} by completing challenges
                </Typography.Text>
                <Button size='large' type='primary' block style={BOTTOM_INDENTS.S} onClick={handleGoToChallenges}>
                    Go to Challanges
                </Button>
                <Button size='large' type='unstyled' block style={BOTTOM_INDENTS.S} onClick={onCloseModal}>
                    Go to Shopping
                    <Icon size='24px' component={() => <ArrowChevronForwardIcon />} />
                </Button>
            </StyledContent>
        </div>
    );
};

export default RewardNotAvailableModalContent;
