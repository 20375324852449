import { emailRegexValidation } from '../../utils';

export const VALIDATION_RULES = {
    EMAIL: [
        { required: true, message: 'This field is required' },
        {
            type: 'email',
            pattern: emailRegexValidation,
            message: 'Please enter a valid e-mail address'
        }
    ],
    REQUIRED: [{ required: true, message: 'This field is required' }],
    NAME: [
        { required: true, message: 'This field is required' },
        { min: 2, message: 'Field should contain at least 2 characters' },
        { pattern: /([A-zÀ-ÿ-. ']+[ ]*)+/gm, message: 'Invalid field value' }
    ],
    COMMENT: [
        { required: true, message: 'This field is mandatory' },
        { min: 50, message: 'min 50 characters' }
    ]
};
