import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Icon, Row, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import ActionButton from '../../../components/Deals/components/ActionButton';
import { GRID_POINTS } from '../../../utils';
import { useCountDown } from '../../index';
import { CouponWrapper } from '../CouponWrapper';
import { StyledContentWrapper, StyledWrapper } from './styled';
import { getCouponBannerContent } from './utils';

const CouponBanner = inject('store')(
    observer(({ store, type = 'firstBanner', isMobileView, height }) => {
        const { pathname } = useLocation();
        const { nextCloseAt, suiteState, loaded, getCurrentSuite } = store.suiteStore;
        const { claimsCount } = store.claimsPointsStore;
        const { isLoggedIn, signUpStatusRejected } = store.accountStore;
        const [stopTimer, setStopTimer] = useState(!nextCloseAt);
        const [content, setContent] = useState(getCouponBannerContent(store, type, pathname));
        const simple = useSimpleInterface(GRID_POINTS.md);

        useEffect(() => {
            if (isLoggedIn && (!nextCloseAt || claimsCount > 0)) {
                setContent(getCouponBannerContent(store, 'randomChallenge', pathname));
            }
        }, [nextCloseAt, isLoggedIn, suiteState, claimsCount]);

        useEffect(() => {
            if (signUpStatusRejected) {
                getCurrentSuite();
            }
        }, []);

        useCountDown(
            nextCloseAt,
            () => {
                if (nextCloseAt) {
                    setStopTimer(true);
                    return setContent(getCouponBannerContent(store, 'randomChallenge', pathname));
                }
            },
            stopTimer
        );

        if (!content) return <Icon.Loader />;

        const title = content.getTitle({ simple: simple, isMobileView });

        return title && ((isLoggedIn && loaded) || !isLoggedIn) ? (
            <StyledWrapper $isMobileView={isMobileView} $height={height}>
                <CouponWrapper>
                    <StyledContentWrapper $isMobileView={isMobileView}>
                        <Row gutter={simple || isMobileView ? 0 : 24} justify='center' align='middle'>
                            <Col flex='auto'>{title}</Col>
                            <Col flex={simple || isMobileView ? 'auto' : 'none'}>
                                <Row gutter={simple || isMobileView ? 0 : 24} justify='center'>
                                    {content.primaryBtn ? (
                                        <Col>
                                            <ActionButton onClick={content.primaryBtn.onClick} isRed hasBackground>
                                                {content.primaryBtn.text}
                                            </ActionButton>
                                        </Col>
                                    ) : null}
                                    {content.secondaryBtn ? (
                                        <Col>
                                            <ActionButton onClick={content.secondaryBtn?.onClick} isRed icon>
                                                {content.secondaryBtn.text}
                                            </ActionButton>
                                        </Col>
                                    ) : null}
                                </Row>
                            </Col>
                        </Row>
                    </StyledContentWrapper>
                </CouponWrapper>
            </StyledWrapper>
        ) : null;
    })
);

export default CouponBanner;
