import React from 'react';
import { BOTTOM_INDENTS, Col, formatPoints, roundToLocaleString, Row, Typography } from '@klickly/front-packages';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { $primary } from '../../../../../../../../shared';
import { StyledWrapper } from './styled';

const ChargedInfo = inject('store')(
    observer(({ store, orderNumber }) => {
        const { getDetailsByOrderNumber, getEarnedPointsByOrderNumber } = store.orderStore;
        const { data } = store.ordersStore;

        const orderData = toJS(data).filter((item) => item.orderNumber === orderNumber);

        const details = getDetailsByOrderNumber(orderNumber);
        const orderPoints = formatPoints(getEarnedPointsByOrderNumber(orderNumber));
        const isPointsCalculating = orderData[0]?.isAvailableForCancel;

        return details ? (
            <StyledWrapper>
                <Row gutter={8} align='middle' justify='space-between' style={BOTTOM_INDENTS.XXS}>
                    <Col>
                        <Typography.Text type='badgeMedium'>Charged to your card</Typography.Text>
                    </Col>
                    <Col>
                        <Typography.Text type='badgeMedium' data-e2e='giftly-order_summary-charged-to-card'>
                            ${roundToLocaleString(details?.priceDetails?.fullTotal)}
                        </Typography.Text>
                    </Col>
                </Row>
                <Row gutter={8} align='middle' justify='space-between' style={BOTTOM_INDENTS.XXS}>
                    <Col>
                        <Typography.Text type='body2' color={$primary}>
                            {isPointsCalculating || getEarnedPointsByOrderNumber(orderNumber) > 0
                                ? 'Points earned'
                                : ''}
                        </Typography.Text>
                    </Col>
                    <Col>
                        <Typography.Text type='body2' color={$primary}>
                            {isPointsCalculating ? 'Calculating your reward...' : null}
                            {!isPointsCalculating && getEarnedPointsByOrderNumber(orderNumber) > 0
                                ? `${orderPoints} pts`
                                : ''}
                        </Typography.Text>
                    </Col>
                </Row>
            </StyledWrapper>
        ) : null;
    })
);

export default ChargedInfo;
