import React, { Fragment } from 'react';
import { StyledBadge, StyledMessage } from '../../styled';

const RewardNotification = ({ rewardType = 'points', rewardCount = 1, message }) => {
    const badgeText = rewardType === 'points' ? 'points for completing' : 'Gifting Suite for completing';
    return (
        <Fragment>
            <StyledBadge>
                {rewardCount} {badgeText}
            </StyledBadge>
            <StyledMessage type='descriptor2'>{message}</StyledMessage>
        </Fragment>
    );
};

export default RewardNotification;
