import { Icon } from '@klickly/front-packages';
import styled from 'styled-components';
import { $background, $bordered } from '../../../../../../shared';

export const Styles = styled.div`
    &&& {
        .ant-collapse-header {
            .ant-collapse-expand-icon {
                padding-inline-start: 0;
            }
        }
    }
`;

export const Body = styled.div`
    padding: 15px 0 20px;
`;

export const StyledPanelHead = styled.div`
    border-bottom: 1px solid ${$bordered};
`;

export const StyledIcon = styled(Icon)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: ${$background};
    border-radius: 50%;
    left: auto;
    margin: 0;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
`;
