import { Col, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../utils';
import { $background as _$background, $white } from '../../../../index';

export const StyledCol = styled(Col)`
    &:last-child {
        flex: none !important;
    }
`;

export const StyledStepWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: ${({ $size }) => ($size === 'small' ? '56px' : '70px')};
    background: ${({ $background = _$background }) => $background};
    cursor: pointer;

    &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;

        border-bottom: solid 28px ${$white};
        border-top: solid 28px transparent;
        border-left: solid 28px transparent;
        border-right: solid 28px transparent;

        ${({ $isActive }) =>
            $isActive &&
            css`
                content: '';
            `};
    }

    &:after {
        content: '';
        position: absolute;
        top: 26px;
        left: 56px;
        width: calc(100% - 56px);
        height: 4px;
        background: ${({ $colors }) => $colors.progressColor};
        z-index: 1;
    }

    &&& {
        .ant-popover {
            min-width: 200px;

            @media (${GRID_DEVICES_MAX.md}) {
                min-width: 150px;
            }
        }

        .ant-popover-inner-content {
            padding: 16px 32px;

            @media (${GRID_DEVICES_MAX.md}) {
                padding: 8px 16px;
            }

            .ant-btn {
                padding: 0;
                border: none;
                color: ${$white};

                .anticon {
                    font-size: 12px;
                }
            }
        }

        > div {
            div:has(.ant-popover) {
                z-index: 1000;
            }
        }
    }
`;

export const StyledStepTitle = styled(Typography.Text)`
    position: absolute;
    top: -24px;
    left: 50%;
    width: 250px;
    text-align: center;
    transform: translateX(-50%);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const StyledStep = styled.div`
    ${({ $colors }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        border: 4px solid ${$colors.borderColor};
        border-radius: 50%;
        background: ${$colors.background};
        z-index: 2;

        ${({ $withTitle }) =>
            $withTitle &&
            css`
                position: relative;
            `};

        .anticon {
            color: ${$colors.color};
        }
    `};
`;
