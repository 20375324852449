import { roundToLocaleString } from '@klickly/front-packages';
import { flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import { CART_REDIRECTS, kcpAPI, ROUTE_PATHS } from '../shared';
import { getMetadata } from '../shared/utils/metadata';
import { GIFTLY_CUSTOMER_BLOCKED_BY_IP_OR_EMAIL, selectCredentials } from '../utils';

export const PreCheckoutStore = types
    .model('PreCheckoutStore', {
        pending: true,
        cartData: types.optional(
            types.model({
                _id: types.maybeNull(types.string),
                bags: types.array(
                    types.model({
                        _id: types.identifier,
                        merchantId: types.string,
                        merchantName: types.maybeNull(types.string),
                        items: types.array(
                            types.model({
                                _id: types.identifier,
                                productId: types.string,
                                productTitle: types.string,
                                variantTitle: types.string,
                                sku: types.optional(types.string, ''),
                                qty: types.number,
                                image: types.string,
                                taxable: types.boolean,
                                requiredShipping: types.boolean,
                                priceDetails: types.model({
                                    qty: types.number,
                                    subTotal: types.number,
                                    originalSubTotal: types.number,
                                    discounts: types.number,
                                    shipping: types.maybe(types.number),
                                    taxes: types.maybe(types.number),
                                    total: types.number,
                                    fullTotal: types.number
                                }),
                                metadata: types.array(types.frozen())
                            })
                        ),
                        priceDetails: types.model({
                            qty: types.number,
                            subTotal: types.number,
                            originalSubTotal: types.number,
                            discounts: types.number,
                            shipping: types.maybe(types.number),
                            taxes: types.maybe(types.number),
                            total: types.number,
                            fullTotal: types.number
                        })
                    })
                ),
                priceDetails: types.maybeNull(
                    types.model({
                        qty: types.number,
                        originalSubTotal: types.number,
                        subTotal: types.number,
                        discounts: types.number,
                        total: types.number,
                        fullTotal: types.number
                    })
                ),
                discounts: types.maybeNull(types.array(types.frozen()))
            }),
            {}
        ),
        email: types.maybe(types.string),
        error: types.maybe(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },

        get fullTotalPrice() {
            return `$${roundToLocaleString(self.cartData?.priceDetails?.fullTotal || 0)}`;
        }
    }))
    .actions((self) => {
        const getCartData = flow(function* getCartData({ cartId, isBuyNow, withPendingTurnover = true }) {
            const cartDataUrl = isBuyNow ? kcpAPI.cart.update(cartId) : kcpAPI.cart.get;
            try {
                const data = yield self.root.get(cartDataUrl);
                self.cartData = data;
                self.pending = withPendingTurnover ? false : self.pending;
                return data._id;
            } catch (error) {
                if (error.response) {
                    self.error = error.response.data.message;
                }
                self.pending = false;
            }
        });

        const goToCheckout = flow(function* goToCheckout(
            cartId,
            onPriceWasChanged = () => null,
            onUserBlocked = () => null
        ) {
            self.pending = true;
            try {
                let updatedCheckoutKey;
                const checkoutData = yield self.root.post(kcpAPI.cart.toCheckout, {
                    cartId,
                    email: self.email,
                    metadata: getMetadata(),
                    redirects: CART_REDIRECTS,
                    priceDetails: getSnapshot(self.cartData.priceDetails)
                });

                if (checkoutData?.key) {
                    const showUpsell = yield self.root.upsellStore.checkForUpsell(cartId);
                    showUpsell
                        ? self.root.routeNavigate(ROUTE_PATHS.upsell, {
                              state: { cartId, checkoutKey: checkoutData.key }
                          })
                        : window.open(`${kcpAPI.cart.makeCheckoutUrl(cartId, checkoutData.key)}`, '_self');
                } else {
                    self.pending = false;
                    if (checkoutData.updatedCart) {
                        self.cartData = checkoutData.updatedCart;
                        const data = yield getCheckoutData(cartId);
                        updatedCheckoutKey = data?.key ? data.key : null;
                    }
                    onPriceWasChanged(cartId, updatedCheckoutKey || checkoutData.key);
                }
            } catch (error) {
                if (error?.response?.data?.message === GIFTLY_CUSTOMER_BLOCKED_BY_IP_OR_EMAIL) {
                    self.root.accountStore.setBlockCustomer(true);
                    return onUserBlocked();
                }
                if (error.response) {
                    self.error = error.response.data.message;
                }
                self.pending = false;
            }
        });

        const checkIfCustomerExists = flow(function* checkIfCustomerExists(
            formData,
            onAfterEmailWasFound,
            checkoutKey,
            onPriceWasChanged,
            onUserBlocked = () => null
        ) {
            self.pending = true;
            try {
                const data = yield self.root.get(kcpAPI.customer.exists, formData);
                yield self.root.patch(kcpAPI.cart.update(self.cartData._id), {
                    customerInfo: formData
                });
                self.email = formData.email;
                if (data.exists) {
                    self.pending = false;
                    onAfterEmailWasFound();
                } else {
                    yield goToCheckout(self.cartData._id, onPriceWasChanged, onUserBlocked);
                }
            } catch (error) {
                if (error.response) {
                    self.error = error.response.data.message;
                }
                self.pending = false;
            }
        });

        const signInBeforeCheckout = flow(function* signInBeforeCheckout(
            formData,
            onPriceWasChanged,
            guestCartId,
            onUserBlocked = () => null
        ) {
            self.pending = true;
            try {
                yield self.root.post(kcpAPI.auth.login, {
                    ...formData,
                    ...selectCredentials()
                });
                yield self.root.authStore.mergeGuestCart(guestCartId);
                self.root.authStore.onSignInSendMessage();
                const cartId = yield getCartData({ withPendingTurnover: false });
                const checkoutData = yield self.root.post(kcpAPI.cart.toCheckout, {
                    cartId,
                    metadata: getMetadata(),
                    redirects: CART_REDIRECTS,
                    priceDetails: getSnapshot(self.cartData.priceDetails)
                });

                if (checkoutData?.key) {
                    const showUpsell = yield self.root.upsellStore.checkForUpsell(cartId);
                    showUpsell
                        ? self.root.routeNavigate(ROUTE_PATHS.upsell, {
                              state: { cartId, checkoutKey: checkoutData.key }
                          })
                        : window.open(`${kcpAPI.cart.makeCheckoutUrl(cartId, checkoutData.key)}`, '_self');
                } else {
                    self.pending = false;
                    onPriceWasChanged(cartId, checkoutData.key);
                }
            } catch (error) {
                if (error.response?.data?.message === GIFTLY_CUSTOMER_BLOCKED_BY_IP_OR_EMAIL) {
                    self.root.accountStore.setBlockCustomer(true);
                    return onUserBlocked();
                }
                if (error.response) {
                    self.error = error.response.data.message;
                }
                self.pending = false;
            }
        });

        const getCheckoutData = flow(function* getCheckoutData(cartId) {
            try {
                return yield self.root.post(kcpAPI.cart.toCheckout, {
                    cartId,
                    metadata: getMetadata(),
                    redirects: CART_REDIRECTS,
                    priceDetails: getSnapshot(self.cartData.priceDetails)
                });
            } catch (error) {
                if (error.response) {
                    self.error = error.response.data.message;
                }
            }
        });

        return {
            getCartData,
            checkIfCustomerExists,
            signInBeforeCheckout,
            goToCheckout
        };
    });
