import React, { useEffect } from 'react';
import {
    Col,
    Form,
    Input,
    notification,
    Popover,
    ProfileIcon,
    Row,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { VALIDATION_RULES } from '../../../../../../../../shared';
import { AvatarUpload } from '../../../../../../../../shared/components/AvatarUpload';
import NotificationContent from '../../../../../../../../shared/components/NotificationContent';
import { GRID_POINTS } from '../../../../../../../../utils';
import { AccountsPageFooter } from './components/AccountsPageFooter';
import { StyledButton, StyledForm } from './styles';

export const EditAccountForm = inject('store')(
    observer(({ store }) => {
        const { account: data, updateAccountData: onSubmit } = store.accountStore;
        const [form] = Form.useForm();
        const simple = useSimpleInterface(GRID_POINTS.md);

        const fields = ['firstName', 'lastName'];

        const isButtonDisabled = () => {
            return (
                !fields.map((field) => form.isFieldTouched(field)).some((touched) => touched) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length).length
            );
        };

        useEffect(() => {
            return () => (typeof notification.destroy === 'function' ? notification.destroy() : null);
        }, []);

        notification.config({
            placement: simple ? 'topRight' : 'bottomRight',
            bottom: simple ? null : '80px',
            top: simple ? '80px' : null
        });

        const submitted = (formData) => {
            if (typeof onSubmit !== 'undefined') {
                onSubmit(formData, form);
                notification.open({
                    duration: 3,
                    description: (
                        <NotificationContent.Info
                            rewardCount={0}
                            icon={<ProfileIcon />}
                            customContent={
                                <Row>
                                    <Typography.Title level={3}>Account Information Updated</Typography.Title>
                                    <Typography.Text type='descriptor2'>
                                        Your account information has been successfully updated. Thanks for keeping your
                                        details fresh!
                                    </Typography.Text>
                                </Row>
                            }
                        />
                    ),
                    bottom: simple ? '111px' : '8px'
                });
            }
        };

        return (
            <StyledForm initialValues={data} form={form} onFinish={submitted} validateTrigger={['onBlur']}>
                <Row gutter={[40, 24]}>
                    <Col lg={8} md={10} xs={24}>
                        <Form.Item noStyle>
                            <AvatarUpload justify='center' iconRemove listType='text' withCredentials />
                        </Form.Item>
                    </Col>
                    <Col lg={10} xs={24}>
                        <Form.Item noStyle name='firstName' rules={VALIDATION_RULES.REQUIRED}>
                            <Input size='large' label='First Name' autoComplete='off' />
                        </Form.Item>
                        <Form.Item noStyle name='lastName' rules={VALIDATION_RULES.REQUIRED}>
                            <Input size='large' label='Last Name' autoComplete='off' />
                        </Form.Item>
                        <Popover
                            content={<span style={{ whiteSpace: 'nowrap' }}>Email cannot be changed.</span>}
                            placement='top'
                            type='primary-black'
                            maxWidth='300px'
                        >
                            <Form.Item noStyle name='email' rules={VALIDATION_RULES.EMAIL}>
                                <Input
                                    size='large'
                                    showWarningIcon
                                    type='email'
                                    label='Email'
                                    autoComplete='off'
                                    disabled
                                    nonOpacityOnDisabled
                                />
                            </Form.Item>
                        </Popover>
                    </Col>
                </Row>

                <AccountsPageFooter>
                    <Form.Item noStyle shouldUpdate>
                        {() => (
                            <StyledButton type='primary' htmlType='submit' disabled={isButtonDisabled()}>
                                Save changes
                            </StyledButton>
                        )}
                    </Form.Item>
                </AccountsPageFooter>
            </StyledForm>
        );
    })
);
