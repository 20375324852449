import { Button, Icon } from '@klickly/front-packages';
import Lottie from 'lottie-react';
import styled, { css } from 'styled-components';
import { $primary } from '../../../../../../shared';

export const StyledWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    padding: ${({ $simple }) => ($simple ? '0' : '8px 68.5px')};
`;

export const StyledIconWrap = styled(Icon)`
    width: 120px;
    height: 120px;
    background-color: ${$primary};
    margin-bottom: 24px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledButton = styled(Button)`
    && {
        width: ${({ $simple }) => ($simple ? '100%' : '327px')};

        .anticon {
            margin-left: 8px;
        }
    }
`;

export const StyledAnimationBlock = styled(Lottie)`
    position: absolute;
    width: 800px;
    height: 800px;
    left: 50%;
    bottom: 80px;
    transform: translateX(-50%);

    ${({ $simple }) =>
        $simple
            ? css`
                  width: 100%;
                  height: 100%;
                  bottom: auto;
                  top: -175px;
              `
            : ''}
`;
