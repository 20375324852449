import styled from 'styled-components';
import { $primary } from '../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const StyledWrapper = styled.div`
    background-color: ${$primary};
    padding: 24px 48px 36px;
    margin-bottom: 80px;

    @media (${GRID_DEVICES_MAX.md}) {
        padding: 24px;
        width: calc(100% + 40px);
        margin-left: -20px;
    }
`;
