import React, { useEffect } from 'react';
import {
    DiamondIcon,
    FacebookIcon,
    Form,
    Icon,
    Input,
    InstagramIcon,
    TiktokIcon,
    Typography
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { getInviteCode } from '../../../../utils';
import { ButtonWrap, StyledButton, StyledForm, StyledIcon, StyledText } from './styles';

export const ConnectInstagramForm = inject('store')(
    observer(({ store }) => {
        const { pending, saveInviteCode, inviteCodeErrorMessage, instagramErrorMessage } = store.onboardingStore;
        const inviteCode = getInviteCode();
        const [form] = Form.useForm();

        const submitted = (data) => {
            if (typeof saveInviteCode !== 'undefined') {
                saveInviteCode(data);
            }
        };

        useEffect(() => {
            if (inviteCode) form.setFieldValue('inviteCode', inviteCode);
        }, [inviteCode]);

        return (
            <StyledForm form={form} onFinish={submitted} validateTrigger={['onBlur']}>
                <Form.Item
                    name='inviteCode'
                    validateStatus={inviteCodeErrorMessage && 'error'}
                    hasFeedback
                    help={inviteCodeErrorMessage}
                >
                    <Input
                        size='large'
                        showWarningIcon
                        label='Invite code (optional)'
                        autoComplete='on'
                        prefix={<StyledIcon component={() => <DiamondIcon />} />}
                    />
                </Form.Item>
                <StyledText type='body2' color='secondaryText'>
                    An invite code increases your chances of being accepted to Giftly.
                </StyledText>
                <br />
                <Form.Item
                    name={['instagram', 'username']}
                    rules={[
                        {
                            pattern: new RegExp(/^[a-zA-Z0-9_][a-zA-Z0-9_.]*$/),
                            message: 'Usernames can only use letters, numbers, underscores and periods'
                        }
                    ]}
                    validateStatus={instagramErrorMessage && 'error'}
                    hasFeedback
                    help={instagramErrorMessage}
                >
                    <Input
                        size='large'
                        showWarningIcon
                        label='Instagram username (optional)'
                        autoComplete='off'
                        prefix={<StyledIcon component={() => <InstagramIcon />} />}
                    />
                </Form.Item>
                <Form.Item
                    name={['facebook', 'username']}
                    rules={[
                        {
                            pattern: new RegExp(/^[a-zA-Z0-9_][a-zA-Z0-9_.]*$/),
                            message: 'Usernames can only use letters, numbers, underscores and periods'
                        }
                    ]}
                >
                    <Input
                        size='large'
                        showWarningIcon
                        label='Facebook username (optional)'
                        autoComplete='off'
                        prefix={<StyledIcon component={() => <FacebookIcon />} />}
                    />
                </Form.Item>
                <Form.Item
                    name={['tiktok', 'username']}
                    rules={[
                        {
                            pattern: new RegExp(/^[a-zA-Z0-9_][a-zA-Z0-9_.]*$/),
                            message: 'Usernames can only use letters, numbers, underscores and periods'
                        }
                    ]}
                >
                    <Input
                        size='large'
                        showWarningIcon
                        label='Tiktok username (optional)'
                        autoComplete='off'
                        prefix={<StyledIcon component={() => <TiktokIcon />} />}
                    />
                </Form.Item>
                <Typography.Text type='body2' color='secondaryText'>
                    We analyze your social profiles both for influence, but also to confirm you’re who you say you are.
                    We will never post to your account without your permission.
                </Typography.Text>
                <br />
                <br />
                <Form.Item noStyle shouldUpdate>
                    {() => (
                        <ButtonWrap>
                            <StyledButton
                                data-e2e='onboarding_submit-button'
                                htmlType='submit'
                                type='primary'
                                size='large'
                            >
                                <Typography.Text type='badgeLarge'>
                                    {Object.values(form.getFieldsValue(true)).some((value) =>
                                        typeof value === 'object' ? Object.values(value).some((v) => v) : value
                                    )
                                        ? 'Submit application'
                                        : 'Skip'}
                                </Typography.Text>
                            </StyledButton>
                        </ButtonWrap>
                    )}
                </Form.Item>
                {pending && <Icon.Loader />}
            </StyledForm>
        );
    })
);
