import { Image, KliCard } from '@klickly/front-packages';
import styled from 'styled-components';

export const Styles = styled(KliCard)`
    height: ${({ direction }) => (direction === 'column' ? '365px' : '180px')};
    width: ${({ direction }) => (direction === 'column' ? '205px' : '100%')};
    margin: ${({ direction }) => (direction === 'column' ? '0' : '20px 0')};
    padding: ${({ direction }) => (direction === 'column' ? '' : '0')};

    &:hover {
        box-shadow: ${({ direction }) => (direction === 'column' ? '' : 'none')};
    }
`;

export const PromotionImg = styled(Image)`
    height: 154px;
    width: auto;
    object-fit: contain;
`;
