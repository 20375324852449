import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    ForwardArrowIcon,
    HeartIcon,
    Icon,
    RIGHT_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { ROUTE_PATHS } from '../../../../../../shared';
import { GRID_POINTS } from '../../../../../../utils';
import { StyledButton, StyledIconWrap, StyledWrap } from './styled';

export const EmptyContent = () => {
    const navigate = useNavigate();
    const simple = useSimpleInterface(GRID_POINTS.sm);

    const onClick = () => {
        navigate(ROUTE_PATHS.shop);
    };

    return (
        <StyledWrap $simple={simple}>
            <StyledIconWrap>
                <Icon size='40px' component={() => <HeartIcon />} />
            </StyledIconWrap>
            <Typography.Title level={2} align='center' style={{ ...BOTTOM_INDENTS.M }}>
                No products in Wilshlist yet
            </Typography.Title>
            <Typography.Text type='body1' align='center' style={{ ...BOTTOM_INDENTS.XXL }}>
                You can add products to your wishlist to buy them later
            </Typography.Text>
            <StyledButton type='primary' size='large' block={simple} onClick={onClick}>
                <Typography.Text type='badgeLarge' style={{ ...RIGHT_INDENTS.M }}>
                    Go to Shop
                </Typography.Text>
                <Icon size='24px' component={() => <ForwardArrowIcon />} />
            </StyledButton>
        </StyledWrap>
    );
};
