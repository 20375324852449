import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../../../../../utils';

export const TaskInitialContentStyles = styled.div`
    width: 100%;
    padding: 40px;
    max-height: 510px;
    overflow-y: auto;

    @media (max-height: 800px) {
        max-height: 400px;
    }
    @media (max-height: 680px) {
        max-height: 300px;
    }
    @media (${GRID_DEVICES_MAX.md}) {
        padding: 24px;
        max-height: unset;
        overflow-y: inherit;
    }
`;
