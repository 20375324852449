import React from 'react';
import { Typography } from '@klickly/front-packages';
import { PromotionImg, Styles } from './styles';

export const Promotion = ({ promotion, action, direction = 'column', ...rest }) => {
    return (
        <Styles button={action} direction={direction} img={<PromotionImg src={promotion?.image} />} {...rest}>
            <Typography.Title level={3}>{promotion?.title}</Typography.Title>
            {promotion?.brandName && <Typography.Text type='body2'>{promotion?.brandName}</Typography.Text>}
        </Styles>
    );
};
