import { Button, Row } from '@klickly/front-packages';
import styled, { css } from 'styled-components';

export const StyledRow = styled(Row)`
    margin-bottom: 24px;

    ${({ $marginTop }) =>
        $marginTop &&
        css`
            margin-top: ${$marginTop};
        `}
    &:last-child {
        margin-bottom: 0;
    }
`;

export const StyledButton = styled(Button)`
    && {
        padding-left: 0;
    }
`;
