import React from 'react';
import { ROUTE_PATHS } from '../../../shared';
import AccountPage from '../components/account';
import BillingAddPage from '../components/billingAdd';
import BillingEditPage from '../components/billingEdit';
import ExtensionPage from '../components/extension';
import FavoriteCategories from '../components/FavoriteCategories';
import FeedbackPage from '../components/feedback';
import NotificationsPage from '../components/notifications';
import OrdersPage from '../components/orders';
import AccountSettingsPage from '../components/settings';
import ShippingAddPage from '../components/shippingAdd';
import ShippingBillingPage from '../components/shippingBilling';
import ShippingEditPage from '../components/shippingEdit';
import SubscriptionPage from '../components/subscription';
import Wishlist from '../components/Wishlist';

export const ROUTES = [
    {
        path: ROUTE_PATHS.settings,
        index: true,
        element: <AccountSettingsPage />
    },
    {
        path: ROUTE_PATHS.account,
        element: <AccountPage />
    },
    {
        path: ROUTE_PATHS.orders,
        element: <OrdersPage />,
        children: OrdersPage.ROUTES
    },
    {
        path: ROUTE_PATHS.subscriptions,
        element: <SubscriptionPage />
    },
    {
        path: ROUTE_PATHS.shippingBilling,
        index: true,
        element: <ShippingBillingPage />
    },
    {
        path: ROUTE_PATHS.favoriteCategories,
        index: true,
        element: <FavoriteCategories />
    },
    {
        path: ROUTE_PATHS.shippingAdd,
        index: true,
        element: <ShippingAddPage />
    },
    {
        path: ROUTE_PATHS.shippingEdit,
        index: true,
        element: <ShippingEditPage />
    },
    {
        path: ROUTE_PATHS.billingAdd,
        index: true,
        element: <BillingAddPage />
    },
    {
        path: ROUTE_PATHS.billingEdit,
        index: true,
        element: <BillingEditPage />
    },
    {
        path: ROUTE_PATHS.notifications,
        index: true,
        element: <NotificationsPage />
    },
    {
        path: ROUTE_PATHS.extensionSettings,
        index: true,
        element: <ExtensionPage />
    },
    {
        path: ROUTE_PATHS.sendFeedback,
        index: true,
        element: <FeedbackPage />
    },
    {
        path: ROUTE_PATHS.wishlist,
        index: true,
        element: <Wishlist />
    }
];
