import React from 'react';
import { BOTTOM_INDENTS, Typography } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { SignInForm } from '../../../../shared/components/SignInForm';
import { AuthHeader } from '../AuthHeader';
import { SignInContentStyles, Styles } from './styles';

export const SignIn = observer(() => {
    return (
        <Styles>
            <AuthHeader />
            <SignInContentStyles>
                <Typography.Title style={BOTTOM_INDENTS.XL} data-e2e='auth_signIn-title'>
                    Sign in to Giftly
                </Typography.Title>
                <SignInForm />
            </SignInContentStyles>
        </Styles>
    );
});

export default SignIn;
