import React, { useEffect } from 'react';
import { GRID_POINTS, Icon, Promotion, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import placeholder from '../../../../../../assets/img/image-placeholder.svg';
import { strategies } from '../../../../../../config/strategies';
import { LikeButton } from '../LikeButton';
import { StyledLoaderWrapper, StyledPromotionWrap, StyledWrap } from './styles';

export const Promotions = inject('store')(
    observer(({ store, onBuy, selectedPromotionIds, onSelectIconClick, hidePrice = false }) => {
        const { promotions, getPromotions, pending } = store.marketplaceStore;
        const twoRows = useSimpleInterface(1025);
        const simple = useSimpleInterface(GRID_POINTS.sm);

        useEffect(() => {
            if (!pending) {
                const queryParams = { limit: 50 /*, categories: categories */ };
                const strategy = strategies.onboardingStrategy;
                if (strategy) queryParams.strategyId = strategy;
                getPromotions(false, queryParams);
            }
        }, []);

        return pending ? (
            <StyledLoaderWrapper>
                <Icon.Loader />
            </StyledLoaderWrapper>
        ) : (
            <StyledWrap $twoRows={twoRows} $simple={simple}>
                {promotions
                    ? promotions.map((promotion) => {
                          const selected = selectedPromotionIds.includes(promotion._id);
                          return (
                              <StyledPromotionWrap key={promotion._id}>
                                  <LikeButton
                                      selected={selected}
                                      onClick={() => onSelectIconClick(promotion, selected)}
                                  />
                                  <Promotion
                                      placeholder={placeholder}
                                      hidePrice={hidePrice}
                                      withoutButton
                                      onBuy={() => onBuy(promotion)}
                                      promotion={promotion}
                                  />
                              </StyledPromotionWrap>
                          );
                      })
                    : null}
            </StyledWrap>
        );
    })
);
