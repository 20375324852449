import styled, { css } from 'styled-components';

export const StyledWrapper = styled.div`
    max-width: 630px;
    ${({ $from }) =>
        $from === 'onboarding' &&
        css`
            margin: 0 auto;
        `};
`;
