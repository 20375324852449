import React from 'react';
import { CardIcon } from '../../../../../icons';
import { CardsIconByBrand } from '../StripeContext';
import { StyledIcon } from './styles';

export const StripeCardIcon = ({ brand = '', ...rest }) => {
    return (
        <React.Fragment>
            {CardsIconByBrand[brand.toLowerCase()] ? (
                <StyledIcon size='28px' {...rest} component={() => CardsIconByBrand[brand.toLowerCase()]} />
            ) : (
                <StyledIcon size='22px' color='secondaryText' {...rest} component={() => <CardIcon />} />
            )}
        </React.Fragment>
    );
};
