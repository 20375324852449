import { getParent, types } from 'mobx-state-tree';

const DEFAULT_CHROME_EXTENSION_ID = 'dljhoakkgdiibemlmihjoicjgkahgenb';

export const ChromeExtensionStore = types
    .model('ChromeExtensionStore', {
        chromeExtensionId: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const init = (chromeExtensionId) => {
            const id = new URLSearchParams(window.location.search).get('chromeExtensionId');
            if (id || chromeExtensionId) {
                localStorage.setItem('chromeExtensionId', id || chromeExtensionId);
            }
            self.chromeExtensionId = localStorage.getItem('chromeExtensionId') || DEFAULT_CHROME_EXTENSION_ID;
        };

        const sendMessage = (message) => {
            const extensionInstalled = self.root.accountStore.extensionInstalled;
            return new Promise((resolve) => {
                if (!self.chromeExtensionId || !extensionInstalled) {
                    resolve({});
                }

                try {
                    // eslint-disable-next-line no-undef
                    chrome.runtime.sendMessage(self.chromeExtensionId, message, (data) => {
                        resolve(data || {});
                    });
                } catch (error) {
                    resolve({});
                }
            });
        };

        const toggleExtensionPanelMute = () => {
            self.root.accountStore.toggleExtensionPanelMute();
        };

        return {
            init,
            sendMessage,
            toggleExtensionPanelMute
        };
    });
