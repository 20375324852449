import { getParent, types } from 'mobx-state-tree';

export const OnboardingPopoverStore = types
    .model('OnboardingPopoverStore', {
        showPopoverOnLaunchGsButton: false,
        showPopoverOnCart: false,
        showPopoverOnLinkToGs: false,
        showPopoverOnLinkToGsManuallyClosed: false
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const toggleShowPopoverOnLaunchGsButton = (value = false) => {
            self.showPopoverOnLaunchGsButton = value;
        };

        const toggleShowPopoverOnLinkToGsManuallyClosed = (value = false) => {
            self.showPopoverOnLinkToGsManuallyClosed = value;
        };

        const toggleShowPopoverOnCart = (value = false) => {
            self.showPopoverOnCart = value;
        };

        const toggleShowPopoverOnLinkToGs = (value = false) => {
            self.showPopoverOnLinkToGs = value;
        };

        const onClosePopoverOnLaunchButton = () => {
            self.showPopoverOnLaunchGsButton = false;
        };

        return {
            toggleShowPopoverOnLaunchGsButton,
            onClosePopoverOnLaunchButton,
            toggleShowPopoverOnCart,
            toggleShowPopoverOnLinkToGs,
            toggleShowPopoverOnLinkToGsManuallyClosed
        };
    });
