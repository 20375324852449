import { Image } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
`;

export const StyledContent = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 414px);
    grid-column-gap: 20px;
    grid-row-gap: 32px;

    @media (max-width: 1320px) {
        grid-template-columns: repeat(2, 414px);
    }

    @media (max-width: 880px) {
        grid-template-columns: repeat(1, 414px);
        grid-row-gap: 14px;
    }

    @media (max-width: 450px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const StyledBlockWrap = styled.div`
    position: relative;
    width: 414px;
    height: 464px;

    @media (max-width: 450px) {
        max-width: 343px;
        width: 100%;
        height: 388px;
    }
`;

export const StyledBlockImg = styled(Image)`
    width: 100%;
    height: 100%;
    object-fit: contain;

    @media (max-width: 340px) {
        object-fit: cover;
    }
`;

export const StyledBlockContent = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 32px 24px;

    @media (max-width: 450px) {
        padding: 32px 16px;
    }
`;

export const StyledBlockTextWrap = styled.div`
    max-width: 65%;

    @media (max-width: 450px) {
        max-width: 50%;
    }
`;
