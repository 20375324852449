import React from 'react';
import { Col, Typography } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { $secondaryText } from '../../../../constants';

const FooterItem = observer(({ to, text }) => {
    const content = (
        <Typography.Text type='badgeSmall' color={$secondaryText}>
            {text}
        </Typography.Text>
    );
    return (
        <Col>
            {to ? (
                <a href={to} target='_blank' rel='noreferrer'>
                    {content}
                </a>
            ) : (
                content
            )}
        </Col>
    );
});

export default FooterItem;
