import { Icon } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledCloseModalIcon = styled(Icon)`
    position: absolute;
    bottom: 100%;
    margin-bottom: 30px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
`;
