import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    Col,
    formatPoints,
    Icon,
    InfoIcon,
    PointsIcon,
    RIGHT_INDENTS,
    roundToLocaleString,
    Row,
    Typography
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../../../../../shared';
import { CouponWrapper } from '../../../../../../../../../../shared/components/CouponWrapper';
import { get } from '../../../../../../../../../../utils';
import { StyledButton, StyledContent, StyledIcon, StyledInfoButton, StyledWrapper } from './styled';

const StrategyBanner = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { points } = store.claimsPointsStore;
        const { strategyInfo } = store.marketplaceStore;

        const strategyName = get(strategyInfo, 'name', '');

        const onClickStrategyBlock = () => {
            store.trackingStore.sendEvent({
                type: 'event',
                name: 'click',
                data: { element: { name: 'GSIncentiveNew' } }
            });
            navigate({
                pathname: ROUTE_PATHS.shop,
                search: `?strategy=${strategyName}`
            });
        };

        const onClickPointsInfo = () => {
            navigate(ROUTE_PATHS.pointsInfo);
        };

        return (
            <StyledWrapper>
                <CouponWrapper>
                    <StyledContent>
                        <Row gutter={16} wrap={false} style={BOTTOM_INDENTS.S}>
                            <Col>
                                <StyledIcon size='24px' color='canvas' component={() => <PointsIcon />} />
                            </Col>
                            <Col>
                                <Typography.Title level={3} color='primary'>
                                    You&apos;ve got {formatPoints(points || 0)} points worth $
                                    {roundToLocaleString(points / 100)} to spend
                                </Typography.Title>
                            </Col>
                        </Row>
                        <Row style={BOTTOM_INDENTS.S}>
                            <Col>
                                <Typography.Text type='body2' color='secondaryText'>
                                    See something you like? Use your Giftly reward points to save even more.
                                </Typography.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <StyledButton type='secondary' block onClick={onClickStrategyBlock}>
                                    <Typography.Text type='badgeSmall' color='primary'>
                                        Explore Deals
                                    </Typography.Text>
                                </StyledButton>
                            </Col>
                            <Col span={12}>
                                <StyledInfoButton color='primary' type='unstyled' onClick={onClickPointsInfo}>
                                    <Icon
                                        size='16px'
                                        color='primary'
                                        component={() => <InfoIcon />}
                                        style={RIGHT_INDENTS.XXS}
                                    />
                                    <Typography.Text type='badgeSmall' color='primary'>
                                        What are Points?
                                    </Typography.Text>
                                </StyledInfoButton>
                            </Col>
                        </Row>
                    </StyledContent>
                </CouponWrapper>
            </StyledWrapper>
        );
    })
);

export default StrategyBanner;
