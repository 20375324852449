import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../utils';
import { $background } from '../../constants';

export const StyledFooter = styled.div`
    flex-basis: 100%;
    background: ${$background};
`;

export const StyledFooterContent = styled.div`
    max-width: 1400px;
    margin: 0 auto;
    padding: 32px 20px;

    @media (${GRID_DEVICES_MAX.md}) {
        padding: 24px 20px;
    }
`;
