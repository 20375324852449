import { device } from '@klickly/front-packages';
import { createGlobalStyle, css } from 'styled-components';
import { $background, $border, $primary, $primaryText, $secondaryText, $white } from './shared';
import { GRID_DEVICES_MAX } from './utils';

const isIos = device.iOS();
const isSafari = device.isSafari();

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;

        box-sizing: border-box;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        -webkit-tap-highlight-color: transparent;
        -webkit-backface-visibility: hidden;
    }

    html,
    body,
    #root {
        position: relative;
        overflow: hidden;
        min-width: 320px;
        background: ${$white};
        height: 100%;

        ${() =>
            isSafari && isIos
                ? css`
                      min-height: -webkit-fill-available;
                  `
                : ''}
    }

    html,
    body {
        overflow: hidden;
    }

    #root {
        overflow-y: auto;
    }

    body {
        margin: 0;
        font-family: 'IBM Plex Sans', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #fff;
        color: ${$primaryText};
        font-size: 14px;
        line-height: 1.5715;

        > iframe {
            position: absolute !important;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
        
        &:hover {
            color: inherit;
        }
    }

    /* Google autocomplete styles */
    .klickly-autocomplete-container {
        width: 100%;
        border: 2px solid #04080F;
        outline: 4px solid #D2D6DA;
        box-sizing: border-box;
        list-style: none;
        position: absolute;
        top: 65px;
        z-index: 10;
        background-color: #ffffff;
    }

    .klickly-autocomplete-container:empty {
        border: 2px solid transparent;
        outline: none;
    }

    .klickly-autocomplete-item {
        padding: 8px 16px;
        font: normal normal 14px/24px 'IBM Plex Sans', sans;
        color: #04080F;
        cursor: pointer;
    }

    .klickly-autocomplete-item:hover {
        background-color: rgba(119, 131, 143, 0.1);
    }

    .klickly-autocomplete-item:not(:last-child) {
        border-bottom: 1px solid #e6e6e6;
    }
    
    #app-wrapper {
        .ant-menu-submenu-popup.kli-main-menu-dropdown {
            &.kli-shop-dropdown {
                top: 160px !important;
                position: fixed;
                width: 100vw;
                left: 0 !important;
                padding-top: 0;

                & > ul.ant-menu-vertical {
                    margin-left: 0;
                }

                & > ul > li {
                    height: initial !important;

                    &:hover {
                        background-color: initial;

                        .anticon {
                            svg {
                                color: rgb(242, 88, 88);
                            }
                        }
                    }
                }

                .ant-menu-item {
                    margin: 0;
                    padding: 0;
                    height: auto;
                    cursor: default;

                    &:hover {
                        * {
                            color: inherit;
                            transition: none;
                        }
                    }

                    .ant-menu-title-content {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        ul.ant-menu.ant-menu-sub.ant-menu-vertical {
            margin-left: -54px;
            background: ${$background};
            border-bottom: 1px solid #f0f0f0;
        }

        .ant-menu-item,
        .ant-menu-submenu,
        .ant-menu-submenu-active {
            &:active,
            &.ant-menu-item-selected {
                background: ${$background};
            }

            &:before {
                display: none;
            }

            &:after {
                right: auto;
                bottom: 4px;
                width: 24px;
            }

            &:hover,
            &.ant-menu-item-active:hover,
            &.ant-menu-item-selected:hover {
                color: ${$primaryText};
            }

            &:has(.active),
            &.ant-menu-item-selected:hover,
            &.ant-menu-item-active:hover {
                &:after {
                    border-bottom: 4px solid ${$primary};
                }
            }
        }

        &.ant-menu-vertical {
            background: ${$white};

            .ant-menu-item {
                height: 80px;
                margin-bottom: 0;

                &:active,
                &.ant-menu-item-selected {
                    background: ${$white};
                }

                &.ant-menu-item-only-child {
                    height: auto;
                    margin-bottom: 0;
                    padding: 8px 0 8px 20px;

                    .ant-menu-title-content {
                        width: 100%;
                    }
                }

                .active:before {
                    top: auto;
                    left: 20px;
                    bottom: 20px;
                    width: 21.5px;
                    height: 4px;
                    background: ${$primary};
                }

                .ant-menu-title-content {
                    width: 100%;
                    margin-left: 12px;

                    a {
                        color: ${$primaryText};
                    }
                }
            }

            .kli-non-menu-item-mobile {
                .ant-menu-title-content {
                    margin-left: 0;
                }
            }
        }

        &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover:after,
        &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after {
            border-bottom: 4px solid ${$primary};
        }

        .ant-menu-submenu-title {
            display: flex;
            align-items: center;
            color: ${$secondaryText};

            &:hover {
                color: ${$primaryText};
            }

            > svg {
                width: 24px;
                height: 24px;
            }
        }

        .ant-menu-submenu {
            .ant-menu-item a:before {
                display: none;
            }
            .ant-menu-title-content {
                .anticon {
                    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
                        color cubic-bezier(0.645, 0.045, 0.355, 1);
                    transform: rotate(0deg);
                }
            }

            &.ant-menu-submenu-active,
            &.ant-menu-submenu-open,
            &:has(.active) {
                .ant-menu-submenu-title {
                    color: ${$primaryText};
                }

                svg {
                    color: ${$primaryText};
                }
            }

            &.ant-menu-submenu-active,
            &.ant-menu-submenu-open {
                color: ${$primaryText};

                .ant-menu-title-content {
                    .anticon {
                        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
                            color cubic-bezier(0.645, 0.045, 0.355, 1);
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open:after {
            border-bottom: 4px solid ${$primary};
        }

        .ant-menu-submenu-horizontal {
            padding: 0 20px;
        }

        &.ant-menu-horizontal .ant-menu-item,
        .ant-menu-submenu-horizontal {
            &:after {
                inset-inline: 20px;
            }
            .ant-menu-title-content {
                span,
                div {
                    font-family: 'IBM Plex Sans', sans-serif;
                }
            }
        }

        a:hover {
            color: ${$primaryText};
        }

        .ant-menu-title-content {
            a,
            button {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                font-family: 'IBM Plex Sans', sans-serif;
                position: relative;

                @media (${GRID_DEVICES_MAX.md}) {
                    position: initial;
                }

                div {
                    color: ${$secondaryText};
                    font-family: 'IBM Plex Sans', sans-serif;

                    @media (${GRID_DEVICES_MAX.md}) {
                        font-family: 'IBM Plex Sans', sans-serif;
                        color: ${$primaryText};
                    }

                    &:hover {
                        color: ${$primaryText};
                    }
                }
            }
            * {
                font-weight: 500;
            }
        }

        .ant-menu-submenu-popup {
            &:after {
                display: none;
            }

            .ant-menu {
                background: ${$background};
                box-shadow: none;
            }

            .ant-menu-item {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 32px;
                padding: 0 48px;
                line-height: 32px;

                &:hover {
                    background-color: initial;

                    * {
                        color: ${$primaryText};
                    }

                    .ant-btn > div {
                        border-bottom: 4px solid ${$primary};
                    }
                }

                &.kli-non-menu-item {
                    cursor: default;
                    &:hover {
                        * {
                            color: ${$secondaryText};
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 24px;
                }

                a {
                    position: relative;
                    display: block;
                    height: 100%;
                    padding: 0;
                    color: ${$secondaryText};
                    &:after {
                        content: '';
                        position: absolute;
                        right: 0;
                        bottom: -3px;
                        left: 0;
                        width: 100%;
                        border-bottom: 4px solid transparent;
                        transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    }
                }

                > svg {
                    margin-left: 8px;
                    color: ${$secondaryText};
                }

                .ant-menu-title-content {
                    margin-left: 2px;
                }

                &.ant-menu-item-selected {
                    background: transparent;
                }

                .active {
                    color: ${$primaryText};
                    &:after {
                        border-bottom: 4px solid ${$primary};
                        transform: none;
                        opacity: 1;
                    }
                }

                &.ant-menu-item-active,
                &.ant-menu-item-selected,
                &:has(.active) {
                    a {
                        &:after {
                            border-bottom: 4px solid ${$primary};
                            transform: none;
                            opacity: 1;
                        }
                    }
                    svg {
                        color: ${$primaryText};
                    }
                }

                &.kli-non-menu-item {
                    height: auto;
                    line-height: unset;
                    .ant-menu-title-content {
                        width: 100%;
                        margin-left: 0;
                        line-height: unset;
                    }
                }
            }

            .ant-menu-vertical .ant-menu-item:after {
                display: none;
            }

            &.kli-main-menu-dropdown {
                //left: -50% !important;
            }
        }
    }

    .ant-notification .ant-notification-notice .ant-notification-notice-close {
        top: 50%;
        inset-inline-end: 12px;
    }
    
    .crop-modal {
        
      .ant-modal {
          @media (${GRID_DEVICES_MAX.md}) {
              top: 0;
              padding: 0;
              max-width: 100%;
          }
          
          .ant-modal-body {
              @media (${GRID_DEVICES_MAX.md}) {
                  height: calc(100dvh - 56px);
              }
          }
      }

      .ant-modal-content {
        background: #090909;
        padding: 56px 0 0;

          @media (${GRID_DEVICES_MAX.md}) {
              border-radius: 0;
          }

        .ant-modal-title {
          background: #090909;
        }

        .ant-modal-close {
          display: none;
        }

        .img-crop-control {
          height: 56px;
          margin: 0 auto;
          width: 66%;

            @media (${GRID_DEVICES_MAX.md}) {
                width: calc(100% - 48px);
                margin: 0 24px;
            }

          & > button {
            width: 16px;
            height: 16px;
            color: ${$border};
            line-height: 1;
            opacity: 1;
          }
        }

        .ant-modal-header {
          display: none;
        }

        .ant-modal-footer {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          padding: 0 24px;
          width: 100%;
          justify-content: space-between;
          margin-top: 8px;

          button {
            &:first-of-type {
              border: 0;
              background-color: transparent;
              box-shadow: none;
              color: ${$white};
              padding: 0;

              svg {
                width: 16px;
                height: 16px;
              }

              & > span {
                display: flex;
                gap: 12px;
                align-items: center;
              }
            }

            &:last-of-type {
              border: 2px solid ${$background};
              padding: 8px 20px;
              background: transparent;
              border-radius: 0;
              height: 40px;
            }
          }
        }

        .ant-slider {
          height: 32px;
          padding: 0;
          padding-block: 0;
          display: flex;
          align-items: center;
          margin: 0 16px;
          justify-content: center;

          .ant-slider-handle {
            height: 32px;
            width: 32px;
            inset-block-start: 0;

            &:before,
            &:after {
              border: 3px solid;
              height: 32px;
              width: 32px;
              box-shadow: none;
              inset-inline-start: 0;
              inset-block-start: 0;
              border-radius: 50%;
            }
          }

          .ant-slider-rail,
          .ant-slider-track {
            height: 12px;
            background: #e8e8e8;
            border-radius: 40px;
          }

          .ant-slider-rail {
            background: ${$secondaryText};
          }
        }
          
        .reactEasyCrop_Container {
            @media (${GRID_DEVICES_MAX.md}) {
                height: calc(100% - 56px);
            }
        }

        .reactEasyCrop_CropArea {
          color: rgba(9, 9, 9, 0.7);
          border: 4px solid ${$primary};
        }
      }
    }
`;
