import styled from 'styled-components';
import { Animated } from '../../../../shared/components/Animated';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const RewardsPageStyles = styled(Animated)`
    height: 100%;
    width: 100%;
    max-width: 1085px;
    margin: 0 auto;
    overflow-y: auto;
    overflow-x: hidden;

    @media (${GRID_DEVICES_MAX.md}) {
        max-width: 100%;
    }
`;
