import React, { Fragment, useEffect, useState } from 'react';
import { ArrowChevronDownIcon, Icon, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { Animated } from '../../../../../../shared/components/Animated';
import { GRID_POINTS } from '../../../../../../utils';
import Coupon from './components/Coupon';
import CouponButtons from './components/CouponButtons';
import NoCoupons from './components/NoCoupons';
import { StyledCouponList, StyledSeeMoreBtn, StyledWrapper } from './styled';

const Coupons = inject('store')(
    observer(({ store }) => {
        const [showSeeMoreBtn, setShowSeeMoreBtn] = useState(true);
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { getRewards, rewards, pending, updateRewardPending, hasNextPage } = store.rewardsStore;
        const { trackViewPromotions } = store.trackingStore;

        useEffect(() => {
            getRewards();
        }, []);

        useEffect(() => {
            const promotions = rewards
                ?.filter((reward) => reward.promotions?.[0]?.variants)
                .map((reward) => reward.promotions[0]);
            if (promotions?.length) trackViewPromotions(promotions);
        }, [rewards?.length]);

        return (
            <StyledWrapper>
                {rewards && !pending && (
                    <Fragment>
                        <Animated>
                            <StyledCouponList>
                                {rewards.map((promotion) => (
                                    <Coupon reward={promotion} key={promotion._id} />
                                ))}
                            </StyledCouponList>
                        </Animated>
                        {showSeeMoreBtn && hasNextPage && (
                            <StyledSeeMoreBtn
                                type='text'
                                onClick={() => {
                                    setShowSeeMoreBtn(false);
                                    return getRewards(0);
                                }}
                            >
                                <Typography.Text type='badgeMedium'>See more</Typography.Text>
                                <Icon stylePriority size='24px' component={() => <ArrowChevronDownIcon />} />
                            </StyledSeeMoreBtn>
                        )}
                        {simple && <CouponButtons />}
                    </Fragment>
                )}
                {!rewards?.length && !pending ? <NoCoupons /> : null}
                {pending || updateRewardPending ? <Icon.Loader /> : null}
            </StyledWrapper>
        );
    })
);

export default Coupons;
