import { Drawer } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledDrawer = styled(Drawer)`
    position: absolute;

    .ant-drawer-header {
        padding: 32px 2px 0;
        border-bottom: none;
    }

    &.ant-drawer-left > .ant-drawer-content-wrapper {
        box-shadow: none;
    }

    .ant-drawer-title > .ant-row {
        align-items: baseline;
    }
`;
