import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI } from '../shared';
import { models } from './constants';

export const SuitePromotionsStore = types
    .model('SuitePromotionsStore', {
        data: types.optional(types.array(models.SuiteCouponModel), []),
        selectedCoupon: types.maybeNull(models.SuiteCouponModel),
        isPageReloaded: true,
        pending: false,
        loaded: false,
        errorMessage: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get promotions() {
            return self.data && self.data.length ? self.data : [];
        },
        get promotionsLength() {
            return self.promotions.length;
        }
    }))
    .actions((self) => {
        const setPending = (pending) => {
            self.pending = pending;
        };

        const setIsPageReloaded = (state) => {
            self.isPageReloaded = state;
        };

        const setError = (error) => {
            if (error && error.response && error.response.data) {
                self.errorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.errorMessage = error.message;
            }
        };

        const getSuiteCoupons = flow(function* getSuiteCoupons(onAfterSuccess = () => null) {
            setPending(true);
            try {
                const { customerId } = self.root.accountStore;
                const { suiteId } = self.root.suiteStore;
                if (customerId && suiteId) {
                    const data = yield self.root.get(kcpAPI.giftingSuite.getCoupons(suiteId));
                    self.data = data;

                    const promotions = data?.map((suite) => suite.promotion);
                    self.root.trackingStore.trackViewPromotions(promotions);
                }
                onAfterSuccess();
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
            }
        });

        const getCouponDetails = flow(function* getCouponDetails(couponId) {
            self.selectedCouponPending = true;
            try {
                const { customerId } = self.root.accountStore;
                if (customerId) {
                    self.selectedCoupon = yield self.root.get(kcpAPI.giftingSuite.getCouponDetails(couponId));
                }
            } catch (e) {
                setError(e);
            } finally {
                self.selectedCouponPending = false;
            }
        });

        return {
            setIsPageReloaded,
            getSuiteCoupons,
            getCouponDetails
        };
    });
