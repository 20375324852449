import React from 'react';
import { ArrowChevronForwardIcon, Col, GiftIcon, Icon, Row, Typography } from '@klickly/front-packages';
import { ROUTE_PATHS } from '../../../../../../../../constants';
import { StyledLink } from './styled';

const GsOpenedBadge = () => {
    return (
        <StyledLink to={ROUTE_PATHS.suiteGs}>
            <Row align='middle' justify='space-between'>
                <Col>
                    <Row gutter={16} align='middle'>
                        <Col>
                            <Icon size='24px' color='primary' component={() => <GiftIcon />} />
                        </Col>
                        <Col>
                            <Typography.Title level={3} color='primary'>
                                Your Gifting Suite is ready <br /> to launch!
                            </Typography.Title>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Icon size='16px' color='primary' component={() => <ArrowChevronForwardIcon />} />
                </Col>
            </Row>
        </StyledLink>
    );
};

export default GsOpenedBadge;
