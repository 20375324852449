import React from 'react';
import { Typography, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { useApplyClick } from '../../../../hooks';
import { $white } from '../../../../shared';
import { Logo } from '../../../../shared/components/Logo';
import { Divider } from '../Divider';
import { GiftPointsImg, StyledButton, StyledHead, StyledLogoWrap, StyledUppercaseText, StyledWrap } from './styles';

export const ApplyNowSection = observer(() => {
    const simple = useSimpleInterface(650);
    const onApplyClick = useApplyClick();

    return (
        <StyledWrap>
            <StyledHead $simple={simple}>
                <StyledLogoWrap $simple={simple}>
                    <Logo size='32px' color={$white} />
                    <StyledUppercaseText type='body2' color='canvas' align='center'>
                        Membership
                    </StyledUppercaseText>
                </StyledLogoWrap>
                {simple && <GiftPointsImg simple marginBottom={simple ? '30px' : 0} />}
                <Typography.Text as='div' type='body1' color='canvas' align='center' style={{ padding: '0 16px' }}>
                    Join us! Get onboarding{' '}
                    <Typography.Text as='span' type='body1' color='canvas' style={{ fontWeight: 600 }}>
                        Gifting Suite worth $20!
                    </Typography.Text>
                </Typography.Text>
                {!simple && <GiftPointsImg />}
            </StyledHead>
            <Divider color={$white} />
            <StyledButton size='large' type='primary' onClick={onApplyClick}>
                <Typography.Text type='badgeLarge'>Apply for access</Typography.Text>
            </StyledButton>
        </StyledWrap>
    );
});
