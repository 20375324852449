import { Button, Icon } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $background, $primary } from '../../index';
import { Link } from '../Link';

export const StyledMenuLink = styled(Link)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 20px;
    transition: all 0.3s ease-in-out;
    width: 240px;

    &:hover {
        color: ${$primary};
        cursor: pointer;
        background: ${$background};
    }

    ${({ $hidden }) =>
        $hidden
            ? css`
                  display: none;
              `
            : ''}
`;

export const StyledMenuButton = styled(Button)`
    && {
        justify-content: flex-start;
        padding: 15px 20px;
        transition: all 0.3s ease-in-out;
        width: 240px;

        &:hover {
            color: ${$primary};
            cursor: pointer;
            background: ${$background};
        }

        ${({ $hidden }) =>
            $hidden
                ? css`
                      display: none;
                  `
                : ''}
    }
`;

export const StyledIcon = styled(Icon)`
    margin-right: 12px;
`;

export const StyledNoStyleButton = styled(Button)`
    && {
        padding: 0;
        font: inherit;
        color: inherit;
        border: none;
        transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

        *,
        &.ant-btn span:not(.kli-icon):not(.kli-text),
        &.ant-btn span:not(.kli-icon):not(.kli-text):focus {
            font: inherit;
            color: inherit;
        }

        > div {
            line-height: 24px;
            padding: 0 8px;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        &:hover {
            border: none;
            > div {
                border-bottom: 4px solid ${$primary};
            }
        }
    }
`;

export const StyledMenuLinkTitle = styled.div`
    line-height: 32px;
    padding: 0 8px;
`;
