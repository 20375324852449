import React from 'react';
import { CircleActionsAlertQuestionIcon, Icon, roundToLocaleString, Typography } from '@klickly/front-packages';
import { $secondaryText, useModal } from '../../../../../../../../../../shared';
import RewardsModalContent from '../../../../../../../../../../shared/components/RewardsModalContent';
import { getPrices } from '../../../../../../utils';
import {
    CardBody,
    CardBodyLeft,
    Info,
    ProductTitle,
    StyledImage,
    StyledInfoRow,
    StyledPrice,
    StyledQuestionButton,
    StyledVariantTitle,
    Styles
} from './styles';

export const ProductCard = ({ item, cartData, simple }) => {
    const priceDetails = item.priceDetails;
    const { fullTotal, originalSubTotal } = getPrices({ ...priceDetails });
    const { openModal, closeModal } = useModal();

    let rewardsData = item.metadata.find((metaItem) => metaItem.name === 'rewardsDetails')?.value || {};

    // filter only for available discounts
    if (rewardsData && rewardsData.rewards && rewardsData.rewards.length) {
        const updatedRewards = rewardsData.rewards.filter((reward) => {
            if (reward.title === 'Price drop') return true;

            const { discounts } = cartData;
            return discounts && discounts.length
                ? discounts.some((discount) => {
                      const discountMetadata = discount?.metadata || [];
                      return discountMetadata.some((metadata) => metadata?.value?.uuid === reward.rewardUUID);
                  })
                : false;
        });
        rewardsData = { ...rewardsData, rewards: updatedRewards };
    }

    const rewardPriceDetails = rewardsData?.priceDetails;

    return (
        <Styles simple={simple}>
            <CardBody>
                <CardBodyLeft>
                    <StyledImage src={item.image} alt={item.productTitle} />
                    <Info>
                        <ProductTitle level={3}>{item.productTitle}</ProductTitle>
                        <StyledVariantTitle type='descriptor2' color={$secondaryText}>
                            {item.variantTitle.replace('Default Title', '')}
                        </StyledVariantTitle>
                    </Info>
                </CardBodyLeft>
                <StyledPrice type='body2' color='secondaryText'>
                    {fullTotal}
                </StyledPrice>
            </CardBody>
            <StyledInfoRow>
                <Typography.Text type='body2' color='secondaryText'>
                    Number of items
                </Typography.Text>
                <Typography.Text type='body2' color='secondaryText'>
                    {priceDetails.qty}
                </Typography.Text>
            </StyledInfoRow>
            <StyledInfoRow>
                <Typography.Text type='body2' color='secondaryText'>
                    Original price
                </Typography.Text>
                <Typography.Text type='body2' color='secondaryText'>
                    {originalSubTotal}
                </Typography.Text>
            </StyledInfoRow>
            {rewardsData?.rewards?.length ? (
                <StyledInfoRow>
                    <StyledInfoRow $justify='flex-start'>
                        <Typography.Text type='body2' color='secondaryText'>
                            {rewardsData?.rewards?.length || 0} Reward applied
                        </Typography.Text>
                        <StyledQuestionButton
                            type='unstyled'
                            onClick={() =>
                                openModal({
                                    title: 'Rewards applied',
                                    children: <RewardsModalContent variant={rewardsData} onCloseModal={closeModal} />
                                })
                            }
                        >
                            <Icon component={() => <CircleActionsAlertQuestionIcon />} />
                        </StyledQuestionButton>
                    </StyledInfoRow>
                    {rewardPriceDetails && (
                        <Typography.Text type='body2' color='secondaryText'>
                            –$
                            {roundToLocaleString(rewardPriceDetails.rewardsPrice * priceDetails.qty)} (
                            {rewardPriceDetails.rewardsPercent}% off)
                        </Typography.Text>
                    )}
                </StyledInfoRow>
            ) : null}
        </Styles>
    );
};
