import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MIN } from '../../../../utils';

export const Wrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;

    @media (${GRID_DEVICES_MIN.md}) {
        flex-direction: row;
    }
`;

export const WrapItem = styled.div`
    padding: 20px 20px 0;

    img {
        width: 100%;
        height: auto;
    }

    @media (${GRID_DEVICES_MIN.md}) {
        width: 50%;
    }
`;

export const PriceItem = styled(Typography.Text)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Bottom = styled.div`
    padding: 20px;
`;

export const Description = styled.div`
    @media (${GRID_DEVICES_MIN.md}) {
        padding-right: 20px;
        margin-right: -20px;
        max-height: 40vh;
        overflow: auto;
    }
`;
