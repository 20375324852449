import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Icon, Input, Row, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS, useModal, VALIDATION_RULES } from '../../../../shared';
import { ErrorField } from '../../../../shared/components/ErrorField';
import AccountNotExists from './components/AccountNotExists';
import OrderUpdatedModalContent from './components/OrderUpdatedModalContent';
import { StyledButton, StyledRow } from './styled';

const EmailForm = inject('store')(
    observer(({ store, cartId }) => {
        const navigate = useNavigate();
        const { email, signInBeforeCheckout, pending, error, goToCheckout } = store.preCheckoutStore;
        const { openModal, closeModal } = useModal();
        const [form] = Form.useForm();

        const onForgotPassword = () => navigate(ROUTE_PATHS.forgotPassword);

        const handleOpenModal = (updatedCartId, updatedCheckoutKey) =>
            openModal({
                title: 'Order updated',
                children: (
                    <OrderUpdatedModalContent
                        onCloseModal={closeModal}
                        cartId={updatedCartId}
                        checkoutKey={updatedCheckoutKey}
                    />
                )
            });

        useEffect(() => {
            if (email) form.setFields([{ name: 'email', touched: true, value: email }]);
        }, [email]);

        const submitForm = (data) =>
            signInBeforeCheckout(data, handleOpenModal, cartId, () => navigate(ROUTE_PATHS.blocked, { replace: true }));

        return (
            <Form form={form} onFinish={submitForm} validateTrigger={['onBlur']}>
                <StyledRow>
                    <Col span={24}>
                        <Typography.Text type='body1'>Contact information</Typography.Text>
                    </Col>
                </StyledRow>
                <StyledRow gutter={20}>
                    <Col span={24}>
                        <Form.Item name='email' rules={VALIDATION_RULES.EMAIL}>
                            <Input size='large' showWarningIcon type='email' label='E-mail' autoComplete='on' />
                        </Form.Item>
                    </Col>
                </StyledRow>
                <AccountNotExists />
                <Row gutter={20}>
                    <Col span={24}>
                        <Form.Item name='password' rules={VALIDATION_RULES.REQUIRED}>
                            <Input
                                size='large'
                                showWarningIcon
                                type='password'
                                label='Password'
                                autoComplete='off'
                                trim={false}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <StyledButton type='unstyled' onClick={onForgotPassword}>
                    <Typography.Text type='badgeSmall' color='secondaryText'>
                        Forgot password?
                    </Typography.Text>
                </StyledButton>
                {error && (
                    <StyledRow>
                        <Col span={24}>
                            <ErrorField error={error} showIcon />
                        </Col>
                    </StyledRow>
                )}
                <StyledRow $marginTop='80px'>
                    <Col span={24} lg={{ span: 12 }}>
                        <Button
                            data-e2e='unauthorized_continueAsGuest-button'
                            type='unstyled'
                            size='large'
                            block
                            onClick={() => goToCheckout(cartId, handleOpenModal, () => navigate(ROUTE_PATHS.blocked))}
                        >
                            Continue as Guest
                        </Button>
                    </Col>
                    <Col span={24} lg={{ span: 12 }}>
                        <Form.Item noStyle shouldUpdate>
                            {() => (
                                <Button
                                    htmlType='submit'
                                    type='primary'
                                    size='large'
                                    block
                                    disabled={
                                        !form.isFieldsTouched(true) ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                >
                                    Sign In
                                </Button>
                            )}
                        </Form.Item>
                    </Col>
                </StyledRow>
                {pending && <Icon.Loader />}
            </Form>
        );
    })
);

export default EmailForm;
