import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackArrowIcon, BOTTOM_INDENTS, Button, Icon, Typography } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { StyledWrapper } from './styled';

const Header = observer(({ orderNumber }) => {
    const navigate = useNavigate();
    return (
        <StyledWrapper>
            <Button
                type='unstyled'
                size='small'
                icon={<Icon size='24px' component={() => <BackArrowIcon />} />}
                onClick={() => navigate(-1)}
                style={BOTTOM_INDENTS.S}
                data-e2e='giftly-acc-settings-order_summary-bank-btn'
            />
            <Typography.Title level={2}>Order {orderNumber}</Typography.Title>
        </StyledWrapper>
    );
});

export default Header;
