import React from 'react';
import { CircleActionsAlertInfoIcon, Icon, Typography } from '@klickly/front-packages';
import {
    StyledContent,
    StyledContentLeft,
    StyledContentRight,
    StyledImg1,
    StyledImg2,
    StyledImg3,
    Styles
} from './styles';

export const ExtensionSettingsInstalled = () => {
    return (
        <Styles>
            <StyledContent $block='1'>
                <StyledContentLeft $block='1'>
                    <Icon
                        size='24px'
                        color='primary'
                        style={{ marginRight: '24px' }}
                        component={() => <CircleActionsAlertInfoIcon />}
                    />
                    <Typography.Text type='descriptor1' color='primary'>
                        To activate the assistant, pin the extension in the toolbar of your browser
                    </Typography.Text>
                </StyledContentLeft>
                <StyledContentRight $block='1'>
                    <StyledImg1 />
                </StyledContentRight>
            </StyledContent>
            <StyledContent>
                <StyledContentLeft $block='2'>
                    <StyledImg2 />
                </StyledContentLeft>
                <StyledContentRight $block='2'>
                    <Typography.Text type='descriptor1' color='primaryText' style={{ marginBottom: '48px' }}>
                        Every week you will receive a Gifting Suite. The Giftly assistant will let you know about it.
                    </Typography.Text>
                    <Typography.Text type='descriptor1' color='primaryText'>
                        You can open your Suite directly from the extension.
                    </Typography.Text>
                </StyledContentRight>
            </StyledContent>
            <StyledContent>
                <StyledContentLeft $block='3'>
                    <Typography.Text type='descriptor1' color='primaryText' style={{ marginBottom: '48px' }}>
                        Once launched, your Suite will be active for 20 minutes.
                    </Typography.Text>
                    <Typography.Text type='descriptor1' color='primaryText'>
                        During this time, you can claim one of 10 offers.
                    </Typography.Text>
                </StyledContentLeft>
                <StyledContentRight $block='3'>
                    <StyledImg3 />
                </StyledContentRight>
            </StyledContent>
        </Styles>
    );
};
