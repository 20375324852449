import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, RIGHT_INDENTS, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $primary, ROUTE_PATHS } from '../../../../../../shared';
import { StyledButton, StyledContent, StyledGSInfo, StyledText, StyledTimer } from '../../styled';

export const SuiteStartedContent = inject('store')(
    observer(({ store, simple }) => {
        const navigate = useNavigate();
        const { nextCloseAt, getCurrentSuite } = store.suiteStore;

        return (
            <StyledGSInfo $simple={simple}>
                <Fragment>
                    <StyledText
                        level={1}
                        color='secondaryText'
                        align={simple ? 'center' : 'left'}
                        style={{ ...RIGHT_INDENTS.XXS }}
                    >
                        <span>
                            <span className='red'>Your Gifting Suite</span> will expire in
                        </span>
                        <span>
                            <StyledTimer
                                $simple={simple}
                                color={$primary}
                                type='headline1'
                                showIcon={false}
                                endDate={nextCloseAt}
                                onTimeLeft={getCurrentSuite}
                                params={{ minutes: 'min' }}
                                style={simple ? { ...BOTTOM_INDENTS.M } : {}}
                            />
                        </span>
                    </StyledText>
                </Fragment>
                <StyledContent $simple={simple}>
                    <StyledButton type='secondary' onClick={() => navigate(ROUTE_PATHS.suiteGs)} $simple={simple}>
                        <Typography.Text type='badgeSmall' color='primary'>
                            Go to Gifting Suite
                        </Typography.Text>
                    </StyledButton>
                </StyledContent>
            </StyledGSInfo>
        );
    })
);
