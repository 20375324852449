import { useEffect } from 'react';
import { remove as removeEl } from '../../utils/elements/common';

const handleClearPixels = (store, shouldClear = true, cart) => {
    if (shouldClear) {
        cart?.bags?.forEach((bag) => {
            bag.pixels?.forEach(() => {
                removeEl(`kl_tw_iframe_${bag.klicklyOrderId}`);
                removeEl(`kl_ga4_iframe_${bag.klicklyOrderId}`);
            });
        });
    }
};

export const useClearCartPixels = (store, shouldClearPixels = true, tyPageCart = null) => {
    const cart = tyPageCart || store.thankYouPageStore.cart;
    useEffect(() => {
        return () => {
            handleClearPixels(store, shouldClearPixels, cart);
        };
    }, [cart]);

    return { handleClearPixels };
};
