import React from 'react';
import { CancelOrderModalContainer } from '../CancelOrderModalContainer';
import { CancelOrderModalStyles } from './styles';

export const CancelOrderModal = ({ onClose, orderId, ...rest }) => {
    return (
        <CancelOrderModalStyles {...rest}>
            <CancelOrderModalContainer orderId={orderId} onClose={onClose} />
        </CancelOrderModalStyles>
    );
};
