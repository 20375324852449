import React from 'react';
import { SUBSCRIPTION_PLANS } from '../../../../shared';
import { SubscriptionItems } from '../../../../shared/components/SubscriptionItems';
import { DialogWithHeader } from '../DialogWithHeader';
import { SubscriptionModalFooter } from './components/SubscriptionModalFooter';

export const ChangeSubscription = ({ onClose }) => (
    <DialogWithHeader
        onClose={onClose}
        title='Giftly premium'
        subTitle='Access tons of premium products and exclusive discounts with Premium membership'
    >
        <SubscriptionItems items={SUBSCRIPTION_PLANS} />
        <SubscriptionModalFooter />
    </DialogWithHeader>
);
