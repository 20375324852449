import { flow, getParent, types } from 'mobx-state-tree';
import qs from 'qs';
import { DIALOG_TRIGGER_IDS, DIALOG_TRIGGER_KEY, kcpAPI } from '../shared';

export const ModalsStore = types
    .model('ModalsStore', {
        data: types.maybeNull(types.frozen()),
        pending: false,
        errorMessage: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },

        get modalsViewed() {
            return self.root.accountStore.accountModalsViewed;
        }
    }))
    .actions((self) => {
        const setPending = (pending) => {
            self.pending = pending;
        };

        const setError = (error) => {
            if (error && error.response && error.response.data) {
                self.errorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.errorMessage = error.message;
            }
        };

        const clearError = () => {
            self.errorMessage = null;
        };

        const setData = (data) => {
            self.data = data;
        };

        const openModal = (payload) => {
            const { dialogId, fromReward, ...rest } = payload;
            self.data = payload;
            const queryObject = { [DIALOG_TRIGGER_KEY]: dialogId, ...rest };
            if (fromReward) queryObject['from-reward'] = true;
            self.root.routeNavigate({ search: `${qs.stringify(queryObject)}` }, { replace: true });
        };

        const closeModal = () => {
            self.data = null;
            self.root.routeNavigate({ search: `${qs.stringify({})}` }, { replace: true });
        };

        const openChangeSubscriptionModal = () => {
            openModal({ dialogId: DIALOG_TRIGGER_IDS.CHANGE_SUBSCRIPTION });
        };

        const updateModalsViewed = flow(function* updateModalsViewed(payload) {
            clearError();
            setPending(true);
            try {
                const accountModalsViewed = self.root.accountStore.accountModalsViewed;
                const modalsViewed = {
                    ...accountModalsViewed,
                    ...payload
                };

                yield self.root.put(kcpAPI.customer.giftlyUpdate, {
                    modalsViewed
                });
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
            }
        });

        const sendReport = flow(function* sendReport(payload) {
            clearError();
            setPending(true);
            try {
                yield self.root.post(kcpAPI.feedback.reportProblem, payload);
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
            }
        });

        return {
            setData,
            openModal,
            closeModal,
            updateModalsViewed,
            openChangeSubscriptionModal,
            sendReport
        };
    });
