import styled from 'styled-components';

export const StyledWrap = styled.div`
    background-color: ${({ $backgroundColor }) => $backgroundColor || 'none'};
    width: ${({ $backgroundSize }) => $backgroundSize || 'auto'};
    height: ${({ $backgroundSize }) => $backgroundSize || 'auto'};
    min-width: ${({ $backgroundSize }) => $backgroundSize || 'auto'};
    min-height: ${({ $backgroundSize }) => $backgroundSize || 'auto'};
    border-radius: 50%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
`;
