import React, { Fragment } from 'react';
import { Typography, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { $primaryText } from '../../../../shared';
import { Logo } from '../../../../shared/components/Logo';
import { products } from '../../contants';
import { Divider } from '../Divider';
import {
    GiftPointsImg,
    StyledContent,
    StyledContentWrap,
    StyledHead,
    StyledImg,
    StyledProductDiscount,
    StyledProductsWrap,
    StyledProductWrap,
    StyledTextWrap,
    StyledUppercaseText,
    StyledWrap
} from './styles';

export const GiftSuiteSection = observer(() => {
    const withoutArrows = useSimpleInterface(1012);
    const simpleHeader = useSimpleInterface(930);
    const simpleProducts = useSimpleInterface(635);

    return (
        <StyledWrap $simple={simpleProducts}>
            <StyledContentWrap>
                <StyledHead $simple={simpleHeader}>
                    <Logo size='32px' color={$primaryText} style={{ marginBottom: simpleHeader ? '12px' : 0 }} />
                    {simpleHeader ? (
                        <Fragment>
                            <StyledUppercaseText
                                type='body2'
                                align='center'
                                style={{
                                    marginBottom: simpleHeader ? '24px' : 0
                                }}
                            >
                                Sign up bonus
                            </StyledUppercaseText>
                            <GiftPointsImg marginBottom={simpleHeader ? '24px' : 0} />
                            <Typography.Text as='div' type='body1' align='center'>
                                New members get{' '}
                                <Typography.Text as='span' type='body1' color='primary' style={{ fontWeight: 600 }}>
                                    a Gifting Suite
                                </Typography.Text>{' '}
                                worth $20!
                            </Typography.Text>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <StyledTextWrap>
                                <StyledUppercaseText type='body2' align='center'>
                                    Sign up bonus
                                </StyledUppercaseText>
                                <Typography.Text as='div' type='body1'>
                                    New members get{' '}
                                    <Typography.Text as='span' type='body1' color='primary' style={{ fontWeight: 600 }}>
                                        a Gifting Suite
                                    </Typography.Text>{' '}
                                    worth $20!
                                </Typography.Text>
                            </StyledTextWrap>
                            <GiftPointsImg />
                        </Fragment>
                    )}
                </StyledHead>
                <Divider style={{ margin: 0 }} lineWeight='2px' />
                <StyledContent $withoutArrows={withoutArrows}>
                    <StyledProductsWrap $withoutArrows={withoutArrows}>
                        {products.map(({ title, price, discount, src }) => (
                            <StyledProductWrap key={title}>
                                <StyledImg src={src} $simple={simpleProducts} />
                                <Typography.Title level={3} align='center' style={{ marginBottom: '3px' }}>
                                    {title}
                                </Typography.Title>
                                <Typography.Text
                                    align='center'
                                    type='badgeSmall'
                                    color='secondaryText'
                                    style={{ textDecoration: 'line-through' }}
                                >
                                    {price}
                                </Typography.Text>
                                <StyledProductDiscount align='center' type='body2' color='primary'>
                                    {discount}
                                </StyledProductDiscount>
                            </StyledProductWrap>
                        ))}
                    </StyledProductsWrap>
                </StyledContent>
            </StyledContentWrap>
        </StyledWrap>
    );
});
