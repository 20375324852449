import React from 'react';
import nextId from 'react-id-generator';
import { Link } from 'react-router-dom';
import { Typography, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { $primaryText, ROUTE_PATHS } from '../../../../shared';
import { AppMenu } from '../../../../shared/components/AppMenu';
import { Logo } from '../../../../shared/components/Logo';
import { headerLinks } from './contants';
import { HeaderContent, HeaderContentLeft, HeaderLinksWrap, HeaderStyles, TextWrap } from './styles';

export const Header = observer(() => {
    const simple = useSimpleInterface(1090);

    return (
        <HeaderStyles simple={simple}>
            <HeaderContent simple={simple}>
                <HeaderContentLeft>
                    <Link to={ROUTE_PATHS.root}>
                        <Logo size={simple ? '24px' : '32px'} color={$primaryText} />
                    </Link>
                    {!simple && (
                        <HeaderLinksWrap>
                            {headerLinks.map((obj) => (
                                <TextWrap key={nextId()}>
                                    <Typography.Text
                                        onClick={() => window.open(obj.link, '_blank')}
                                        as='span'
                                        type='badgeSmall'
                                    >
                                        {obj.label}
                                    </Typography.Text>
                                </TextWrap>
                            ))}
                        </HeaderLinksWrap>
                    )}
                </HeaderContentLeft>
                <AppMenu showClaimsPointsData={false} showCartData={false} />
            </HeaderContent>
        </HeaderStyles>
    );
});
