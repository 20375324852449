import React, { useEffect, useRef, useState } from 'react';
import { useCountUp } from 'react-countup';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Icon, PointsIcon, Popover, Row, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../../../../utils';
import { $primary, $white, ROUTE_PATHS } from '../../../../../../constants';
import { StyledText, StyledWrap } from './styles';

const Points = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { pathname } = useLocation();
        const { points, prevPoints, setPrevPoints } = store.claimsPointsStore;
        const { closePDP } = store.pdpStore;
        const { getShoppingCartState } = store.shoppingCartStore;
        const [color, setColor] = useState('primaryText');
        const [backgroundColor, setBackgroundColor] = useState($white);
        const ref = useRef(null);
        const simple = useSimpleInterface(GRID_POINTS.md);

        const { start } = useCountUp({
            ref,
            start: prevPoints,
            end: points,
            duration: 1.5,
            onEnd: () => {
                setPrevPoints(points);
                setColor(pathname.includes('rewards') ? 'primary' : 'primaryText');
                setBackgroundColor($white);
            }
        });

        useEffect(() => {
            if (points !== prevPoints) {
                setColor($white);
                setBackgroundColor($primary);
                start();
            }
            if (points === prevPoints) {
                setColor(pathname.includes('rewards') ? 'primary' : 'primaryText');
                setBackgroundColor($white);
            }
        }, [points, prevPoints]);

        const goToRewards = () => {
            closePDP();
            getShoppingCartState();
            navigate(ROUTE_PATHS.points);
        };

        return (
            <StyledWrap>
                <StyledText as='div' color={color} $backgroundColor={backgroundColor} onClick={goToRewards}>
                    <Popover.Points points={points} showUsdOnly={simple} getPopupContainer={() => document.body}>
                        <Row gutter={4} align='middle'>
                            <Col>
                                <Icon size='14px' component={() => <PointsIcon />} />
                            </Col>
                            <Col>
                                <Typography.Text type='badgeMedium' as='span'>
                                    <span ref={ref} />
                                </Typography.Text>
                            </Col>
                        </Row>
                    </Popover.Points>
                </StyledText>
            </StyledWrap>
        );
    })
);

export default Points;
