import { get } from '../../utils';
import { loadPixels } from '../../utils/pixels';

export const handleOrderSuccess = ({ cart, onCompleteTask = () => null }) => {
    if (!cart) return null;

    const bags = cart?.bags || [];
    const customerEmail = get(cart, 'customerInfo.email');

    (bags || []).map((bag) => {
        if (bag.pixels?.length) {
            loadPixels(bag.pixels, {
                tw: {
                    id: `kl_tw_iframe_${bag.klicklyOrderId}`,
                    queryParams: { thank_you: 1, email: customerEmail }
                }
            });
        }
    });

    onCompleteTask(cart?.bags?.[0]?.items?.[0]?.productId);
};
