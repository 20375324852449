import React from 'react';
import { GiftLiteIcon, Icon, Typography } from '@klickly/front-packages';
import { StyledTitle, StyledWrapper } from './styled';

const NoRewardsAvailable = ({
    isLoggedIn,
    subTitle = 'No Rewards available',
    description,
    icon = <Icon size='80px' component={() => <GiftLiteIcon />} />,
    useApplyRewardError = false,
    applyRewardError
}) => {
    const text = isLoggedIn
        ? 'Earn Rewards by completing tasks and challenges'
        : 'Earn rewards by becoming a Giftly member';
    const desc = useApplyRewardError ? applyRewardError : description;
    return (
        <StyledWrapper>
            {icon}
            <StyledTitle level={3} type='descriptor1'>
                {subTitle}
            </StyledTitle>
            <Typography.Text type='body2' align='center'>
                {desc || text}
            </Typography.Text>
        </StyledWrapper>
    );
};

export default NoRewardsAvailable;
