import styled from 'styled-components';
import { $border } from '../../index';

export const Hr = styled.div`
    display: block;
    width: 100%;
    margin: 24px 0;
    height: 1px;
    background-color: ${$border};
`;
