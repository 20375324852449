import { Button, Typography } from '@klickly/front-packages';
import styled, { createGlobalStyle } from 'styled-components';
import { $primaryText, $white } from '../../constants';

export const StyledPopoverContainer = createGlobalStyle`
    .popover-with-button {
        position: absolute;
        
        .ant-popover-inner-content {
            padding: 16px 24px;
            background: ${$primaryText};
            color: ${$white};
        }

        .ant-popover-arrow-content {
            box-shadow: none;

            &:before {
                background: ${$primaryText};
            }
        }

        .ant-popover-inner {
            box-shadow: none;
        }
    }
`;

export const StyledContentWrapper = styled(Typography.Text)`
    margin-bottom: 10px;
`;

export const StyledButton = styled(Button)`
    && {
        padding: 8px 24px;
        border: 1px solid ${$white} !important;
        background: ${$primaryText};
        cursor: pointer;

        &&& > span {
            color: ${$white};
        }
    }
`;
