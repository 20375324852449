import React, { Fragment, useContext } from 'react';
import { useSimpleInterface } from '../../../utils';
import { KliThemeContext } from '../KliThemeProvider';
import { StyledForm } from './StyledForm';
import { StyledNotification } from './StyledNotification';

const StyledUI = () => {
    const simple = useSimpleInterface();
    const themeContext = useContext(KliThemeContext);
    return (
        <Fragment>
            <StyledNotification $theme={themeContext} $simple={simple} />
            <StyledForm $theme={themeContext} />
        </Fragment>
    );
};

export default StyledUI;
