import { Button, Input } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $success, $white } from '../../../../../../../../../../../../../../shared';

export const StyledWrap = styled.div`
    width: 100%;
`;

export const StyledInput = styled(Input)`
    touch-action: none;
    pointer-events: none;
    margin-bottom: 24px;
`;

export const StyledButton = styled(Button)`
    && {
        width: 100%;
        margin-bottom: 8px;

        ${({ $copied }) =>
            $copied
                ? css`
                      touch-action: none;
                      pointer-events: none;
                      border-color: ${$success};
                      background-color: #ffffff !important;

                      &:focus:not(:hover) {
                          border-color: ${$success};
                      }
                  `
                : css`
                      &:hover:not(:disabled):not(:active) {
                          > span {
                              color: ${$white};
                          }
                      }
                  `}
    }
`;
