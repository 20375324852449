import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BagAndSuccessIcon,
    BOTTOM_INDENTS,
    Button,
    Col,
    Image,
    Row,
    TOP_INDENTS,
    Typography
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import qs from 'qs';
import placeholder from '../../../../assets/img/image-placeholder.svg';
import { $background, $primaryText, ROUTE_PATHS } from '../../../../shared';
import CancelOrder from '../CancelOrder';
import { StyledIcon } from '../RightContent/components/Info/styled';
import { StyledWrapper } from './styled';

const LeftContent = inject('store')(
    observer(({ orderId, orderNumber, content, isFirstClaim, mobile, store, isLoggedIn }) => {
        const { itemsInOrder, gsImage } = store.thankYouPageStore;
        const { signUpStatusRejected } = store.accountStore;
        const navigate = useNavigate();
        const image = gsImage || placeholder;

        return (
            <StyledWrapper>
                <Row justify='center'>
                    <Col span={24} align='center'>
                        {mobile && content.leftPart.getTitle()}
                        {isFirstClaim ? (
                            <Image width='160px' src={image} />
                        ) : (
                            <StyledIcon
                                style={mobile ? { ...TOP_INDENTS.XL, ...BOTTOM_INDENTS.XXL } : BOTTOM_INDENTS.XXL}
                                roundedOpacity='1'
                                size='107px'
                                roundedSize='160px'
                                hoverRoundedColor={$background}
                                roundedColor={$background}
                                component={() => <BagAndSuccessIcon />}
                            />
                        )}
                        {!mobile && content.leftPart.getTitle()}
                        <Typography.Title align='center' style={TOP_INDENTS.SM} level={3} color={$primaryText}>
                            We’ve received your order and will notify you as soon as it’s shipped
                        </Typography.Title>
                        {isLoggedIn && !signUpStatusRejected && (
                            <Button
                                onClick={() => {
                                    itemsInOrder.length > 1
                                        ? navigate(ROUTE_PATHS.orders)
                                        : navigate({
                                              pathname: `${ROUTE_PATHS.orders}/${orderId}`,
                                              search: qs.stringify({ orderNumber })
                                          });
                                }}
                                size='large'
                                ghost
                                type='unstyled'
                                style={TOP_INDENTS.SM}
                                level={3}
                                color={$primaryText}
                                data-e2e='see-orders-btn-giftly'
                            >
                                {itemsInOrder.length > 1 ? 'See Orders' : 'See Order Details'}
                            </Button>
                        )}
                    </Col>
                    {!mobile && (
                        <CancelOrder
                            signUpStatusRejected={signUpStatusRejected}
                            navigate={navigate}
                            isLoggedIn={isLoggedIn}
                        />
                    )}
                </Row>
            </StyledWrapper>
        );
    })
);

export default LeftContent;
