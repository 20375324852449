import React from 'react';
import { Alert, ForwardArrowIcon, Typography } from '@klickly/front-packages';

export const AlertInfo = ({ onClick, text = 'How do rewards work?', bottomIndent, ...props }) => {
    return (
        <Alert
            data-e2e='how-rewards-work-button'
            onClick={onClick}
            className='hiddenOnMobile'
            afterIcon={<ForwardArrowIcon />}
            marginBottom={bottomIndent}
            showIcon
            description={text}
            {...props}
        >
            <Typography.Text type='badgeMedium'>{text}</Typography.Text>
        </Alert>
    );
};
