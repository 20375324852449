import React from 'react';
import { BOTTOM_INDENTS, Icon, Typography } from '@klickly/front-packages';
import { StyledButton, StyledValueWrap, StyledWrap } from './styled';

export const Card = ({ icon, value, text, buttonText, onClick, simple }) => {
    return (
        <StyledWrap>
            <Icon size='64px' color='primary' component={() => icon} style={BOTTOM_INDENTS.XXS} />
            <StyledValueWrap>
                <Typography.Title level={2} align='center' style={BOTTOM_INDENTS.XXS}>
                    {value}
                </Typography.Title>
                <Typography.Text type='body2' align='center'>
                    {text}
                </Typography.Text>
            </StyledValueWrap>
            <StyledButton type='unstyled' onClick={onClick}>
                <Typography.Text type='badgeSmall' color='primary'>
                    {buttonText}
                </Typography.Text>
            </StyledButton>
        </StyledWrap>
    );
};
