import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../utils';

export const StyledModalWrapper = styled.div`
    background-color: #fff;
    width: 630px;
    min-height: auto;
    position: relative;

    @media (${GRID_DEVICES_MAX.sm}) {
        width: 100%;
        min-height: 100%;
    }
`;
