import React from 'react';
import {
    BOTTOM_INDENTS,
    Col,
    formatPoints,
    GiftIcon,
    Icon,
    PointsIcon,
    Row,
    Typography
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $primary } from '../../../../../../../../shared';

const Reward = inject('store')(
    observer(({ store, simple }) => {
        const { activeChallengeRewards } = store.seasonalChallengeStore;
        const points = activeChallengeRewards?.point;
        const claims = activeChallengeRewards?.claim;
        return points || claims ? (
            <Row gutter={8} align='middle' justify={simple ? 'center' : 'left'} style={BOTTOM_INDENTS.M}>
                <Col>
                    <Typography.Title level={2} color={$primary}>
                        And earn
                    </Typography.Title>
                </Col>
                {points ? (
                    <Col>
                        <Row gutter={8} align='middle'>
                            <Col>
                                <Icon size='32px' color={$primary} component={() => <PointsIcon />} />
                            </Col>
                            <Col>
                                <Typography.Title level={2} color={$primary}>
                                    {formatPoints(points)} points
                                </Typography.Title>
                            </Col>
                        </Row>
                    </Col>
                ) : null}
                {claims ? (
                    <Col>
                        <Row gutter={8} align='middle'>
                            {points ? (
                                <Col>
                                    <Typography.Title level={2} color={$primary}>
                                        +
                                    </Typography.Title>
                                </Col>
                            ) : null}
                            <Col>
                                <Icon size='32px' color={$primary} component={() => <GiftIcon />} />
                            </Col>
                            <Col>
                                <Typography.Title level={2} color={$primary}>
                                    {claims} Gifting Suite
                                </Typography.Title>
                            </Col>
                        </Row>
                    </Col>
                ) : null}
            </Row>
        ) : null;
    })
);

export default Reward;
