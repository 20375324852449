import React from 'react';
import { observer } from 'mobx-react';
import { SUITE_INFO_DATA } from '../../../../../../shared';
import { InfoList } from '../../../../../../shared/components/InfoList';
import { PageHeader } from '../../../../../../shared/components/PageHeader';
import { InfoPageStyles } from './styles';

const InfoPage = observer(({ data = SUITE_INFO_DATA }) => {
    return (
        <InfoPageStyles>
            <PageHeader title='Learn more' subTitle='' withGoBack />
            <InfoList data={data} />
        </InfoPageStyles>
    );
});

export default InfoPage;
