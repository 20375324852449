import { Button } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledBackButton = styled(Button)`
    && {
        margin-left: -8px;
    }
`;

export const StyledWrapper = styled.div`
    && {
        .ant-btn {
            padding: 8px 20px;
        }
    }
`;

export const StyledDividerWrapper = styled.div`
    padding: 0 24px;
`;
