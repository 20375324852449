import { ROUTE_PATHS, SUITE_STATES } from '../../../index';
import { getBannerContent } from './getBannerContent';

export const getCouponBannerContent = (store, bannerType, pathname) => {
    const { isLoggedIn, signUpStatusRejected, profileEmail } = store.accountStore;
    const { suiteState, nextCloseAt } = store.suiteStore;
    const { claimsCount } = store.claimsPointsStore;
    const navigate = store.routeNavigate;

    const isDealsPage = pathname === ROUTE_PATHS.deals;

    const COUPON_BANNER_CONTENT = getBannerContent(navigate, profileEmail, isDealsPage);

    if (isLoggedIn) {
        if (signUpStatusRejected) {
            return COUPON_BANNER_CONTENT.authorized.waitListed;
        }

        if (suiteState === SUITE_STATES.started && new Date().valueOf() < new Date(nextCloseAt).valueOf()) {
            return COUPON_BANNER_CONTENT.authorized.suiteStarted;
        }
        if (suiteState === SUITE_STATES.opened && claimsCount > 0) {
            return COUPON_BANNER_CONTENT.authorized.suiteOpened;
        }

        if (new Date().valueOf() < new Date(nextCloseAt).valueOf()) {
            return COUPON_BANNER_CONTENT.authorized.suiteStarted;
        }

        return COUPON_BANNER_CONTENT.authorized[bannerType];
    }
    return COUPON_BANNER_CONTENT.unauthorized[bannerType];
};
