import React from 'react';
import {
    ArrowChevronForwardIcon,
    CircleActionsPlaceholderIcon,
    FilledSuccessIcon,
    Icon
} from '@klickly/front-packages';
import { Field } from '../../../../../../../../../../shared/components/Field';
import { StyledWrapper } from './styles';

export const ShippingItemPayment = ({ payment, simple, ...rest }) => {
    const renderLeftIcon = () => {
        if (!payment.isDefault && simple) {
            return null;
        }

        if (payment.isDefault) {
            return (
                <Icon
                    size='24px'
                    color='primary'
                    style={{ paddingLeft: '20px' }}
                    component={() => <FilledSuccessIcon />}
                />
            );
        }

        return <Icon size='22px' style={{ paddingLeft: '20px' }} component={() => <CircleActionsPlaceholderIcon />} />;
    };

    const renderRightIcon = () => {
        if (simple) {
            return (
                <Icon
                    size='24px'
                    color='secondaryText'
                    style={{ paddingRight: '20px' }}
                    component={() => <ArrowChevronForwardIcon />}
                />
            );
        }
    };

    return (
        <StyledWrapper>
            {renderLeftIcon()}
            <Field
                value={payment.address1}
                placeholder={'Ending in ' + payment.last4}
                nonOpacityOnDisabled
                large
                disabled
                {...rest}
            />
            {renderRightIcon()}
        </StyledWrapper>
    );
};
