import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $primary, $primaryBackground, $primaryText, $white } from '../../../../../../../../shared';

export const StyledWrapper = styled.div`
    margin-bottom: 60px;
    padding: 16px 20px 0;

    > div {
        &:first-child {
            flex-direction: row;
        }
    }
`;

export const StyledBottomText = styled(Typography.Text)`
    margin-bottom: -12px;
    padding: 0 24px;
    text-align: center;
    background: ${$white};
`;

export const StyledFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 48px;
    border-bottom: 2px dashed ${$primaryText};
`;

export const StyledTitle = styled(Typography.Title)`
    margin-bottom: 48px;
`;

export const StyledRewardsBadge = styled(Typography.Text)`
    width: fit-content;
    margin-bottom: 16px;
    padding: 12px;
    background-color: ${$primaryBackground};
`;

export const StyledExpiredText = styled(Typography.Text)`
    margin-bottom: 8px;
    color: ${$primary};
`;

export const StyledDescription = styled(Typography.Text)`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;
