import React from 'react';
import { useSimpleInterface } from '@klickly/front-packages';
import { DialogHeader } from './components/DialogHeader';
import { Styles } from './styles';

export const DialogWithHeader = ({ children, onClose, title, subTitle }) => {
    const simple = useSimpleInterface();

    return (
        <Styles simple={simple}>
            <DialogHeader title={title} subTitle={subTitle} simple={simple} onClose={onClose} />

            {children}
        </Styles>
    );
};
