import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GiftIcon, Icon, PointsIcon, ProfileIcon, TrophyIcon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../shared';
import { get } from '../../../../utils';
import { Card } from './components/Card';
import { useCardsData } from './hooks';
import { StyledLoaderWrap, StyledWrap } from './styled';

export const CardsSection = inject('store')(
    observer(({ store, position = 'main' }) => {
        const navigate = useNavigate();
        const { result, pending } = useCardsData(store);
        const challengesData = get(result, 'challenges', {});
        const suiteData = get(result, 'suite', {});
        const pointsData = get(result, 'points', {});
        const inviteData = get(result, 'invite', {});

        if (pending) {
            return (
                <StyledLoaderWrap>
                    <Icon.Loader zIndex={5} />
                </StyledLoaderWrap>
            );
        }

        return (
            <StyledWrap $position={position}>
                {challengesData?.visible ? (
                    <Card
                        icon={<TrophyIcon />}
                        value={challengesData.value}
                        text='Challenges'
                        buttonText='Accept challenge'
                        onClick={() => navigate(ROUTE_PATHS.challenges)}
                    />
                ) : null}
                {suiteData?.visible ? (
                    <Card
                        icon={<GiftIcon />}
                        value={suiteData.value}
                        text='Gifting Suite'
                        buttonText='Launch'
                        onClick={() => navigate(ROUTE_PATHS.suiteGs)}
                    />
                ) : null}
                {pointsData?.visible ? (
                    <Card
                        icon={<PointsIcon />}
                        value={pointsData.value}
                        text='Points'
                        buttonText='Earn more'
                        onClick={() => navigate(ROUTE_PATHS.shop)}
                    />
                ) : null}
                {inviteData?.visible ? (
                    <Card
                        icon={<ProfileIcon />}
                        value={inviteData.value}
                        text='Invites'
                        buttonText='Invite a friend'
                        onClick={() => navigate(ROUTE_PATHS.inviteAndEarn)}
                    />
                ) : null}
            </StyledWrap>
        );
    })
);
