import React from 'react';
import { BOTTOM_INDENTS, Button, Col, Form, Icon, Input, Row, TOP_INDENTS, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS, VALIDATION_RULES } from '../../../../../../shared';
import { ErrorField } from '../../../../../../shared/components/ErrorField';
import { Link } from '../../../../../../shared/components/Link';

export const ResetPasswordForm = inject('store')(
    observer(({ store }) => {
        const [form] = Form.useForm();
        const { resetPassword: onSubmit, pending, errorMessage } = store.authStore;

        const submitted = (data) => {
            if (typeof onSubmit !== 'undefined') {
                onSubmit(data);
            }
        };

        return (
            <Form form={form} onFinish={submitted} validateTrigger={['onBlur']}>
                <Row gutter={20}>
                    <Col span={24}>
                        <Form.Item name='resetToken' rules={VALIDATION_RULES.REQUIRED}>
                            <Input size='large' showWarningIcon label='Token' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={24}>
                        <Form.Item name='password' rules={VALIDATION_RULES.REQUIRED}>
                            <Input size='large' showWarningIcon label='Password' type='password' trim={false} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={24}>
                        <Form.Item
                            name='repeatPassword'
                            dependencies={['password']}
                            rules={[
                                ...VALIDATION_RULES.REQUIRED,
                                ({ getFieldValue }) => ({
                                    validator: (_, value) => {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The passwords do not match'));
                                    }
                                })
                            ]}
                        >
                            <Input size='large' showWarningIcon label='Repeat password' type='password' trim={false} />
                        </Form.Item>
                    </Col>
                </Row>
                {errorMessage && (
                    <Row>
                        <Col>
                            <ErrorField hasBottomIndent error={errorMessage} />
                        </Col>
                    </Row>
                )}
                <Row style={TOP_INDENTS.SM}>
                    <Col span={24} xl={{ span: 14 }}>
                        <Form.Item noStyle shouldUpdate>
                            {() => (
                                <Button
                                    data-e2e='auth_reset-password-button'
                                    htmlType='submit'
                                    type='primary'
                                    size='large'
                                    block
                                    disabled={
                                        !form.isFieldsTouched(true) ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                >
                                    Reset password
                                </Button>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col style={BOTTOM_INDENTS.XL} span={24}>
                        <Typography.Text color='secondaryText'>
                            Didn’t receive an e-mail? Check your spam folder or
                            <Link to={ROUTE_PATHS.forgotPassword}>
                                <Typography.Text
                                    data-e2e='auth_try-anotherEmail-button'
                                    as='span'
                                    color='secondaryText'
                                    type='badgeSmall'
                                >
                                    try another e-mail address
                                </Typography.Text>
                            </Link>
                        </Typography.Text>
                    </Col>
                </Row>
                {pending && <Icon.Loader />}
            </Form>
        );
    })
);
