import { Icon, Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../../../utils';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 90px;

    @media (${GRID_DEVICES_MAX.md}) {
        margin-top: 30px;
    }
`;

export const StyledIcon = styled(Icon)`
    margin-bottom: 64px;
    z-index: 1;
`;

export const StyledDescription = styled(Typography.Text)`
    margin-bottom: 64px;
    text-align: center;
`;
