import React, { useEffect, useRef } from 'react';
import { ForwardArrowIcon, GiftIcon, Icon, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import * as pointsAnimation from '../../../../../../assets/animation/points-animation.json';
import { StyledAnimationBlock, StyledButton, StyledIconWrap, StyledWrap } from './styles';

export const WelcomeModal = inject('store')(
    observer(({ store, onClose, simple }) => {
        const animationRef = useRef(null);

        useEffect(() => {
            animationRef.current.play();
        }, []);

        const { firstName } = store.accountStore;
        return (
            <StyledWrap $simple={simple}>
                <StyledIconWrap size='40px' color='canvas' component={() => <GiftIcon />} />
                <Typography.Title level={2} align='center' style={{ marginBottom: '40px' }}>
                    Congrats {firstName}
                </Typography.Title>
                {!simple ? (
                    <Typography.Title align='center' color='primary' style={{ marginBottom: '20px' }}>
                        You’ve updated your preferences!
                    </Typography.Title>
                ) : (
                    <Typography.Text type='badgeLarge' align='center' color='primary' style={{ marginBottom: '24px' }}>
                        You’ve updated your preferences!
                    </Typography.Text>
                )}
                {!simple ? (
                    <Typography.Text type='badgeLarge' align='center' style={{ marginBottom: '64px' }}>
                        Open your personalized Gifting Suite to claim your reward!
                    </Typography.Text>
                ) : (
                    <Typography.Text type='body1' align='center' style={{ marginBottom: '64px' }}>
                        Open your personalized Gifting Suite to claim your reward!
                    </Typography.Text>
                )}
                <StyledButton
                    data-e2e='gsPage_claimReward-button'
                    $simple={simple}
                    type='primary'
                    size='large'
                    onClick={onClose}
                >
                    <Typography.Text type='badgeLarge' style={{ marginRight: '16px' }}>
                        Claim your reward
                    </Typography.Text>
                    <Icon stylePriority size='24px' component={() => <ForwardArrowIcon />} />
                </StyledButton>
                <StyledAnimationBlock
                    $simple={simple}
                    lottieRef={animationRef}
                    animationData={pointsAnimation}
                    loop={false}
                    autoplay={false}
                />
            </StyledWrap>
        );
    })
);
