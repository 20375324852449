import { Icon, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $border, $primary, $white } from '../../../../../../../shared';

export const StyledWrap = styled.div`
    width: 56px;
    position: relative;
`;

export const StyledIcon = styled(Icon)`
    width: 56px;
    height: 56px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 4px solid transparent;
    border-radius: 50%;
    background-color: transparent;

    ${({ $state }) =>
        $state === 'active'
            ? css`
                  color: ${$primary};
                  border-color: ${$primary};
              `
            : ''}

    ${({ $state }) =>
        $state === 'completed'
            ? css`
                  color: ${$white};
                  border-color: ${$primary};
                  background-color: ${$primary};
              `
            : ''}

    ${({ $state }) =>
        $state === 'uncompleted'
            ? css`
                  color: ${$border};
                  border-color: ${$border};
              `
            : ''}
`;

export const StyledDescription = styled(Typography.Text)`
    position: absolute;
    top: 100%;
    padding-top: 8px;
    width: calc(100% + 50px);
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    ${({ $firstStep }) =>
        $firstStep
            ? css`
                  left: 0;
                  transform: translateX(0);
                  text-align: left;
              `
            : ''};

    ${({ $lastStep }) =>
        $lastStep
            ? css`
                  left: auto;
                  right: 0;
                  transform: translateX(0);
                  text-align: right;
              `
            : ''};
`;
