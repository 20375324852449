import React from 'react';
import { ForwardArrowIcon, Icon, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $primary } from '../../../../../../../../shared';
import { Logo } from '../../../../../../../../shared/components/Logo';
import {
    StyledButton,
    StyledContent,
    StyledContentLeft,
    StyledContentRight,
    StyledHead,
    StyledImg,
    Styles
} from './styles';

export const ExtensionSettingsNotInstalled = inject('store')(
    observer(({ store }) => {
        const { openChromeStore } = store.tasksStore;
        return (
            <Styles>
                <StyledHead>
                    <StyledButton type='primary' size='large' onClick={openChromeStore}>
                        <Typography.Text type='badgeLarge'>Install</Typography.Text>
                        <Icon stylePriority marginLeft='8px' size='16px' component={() => <ForwardArrowIcon />} />
                    </StyledButton>
                    <Typography.Text type='descriptor1' color='secondaryText'>
                        Proceed to the Chrome Web Store. <br />
                        After the extension is installed in your browser, the challenge will be complete. You will earn
                        a free Gifting Suite.
                    </Typography.Text>
                </StyledHead>
                <StyledContent>
                    <StyledContentLeft>
                        <Logo size='40px' color={$primary} style={{ marginBottom: '40px' }} />
                        <Typography.Title color='primary' style={{ marginBottom: '40px' }}>
                            Giftly Personal assistant
                        </Typography.Title>
                        <Typography.Text type='body1' color='primary'>
                            By installing the Chrome extension, you will always be up to date with the best Giftly
                            offers, new challenges and personal promotions.
                        </Typography.Text>
                    </StyledContentLeft>
                    <StyledContentRight>
                        <StyledImg />
                    </StyledContentRight>
                </StyledContent>
            </Styles>
        );
    })
);
