import styled, { css } from 'styled-components';

export const StyledWrap = styled.div`
    width: 100%;

    ${({ $simple }) =>
        $simple
            ? css`
                  height: 100%;
                  overflow: auto;
              `
            : ''}
`;

export const StyledPDPWrap = styled.div`
    width: 100%;
    height: ${({ $simple }) => ($simple ? 'calc(100% - 80px)' : 'auto')};
`;
