import { useState } from 'react';
import { useInterval } from '@klickly/front-packages';
import moment from 'moment';
import { convertExpiresDateToDefaultFormat } from '../../utils';

export const useCountDown = (endDate, onAfterFinish = () => null, stopCountDown = false) => {
    const [date, setDate] = useState(convertExpiresDateToDefaultFormat(endDate));
    useInterval(
        () => {
            if (date && moment().valueOf() <= moment(endDate).valueOf()) {
                setDate(convertExpiresDateToDefaultFormat(endDate));
            } else {
                onAfterFinish();
            }
        },
        1000,
        stopCountDown
    );

    return date;
};
