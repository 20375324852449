import React from 'react';
import { inject, observer } from 'mobx-react';
import { app } from '../../../../../../config';
import { PDPComponent } from '../../../../../../shared/components/PDP';
import CouponDetailsExtraContent from './components/CouponDetailsExtraContent';

const CouponDetails = inject('store')(
    observer(({ store }) => {
        const { isLoggedIn } = store.accountStore;
        const {
            selectedRewardPromotionId,
            setSelectedRewardPromotionId,
            newRewardUuid,
            setSelectedReward
        } = store.rewardsStore;

        return (
            <PDPComponent
                id={selectedRewardPromotionId}
                customizationsKey='rewards'
                extraContent={
                    <CouponDetailsExtraContent
                        selectedRewardId={store.rewardsStore.selectedRewardId}
                        getRewardById={store.rewardsStore.getRewardById}
                        selectedReward={store.rewardsStore.selectedReward}
                        setSelectedRewardPromotionId={store.rewardsStore.setSelectedRewardPromotionId}
                        setSelectedReward={store.rewardsStore.setSelectedReward}
                    />
                }
                onClose={() => {
                    setSelectedRewardPromotionId(null);
                    setSelectedReward(null);
                }}
                rewardUuid={!isLoggedIn ? newRewardUuid : null}
                onBrandNameButtonClick={(brandId) => {
                    window.open(`${app.giftlyUrl}/brands/${brandId}`, '__blank');
                }}
            />
        );
    })
);

export default CouponDetails;
