import { Button, Icon } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
    && {
        width: 100%;
    }
`;

export const StyledIcon = styled(Icon)`
    margin: 0 10px;
`;
