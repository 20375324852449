import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../utils';

export const StyledWrapper = styled.div`
    position: relative;
    margin-bottom: 80px;
    padding: 0 4px;

    > div {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    ${({ $isMobileView }) =>
        $isMobileView &&
        css`
            min-height: 240px;
            margin-bottom: 0;
        `};

    ${({ $height }) =>
        $height &&
        css`
            height: ${$height};
        `};

    @media (${GRID_DEVICES_MAX.md}) {
        min-height: 240px;
        margin-bottom: 64px;

        ${({ $isMobileView }) =>
            $isMobileView &&
            css`
                margin-bottom: 0;
            `};
    }
`;

export const StyledContentWrapper = styled.div`
    padding: 0 40px;
    height: 100%;

    > div {
        height: 100%;
        min-height: 186px;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        padding: 16px 12px;
    }

    ${({ $isMobileView }) =>
        $isMobileView &&
        css`
            padding: 16px 12px;
        `};
`;

export const StyledText = styled.span`
    color: ${({ $color }) => $color || 'black'};
    margin-top: ${({ $topIndent = 0 }) => $topIndent || 0};
    display: ${({ $topIndent }) => ($topIndent ? 'block' : 'initial')};
`;
