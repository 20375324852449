import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import {
    BOTTOM_INDENTS,
    Button,
    Col,
    Form,
    Icon,
    Input,
    Row,
    TOP_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../utils';
import { ROUTE_PATHS, VALIDATION_RULES } from '../../constants';
import { ApplyNow } from '../ApplyNow';
import { ErrorField } from '../ErrorField';
import { StyledForgotPasswordBtn } from './styled';

export const SignInForm = inject('store')(
    observer(
        ({
            store,
            alignContent,
            onForgotPasswordClick = () => null,
            onApplyNowClick = () => null,
            onAfterSignIn = () => null,
            redirectToAfterSignIn
        }) => {
            const { signIn, pending, errorMessage, setError } = store.authStore;
            const navigate = useNavigate();
            const redirectTo = useSearchParam('redirectTo') || redirectToAfterSignIn;
            const simple = useSimpleInterface(GRID_POINTS.lg);
            const [form] = Form.useForm();
            const forgotPasswordLink = ROUTE_PATHS.forgotPassword;

            const onSubmit = (data) => {
                signIn(data, redirectTo, onAfterSignIn, () => navigate(ROUTE_PATHS.blocked, { replace: true }));
            };

            const submitted = (data) => {
                if (typeof onSubmit !== 'undefined') {
                    onSubmit(data);
                }
            };

            return (
                <Form form={form} onFinish={submitted} validateTrigger={['onBlur']}>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item name='email' rules={VALIDATION_RULES.EMAIL}>
                                <Input
                                    data-e2e='auth_email'
                                    size='large'
                                    showWarningIcon
                                    type='email'
                                    label='E-mail'
                                    autoComplete='on'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item name='password' rules={VALIDATION_RULES.REQUIRED}>
                                <Input
                                    data-e2e='auth_password'
                                    size='large'
                                    showWarningIcon
                                    type='password'
                                    label='Password'
                                    autoComplete='off'
                                    trim={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={BOTTOM_INDENTS.SM} justify='between'>
                        <Col>
                            <StyledForgotPasswordBtn
                                data-e2e='auth_forgotPassword-button'
                                type='text'
                                onClick={() => {
                                    onForgotPasswordClick();
                                    setError(null);
                                    return navigate(forgotPasswordLink);
                                }}
                                block={alignContent === 'center'}
                            >
                                <Typography.Text color='secondaryText' type='badgeSmall'>
                                    Forgot password?
                                </Typography.Text>
                            </StyledForgotPasswordBtn>
                        </Col>
                    </Row>
                    {errorMessage && (
                        <Row>
                            <Col>
                                <ErrorField hasBottomIndent error={errorMessage} showIcon />
                            </Col>
                        </Row>
                    )}
                    <Row style={TOP_INDENTS.SM}>
                        <Col span={24} lg={{ span: alignContent === 'center' ? 24 : 12 }}>
                            <Form.Item noStyle shouldUpdate>
                                {() => (
                                    <Button
                                        data-e2e='auth_signIn-button'
                                        htmlType='submit'
                                        type='primary'
                                        size='large'
                                        block
                                        disabled={
                                            !form.isFieldsTouched(true) ||
                                            !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                        }
                                    >
                                        Sign in
                                    </Button>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    {simple && (
                        <Row justify='center'>
                            <Col width='fit-content'>
                                <ApplyNow hasTopIndent onClick={onApplyNowClick} />
                            </Col>
                        </Row>
                    )}
                    {pending && <Icon.Loader />}
                </Form>
            );
        }
    )
);
