import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../utils';
import Breadcrumbs from './components/Breadcrumbs';
import EmailForm from './components/EmailForm';
import { Sidebar } from './components/Sidebar';
import SignInForm from './components/SignInForm';
import { StyledContent, StyledContentWrapper, StyledTitle, StyledWrapper } from './styled';

const UnauthorizedPreCheckout = inject('store')(
    observer(({ store }) => {
        const { state } = useLocation();
        const cartId = state?.cartId;
        const checkoutKey = state?.checkoutKey;
        const isBuyNow = state?.isBuyNow;
        const [showEmailForm, setShowEmailForm] = useState(true);
        const simple = useSimpleInterface(GRID_POINTS.lg);
        const { getCartData, pending } = store.preCheckoutStore;

        useEffect(() => {
            getCartData({ cartId, isBuyNow });
        }, []);

        return pending ? (
            <Icon.Loader />
        ) : (
            <StyledWrapper $simple={simple}>
                <Sidebar simple={simple} />
                <StyledContentWrapper $simple={simple}>
                    <StyledContent>
                        {!simple ? <StyledTitle level={2}>Checkout</StyledTitle> : null}
                        <Breadcrumbs />
                        {showEmailForm ? (
                            <EmailForm onAfterEmailWasFound={() => setShowEmailForm(false)} checkoutKey={checkoutKey} />
                        ) : (
                            <SignInForm cartId={cartId} checkoutKey={checkoutKey} />
                        )}
                    </StyledContent>
                </StyledContentWrapper>
            </StyledWrapper>
        );
    })
);

export default UnauthorizedPreCheckout;
