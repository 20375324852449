import { BrowserStorage } from '@klickly/front-packages';
import { flow, getParent, types } from 'mobx-state-tree';
import { app } from '../config';
import { kcpAPI, ROUTE_PATHS } from '../shared';
import { GIFTLY_CUSTOMER_BLOCKED_BY_IP_OR_EMAIL } from '../utils';
import { models } from './constants';

export const AccountStore = types
    .model('AccountStore', {
        data: types.maybeNull(
            types.model({
                _id: types.identifier,
                emailNotificationSubscriptions: types.array(types.string),
                klicklyTermsPrivacyAccepted: types.boolean,
                firstName: types.maybeNull(types.string),
                lastName: types.maybe(types.maybeNull(types.string)),
                email: types.string,
                signUpSource: types.maybeNull(types.string),
                shippingAddresses: types.maybeNull(types.array(models.ShippingAddressModel)),
                createdAt: types.maybeNull(types.string),
                cartId: types.maybeNull(types.string),
                avatar: types.maybeNull(types.string),
                extension: types.maybeNull(
                    types.model({
                        muted: types.boolean,
                        token: types.string
                    })
                ),
                giftly: types.maybeNull(
                    types.model({
                        _id: types.identifier,
                        modalsViewed: types.maybeNull(
                            types.model({
                                welcomeRewards: types.maybeNull(types.boolean)
                            })
                        ),
                        selectedInterestToCustomer: types.maybeNull(types.array(types.string)),
                        selectedCategories: types.maybeNull(types.array(types.string)),
                        selectedImportantToCustomer: types.maybeNull(types.array(types.string)),
                        signUpStatus: types.string,
                        customerStatus: types.string,
                        instagram: types.maybeNull(
                            types.model({
                                _id: types.maybeNull(types.string),
                                username: types.string,
                                fullName: types.maybeNull(types.string),
                                profilePicUrl: types.maybeNull(types.string),
                                isPrivate: types.boolean,
                                followers: types.maybeNull(types.number),
                                posts: types.maybeNull(types.number)
                            })
                        ),
                        inviteCode: types.maybeNull(types.string),
                        signUpStatusValidation: types.model({
                            inviteCodeCheck: types.maybeNull(types.boolean),
                            instagramCheck: types.maybeNull(types.boolean)
                        }),
                        tiktok: types.maybeNull(
                            types.model({
                                username: types.optional(types.string, '')
                            })
                        ),
                        facebook: types.maybeNull(
                            types.model({
                                username: types.optional(types.string, '')
                            })
                        ),
                        signUpApprovedLaterAt: types.maybeNull(types.string),
                        onboardedAt: types.maybeNull(types.string),
                        testMode: types.optional(types.boolean, false),
                        referralInviteCode: types.maybeNull(types.string)
                    })
                ),
                wishlist: types.maybeNull(
                    types.array(
                        types.model({
                            _id: types.identifier,
                            brandName: types.maybeNull(types.string),
                            image: types.maybeNull(types.string),
                            promotionId: types.maybeNull(types.string),
                            title: types.maybeNull(types.string),
                            variantId: types.maybeNull(types.number),
                            available: types.maybeNull(types.boolean)
                        })
                    )
                )
            })
        ),
        billingData: types.maybeNull(types.array(models.BillingProfileModel)),
        pending: true,
        processing: false,
        pendingBilling: false,
        pendingWishlist: false,
        loaded: false,
        errorMessage: types.maybeNull(types.string),
        billingErrorMessage: types.maybeNull(types.string),
        isCustomerBanned: false,
        avatarLoading: false,
        avatarErrorMessage: types.maybeNull(types.string),
        stripeClientSecret: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },

        get account() {
            return self.data || {};
        },

        get isLoggedIn() {
            return Boolean(self.data && self.data._id);
        },

        get customerId() {
            return self.data?._id || null;
        },

        get avatar() {
            return self.data?.avatar || '';
        },

        get firstName() {
            return self.data?.firstName || '';
        },

        get lastName() {
            return self.data?.lastName || '';
        },

        get giftlyData() {
            return self.data?.giftly || null;
        },

        get profileImageURL() {
            return self.data?.giftly?.instagram?.profilePicUrl || null;
        },

        get profileEmail() {
            return self.data?.email || null;
        },

        get instagramCheck() {
            return (
                self.data?.giftly?.signUpStatusValidation?.instagramCheck ||
                self.data?.giftly?.facebook?.username ||
                self.data?.giftly?.tiktok?.username ||
                null
            );
        },

        get isValidInviteCodeUsed() {
            return self.data?.giftly?.signUpStatusValidation?.inviteCodeCheck;
        },

        get isCustomerStatusOnboarding() {
            return self.data?.giftly?.customerStatus === 'onboarding';
        },

        get hasMarketingEmailSubscription() {
            return (
                self.data?.emailNotificationSubscriptions &&
                self.data.emailNotificationSubscriptions.includes('marketing')
            );
        },

        get accountModalsViewed() {
            return self.data?.giftly?.modalsViewed || {};
        },

        get shippingAddresses() {
            return self.data?.shippingAddresses || [];
        },

        get defaultShippingAddress() {
            return self.data?.shippingAddresses
                ? self.data.shippingAddresses.find((address) => address.isDefault)
                : null;
        },

        get defaultBilling() {
            return self.billingData ? self.billingData.find((data) => data.isDefault) : null;
        },

        get signUpStatus() {
            return self.data?.giftly?.signUpStatus;
        },

        get chromeExtensionId() {
            return self.data?.extension?.token;
        },

        get extensionInstalled() {
            return self.data?.extension?.token;
        },

        get extensionMuted() {
            return self.data?.extension?.muted;
        },

        get selectedCategories() {
            return self.data?.giftly?.selectedCategories || [];
        },

        get signUpStatusRejected() {
            return self.signUpStatus === 'rejected';
        },

        get isApprovedCustomer() {
            return self.isLoggedIn && self.giftlyData && self.signUpStatus === 'approved';
        },

        get isRejectedCustomer() {
            return (
                self.isLoggedIn &&
                self.giftlyData &&
                self.signUpStatus === 'rejected' &&
                !self.isValidInviteCodeUsed &&
                !self.instagramCheck
            );
        },

        get testMode() {
            return self.data?.giftly?.testMode || false;
        },

        get wishlist() {
            return self.data?.wishlist || [];
        },

        get wishlistAmount() {
            return self.data?.wishlist?.length || 0;
        },

        get referralInviteLink() {
            return self.data?.giftly?.referralInviteCode
                ? `${app.giftlyUrl}?inviteCode=${self.data.giftly.referralInviteCode}`
                : '';
        }
    }))
    .actions((self) => {
        const setPending = (pending) => {
            self.pending = pending;
        };

        const setBillingPending = (pending) => {
            self.pendingBilling = pending;
        };

        const setAvatarErrorMessage = (message) => {
            self.avatarErrorMessage = message;
        };

        const removeCustomerAvatar = flow(function* removeCustomerAvatar() {
            self.avatarLoading = true;
            yield self.root.delete(kcpAPI.customer.removeAvatar);

            self.data.avatar = '';
            self.avatarLoading = false;
        });

        const uploadCustomerAvatar = flow(function* uploadCustomerAvatar({ onSuccess, onError, file, onProgress }) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                self.avatarLoading = true;
                const avatar = yield self.root.post(kcpAPI.customer.uploadAvatar, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                self.data.avatar = avatar.url;
            } catch (error) {
                self.avatarErrorMessage = 'Invalid image. Please choose another image';
                self.avatarLoading = false;
            } finally {
                self.avatarLoading = false;
            }
        });

        const setCustomerAvatar = (data) => {
            self.data.avatar = data;
        };

        const setError = (error) => {
            if (error && error.response && error.response.data) {
                self.errorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.errorMessage = error.message;
            }
        };

        const setBillingError = (error) => {
            if (error && error.response && error.response.data) {
                self.billingErrorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.billingErrorMessage = error.message;
            }
        };

        const setBlockCustomer = (status) => {
            self.isCustomerBanned = status;
        };

        const clearError = () => {
            self.errorMessage = null;
        };

        const setAccountData = (accountData) => {
            self.data = accountData;
            onGetAccountData(accountData);
        };

        const getAccountData = flow(function* getAccountData(onAfterSuccess = () => null) {
            clearError();
            setPending(true);
            try {
                const account = yield self.root.get(kcpAPI.auth.me);
                if (account.error === GIFTLY_CUSTOMER_BLOCKED_BY_IP_OR_EMAIL) {
                    self.isCustomerBanned = true;
                    self.root.routeNavigate({ pathname: ROUTE_PATHS.blocked }, { replace: true });
                }
                self.data = account;
                onGetAccountData(account);
                onAfterSuccess();
            } catch (e) {
                // nothing to do
            } finally {
                setPending(false);
                self.loaded = true;
            }
        });

        const onGetAccountData = (data) => {
            self.root.trackingStore.sendEvent({
                type: 'meta',
                name: 'profile',
                data: data
                    ? {
                          _id: data._id,
                          email: data.email,
                          phone: data.phone
                      }
                    : undefined
            });
        };

        const updateAccountData = flow(function* updateAccountData(data, form) {
            clearError();
            setPending(true);
            try {
                const response = yield self.root.put(kcpAPI.customer.update, data);
                self.data = { ...self.data, ...{ firstName: response.firstName, lastName: response.lastName } };
                form.setFieldsValue(data);
                form.resetFields();
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
            }
        });

        const updateCustomerInfo = flow(function* updateCustomerInfo(data, onAfterSuccess = () => null) {
            clearError();
            setPending(true);
            try {
                yield self.root.put(kcpAPI.customer.giftlyUpdate, data);
                yield getAccountData();
                onAfterSuccess();
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
            }
        });

        const updateMarketingEmailSubscription = flow(function* updateMarketingEmailSubscription(key, selected) {
            clearError();
            try {
                if (selected) {
                    self.data.emailNotificationSubscriptions.push(key);
                } else {
                    self.data.emailNotificationSubscriptions = self.data.emailNotificationSubscriptions.filter(
                        (subscription) => subscription !== key
                    );
                }
                yield self.root.put(kcpAPI.customer.update, {
                    emailNotificationSubscriptions: self.data.emailNotificationSubscriptions
                });
            } catch (e) {
                setError(e);
            }
        });

        const addShippingAddress = flow(function* addShippingAddress(
            shippingAddress,
            onAfterSuccess = () => null,
            onShippingErrorCallback = () => null
        ) {
            clearError();
            setPending(true);
            try {
                yield self.root.post(kcpAPI.customerData.address(), shippingAddress);
                yield getAccountData();
                const { completeTask, addShippingTask } = self.root.tasksStore;
                const taskId = addShippingTask?._id;
                const taskData = addShippingTask?.data;
                const isTaskActive = taskData?.status === 'active';
                if (self.root.accountStore.isApprovedCustomer && isTaskActive && taskId) {
                    const taskParams = {
                        taskPayload: shippingAddress,
                        taskId,
                        sources: 'claims',
                        onAfterSuccess: () => onAfterSuccess({ taskCompleted: true })
                    };
                    yield completeTask(taskParams);
                    yield self.root.suiteStore.getCurrentSuite();
                } else {
                    onAfterSuccess({ taskCompleted: false });
                }
            } catch (e) {
                setError(e);
                onShippingError();
                onShippingErrorCallback();
            } finally {
                setPending(false);
                BrowserStorage.set('customerFirstName', '');
            }
        });

        const updateShippingAddress = flow(function* updateShippingAddress(shippingAddress) {
            clearError();
            setPending(true);
            try {
                const data = {
                    ...shippingAddress,
                    countryCode: 'US',
                    countryName: 'United States'
                };
                yield self.root.put(kcpAPI.customerData.address(data._id), data);
                yield getAccountData();
                self.root.routeNavigate({ pathname: ROUTE_PATHS.shippingBilling });
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
            }
        });

        const deleteShippingAddress = flow(function* deleteShippingAddress(addressId) {
            clearError();
            setPending(true);
            try {
                yield self.root.delete(kcpAPI.customerData.address(addressId));
                self.root.routeNavigate({ pathname: ROUTE_PATHS.shippingBilling });
                yield getAccountData();
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
            }
        });

        const setShippingAddressAsDefault = flow(function* setShippingAddressAsDefault(addressId) {
            clearError();
            try {
                yield self.root.patch(kcpAPI.customerData.address(addressId));
                self.data.shippingAddresses = [
                    ...self.data.shippingAddresses.map((address) => ({
                        ...address,
                        isDefault: address._id === addressId
                    }))
                ];
            } catch (e) {
                setError(e);
            }
        });

        const onShippingError = () => {
            self.root.trackingStore.sendEvent({
                type: 'event',
                name: 'error',
                data: { message: self.errorMessage }
            });
        };

        const getBillingData = flow(function* getBillingData() {
            self.billingErrorMessage = null;
            self.pendingBilling = true;
            try {
                if (self.customerId) {
                    self.billingData = yield self.root.get(kcpAPI.customerData.payment());
                }
            } catch (e) {
                setBillingError(e);
            } finally {
                self.pendingBilling = false;
            }
        });

        const addBillingProfile = flow(function* addBillingProfile(paymentMethodId) {
            self.billingErrorMessage = null;
            self.pendingBilling = true;
            try {
                self.billingData = yield self.root.post(kcpAPI.customerData.payment(), { paymentMethodId });
                self.root.routeNavigate({ pathname: ROUTE_PATHS.shippingBilling });
            } catch (e) {
                setBillingError(e);
            } finally {
                self.pendingBilling = false;
            }
        });

        const updateBillingProfile = flow(function* updateBillingProfile(stripeCardId, data) {
            self.billingErrorMessage = null;
            self.pendingBilling = true;
            try {
                self.billingData = yield self.root.put(kcpAPI.customerData.payment(stripeCardId), data);
                self.root.routeNavigate({ pathname: ROUTE_PATHS.shippingBilling });
            } catch (e) {
                setBillingError(e);
            } finally {
                self.pendingBilling = false;
            }
        });

        const deleteBillingProfile = flow(function* deleteBillingProfile(billingId) {
            self.billingErrorMessage = null;
            self.pendingBilling = true;
            try {
                yield self.root.delete(kcpAPI.customerData.payment(billingId));
                self.root.routeNavigate({ pathname: ROUTE_PATHS.shippingBilling });
                yield getBillingData();
            } catch (e) {
                setBillingError(e);
            } finally {
                self.pendingBilling = false;
            }
        });

        const setBillingProfileAsDefault = flow(function* setBillingProfileAsDefault(billingId) {
            clearError();
            try {
                yield self.root.patch(kcpAPI.customerData.payment(billingId));
                self.billingData = [
                    ...self.billingData.map((billing) => ({
                        ...billing,
                        isDefault: billing._id === billingId
                    }))
                ];
            } catch (e) {
                setError(e);
            }
        });

        const sendFeedback = flow(function* sendFeedback({ payload, onAfterSuccess = () => null }) {
            clearError();
            self.processing = true;
            try {
                yield self.root.post(kcpAPI.feedback.send, payload);
                onAfterSuccess();
            } catch (e) {
                setError(e);
            } finally {
                self.processing = false;
            }
        });

        const toggleExtensionPanelMute = () => {
            if (self.data?.extension) {
                self.data.extension.muted = !self.data.extension.muted;
            }
        };

        const clearAccountState = () => {
            self.data = null;
        };

        const updateAccountWishlist = flow(function* updateAccountWishlist({
            wishlists = [],
            onAfterSuccess = () => null
        }) {
            clearError();
            self.pendingWishlist = true;
            try {
                yield self.root.post(kcpAPI.customer.addToWishlist, { wishlists });
                onAfterSuccess();
            } catch (e) {
                setError(e);
            } finally {
                self.pendingWishlist = false;
            }
        });

        const removeWishlistItem = flow(function* removeWishlistItem(productId) {
            clearError();
            self.pendingWishlist = true;
            try {
                yield self.root.delete(kcpAPI.customer.removeItemWishlist(productId));
                yield getAccountData();
            } catch (e) {
                setError(e);
            } finally {
                self.pendingWishlist = false;
            }
        });

        const getStripeClientSecret = flow(function* getStripeClientSecret() {
            try {
                const data = yield self.root.get(kcpAPI.stripe.getStripeClientSecret);

                if (data.clientSecret) {
                    self.stripeClientSecret = data.clientSecret;
                }
            } catch (e) {
                setError(e);
            }
        });

        return {
            uploadCustomerAvatar,
            removeCustomerAvatar,
            setAvatarErrorMessage,
            setCustomerAvatar,
            setBlockCustomer,
            setAccountData,
            getAccountData,
            updateAccountData,
            updateCustomerInfo,
            updateMarketingEmailSubscription,
            addShippingAddress,
            updateShippingAddress,
            deleteShippingAddress,
            setShippingAddressAsDefault,
            getBillingData,
            addBillingProfile,
            setBillingError,
            updateBillingProfile,
            deleteBillingProfile,
            setBillingProfileAsDefault,
            toggleExtensionPanelMute,
            clearAccountState,
            sendFeedback,
            updateAccountWishlist,
            removeWishlistItem,
            getStripeClientSecret,
            setBillingPending
        };
    });
