import { Button, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../utils';
import { $border } from '../../../../constants';

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    ${({ $withBorder }) =>
        $withBorder
            ? css`
                  padding-bottom: 24px;
                  border-bottom: 4px solid ${$border};
              `
            : ''}
`;

export const StyledControls = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledSeeAllButton = styled(Button)`
    && {
        padding: 8px 24px;

        @media (${GRID_DEVICES_MAX.sm}) {
            ${({ $simple }) =>
                $simple &&
                css`
                    padding-right: 0;
                    font-size: 14px;
                `};
        }
    }
`;

export const StyledSlideCounter = styled(Typography.Text)`
    padding: 8px 24px;
    font-weight: 500;

    @media (${GRID_DEVICES_MAX.sm}) {
        ${({ $simple }) =>
            $simple &&
            css`
                padding-right: 0;
                font-size: 14px;
            `};
    }
`;

export const StyledTitle = styled(Typography.Title)`
    @media (${GRID_DEVICES_MAX.sm}) {
        ${({ $simple, $isRecommendation }) =>
            $simple &&
            !$isRecommendation &&
            css`
                font-family: 'IBM Plex Sans', Sans;
            `};
    }
`;
