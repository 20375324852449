import React, { Fragment, useEffect } from 'react';
import { Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { NOTIFICATION_MESSAGES } from '../../../../../../shared';
import { PageHeader } from '../../../../../../shared/components/PageHeader';
import { useProductReviewNotification } from '../../../../../../shared/hooks/useProductReviewNotification';
import { NoOrdersMessage } from './components/NoOrdersMessage';
import { OrdersByDateList } from './components/OrdersByDateList';
import { OrdersFooter } from './components/OrdersFooter';

export const OrdersContainer = inject('store')(
    observer(({ store }) => {
        const { meta, pending, getOrders } = store.ordersStore;
        const { totalDocuments } = meta;

        useProductReviewNotification(store, NOTIFICATION_MESSAGES.PRODUCT_REVIEW[0]);

        useEffect(() => {
            getOrders();
        }, []);

        return (
            <Fragment>
                <PageHeader hasBottomIndent title='Orders' />
                {totalDocuments > 0 ? (
                    <Fragment>
                        <OrdersByDateList />
                        <OrdersFooter />
                    </Fragment>
                ) : (
                    <NoOrdersMessage />
                )}

                {pending && <Icon.Loader />}
            </Fragment>
        );
    })
);
