import React, { Fragment } from 'react';
import { BOTTOM_INDENTS, GiftIcon, Icon, PointsIcon, TimeIcon, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $primaryText, $secondaryText, TASK_STATUSES } from '../../constants';
import { ChallengeIcon } from '../ChallengeIcon';
import { RewardsTasksStyles, RewardTaskFooter } from './styled';
import { getTaskNameByStatus } from './utils';

const Challenge = inject('store')(
    observer(({ store, task }) => {
        // for different tasks different check of disabled state;
        const disabled =
            task.data.status === TASK_STATUSES.completed ||
            task.data.status === TASK_STATUSES.inactive ||
            task.data.status === TASK_STATUSES.disable;

        return (
            <RewardsTasksStyles $disabled={disabled} onClick={() => store.tasksStore.onTaskClick(task)}>
                <ChallengeIcon
                    alias={task.alias}
                    size='20px'
                    backgroundSize='40px'
                    color='canvas'
                    backgroundColor={$primaryText}
                    style={{ ...BOTTOM_INDENTS.S, opacity: disabled ? '0.5' : '1' }}
                />

                {task.data.title && (
                    <Typography.Title level={3} color={disabled ? $secondaryText : $primaryText}>
                        {task.data.title}
                    </Typography.Title>
                )}

                {task.data.description && (
                    <Typography.Text type='body2' color={disabled ? $secondaryText : $primaryText}>
                        {task.data.description}
                    </Typography.Text>
                )}

                <RewardTaskFooter>
                    <Typography.Text>
                        {Boolean(task.data.rewardValue) && (
                            <Fragment>
                                <Icon component={() => (task.data.rewardValue < 50 ? <GiftIcon /> : <PointsIcon />)} />
                                <Typography.Text type='descriptor2' as='span'>
                                    &nbsp;&nbsp;+&nbsp;{task.data.rewardValue}
                                </Typography.Text>
                            </Fragment>
                        )}
                    </Typography.Text>

                    {
                        // if some task has a requiredTime field and it is not inactive or completed status - show it
                        task.data.requiredTime &&
                            task.data.status !== TASK_STATUSES.inactive &&
                            task.data.status !== TASK_STATUSES.completed && (
                                <Typography.Text color='secondaryText'>
                                    <Icon component={() => <TimeIcon />} />
                                    <Typography.Text type='descriptor2' as='span'>
                                        &nbsp;&nbsp;{task.data.requiredTime}
                                    </Typography.Text>
                                </Typography.Text>
                            )
                    }

                    {
                        // show task status;
                        (task.data.status === TASK_STATUSES.completed ||
                            task.data.status === TASK_STATUSES.inactive) && (
                            <Typography.Text color='success' type='descriptor2'>
                                {getTaskNameByStatus(task.data.status)}
                            </Typography.Text>
                        )
                    }
                </RewardTaskFooter>
            </RewardsTasksStyles>
        );
    })
);

export default Challenge;
