import styled from 'styled-components';
import { $primaryText } from '../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const StyledWrapper = styled.div`
    margin-bottom: 80px;

    @media (${GRID_DEVICES_MAX.md}) {
        margin-bottom: 64px;
    }

    button.ant-btn-circle {
        border-radius: 50%;
    }

    .slick-dots li button {
        opacity: 1;
    }

    .popover-with-button {
        width: 300px;

        .ant-popover-arrow {
            &:after,
            &:before {
                background: ${$primaryText};
            }
        }
    }
`;

export const StyledHeader = styled.div`
    padding: 12px 0;

    .ant-col {
        display: flex;
        align-items: center;
    }

    .ant-btn {
        padding-left: 0;
        padding-right: 0;
    }
`;
