import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $primary, $secondaryText } from '../../constants';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledTitle = styled(Typography.Text)`
    margin-bottom: 24px;

    > span {
        color: ${$primary};
        font-weight: 500;
    }
`;

export const StyledContent = styled.div`
    width: 100%;
    padding: 20px 0;
`;

export const StyledItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const StyledInfo = styled.div`
    flex-grow: 1;
    margin-left: 16px;
`;

export const StyledUserName = styled(Typography.Text)`
    font-weight: normal;
    color: ${$secondaryText};
    margin-bottom: 4px;
`;

export const StyledProductTitle = styled(Typography.Title)`
    margin-bottom: 4px;
`;

export const StyledPrice = styled(Typography.Text)`
    font-weight: 500;
`;
