import { Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../utils';
import { $primary } from '../../../../constants';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (${GRID_DEVICES_MAX.md}) {
        align-items: flex-start;
    }
`;

export const StyledMessage = styled(Typography.Title)`
    width: 100%;

    ${({ $withBottomIndent }) =>
        $withBottomIndent &&
        css`
            margin-bottom: 16px;
        `};
`;

export const StyledText = styled.span`
    color: ${$primary};
`;

export const StyledMainText = styled.div`
    && {
        gap: 5px;
        display: flex;
        justify-content: ${({ $justify = 'center' }) => $justify};
        align-items: center;

        button {
            padding: 0 20px;
        }
    }
`;
