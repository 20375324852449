import React, { useEffect, useState } from 'react';
import { useSearchParam } from 'react-use';
import { Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { BillingForm } from '../../../BillingForm';
import { StyledWrapper } from './styles';

export const EditBilling = inject('store')(
    observer(({ store }) => {
        const {
            billingData: data,
            updateBillingProfile,
            deleteBillingProfile,
            setBillingProfileAsDefault,
            billingErrorMessage,
            billingPending
        } = store.accountStore;
        const showDeleteButton = data && data.length && data.length > 1;
        const [payment, setPayment] = useState();
        const paymentId = useSearchParam('id');

        useEffect(() => {
            if (!data) {
                return;
            }

            if (!paymentId) {
                return;
            }

            // eslint-disable-next-line
            // TODO: rewrite with selector;
            const currentPaymentMethod = data.find((payment) => payment._id === paymentId);

            if (currentPaymentMethod) {
                setPayment(currentPaymentMethod);
            } else {
                return new Error('wrong payment method id');
            }
        }, [data, paymentId, setPayment]);

        return (
            <StyledWrapper>
                {payment ? (
                    <BillingForm
                        onSubmit={updateBillingProfile}
                        payment={payment}
                        error={billingErrorMessage}
                        defaultAddress={payment.isDefault}
                        showDeletePaymentButton={showDeleteButton}
                        setDefault={() => setBillingProfileAsDefault(payment._id)}
                        onDelete={() => deleteBillingProfile(payment._id)}
                    />
                ) : (
                    <Icon.Loader />
                )}
                {billingPending && <Icon.Loader />}
            </StyledWrapper>
        );
    })
);
