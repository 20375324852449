import React, { useEffect, useRef } from 'react';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { CheckoutShoppingCart, CheckoutShoppingCartHTTPWrapper, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import {
    CART_REDIRECTS,
    kcpAPI,
    ROUTE_PATHS,
    SEASONAL_CHALLENGE_ALIASES,
    useRewardAndPointsModal,
    useSeasonalChallengeNotifications
} from '../../shared';
import FavoritesPromotions from '../../shared/components/FavoritesPromotions';
import { Layout } from '../../shared/components/Layout';
import { getMetadata } from '../../shared/utils/metadata';
import { GRID_POINTS } from '../../utils';
import { ButtonBadge } from './components/ButtonBadge';
import { useBuyProductNotification, useHandleGoToCheckout } from './hooks';
import { StyledFavoritesWrap } from './styles';

const ShoppingCartPage = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const navigationType = useNavigationType();
        const favoritesRef = useRef(null);
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { getShoppingCartState } = store.shoppingCartStore;
        const { isLoggedIn } = store.accountStore;
        const { activeChallenge } = store.seasonalChallengeStore;
        const withUpsellCheck = isLoggedIn && !store.ordersStore.orders?.length;
        const handleCheckout = useHandleGoToCheckout(store, isLoggedIn, withUpsellCheck);
        const message = 'Step 3 of 3. Proceed to Checkout and finish your order';
        const { openRewardModal, openPointsModal } = useRewardAndPointsModal({
            isLoggedIn,
            additionalProps: {
                notLoggedInRedirectUrl: `${ROUTE_PATHS.signIn}?redirectTo=${ROUTE_PATHS.shoppingCart}`
            }
        });

        const showSeasonalChallengeNotification = useSeasonalChallengeNotifications(store);

        useBuyProductNotification(store);

        useEffect(() => {
            return () => {
                getShoppingCartState();
            };
        }, [getShoppingCartState]);

        useEffect(() => {
            store.favoritesPromotionsStore.getFavoritesPromotionsByStrategy();
        }, []);

        useEffect(() => {
            // reload page if it was loaded from cache (Safari issue with SC loader after back browser button click from checkout)
            window.onpageshow = function (event) {
                if (event.persisted) {
                    window.location.reload();
                }
            };

            if (
                activeChallenge?.alias === SEASONAL_CHALLENGE_ALIASES.MAKE_PURCHASE &&
                activeChallenge.challengeAccepted
            ) {
                showSeasonalChallengeNotification('3', message);
            }
        }, []);

        const onBackButtonClick = () => {
            navigate(-1);
        };

        return (
            <Layout withoutPadding contentFullHeight>
                <CheckoutShoppingCartHTTPWrapper
                    links={{
                        cartFetch: kcpAPI.cart.get,
                        cartItemsManage: kcpAPI.cart.itemsManage,
                        buyNow: kcpAPI.cart.buyNow,
                        pointsFetch: kcpAPI.cart.points.get,
                        pointsApply: kcpAPI.cart.points.apply,
                        pointsRemove: kcpAPI.cart.points.remove
                    }}
                    onCheckoutButtonClick={handleCheckout}
                    onBackButtonClick={navigationType === 'PUSH' ? onBackButtonClick : null}
                    onRewardIconClick={openRewardModal}
                    onPointsIconClick={openPointsModal}
                    onCartCouponRemove={() => {
                        navigate(ROUTE_PATHS.suiteGs);
                    }}
                >
                    <CheckoutShoppingCart
                        isLoggedIn={isLoggedIn}
                        metadata={getMetadata()}
                        redirects={CART_REDIRECTS}
                        customizations={{
                            showProductPoints: isLoggedIn,
                            showApplyPointsBlock: isLoggedIn,
                            showRewards: true
                        }}
                        bottomExtraContent={
                            <StyledFavoritesWrap $simple={simple} ref={favoritesRef}>
                                <FavoritesPromotions
                                    promotions={store.favoritesPromotionsStore.favoritesPromotions}
                                    pending={store.favoritesPromotionsStore.pending}
                                />
                            </StyledFavoritesWrap>
                        }
                        buttonBadge={
                            <ButtonBadge
                                onClick={() => favoritesRef?.current?.scrollIntoView({ behavior: 'smooth' })}
                            />
                        }
                    />
                </CheckoutShoppingCartHTTPWrapper>
            </Layout>
        );
    })
);

export default ShoppingCartPage;
