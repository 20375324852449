import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { OrdersData } from './components/OrdersData';
import { OrderSummary } from './components/orderSummary';
import { Topbar } from './components/Topbar';
import { WrapStyles } from './styles';

export const Sidebar = observer(({ simple }) => {
    return (
        <WrapStyles>
            {simple ? (
                <Topbar>
                    <OrdersData simple={simple} />
                </Topbar>
            ) : (
                <Fragment>
                    <OrderSummary />
                    <OrdersData simple={simple} />
                </Fragment>
            )}
        </WrapStyles>
    );
});
