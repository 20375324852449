import { useCallback } from 'react';
import { useScrollable } from '@klickly/front-packages';
import { useLayout } from '../../../../../shared';

export const useLayoutOnBottomHandler = (getPromotions, nextPage, pending) => {
    const { bodyRef } = useLayout();

    const getNextPage = useCallback(() => {
        if (nextPage && !pending) {
            getPromotions(true);
        }
    }, [getPromotions, nextPage]);
    useScrollable(bodyRef, null, getNextPage);
};
