import styled from 'styled-components';
import { $background } from '../../../../../../../../../../shared';

export const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    background-color: ${$background};

    & input {
        opacity: 1;
    }
`;
