import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { notification, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../shared';
import NotificationContent from '../../../../shared/components/NotificationContent';
import { PersonalizationCategoriesForm } from '../../../../shared/components/PersonalizationCategoriesForm';
import { GRID_POINTS } from '../../../../utils';
import { StyledFormWrapper, StyledSectionName, StyledTitle, StyledWrapper } from './styled';

const FavoriteCategories = inject('store')(
    observer(({ store }) => {
        const { state } = useLocation();
        const navigate = useNavigate();
        const { isApprovedCustomer } = store.accountStore;
        const { selectCategoriesTask } = store.tasksStore;
        const challenge = store.tasksStore?.selectCategoriesTask;
        const requiredCategoriesCount = 3;
        const [categoriesToSelectCount, setCategoriesToSelectCount] = useState(3);
        const simple = useSimpleInterface(GRID_POINTS.sm);
        const Wrapper = simple ? Fragment : StyledWrapper;
        const message =
            categoriesToSelectCount > 0
                ? `Please select ${categoriesToSelectCount} categor${categoriesToSelectCount > 1 ? 'ies' : 'y'}`
                : 'Save your shopping preferences';
        const step = categoriesToSelectCount > 0 ? '1' : '2';

        useEffect(() => {
            if (
                state === selectCategoriesTask?.alias &&
                selectCategoriesTask?.data?.status === 'active' &&
                isApprovedCustomer
            ) {
                store.trackingStore.trackChallengeNotification(challenge, step, message);
                notification.open({
                    key: selectCategoriesTask?.alias,
                    duration: null,
                    description: <NotificationContent.Reward rewardCount={50} message={message} />,
                    bottom: simple ? '111px' : '8px'
                });
            }
            return () => (typeof notification.destroy === 'function' ? notification.destroy() : null);
        }, [categoriesToSelectCount]); //eslint-disable-line

        return (
            <Wrapper>
                <StyledSectionName type='body2'>Account</StyledSectionName>
                <StyledTitle level={2}>Favorite categories</StyledTitle>
                <StyledFormWrapper>
                    <PersonalizationCategoriesForm
                        submitButtonText='Save changes'
                        onSelectedAmountChanged={(selected) =>
                            setCategoriesToSelectCount(requiredCategoriesCount - selected)
                        }
                        onAfterSuccess={({ taskCompleted }) => {
                            if (taskCompleted) {
                                store.trackingStore.trackChallengeNotification(
                                    challenge,
                                    'completed',
                                    `${selectCategoriesTask?.data?.title} completed!`
                                );
                                notification.open({
                                    key: selectCategoriesTask?.alias,
                                    duration: null,
                                    description: (
                                        <NotificationContent.Completed
                                            completedActionName={selectCategoriesTask?.data?.title}
                                        />
                                    ),
                                    bottom: simple ? '111px' : '8px'
                                });
                                notification.open({
                                    duration: null,
                                    description: <NotificationContent.RewardEarned value={50} navigate={navigate} />,
                                    bottom: simple ? '111px' : '8px'
                                });
                                navigate(ROUTE_PATHS.challenges);
                            }
                        }}
                    />
                </StyledFormWrapper>
            </Wrapper>
        );
    })
);

export default FavoriteCategories;
