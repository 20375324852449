import React, { useRef } from 'react';
import nextId from 'react-id-generator';
import { Carousel } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { StyledImage, StyledImgWrapper, StyledSliderWrap } from './styles';

export const ProductImage = observer(({ images, simple }) => {
    const carouselRef = useRef(null);

    const showDots = images?.length < 10;

    const renderImages = () => {
        if (images?.length) {
            return images.map((image) => <StyledImage $simple={simple} key={nextId()} src={image.src} />);
        }

        return null;
    };

    return (
        <StyledImgWrapper $simple={simple}>
            {!simple ? (
                <StyledSliderWrap>
                    <Carousel.WithPreview carouselRef={carouselRef} previews={renderImages()}>
                        {renderImages()}
                    </Carousel.WithPreview>
                </StyledSliderWrap>
            ) : (
                <StyledSliderWrap $simple={simple}>
                    <Carousel dots={showDots ? { bottom: '-4px' } : false} arrows carouselRef={carouselRef}>
                        {renderImages()}
                    </Carousel>
                </StyledSliderWrap>
            )}
        </StyledImgWrapper>
    );
});
