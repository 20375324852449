import { types } from 'mobx-state-tree';

export const PointItemModel = types.model({
    _id: types.identifier,
    action: types.string,
    type: types.string,
    reason: types.string,
    value: types.maybeNull(types.number),
    customer: types.string,
    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string)
});
