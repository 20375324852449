import React from 'react';
import { Outlet } from 'react-router-dom';
import { GRID_POINTS, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { Layout } from '../../shared/components/Layout';
import InviteAndEarnSidebar from './components/InviteAndEarnSidebar';
import { ROUTES } from './constants/routes';

const InviteAndEarnPage = observer(() => {
    const simple = useSimpleInterface(GRID_POINTS.lg);

    return (
        <Layout hasFooter={!simple} sidebar={simple ? null : <InviteAndEarnSidebar />} contentFullHeight>
            <Outlet />
        </Layout>
    );
});

InviteAndEarnPage.ROUTES = ROUTES;

export default InviteAndEarnPage;
