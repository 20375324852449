import moment from 'moment';

export const seasonalChallengeName = () => {
    const currentMonth = parseInt(moment().format('M'));

    switch (true) {
        case [12, 1, 2].includes(currentMonth):
            return 'Winter';
        case [3, 4, 5].includes(currentMonth):
            return 'Spring';
        case [6, 7, 8].includes(currentMonth):
            return 'Summer';
        case [9, 10, 11].includes(currentMonth):
            return 'Autumn';
        default:
            return 'Spring';
    }
};
