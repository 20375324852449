import styled from 'styled-components';
import { Animated } from '../../../../shared/components/Animated';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const Styles = styled(Animated)`
    max-width: 845px;
    margin: auto;

    @media (${GRID_DEVICES_MAX.sm}) {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }
`;
