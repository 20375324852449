import React from 'react';
import { Animated } from '../../../../shared/components/Animated';
import { PageHeader } from '../../../../shared/components/PageHeader';
import { AddBilling } from './components/AddBilling';

const BillingAddPage = () => {
    return (
        <Animated style={{ maxWidth: '845px', margin: 'auto' }}>
            <PageHeader hasBottomIndent title='Add billing information' subTitle='' withGoBack />
            <AddBilling />
        </Animated>
    );
};

export default BillingAddPage;
