import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    ForwardArrowIcon,
    Icon,
    LEFT_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../../../shared';
import { StyledButton } from './styled';

const Completed = inject('store')(
    observer(({ store }) => {
        const simple = useSimpleInterface(1180);
        const { seasonalChallenge, challenges } = store.seasonalChallengeStore;
        const isCompleted = seasonalChallenge?.completed || !challenges?.length;
        const isClaim = seasonalChallenge?.reward?.type === 'claim';
        const navigate = useNavigate();
        return (
            <Fragment>
                <Typography.Title level={3} style={BOTTOM_INDENTS.S} align={simple ? 'center' : 'left'}>
                    {seasonalChallenge?.name} completed!
                </Typography.Title>
                {isCompleted ? (
                    <Typography.Text type='descriptor1' style={BOTTOM_INDENTS.M} align={simple ? 'center' : 'left'}>
                        Congratulations on completing all 5 challenges and earning your {seasonalChallenge?.name} bonus
                        gift! We’ve automatically added your{' '}
                        {isClaim ? (
                            ' Gifting Suites '
                        ) : (
                            <Fragment>
                                {seasonalChallenge?.reward?.type === 'coupon' ? ' Coupon ' : ' Points '}
                            </Fragment>
                        )}{' '}
                        to your account.
                    </Typography.Text>
                ) : (
                    <Typography.Text type='descriptor1' style={BOTTOM_INDENTS.M} align={simple ? 'center' : 'left'}>
                        Congratulations on completing all 5 challenges and earning your {seasonalChallenge?.name} bonus
                        gift! Click Claim Reward to get 3 free{' '}
                        {isClaim ? (
                            ' Gifting Suites'
                        ) : (
                            <Fragment>{seasonalChallenge?.reward?.type === 'coupon' ? ' Coupon' : ' Points'}</Fragment>
                        )}
                        .
                    </Typography.Text>
                )}
                <StyledButton
                    type='primary'
                    onClick={() =>
                        !isCompleted
                            ? navigate(`/reward/${seasonalChallenge?.reward?.uuid}`)
                            : navigate(ROUTE_PATHS.challenges)
                    }
                    $simple={simple}
                >
                    {isCompleted ? 'Explore other Challenges' : 'Claim Reward'}
                    <Icon style={LEFT_INDENTS.S} size='24px' component={() => <ForwardArrowIcon />} />
                </StyledButton>
            </Fragment>
        );
    })
);

export default Completed;
