import React, { Fragment } from 'react';
import { BOTTOM_INDENTS, InfoIcon, TOP_INDENTS, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $grayScale60Black, $primary, $white } from '../../../../../../shared';
import { ButtonWrapper, StyledButton, StyledButtonInfo, StyledIcon } from './styled';

const Info = inject('store')(
    observer(({ content, mobile }) => {
        return (
            <Fragment>
                {(mobile && !content.rightPart?.logo?.mobile) || !content.rightPart?.logo?.component ? null : (
                    <StyledIcon
                        style={mobile ? TOP_INDENTS.L : BOTTOM_INDENTS.XXL}
                        roundedOpacity='1'
                        size={mobile ? '32px' : '80px'}
                        roundedSize={mobile ? '64px' : '160px'}
                        hoverRoundedColor={mobile ? $primary : $white}
                        roundedColor={mobile ? $primary : $white}
                        color={mobile ? $white : $primary}
                        component={content.rightPart.logo.component}
                    />
                )}
                {content.rightPart.getTitle()}
                {content.rightPart.description && (
                    <Typography.Title
                        style={mobile ? TOP_INDENTS.S : TOP_INDENTS.M}
                        color={mobile ? $grayScale60Black : $white}
                        align='center'
                        level={3}
                    >
                        {content.rightPart.description}
                    </Typography.Title>
                )}
                {content.rightPart.info ? (
                    <ButtonWrapper>
                        {content.rightPart.button ? (
                            <StyledButton
                                type={mobile ? 'secondary' : 'primary-inverted'}
                                size={mobile ? 'small' : 'large'}
                                block={!mobile}
                                ghost={!mobile}
                                style={mobile ? null : TOP_INDENTS.M}
                                onClick={content.rightPart.button?.onClick}
                            >
                                {content.rightPart.button?.text}
                            </StyledButton>
                        ) : null}
                        <StyledButtonInfo
                            icon={<InfoIcon />}
                            onClick={content.rightPart.info?.onClick}
                            size='large'
                            ghost
                            block
                            type='unstyled'
                            style={mobile ? null : TOP_INDENTS.SM}
                            level={mobile ? 4 : 3}
                            color={mobile ? $primary : $white}
                        >
                            {content.rightPart.info?.content}
                        </StyledButtonInfo>
                    </ButtonWrapper>
                ) : content.rightPart.button ? (
                    <StyledButton
                        type={mobile ? 'secondary' : 'primary-inverted'}
                        size={mobile ? 'small' : 'large'}
                        block={!mobile}
                        ghost={!mobile}
                        style={mobile ? { ...TOP_INDENTS.S, ...BOTTOM_INDENTS.XL } : TOP_INDENTS.M}
                        onClick={content.rightPart.button?.onClick}
                    >
                        {content.rightPart.button?.text}
                    </StyledButton>
                ) : null}
            </Fragment>
        );
    })
);

export default Info;
