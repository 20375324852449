import React, { Fragment } from 'react';
import { Step as StepperStep } from './components/Step';
import { StyledDivider, StyledWrap } from './styles';

export const { Step } = StepperStep;

export const Stepper = ({ children, activeStep = 0, simple }) => {
    return (
        <StyledWrap $simple={simple}>
            {React.Children.map(children, (child, index) => {
                const stepState =
                    activeStep === index + 1 ? 'active' : activeStep > index + 1 ? 'completed' : 'uncompleted';
                const dividerState = activeStep > index + 1 ? 'completed' : 'uncompleted';
                const isFirstElem = index === 0;
                const isLastElem = index + 1 === children.length;
                return (
                    <Fragment>
                        {React.cloneElement(child, {
                            state: stepState,
                            firstStep: isFirstElem,
                            lastStep: isLastElem
                        })}
                        {!isLastElem ? <StyledDivider $state={dividerState} /> : null}
                    </Fragment>
                );
            })}
        </StyledWrap>
    );
};
