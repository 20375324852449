import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { notification } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import NotificationContent from '../../../../../../shared/components/NotificationContent';
import { ExtensionSettingsInstalled } from './components/ExtensionSettingsInstalled';
import { ExtensionSettingsNotInstalled } from './components/ExtensionSettingsNotInstalled';

export const ExtensionSettings = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const [searchParams] = useSearchParams();
        const { extensionInstalled, isApprovedCustomer } = store.accountStore;
        const { tasksExists, installExtensionTask, completeInstallExtensionTask } = store.tasksStore;
        const extensionToken = searchParams.get('token');
        const notificationSettings = { duration: null, bottom: '8px' };

        useEffect(() => {
            if (
                tasksExists &&
                installExtensionTask &&
                installExtensionTask.data.status !== 'completed' &&
                extensionToken &&
                isApprovedCustomer
            ) {
                completeInstallExtensionTask({ token: extensionToken }, () => {
                    store.trackingStore.trackChallengeNotification(
                        installExtensionTask,
                        'completed',
                        `${installExtensionTask?.data?.title} completed!`
                    );
                    notification.open({
                        description: (
                            <NotificationContent.Completed completedActionName={installExtensionTask?.data?.title} />
                        ),
                        ...notificationSettings
                    });
                    notification.open({
                        description: (
                            <NotificationContent.RewardEarned
                                value={1}
                                dimension='Gifting Suite'
                                showClaimReward
                                navigate={navigate}
                            />
                        ),
                        ...notificationSettings
                    });
                });
            }
        }, [tasksExists, installExtensionTask, extensionToken]); //eslint-disable-line

        return extensionInstalled ? <ExtensionSettingsInstalled /> : <ExtensionSettingsNotInstalled />;
    })
);
