import React from 'react';
import {
    BOTTOM_INDENTS,
    Col,
    GRID_POINTS,
    Row,
    TOP_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { observer } from 'mobx-react';
import OnboardingInfoSlider from '../OnboardingInfoSlider';
import SidePanel from '../SidePanel';
import { ConnectInstagramForm } from './components/ConnectInstagramForm';
import { ConnectInstagramContentStyles, ConnectInstagramStyles } from './styles';

export const ConnectInstagram = observer(() => {
    const simple = useSimpleInterface(GRID_POINTS.md);
    return (
        <ConnectInstagramStyles>
            {simple ? <OnboardingInfoSlider /> : null}
            <Row>
                <Col span={24} lg={{ span: 16 }}>
                    <ConnectInstagramContentStyles>
                        <Typography.Title style={{ ...BOTTOM_INDENTS.SM, ...TOP_INDENTS.SM }}>
                            Let’s see if we’re a fit
                        </Typography.Title>
                        <Typography.Text style={BOTTOM_INDENTS.XL} type='body1'>
                            We use your social profiles and invite codes to confirm eligibility
                        </Typography.Text>
                        <ConnectInstagramForm />
                    </ConnectInstagramContentStyles>
                </Col>
                <SidePanel />
            </Row>
        </ConnectInstagramStyles>
    );
});

export default ConnectInstagram;
