import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    formatPoints,
    Icon,
    PointsIcon,
    RIGHT_INDENTS,
    roundToLocaleString,
    Typography
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../shared';
import { SectionHeader } from '../SectionHeader';
import { InfoRow } from './components/InfoRow';
import { getOrderData } from './helpers';
import {
    StyledBadge,
    StyledDealPrice,
    StyledDetailsBlock,
    StyledHead,
    StyledImage,
    StyledImgBlock,
    StyledLoaderWrap,
    StyledOriginalPrice,
    StyledPoints,
    StyledPointsBlock,
    StyledPrices,
    StyledPricesBlock,
    StyledWrap
} from './styled';

export const LastOrderSection = inject('store')(
    observer(({ store, simple }) => {
        const navigate = useNavigate();
        const { lastOrder, pending } = store.ordersStore;
        const {
            createdAt,
            originalPrice,
            price,
            itemsBadge,
            imageSrc,
            pointsEarned,
            orderNumber,
            brandName,
            externalId,
            trackingUrl
        } = getOrderData(lastOrder);

        if (!pending && !lastOrder) {
            return null;
        }

        if (pending) {
            return (
                <StyledLoaderWrap>
                    <Icon.Loader zIndex={5} />
                </StyledLoaderWrap>
            );
        }

        return (
            <Fragment>
                <SectionHeader
                    title='Your Last Order'
                    onClick={() => navigate(ROUTE_PATHS.orders)}
                    withBorder={!simple}
                />
                <StyledWrap>
                    <StyledImgBlock>
                        <StyledImage width={300} height={300} src={imageSrc} alt='' />
                        {itemsBadge ? (
                            <StyledBadge>
                                <Typography.Title level={1} color='canvas' align='center'>
                                    +{itemsBadge}
                                </Typography.Title>
                            </StyledBadge>
                        ) : null}
                    </StyledImgBlock>
                    <StyledDetailsBlock>
                        <StyledHead>
                            <StyledPricesBlock>
                                <Typography.Text type='body2' color='secondaryText'>
                                    {createdAt}
                                </Typography.Text>
                                <StyledPrices>
                                    {originalPrice && originalPrice > price ? (
                                        <StyledOriginalPrice level={2}>
                                            ${roundToLocaleString(originalPrice)}
                                        </StyledOriginalPrice>
                                    ) : null}
                                    <StyledDealPrice level={2} color='primary'>
                                        {price === 0 ? 'Free' : `$${roundToLocaleString(price)}`}
                                    </StyledDealPrice>
                                </StyledPrices>
                            </StyledPricesBlock>
                            {pointsEarned ? (
                                <StyledPointsBlock>
                                    <Typography.Text type='body2' color='primary'>
                                        You’ve earned:
                                    </Typography.Text>
                                    <StyledPoints>
                                        <Icon
                                            size='12px'
                                            color='primary'
                                            component={() => <PointsIcon />}
                                            style={{ ...RIGHT_INDENTS.XXS }}
                                        />
                                        <Typography.Title level={2} color='primary'>
                                            {formatPoints(pointsEarned)}
                                        </Typography.Title>
                                    </StyledPoints>
                                </StyledPointsBlock>
                            ) : null}
                        </StyledHead>
                        <div>
                            <InfoRow label='Giftly ID' value={orderNumber} />
                            <InfoRow label={`${brandName} ID`} value={externalId} />
                            {trackingUrl ? <InfoRow label='Tracking URL' value={trackingUrl} /> : null}
                        </div>
                    </StyledDetailsBlock>
                </StyledWrap>
            </Fragment>
        );
    })
);
