import React, { useState } from 'react';
import { Taxonomy } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import MenuTitle from './components/MenuTitle';
import { StyledDrawer } from './styled';

const CategoriesMenu = inject('store')(
    observer(({ store, showMenu, onMenuClose, onCategoriesClose }) => {
        const { updateQuery, updatePromotions } = store.marketplaceStore;
        const [selectedSubCategory, setSelectedSubCategory] = useState(null);
        return (
            <StyledDrawer
                width='100%'
                placement='left'
                open={showMenu}
                closable={false}
                getContainer={() => document.querySelector('#app-wrapper')}
                title={
                    <MenuTitle
                        selectedSubCategory={selectedSubCategory}
                        setSelectedSubCategory={setSelectedSubCategory}
                        onCategoriesClose={onCategoriesClose}
                        onMenuClose={onMenuClose}
                    />
                }
            >
                <Taxonomy
                    taxonomyType='marketplace'
                    selectedSubCategory={selectedSubCategory}
                    setSelectedSubCategory={setSelectedSubCategory}
                    onAfterCategorySelect={(gt) => {
                        updateQuery({ gt });
                        updatePromotions({ isNewSearch: true });
                        onCategoriesClose();
                        onMenuClose();
                    }}
                    inMobileMenu
                />
            </StyledDrawer>
        );
    })
);

export default CategoriesMenu;
