import styled from 'styled-components';
import { ReactComponent as WavesBottom } from '../../../assets/img/upsell/waves_bottom.svg';
import { ReactComponent as WavesTop } from '../../../assets/img/upsell/waves_top.svg';
import { $primary } from '../../../shared';

export const StyledWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 186px;

    border-top: 1px solid ${$primary};
    border-bottom: 1px solid ${$primary};
`;

export const StyledWavesTop = styled(WavesTop)`
    width: 250px;
    height: 270px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    pointer-events: none;
    touch-action: none;
`;

export const StyledWavesBottom = styled(WavesBottom)`
    width: 280px;
    height: 240px;
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0.3;
    pointer-events: none;
    touch-action: none;
`;

export const StyledBorderLeft = styled.div`
    position: absolute;
    top: 0;
    left: -6px;
    height: 100%;
    width: 6px;
    background-image: url('https://cdn.klickly.com/images/giftly/coupon-border-filled.svg');
    background-repeat: repeat-y;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        width: 50px;
        border-top: 1px solid ${$primary};
    }

    &:after {
        top: auto;
        bottom: -1px;
    }
`;

export const StyledBorderRight = styled.div`
    position: absolute;
    top: 0;
    right: -6px;
    height: 100%;
    width: 6px;
    background-image: url('https://cdn.klickly.com/images/giftly/coupon-border-filled.svg');
    background-repeat: repeat-y;
    transform: rotate(180deg);

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: -1px;
        right: -24px;
        width: 30px;
        border-top: 1px solid ${$primary};
    }

    &:after {
        top: auto;
        bottom: -1px;
    }
`;
