import { Button, Col, Form } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../../../utils';

export const StyledButton = styled(Button)`
    && {
        @media (${GRID_DEVICES_MAX.sm}) {
            width: 100%;
        }
    }
`;
export const StyledForm = styled(Form)`
    @media (${GRID_DEVICES_MAX.sm}) {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
`;

export const FullContentCol = styled(Col)`
    span {
        width: 100%;
        display: block;
    }
`;
