import styled from 'styled-components';
import { ReactComponent as ItemBackground } from '../../../../../../../../assets/img/GSGalleryItemBackground.svg';
import { $primaryText } from '../../../../../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../../../../../utils';

export const StyledGalleryWrapper = styled.div`
    padding-top: 12px;
    border-left: 2px solid ${$primaryText};
    border-right: 2px solid ${$primaryText};

    @media (${GRID_DEVICES_MAX.xs}) {
        border: none;
    }
`;

export const StyledItemWrapper = styled.div`
    position: relative;
    width: 360px;
    height: 356px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 340px;
        z-index: 1;

        * {
            white-space: normal;
            text-align: center;
        }

        .ant-typography + h3.ant-typography {
            margin-top: 0;
        }
    }

    img {
        width: 200px;
        height: 200px;
        object-fit: cover;
        margin-bottom: 8px;
    }
`;

export const StyledBackground = styled(ItemBackground)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;
