import React from 'react';
import { CircleActionsCloseIcon, Icon, Typography } from '@klickly/front-packages';
import { StyledWrapper } from './styled';

const AccountNotExists = () => {
    return (
        <StyledWrapper>
            <Icon size='22px' component={() => <CircleActionsCloseIcon />} />
            <Typography.Text type='descriptor2'>
                An account exists for this email. Sign in to earn points on this order.
            </Typography.Text>
        </StyledWrapper>
    );
};

export default AccountNotExists;
