import React from 'react';
import { BOTTOM_INDENTS, Col, Icon, Row, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ReactComponent as BigBag } from '../../../../assets/img/seasonalChallenge/BigBag.svg';
import { $primary, $secondaryText } from '../../../../shared';
import ColoredText from '../../../../shared/components/ColoredText';
import { GRID_POINTS } from '../../../../utils';

const BonusGift = inject('store')(
    observer(({ store, isCompleted }) => {
        const { seasonalChallenge } = store.seasonalChallengeStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        return (
            <Row gutter={10} align='middle' style={simple ? BOTTOM_INDENTS.XL : {}}>
                <Col flex='120px'>
                    <Icon size='120px' component={() => <BigBag />} />
                </Col>
                <Col flex='1'>
                    <Typography.Title color={$secondaryText} level={2} style={BOTTOM_INDENTS.XS}>
                        Bonus Gift: <br />{' '}
                        <ColoredText color={$primary}>{seasonalChallenge?.reward?.value} Free</ColoredText>
                        {seasonalChallenge?.reward?.type === 'claim' &&
                            ` Gifting Suite${seasonalChallenge?.reward?.value > 1 ? 's' : ''}`}
                        {seasonalChallenge?.reward?.type === 'point' &&
                            ` Point${seasonalChallenge?.reward?.value > 1 ? 's' : ''}`}
                    </Typography.Title>
                    {isCompleted ? null : (
                        <Typography.Text type='body2' color={$secondaryText} style={simple ? {} : BOTTOM_INDENTS.M}>
                            Complete all challenges to receive
                        </Typography.Text>
                    )}
                </Col>
            </Row>
        );
    })
);

export default BonusGift;
