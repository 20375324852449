import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { SEASONAL_CHALLENGE_ALIASES, useSeasonalChallengeNotifications } from '../../../../shared';
import { ROUTES } from './constants/routes';
import { useWelcomeModal } from './hooks';

const GsPage = inject('store')(
    observer(({ store }) => {
        const { pending } = store.suiteStore;
        const { activeChallenge, getSeasonalChallenge } = store.seasonalChallengeStore;
        const showWelcomeModal = useSearchParam('welcomeModal');

        const { showModal } = useWelcomeModal(() => {
            store.onboardingPopoverStore.toggleShowPopoverOnLaunchGsButton(true);
            if (
                activeChallenge?.alias === SEASONAL_CHALLENGE_ALIASES.COMPLETE_ONBOARDING &&
                activeChallenge?.challengeAccepted
            ) {
                showSeasonalChallengeNotification('completed');
                getSeasonalChallenge(true);
            }
        });

        const showSeasonalChallengeNotification = useSeasonalChallengeNotifications(store);

        useEffect(() => {
            if (showWelcomeModal) {
                showModal();
            }
        }, []);

        if (pending) {
            return <Icon.Loader />;
        }

        return <Outlet />;
    })
);

GsPage.ROUTES = ROUTES;

export default GsPage;
