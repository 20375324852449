import React from 'react';
import { LegalContent } from '../../../../shared/components/LegalContent';
import { StyledText } from './styles';

/* eslint-disable */

const SensitiveInformation = () => {
    return (
        <LegalContent
            title="Right to Limit Use/Disclosure of Sensitive Information"
            date="Last Updated August 10, 2023"
        >
            <StyledText type="descriptor1">
                To opt out concerning use and disclosure of your sensitive personal information
                (as defined under and described in our privacy policy and/or an applicable law),
                please submit a request by emailing the following info to{' '}
                <a href='mailto:privacy@klickly.com'>privacy@klickly.com</a>.
            </StyledText>
            <br/>
            <StyledText as="div" type="descriptor1">
                <ul>
                    <li>First name</li>
                    <li>Last name</li>
                    <li>Email</li>
                    <li>Street Address</li>
                    <li>City</li>
                    <li>State</li>
                    <li>Postal Code</li>
                    <li>Country</li>
                </ul>
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                The right to opt-out is subject to certain exemptions and exceptions. Additionally, we may be unable
                to fulfill your request if we believe the request is fraudulent.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                Please visit our <a href='/help/privacy'>Privacy Policy</a> for information about
                your rights.
            </StyledText>
        </LegalContent>
    );
};

export default SensitiveInformation;
