import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    GRID_POINTS,
    Icon,
    notification,
    PointsIcon,
    ProfileIcon,
    TagIcon,
    TOP_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { SEASONAL_CHALLENGE_ALIASES, useSeasonalChallengeNotifications } from '../../../../shared';
import { Animated } from '../../../../shared/components/Animated';
import NotificationContent from '../../../../shared/components/NotificationContent';
import Unauthorized from '../../../../shared/components/Unauthorized';
import { EarnTask } from './components/EarnTask';
import HowItWorksBtn from './components/HowItWorksBtn';
import { StyledTaskRow, StyledWrapper } from './styles';

const InviteAndEarn = inject('store')(
    observer(({ store }) => {
        const { state } = useLocation();
        const { isApprovedCustomer } = store.accountStore;
        const {
            inviteFriendTask,
            shareProductTask,
            onInviteFriendTaskClick,
            onShareProductTaskClick
        } = store.tasksStore;
        const challenge = store.tasksStore?.inviteFriendTask;
        const { activeChallenge } = store.seasonalChallengeStore;
        const alias = challenge?.alias;
        const simple = useSimpleInterface(GRID_POINTS.sm);
        const showSeasonalChallengeNotification = useSeasonalChallengeNotifications(store);
        const message = 'Step 1 of 2. Click on Invite a friend ';

        useEffect(() => {
            if (isApprovedCustomer && state === alias && store.tasksStore?.inviteFriendTask?.results?.length === 0) {
                store.trackingStore.trackChallengeNotification(challenge, '1', message);
                notification.open({
                    key: alias,
                    duration: null,
                    description: <NotificationContent.Reward rewardCount={50} message={message} />,
                    bottom: simple ? '77px' : '8px'
                });
            }
            if (
                activeChallenge?.alias === SEASONAL_CHALLENGE_ALIASES.INVITE_FRIENDS &&
                activeChallenge.challengeAccepted
            ) {
                showSeasonalChallengeNotification('1', 'Click Invite a friend', {
                    duration: null,
                    bottom: simple ? '77px' : '8px'
                });
            }
            return () => (typeof notification.destroy === 'function' ? notification.destroy() : null);
        }, []); //eslint-disable-line

        return isApprovedCustomer ? (
            <StyledWrapper>
                <Animated>
                    {simple ? (
                        <Typography.Title style={{ marginBottom: '8px', ...TOP_INDENTS.SM }}>
                            Invite & Earn
                        </Typography.Title>
                    ) : (
                        <Typography.Title level={2} style={{ marginBottom: '8px', ...TOP_INDENTS.SM }}>
                            Invite & Earn
                        </Typography.Title>
                    )}
                    <Typography.Text type='body1' style={{ marginBottom: '24px' }}>
                        Earn by sharing with friends and followers. Spend on products you love.
                    </Typography.Text>
                    <StyledTaskRow>
                        {inviteFriendTask ? (
                            <EarnTask
                                description='Earn a gift claim whenever someone you’ve invited signs up using your invite code & is approved'
                                onClick={() => {
                                    onInviteFriendTaskClick({
                                        fromReward: Boolean(state)
                                    });
                                }}
                                icon={
                                    <Icon
                                        color='primary'
                                        roundedSize='200%'
                                        roundedColor='primaryBackground'
                                        size='20px'
                                        marginRight='10px'
                                        component={() => <ProfileIcon />}
                                    />
                                }
                                task={inviteFriendTask}
                            />
                        ) : null}
                        {shareProductTask ? (
                            <EarnTask
                                description='Share a product and earn a gift claim whenever someone purchases using your link'
                                onClick={onShareProductTaskClick}
                                icon={
                                    <Icon
                                        color='primary'
                                        roundedSize='200%'
                                        roundedColor='primaryBackground'
                                        size='20px'
                                        marginRight='10px'
                                        component={() => <TagIcon />}
                                    />
                                }
                                task={shareProductTask}
                            />
                        ) : null}
                    </StyledTaskRow>
                </Animated>
                {simple && <HowItWorksBtn />}
            </StyledWrapper>
        ) : (
            <Unauthorized
                title='Invite and earn'
                contentTitle='Unlock exclusive offers & deals'
                description='Complete challenges & access existing rewards'
                icon={<PointsIcon />}
                howItWorkBtnText='How Sharing Works'
            />
        );
    })
);

export default InviteAndEarn;
