import { device, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../utils';
import { $border, $primary, $primaryText } from '../../constants';
import { Link } from '../Link';

const isIos = device.iOS();
const isSafari = device.isSafari();

export const LegalStyles = styled.div`
    width: 100%;
    height: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    ${isIos &&
    isSafari &&
    css`
        padding-bottom: 80px;
    `}
`;

export const LegalContentStyles = styled.div`
    flex-grow: 1;
    padding: 40px 80px;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;

    @media (${GRID_DEVICES_MAX.lg}) {
        padding: 40px 24px;
    }
`;

export const LegalNavbarWrap = styled.div`
    padding: 50px 30px;

    @media (${GRID_DEVICES_MAX.lg}) {
        padding: 24px 0 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid ${$border};
    }
`;

export const LegalNavbarLink = styled(Link)`
    display: block;
    margin: 15px 0;
`;

export const LegalContentHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (${GRID_DEVICES_MAX.lg}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const LegalContentBody = styled.div`
    padding: 20px 0;
`;

export const StyledHeadLine1 = styled(Typography.Title)`
    padding-right: 50px;

    @media (${GRID_DEVICES_MAX.lg}) {
        padding: 24px 0;
    }
`;

export const StyledHeadLine2 = styled(Typography.Title)`
    padding-left: 16px;
    color: ${({ active }) => (active ? $primary : $primaryText)};
    border-left: ${({ active }) => (active ? `2px solid ${$primary}` : '2px solid transparent')};
`;
