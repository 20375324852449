import { PDP_REDIRECTS } from '../../../../../shared';
import { get } from '../../../../../utils';
import { getMetaDataByPromotion } from './getMetaDataByPromotion';

export const getPayload = (promotion) => {
    const path = promotion.selectedVariant ? 'selectedVariant' : 'variants[0]';
    return {
        productId: promotion._id,
        variantId: get(promotion, `${path}.externalId`, ''),
        prices: {
            originalPrice: get(promotion, `${path}.originalPrice`, null),
            price: get(promotion, `${path}.price`, null)
        },
        qty: 1,
        metadata: getMetaDataByPromotion(promotion),
        redirect: PDP_REDIRECTS.onboarding
    };
};
