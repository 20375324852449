export const checkOrderType = (store, isFirstClaim, pointsNumber, cart) => {
    if (!cart) return;

    const { itemsInOrder, gsClaims } = store.thankYouPageStore;
    const { isLoggedIn, signUpStatusRejected } = store.accountStore;
    const { suiteState, nextCloseAt } = store.suiteStore;
    const { claimsCount } = store.claimsPointsStore;

    switch (true) {
        case signUpStatusRejected:
            return 'waitListed';
        case !isLoggedIn:
            return 'unauthorized';
        case isFirstClaim:
            return 'firstGsClaim';
        case itemsInOrder.length > 1:
            return 'regularPurchaseMultipleOrders';
        case suiteState === 'started' || new Date().valueOf() < new Date(nextCloseAt).valueOf():
            return 'regularPurchaseStartedGs';
        case pointsNumber >= 1000:
            return 'regularPurchaseByPoints';
        case claimsCount > 0:
            return 'regularPurchaseGsClaimExist';
        case !nextCloseAt || suiteState === 'opened' || gsClaims > 0:
            return 'regularPurchaseChallenge';
        default:
            return 'unauthorized';
    }
};
