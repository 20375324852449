import { kcpAPI } from '../../../constants';

export const getEndpoints = (productId) => {
    return {
        productFetchURL: kcpAPI.promotions.fetchUrl(productId),
        productReviewsFetchURL: kcpAPI.promotions.reviewsUrl(productId),
        cartItemsManageURL: kcpAPI.cart.itemsManage,
        buyNowURL: kcpAPI.cart.buyNow,
        reviewsWriteURL: kcpAPI.reviews.writeUrl
    };
};
