import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, RIGHT_INDENTS, Typography } from '@klickly/front-packages';
import { $primary, ROUTE_PATHS } from '../../../../../../shared';
import { ChallengeIcon } from '../../../../../../shared/components/ChallengeIcon';
import { StyledButton, StyledGSInfo, StyledTaskContent, StyledTextWrap } from '../../styled';

export const ChallengeContent = ({ task, simple }) => {
    const navigate = useNavigate();

    return (
        <StyledGSInfo $simple={simple}>
            <StyledTaskContent $simple={simple}>
                <ChallengeIcon
                    alias={task.alias}
                    size={simple ? '32px' : '60px'}
                    backgroundSize={simple ? '64px' : '120px'}
                    color='canvas'
                    backgroundColor={$primary}
                    style={simple ? { ...BOTTOM_INDENTS.S } : { ...RIGHT_INDENTS.M }}
                />
                <StyledTextWrap>
                    <Typography.Title
                        level={simple ? 2 : 1}
                        color='primary'
                        align={simple ? 'center' : 'left'}
                        style={simple ? { ...BOTTOM_INDENTS.S } : { ...BOTTOM_INDENTS.XXS }}
                    >
                        {task?.data?.title}
                    </Typography.Title>
                    <Typography.Title
                        level={3}
                        color='secondaryText'
                        align={simple ? 'center' : 'left'}
                        style={simple ? { ...BOTTOM_INDENTS.S } : { margin: 0, ...BOTTOM_INDENTS.XXS }}
                    >
                        {task?.data?.description}
                    </Typography.Title>
                </StyledTextWrap>
            </StyledTaskContent>
            <StyledButton type='secondary' onClick={() => navigate(ROUTE_PATHS.challenges)} $simple={simple}>
                <Typography.Text type='badgeSmall' color='primary'>
                    Go to Challenge
                </Typography.Text>
            </StyledButton>
        </StyledGSInfo>
    );
};
