import { keyframes } from 'styled-components';

export const animation$ = keyframes`
    from {
        opacity: 0.85;
        transform: translate3d(0, 0, 0) scale3d(1.0025, 1.0025, 1);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
`;
