import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { LayoutContext } from '../../constants';
import { ROUTE_PATHS } from '../../index';
import Footer from '../Footer';
import ErrorPage from './components/ErrorPage';
import Header from './components/Header';
import { Body, Content, SideBar, Wrap } from './styles';

export const Layout = inject('store')(
    observer(
        ({
            store,
            sidebar,
            disabledMenu = false,
            disableOnLogoClick = false,
            contentFullHeight = false,
            withoutPadding = false,
            children,
            withoutBottomPadding,
            menuHidden,
            menuBarHidden,
            hasFooter,
            afterHeaderContent = null
        }) => {
            const headerRef = useRef(null);
            const sideRef = useRef(null);
            const bodyRef = useRef(null);
            const contentRef = useRef(null);
            const wrapRef = useRef(null);

            const { pathname } = useLocation();
            const withoutPaddingValue = pathname === ROUTE_PATHS.suiteGsCouponDetails ? true : withoutPadding;

            return (
                <LayoutContext.Provider value={{ headerRef, sideRef, bodyRef, contentRef, wrapRef }}>
                    {store.errorStatus === 500 ? (
                        <ErrorPage />
                    ) : (
                        <Wrap id='app-wrapper' ref={wrapRef} $singleBar={menuBarHidden}>
                            <Header
                                headerRef={headerRef}
                                disableOnLogoClick={disableOnLogoClick}
                                menuDisabled={disabledMenu}
                                menuHidden={menuHidden}
                                menuBarHidden={menuBarHidden}
                            />
                            <Body
                                ref={bodyRef}
                                $hasFooter={hasFooter}
                                $hasAfterHeaderContent={Boolean(afterHeaderContent)}
                                $withStickyBottomElem={
                                    pathname.includes(ROUTE_PATHS.shoppingCart) ||
                                    pathname.includes(ROUTE_PATHS.suiteGsCouponDetails) ||
                                    pathname.includes(`${ROUTE_PATHS.shop}/`)
                                }
                            >
                                {afterHeaderContent}
                                {sidebar && (
                                    <SideBar ref={sideRef} $hasFooter={hasFooter}>
                                        {sidebar}
                                    </SideBar>
                                )}
                                <Content
                                    ref={contentRef}
                                    $withSideBar={sidebar}
                                    $contentFullHeight={contentFullHeight}
                                    $withoutPadding={withoutPaddingValue}
                                    $withoutBottomPadding={withoutBottomPadding}
                                    $hasFooter={hasFooter}
                                >
                                    {children}
                                </Content>
                                {hasFooter ? <Footer /> : null}
                            </Body>
                        </Wrap>
                    )}
                </LayoutContext.Provider>
            );
        }
    )
);
