import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    ForwardArrowIcon,
    Icon,
    LEFT_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { getAcceptChallengeRedirect } from '../../../../../../../../shared/utils/getAcceptChallengeRedirect';
import Reward from '../Reward';
import { StyledButton } from './styled';

const ActiveChallenge = inject('store')(
    observer(({ store }) => {
        const simple = useSimpleInterface(1180);
        const { activeChallenge } = store.seasonalChallengeStore;
        const navigate = useNavigate();
        return (
            <Fragment>
                <Typography.Title level={3} style={BOTTOM_INDENTS.S} align={simple ? 'center' : 'left'}>
                    Challenge {activeChallenge?.position}: {activeChallenge?.name}
                </Typography.Title>
                <Reward simple={simple} />
                <Typography.Text type='descriptor1' style={BOTTOM_INDENTS.M} align={simple ? 'center' : 'left'}>
                    {activeChallenge?.description}
                </Typography.Text>
                <StyledButton
                    type='primary'
                    onClick={() => {
                        activeChallenge?.setChallengeAccepted();
                        return navigate(getAcceptChallengeRedirect(activeChallenge?.alias));
                    }}
                    $simple={simple}
                >
                    Accept challenge
                    <Icon style={LEFT_INDENTS.S} size='24px' component={() => <ForwardArrowIcon />} />
                </StyledButton>
            </Fragment>
        );
    })
);

export default ActiveChallenge;
