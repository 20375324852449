import React, { useEffect } from 'react';
import { notification, useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../utils';
import NotificationContent from '../components/NotificationContent';

export const useBuyProductNotification = (store) => {
    const { isApprovedCustomer } = store.accountStore;
    const { completeTask } = store.tasksStore;
    const { getProductTypes } = store.thankYouPageStore;
    const simple = useSimpleInterface(GRID_POINTS.md);
    const defaultSettings = { duration: null, bottom: simple ? '0' : '8px' };

    useEffect(() => {
        return () => (typeof notification.destroy === 'function' ? notification.destroy() : null);
    }, []);

    return {
        onCompleteTask: (productId) => {
            const navigate = store.routeNavigate;
            const challenge = store.tasksStore?.shopSuiteTask;
            const ifOnlyGsInCart = getProductTypes.gs.length && !getProductTypes.marketplace.length;
            if (isApprovedCustomer && challenge?.data?.status !== 'completed' && !ifOnlyGsInCart) {
                completeTask({
                    taskPayload: { productId },
                    taskId: challenge._id,
                    onAfterSuccess: () => {
                        store.trackingStore.trackChallengeNotification(
                            challenge,
                            'completed',
                            `${challenge?.data?.title} completed!`
                        );
                        notification.open({
                            key: challenge?.alias,
                            description: <NotificationContent.Completed completedActionName={challenge?.data?.title} />,
                            ...defaultSettings
                        });
                        notification.open({
                            description: <NotificationContent.RewardEarned value={50} navigate={navigate} />,
                            ...defaultSettings
                        });
                    }
                });
            }
        }
    };
};
