import { getParent, types } from 'mobx-state-tree';
import { ROUTE_PATHS } from '../shared';
import { models } from './constants';

export const PdpStore = types
    .model('PdpStore', {
        showPDP: false,
        isPageReloaded: true,
        customizationsKey: types.maybeNull(types.string),
        promotion: types.maybeNull(models.PDPModel),
        productId: types.maybeNull(types.string),
        rewardUuid: types.maybeNull(types.string),
        prevProductId: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const setPdpProps = ({ productId, customizationsKey, showPDP = false, rewardUuid }) => {
            self.customizationsKey = customizationsKey;
            self.productId = productId;
            self.showPDP = showPDP;
            self.rewardUuid = rewardUuid;
        };

        const openPDP = ({
            productId,
            customizationsKey,
            addToHistory,
            extraContent,
            rewardUuid = null,
            promotion = null
        }) => {
            setPdpProps({
                productId,
                customizationsKey,
                extraContent,
                showPDP: true,
                rewardUuid
            });

            if (promotion) self.root.trackingStore.trackClick(promotion);
            if (customizationsKey === 'shop' && addToHistory) {
                self.root.routeNavigate({
                    pathname: `/marketplace/${productId}`,
                    search: self.root.routeLocation.search
                });
            }
        };

        const closePDP = (addToHistory) => {
            const params = new URLSearchParams(self.root.routeLocation.search);
            params.delete('referrer');
            if (self.customizationsKey === 'shop' && addToHistory) {
                self.root.routeNavigate({
                    pathname: ROUTE_PATHS.shop,
                    search: `?${params.toString()}`
                });
            }
            self.showPDP = false;
            self.productId = null;
            self.customizationsKey = null;
        };

        const setPrevProductId = (value = null) => {
            self.prevProductId = value;
        };

        const setIsPageReloaded = (value) => {
            self.isPageReloaded = value;
        };

        const setPromotion = (promotion) => {
            self.promotion = promotion;
        };

        return { setPromotion, openPDP, closePDP, setPdpProps, setPrevProductId, setIsPageReloaded };
    });
