import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { device, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../shared';
import { Layout } from '../../shared/components/Layout';
import { GRID_POINTS } from '../../utils';
import GsSideBar from './components/GsSideBar';
import { ROUTES } from './constants/routes';

const SuitePage = inject('store')(
    observer(({ store }) => {
        const { pathname } = useLocation();
        const isIos = device.iOS();
        const isSafari = device.isSafari();
        const { isSuiteStateClosed } = store.suiteStore;
        const simple = useSimpleInterface(GRID_POINTS.lg);

        return (
            <Layout
                hasFooter={!simple}
                sidebar={simple && isSuiteStateClosed ? null : <GsSideBar />}
                contentFullHeight={!simple || (pathname.includes(ROUTE_PATHS.suiteGsCouponDetails) && simple)}
                withoutBottomPadding={pathname.includes(ROUTE_PATHS.rewardsInfo) && isSafari && isIos}
            >
                <Outlet />
            </Layout>
        );
    })
);

SuitePage.ROUTES = ROUTES;
export default SuitePage;
