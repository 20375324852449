import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@klickly/front-packages';
import { app } from '../../config';
import { Layout } from '../../shared/components/Layout';
import { StyledIframe } from './styled';

const HelpCenter = () => {
    const [loading, setLoading] = useState(true);
    const [iframePath, setIframePath] = useState(app.giftlyFaq);
    const navigate = useNavigate();

    const pushToHistory = (event) => {
        if (event.data.type === 'link') {
            const eventLink = new URL(event.data.link);
            const pathname = eventLink.pathname;
            const hash = eventLink.hash.substring(1);

            const url = new URLSearchParams();
            url.append('link', pathname);
            if (hash) {
                url.append('hash', hash);
            }
            navigate(`?${url.toString()}`);
        }
    };

    const updateIframeUrl = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const pathname = searchParams.get('link');
        const hash = searchParams.get('hash');
        const iframeUrl = new URL(iframePath);

        if (pathname) {
            iframeUrl.pathname = pathname;
        }

        if (hash) {
            iframeUrl.hash = hash;
        }

        setIframePath(() => iframeUrl.href);
    };

    const handleLoadIframe = () => {
        setLoading(false);
    };

    useEffect(() => {
        updateIframeUrl();
        window.addEventListener('message', pushToHistory);

        return () => window.removeEventListener('message', pushToHistory);
    }, []);

    return (
        <Layout withoutPadding>
            <StyledIframe>
                {loading && <Icon.Loader />}
                <iframe
                    onLoad={handleLoadIframe}
                    title='Giftly FAQ'
                    id='giftly-faq'
                    src={iframePath}
                    frameBorder='0'
                    border='0'
                    cellSpacing='0'
                />
            </StyledIframe>
        </Layout>
    );
};

export default HelpCenter;
