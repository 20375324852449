import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI, SUITE_STATES } from '../shared';

export const SuiteStore = types
    .model('SuiteStore', {
        data: types.maybeNull(
            types.model({
                _id: types.identifier,
                state: types.string,
                nextOpenAt: types.maybeNull(types.string),
                nextCloseAt: types.maybeNull(types.string),
                startedAt: types.maybeNull(types.string),
                timer: types.maybeNull(
                    types.model({
                        duration: types.maybeNull(
                            types.model({
                                created: types.maybeNull(types.number),
                                started: types.maybeNull(types.number)
                            })
                        ),
                        speed: types.maybeNull(types.number)
                    })
                )
            })
        ),
        pending: false,
        isFeedbackBannerVisible: false,
        feedbackPending: false,
        loaded: false,
        errorMessage: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },

        get suiteId() {
            return self.data?._id || null;
        },

        get suiteState() {
            return self.data?.state || null;
        },

        get nextCloseAt() {
            return self.data?.nextCloseAt || null;
        },

        get isSuiteStateClosed() {
            return !self.data;
        },

        get isSuiteStateStared() {
            return self.data?.state === 'started';
        }
    }))
    .actions((self) => {
        const setPending = (pending) => {
            self.pending = pending;
        };

        const setError = (error) => {
            if (error && error.response && error.response.data) {
                self.errorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.errorMessage = error.message;
            }
        };

        const sendSuiteDataEvent = () => {
            self.root.trackingStore.sendEvent({
                type: 'meta',
                name: 'suite',
                data: self.data
                    ? {
                          _id: self.data._id,
                          status: self.data.status
                      }
                    : undefined
            });
        };

        const getCurrentSuite = flow(function* getCurrentSuite(autoCreate = true, onAfterSuccess = () => null) {
            setPending(true);
            try {
                const { customerId, giftlyData } = self.root.accountStore;
                if (customerId && giftlyData) {
                    const query = !autoCreate ? 'autoCreate=false' : '';
                    const data = yield self.root.get(`${kcpAPI.giftingSuite.get}?${query}`);
                    self.data = data || null;
                    yield self.root.claimsPointsStore.getClaims();
                    yield getPrevSuite();

                    self.loaded = true;

                    if (data) {
                        sendSuiteDataEvent();
                    }
                    onAfterSuccess();
                }
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
            }
        });

        const getPrevSuite = flow(function* getPrevSuite() {
            try {
                const data = yield self.root.get(kcpAPI.giftingSuite.feedback);
                if (data.isFeedbackVisible) {
                    self.isFeedbackBannerVisible = true;
                }
            } catch (e) {
                setError(e);
            }
        });

        const suiteFeedbackSend = flow(function* suiteFeedbackSend(values, onClose = () => null) {
            self.feedbackPending = true;
            try {
                self.isFeedbackBannerVisible = false;
                yield self.root.post(kcpAPI.giftingSuite.feedback, values);
                self.feedbackPending = false;
                onClose();
            } catch (e) {
                setError(e);
            }
        });

        const startSuite = flow(function* startSuite() {
            setPending(true);
            try {
                if (self.suiteState === SUITE_STATES.opened) {
                    self.data = yield self.root.post(kcpAPI.giftingSuite.launch(self.suiteId), {});
                }
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
            }
        });

        const setSuiteData = flow(function* setSuiteData(data) {
            self.data = data;
            yield self.root.claimsPointsStore.getClaims();
            self.loaded = true;

            sendSuiteDataEvent();
        });

        const launchSuite = flow(function* launchSuite(onAfterSuccess = () => null) {
            yield startSuite();
            yield getCurrentSuite();
            onAfterSuccess();
        });

        return {
            suiteFeedbackSend,
            getPrevSuite,
            getCurrentSuite,
            startSuite,
            launchSuite,
            setSuiteData
        };
    });
