import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, Button, Col, Icon, ProfileIcon, Row, TOP_INDENTS, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $grayScale40Black, $grayScale60Black, ROUTE_PATHS } from '../../shared';
import { Logo } from '../../shared/components/Logo';
import { StyledRow } from './styled';

export const CustomerBlocked = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { isCustomerBanned } = store.accountStore;

        if (!isCustomerBanned) {
            return navigate(ROUTE_PATHS.dashboard);
        }

        return (
            <StyledRow justify='center'>
                <Col xs={20} sm={12} md={10} lg={8} xl={6} xxl={6}>
                    <Row justify='center' style={BOTTOM_INDENTS.XL}>
                        <Col>
                            <Link to='/'>
                                <Logo size='40px' color={$grayScale60Black} />
                            </Link>
                        </Col>
                    </Row>
                    <Row justify='center' style={BOTTOM_INDENTS.XL}>
                        <Col>
                            <Icon
                                style={{ ...TOP_INDENTS.XL, ...BOTTOM_INDENTS.XL }}
                                color={$grayScale40Black}
                                size='80px'
                                component={() => <ProfileIcon />}
                            />
                        </Col>
                    </Row>
                    <Typography.Title style={BOTTOM_INDENTS.XL} align='center' level={1}>
                        We’re sorry...
                    </Typography.Title>
                    <Typography.Text style={BOTTOM_INDENTS.XL} align='center' type='body1'>
                        We&apos;re having trouble accessing your account right now. We’re sorry for any inconvenience.
                    </Typography.Text>
                    <Button
                        onClick={() => (window.location.href = 'mailto:support@joingiftly.com')}
                        style={BOTTOM_INDENTS.XL}
                        size='large'
                        type='primary'
                        ghost
                        block
                    >
                        Contact Support
                    </Button>
                </Col>
            </StyledRow>
        );
    })
);

export default CustomerBlocked;
