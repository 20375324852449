import React, { useState } from 'react';
import {
    BOTTOM_INDENTS,
    Button,
    CloseXIcon,
    Col,
    Icon,
    Popover,
    Row,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../../../../utils';
import { $white } from '../../../../../../constants';

const StepPopover = observer(({ children, position, name }) => {
    const simple = useSimpleInterface(GRID_POINTS.md);
    const [open, setOpen] = useState(false);

    return (
        <Popover
            open={simple ? open : undefined}
            onOpenChange={setOpen}
            getPopupContainer={(node) => (simple ? node : document.body)}
            trigger={simple ? 'click' : 'hover'}
            placement={simple ? 'leftTop' : 'bottom'}
            content={
                <Row gutter={12}>
                    <Col span={simple ? 21 : 24}>
                        <Typography.Text
                            type={simple ? 'descriptor2' : 'body2'}
                            color={$white}
                            style={simple ? BOTTOM_INDENTS.XXXS : BOTTOM_INDENTS.XXS}
                        >
                            Challenge {position}
                        </Typography.Text>
                        <Typography.Text
                            type={simple ? 'badgeSmall' : 'badgeLarge'}
                            color={$white}
                            style={simple ? BOTTOM_INDENTS.XXXS : BOTTOM_INDENTS.XXS}
                        >
                            {name}
                        </Typography.Text>
                        <Typography.Text
                            type={simple ? 'descriptor2' : 'body2'}
                            color={$white}
                            style={simple ? BOTTOM_INDENTS.XXXS : BOTTOM_INDENTS.XXS}
                        >
                            Completed
                        </Typography.Text>
                    </Col>
                    {simple ? (
                        <Col span={2}>
                            <Button
                                type='unstyled'
                                onClick={() => setOpen(false)}
                                icon={<Icon color={$white} size='12px' component={() => <CloseXIcon />} />}
                            />
                        </Col>
                    ) : null}
                </Row>
            }
            maxWidth={simple ? '190px' : '240px'}
            type='primary'
        >
            {children}
        </Popover>
    );
});

export default StepPopover;
