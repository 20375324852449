import React, { useCallback, useState } from 'react';
import { Button, EmojiSadFrownIcon, Icon, Typography } from '@klickly/front-packages';
import { ErrorMessage, Styles } from './styles';

export const SubscriptionModalFooter = ({ ...rest }) => {
    const [clicked, setClicked] = useState(false);

    const onClick = useCallback(() => {
        setClicked(true);
    }, [setClicked]);

    return (
        <Styles {...rest}>
            <Button
                onClick={onClick}
                disabled={clicked}
                type='primary'
                size='large'
                id='premium-membership-upgrade-button'
            >
                Upgrade to Premium
            </Button>

            <br />

            {clicked ? (
                <ErrorMessage>
                    <Icon color='error' size='22px' component={() => <EmojiSadFrownIcon />} />
                    <Typography.Text color='error' type='descriptor1'>
                        We’re sorry, the premium membership beta is currently full. Please try again at a later time
                    </Typography.Text>
                </ErrorMessage>
            ) : (
                <Typography.Text color='secondaryText' type='descriptor1'>
                    By clicking “Upgrade to Premium”, you agree that we will charge your credit card $29 every 30 days,
                    with your 1st charge today
                </Typography.Text>
            )}
        </Styles>
    );
};
