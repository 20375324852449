import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Icon } from '@klickly/front-packages';
import { Provider } from 'mobx-react';
import { RootStore } from './_store/RootStore';
import ErrorBoundary from './components/ErrorBoundary';
import Page404 from './components/Page404';
import App from './App';
import { GlobalStyle } from './App.styles';
import { ROUTES } from './constants';

const store = RootStore.create({});
const root = createRoot(document.getElementById('root'));
const router = createBrowserRouter([
    {
        element: <App />,
        errorElement: <Page404 />,
        children: ROUTES
    }
]);

root.render(
    <ErrorBoundary errorView={<div>Error</div>}>
        <Provider store={store}>
            <GlobalStyle />
            <Suspense fallback={<Icon.Loader />}>
                <RouterProvider router={router} />
            </Suspense>
        </Provider>
    </ErrorBoundary>
);
