import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, getFilterParamsFromUrl, Row, Taxonomy, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $primaryText, ROUTE_PATHS } from '../../constants';
import CategoriesList from './components/CategoriesList';
import { StyledMenuItem, StyledWrapper } from './styled';

const CategoriesMenu = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { clearAll } = store.marketplaceStore;
        const { setSelectedCategories } = Taxonomy.store;
        const { clearAll: clearBrandsSearchParams } = store.brandPageStore;
        const urlParams = getFilterParamsFromUrl();

        useEffect(() => {
            if (!urlParams.gt) setSelectedCategories([]);
        }, [urlParams.gt]);

        return (
            <StyledWrapper>
                <Row gutter={20} justify='center'>
                    <Col span={6}>
                        <StyledMenuItem role='button' onClick={() => navigate(ROUTE_PATHS.deals)}>
                            <Typography.Text color={$primaryText} type='descriptor2'>
                                Deals overview
                            </Typography.Text>
                        </StyledMenuItem>
                        <StyledMenuItem
                            role='button'
                            onClick={() => {
                                clearAll();
                                clearBrandsSearchParams({ refetch: false });
                                setSelectedCategories([]);
                                return navigate(ROUTE_PATHS.shop);
                            }}
                        >
                            <Typography.Text color={$primaryText} type='descriptor2'>
                                All products
                            </Typography.Text>
                        </StyledMenuItem>
                    </Col>
                    <Col span={18}>
                        <CategoriesList />
                    </Col>
                </Row>
            </StyledWrapper>
        );
    })
);

export default CategoriesMenu;
