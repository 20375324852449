import React from 'react';
import { ShippingModalContainer } from '../Container';
import { StyledWrap } from './styles';

export const ShippingModal = ({ defaultShippingAddress, onClose, ...rest }) => {
    return (
        <StyledWrap {...rest}>
            <ShippingModalContainer defaultShippingAddress={defaultShippingAddress} onClose={onClose} />
        </StyledWrap>
    );
};
