import { GRID_DEVICES_MAX } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledBody = styled.div`
    &&& {
        padding: 40px;
        width: 100vw;
        max-width: 100%;

        @media (${GRID_DEVICES_MAX.md}) {
            padding: 24px;
        }

        textarea {
            min-height: 112px;
        }

        label.label-float {
            @media (${GRID_DEVICES_MAX.md}) {
                font-size: 11px;
            }
        }
    }
`;
