import styled from 'styled-components';

export const StyledWrap = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`;

export const StyledContentWrap = styled.div`
    max-width: 1400px;
    margin: auto;
`;

export const StyledContent = styled.div`
    width: 100%;
    padding: 0 20px;
`;

export const StyledSearchWrap = styled.div`
    padding: 24px;
`;
