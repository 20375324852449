import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI } from '../shared';
import { ordersByDate } from '../shared/utils';
import { models } from './constants';

export const OrdersStore = types
    .model('OrdersStore', {
        data: types.optional(types.array(models.OrderModel), []),
        // need for orders refunded via shopify webhook
        refundedOrderList: types.array(types.string),
        meta: types.optional(
            types.model({
                currentPage: types.maybeNull(types.number),
                hasNextPage: types.maybeNull(types.boolean),
                hasPreviousPage: types.maybeNull(types.boolean),
                lastPage: types.maybeNull(types.number),
                nextPage: types.maybeNull(types.number),
                totalPages: types.maybeNull(types.number),
                totalDocuments: types.maybeNull(types.number)
            }),
            {}
        ),
        pending: false,
        errorMessage: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get orders() {
            return self.data;
        },
        get lastOrder() {
            return self.data?.length ? self.data[0] : null;
        },
        get ordersLength() {
            return self.data?.length;
        },
        get ordersByDate() {
            return ordersByDate(self.data);
        },
        get hasNextPage() {
            return self.meta?.hasNextPage || false;
        },
        getOrderById(orderId) {
            return self.data.find((order) => order._id === orderId);
        }
    }))
    .actions((self) => {
        const setPending = (pending) => {
            self.pending = pending;
        };

        const setError = (error) => {
            if (error && error.response && error.response.data) {
                self.errorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.errorMessage = error.message;
            }
        };

        const onErrorMessage = () => {
            self.root.trackingStore.sendEvent({
                type: 'event',
                name: 'error',
                data: { message: self.errorMessage }
            });
        };

        const getOrders = flow(function* getOrders() {
            setPending(true);
            try {
                const { customerId } = self.root.accountStore;
                if (customerId) {
                    const { data, meta } = yield self.root.get(kcpAPI.customerData.orders.list, {});
                    const updateOrdersStatus = data?.map((order) => {
                        if (self.refundedOrderList.includes(order._id)) {
                            order.orderStatus = 'refunded';
                            order.isAvailableForCancel = false;
                        }
                        return order;
                    });
                    self.data = updateOrdersStatus;
                    self.meta = meta;
                }
            } catch (e) {
                setError(e);
                onErrorMessage();
            } finally {
                setPending(false);
            }
        });

        const loadMoreOrders = flow(function* loadMoreOrders() {
            setPending(true);
            try {
                const { customerId } = self.root.accountStore;
                if (customerId && self.hasNextPage) {
                    const { data, meta } = yield self.root.get(kcpAPI.customerData.orders.list, {
                        page: self.meta.nextPage
                    });
                    self.data = data;
                    self.meta = meta;
                }
            } catch (e) {
                setError(e);
                onErrorMessage();
            } finally {
                setPending(false);
            }
        });

        const cancelOrder = flow(function* cancelOrder(payload) {
            setPending(true);
            try {
                const { orderId, cancellationReason, reply, onClose } = payload;
                const note = `${cancellationReason}, ${reply}`;
                const { customerId } = self.root.accountStore;
                if (customerId) {
                    yield self.root.post(kcpAPI.customerData.orders.cancelOrder(orderId), { note });
                    self.refundedOrderList.push(orderId);
                    yield getOrders();
                    return typeof onClose === 'function' ? onClose() : null;
                }
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
            }
        });

        const getOrdersByCode = flow(function* getOrdersByCode(code) {
            setPending(true);
            try {
                return yield self.root.get(`${kcpAPI.customerData.orders.getByCode}?code=${code}`);
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
            }
        });

        return {
            getOrders,
            loadMoreOrders,
            cancelOrder,
            getOrdersByCode
        };
    });
