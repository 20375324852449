import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ForwardArrowIcon, Icon, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../../utils';
import { ROUTE_PATHS } from '../../../../index';

const ApplyButton = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { isRejectedCustomer } = store.accountStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        return (
            <Button
                block={simple}
                type='primary'
                size='large'
                onClick={() => navigate(isRejectedCustomer ? ROUTE_PATHS.waitlisted : ROUTE_PATHS.onboarding)}
            >
                <Typography.Text type='badgeMedium'>Apply for access</Typography.Text>
                <Icon stylePriority marginLeft='8px' size='24px' component={() => <ForwardArrowIcon />} />
            </Button>
        );
    })
);

export default ApplyButton;
