import React from 'react';
import { inject, observer } from 'mobx-react';
import { ALIAS_IMAGES_MAP } from '../../constants';
import ActiveChallenge from './components/ActiveChallenge';
import Completed from './components/Completed';
import { StyledImage, StyledInfoWrapper, StyledWrapper } from './styled';

const StepInfo = inject('store')(
    observer(({ store }) => {
        const { activeChallenge, isNoActiveChallenge } = store.seasonalChallengeStore;

        return (
            <StyledWrapper>
                <StyledImage src={ALIAS_IMAGES_MAP[activeChallenge?.alias] || ALIAS_IMAGES_MAP.completed} />
                <StyledInfoWrapper>{isNoActiveChallenge ? <Completed /> : <ActiveChallenge />}</StyledInfoWrapper>
            </StyledWrapper>
        );
    })
);

export default StepInfo;
