import React from 'react';
import { notification } from '@klickly/front-packages';
import NotificationContent from '../../../../../../../shared/components/NotificationContent';

export const handleTaskCompleted = (store, simple) => {
    const navigate = store.routeNavigate;
    const title = store.tasksStore.rateGiftlyTask?.data?.title;
    store.tasksStore.getTasks();
    store.trackingStore.trackChallengeNotification(store.tasksStore.rateGiftlyTask, 'completed', `${title} completed!`);
    notification.open({
        duration: null,
        description: <NotificationContent.Completed completedActionName={title} />,
        bottom: simple ? '0' : '8px'
    });
    notification.open({
        duration: null,
        description: <NotificationContent.RewardEarned value={50} navigate={navigate} />,
        bottom: simple ? '0' : '8px'
    });
};
