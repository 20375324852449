import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const StyledWrapper = styled.div`
    height: 100%;
    padding-top: 100px;

    @media (${GRID_DEVICES_MAX.md}) {
        padding-top: 48px;
    }

    .ant-row {
        height: 100%;
        max-width: 327px;
        margin: 0 auto;
    }
`;
