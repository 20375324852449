export const TASK_STATUSES = Object.freeze({
    inactive: 'inactive',
    completed: 'completed',
    inProgress: 'in-progress',
    disable: 'disable',
    active: 'active'
});
export const TASK_STATUS_NAMES = Object.freeze({
    [TASK_STATUSES.inactive]: 'Disabled',
    [TASK_STATUSES.completed]: 'Completed',
    [TASK_STATUSES.inProgress]: 'In Progress',
    [TASK_STATUSES.disable]: 'Disable',
    [TASK_STATUSES.active]: 'Active'
});

export const tasksAliasMap = {
    'share-product': 'shareProduct',
    'invite-friend': 'inviteFriend',
    'write-product-review': 'writeProductReview',
    'rate-giftly': 'rateGiftly',
    'shop-suite': 'shopSuite'
};

export const TASKS_ALIASES = Object.freeze({
    INVITE_FRIEND: 'invite-friend',
    RATE_GIFTLY: 'rate-giftly',
    SUITE_FEEDBACK: 'gs-feedback',
    SHARE_PRODUCT: 'share-product',
    PROMOTIONS_SUITE: 'promotions-suite',
    WRITE_PRODUCT_REVIEW: 'write-product-review',
    SHOP_SUITE: 'shop-suite'
});
