import { BrowserStorage } from '@klickly/front-packages';

export const getMetadata = () => [
    {
        name: 'klpixel',
        data: {
            cid: BrowserStorage.get('_klpixel_cid'),
            hash: BrowserStorage.get('_klpixel_hash')
        }
    },
    {
        name: 'location',
        value: window.location.href
    }
];
