import React, { Fragment, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { StyledUI } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { DialogsProvider } from './components/DialogsProvider';
import { usePageTitle } from './shared/hooks';
import { useHandleKlicklyEvents } from './hooks';
import { loadGtm } from './utils';

const App = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const location = useLocation();
        store.setHistoryState(navigate, location);
        usePageTitle();

        useEffect(() => {
            if (!window.___GTM) {
                (async () => {
                    try {
                        await loadGtm();
                        window.___GTM = true;
                    } catch (e) {
                        window.___GTM = false;
                        Bugsnag.notify(new Error('GTM load failed'));
                    }
                })();
            }
        }, []); // eslint-disable-line

        useHandleKlicklyEvents(store);

        return (
            <Fragment>
                <StyledUI />
                <DialogsProvider isLoading={store.isLoading || !store.accountStore.loaded}>
                    <Outlet />
                </DialogsProvider>
            </Fragment>
        );
    })
);

export default App;
