import { types } from 'mobx-state-tree';

const ShareProductTaskDataModel = types.model({
    data: types.maybeNull(
        types.model({
            buttonText: types.maybeNull(types.string),
            description: types.maybeNull(types.string),
            iconURL: types.maybeNull(types.string),
            requiredTime: types.maybeNull(types.string),
            rewardDescription: types.maybeNull(types.string),
            rewardValue: types.maybeNull(types.number),
            status: types.maybeNull(types.string),
            tags: types.array(
                types.maybeNull(
                    types.model({
                        color: types.maybeNull(types.string),
                        html: types.maybeNull(types.string),
                        title: types.maybeNull(types.string)
                    })
                )
            ),
            title: types.maybeNull(types.string)
        })
    ),
    products: types.array(
        types.maybeNull(
            types.model({
                order: types.maybeNull(types.string),
                referrer: types.maybeNull(types.string),
                promotion: types.model({
                    brandName: types.maybeNull(types.string),
                    description: types.maybeNull(types.string),
                    image: types.maybeNull(types.string),
                    title: types.maybeNull(types.string),
                    _id: types.maybeNull(types.string)
                })
            })
        )
    )
});

export const InviteFriendTaskDataModel = types.model({
    attemptsRemained: types.number,
    isAttemptsQuotaRequested: types.boolean,
    results: types.array(
        types.model({
            id: types.string,
            inviteAccepted: types.maybeNull(types.string),
            inviteCode: types.maybeNull(types.string),
            inviteSendAt: types.maybeNull(types.string),
            inviteStatus: types.maybeNull(types.string),
            inviteeEmail: types.maybeNull(types.string),
            inviteeFirstName: types.maybeNull(types.string)
        })
    )
});

const WhiteProductReviewTaskModel = types.model({
    data: types.maybeNull(
        types.model({
            buttonText: types.maybeNull(types.string),
            description: types.maybeNull(types.string),
            title: types.maybeNull(types.string)
        })
    ),
    productReviewList: types.array(
        types.maybeNull(
            types.model({
                order: types.maybeNull(types.string),
                promotion: types.maybeNull(
                    types.model({
                        brandName: types.maybeNull(types.string),
                        description: types.maybeNull(types.string),
                        image: types.maybeNull(types.string),
                        title: types.maybeNull(types.string),
                        _id: types.maybeNull(types.string)
                    })
                )
            })
        )
    )
});

export const TASKS_DATA_MODELS = {
    shareProduct: ShareProductTaskDataModel,
    inviteFriend: InviteFriendTaskDataModel,
    writeProductReview: WhiteProductReviewTaskModel
};
