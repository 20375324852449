import React, { Fragment } from 'react';
import nextId from 'react-id-generator';
import { Carousel, Col, Divider, GRID_POINTS, Row, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import PopoverWithButton from '../../../../shared/components/PopoverWithButton';
import ActionButton from '../ActionButton';
import BrandCard from './components/BrandCard';
import { StyledHeader, StyledWrapper } from './styled';
import { getOffersForMobile } from './utils';

const BestWelcomeGiftOffers = inject('store')(
    observer(({ store }) => {
        const { bestWelcomeGiftOffers } = store.dealsStore;
        const simple = useSimpleInterface(GRID_POINTS.md);

        const offers = simple ? getOffersForMobile(bestWelcomeGiftOffers) : bestWelcomeGiftOffers;

        return (
            <StyledWrapper>
                <StyledHeader>
                    <Row gutter={24} align='middle' justify={simple ? 'center' : 'space-between'}>
                        <Col>
                            <Typography.Title level={2}>Best Welcome Gift Offers</Typography.Title>
                        </Col>
                        <Col>
                            <PopoverWithButton
                                showButton={false}
                                content={
                                    <Fragment>
                                        <b>What is a WELCOME GIFT?</b>
                                        <br />
                                        Your first purchase earns you &ldquo;Welcome Gift&ldquo; points that can be used
                                        towards any brand on our platform. Try products from different brands and earn
                                        points each time. Start saving today and claim your &ldquo;Welcome Gift&ldquo;
                                        points now!
                                    </Fragment>
                                }
                            >
                                <ActionButton icon onClick={() => null}>
                                    What is a Welcome Gift?
                                </ActionButton>
                            </PopoverWithButton>
                        </Col>
                    </Row>
                </StyledHeader>
                <Divider height={4} />
                {simple ? (
                    <Carousel bottomControls={{ justify: 'space-between' }}>
                        {offers.map((offersPair) => (
                            <div key={nextId()}>
                                <Row gutter={20}>
                                    {offersPair.map((offer) => (
                                        <Col key={offer.brandId} span={12}>
                                            <BrandCard
                                                id={offer.brandId}
                                                imgSrc={offer.logoImage}
                                                brandName={offer.name}
                                                pointsValue={Math.round(offer.weightedAvgPoints || 0)}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        ))}
                    </Carousel>
                ) : (
                    <Row gutter={20}>
                        {offers.slice(0, 4).map((offer) => (
                            <Col span={6} key={offer.brandId}>
                                <BrandCard
                                    id={offer.brandId}
                                    imgSrc={offer.logoImage}
                                    brandName={offer.name}
                                    pointsValue={Math.round(offer.weightedAvgPoints || 0)}
                                />
                            </Col>
                        ))}
                    </Row>
                )}
            </StyledWrapper>
        );
    })
);

export default BestWelcomeGiftOffers;
