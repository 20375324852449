import React, { useEffect } from 'react';
import { notification } from '@klickly/front-packages';
import NotificationContent from '../../NotificationContent';

export const useBuyProductNotification = (store, defaultSettings) => {
    const { isApprovedCustomer } = store.accountStore;
    const { activeChallengeName } = store.marketplaceStore;
    const challenge = store.tasksStore?.shopSuiteTask;
    const isChallenge = activeChallengeName === challenge?.alias && isApprovedCustomer;
    const message = 'Step 2 of 3. Add product to cart';

    useEffect(() => {
        if (isChallenge) {
            store.trackingStore.trackChallengeNotification(challenge, '2', message);
            notification.open({
                key: challenge?.alias,
                description: <NotificationContent.Reward rewardCount={50} message={message} />,
                ...defaultSettings
            });
        }
        return () => (typeof notification.destroy === 'function' ? notification.destroy() : null);
    }, [isChallenge]); //eslint-disable-line

    return { isBuyProductTask: isChallenge };
};
