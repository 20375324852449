import React from 'react';
import { device } from '@klickly/front-packages';
import { REWARDS_INFO_DATA } from '../../../../shared';
import { InfoList } from '../../../../shared/components/InfoList';
import { Layout } from '../../../../shared/components/Layout';
import { PageHeader } from '../../../../shared/components/PageHeader';
import { RewardsPageStyles } from './styles';

const RewardsInfo = ({ data = REWARDS_INFO_DATA }) => {
    const isIos = device.iOS();
    const isSafari = device.isSafari();
    return (
        <Layout contentFullHeight withoutBottomPadding={isSafari && isIos}>
            <RewardsPageStyles>
                <PageHeader title='Learn more' subTitle='Rewards' withGoBack />
                <InfoList data={data} />
            </RewardsPageStyles>
        </Layout>
    );
};

export default RewardsInfo;
