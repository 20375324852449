import { types } from 'mobx-state-tree';

export const TASK_MODEL = types.model({
    _id: types.identifier,
    alias: types.string,
    attempts: types.number,
    data: types.maybeNull(
        types.model({
            description: types.maybeNull(types.string),
            iconURL: types.maybeNull(types.string),
            requiredTime: types.maybeNull(types.string),
            rewardDescription: types.maybeNull(types.string),
            rewardValue: types.maybeNull(types.number),
            status: types.maybeNull(types.string),
            tags: types.array(
                types.maybeNull(
                    types.model({
                        color: types.maybeNull(types.string),
                        html: types.maybeNull(types.string),
                        title: types.maybeNull(types.string)
                    })
                )
            ),
            title: types.maybeNull(types.string)
        })
    ),
    isActive: types.boolean,
    priority: types.number,
    results: types.array(types.model({ id: types.maybe(types.string) })),
    isHidden: types.boolean,
    startedAt: types.maybeNull(types.string)
});
