import styled from 'styled-components';

export const StyledWrapper = styled.div`
    max-width: 845px;
    margin: 0 auto;
`;

export const StyledHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
