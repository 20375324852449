import React, { Fragment } from 'react';
import {
    BOTTOM_INDENTS,
    Button,
    CartIcon,
    ForwardArrowIcon,
    GRID_POINTS,
    Icon,
    notification,
    RIGHT_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { ROUTE_PATHS } from '../../../../constants';
import { getOrdersNumbersView } from '../../../../utils';
import { StyledContent, StyledImage, StyledNotification, StyledWrap } from './styled';

const OrderReceivedNotification = ({ ordersNumbers, imageSrc, orderId, navigate }) => {
    const simple = useSimpleInterface(GRID_POINTS.sm);

    const handleClick = () => {
        navigate(ROUTE_PATHS.orders);
        notification.destroy();
    };

    const orderNumbersView = getOrdersNumbersView(ordersNumbers, orderId, navigate);

    return (
        <StyledWrap $simple={simple}>
            <StyledNotification />
            {!simple ? <StyledImage src={imageSrc} alt='order' /> : null}
            <StyledContent $simple={simple}>
                {!simple ? (
                    <Typography.Title level={3} style={{ ...BOTTOM_INDENTS.S }} data-e2e='receipt-pop-up_order-summary'>
                        {orderNumbersView}
                    </Typography.Title>
                ) : (
                    <Fragment>
                        <Icon style={{ ...RIGHT_INDENTS.XS }} size='16px' component={() => <CartIcon />} />
                        <Typography.Text type='descriptor2' data-e2e='receipt-pop-up_order-summary'>
                            {orderNumbersView}
                        </Typography.Text>
                    </Fragment>
                )}
                <Button
                    type={simple ? 'unstyled' : 'primary'}
                    data-e2e='receipt-pop-up_view-details-btn'
                    onClick={handleClick}
                >
                    <Typography.Text type='badgeSmall' style={{ ...RIGHT_INDENTS.XS }}>
                        View details
                    </Typography.Text>
                    <Icon color='primaryText' size='16px' component={() => <ForwardArrowIcon />} />
                </Button>
            </StyledContent>
        </StyledWrap>
    );
};

export default OrderReceivedNotification;
