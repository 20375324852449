import { $border, $grayScale40Black, $primary, $white, TASK_STATUSES } from '../../../shared';

export const getColorsByStatus = (status) => {
    switch (status) {
        case TASK_STATUSES.active:
            return { color: $primary, background: $white, borderColor: $primary, progressColor: $border };
        case TASK_STATUSES.completed:
            return { color: $white, background: $primary, borderColor: $primary, progressColor: $primary };
        default:
            return { color: $grayScale40Black, background: $white, borderColor: $border, progressColor: $border };
    }
};
