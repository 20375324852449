import React from 'react';
import { BOTTOM_INDENTS } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $primary, $primaryText, $secondaryText } from '../../../../index';
import { Timer } from '../../../Timer';
import { StyledText, StyledTitle } from './styled';

const SuiteStartedTitle = inject('store')(
    observer(({ store, simple, isMobileView }) => {
        return (
            <StyledTitle $isMobileView={isMobileView} level={simple || isMobileView ? 2 : 1} align='center'>
                <StyledText style={BOTTOM_INDENTS.SM} $color={$primary}>
                    Your Gifting Suite{' '}
                </StyledText>
                <StyledText $color={$secondaryText}>will expire after</StyledText>{' '}
                <StyledText $color={$primary}>
                    <Timer
                        color={$primaryText}
                        showIcon={false}
                        onTimeLeft={null}
                        endDate={store.suiteStore.nextCloseAt}
                        params={{ minutes: 'min' }}
                    />
                </StyledText>
            </StyledTitle>
        );
    })
);

export default SuiteStartedTitle;
