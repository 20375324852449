import shopBlock1 from '../../../assets/img/landing/blocks/block1.png';
import shopBlock2 from '../../../assets/img/landing/blocks/block2.png';
import shopBlock3 from '../../../assets/img/landing/blocks/block3.png';
import shopBlock4 from '../../../assets/img/landing/blocks/block4.png';
import shopBlock5 from '../../../assets/img/landing/blocks/block5.png';
import shopBlock6 from '../../../assets/img/landing/blocks/block6.png';
import productImg1 from '../../../assets/img/landing/products/product1.svg';
import productImg2 from '../../../assets/img/landing/products/product2.png';
import productImg3 from '../../../assets/img/landing/products/product3.svg';
import productImg4 from '../../../assets/img/landing/products/product4.svg';

export const products = [
    {
        title: 'GRO More Kit',
        price: '$86.00',
        discount: 'Free gift!',
        src: productImg1
    },
    {
        title: 'Beauty Trio Set #1',
        price: '$25.00',
        discount: '90% off!',
        src: productImg2
    },
    {
        title: 'Antioxidant Serum',
        price: '$33.99',
        discount: 'Limited time!',
        src: productImg3
    },
    {
        title: 'Chill Decaf Coffee',
        price: '$20.00',
        discount: '85% OFF!',
        src: productImg4
    }
];

export const shopBlocks = [
    {
        title: 'Claim your free sign-up gift',
        subtitle: 'Gifting Suite Offer!',
        src: shopBlock1
    },
    {
        title: '95% off beauty brands',
        subtitle: 'Juiciest Beauty Brands',
        src: shopBlock3
    },
    {
        title: 'Skincare at 1/3 the price',
        subtitle: 'Most Loved Bodycare',
        src: shopBlock2
    },
    {
        title: 'Luxury decor at unheard of prices',
        subtitle: 'Cozy Home Decor',
        src: shopBlock4
    },
    {
        title: 'Makeup that makes your skin & wallet glow',
        subtitle: 'Juiciest Makeup',
        src: shopBlock5
    },
    {
        title: 'Supplements our members love',
        subtitle: 'Most Loved Supplements',
        src: shopBlock6
    }
];
