import styled from 'styled-components';
import { $white } from '../../../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../../../utils';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 48px;

    .anticon {
        z-index: 1;
    }

    &&& {
        .ant-btn {
            width: 320px;

            @media (${GRID_DEVICES_MAX.md}) {
                width: 100%;
            }

            &:hover {
                .anticon {
                    color: ${$white};
                }
            }

            .anticon {
                font-size: 24px;
            }
        }
    }
`;
