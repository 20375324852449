import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../utils';
import { $white } from '../../index';

export const StyledWrapper = styled.div`
    position: relative;
    padding: 24px 0;

    @media (${GRID_DEVICES_MAX.md}) {
        height: 100%;
        padding-bottom: 168px;
    }
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 64px;
    padding: 24px 0;

    > .anticon {
        margin-bottom: 60px;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        margin-top: 40px;
    }
`;

export const StyledContentWrapper = styled.div`
    height: 100%;
    overflow-y: auto;
`;

export const StyledDescription = styled(Typography.Text)`
    margin-bottom: 64px;
    text-align: center;
`;

export const StyledBottomFixedContent = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: -20px;
    width: calc(100% + 40px);
    padding: 20px;
    background: ${$white};
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.12);

    button {
        display: block;
        width: 100%;

        &:first-child {
            margin-bottom: 4px;
        }
    }
`;
