import { types } from 'mobx-state-tree';

export const SuiteCouponModel = types.model({
    _id: types.identifier,
    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    image: types.maybeNull(types.string),
    priceDetails: types.model({
        originalPrice: types.maybeNull(types.number),
        rewardsPrice: types.maybeNull(types.number),
        rewardsPercent: types.maybeNull(types.number),
        youPayPrice: types.maybeNull(types.number),
        shipping: types.maybeNull(types.number)
    }),
    promotion: types.maybeNull(
        types.model({
            _id: types.identifier,
            externalId: types.string,
            title: types.maybeNull(types.string),
            account: types.maybeNull(types.string),
            campaign: types.maybeNull(types.string),
            campaignName: types.maybeNull(types.string),
            shopDomain: types.maybeNull(types.string),
            brandName: types.maybeNull(types.string),
            variants: types.array(
                types.model({
                    price: types.maybeNull(types.number)
                })
            )
        })
    )
});
