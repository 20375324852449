import React from 'react';
import { BOTTOM_INDENTS, Col, Icon, Row, SettingsIcon, TOP_INDENTS, Typography } from '@klickly/front-packages';
import { $background, $primaryText } from '../../../../../../constants';
import { StyledWrapper } from './styled';

const Body = () => {
    return (
        <StyledWrapper>
            <Row justify='middle' align='center'>
                <Col span={24} style={{ ...BOTTOM_INDENTS.XXL, ...TOP_INDENTS.XXL }}>
                    <Icon
                        size='40px'
                        roundedSize='120px'
                        roundedColor={$background}
                        hoverRoundedColor={$background}
                        color={$primaryText}
                        roundedOpacity={1}
                        component={() => <SettingsIcon />}
                    />
                </Col>
                <Col align='center' span={24}>
                    <Typography.Title align='center' style={BOTTOM_INDENTS.M} color={$primaryText} level={2}>
                        Giftly server is temporarily unavailable
                    </Typography.Title>
                    <Typography.Text align='center' color={$primaryText} type='body1'>
                        Try refreshing this page or reload at a later time
                    </Typography.Text>
                </Col>
            </Row>
        </StyledWrapper>
    );
};

export default Body;
