import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    GRID_CUSTOM_POINTS,
    GRID_POINTS,
    Promotion,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import placeholder from '../../../../assets/img/image-placeholder.svg';
import PromotionsSlider from '../../../../shared/components/PromotionsSlider';
import { CustomCollapse } from './components/CustomCollapse';
import { StyledContentLeft, StyledContentListItem, StyledContentRight, StyledWrap } from './styled';

export const BestSellers = inject('store')(
    observer(({ store, hidden, onChange }) => {
        const navigate = useNavigate();
        const twoColumns = useSimpleInterface(GRID_CUSTOM_POINTS.biggest);
        const { brandBestSellers } = store.brandPageStore;
        const simple = useSimpleInterface(GRID_POINTS.md);

        const promotions = twoColumns && !simple ? brandBestSellers.slice(0, 2) : brandBestSellers;
        const simpleRow = simple && brandBestSellers?.length === 1;

        return (
            <StyledWrap $simple={simple}>
                {!simple ? (
                    <StyledContentLeft>
                        <Typography.Title level={1} color='secondaryText' align='left'>
                            Bestsellers
                        </Typography.Title>
                    </StyledContentLeft>
                ) : null}
                <StyledContentRight $simple={simple}>
                    {simple ? (
                        <CustomCollapse hidden={hidden} onChange={onChange}>
                            <Typography.Title
                                level={2}
                                color='primaryText'
                                align='center'
                                style={{ ...BOTTOM_INDENTS.S }}
                            >
                                Bestsellers
                            </Typography.Title>
                            <PromotionsSlider
                                withoutHeader
                                promotions={promotions}
                                promotionsHeight={simpleRow ? '250px' : '370px'}
                                settings={simpleRow ? { slidesToShow: 1, slidesToScroll: 1, responsive: [] } : {}}
                            />
                        </CustomCollapse>
                    ) : (
                        promotions.map((promotion) => (
                            <StyledContentListItem key={promotion._id}>
                                <Promotion
                                    placeholder={placeholder}
                                    withoutButton
                                    inSlider
                                    onBuy={(promotion) =>
                                        navigate({
                                            pathname: `/products/${promotion._id}`,
                                            search: '?page_ref=brand-page'
                                        })
                                    }
                                    promotion={promotion}
                                />
                            </StyledContentListItem>
                        ))
                    )}
                </StyledContentRight>
            </StyledWrap>
        );
    })
);
