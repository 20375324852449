import React, { Fragment } from 'react';
import { BOTTOM_INDENTS, Button, Form, Input, TOP_INDENTS, useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../../utils';
import { VALIDATION_RULES } from '../../constants';
import { useConfirmDeletingModal } from '../ConfirmDeletingModal/hooks';
import { ErrorField } from '../ErrorField';
import { ShippingAddressFormFields } from '../ShippingAddressFormFields';
import { ShippingAddressFormControls } from './components/ShippingAddressFormControls';
import { StyledButtonsWrapper } from './styled';

export const ShippingAddressForm = ({
    address,
    isDefault,
    onSubmit,
    submitButtonText,
    showDeleteAddressButton,
    setDefault,
    onDelete,
    error,
    from,
    onAfterSuccess,
    isChallenge
}) => {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    const [form] = Form.useForm();
    const openConfirmDeletingModal = useConfirmDeletingModal({
        title: 'Delete shipping',
        bodyText: 'Are you sure you want to delete this shipping address?',
        onConfirm: onDelete
    });

    const submitted = (data) => {
        if (typeof onSubmit !== 'undefined') {
            onSubmit(data, onAfterSuccess);
        }
    };

    return (
        <Form form={form} initialValues={address} onFinish={submitted} validateTrigger={['onBlur']}>
            <Form.Item noStyle shouldUpdate>
                {() =>
                    address ? (
                        <Form.Item hidden name='_id' rules={VALIDATION_RULES.REQUIRED}>
                            <Input />
                        </Form.Item>
                    ) : null
                }
            </Form.Item>
            {simple && !isDefault && !isChallenge ? (
                <Button
                    block
                    type='primary'
                    size='large'
                    onClick={setDefault}
                    style={simple && { ...BOTTOM_INDENTS.M }}
                >
                    Set as default address
                </Button>
            ) : null}

            <ShippingAddressFormFields hasBottomIndent form={form} />

            {error ? (
                <Fragment>
                    <ErrorField hasBottomIndent error={error} />
                </Fragment>
            ) : null}

            {simple ? null : (
                <ShippingAddressFormControls
                    from={from}
                    showDeleteAddressButton={showDeleteAddressButton}
                    onDelete={simple ? openConfirmDeletingModal : null}
                    submitButtonText={submitButtonText}
                />
            )}

            {simple ? (
                <StyledButtonsWrapper style={TOP_INDENTS.XL}>
                    <ShippingAddressFormControls
                        from={from}
                        showDeleteAddressButton={showDeleteAddressButton}
                        onDelete={simple ? openConfirmDeletingModal : null}
                        submitButtonText={submitButtonText}
                    />
                </StyledButtonsWrapper>
            ) : null}
        </Form>
    );
};
