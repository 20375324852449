import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { notification, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../shared';
import { AddShipping } from '../../../../shared/components/AddShipping';
import { Animated } from '../../../../shared/components/Animated';
import NotificationContent from '../../../../shared/components/NotificationContent';
import { PageHeader } from '../../../../shared/components/PageHeader';
import { GRID_POINTS } from '../../../../utils';
import { StyledIndent } from './styled';

const ShippingAddPage = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { state } = useLocation();
        const { isApprovedCustomer } = store.accountStore;
        const alias = store.tasksStore?.addShippingTask?.alias;
        const simple = useSimpleInterface(GRID_POINTS.sm);
        const message = 'Add your first Shipping address';
        const isChallenge = state && alias && state === alias && isApprovedCustomer;

        useEffect(() => {
            if (isChallenge) {
                const challenge = store.tasksStore?.addShippingTask;
                store.trackingStore.trackChallengeNotification(challenge, '1', message);
                notification.open({
                    key: alias,
                    duration: null,
                    description: <NotificationContent.Reward rewardCount={1} rewardType='claims' message={message} />,
                    bottom: simple ? '0' : '8px'
                });
            }
            return () => (typeof notification.destroy === 'function' ? notification.destroy() : null);
        }, []); //eslint-disable-line
        return (
            <Animated style={{ maxWidth: '845px', margin: 'auto' }}>
                <PageHeader
                    titleComponent={(props) => <Typography.Title level={2} {...props} />}
                    title='Add shipping address'
                    subTitle=''
                    withGoBack
                />
                <AddShipping
                    onAfterSuccess={({ taskCompleted }) =>
                        navigate(ROUTE_PATHS.shippingBilling, {
                            state: { taskCompleted }
                        })
                    }
                    isChallenge={isChallenge}
                />
                {simple && isChallenge && <StyledIndent />}
            </Animated>
        );
    })
);

export default ShippingAddPage;
