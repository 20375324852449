import React, { Fragment } from 'react';
import { Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { PageHeader } from '../../../../../../shared/components/PageHeader';
import { EditAccountForm } from './components/EditAccountForm';

export const AccountContainer = inject('store')(
    observer(({ store }) => {
        const { account } = store.accountStore;

        return (
            <Fragment>
                <PageHeader hasBottomIndent title='My profile' />
                {account ? <EditAccountForm /> : <Icon.Loader />}
            </Fragment>
        );
    })
);
