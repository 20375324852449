import React, { useEffect, useState } from 'react';
import { Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { InviteFriendTaskContent } from './components/InviteFriendTaskContent';

export const InviteFriendTaskContainer = inject('store')(
    observer(({ store, onClose }) => {
        const [submitted, setSubmitted] = useState(false);
        const { getInviteFriendTaskData, inviteFriendTaskData, dataPending } = store.tasksStore;

        useEffect(() => {
            getInviteFriendTaskData();
        }, []);

        return inviteFriendTaskData && !dataPending ? (
            <InviteFriendTaskContent submitted={submitted} onClose={onClose} setSubmitted={setSubmitted} />
        ) : (
            <Icon.Loader />
        );
    })
);
