import React from 'react';
import { BOTTOM_INDENTS, Col, Divider, roundToLocaleString, Row, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $border, $secondaryText } from '../../../../../../../../shared';
import { StyledWrapper } from './styled';

const OrderPriceDetails = inject('store')(
    observer(({ store, orderNumber }) => {
        const { getDetailsByOrderNumber, getOrderRewards } = store.orderStore;
        const details = getDetailsByOrderNumber(orderNumber);
        const rewardData = getOrderRewards(orderNumber);

        return details ? (
            <StyledWrapper>
                <Row align='middle' justify='space-between' gutter={8} style={BOTTOM_INDENTS.XXS}>
                    <Col>
                        <Typography.Text type='body2' color={$secondaryText}>
                            Original Price
                        </Typography.Text>
                    </Col>
                    <Col>
                        <Typography.Text type='body2' color={$secondaryText} data-e2e='giftly-order_summary-orig-price'>
                            ${roundToLocaleString(details?.priceDetails.originalSubTotal)}
                        </Typography.Text>
                    </Col>
                </Row>
                <Row align='middle' justify='space-between' gutter={8} style={BOTTOM_INDENTS.XXS}>
                    <Col>
                        <Typography.Text type='body2' color={$secondaryText}>
                            {rewardData?.rewardsCount} Rewards applied
                        </Typography.Text>
                    </Col>
                    <Col>
                        <Typography.Text
                            type='body2'
                            color={$secondaryText}
                            data-e2e='giftly-order_summary-reward-apply'
                        >
                            -${roundToLocaleString(rewardData?.rewardsAmount)}(
                            {Math.floor(
                                ((rewardData?.rewardsAmount || 0) / details?.priceDetails.originalSubTotal) * 100
                            )}
                            % off)
                        </Typography.Text>
                    </Col>
                </Row>
                <Row align='middle' justify='space-between' gutter={8} style={BOTTOM_INDENTS.XXS}>
                    <Col>
                        <Typography.Text type='body2' color={$secondaryText}>
                            Shipping
                        </Typography.Text>
                    </Col>
                    <Col>
                        <Typography.Text type='body2' color={$secondaryText} data-e2e='giftly-order_summary-shipping'>
                            ${roundToLocaleString(details?.priceDetails.shipping)}
                        </Typography.Text>
                    </Col>
                </Row>
                <Row align='middle' justify='space-between' gutter={8}>
                    <Col>
                        <Typography.Text type='body2' color={$secondaryText}>
                            Taxes
                        </Typography.Text>
                    </Col>
                    <Col>
                        <Typography.Text type='body2' color={$secondaryText} data-e2e='giftly-order_summary-taxes'>
                            ${roundToLocaleString(details?.priceDetails.taxes)}
                        </Typography.Text>
                    </Col>
                </Row>
                <Divider height='1px' color={$border} />
                <Row align='middle' justify='space-between' gutter={8} style={BOTTOM_INDENTS.XXL}>
                    <Col>
                        <Typography.Text type='badgeMedium'>Subtotal</Typography.Text>
                    </Col>
                    <Col>
                        <Typography.Text type='badgeMedium' data-e2e='giftly-order_summary-subtotal'>
                            ${roundToLocaleString(details?.priceDetails.total)}
                        </Typography.Text>
                    </Col>
                </Row>
            </StyledWrapper>
        ) : null;
    })
);

export default OrderPriceDetails;
