import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../utils';
import { $tartRed } from '../../../../index';

export const StyledWrapper = styled.div`
    margin-left: 48px;

    h1,
    h2,
    h3 {
        max-width: 420px;
    }

    .anticon:hover {
        cursor: auto;
        &:after {
            background-color: ${$tartRed};
        }
    }

    @media (${GRID_DEVICES_MAX.md}) {
        margin-left: 0;

        h1,
        h2,
        h3 {
            max-width: 100%;
        }
    }

    ${({ $isMobileView }) =>
        $isMobileView &&
        css`
            margin-left: 0;

            h1,
            h2,
            h3 {
                max-width: 100%;
            }
        `};
`;
