import React, { Fragment, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GRID_POINTS, notification, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import {
    SEASONAL_CHALLENGE_ALIASES,
    TASK_STATUSES,
    useSeasonalChallengeNotifications
} from '../../../../../../../../shared';
import { CloseModalIcon } from '../../../../../../../../shared/components/CloseModalButton';
import NotificationContent from '../../../../../../../../shared/components/NotificationContent';
import { InviteFriendTaskInitialContent } from './components/InviteFriendTaskInitialContent';
import { InviteFriendTaskThanks } from './components/InviteFriendTaskThanks';

export const InviteFriendTaskContent = inject('store')(
    observer(({ store, submitted, onClose, setSubmitted }) => {
        const [searchParams] = useSearchParams();
        const isFromReward = searchParams.get('from-reward');
        const { isApprovedCustomer } = store.accountStore;
        const { activeChallenge } = store.seasonalChallengeStore;
        const { inviteFriendTaskData: data, inviteFriendTask } = store.tasksStore;
        const simple = useSimpleInterface(GRID_POINTS.md);

        const showSeasonalChallengeNotification = useSeasonalChallengeNotifications(store);

        const challenge = inviteFriendTask;
        const message = 'Step 2 of 2. Fill out the form and click Send invite';

        useEffect(() => {
            if (typeof notification.destroy === 'function') notification.destroy();
        }, []);

        useEffect(() => {
            if (isApprovedCustomer && isFromReward && inviteFriendTask?.data.status !== TASK_STATUSES.completed) {
                store.trackingStore.trackChallengeNotification(challenge, '2', message);
                if (!submitted) {
                    notification.open({
                        key: challenge?.alias,
                        duration: null,
                        description: <NotificationContent.Reward rewardCount={50} message={message} />,
                        bottom: simple ? '0' : '8px'
                    });
                }
            }
            if (
                activeChallenge?.alias === SEASONAL_CHALLENGE_ALIASES.INVITE_FRIENDS &&
                activeChallenge.challengeAccepted
            ) {
                showSeasonalChallengeNotification(
                    '2',
                    'Invite a friend. Once they are accepted, you\'ll earn a reward', // prettier-ignore
                    { duration: null, bottom: simple ? '0' : '8px' }
                );
            }
            return () => (typeof notification.destroy === 'function' ? notification.destroy() : null);
            }, [submitted]); //eslint-disable-line

        return data ? (
            <Fragment>
                {!simple ? <CloseModalIcon onClick={onClose} /> : null}
                {!submitted ? (
                    <InviteFriendTaskInitialContent onClose={onClose} setSubmitted={setSubmitted} />
                ) : (
                    <InviteFriendTaskThanks onClose={onClose} setSubmitted={setSubmitted} />
                )}
            </Fragment>
        ) : null;
    })
);
