import React from 'react';
import { ArrowChevronForwardIcon, GRID_POINTS, Icon, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { SEASONAL_CHALLENGE_ALIASES, useSeasonalChallengeNotifications } from '../../../../../../shared';
import { StyledIconWrap, StyledLeftContent, StyledTextWrapper, StyledWrapper } from './styles';

export const EarnTask = inject('store')(
    observer(({ store, icon, task, onClick = null, title: customTitle, description: customDescription }) => {
        const title = task?.data?.title || customTitle || '';
        const description = task?.data?.description || customDescription || '';
        const { activeChallenge } = store.seasonalChallengeStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const showSeasonalChallengeNotification = useSeasonalChallengeNotifications(store);

        return (
            <StyledWrapper
                $pointer={typeof onClick === 'function'}
                onClick={() => {
                    if (typeof onClick === 'function') onClick();
                    if (
                        activeChallenge?.alias === SEASONAL_CHALLENGE_ALIASES.INVITE_FRIENDS &&
                        activeChallenge.challengeAccepted
                    ) {
                        showSeasonalChallengeNotification(
                            '2',
                            'Invite a friend. Once they accepted, you\'ll earn a reward', // prettier-ignore
                            { duration: null, bottom: simple ? '0' : '8px' }
                        );
                    }
                }}
            >
                <StyledLeftContent>
                    {icon ? <StyledIconWrap>{icon}</StyledIconWrap> : null}
                    <StyledTextWrapper>
                        <Typography.Title level={3} style={{ marginBottom: '4px' }}>
                            {title}
                        </Typography.Title>
                        {description ? (
                            <Typography.Text type='body2' color='secondaryText'>
                                {description}
                            </Typography.Text>
                        ) : null}
                    </StyledTextWrapper>
                </StyledLeftContent>
                <Icon size='16px' color='secondaryText' component={() => <ArrowChevronForwardIcon />} />
            </StyledWrapper>
        );
    })
);
