import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../../../../../../../../../../../../../utils';

export const CancelOrderStyles = styled.div`
    width: 630px;

    @media (${GRID_DEVICES_MAX.sm}) {
        width: 100%;
    }
`;

export const CancelOrderContent = styled.div`
    padding: 40px 24px;
`;
