import React from 'react';
import { KliInfinityScrollGallery } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { READY_TO_LUNCH_PRODUCTS } from '../../../../../../shared';
import SuiteGalleryVariant1 from './components/SuiteGalleryVariant1';
import { SuiteProductItem } from './components/SuiteProductItem';
import { GalleryWrapper } from './styled';

const SuiteGallery = observer(() => {
    return (
        <GalleryWrapper>
            <KliInfinityScrollGallery height='285px' speed={30} stopOnHover>
                {READY_TO_LUNCH_PRODUCTS.map((item) => (
                    <SuiteProductItem
                        img={item.imageSRC}
                        key={item.title}
                        description={item.brandName}
                        title={item.title}
                    />
                ))}
            </KliInfinityScrollGallery>
        </GalleryWrapper>
    );
});

export default SuiteGallery;

SuiteGallery.Variant1 = SuiteGalleryVariant1;
