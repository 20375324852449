import React from 'react';
import { CloseXIcon } from '@klickly/front-packages';
import { CloseModalIcon, StyledModalContent, StyledModalWrapper, StyledTitle } from './styled';

const ModalContainer = ({
    simple,
    onClose,
    title,
    children,
    withoutCloseIcon,
    modalWrapperStyles,
    modalContentStyles
}) => {
    return (
        <StyledModalWrapper onClick={(e) => e.stopPropagation()} style={modalWrapperStyles}>
            {!withoutCloseIcon ? (
                <CloseModalIcon
                    size='24px'
                    roundedSize='200%'
                    color='canvas'
                    onClick={onClose}
                    component={() => <CloseXIcon />}
                />
            ) : null}
            {title ? <StyledTitle>{title}</StyledTitle> : null}
            <StyledModalContent $simple={simple} style={modalContentStyles}>
                {children}
            </StyledModalContent>
        </StyledModalWrapper>
    );
};

export default ModalContainer;
