import { Button } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../../../../../../../../../../../../../utils';

export const ButtonsWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (${GRID_DEVICES_MAX.sm}) {
        flex-direction: column-reverse;
        width: 100%;
    }
`;

export const StyledButton = styled(Button)`
    && {
        padding: 20px 85px;
        margin: 0 10px;

        @media (${GRID_DEVICES_MAX.sm}) {
            width: 100%;
            margin-bottom: 16px;
        }
    }
`;
