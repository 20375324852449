import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, EmojiSadFrownIcon, ForwardArrowIcon, Icon, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $background, $primaryText, $white, ROUTE_PATHS } from '../../shared';
import { BoxLoader } from '../../shared/components/BoxLoader';
import { Layout } from '../../shared/components/Layout';
import { GRID_POINTS } from '../../utils';
import { saveOnboardingQueryData } from '../../utils/saveOnboardingQueryData';
import { StyledDescription, StyledTitle, StyledWrapper } from './styled';

const VerifyInviteCode = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { search } = useLocation();
        const simple = useSimpleInterface(GRID_POINTS.sm);
        const { errorMessage, setOnboardingEmail, verifyInviteCode, clearError } = store.onboardingStore;

        const urlSearchParams = new URLSearchParams(search);
        const inviteCode = urlSearchParams.get('inviteCode');
        const invitedEmail = urlSearchParams.get('email');

        useEffect(() => {
            (async () => {
                if (invitedEmail) setOnboardingEmail(invitedEmail);
                if (inviteCode) {
                    const data = await verifyInviteCode(inviteCode);
                    if (data && data?.isValid) {
                        saveOnboardingQueryData(search);
                        navigate({ pathname: ROUTE_PATHS.root });
                    }
                }
            })();
        }, []);

        if (errorMessage) {
            return (
                <Layout contentFullHeight withoutPadding menuHidden>
                    <StyledWrapper>
                        <BoxLoader
                            waveColor={simple ? $background : $white}
                            iconColor={$primaryText}
                            iconSize='55px'
                            withShadow={false}
                            icon={<EmojiSadFrownIcon />}
                            animationDuration='0s'
                        />
                        <StyledTitle align='center'>
                            The invite you are using <br /> is no longer valid
                        </StyledTitle>
                        <StyledDescription type='body1'>
                            You still can apply for access Exclusive deals <br /> & gifts for members
                        </StyledDescription>
                        <Button
                            type='primary'
                            size='large'
                            ghost
                            onClick={() => {
                                clearError();
                                navigate(ROUTE_PATHS.personalInfo);
                            }}
                        >
                            <span>Apply for access</span>
                            <Icon stylePriority size='24px' component={() => <ForwardArrowIcon />} />
                        </Button>
                    </StyledWrapper>
                </Layout>
            );
        }

        return <Icon.Loader />;
    })
);

export default VerifyInviteCode;
