import styled, { css } from 'styled-components';
import { animation$ } from '../../../../shared/constants/animation';
import { GRID_DEVICES_CUSTOM_MAX, GRID_DEVICES_CUSTOM_MIN, GRID_DEVICES_MAX } from '../../../../utils';

export const StyledWrap = styled.div`
    width: 100%;
    display: flex;
    padding-bottom: 40px;
    padding-top: ${({ $simple, $withTopPadding }) => ($simple && $withTopPadding ? '20px' : '0')};
`;

export const StyledSideBar = styled.div`
    width: 305px;
    margin-right: 40px;

    ${({ $simple }) =>
        $simple
            ? css`
                  width: 0;
                  margin: 0;
              `
            : ''}
`;

export const StyledContentWrap = styled.div`
    width: calc(100% - 305px);

    ${({ $simple }) =>
        $simple
            ? css`
                  width: 100%;
              `
            : ''}
`;

export const StyledPromotionsWrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

export const StyledContentListItem = styled.div`
    display: flex;
    position: relative;
    padding: 10px;
    animation: ${animation$} 0.3s ease-in-out;
    will-change: transform, opcity;

    &:hover {
        z-index: 1;
    }

    @media (${GRID_DEVICES_MAX.sm}) {
        padding: 10px 8px;
    }

    ${({ $fullHeight }) =>
        $fullHeight &&
        css`
            height: 100%;
        `};

    ${({ $fullWidth }) =>
        $fullWidth
            ? css`
                  width: 100%;
              `
            : ''};

    ${({ $fullWidth }) =>
        !$fullWidth &&
        css`
            @media (${GRID_DEVICES_CUSTOM_MAX.small}) {
                width: ${({ $inSlider }) => ($inSlider ? 100 : 50)}%;
            }

            @media (${GRID_DEVICES_CUSTOM_MIN.small}) {
                width: 50%;
            }

            @media (${GRID_DEVICES_CUSTOM_MIN.medium}) {
                width: ${({ $inSlider }) => ($inSlider ? 100 : 50)}%;
            }

            @media (${GRID_DEVICES_CUSTOM_MIN.large}) {
                width: 50%;
            }

            @media (${GRID_DEVICES_CUSTOM_MIN.biggest}) {
                width: 33%;
            }
        `};
`;
