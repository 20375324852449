import React from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../shared';
import ApplyInviteCode from '../components/ApplyInviteCode';
import Approved from '../components/Approved';
import ConnectInstagram from '../components/ConnectInstagram';
import OnboardingSuccess from '../components/OnboardingSuccess';
import PersonalInfo from '../components/PersonalInfo';
import PersonalizationCategories from '../components/PersonalizationCategories';
import PersonalizationImportance from '../components/PersonalizationImportance';
import PersonalizationProcessing from '../components/PersonalizationProcessing';
import PersonalizationShipping from '../components/PersonalizationShipping';
import Processing from '../components/Processing';
import Waitlisted from '../components/Waitlisted';

export const ROUTES = [
    {
        path: ROUTE_PATHS.onboarding,
        index: true,
        element: <Navigate to={ROUTE_PATHS.personalInfo} replace />
    },
    {
        path: ROUTE_PATHS.welcome,
        element: <Navigate to={ROUTE_PATHS.personalInfo} replace />
    },
    {
        path: ROUTE_PATHS.personalInfo,
        element: <PersonalInfo />
    },
    {
        path: ROUTE_PATHS.connectInstagram,
        element: <ConnectInstagram />
    },
    {
        path: ROUTE_PATHS.processing,
        element: <Processing />
    },
    {
        path: ROUTE_PATHS.waitlisted,
        element: <Waitlisted />
    },
    {
        path: ROUTE_PATHS.applyInviteCode,
        element: <ApplyInviteCode />
    },
    {
        path: ROUTE_PATHS.approved,
        element: <Approved />
    },
    {
        path: ROUTE_PATHS.personalizationCategories,
        element: <PersonalizationCategories />
    },
    {
        path: ROUTE_PATHS.personalizationImportance,
        element: <PersonalizationImportance />
    },
    {
        path: ROUTE_PATHS.personalizationShipping,
        element: <PersonalizationShipping />
    },
    {
        path: ROUTE_PATHS.personalizationProcessing,
        element: <PersonalizationProcessing />
    },
    {
        path: ROUTE_PATHS.onboardingSuccess,
        element: <OnboardingSuccess />
    }
];
