import React from 'react';
import { Order } from './components/Order';
import { Styles } from './styles';

export const OrdersList = ({ orders = [], ...rest }) => {
    return (
        <Styles {...rest}>
            {orders.map((order) => (
                <Order key={order._id} order={order} />
            ))}
        </Styles>
    );
};
