import styled from 'styled-components';

export const StyledWrap = styled.div`
    max-width: 969px;
    width: 100%;
    height: 100%;
    padding-top: 8px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
`;

export const ErrorWrap = styled.div`
    padding: 0 25px;
`;
