import { Button } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
    && {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 76px;
        box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.12);

        p {
            font-weight: 500;
        }
    }
`;
