import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Logo } from '../../../../../Logo';
import { StyledLink } from './styled';

const Header = inject('store')(
    observer(({ store }) => {
        const { getHomePageLink } = store.navigationStore;
        const { clearAll } = store.marketplaceStore;

        return (
            <StyledLink>
                <Link to={getHomePageLink()}>
                    <Logo onClick={() => clearAll({ shouldClearSearch: true, shouldClearStrategy: true })} />
                </Link>
            </StyledLink>
        );
    })
);

export default Header;
