import { Button, Icon } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $primary, $primaryText, $robinBlue } from '../../../../constants';

export const StyledWrap = styled.div``;

export const StyledIcon = styled(Icon)`
    && {
        font-size: 20px !important;
    }
`;

export const StyledButton = styled(Button)`
    &&&,
    &&&:hover,
    &&&:active {
        width: 181px;

        ${({ $isProductInWishlist }) =>
            $isProductInWishlist
                ? css`
                      opacity: 1;
                      color: ${$robinBlue};
                  `
                : ''}
    }
`;

export const StyledLabel = styled.span`
    color: ${({ $isProductInWishlist }) => ($isProductInWishlist ? $robinBlue : $primaryText)};
`;

export const ButtonLoaderWrap = styled.div`
    position: relative;
    width: 20px;
    height: 20px;
    margin-left: 8px;

    & > div > span {
        color: ${$primary} !important;
        font-size: 20px !important;
    }
`;
