import React, { useMemo } from 'react';
import { Item, StyledCheckbox, StyledText, Styles } from './styles';

export const FilterItems = ({ items, onChange }) => {
    const sortedItems = useMemo(() => [...items].sort(({ name: a }, { name: b }) => a.localeCompare(b)), [items]);
    return (
        <Styles>
            {sortedItems.map((item) => (
                <Item key={item.key}>
                    <StyledCheckbox
                        type='rounded'
                        size='20px'
                        checked={item.checked}
                        onChange={(e) => onChange({ ...item, checked: e.target.checked })}
                        checkedColor='primary'
                        value={item.key}
                    >
                        <StyledText title={item.name} as='span' type='body2'>
                            {item.name}
                        </StyledText>
                    </StyledCheckbox>
                </Item>
            ))}
        </Styles>
    );
};
