import React from 'react';
import { Icon } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { TASK_STATUSES } from '../../../../../../shared';
import { getIconByAlias } from '../../../../../SeasonalChallenge/utils';
import { getColorsByStatus } from '../../utils';
import { StyledCol, StyledStep, StyledStepWrapper } from './styled';

const Step = observer(({ status, isLastStep, alias, position, name }) => {
    const colors = getColorsByStatus(status);
    const isActive = status === TASK_STATUSES.active;
    const StepIcon = getIconByAlias(alias);
    return (
        <StyledCol {...(isLastStep ? {} : { flex: 'auto' })}>
            <StyledStepWrapper $colors={colors} $isActive={isActive}>
                <StyledStep $colors={colors}>
                    <Icon size='24px' component={() => <StepIcon />} />
                </StyledStep>
            </StyledStepWrapper>
        </StyledCol>
    );
});

export default Step;
