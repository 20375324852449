import React, { Fragment, useEffect, useRef, useState } from 'react';
import { dynamicFontSize, Icon, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import qs from 'qs';
import { GRID_POINTS } from '../../../utils';
import { ROUTE_PATHS } from '../../constants';
import { StyledImgWrapper, StyledTitle, StyledWrapper } from './styled';

const ExclusiveDealsBanner = inject('store')(
    observer(({ store }) => {
        const { bannerTextSizePending, setBannerSizePending } = store.marketplaceStore;
        const { getStrategyInfo, strategyInfo } = store.dealsStore;
        const mobile = useSimpleInterface(GRID_POINTS.md);
        const bannerRef = useRef(null);
        const [fontSize, setFontSize] = useState(mobile ? 48 : 56);
        const bannerText = strategyInfo?.styles?.bannerText;

        useEffect(() => {
            if (bannerRef.current) {
                dynamicFontSize(bannerRef, setFontSize, fontSize, bannerText, mobile, setBannerSizePending);
            }
        }, [bannerRef.current, bannerText, fontSize]);

        useEffect(() => {
            getStrategyInfo();
        }, []);

        return strategyInfo?.styles?.bannerImage ? (
            <StyledWrapper
                ref={bannerRef}
                onClick={() =>
                    window.open(`${ROUTE_PATHS.shop}?${qs.stringify({ strategy: strategyInfo.name })}`, '_self')
                }
            >
                {bannerTextSizePending && bannerText ? (
                    <Icon.Loader />
                ) : (
                    <Fragment>
                        <StyledImgWrapper>
                            <img src={strategyInfo.styles.bannerImage} alt={bannerText || ''} />
                        </StyledImgWrapper>
                        <StyledTitle $fontSize={`${fontSize}px`}>{bannerText}</StyledTitle>
                    </Fragment>
                )}
            </StyledWrapper>
        ) : null;
    })
);
export default ExclusiveDealsBanner;
