export const ROUTE_PATHS = {
    root: '/',
    verify: '/verify',
    auth: '/auth',
    signIn: '/auth/sign-in',
    signOut: '/sign-out',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password',

    dashboard: '/dashboard',
    settings: '/settings',
    account: '/settings/account-settings',
    orders: '/settings/orders',
    order: '/settings/orders/:id',
    shippingBilling: '/settings/shipping-billing',
    favoriteCategories: '/settings/favorite-categories',
    shippingAdd: '/settings/shipping-billing/shipping-add',
    shippingEdit: '/settings/shipping-billing/shipping-edit',
    billingAdd: '/settings/shipping-billing/billing-add',
    billingEdit: '/settings/shipping-billing/billing-edit',
    notifications: '/settings/notifications',
    subscriptions: '/settings/membership',
    sendFeedback: '/settings/send-feedback',
    extensionSettings: '/settings/extension',
    wishlist: '/settings/wishlist',

    blocked: '/blocked',

    onboarding: '/onboarding',
    onboardingVerify: '/onboarding/verify',
    welcome: '/onboarding/welcome',
    personalInfo: '/onboarding/personal-info',
    connectInstagram: '/onboarding/connect-instagram',
    processing: '/onboarding/processing',
    waitlisted: '/onboarding/waitlisted',
    applyInviteCode: '/onboarding/apply-invite-code',
    approved: '/onboarding/approved',
    personalizationCategories: '/onboarding/categories',
    personalizationImportance: '/onboarding/add-product-to-cart',
    personalizationShipping: '/onboarding/shipping',
    personalizationProcessing: '/onboarding/processing',
    onboardingSuccess: '/onboarding/success',

    shoppingCart: '/shopping-cart',
    preCheckout: '/checkout',
    upsell: '/upsell',

    suite: '/gifting-suite',
    suiteGs: '/gifting-suite/gs',
    suiteGsCouponDetails: '/gifting-suite/gs/coupon-details',
    suiteGsInfo: '/gifting-suite/gs/learn-more',
    suiteGsSharingRules: '/gifting-suite/gs/sharing-rules',

    rewards: '/rewards',
    rewardsApplyRedirect: '/reward/:uuid',
    rewardsApply: '/rewards/apply',
    coupons: '/rewards/coupons',
    challenges: '/rewards/challenges',
    rewardsInfo: '/rewards/learn-more',
    seasonalChallenge: '/seasonal-challenge',
    points: '/points',
    pointsInfo: '/points/learn-more',

    inviteAndEarn: '/earn',
    inviteAndEarnRules: '/earn/rules',

    shop: '/marketplace',
    pdp: '/marketplace/:id',
    shopWithPDP: '/marketplace/:promotionId?',
    share: '/gifting-suite/share',

    deals: '/deals',

    brand: '/brands/:brandId',
    pdpProductPage: '/products/:productId',

    helpPage: '/help',
    helpCenter: '/help-center',
    helpPageBetaTerms: '/help/betaterms',
    helpPageTerms: '/help/terms',
    helpPagePrivacy: '/help/privacy',
    helpPagePrivacyOptOut: '/help/privacy-opt-out',
    helpPageDisclosureSensitiveInfo: '/help/disclosure-sensitive-info',

    betaTerms: '/betaterms',
    terms: '/terms',
    privacy: '/privacy',
    privacyCalifornia: '/privacy-california',
    privacyOptOut: '/privacy-opt-out',

    shareProductLink: '/r/:base64Params',

    checkoutThankYouPage: '/thank-you',
    gsThankYouPage: '/gs-thank-you',

    extensionUninstall: '/uninstall',

    unsubscribe: '/unsubscribe',

    notFound: '*'
};

export const ROUTE_TITLES = {
    [ROUTE_PATHS.root]: 'Giftly',
    [ROUTE_PATHS.auth]: 'Auth',
    [ROUTE_PATHS.signIn]: 'Sign in',
    [ROUTE_PATHS.blocked]: 'User blocked',
    [ROUTE_PATHS.forgotPassword]: 'Forgot password',
    [ROUTE_PATHS.resetPassword]: 'Reset password',

    [ROUTE_PATHS.dashboard]: 'Dashboard',
    [ROUTE_PATHS.settings]: 'Settings',
    [ROUTE_PATHS.account]: 'Account Settings',
    [ROUTE_PATHS.orders]: 'Account Orders',
    [ROUTE_PATHS.shippingBilling]: 'Shipping Billing',
    [ROUTE_PATHS.favoriteCategories]: 'Favorite Categories',
    [ROUTE_PATHS.shippingAdd]: 'Shipping | Add',
    [ROUTE_PATHS.shippingEdit]: 'Shipping | Edit',
    [ROUTE_PATHS.billingAdd]: 'Billing | Add',
    [ROUTE_PATHS.billingEdit]: 'Billing | Edit',
    [ROUTE_PATHS.subscriptions]: 'Manage Subscription',
    [ROUTE_PATHS.notifications]: 'Manage Notifications',
    [ROUTE_PATHS.sendFeedback]: 'Send feedback',
    [ROUTE_PATHS.extensionSettings]: 'Extension Settings',
    [ROUTE_PATHS.wishlist]: 'Wishlist',

    [ROUTE_PATHS.onboarding]: 'Onboarding',
    [ROUTE_PATHS.welcome]: 'Onboarding | Intro',
    [ROUTE_PATHS.personalInfo]: 'Onboarding | Personal Info',
    [ROUTE_PATHS.connectInstagram]: 'Onboarding | Connect Instagram',
    [ROUTE_PATHS.processing]: 'Onboarding | Processing',
    [ROUTE_PATHS.waitlisted]: 'Onboarding | Waitlisted',
    [ROUTE_PATHS.applyInviteCode]: 'Onboarding | Apply invite code',
    [ROUTE_PATHS.approved]: 'Onboarding | Approved',
    [ROUTE_PATHS.personalizationCategories]: 'Onboarding | Personalization',
    [ROUTE_PATHS.personalizationImportance]: 'Onboarding | Personalization',
    [ROUTE_PATHS.personalizationShipping]: 'Onboarding | Personalization',
    [ROUTE_PATHS.personalizationProcessing]: 'Onboarding | Processing',
    [ROUTE_PATHS.onboardingSuccess]: 'Onboarding | Success',

    [ROUTE_PATHS.shoppingCart]: 'Giftly | Shopping cart',
    [ROUTE_PATHS.preCheckout]: 'Giftly | Checkout',
    [ROUTE_PATHS.upsell]: 'Giftly | Upsell',

    [ROUTE_PATHS.suite]: 'Giftly | Dashboard',
    [ROUTE_PATHS.suiteGs]: 'Giftly | Dashboard',
    [ROUTE_PATHS.suiteGsCouponDetails]: 'Giftly | Coupon Details',
    [ROUTE_PATHS.suiteGsInfo]: 'Giftly | Learn More',
    [ROUTE_PATHS.suiteGsSharingRules]: 'Giftly | Sharing Rules',

    [ROUTE_PATHS.rewards]: 'Giftly | Rewards',
    [ROUTE_PATHS.rewardsApply]: 'Giftly | Rewards',
    [ROUTE_PATHS.coupons]: 'Giftly | Rewards',
    [ROUTE_PATHS.challenges]: 'Giftly | Rewards',
    [ROUTE_PATHS.rewardsInfo]: 'Giftly | Rewards',
    [ROUTE_PATHS.seasonalChallenge]: 'Giftly | Seasonal Challenge',
    [ROUTE_PATHS.points]: 'Giftly | Points',

    [ROUTE_PATHS.inviteAndEarn]: 'Giftly | Invite and Earn',
    [ROUTE_PATHS.inviteAndEarnRules]: 'Invite and Earn Rules',

    [ROUTE_PATHS.shop]: 'Giftly | Shop',
    [ROUTE_PATHS.deals]: 'Giftly | Deals',
    [ROUTE_PATHS.share]: 'Giftly | share',

    [ROUTE_PATHS.brand]: 'Giftly | Brands',
    [ROUTE_PATHS.pdpProductPage]: 'Giftly | Product',

    [ROUTE_PATHS.helpPage]: 'Giftly | Help',

    [ROUTE_PATHS.betaTerms]: 'Giftly | Beta Terms',
    [ROUTE_PATHS.terms]: 'Giftly | Terms',
    [ROUTE_PATHS.privacy]: 'Giftly | Privacy Policy',
    [ROUTE_PATHS.privacyCalifornia]: 'Giftly | Privacy Notice for California Residents',
    [ROUTE_PATHS.privacyOptOut]: 'Giftly | Right to Opt Out of Sale of Information',
    [ROUTE_PATHS.helpPageDisclosureSensitiveInfo]: 'Giftly | Right to Limit Use/Disclosure of Sensitive Information',

    [ROUTE_PATHS.extensionUninstall]: 'Giftly',

    [ROUTE_PATHS.checkoutThankYouPage]: 'Giftly | Thank you',
    [ROUTE_PATHS.gsThankYouPage]: 'Giftly | Thank you',

    [ROUTE_PATHS.notFound]: '404'
};
