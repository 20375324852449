import React, { Fragment } from 'react';
import nextId from 'react-id-generator';
import { Button, FilledStarIcon, StarIcon, Typography, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { useApplyClick } from '../../../../hooks';
import { reviews } from './reviews';
import {
    StyledContent,
    StyledReview,
    StyledReviewHead,
    StyledReviewsWrap,
    StyledStarsWrap,
    StyledTextBlock,
    StyledWrap
} from './styles';

export const ReviewsSection = observer(() => {
    const simple = useSimpleInterface(700);
    const onApplyClick = useApplyClick();

    return (
        <StyledWrap>
            {!simple ? (
                <Typography.Text type='em0' align='center' style={{ marginBottom: '64px' }}>
                    What Giftly members are saying
                </Typography.Text>
            ) : (
                <Typography.Title align='center' style={{ marginBottom: '8px' }}>
                    What Giftly members are saying
                </Typography.Title>
            )}
            <StyledContent>
                <StyledTextBlock>
                    {!simple ? (
                        <Typography.Title style={{ marginBottom: '24px' }}>4.8 out of 5!</Typography.Title>
                    ) : (
                        <Typography.Title level={2} align='center' style={{ marginBottom: '16px' }}>
                            4.8 out of 5!
                        </Typography.Title>
                    )}

                    <Typography.Text type='body1' style={{ marginBottom: '24px' }} align={!simple ? 'left' : 'center'}>
                        This is the overall rating of the platform by Giftly members!
                    </Typography.Text>
                    {!simple && (
                        <Button size='large' type='secondary' style={{ width: '100%' }} onClick={onApplyClick}>
                            <Typography.Text type='badgeLarge'>Apply for access</Typography.Text>
                        </Button>
                    )}
                </StyledTextBlock>
                <StyledReviewsWrap>
                    {reviews.map((review, index) => {
                        return (
                            <StyledReview key={nextId()}>
                                <StyledReviewHead>
                                    <StyledStarsWrap
                                        size='22px'
                                        color='alert'
                                        component={() => (
                                            <Fragment>
                                                <FilledStarIcon />
                                                <FilledStarIcon />
                                                <FilledStarIcon />
                                                <FilledStarIcon />
                                                {index + 1 !== reviews.length ? <FilledStarIcon /> : <StarIcon />}
                                            </Fragment>
                                        )}
                                    />
                                    <Typography.Text type='body2'>{review.text}</Typography.Text>
                                </StyledReviewHead>
                                <Typography.Title level={3}>{review.name}</Typography.Title>
                            </StyledReview>
                        );
                    })}
                </StyledReviewsWrap>
                {simple && (
                    <Button size='large' type='secondary' style={{ width: '100%' }} onClick={onApplyClick}>
                        <Typography.Text type='badgeLarge'>Apply for access</Typography.Text>
                    </Button>
                )}
            </StyledContent>
        </StyledWrap>
    );
});
