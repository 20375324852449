import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ActionsPlusIcon,
    BOTTOM_INDENTS,
    Button,
    Col,
    Icon,
    RIGHT_INDENTS,
    Row,
    TOP_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../shared';
import { GRID_POINTS } from '../../../../../../utils';
import { ShippingItem } from './components/ShippingItem';
import { StyledButton, StyledHead } from './styles';

export const ShippingContainer = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { shippingAddresses, deleteShippingAddress, setShippingAddressAsDefault } = store.accountStore;
        const simple = useSimpleInterface(GRID_POINTS.sm);
        const showDeleteAddressButton = shippingAddresses && shippingAddresses.length > 1;

        const onAddAddress = () => {
            navigate(ROUTE_PATHS.shippingAdd);
        };

        const onEditAddress = (addressId) => {
            navigate({
                pathname: ROUTE_PATHS.shippingEdit,
                search: `id=${addressId}`
            });
        };

        return (
            <div style={TOP_INDENTS.SM}>
                <StyledHead style={BOTTOM_INDENTS.SM}>
                    <Typography.Title level={3}>Shipping</Typography.Title>
                    {simple && (
                        <Button onClick={onAddAddress} type='unstyled' style={{ padding: '0' }}>
                            <Icon stylePriority component={() => <ActionsPlusIcon />} style={RIGHT_INDENTS.XXS} />
                            <Typography.Text as='span'>Add</Typography.Text>
                        </Button>
                    )}
                </StyledHead>
                {shippingAddresses && shippingAddresses.length ? (
                    <Fragment>
                        {shippingAddresses.map((address) => (
                            <ShippingItem
                                key={address._id}
                                address={address}
                                onEdit={() => onEditAddress(address._id)}
                                onDelete={() => deleteShippingAddress(address._id)}
                                showDeleteButton={showDeleteAddressButton}
                                onAsDefault={() => setShippingAddressAsDefault(address._id)}
                                isDefault={address.isDefault}
                                simple={simple}
                            />
                        ))}
                        {!simple && (
                            <Button onClick={onAddAddress} type='unstyled'>
                                <Icon stylePriority component={() => <ActionsPlusIcon />} />
                                <Typography.Text as='span'>Add</Typography.Text>
                            </Button>
                        )}
                    </Fragment>
                ) : (
                    <Row>
                        <Col span={24} md={{ span: 12 }}>
                            <StyledButton onClick={onAddAddress} size='large' type='dashed'>
                                <Icon component={() => <ActionsPlusIcon />} />
                                <Typography.Text as='span'>Add shipping info</Typography.Text>
                            </StyledButton>
                        </Col>
                    </Row>
                )}
            </div>
        );
    })
);
