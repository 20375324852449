import { Button, Typography } from '@klickly/front-packages';
import styled from 'styled-components';

export const NotificationText = styled(Typography.Text)`
    text-align: center;
    margin-top: 24px;
`;

export const StyledButton = styled(Button)`
    && {
        width: 100%;
    }
`;
