import { getFilterParamsFromUrl, getPriceRange, getUrlParamsFromFilter, MARKETPLACE } from '@klickly/front-packages';
import { flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import qs from 'qs';
import { kcpAPI } from '../shared';
import { models } from './constants';

const { PROMOTIONS_SORTS_STATE } = MARKETPLACE;

export const BrandPageStore = types
    .model('BrandPageStore', {
        pending: types.boolean,
        error: models.ErrorModel,
        brandId: types.maybeNull(types.string),
        brandData: types.maybeNull(
            types.model({
                name: types.maybeNull(types.string),
                url: types.maybeNull(types.string),
                googleTaxonomy: types.frozen(),
                bannerImage: types.maybeNull(types.string),
                bestSellers: types.maybeNull(types.array(models.PromotionModel)),
                categories: types.maybeNull(types.array(types.string)),
                pixels: types.array(
                    types.model({
                        name: types.string,
                        enabled: types.maybeNull(types.boolean),
                        src: types.string
                    })
                )
            })
        ),
        promotionsPending: types.boolean,
        promotions: types.array(models.PromotionModel),
        search: types.maybe(types.string),
        query: types.maybe(models.QueryModel),
        page: types.number,
        nextPage: types.maybeNull(types.number),
        totalHits: types.maybeNull(types.number),
        totalShown: types.maybeNull(types.number),
        isOpenOnMobile: types.boolean,
        prevProductId: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get bannerImage() {
            return self.brandData?.bannerImage || '';
        },
        get brandName() {
            return self.brandData?.name || '';
        },
        get brandBestSellers() {
            return self.brandData?.bestSellers || [];
        },
        get brandUrl() {
            return self.brandData?.url || null;
        },
        get brandCategories() {
            return self.brandData?.categories || [];
        },
        get brandPixels() {
            return self.brandData?.pixels || [];
        },
        get brandTwPixel() {
            return self.brandPixels.find((pixel) => pixel.name === 'tw');
        }
    }))
    .actions((self) => {
        const setBrandId = (brandId) => {
            self.brandId = brandId;
        };

        const getBrandData = flow(function* getBrandData({ isSimple }) {
            self.pending = true;
            try {
                const data = yield self.root.get(
                    `${kcpAPI.marketplace.brands.getBrandData(self.brandId)}?bestSellersPerPage=${isSimple ? 10 : 3}`
                );
                self.brandData = data;
                self.root.trackingStore.trackViewPromotions(data.bestSellers);
            } catch (error) {
                self.error = error;
            } finally {
                self.pending = false;
            }
        });

        const getPromotions = flow(function* getPromotions({
            brandId,
            isNextPage = false,
            customQueryParams = null,
            isNewSearch = false
        }) {
            if (isNewSearch) {
                self.page = 1;
                self.nextPage = 0;
            }
            if (isNextPage && (self.page === self.nextPage || self.nextPage === null)) return false;

            const url = self.search ? kcpAPI.marketplace.search.basic : kcpAPI.marketplace.promotions.get;

            self.promotionsPending = true;
            try {
                const page = isNextPage ? self.nextPage : self.page;
                const queryParams = getUrlParamsFromFilter({ ...self.query, search: self.search }, { page });
                queryParams.brands = [brandId || self.brandId];
                const data = yield self.root.get(`${url}?${qs.stringify(customQueryParams || queryParams)}`);
                self.page = data.page;
                self.promotions =
                    self.page === 1 || (!isNextPage && isNewSearch)
                        ? [...data.promotions]
                        : [...getSnapshot(self.promotions), ...data.promotions];
                self.nextPage = data.pagination.next;
                self.totalHits = data.totalHits;
                self.totalShown = data.totalShown;

                self.root.trackingStore.trackViewPromotions(data.promotions);
            } catch (error) {
                self.error = error;
            } finally {
                self.promotionsPending = false;
            }
        });

        const updatePromotions = (params = {}) => {
            const { queryParams = {}, isNewSearch = false } = params;

            const search = qs.stringify(getUrlParamsFromFilter({ ...self.query }, queryParams));
            self.root.routeNavigate({ pathname: window.location.pathname, search });
            getPromotions({ isNextPage: false, isNewSearch });
        };

        const updateIsOpenOnMobile = (isOpenOnMobile) => {
            self.isOpenOnMobile = isOpenOnMobile;
        };

        const updateCategory = (data) => {
            self.query.categories = self.query.categories.map((category) =>
                category.key === data.key ? { ...category, ...data } : { ...category }
            );
            updatePromotions({ isNewSearch: true });
        };

        const updatePrice = (data) => {
            self.query.price = { ...data };
            updatePromotions({ isNewSearch: true });
        };
        const updateQuery = (data) => {
            self.query = { ...self.query, ...data };
        };

        const selectSorting = (data) => {
            self.query.sort = self.query.sort.map((sortItem) => ({ ...sortItem, checked: sortItem.key === data.key }));
            updatePromotions({ isNewSearch: true });
        };

        const clearAll = (params) => {
            const clearParams = { refetch: true, ...(typeof params === 'object' ? params : {}) };
            self.query.price = { ...getPriceRange() };
            self.query.sort = [...PROMOTIONS_SORTS_STATE];
            self.query.gt = '';
            if (clearParams.refetch) updatePromotions({ isNewSearch: true });
        };
        const setPrevProductId = (value) => {
            self.prevProductId = value;
        };

        const afterCreate = () => {
            const { search, ...query } = getFilterParamsFromUrl();
            self.search = search;
            self.query = query;
        };

        return {
            afterCreate,
            setBrandId,
            getBrandData,
            getPromotions,
            updateIsOpenOnMobile,
            updateCategory,
            updatePrice,
            selectSorting,
            clearAll,
            setPrevProductId,
            updateQuery,
            updatePromotions
        };
    });
