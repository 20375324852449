import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Alert,
    BOTTOM_INDENTS,
    ForwardArrowIcon,
    Icon,
    InfoIcon,
    TOP_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { strategies } from '../../../../../../config/strategies';
import {
    $primaryText,
    ROUTE_PATHS,
    SEASONAL_CHALLENGE_ALIASES,
    useSeasonalChallengeNotifications
} from '../../../../../../shared';
import { Animated } from '../../../../../../shared/components/Animated';
import { Timer } from '../../../../../../shared/components/Timer';
import { get, GRID_POINTS } from '../../../../../../utils';
import { SuiteOpenedList } from './components/SuiteOpenedList';

const SuiteOpened = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const strategyId = strategies.gsCrossSellStrategy;
        const { pending, data: suitePromotions } = store.suitePromotionsStore;
        const { getStrategyInfoById, strategyInfo } = store.marketplaceStore;
        const { getSeasonalChallenge, activeChallenge } = store.seasonalChallengeStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const strategyDescription = get(strategyInfo, 'styles.bannerText', ''); //
        const strategyName = get(strategyInfo, 'name', '');

        const onClickStrategyBlock = () => {
            store.trackingStore.sendEvent({
                type: 'event',
                name: 'click',
                data: { element: { name: 'GSIncentiveOld' } }
            });
            navigate({
                pathname: ROUTE_PATHS.shop,
                search: `?strategy=${strategyName}`
            });
        };

        const showSeasonalChallengeNotification = useSeasonalChallengeNotifications(store);

        useEffect(() => {
            if (strategyId) {
                getStrategyInfoById(strategyId);
            }

            (async () => {
                await store.suitePromotionsStore.getSuiteCoupons(() => {
                    if (
                        activeChallenge?.alias === SEASONAL_CHALLENGE_ALIASES.LAUNCH_GS &&
                        activeChallenge.challengeAccepted
                    ) {
                        showSeasonalChallengeNotification('completed');
                        getSeasonalChallenge(true);
                    }
                });
            })();
        }, []);

        return (
            <Animated style={BOTTOM_INDENTS.XXL}>
                <Typography.Title level={2} align={simple ? 'center' : 'left'}>
                    This is your Gifting Suite
                </Typography.Title>
                <Timer
                    style={{
                        ...TOP_INDENTS.XS,
                        ...BOTTOM_INDENTS.XL
                    }}
                    color={$primaryText}
                    showIcon={false}
                    onTimeLeft={null}
                    endDate={store.suiteStore.nextCloseAt}
                    text='You have {time} to choose 1 of the following Reward coupons'
                    align={simple ? 'center' : 'left'}
                />
                <Alert
                    message={strategyDescription}
                    showIcon
                    icon={<InfoIcon />}
                    afterIcon={<ForwardArrowIcon />}
                    onClick={onClickStrategyBlock}
                />
                {suitePromotions || pending ? <SuiteOpenedList /> : <Icon.Loader />}
            </Animated>
        );
    })
);

export default SuiteOpened;
