import { app } from '../../config';
import { ROUTE_PATHS } from './routePaths';

export const CART_REDIRECTS = {
    customThankYouPageUrl: `${app.giftlyUrl}${ROUTE_PATHS.checkoutThankYouPage}`,
    continueShoppingUrl: `${app.giftlyUrl}${ROUTE_PATHS.shop}`
};

export const PDP_REDIRECTS = Object.freeze({
    suite: {
        customThankYouPageUrl: `${app.giftlyUrl}${ROUTE_PATHS.gsThankYouPage}`
    },
    shop: {
        continueShoppingUrl: `${app.giftlyUrl}${ROUTE_PATHS.shop}`,
        customThankYouPageUrl: `${app.giftlyUrl}${ROUTE_PATHS.checkoutThankYouPage}`
    },
    rewards: {
        continueShoppingUrl: `${app.giftlyUrl}${ROUTE_PATHS.coupons}`,
        customThankYouPageUrl: `${app.giftlyUrl}${ROUTE_PATHS.checkoutThankYouPage}`
    },
    onboarding: {
        continueShoppingUrl: `${app.giftlyUrl}${ROUTE_PATHS.shop}`,
        customThankYouPageUrl: `${app.giftlyUrl}${ROUTE_PATHS.checkoutThankYouPage}`
    }
});

export * from './api';
export * from './colors';
export * from './data.mock';
export * from './dialogs';
export * from './gs';
export * from './layoutContext';
export * from './notifications';
export * from './promotions';
export * from './routePaths';
export * from './seasonalChallenges';
export * from './tasks';
export * from './validationRules';
