import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI, ROUTE_PATHS } from '../shared';

export const ShareProductStore = types
    .model('ShareProductStore', {
        error: types.maybe(types.string),
        pending: true,
        sharedPromotion: types.maybeNull(
            types.model({
                _id: types.identifier,
                title: types.string,
                brandName: types.string,
                description: types.string,
                images: types.array(
                    types.model({
                        src: types.string,
                        externalId: types.number
                    })
                ),
                variants: types.array(
                    types.model({
                        price: types.number,
                        pointsDetails: types.model({
                            maxPoints: types.number,
                            isWelcome: types.boolean,
                            list: types.array(
                                types.model({
                                    type: types.string,
                                    value: types.number
                                })
                            )
                        })
                    })
                )
            })
        )
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get cheapestVariant() {
            // already sorted on back-end
            return self.sharedPromotion.variants[0];
        },
        get guestPointsUpTo() {
            const pointsDetails = self.cheapestVariant.pointsDetails;
            const fixedPoints = pointsDetails.list.find((details) => details.type === 'fixed');
            if (!fixedPoints) {
                return pointsDetails.maxPoints;
            }
            return fixedPoints.value;
        }
    }))
    .actions((self) => {
        const shareProductLink = (referrer, promotionId) => {
            self.root.routeNavigate({
                pathname: `${ROUTE_PATHS.shop}/${promotionId}`,
                search: `referrer=${referrer}`
            });
        };

        const setError = (error) => {
            self.error = error;
        };

        const getPromotion = flow(function* getPromotion(promotionId) {
            try {
                self.sharedPromotion = yield self.root.get(kcpAPI.promotions.fetchUrl(promotionId), {
                    shareProduct: true
                });
                self.pending = false;
            } catch (error) {
                self.error = error.response?.data?.message;
                self.pending = false;
            }
            return self.sharedPromotion;
        });

        const removeSharedPromotion = () => {
            self.sharedPromotion = null;
        };

        return {
            shareProductLink,
            setError,
            getPromotion,
            removeSharedPromotion
        };
    });
