import { Icon, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $primary } from '../../../../shared';

export const StyledWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 40px;

    ${({ $simple }) =>
        $simple
            ? css`
                  flex-direction: column;
                  padding: 24px;
              `
            : ''}
`;

export const StyledContent = styled.div`
    display: flex;
    flex-wrap: wrap;

    ${({ $simple }) =>
        $simple
            ? css`
                  justify-content: center;
              `
            : ''}
`;

export const StyledHeadLine2 = styled(Typography.Title)`
    ${({ $simple }) =>
        !$simple
            ? css`
                  max-width: 210px;
                  margin-right: 68px;
              `
            : css`
                  margin-bottom: 40px;
                  text-align: center;
              `}
`;

export const StyledPoint = styled.div`
    display: flex;
    align-items: center;
    width: 302px;
    margin: 0 24px 24px 0;
`;

export const StyledIcon = styled(Icon)`
    width: 52px;
    height: 52px;
    background-color: ${$primary};
    padding: 14px;
    border-radius: 50%;
    margin-right: 20px;
`;
