import React from 'react';
import { Avatar, BOTTOM_INDENTS, Col, Row, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { StyledMenuHeaderWrapper } from './styled';

const MenuHeader = inject('store')(
    observer(({ store }) => {
        const { profileImageURL, firstName, avatar } = store.accountStore;
        return (
            <StyledMenuHeaderWrapper>
                <Row gutter={24} align='middle'>
                    <Col>
                        <Avatar size={80} data-e2e='appMenu_menu-avatar' src={avatar || profileImageURL} />
                    </Col>
                    <Col>
                        <Typography.Text type='body2' style={BOTTOM_INDENTS.XXXS}>
                            Hello
                        </Typography.Text>
                        <Typography.Title>{firstName}</Typography.Title>
                    </Col>
                </Row>
            </StyledMenuHeaderWrapper>
        );
    })
);

export default MenuHeader;
