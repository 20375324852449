import React from 'react';
import { BOTTOM_INDENTS } from '@klickly/front-packages';
import { ReactComponent as LogoIcon } from '../../../assets/img/logo.svg';
import { KliLogo } from './Logo.styles';

export const Logo = ({ hasBottomIndent, size, color, ...rest }) => {
    return (
        <KliLogo style={hasBottomIndent ? BOTTOM_INDENTS.XL : {}} color={color} size={size} {...rest}>
            <LogoIcon />
        </KliLogo>
    );
};
