import styled, { css } from 'styled-components';
import { $bordered, $tartRed } from '../../shared';

export const StyledWrap = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${$tartRed};
`;

export const StyledContent = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;

    ${({ $simple }) =>
        $simple
            ? css`
                  padding: 26px;
              `
            : ''}
`;

export const StyledButtonsWrap = styled.div`
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${({ $simple }) =>
        $simple
            ? css`
                  max-width: unset;
                  width: calc(100% + 48px);
                  left: -24px;
                  position: sticky;
                  bottom: 0;
                  background-color: ${$tartRed};
                  padding: 24px;
                  border-top: 1px solid ${$bordered};
                  z-index: 5;
              `
            : ''}
`;
