import React from 'react';
import { Animated } from '../../../../shared/components/Animated';
import { PageHeader } from '../../../../shared/components/PageHeader';
import { EditShipping } from './components/EditShipping';

const ShippingEditPage = () => {
    return (
        <Animated style={{ maxWidth: '845px', margin: 'auto' }}>
            <PageHeader hasBottomIndent title='Edit shipping address' subTitle='' withGoBack />
            <EditShipping />
        </Animated>
    );
};

export default ShippingEditPage;
