import React from 'react';
import { GiftIcon, Typography } from '@klickly/front-packages';
import { StyledIcon, StyledWrapper } from './styled';

const CompletedNotification = ({ icon = <GiftIcon />, completedActionName, text = '', customContent = null }) => {
    return (
        <StyledWrapper>
            <StyledIcon size='24px' component={() => icon} />
            {customContent || (
                <Typography.Title level={3}>{text || `${completedActionName} completed!`}</Typography.Title>
            )}
        </StyledWrapper>
    );
};

export default CompletedNotification;
