import React from 'react';
import { BOTTOM_INDENTS } from '@klickly/front-packages';
import { LegalContent } from '../../../../shared/components/LegalContent';
import { StyledText } from './styles';

/* eslint-disable */
const PrivacyPolicyPage = () => {
    return (
        <LegalContent title='Privacy Policy' date='Last Updated August 10, 2023'>
            <StyledText type='descriptor1'>
                This Privacy Policy governs the manner in which Klickly, Inc. ( "Klickly" , "We" or "Us") collects,
                uses, maintains and discloses information collected from users and other individuals (each, a "User").
                This policy applies to your use of our websites and other applications or platforms displaying this
                Privacy Policy (individually and collectively, the "Site" ), interactions with our technology, and the
                real-time commerce services offered by Klickly (together with the Site, the "Services" ) or when you
                otherwise interact with us. If you use the Services, you consent to this Privacy Policy and the
                collection, use and disclosure of your information as described below.
            </StyledText>
            <br />
            <StyledText type='badgeLarge'>How we collect User information</StyledText>
            <br />
            <StyledText type='descriptor1'>
                We may collect information from Users in a variety of ways, including, but not limited to, when Users
                visit our Site, create an account, fill out a form, engage in a transaction via our Services and in
                connection with other technology, activities, services, features or resources we make available via our
                Services, including, but not limited to, information received from customers in connection with the
                Services.
            </StyledText>
            <br />
            <StyledText type='descriptor1'>
                We collect information that Users provide directly to us. For example, we may collect Users’ names,
                email addresses, shipping addresses, billing information, profile customizations and any other
                information Users choose to provide. Please note that the credit card and banking information Users may
                provide via the Site will be collected directly by our financial service provider, who will use such
                information solely to process and settle payment transactions.
            </StyledText>
            <br />
            <StyledText type='descriptor1'>
                When you access, use, or interact with our Services, we automatically collect information about you,
                including but not limited to:
            </StyledText>
            <br />
            <StyledText as='div' type='descriptor1'>
                <ul>
                    <li>
                        Log information: We log information about Users whenever they interact with our Services,
                        including but not limited to the browser name, pages viewed, access times, the type of computer
                        and technical information about Users’ means of connection to our Services, such as the
                        operating system and any internet service providers utilized and other similar information.
                    </li>
                    <li>
                        Web browser cookies and web beacons: We may use “cookies” and/or “web beacons” or other
                        technology to enhance our Services or User experience. Cookies and web beacons are small data
                        files stored on your hard drive or in device memory that help us to improve our Services and
                        your experience, see which areas and features of our Services are popular and count visits. For
                        more information about cookies and web beacons, and how to disable them, please see "Your
                        choices" below.
                    </li>
                </ul>
            </StyledText>
            <br />
            <StyledText type='descriptor1'>
                We may also obtain information from other sources and combine that with information we collect through
                our Services. For example, when you connect your account with a social media site, we will have access
                to certain information from that site, such as your name, account information and friends lists, in
                accordance with the authorization procedures determined by such social media site. We may also receive
                information from customers in connection with Services we provide to them.
            </StyledText>
            <br />
            <StyledText type='descriptor1'>
                Sensitive Information: We do not require you to provide us sensitive information (e.g. racial or ethnic
                origin, political opinions, religious or philosophical beliefs, trade union memberships, genetic data,
                biometric data, data concerning health or data concerning sexual orientation, or other sensitive
                personal information as classified under an applicable law). In the event that you provide such
                sensitive information as listed above without an express agreement covering it, you acknowledge that you
                have provided, or in the case of another individual have obtained, explicit consent concerning the
                collection, use and disclosure of such information in accordance with this applicable law.
            </StyledText>
            <br />
            <StyledText type='badgeLarge'>How we use User information</StyledText>
            <br />
            <StyledText type='descriptor1'>
                We use Users' information for various purposes, including but not limited to:
            </StyledText>
            <br />
            <StyledText as='div' type='descriptor1'>
                <ul>
                    <li>Provide, maintain and improve our Services;</li>
                    <li>
                        Provide and deliver the products and services you request, process transactions and send you
                        related information, including confirmations and invoices;
                    </li>
                    <li>
                        Send you technical notices, updates, security alerts and support and administrative messages;
                    </li>
                    <li>Respond to your comments, questions and requests and provide customer service;</li>
                    <li>
                        Communicate with you about products, services, offers, promotions, rewards, and events offered
                        by Klickly and others, and provide news, advertising, and information we think will be of
                        interest to you;
                    </li>
                    <li>Monitor and analyze trends, usage and activities in connection with our Services;</li>
                    <li>
                        Personalize and improve the Services and provide advertisements, content or features that match
                        user profiles or interests;
                    </li>
                    <li>Process and deliver contest entries and rewards;</li>
                    <li>Aggregate and analyze data;</li>
                    <li>
                        Link or combine with information we get from others to help understand your needs and provide
                        you with better service;
                    </li>
                    <li>
                        Carry out any other purpose for which the information was collected or which is permitted by a
                        customer contract or by law;
                    </li>
                    <li>Manage our business, including but not limited to de-identifying information; and</li>
                    <li>
                        Enforce our terms and conditions and other agreements; respond to investigations, court orders,
                        legal process; or to investigate, prevent or take action regarding illegal activities, or
                        suspected fraud; or as otherwise required by law.
                    </li>
                </ul>
            </StyledText>
            <br />
            <StyledText type='descriptor1'>
                The nature of our business involves the utilization of the information that we collect for a number of
                different purposes, including the purposes of our business, our customers and business partners or other
                third parties, in connection with ecommerce, advertising, online monitoring and other activities, and
                you understand and agree to such collection and usage.
            </StyledText>
            <br />
            <StyledText type='badgeLarge'>How we disclose or share User information</StyledText>
            <br />
            <StyledText type='descriptor1'>
                We disclose or share Users' information for various purposes, including but not limited to:
            </StyledText>
            <br />
            <StyledText as='div' type='descriptor1'>
                <ul>
                    <li>
                        We may disclose your information to any merchant or organization to which the User donates or
                        makes a payment via our real-time commerce solution. These merchants and organizations may use
                        such information to fulfill Users' purchases and to engage in their own direct marketing. If you
                        are a resident of California or Virginia, please see "Your choices" below for more details about
                        this disclosure;
                    </li>
                    <li>
                        We may disclose your information to a Klickly client or customer, including but not limited to,
                        in a transaction that may be considered a “sale” under some laws unless you register under an
                        available opt-out mechanism.
                    </li>
                    <li>We may engage in or otherwise permit cross-context behavioral advertising.</li>
                    <li>
                        We may share your personal data with companies operating in the ad tech sector for their own
                        commercial marketing purpose.
                    </li>
                    <li>
                        We may share your information in connection with, or during negotiations of, any merger, sale of
                        assets, financing or acquisition of all or a portion of our business to another company;
                    </li>
                    <li>
                        We may also share aggregated or de-identified information, which cannot reasonably be used to
                        identify you.
                    </li>
                    <li>
                        We may disclose your information to third party service providers to help us operate our
                        business and the Services or administer activities on our behalf, such as sending out
                        newsletters or surveys.
                    </li>
                    <li>
                        We may disclose your information in response to a request for information if we believe
                        disclosure is in accordance with any applicable law, regulation or legal process, or as
                        otherwise required by any applicable law, rule or regulation; and
                    </li>
                    <li>
                        We may disclose your information if we believe your actions are inconsistent with the spirit or
                        language of our user agreements or policies, or to protect the rights, property and safety of
                        Klickly or others.
                    </li>
                    <li>
                        With respect to personal information that is deidentified by Klickly, Klickly publicly commits
                        to maintain and use the information in deidentified form and not to attempt to reidentify the
                        information. Klickly takes reasonable measures to ensure that the information cannot be
                        associated with a consumer or household, and contractually obligates any recipients of such
                        deidentified information to comply with all applicable laws.
                    </li>
                </ul>
            </StyledText>
            <br />
            <StyledText type='badgeLarge'>How we protect User information</StyledText>
            <br />
            <StyledText type='descriptor1'>
                We adopt appropriate data collection, storage and processing practices and security measures to protect
                against unauthorized access, alteration, disclosure or destruction of User information. However, we do
                not guarantee that your information may not be accessed, disclosed, altered or destroyed by breach of
                our technical and organizational measures to prevent them.
            </StyledText>
            <br />
            <StyledText type='badgeLarge'>Advertising, Profiling and Behavioral Targeting</StyledText>
            <br />
            <StyledText type='descriptor1'>
                We advertise to, and may allow advertisers to display advertising in connection with, the Users. The
                advertisers may use technology such as cookies to gather information about your activities on multiple
                sites in order to provide you advertising based upon your activities and interests. Please review your
                browser or mobile device information for instructions on how to delete and disable cookies, and other
                tracking/recording tools. To learn more about cookies, clear gifs/web beacons and related technologies,
                you may wish to visit <a href='http://www.allaboutcookies.org'>http://www.allaboutcookies.org</a> and/or
                the Network Advertising Initiative’s online resources, at{' '}
                <a href='http://www.networkadvertising.org'>http://www.networkadvertising.org</a>, which also contains
                opt-out options supported by the industry organization. To the extent required by applicable law, we
                will not engage in a sale of personal information under the CCPA through our advertising if you opt-out
                using the Do Not Sell My Personal Information page on our website.
            </StyledText>
            <br />
            <StyledText type='descriptor1'>
                Klickly collects data regarding users’ online activities over time and across non-affiliated sites for
                the purpose of using such data to predict user preferences or interests to deliver advertising to a
                computer or device based on the preferences or interests inferred from such users’ online activities.
            </StyledText>
            <br />
            <StyledText type='descriptor1'>
                Data collected from a particular browser or device may be used to match another computer or device to
                you, or used with another computer or device that is linked to the browser or device on which such data
                was collected, or transferred to a non-affiliate for any lawful purposes, including advertising and
                marketing. Klickly itself does not engage in cross-device tracking on sensitive data, including health,
                financial, and children’s information, as well as precise geolocation information. Industry available
                opt-outs are listed above. Additional opt-outs may be available for mobile devices. Please note that the
                use of an opt-out may be limited to only certain types of tracking technologies.
            </StyledText>
            <br />
            <StyledText type='badgeLarge'>Analytics services provided by others</StyledText>
            <br />
            <StyledText type='descriptor1'>
                We may allow others to provide analytics services on our behalf. These entities may use cookies, web
                beacons and other technologies to collect information about your use of the Services, including your IP
                address, web browser, pages viewed, time spent on pages and links clicked. This information may be used
                by Klickly to, among other things, analyze and track data, determine the popularity of certain content
                and better understand your online activity.
            </StyledText>
            <br />
            <StyledText type='badgeLarge'>Your choices</StyledText>
            <br />
            <StyledText as='div' type='descriptor1'>
                <ul>
                    <li>
                        Promotional Communications. You may opt out of receiving promotional emails from Klickly by
                        following the instructions in those emails. If you opt out, we may still send you transactional
                        or relationship messages, such as those about your account or our ongoing business relations.
                    </li>
                    <li>
                        Your California Privacy Rights. California law permits residents of California to request
                        certain details about how their information is shared with third parties for direct marketing
                        purposes. If you are a California resident and would like to make such a request, please contact
                        us at <a href='mailto:privacy@klickly.com'>privacy@klickly.com</a>.
                    </li>
                    <li>
                        Cookies. Most web browsers are set to accept cookies by default. If you prefer, you can usually
                        choose to set your browser to remove or reject browser cookies. Please note that if you choose
                        to remove or reject cookies, this could affect the availability and functionality of our
                        Services.
                    </li>
                    <li>
                        Delete Personal Information: Klickly provides all consumers the ability to delete their personal
                        information, subject to standard limitations of this ability described in applicable laws. To exercise
                        this right, please contact us utilizing the contact information below.
                    </li>
                    <li>
                        Sensitive Personal Information. Although Klickly does not knowingly track or use sensitive
                        personal information currently, Klickly provides all consumers the opportunity to limit the use/disclosure of
                        sensitive personal information as required by applicable law, if you complete the form at{' '}
                        <a href='https://klickly.com/docs/disclosure-sensitive-info'>
                            https://klickly.com/docs/disclosure-sensitive-info
                        </a>.
                        Sensitive personal information for the purposes of this opt-out is your social security number, financial
                        account information, your precise geolocation data, or your genetic data racial or ethnic origin,
                        religious beliefs, mental or physical health diagnosis, sexual orientation, or citizenship or immigration status.
                    </li>
                    <li>
                        Sale of Personal Information. We offer all consumers the ability to opt-out of the sale of personal information at the
                        following link:{' '}
                        <a href='https://www.klickly.com/docs/privacy/opt_out'>Opt Out of Sale of Information</a>.
                        Although Klickly requires you to opt-out of the sale of personal information on its website, or,
                        to the extent required by applicable law, the Global Privacy Control (GPC), other organizations
                        permit opt-out by choice at the Digital Advertising Alliance at{' '}
                        <a href='https://optout.privacyrights.info/?c=1'>https://optout.privacyrights.info/?c=1</a>, and
                        the IAB at{' '}
                        <a href='https://www.iabprivacy.com/optout.html'>https://www.iabprivacy.com/optout.html</a>.
                        Individuals may also opt out of aspects of the usage of their personal information at a site
                        such as the Network Advertising Initiative Opt-Out, at{' '}
                        <a href='https://thenai.org/opt-out/'>https://thenai.org/opt-out/</a>.
                        You may also opt out of certain industry usage of hashed email addresses at{' '}
                        <a href='https://optout.networkadvertising.org/optout/email'>https://optout.networkadvertising.org/optout/email</a>.
                    </li>
                    <li>
                        Right to opt-out of certain Targeted Advertising. You may have the right to opt out of the performance
                        of certain targeted advertising, subject to the exceptions in any applicable law.
                    </li>
                    <li>
                        Right to opt-out of certain Profiling or Automated Decision-Making. In certain circumstances,
                        you may have the right to opt out of or object to such acts in furtherance of decisions that produce legal or other significant effects concerning you.
                    </li>
                </ul>
            </StyledText>
            <StyledText type='descriptor1' style={BOTTOM_INDENTS.S}>
                Do Not Track Signals. Except as required by applicable law, Klickly does not respond to “Do Not Track” (DNT)
                signals or other mechanisms.
            </StyledText>
            <StyledText type='descriptor1' style={BOTTOM_INDENTS.S}>
                Response Timing. We attempt to respond to a verifiable consumer request within the applicable time period of
                its receipt. If we require more time, we will inform you of the reason and extension period.
            </StyledText>
            <StyledText type='descriptor1' style={BOTTOM_INDENTS.S}>
                Appeals. For reconsideration of our response to your request under any applicable law that provides
                for an appeal, please request an appeal within the time frame for the appeal notice set forth in the
                applicable law by utilizing the contact information published below.
            </StyledText>
            <br />
            <StyledText type='badgeLarge'>California and Virginia</StyledText>
            <br />
            <StyledText type='descriptor1'>
                The California Consumer Privacy Act, as amended, the Virginia Consumer Data Protection Act and other state laws provide
                certain privacy rights to state residents concerning their personal information,
                including the right to access and delete personal information as well as opt-out of the sale of
                personal information.
            </StyledText>
            <br />
            <br />
            <StyledText type='badgeLarge'>Information We Collect</StyledText>
            <br />
            <StyledText type='descriptor1'>
                We collect information that identifies, relates to, describes, references, is reasonably capable of being associated
                with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (“personal information”).
                In particular, we have collected the following categories of personal information from consumers within the last twelve (12) months:
            </StyledText>
            <br />
            <StyledText as="div" type="descriptor1">
                <table>
                    <thead>
                    <tr>
                        <td>Category</td>
                        <td>Examples from the Law</td>
                        <td>Collected</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>A. Identifiers.</td>
                        <td>
                            A real name, alias, postal address, unique personal identifier, online identifier,
                            Internet Protocol address, email address, account name, Social Security number, driver's
                            license number, passport number, or other similar identifiers.
                        </td>
                        <td>YES</td>
                    </tr>

                    <tr>
                        <td>
                            B. Personal information categories listed in the California Customer Records statute
                            (Cal. Civ. Code § 1798.80(e)).
                        </td>
                        <td>
                            A name, signature, Social Security number, physical characteristics or description,
                            address, telephone number, passport number, driver's license or state identification
                            card number, insurance policy number, education, employment, employment history,
                            bank account number, credit card number, debit card number, or any other financial
                            information, medical information, or health insurance information.
                        </td>
                        <td>YES</td>
                    </tr>

                    <tr>
                        <td>C. Protected classification characteristics under California or federal law.</td>
                        <td>
                            Age (40+ years), race, color, ancestry, national origin, citizenship, religion or creed,
                            marital status, medical condition, physical or mental disability, sex, sexual orientation, veteran or military status, genetic information.
                        </td>
                        <td>YES (employees)</td>
                    </tr>

                    <tr>
                        <td>D. Commercial information.</td>
                        <td>
                            Records of personal property, products or services purchased, obtained, or considered,
                            or other purchasing or consuming histories or tendencies.
                        </td>
                        <td>YES</td>
                    </tr>

                    <tr>
                        <td>E. Biometric information.</td>
                        <td>
                            Genetic, physiological, behavioral, and biological characteristics, or activity patterns
                            used to extract a template or other identifier or identifying information, such as,
                            fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or
                            other physical patterns, and sleep, health, or exercise data.
                        </td>
                        <td>NO</td>
                    </tr>

                    <tr>
                        <td>F. Internet or other similar network activity.</td>
                        <td>
                            Browsing history, search history, information on a consumer's interaction with a
                            website, application, or advertisement.
                        </td>
                        <td>YES</td>
                    </tr>

                    <tr>
                        <td>G. Geolocation data.</td>
                        <td>Physical location or movements.</td>
                        <td>NO</td>
                    </tr>

                    <tr>
                        <td>H. Sensory data.</td>
                        <td>Audio, electronic, visual, thermal, olfactory, or similar information.</td>
                        <td>YES</td>
                    </tr>

                    <tr>
                        <td>I. Professional or employment-related information.</td>
                        <td>Current or past job history, etc.</td>
                        <td>YES (Employees)</td>
                    </tr>

                    <tr>
                        <td>
                            J. Non-public education information (per the Family Educational Rights and Privacy Act
                            (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).
                        </td>
                        <td>
                            Grades, transcripts, class lists, student schedules, student identification codes,
                            student financial information, or student disciplinary records.
                        </td>
                        <td>NO</td>
                    </tr>

                    <tr>
                        <td>K. Inferences drawn from other personal information.</td>
                        <td>
                            Profile reflecting preferences, characteristics, psychological trends, predispositions,
                            behavior, attitudes, intelligence, abilities, and aptitudes.
                        </td>
                        <td>YES</td>
                    </tr>
                    </tbody>
                </table>
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                We obtain the categories of personal information listed above from the following categories of sources:
            </StyledText>
            <br />
            <StyledText as="div" type="descriptor1">
                <ul>
                    <li>Directly or indirectly from you.</li>
                    <li>From activity on our website or social networking sites.</li>
                    <li>From companies where we act as service provider or third party.</li>
                    <li>From our service providers.</li>
                    <li>From other companies or organizations that have authorization to disclose such information to us.</li>
                </ul>
            </StyledText>
            <br />
            <StyledText as="div" type="descriptor1">
                We identify the purposes for which the information is being collected before or at the time of collection,
                and the retention of your personal information will be constituent with the fulfillment of the purposes we identify.
                We will keep your personal data only as long as required to serve those purposes and in no event longer than
                permitted by applicable law, provided that we will also retain and use your personal data for as long as necessary
                to comply with our legal obligations, resolve disputes, and operate under or enforce our agreements.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                Use of Personal Information
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                We may use or disclose the personal information we collect for one or more of the following business purposes:
            </StyledText>
            <br />
            <StyledText as="div" type="descriptor1">
                <ul>
                    <li>To fulfill or meet the reason for which the information is provided.</li>
                    <li>To provide you with information, products or services on behalf of ourselves or one of our customers.</li>
                    <li>To provide you with emails concerning our products or services that may be of interest to you.</li>
                    <li>To improve our website and present its contents to you.</li>
                    <li>For testing, research, analysis and product development.</li>
                    <li>In connection with our employment of you.</li>
                    <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us.</li>
                    <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
                    <li>As described to you when collecting your personal information.</li>
                    <li>As otherwise set forth in the CCPA or permitted by law.</li>
                    <li>
                        To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale
                        or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation,
                        or similar proceeding, in which personal information held by us is among the assets transferred.
                    </li>
                </ul>
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                We will not collect additional categories of personal information or use the personal information we
                collected for materially different, unrelated, or incompatible purposes without providing you notice
                by posting for thirty (30) days on our Site.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                Sales or Disclosure of Personal Information
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                We may disclose your personal information to a third party for various purposes.
                In the preceding twelve (12) months, we have disclosed the following categories of
                personal information for a business and commercial purpose:
            </StyledText>
            <br />
            <StyledText as="div" type="descriptor1" $noTableBorders>
                <table>
                    <tbody>
                    <tr>
                        <td>
                            <p>Category A:</p>
                        </td>
                        <td>Identifiers.</td>
                    </tr>

                    <tr>
                        <td>
                            <p>Category B:</p>
                        </td>
                        <td>California Customer Records personal information categories.</td>
                    </tr>

                    <tr>
                        <td>
                            <p>Category D:</p>
                        </td>
                        <td>Commercial information.</td>
                    </tr>

                    <tr>
                        <td>
                            <p>Category F:</p>
                        </td>
                        <td>Internet or other similar network activity.</td>
                    </tr>

                    <tr>
                        <td>
                            <p>Category H:</p>
                        </td>
                        <td>Sensory data.</td>
                    </tr>

                    <tr>
                        <td>
                            <p>Category K:</p>
                        </td>
                        <td>Inferences drawn from other personal information.</td>
                    </tr>
                    </tbody>
                </table>
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                We disclose your personal information for a business purpose to the following categories of third parties:
            </StyledText>
            <br />
            <StyledText as="div" type="descriptor1">
                <ul>
                    <li>Our affiliates.</li>
                    <li>Service providers.</li>
                    <li>
                        Third parties to whom you or your agents authorize us to disclose your personal information in
                        connection with products or services we provide to you.
                    </li>
                    <li>Third parties with whom you have an existing relationship.</li>
                    <li>Other third parties as permitted by applicable laws.</li>
                </ul>
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                In the preceding twelve (12) months, we have engaged in, entered into or contemplated commercial
                relationships which do or may constitute a “sale” or “share” of personal information under applicable laws.
            </StyledText>
            <br/>
            <StyledText as="div" type="descriptor1">
                <ul>
                    <li>Advertising networks</li>
                    <li>Ecommerce companies</li>
                    <li>Data Brokers</li>
                    <li>Third parties with whom you have an existing relationship or other connection.</li>
                    <li>Other third parties as permitted by applicable laws.</li>
                </ul>
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                We do not have actual knowledge that we sell or share the personal information of consumers under 16 years of age.
            </StyledText>
            <StyledText type="descriptor1">
                To exercise your rights to additional disclosures, to access, correct and delete personal information,
                opt-out or otherwise restrict processing under these laws, please see the section entitled
                Your Rights above and the additional information below.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                Access and Data Portability Rights
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                You have the right to request that we disclose certain information to you about our processing, collection and
                use of your personal information. Once we receive and confirm your verifiable consumer request, we
                will disclose to you:
            </StyledText>
            <br />
            <StyledText as="div" type="descriptor1">
                <ul>
                    <li>The categories of personal information we have collected about you.</li>
                    <li>The categories of sources for the personal information we collected about you.</li>
                    <li>Our business or commercial purpose for collecting or selling that personal information.</li>
                    <li>The categories of third parties with whom we share that personal information.</li>
                    <li>The specific pieces of personal information we collected about you, in a portable format.</li>
                </ul>
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                To request disclosure of your personal information, please email{' '}
                <a href='mailto:privacy@klickly.com'>privacy@klickly.com</a>{' '}
                with the following information:
                your first name, last name, email, street address, city, state, postal code, and country. If we have acquired your
                personal information as a service provider, we will direct you to submit your request through our customer where possible.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">Deletion Requests</StyledText>
            <br />
            <StyledText type="descriptor1">
                You have the right to request that we delete any of your personal information that we collected from you and retained,
                subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete
                (and direct our service providers to delete) your personal information from our records, unless an exception applies.
                We may deny your deletion request if retaining the information is necessary for us or our service providers to
                retain under an exception provided for by the state laws.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                Correction Requests
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                You have the right to request that we correct any inaccurate personal information that we have
                collected from you and retained, subject to certain exceptions.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                Do Not Sell/Share My Personal Information
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                You have the right to opt-out of the sale of your personal information under certain state laws and
                the sharing of personal information for cross-contextual behavioral advertising under the CCPA.
                Please visit our {' '}<a href='/help/privacy-opt-out'>Do Not Sell My Personal Information Opt-Out </a>{' '}
                to exercise your right under such state laws.
                The right to opt-out is subject to certain exemptions and exceptions. For example, it does not apply to
                personal information that we share with service providers that perform certain business functions for
                us pursuant to a written contract.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                Exercising Your Rights
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                To exercise the access and deletion rights described above, please submit a verifiable
                consumer request to <a href='mailto:privacy@klickly.com'>privacy@klickly.com</a>. <br />
                Only you or a person registered with an appropriate third party that you authorize to act
                on your behalf, may make a verifiable consumer request related to your personal information. <br />
                You may only make a verifiable consumer request for access twice within a 12-month period.
                If we have acquired your personal information as a service provider, we will direct you to
                submit your request through our customer where possible. <br />
                The verifiable consumer request must provide sufficient information that allows us to reasonably
                verify you are the person about whom we collected personal information or an authorized representative.
                It should include your first name, last name, email, street address, city, state, postal code, and country.
                We cannot provide you with personal information if we cannot verify your identity and confirm
                the personal information relates to you.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                State privacy laws are regularly changing. If we are required to disclose information under an applicable
                state law that you do not see disclosed in this Privacy Policy, please contact us utilizing the contact
                information below and we will proved the information required to you. If a disability prevents you from
                accessing and/or understanding this Privacy Policy, please contact us utilizing the contact information
                below and we will assist.
            </StyledText>
            <br />
            <StyledText type='badgeLarge'>International Transfer of your Personal Information</StyledText>
            <br />
            <StyledText type='descriptor1'>
                The Site and Services are hosted and operated entirely in the United States and are subject to United
                States law. Any personal information that we collect from you is currently stored and processed in the
                United States. If you are accessing the Site or Services outside of the U.S., you consent to the
                transfer of your personal information to the United States. Please be advised that United States law may
                not offer the same privacy protections as the law in your jurisdiction.
            </StyledText>
            <br />
            <StyledText type='badgeLarge'>Children’s Privacy</StyledText>
            <br />
            <StyledText type='descriptor1'>
                We do not knowingly collect any information from anyone who we know to be under the age of 13. If you
                are under the age of 18, you should use this Site or Services only with the involvement of a parent or
                guardian and should not submit any personal information to us. If we discover that a person under the
                age of 13 has provided us with any personal information, we will use commercially reasonable efforts to
                delete such person's personal information from all Klickly systems.
            </StyledText>
            <br />
            <StyledText type='badgeLarge'>Changes to this privacy policy</StyledText>
            <br />
            <StyledText type='descriptor1'>
                We may update and amend this privacy policy at any time. When we do, we will revise the updated date at
                the top of this page, and, in some cases, we may provide additional notice (such as by sending an email
                or providing notice on the homepage of our Site). We encourage Users to frequently check this policy to
                stay informed about our information practices and the ways you can help protect your privacy. If we are
                going to use personal information collected through the Site or Services in a manner materially
                different from that stated at the time of collection, Klickly will notify users by posting a notice on
                the Site for thirty (30) days prior to such use. This Privacy Policy may not be otherwise amended except
                in a writing that specifically refers to this Privacy Policy and is physically signed by both parties.
            </StyledText>
            <br />
            <StyledText type='badgeLarge'>Successors and Assigns</StyledText>
            <br />
            <StyledText type='descriptor1'>
                This Privacy Policy inures to the benefit of successors and assigns of Klickly.
            </StyledText>
            <br />
            <StyledText type='badgeLarge'>Contact us</StyledText>
            <br />
            <StyledText type='descriptor1'>
                If you have any questions about this Privacy Policy, please contact us at:
            </StyledText>
            <br />
            <StyledText type='descriptor1'>
                <strong>Klickly, Inc. </strong>
                <br />
                2219 Main Street, #166
                <br />
                Santa Monica, CA 90405
                <br />
                <a href='mailto:privacy@klickly.com'>privacy@klickly.com</a>
            </StyledText>
            <br />
        </LegalContent>
    );
};

export default PrivacyPolicyPage;
