import React from 'react';
import { BOTTOM_INDENTS, KliInfinityScrollGallery, Typography } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { READY_TO_LUNCH_PRODUCTS_V1 } from '../../../../../../../../shared';
import { StyledBackground, StyledGalleryWrapper, StyledItemWrapper } from './styled';

const SuiteGalleryVariant1 = observer(() => {
    return (
        <StyledGalleryWrapper>
            <KliInfinityScrollGallery height='376px' speed={80} stopOnHover>
                {READY_TO_LUNCH_PRODUCTS_V1.map((item) => (
                    <StyledItemWrapper key={item.title}>
                        <div>
                            <img src={item.imageSRC} alt={item.title} />
                            <Typography.Title align='center' color='primary' level={3} style={BOTTOM_INDENTS.XXXS}>
                                {Math.floor(Math.random() * 31) + 70}% off
                            </Typography.Title>
                            <Typography.Title align='center' level={3} style={BOTTOM_INDENTS.XXXS}>
                                {item.title}
                            </Typography.Title>
                            <Typography.Text align='center' type='body2'>
                                {item.brandName}
                            </Typography.Text>
                        </div>
                        <StyledBackground />
                    </StyledItemWrapper>
                ))}
            </KliInfinityScrollGallery>
        </StyledGalleryWrapper>
    );
});

export default SuiteGalleryVariant1;
