import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { notification, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { Animated } from '../../../../shared/components/Animated';
import NotificationContent from '../../../../shared/components/NotificationContent';
import { PageHeader } from '../../../../shared/components/PageHeader';
import { GRID_POINTS } from '../../../../utils';
import { LeaveFeedback } from './components/LeaveFeedback';

const FeedbackPage = inject('store')(
    observer(({ store }) => {
        const { state } = useLocation();
        const { rateGiftlyTask } = store.tasksStore;
        const { isApprovedCustomer } = store.accountStore;
        const simple = useSimpleInterface(GRID_POINTS.sm);
        const message = 'Fill out the form and click on Send feedback';
        const alias = rateGiftlyTask?.alias;
        const showFirstNotification =
            state === alias &&
            rateGiftlyTask?.data &&
            rateGiftlyTask?.data?.status !== 'completed' &&
            isApprovedCustomer;

        useEffect(() => {
            if (showFirstNotification) {
                store.trackingStore.trackChallengeNotification(rateGiftlyTask, '1', message);
                notification.open({
                    key: alias,
                    duration: null,
                    placement: 'bottomRight',
                    description: <NotificationContent.Reward rewardCount={50} rewardType='points' message={message} />,
                    bottom: simple ? '112px' : '8px'
                });
            }
            return () => (typeof notification.destroy === 'function' ? notification.destroy() : null);
        }, []); //eslint-disable-line

        return (
            <Animated style={{ maxWidth: '845px', margin: 'auto' }}>
                <PageHeader title='Send feedback' subTitle='Account' />
                <LeaveFeedback />
            </Animated>
        );
    })
);

export default FeedbackPage;
