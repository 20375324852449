import React, { Fragment } from 'react';
import { CouponDescription, CouponDetails, CouponImgWrap, CouponTitle } from './styles';

export const KliExpiredCouponContent = ({
    img,
    title,
    description,
    showFullDescription,
    additionalDetailsContent = null
}) => {
    return (
        <Fragment>
            {img ? <CouponImgWrap>{img}</CouponImgWrap> : null}
            <CouponDetails>
                <CouponTitle level={3}>{title}</CouponTitle>
                <CouponDescription $showFullDescription={showFullDescription} type='body2'>
                    {description}
                </CouponDescription>
                {additionalDetailsContent}
            </CouponDetails>
        </Fragment>
    );
};
