import React, { Fragment } from 'react';
import { CancelOrderConfirmation } from '../CancelOrderConfirmation';
import { CancelOrderForm } from '../CancelOrderForm';
import { Header } from '../Header';
import { CancelOrderContent, CancelOrderStyles } from './styles';

export const CancelOrder = ({
    orderId,
    renderReason,
    pending,
    errorMessage,
    goToCancellationReason,
    cancelOrder,
    onClose,
    simple
}) => {
    const onCancelOrder = (orderPayload) => {
        cancelOrder(orderPayload);
    };

    return (
        <CancelOrderStyles>
            <Fragment>
                <Header title='Cancel order' simple={simple} onClose={onClose} />
                <CancelOrderContent>
                    {!renderReason ? (
                        <CancelOrderConfirmation goToCancellationReason={goToCancellationReason} onClose={onClose} />
                    ) : (
                        <CancelOrderForm
                            orderId={orderId}
                            onSubmit={onCancelOrder}
                            pending={pending}
                            errorMessage={errorMessage}
                            onClose={onClose}
                        />
                    )}
                </CancelOrderContent>
            </Fragment>
        </CancelOrderStyles>
    );
};
