import { types } from 'mobx-state-tree';

export const AvailableRewardsModel = types.model({
    externalId: types.number,
    externalProductId: types.number,
    title: types.string,
    price: types.string,
    sku: types.string,
    position: types.number,
    grams: types.number,
    inventoryPolicy: types.string,
    originalPrice: types.maybe(types.string),
    fulfillmentService: types.string,
    option1: types.string,
    taxable: types.boolean,
    barcode: types.maybe(types.string),
    quantity: types.number,
    weight: types.number,
    weightUnit: types.maybe(types.string),
    requiresShipping: types.boolean,
    oldInventoryQuantity: types.number,
    inventoryManagement: types.string,
    inventoryItemId: types.number,
    rewards: types.array(
        types.model({
            _id: types.maybe(types.maybeNull(types.string)),
            title: types.maybe(types.maybeNull(types.string)),
            description: types.maybe(types.maybeNull(types.string)),
            image: types.maybe(types.maybeNull(types.string)),
            expireAt: types.maybe(types.maybeNull(types.string)),
            appliedTo: types.maybe(types.maybeNull(types.string)),
            amount: types.number
        })
    ),
    strictQuantity: types.maybe(types.number),
    priceDetails: types.model({
        originalPrice: types.maybeNull(types.number),
        rewardsPrice: types.maybeNull(types.number),
        rewardsPercent: types.maybeNull(types.number),
        youPayPrice: types.maybeNull(types.number)
    })
});
