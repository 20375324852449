import React, { Fragment, useState } from 'react';
import { CloseXIcon, HamburgerMenuIcon, notification } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { SUITE_STATES } from '../../../../../../constants';
import Menu from '../Menu';
import GsOpenedBadge from './components/GsOpenedBadge';
import MenuHeader from './components/MenuHeader';
import { StyledBurgerButton, StyledDrawer } from './styled';

const BurgerMenu = inject('store')(
    observer(({ store }) => {
        const [showMenu, setShowMenu] = useState(false);
        const { suiteState } = store.suiteStore;
        const { isLoggedIn } = store.accountStore;
        return (
            <Fragment>
                <StyledBurgerButton
                    type='unstyled'
                    icon={showMenu ? <CloseXIcon /> : <HamburgerMenuIcon />}
                    onClick={() => {
                        if (typeof notification.destroy === 'function') notification.destroy();
                        return setShowMenu(!showMenu);
                    }}
                />
                <StyledDrawer
                    placement='left'
                    getContainer={() => document.querySelector('#app-wrapper')}
                    open={showMenu}
                    closable={false}
                    width='100%'
                >
                    {isLoggedIn ? <MenuHeader /> : null}
                    {suiteState === SUITE_STATES.opened ? <GsOpenedBadge /> : null}
                    <Menu mode='vertical' isMenuOpen={showMenu} onMenuClose={() => setShowMenu(false)} />
                </StyledDrawer>
            </Fragment>
        );
    })
);

export default BurgerMenu;
