export const STRIPE_APPEARANCE = {
    theme: 'stripe',
    variables: {
        colorBackground: '#F4F4F4',
        colorText: '#000000',
        colorTextPlaceholder: '#424770',
        colorDanger: '#da1781',
        fontFamily: 'IBM Plex Sans, Helvetica, sans-serif',
        fontSizeBase: '14px',
        borderRadius: '0px'
    },
    rules: {
        '.Input': {
            border: '1px solid transparent',
            borderWidth: '1px',
            boxShadow: 'none',
            outline: 'none',
            lineHeight: '40px',
            fontSize: '16px'
        },

        '.Input--invalid': {
            boxShadow: 'none'
        },
        '.Input--empty:focus': {
            boxShadow: 'none',
            outline: 'none',
            border: '1px solid #c6c6c6'
        },
        '.Input:focus': {
            boxShadow: 'none',
            outline: 'none',
            border: '1px solid #c6c6c6'
        },
        '.Label': {
            lineHeight: '24px',
            fontSize: '14px'
        }
    }
};
