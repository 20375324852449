import React from 'react';
import { ROUTE_PATHS } from '../../../../../shared';
import CouponDetails from '../components/CouponDetails';
import SuiteByState from '../components/SuiteByState';
import InfoPage from '../components/SuiteInfo';
import SharingRulesPage from '../components/SuiteSharingRules';

export const ROUTES = [
    {
        index: true,
        element: <SuiteByState />
    },
    {
        path: ROUTE_PATHS.suiteGsCouponDetails,
        element: <CouponDetails />
    },
    {
        path: ROUTE_PATHS.suiteGsInfo,
        element: <InfoPage />
    },
    {
        path: ROUTE_PATHS.suiteGsSharingRules,
        element: <SharingRulesPage />
    }
];
