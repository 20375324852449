import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../utils';

const getLeftMargin = (activeItemPosition, $isNoActiveChallenge) => {
    if ($isNoActiveChallenge) return '-124%';

    switch (activeItemPosition) {
        case 2:
            return '2%';
        case 3:
            return '-40%';
        case 4:
            return '-82%';
        case 5:
            return '-124%';
        default:
            return '50% - 28px';
    }
};

export const StyledStepsWrapper = styled.div`
    @media (${GRID_DEVICES_MAX.md}) {
        width: calc(100% + 40px);
        margin-left: -20px;
        overflow: hidden;

        ${({ $itemsCount, $activeItemPosition, $isNoActiveChallenge }) => css`
            > .ant-row {
                width: ${(100 / 2.75) * $itemsCount}%;
                margin-left: calc(${getLeftMargin($activeItemPosition, $isNoActiveChallenge)});
            }
        `};
    }
`;
