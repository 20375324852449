import React from 'react';
import { Col, Form, Input, MailIcon, Row } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { VALIDATION_RULES } from '../../../../../../../../../../../../../../shared';
import { StyledButton, StyledIcon } from './styles';

export const InviteFriendTaskForm = inject('store')(
    observer(({ store, setSubmitted }) => {
        const {
            inviteFriendTaskData: { attemptsRemained },
            taskErrorMessage: errorMessage,
            clearTaskError,
            sendInvite
        } = store.tasksStore;

        const [form] = Form.useForm();

        const submitted = (data) => {
            sendInvite(data, () => setSubmitted(true));
        };

        return (
            <Form
                id='invite-friend-form'
                form={form}
                onFinish={submitted}
                validateTrigger={['onBlur']}
                onFieldsChange={(changedFields) => {
                    if (changedFields.some((field) => field.name[0] === 'inviteeEmail')) {
                        clearTaskError();
                    }
                }}
            >
                <Row gutter={20}>
                    <Col span={24}>
                        <Form.Item
                            name='inviteeEmail'
                            rules={VALIDATION_RULES.EMAIL}
                            validateStatus={errorMessage && 'error'}
                            hasFeedback
                            help={errorMessage}
                        >
                            <Input
                                size='large'
                                type='email'
                                allowClear={Boolean(errorMessage)}
                                label='E-mail'
                                autoComplete='on'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={24}>
                        <Form.Item name='inviteeFirstName' rules={VALIDATION_RULES.REQUIRED}>
                            <Input size='large' label='First name' autoComplete='off' showWarningIcon />
                        </Form.Item>
                    </Col>
                </Row>
                {attemptsRemained ? (
                    <Form.Item noStyle shouldUpdate>
                        {() => (
                            <StyledButton
                                htmlType='submit'
                                form='invite-friend-form'
                                type='primary'
                                size='large'
                                disabled={
                                    !form.isFieldsTouched(true) ||
                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                }
                            >
                                <StyledIcon stylePriority size='20px' component={() => <MailIcon />} />
                                Send invite
                            </StyledButton>
                        )}
                    </Form.Item>
                ) : null}
            </Form>
        );
    })
);
