import React, { Fragment } from 'react';
import { BOTTOM_INDENTS, GiftIcon, PointsIcon, TOP_INDENTS, Typography } from '@klickly/front-packages';
import { $primary, $primaryText, $secondaryText, $white, ROUTE_PATHS } from '../../../shared';
import { StyledText } from '../../../shared/components/CouponBanner/styled';
import { seasonalChallengeName } from '../../../shared/utils/seasonalChallengeName';
import RandomChallengeContent from '../components/RandomChallengeContent';
import SuiteOpenTitle from '../components/SuiteOpenTitle';

export const getThankYouPageContent = (
    navigate,
    orderNumber,
    pointsString,
    pointsNumber,
    mobile,
    profileEmail,
    onClickStrategy
) => {
    const season = seasonalChallengeName();

    return {
        unauthorized: {
            rightPart: {
                getTitle: () => (
                    <Typography.Title
                        style={mobile ? TOP_INDENTS.L : TOP_INDENTS.XXL}
                        color={mobile ? $primary : $white}
                        align='center'
                        level={mobile ? 2 : 1}
                    >
                        <StyledText $color={mobile ? $secondaryText : $white}>New members get</StyledText>
                        <br />
                        <StyledText $color={mobile ? $primary : $white}>a Gifting Suite worth up to $50!</StyledText>
                    </Typography.Title>
                ),
                description: 'Shop your favorite deals and watch your points grow with every order!',
                button: {
                    text: 'Apply',
                    onClick: () => navigate(ROUTE_PATHS.personalInfo)
                },
                logo: {
                    component: () => <GiftIcon />,
                    mobile: true
                }
            },
            leftPart: {
                getTitle: () => (
                    <Typography.Title align='center' style={BOTTOM_INDENTS.SM} level={1} color={$primaryText}>
                        Your order {orderNumber} has been placed
                    </Typography.Title>
                )
            }
        },
        waitListed: {
            rightPart: {
                getTitle: () => (
                    <Typography.Title
                        style={mobile ? TOP_INDENTS.L : TOP_INDENTS.XXL}
                        color={$primary}
                        align='center'
                        level={mobile ? 2 : 1}
                    >
                        <StyledText $topIndent={mobile ? '0' : '140px'} $color={mobile ? $primary : $white}>
                            You’re on the waitlist
                        </StyledText>
                    </Typography.Title>
                ),
                description: (
                    <Fragment>
                        <Fragment>
                            As soon as a space opens <br />
                        </Fragment>
                        <Fragment>
                            we’ll e-mail you at: {mobile ? <br /> : profileEmail}
                            {mobile ? <StyledText $color={$primary}>{profileEmail}</StyledText> : null}
                        </Fragment>
                    </Fragment>
                )
            },
            leftPart: {
                getTitle: () => (
                    <Typography.Title align='center' style={BOTTOM_INDENTS.SM} level={1} color={$primaryText}>
                        Your order {orderNumber} has been placed
                    </Typography.Title>
                )
            }
        },
        firstGsClaim: {
            rightPart: {
                getTitle: () => (
                    <Typography.Title
                        style={mobile ? TOP_INDENTS.L : TOP_INDENTS.XXL}
                        color={mobile ? $primary : $white}
                        align='center'
                        level={mobile ? 2 : 1}
                    >
                        Continue your {season} Challenge
                    </Typography.Title>
                ),
                description: 'Complete 5 personalized challenges to earn 5 exclusive rewards',
                button: {
                    text: 'Go to Challenge',
                    onClick: () => navigate(ROUTE_PATHS.seasonalChallenge)
                },
                logo: {
                    component: () => <GiftIcon />,
                    mobile: true
                }
            },
            leftPart: {
                getTitle: () => (
                    <Typography.Title align='center' style={BOTTOM_INDENTS.SM} level={1} color={$primary}>
                        Congrats on your first claim!
                    </Typography.Title>
                )
            }
        },
        regularPurchaseStartedGs: {
            rightPart: {
                getTitle: () => <SuiteOpenTitle />,
                button: {
                    text: 'Go to Gifting Suite',
                    onClick: () => navigate(ROUTE_PATHS.suite)
                },
                logo: {
                    component: () => <GiftIcon />,
                    mobile: false
                }
            },
            leftPart: {
                getTitle: () => (
                    <Typography.Title align='center' style={BOTTOM_INDENTS.SM} level={1} color={$primaryText}>
                        Your order {orderNumber} has been placed
                    </Typography.Title>
                )
            }
        },
        regularPurchaseByPoints: {
            rightPart: {
                getTitle: () => (
                    <Typography.Title
                        style={mobile ? TOP_INDENTS.L : TOP_INDENTS.XXL}
                        color={mobile ? $primary : $white}
                        align='center'
                        level={mobile ? 2 : 1}
                    >
                        You’ve got {pointsString} <br />
                        points worth ${pointsNumber / 100} to spend
                    </Typography.Title>
                ),
                description: 'Shop your favorite deals and watch your points grow with every order!',
                button: {
                    text: 'Explore Deals',
                    onClick: () => onClickStrategy()
                },
                info: {
                    content: 'What are Points?',
                    onClick: () => navigate(ROUTE_PATHS.pointsInfo)
                },
                logo: {
                    component: () => <PointsIcon />,
                    mobile: true
                }
            },
            leftPart: {
                getTitle: () => (
                    <Typography.Title align='center' style={BOTTOM_INDENTS.SM} level={1} color={$primaryText}>
                        Your order {orderNumber} has been placed
                    </Typography.Title>
                )
            }
        },
        regularPurchaseMultipleOrders: {
            rightPart: {
                getTitle: () => (
                    <Typography.Title
                        style={mobile ? TOP_INDENTS.L : TOP_INDENTS.XXL}
                        color={mobile ? $primary : $white}
                        align='center'
                        level={mobile ? 2 : 1}
                    >
                        You’ve got {pointsString} <br />
                        points worth ${pointsNumber / 100} to spend
                    </Typography.Title>
                ),
                description: 'Shop your favorite deals and watch your points grow with every order!',
                button: {
                    text: 'Explore Deals',
                    onClick: () => onClickStrategy()
                },
                info: {
                    content: 'What are Points?',
                    onClick: () => navigate(ROUTE_PATHS.pointsInfo)
                },
                logo: {
                    component: () => <PointsIcon />,
                    mobile: true
                }
            },
            leftPart: {
                getTitle: () => (
                    <Typography.Title align='center' style={BOTTOM_INDENTS.SM} level={1} color={$primaryText}>
                        Your orders {orderNumber} have been placed
                    </Typography.Title>
                )
            }
        },
        regularPurchaseChallenge: {
            rightPart: {
                getTitle: () => <RandomChallengeContent mobile={mobile} />,
                button: {
                    text: 'Go to Challenge',
                    onClick: () => navigate(ROUTE_PATHS.challenges)
                }
            },
            leftPart: {
                getTitle: () => (
                    <Typography.Title align='center' style={BOTTOM_INDENTS.SM} level={1} color={$primaryText}>
                        Your order {orderNumber} has been placed
                    </Typography.Title>
                )
            }
        },
        regularPurchaseGsClaimExist: {
            rightPart: {
                getTitle: () => (
                    <Typography.Title style={mobile ? TOP_INDENTS.L : TOP_INDENTS.XXL} align='center' level={1}>
                        <StyledText $color={mobile ? $primary : $white}>Your Gifting Suite</StyledText>
                        <br />
                        <StyledText $color={mobile ? $secondaryText : $white}>is ready to launch!</StyledText>
                    </Typography.Title>
                ),
                button: {
                    text: 'Launch',
                    onClick: () => navigate(ROUTE_PATHS.suite)
                },
                info: {
                    content: 'What is a Gifting Suite?',
                    onClick: () => navigate(ROUTE_PATHS.suiteGsInfo)
                },
                logo: {
                    component: () => <GiftIcon />,
                    mobile: false
                }
            },
            leftPart: {
                getTitle: () => (
                    <Typography.Title align='center' style={BOTTOM_INDENTS.SM} level={1} color={$primaryText}>
                        Your order {orderNumber} has been placed
                    </Typography.Title>
                )
            }
        }
    };
};
