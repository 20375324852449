import { Image, Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $primary, $tartRed } from '../../../../shared';

export const StyledWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 80px;

    @media (max-width: 1130px) {
        flex-direction: column;

        & > div:last-child {
            max-width: 100%;
        }
    }
`;

export const StyledImgBlock = styled.div`
    position: relative;
    margin-right: 20px;

    @media (max-width: 1130px) {
        margin: 0 0 24px 0;
    }
`;

export const StyledImage = styled(Image)`
    object-fit: contain;
`;

export const StyledDetailsBlock = styled.div`
    max-width: calc(100% - 320px);
    width: 100%;
`;

export const StyledHead = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 16px;
`;

export const StyledPricesBlock = styled.div`
    width: 64%;
    padding: 8px 16px;
`;

export const StyledPrices = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledOriginalPrice = styled(Typography.Title)`
    && {
        margin: 0 8px 0 0 !important;
        text-decoration: line-through;
    }
`;

export const StyledDealPrice = styled(Typography.Title)`
    && {
        margin: 0 !important;
    }
`;

export const StyledBadge = styled.div`
    width: 64px;
    height: 64px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: ${$primary};
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledPointsBlock = styled.div`
    width: 36%;
    padding: 8px 16px;
    background-color: ${$tartRed};
`;

export const StyledPoints = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledLoaderWrap = styled.div`
    position: relative;
    width: 100%;
    height: 400px;
`;
