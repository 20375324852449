import React, { Fragment } from 'react';
import { Icon, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import PromotionsSlider from '../PromotionsSlider';
import { StyledWrapper } from './styled';

const FavoritesPromotions = observer(({ promotions, pending }) => {
    const simple = useSimpleInterface(889);

    return pending ? (
        <Icon.Loader zIndex={4} />
    ) : (
        <Fragment>
            {promotions.length > 0 ? (
                <StyledWrapper data-e2e='favorites-promotions-block'>
                    <PromotionsSlider
                        promotions={promotions}
                        title='Special offers'
                        isRecommendation
                        showButtonsOnTop
                        promotionsHeight={simple ? '315px' : '370px'}
                        promotionsWithoutButton
                    />
                </StyledWrapper>
            ) : null}
        </Fragment>
    );
});

export default FavoritesPromotions;
