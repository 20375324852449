import React from 'react';
import nextId from 'react-id-generator';
import {
    ActionsMinusIcon,
    ActionsPlusIcon,
    Collapse,
    GRID_POINTS,
    Icon,
    Panel,
    TOP_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { faq } from './faq';
import { FaqContentStyles, FaqStyles, StyledText } from './styles';

export const FaqSection = observer(({ sectionRef }) => {
    const simple = useSimpleInterface(GRID_POINTS.md);

    return (
        <FaqStyles ref={sectionRef}>
            {!simple ? (
                <Typography.Text type='em0' align='center'>
                    Frequently Asked Questions
                </Typography.Text>
            ) : (
                <Typography.Title align='center'>Frequently Asked Questions</Typography.Title>
            )}
            <FaqContentStyles style={TOP_INDENTS.SM}>
                <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => (
                        <Icon size='18px' component={() => (isActive ? <ActionsMinusIcon /> : <ActionsPlusIcon />)} />
                    )}
                    expandIconPosition='end'
                >
                    {faq.map((item) => {
                        return (
                            <Panel
                                header={
                                    simple ? (
                                        <Typography.Title level={3} as='span'>
                                            {item.headerText}
                                        </Typography.Title>
                                    ) : (
                                        <Typography.Title level={2} as='span'>
                                            {item.headerText}
                                        </Typography.Title>
                                    )
                                }
                                key={nextId()}
                            >
                                <StyledText type={simple ? 'body2' : 'body1'}>{item.bodyText}</StyledText>
                            </Panel>
                        );
                    })}
                </Collapse>
            </FaqContentStyles>
        </FaqStyles>
    );
});
