import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { $primaryText } from '../../../../shared';

export const Styles = styled(Link)`
    padding: 0;
    font-size: ${({ size }) => size || '32px'};
    width: fit-content;
    height: ${({ size }) => size || '32px'};
    color: ${({ color }) => color || $primaryText};
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
`;
