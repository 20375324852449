import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { Styles } from './styles';

const ShareProductLink = inject('store')(
    observer(({ store }) => {
        const location = useLocation();
        const { shareProductLink, error, setError } = store.shareProductStore;

        useEffect(() => {
            const pathName = location.pathname;
            const base64Param = pathName.split('/').pop();

            if (!base64Param) {
                setError('Invalid URL, missing shared link');
                return false;
            }

            const [referrer, promotion] = window.atob(base64Param).split(':');
            shareProductLink(referrer, promotion);
        }, [location, setError]);

        return <Styles>{error ? <Typography.Text color='error'>{error}</Typography.Text> : <Icon.Loader />}</Styles>;
    })
);

export default ShareProductLink;
