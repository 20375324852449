import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { useApplyClick } from '../../../../hooks';
import { ROUTE_PATHS } from '../../../../shared';
import {
    Entrepreneur,
    Forbes,
    Huffpost,
    Inc,
    IntroStyledContent,
    LandingMainDesktopImg,
    LandingMainMobileLargeImg,
    LandingMainMobileSmallImg1,
    LandingMainMobileSmallImg2,
    LandingMainMobileSmallImg3,
    Mashable,
    StyledApplyButton,
    StyledButtonWrap,
    StyledCompaniesWrap,
    StyledDiscoverButton,
    StyledIntroWrap,
    StyledMainMobileImgRow,
    StyledMainMobileImgWrap
} from './styles';

export const IntroSection = observer(() => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const simple = useSimpleInterface(550);
    const onApplyClick = useApplyClick();

    const onDiscoverClick = () => {
        navigate({
            pathname: ROUTE_PATHS.shop,
            search: search
        });
    };

    return (
        <StyledIntroWrap>
            {!simple ? (
                <LandingMainDesktopImg />
            ) : (
                <StyledMainMobileImgWrap>
                    <StyledMainMobileImgRow>
                        <LandingMainMobileSmallImg1 />
                        <LandingMainMobileSmallImg2 />
                        <LandingMainMobileSmallImg3 />
                    </StyledMainMobileImgRow>
                    <StyledMainMobileImgRow>
                        <LandingMainMobileLargeImg />
                    </StyledMainMobileImgRow>
                </StyledMainMobileImgWrap>
            )}
            <IntroStyledContent $simple={simple}>
                {!simple ? (
                    <Typography.Title align='center' style={{ marginBottom: '32px' }}>
                        The Invite-Only App That Gives Free Gifts & Private Discounts
                    </Typography.Title>
                ) : (
                    <Typography.Title level={2} align='center' style={{ marginBottom: '16px' }}>
                        The Invite-Only App That Gives Free Gifts & Private Discounts
                    </Typography.Title>
                )}
                <StyledButtonWrap $simple={simple}>
                    <StyledApplyButton
                        data-e2e='landing_applyGiftly-button'
                        $simple={simple}
                        type='primary'
                        size='large'
                        ghost
                        onClick={onApplyClick}
                    >
                        Apply
                    </StyledApplyButton>
                    <StyledDiscoverButton
                        data-e2e='landing_discoverGiftly-button'
                        $simple={simple}
                        type='primary'
                        size='large'
                        onClick={onDiscoverClick}
                    >
                        Discover
                    </StyledDiscoverButton>
                </StyledButtonWrap>
                <StyledCompaniesWrap $simple={simple}>
                    <Entrepreneur style={{ height: simple ? '12px' : '16px' }} />
                    <Forbes />
                    <Huffpost />
                    <Inc />
                    <Mashable />
                </StyledCompaniesWrap>
            </IntroStyledContent>
        </StyledIntroWrap>
    );
});
