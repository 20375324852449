import React, { useEffect } from 'react';
import { Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { StripeAddCard } from './components/StripeAddCard';
import { StyledWrapper } from './styles';

export const AddBilling = inject('store')(
    observer(({ store }) => {
        const {
            getStripeClientSecret,
            stripeClientSecret,
            addBillingProfile,
            pendingBilling,
            setBillingError,
            billingErrorMessage,
            defaultShippingAddress,
            setBillingPending
        } = store.accountStore;

        useEffect(() => {
            getStripeClientSecret();
        }, []);

        const onSubmit = (data) => {
            if (data?.payment_method) {
                addBillingProfile(data.payment_method);
            }
        };

        const onError = (error) => {
            setBillingError(error);
        };

        return (
            <StyledWrapper>
                {stripeClientSecret ? (
                    <StripeAddCard
                        defaultShippingAddress={defaultShippingAddress}
                        stripeClientSecret={stripeClientSecret}
                        onSubmit={onSubmit}
                        onError={onError}
                        error={billingErrorMessage}
                        setBillingPending={setBillingPending}
                    />
                ) : (
                    <Icon.Loader />
                )}
                {pendingBilling ? <Icon.Loader /> : null}
            </StyledWrapper>
        );
    })
);
