import React, { Fragment } from 'react';
import nextId from 'react-id-generator';
import { Typography } from '@klickly/front-packages';
import { $primary } from '../../constants';
import { ShadowCard } from './components/ShadowCard';
import { DescriptionMessage, PlanItem, PlanItemContent, PlanItems } from './styles';

export const SubscriptionItems = ({ items }) => {
    return (
        <Fragment>
            <PlanItems>
                {items.map((item, index) => (
                    <PlanItem key={item.name}>
                        <ShadowCard borderColor={index === 1 ? $primary : null}>
                            <PlanItemContent>
                                <div style={{ textAlign: 'center' }}>
                                    <Typography.Text type='descriptor2' align='center'>
                                        {item.descriptor}
                                    </Typography.Text>
                                    <Typography.Title level={2} align='center'>
                                        {item.name}
                                    </Typography.Title>
                                    <Typography.Text type='body2' align='center'>
                                        {item.pricingPlan}
                                    </Typography.Text>
                                </div>

                                <ul>
                                    {item.pricingItems.map((item) => (
                                        <li key={nextId()}>
                                            <Typography.Text as='span' color='secondaryText'>
                                                {item}
                                            </Typography.Text>
                                        </li>
                                    ))}
                                </ul>
                            </PlanItemContent>
                        </ShadowCard>
                    </PlanItem>
                ))}
            </PlanItems>
            <DescriptionMessage>
                <Typography.Text color='secondaryText' type='body2'>
                    Pricing includes all taxes. 7-day moneyback guarantee. All transactions are 100% secure
                </Typography.Text>
            </DescriptionMessage>
        </Fragment>
    );
};
