import styled from 'styled-components';
import { $border } from '../../../../../../shared';

export const Styles = styled.div`
    padding: 24px;
    border-top: 1px solid ${$border};
    text-align: center;

    button {
        width: 100%;
    }
`;

export const ErrorMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;

    svg {
        font-size: 22px;
        margin-right: 20px;
    }
`;
