import { flow, getParent, types } from 'mobx-state-tree';
import qs from 'qs';
import { kcpAPI } from '../shared';
import { get } from '../utils';

export const UnsubscribeStore = types
    .model('UnsubscribeStore', {
        isLoading: true,
        unsubscribeStatus: false,
        email: types.optional(types.maybeNull(types.string), null),
        error: '',
        token: ''
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const fetchData = flow(function* fetchData() {
            try {
                const searchParams =
                    qs.parse(window.location.search, {
                        ignoreQueryPrefix: true
                    }) || {};
                self.token = searchParams.token;
                if (self.token) {
                    const data = yield self.root.get(kcpAPI.notifications.getUnsubscribe, { token: self.token });
                    self.email = data.email;
                    self.unsubscribeStatus = data.unsubscribeStatus;
                    self.isLoading = false;
                }
            } catch (error) {
                self.error = get(error, 'response.data.message', error.message);
                self.isLoading = false;
            }
        });

        const submit = flow(function* fetchData() {
            self.isLoading = true;
            try {
                const url = self.unsubscribeStatus
                    ? kcpAPI.notifications.submitSubscribe
                    : kcpAPI.notifications.submitUnsubscribe;
                const data = yield self.root.post(url, { token: self.token });
                self.unsubscribeStatus = data.unsubscribeStatus;
            } catch (error) {
                self.error = get(error, 'response.data.message', error.message);
            }
            self.isLoading = false;
        });

        return { fetchData, submit };
    });
