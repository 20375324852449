import React from 'react';
import { Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { Styles } from './styles';

export const OrderSummary = inject('store')(
    observer(({ store }) => {
        const { fullTotalPrice: price } = store.preCheckoutStore;
        return (
            <Styles>
                <Typography.Title level={2}>Estimated total</Typography.Title>
                <Typography.Title level={2}>{price}</Typography.Title>
            </Styles>
        );
    })
);
