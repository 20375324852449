import React from 'react';
import { BOTTOM_INDENTS, Col, Row, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../utils';
import BonusGift from '../BonusGift';

const SeasonalChallengeHeader = inject('store')(
    observer(({ store }) => {
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { seasonalChallenge, isNoActiveChallenge } = store.seasonalChallengeStore;
        return isNoActiveChallenge ? null : (
            <Row gutter={16} align='middle'>
                <Col flex={simple ? '0 0 100%' : '1'}>
                    <Typography.Title style={BOTTOM_INDENTS.L}>{seasonalChallenge.name}</Typography.Title>
                    <Typography.Text type='descriptor1' style={BOTTOM_INDENTS.M}>
                        {seasonalChallenge.description}
                    </Typography.Text>
                </Col>
                {seasonalChallenge?.reward ? (
                    <Col flex={simple ? '0 0 100%' : '456px'}>
                        <BonusGift />
                    </Col>
                ) : null}
            </Row>
        );
    })
);

export default SeasonalChallengeHeader;
