import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI, ROUTE_PATHS } from '../shared';
import { GIFTLY_CUSTOMER_BLOCKED_BY_IP_OR_EMAIL, GIFTLY_CUSTOMER_NOT_EXISTS_ERROR, selectCredentials } from '../utils';

export const AuthStore = types
    .model('AuthStore', {
        pending: true,
        errorMessage: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const setPending = (pending) => {
            self.pending = pending;
        };

        const setError = (error) => {
            if (error === null) {
                self.errorMessage = null;
                return false;
            }
            if (error && error.response && error.response.data) {
                self.errorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.errorMessage = error.message;
            }
        };

        const checkAuth = flow(function* checkAuth() {
            setError(null);
            setPending(true);
            try {
                yield self.root.accountStore.getAccountData();
            } catch (e) {
                setError(e);
                self.root.accountStore.data = null;
            } finally {
                setPending(false);
            }
        });

        const mergeGuestCart = flow(function* mergeGuestCart(cartId) {
            try {
                const data = cartId ? { secondaryCart: cartId } : {};
                yield self.root.post(kcpAPI.cart.mergeGuestCart, data);
            } catch (e) {
                setError(e);
            }
        });

        const onSignInRedirect = () => {
            return self.root.routeNavigate(self.root.navigationStore.getLinkToMarketPlace());
        };

        const onSignInSendMessage = () => {
            const { account } = self.root.accountStore;
            const giftlyToken = localStorage.getItem('giftly-extension-token');

            if (account?.extensionInstalled && giftlyToken !== account?.extension?.token) {
                localStorage.setItem('giftly-extension-token', account.extension.token);
                self.root.chromeExtensionStore.sendMessage({
                    action: 'changeExtensionTokenId',
                    data: { token: account.extension.token }
                });
            }
        };

        const signIn = flow(function* signIn(
            payload,
            onAfterLoginRedirectRoute = null,
            onAfterSignIn = () => null,
            onUserBlocked = () => null
        ) {
            setError(null);
            setPending(true);
            try {
                const data = { ...payload, ...selectCredentials() };
                yield self.root.post(kcpAPI.auth.login, data);
                yield self.root.accountStore.getAccountData();
                yield self.root.claimsPointsStore.getPoints();
                yield self.root.suiteStore.getCurrentSuite();
                yield mergeGuestCart();
                onSignInSendMessage();

                yield self.root.shoppingCartStore.getShoppingCartState();
                self.root.ordersStore.getOrders();
                self.root.accountStore.getBillingData();
                self.root.tasksStore.getTasks(true);
                self.root.seasonalChallengeStore.getSeasonalChallenge();

                if (self.root.accountStore.signUpStatusRejected) {
                    return self.root.routeNavigate(ROUTE_PATHS.waitlisted);
                }

                const state = self.root.routeLocation.state || {};
                const { uuid, redirectTo } = state;

                if (uuid) {
                    return self.root.routeNavigate(`${ROUTE_PATHS.rewards}/apply`, { state: { uuid, redirectTo } });
                }

                if (onAfterLoginRedirectRoute === 'self') return onAfterSignIn();

                if (onAfterLoginRedirectRoute) {
                    onAfterSignIn();
                    self.root.routeNavigate(onAfterLoginRedirectRoute);
                } else {
                    onAfterSignIn();
                    onSignInRedirect();
                }
            } catch (e) {
                setError(e);
                // TODO: check logic after KCP onboarding, may be redundant
                if (e.response?.data?.message === GIFTLY_CUSTOMER_BLOCKED_BY_IP_OR_EMAIL) {
                    self.root.accountStore.setBlockCustomer(true);
                    return onUserBlocked();
                }
                if (e.message === GIFTLY_CUSTOMER_NOT_EXISTS_ERROR) {
                    self.root.onboardingRedirectionStore.setRedirectionState(true);

                    if (window.location.pathname.includes(ROUTE_PATHS.signIn)) {
                        self.root.routeNavigate(ROUTE_PATHS.personalInfo);
                    }
                }
            } finally {
                setPending(false);
            }
        });

        const forgotPassword = flow(function* forgotPassword(data) {
            setError(null);
            setPending(true);
            try {
                yield self.root.post(kcpAPI.auth.passwordForgot, data);
                self.root.routeNavigate(ROUTE_PATHS.resetPassword);
            } catch (e) {
                setError(e);
                onForgotPasswordError();
            } finally {
                setPending(false);
            }
        });

        const onForgotPasswordError = () => {
            self.root.trackingStore.sendEvent({
                type: 'event',
                name: 'error',
                data: { message: self.errorMessage }
            });
        };

        const resetPassword = flow(function* resetPassword(data) {
            setError(null);
            setPending(true);
            try {
                yield self.root.post(kcpAPI.auth.passwordReset, data);
                self.root.routeNavigate(ROUTE_PATHS.signIn);
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
            }
        });

        const logout = flow(function* logout() {
            setError(null);
            setPending(true);
            try {
                yield self.root.post(kcpAPI.auth.logout);
                self.root.accountStore.clearAccountState();
                self.root.routeNavigate(ROUTE_PATHS.root);
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
                self.root.onboardingPopoverStore.toggleShowPopoverOnLinkToGs(false);
                self.root.onboardingPopoverStore.toggleShowPopoverOnCart(false);
                self.root.onboardingPopoverStore.toggleShowPopoverOnLaunchGsButton(false);
            }
        });

        return {
            checkAuth,
            signIn,
            forgotPassword,
            resetPassword,
            logout,
            mergeGuestCart,
            onSignInSendMessage,
            setError
        };
    });
