import { Button } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../utils';

export const StyledSeeMoreBtn = styled(Button)`
    && {
        margin: 8px auto 0;
    }
`;

export const StyledWrapper = styled.div`
    @media (${GRID_DEVICES_MAX.md}) {
        padding-bottom: 120px;
    }
`;

export const StyledCouponList = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    align-self: center;

    @media (max-width: 1300px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 990px) {
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;
