import React, { Fragment } from 'react';
import { Button, ForwardArrowIcon, Icon } from '@klickly/front-packages';
import { ROUTE_PATHS } from '../../../../constants';
import { StyledMainText, StyledMessage, StyledText, StyledWrapper } from './styled';

const RewardEarnedNotification = ({
    value,
    dimension = 'points',
    showClaimReward = false,
    additionalText = null,
    navigate
}) => {
    return (
        <StyledWrapper>
            <StyledMessage level={3} $withBottomIndent={showClaimReward}>
                <StyledMainText $justify={dimension === 'points' ? 'space-between' : 'center'}>
                    <span>
                        You’ve earned{' '}
                        <StyledText>
                            {value} {dimension}!
                        </StyledText>
                    </span>
                    {dimension === 'points' && (
                        <Button onClick={() => navigate(ROUTE_PATHS.points)} type='unstyled' ghost>
                            Learn more
                        </Button>
                    )}
                </StyledMainText>
                {additionalText && (
                    <Fragment>
                        <br />
                        {additionalText}
                    </Fragment>
                )}
            </StyledMessage>
            {showClaimReward && (
                <Button type='primary' onClick={() => navigate(ROUTE_PATHS.suiteGs)}>
                    <span>Claim your Reward</span>
                    <Icon stylePriority component={() => <ForwardArrowIcon />} />
                </Button>
            )}
        </StyledWrapper>
    );
};

export default RewardEarnedNotification;
