import React from 'react';
import { Icon, TOP_INDENTS } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ShippingAddressForm } from '../ShippingAddressForm';
import { StyledWrapper } from './styles';

export const AddShipping = inject('store')(
    observer(({ store, from = 'settings', onAfterSuccess, onShippingErrorCallback, isChallenge }) => {
        const { addShippingAddress, errorMessage, pending } = store.accountStore;

        const handleAddShippingAddress = (data, onAfterSuccess) => {
            addShippingAddress(data, onAfterSuccess, onShippingErrorCallback);
        };

        return (
            <StyledWrapper style={TOP_INDENTS.SM} $from={from}>
                <ShippingAddressForm
                    from={from}
                    submitButtonText='Add shipping address'
                    error={errorMessage}
                    onSubmit={handleAddShippingAddress}
                    onAfterSuccess={onAfterSuccess}
                    isChallenge={isChallenge}
                />
                {pending && <Icon.Loader />}
            </StyledWrapper>
        );
    })
);
