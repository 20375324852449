import { Spin } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $background, $error } from '../../../shared';
import { GRID_DEVICES_MAX } from '../../../utils';

export const StyledUpload = styled.div`
    position: relative;
    max-width: 240px;

    @media (${GRID_DEVICES_MAX.md}) {
        display: flex;
        align-items: center;
        width: 100%;
        gap: ${({ $justify }) => ($justify ? '16px' : '24px')};
        max-width: 100%;

        ${({ $justify }) => css`
            justify-content: ${$justify};
        `};
    }

    .ant-avatar {
        border: 0;
        cursor: initial;
    }

    .ant-upload-wrapper {
        display: block;
        position: absolute;
        top: 240px;
        transform: translateY(-100%);
        right: 0;
        cursor: pointer;

        @media (${GRID_DEVICES_MAX.md}) {
            position: initial;
            transform: initial;
            order: 3;
        }

        .ant-upload-select {
            margin-left: auto;

            .ant-upload {
                display: flex;
                gap: 8px;

                @media (${GRID_DEVICES_MAX.md}) {
                    gap: 16px;
                }

                .anticon {
                    height: 32px;
                    background: ${$background};
                    width: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
`;

export const StyledSpin = styled(Spin)`
    &&& {
        .anticon {
            margin: 0;
            width: 50px;
            height: 50px;
            transform: translate(-50%, -50%);

            svg {
                display: block;
                font-size: 50px;
            }
        }
    }
`;

export const StyledError = styled.div`
    color: ${$error};

    @media (${GRID_DEVICES_MAX.md}) {
        order: 4;
        flex: 1 0 100%;
    }
`;

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (${GRID_DEVICES_MAX.md}) {
        order: 4;
        flex: 1 0 100%;
    }
`;
