import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    Col,
    Form,
    ForwardArrowIcon,
    Icon,
    notification,
    ProfileIcon,
    RIGHT_INDENTS,
    Row,
    SelectableItem,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../utils';
import { ROUTE_PATHS, TASK_STATUSES } from '../../constants';
import NotificationContent from '../NotificationContent';
import { PersonalizationHead } from '../PersonalizationHead';
import { categories } from './categories';
import { ButtonWrap, StyledButton, StyledCategoriesWrap, StyledForm } from './styles';

export const PersonalizationCategoriesForm = inject('store')(
    observer(({ store, from = 'default', submitButtonText = 'Next', onAfterSuccess }) => {
        const navigate = useNavigate();
        const { isApprovedCustomer } = store.accountStore;
        const { pending, completeTask, selectCategoriesTask } = store.tasksStore;
        const { selectedCategories, updateCustomerInfo, pending: accountPending, getAccountData } = store.accountStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const [form] = Form.useForm();

        const handleAfterSuccess = (...props) => {
            if (typeof onAfterSuccess === 'function') {
                return onAfterSuccess(...props);
            }

            return from === 'onboarding' ? navigate(ROUTE_PATHS.personalizationImportance) : null;
        };

        notification.config({
            bottom: simple ? '110px' : '80px'
        });

        const submitted = (data) => {
            const selectedCategories = Object.entries(data)
                .filter(([, value]) => value)
                .map(([key]) => key);
            return selectCategoriesTask?.data.status === TASK_STATUSES.active && isApprovedCustomer
                ? completeTask({
                      taskPayload: { selectedCategories },
                      taskId: selectCategoriesTask?._id,
                      onAfterSuccess: (...args) => getAccountData(() => handleAfterSuccess(...args)),
                      taskCompleted: selectCategoriesTask?.data?.status === TASK_STATUSES.active,
                      initialFetch: false,
                      sources: 'points'
                  })
                : updateCustomerInfo({ selectedCategories }, () =>
                      from === 'onboarding'
                          ? navigate(ROUTE_PATHS.personalizationImportance)
                          : notification.open({
                                duration: 3,
                                description: (
                                    <NotificationContent.Info
                                        rewardCount={0}
                                        icon={<ProfileIcon />}
                                        customContent={
                                            simple ? (
                                                <Typography.Text type='descriptor2'>
                                                    Saved. Thanks for sharing this information.
                                                </Typography.Text>
                                            ) : (
                                                <Typography.Title level={3}>
                                                    Saved. <br />
                                                    Thanks for sharing this information.
                                                </Typography.Title>
                                            )
                                        }
                                    />
                                ),
                                bottom: simple ? '111px' : '8px'
                            })
                  );
        };

        return (
            <StyledForm
                form={form}
                onFinish={submitted}
                validateTrigger={['onBlur']}
                initialValues={selectedCategories.reduce((result, category) => ({ ...result, [category]: true }), {})}
            >
                {from === 'onboarding' ? (
                    <Row gutter={20}>
                        <Col span={24}>
                            <PersonalizationHead
                                step={1}
                                title='Which categories are you most interested in?'
                                description='Choose 3 categories to help us get to know you'
                                simple={simple}
                                button={
                                    !simple ? (
                                        <Form.Item noStyle shouldUpdate>
                                            {() => (
                                                <StyledButton
                                                    $simple={simple}
                                                    htmlType='submit'
                                                    type='primary'
                                                    size='large'
                                                    disabled={
                                                        Object.values(form.getFieldsValue(true)).filter((v) => v)
                                                            .length < 3
                                                    }
                                                >
                                                    <Typography.Text type='badgeLarge' style={RIGHT_INDENTS.S}>
                                                        {submitButtonText}
                                                    </Typography.Text>
                                                    <Icon
                                                        stylePriority
                                                        size='24px'
                                                        component={() => <ForwardArrowIcon />}
                                                    />
                                                </StyledButton>
                                            )}
                                        </Form.Item>
                                    ) : null
                                }
                            />
                        </Col>
                    </Row>
                ) : null}
                <StyledCategoriesWrap style={BOTTOM_INDENTS.SM} $simple={simple}>
                    {categories.map((category) => (
                        <Form.Item
                            initialValue={false}
                            valuePropName='checked'
                            name={category.value}
                            key={category.value}
                        >
                            <SelectableItem fillSelected img={category.icon}>
                                <Typography.Title level={simple ? 2 : 3}>{category.title}</Typography.Title>
                                <Typography.Text type={simple ? 'descriptor1' : 'body2'}>
                                    {category.text}
                                </Typography.Text>
                            </SelectableItem>
                        </Form.Item>
                    ))}
                </StyledCategoriesWrap>
                <Form.Item noStyle shouldUpdate>
                    {() => (
                        <ButtonWrap $align={from === 'onboarding' ? 'center' : 'flex-start'}>
                            <StyledButton
                                $simple={simple}
                                htmlType='submit'
                                type='primary'
                                size='large'
                                disabled={Object.values(form.getFieldsValue(true)).filter((v) => v).length < 3}
                            >
                                <Typography.Text type='badgeLarge' style={RIGHT_INDENTS.S}>
                                    {submitButtonText}
                                </Typography.Text>
                                <Icon stylePriority size='24px' component={() => <ForwardArrowIcon />} />
                            </StyledButton>
                        </ButtonWrap>
                    )}
                </Form.Item>
                {(pending || accountPending) && <Icon.Loader />}
            </StyledForm>
        );
    })
);
