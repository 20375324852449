export const reviews = [
    {
        text: 'A friend of mine sent this to me – I qualified and just got my 1st gift. This is an amazing program!',
        name: 'Jessie'
    },
    {
        text:
            'So I’ve already used all 3 of my invites, but I have a bunch of friends who are begging me to get them access. How do I get more invites?',
        name: 'Sarah'
    },
    {
        text:
            'I’m loving Giftly! I’ve been trying it for a few weeks now – I’ve claimed 7 gifts, recieved 5 and posted about 2 I really liked... It’s incredible!',
        name: 'Lauren'
    },
    {
        text:
            'I really like trying new things and this helps me know the product before buying another for the whole price.',
        name: 'Nicky'
    }
];
