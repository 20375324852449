import styled, { css } from 'styled-components';
import { $border, $primaryText } from '../../../../../../../../shared';

export const Styles = styled.div`
    width: 100%;
    padding: 16px 24px;
    border-bottom: 1px solid ${$border};

    &:last-child {
        margin-bottom: 16px;
        border-bottom: none;
    }

    ${({ $simple }) =>
        $simple
            ? css`
                  &:last-child {
                      margin-bottom: 0;
                      border-bottom: none;
                  }
              `
            : ''};
`;

export const CardHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;
`;

export const CardBody = styled.div`
    width: 100%;
`;

export const StyledText = styled.div`
    padding-right: 16px;
    font-weight: 500;
`;

export const CardPricesRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${({ $withBottomIndent }) =>
        $withBottomIndent &&
        css`
            margin-bottom: 16px;
        `};
`;

export const SubtotalPrice = styled.div`
    color: ${$primaryText};
    padding: 16px 0 0;
    border-top: 1px solid ${$border};
`;
