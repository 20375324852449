import styled from 'styled-components';

export const FieldStyles = styled.div`
    width: 100%;

    & > div > label > input {
        padding: 22px 14px 10px 14px !important;
    }

    & > div > label > span {
        padding: 14px 14px !important;
    }
`;
