import { Button, device } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $bordered } from '../../../../../../shared';

const isIos = device.iOS();
const isSafari = device.isSafari();

export const Styles = styled.div``;

export const StyledButtonWrap = styled.div`
    width: 100%;

    ${({ $simple }) =>
        $simple
            ? css`
                  width: calc(100% + 44px);
                  margin-left: -24px;
                  padding: 22px 24px;
                  position: sticky;
                  bottom: ${() => (isSafari && isIos ? '-90px' : '0')};
                  border-top: 2px solid ${$bordered};
                  z-index: 5;
                  background: #ffffff;
              `
            : ''}
`;

export const StyledButton = styled(Button)`
    && {
        padding: 20px 24px;

        ${({ $simple }) =>
            $simple
                ? css`
                      width: 100%;
                  `
                : ''}
    }
`;
