import styled from 'styled-components';
import { $primaryText } from '../../../../../../shared';
import { GRID_DEVICES_MAX, GRID_DEVICES_MIN } from '../../../../../../utils';

export const Styles = styled.div`
    && {
        padding-bottom: 20px;

        .ant-popover-arrow {
            &:before {
                background-color: ${$primaryText};
            }
        }
    }

    @media (${GRID_DEVICES_MAX.md}) {
        text-align: center;
    }

    @media (${GRID_DEVICES_MIN.md}) {
        text-align: left;
    }
`;
