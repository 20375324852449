import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $background, $white } from '../../shared';
import { GRID_DEVICES_MAX } from '../../utils';

export const StyledWrapper = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${$background};

    @media (${GRID_DEVICES_MAX.sm}) {
        background: ${$white};
    }
`;

export const StyledTitle = styled(Typography.Title)`
    && {
        margin: 40px 0 24px;

        @media (${GRID_DEVICES_MAX.sm}) {
            margin: 24px 0 20px;
            font-size: 24px;
            line-height: 36px;
        }
    }
`;

export const StyledDescription = styled(Typography.Text)`
    margin-bottom: 40px;
    font-weight: 500;
    text-align: center;

    @media (${GRID_DEVICES_MAX.sm}) {
        font-weight: normal;
    }
`;
