import React, { useCallback } from 'react';
import { useModal as useAntdModal, useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../utils';
import ModalContainer from '../components/ModalContainer';

export const useModal = (onModalCloseCallback = () => null) => {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    const { setModal, hideModal } = useAntdModal();

    const onModalClose = useCallback(() => {
        hideModal();
        onModalCloseCallback();
    }, [hideModal]);

    const openModal = useCallback(
        ({
            children,
            title,
            modalWrapperStyles = {},
            modalContentStyles = {},
            withoutCloseIcon = false,
            settings = {}
        }) => {
            setModal(
                <ModalContainer
                    simple={simple}
                    onClose={onModalClose}
                    title={title}
                    withoutCloseIcon={withoutCloseIcon}
                    modalWrapperStyles={modalWrapperStyles}
                    modalContentStyles={modalContentStyles}
                >
                    {children}
                </ModalContainer>,
                {
                    fullscreen: simple,
                    closable: false,
                    ...settings
                }
            );
        },
        [setModal, onModalClose, simple]
    );

    return { openModal, closeModal: onModalClose };
};
