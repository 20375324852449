import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, Col, GRID_POINTS, KliHero, Row, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../shared';
import SidePanel from '../SidePanel';
import { ApplyInviteCodeForm } from './components/ApplyInviteCodeForm';
import { ApplyInviteCodeContentStyles, ApplyInviteCodeStyles, StyledKliHero } from './styles';

const ApplyInviteCode = observer(() => {
    const simple = useSimpleInterface(GRID_POINTS.lg);
    const navigate = useNavigate();

    const onBack = () => navigate(ROUTE_PATHS.waitlisted);

    return (
        <ApplyInviteCodeStyles>
            <Row>
                <Col span={24} lg={{ span: 16 }}>
                    <ApplyInviteCodeContentStyles>
                        {!simple ? (
                            <StyledKliHero
                                title='I have an invite code'
                                subTitle='An invite code increases your chances of being accepted to Giftly'
                                simple={false}
                                titleOnTop
                                withoutBorders
                                onBack={onBack}
                            />
                        ) : null}
                        {simple && (
                            <div style={BOTTOM_INDENTS.SM}>
                                <KliHero
                                    title='I have an invite code'
                                    subTitle='An invite code increases your chances of being accepted to Giftly'
                                    simple={false}
                                    titleOnTop
                                    withoutBorders
                                    onBack={onBack}
                                />
                            </div>
                        )}
                        <ApplyInviteCodeForm />
                    </ApplyInviteCodeContentStyles>
                </Col>
                <SidePanel />
            </Row>
        </ApplyInviteCodeStyles>
    );
});

export default ApplyInviteCode;
