import { useLocation, useParams } from 'react-router-dom';
import { notification, useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../../../utils';
import { handleTaskCompleted } from '../utils';

export const useOnProductReviewSubmit = (store) => {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    const { state } = useLocation();
    const { writeProductReviewTask, completeTask } = store.tasksStore;
    const { isApprovedCustomer, sendFeedback } = store.accountStore;
    const { id } = useParams();

    return (data, callback = () => null) => {
        if (typeof notification.destroy === 'function') notification.destroy();
        const shouldCompleteTask =
            writeProductReviewTask && writeProductReviewTask?.data?.status === 'active' && isApprovedCustomer;
        if (shouldCompleteTask && data) {
            completeTask({
                taskPayload: {
                    ...data,
                    ...{
                        order: state.orderId,
                        promotion: id,
                        promotionTitle: state.productTitle,
                        brandName: state.merchantName,
                        headline: state.variantTitle
                    }
                },
                taskId: writeProductReviewTask._id,
                onAfterSuccess: () => {
                    handleTaskCompleted(store, simple);
                    // eslint-disable-next-line promise/prefer-await-to-callbacks
                    callback();
                }
            });
        } else {
            sendFeedback({
                payload: data,
                onAfterSuccess: () => {
                    // eslint-disable-next-line promise/prefer-await-to-callbacks
                    callback();
                }
            });
        }
    };
};
