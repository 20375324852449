import { Icon } from '@klickly/front-packages';
import styled from 'styled-components';

export const ErrorFieldStyles = styled.div`
    display: flex;
    align-items: center;
`;

export const ErrorFieldIcon = styled(Icon)`
    margin: 0 16px 0 0;
`;
