import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    ForwardArrowIcon,
    Icon,
    TOP_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { READY_TO_LUNCH_PRODUCTS, ROUTE_PATHS } from '../../../../../../shared';
import { Animated } from '../../../../../../shared/components/Animated';
import { GRID_POINTS } from '../../../../../../utils';
import SuiteGallery from '../SuiteGallery';
import { InfoButton, StyledHeadline1, StyledHeadline2, Styles, SuiteButton } from './styles';

const SuiteClosed = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const products = READY_TO_LUNCH_PRODUCTS;
        const simple = useSimpleInterface(GRID_POINTS.lg);
        const { trackViewPromotions } = store.trackingStore;

        useEffect(() => {
            if (products?.length) trackViewPromotions(products);
        }, [products?.length]);

        const goToInfo = () => {
            navigate(ROUTE_PATHS.suiteGsInfo);
        };

        const goToSubscription = () => {
            navigate(ROUTE_PATHS.subscriptions);
        };

        return (
            <Animated>
                <Styles>
                    {simple ? (
                        <StyledHeadline1>Welcome to your Gifting Suite</StyledHeadline1>
                    ) : (
                        <StyledHeadline2 level={2}>Welcome to your Gifting Suite</StyledHeadline2>
                    )}
                    <Typography.Text type='body1'>
                        Upgrade to premium and pick a new gift, just for you, each and every week.
                    </Typography.Text>
                </Styles>
                <SuiteGallery />
                <Styles style={BOTTOM_INDENTS.XXL}>
                    <SuiteButton style={TOP_INDENTS.XL} type='primary' size='large' onClick={goToSubscription}>
                        &nbsp;&nbsp;
                        <Typography.Text as='span' type='badgeLarge'>
                            Upgrade to premium
                        </Typography.Text>
                        <Icon stylePriority size='16px' component={() => <ForwardArrowIcon />} />
                        &nbsp;&nbsp;
                    </SuiteButton>
                    {simple && (
                        <Fragment>
                            <InfoButton style={TOP_INDENTS.SM} type='unstyled' onClick={goToInfo}>
                                <Typography.Text type='badgeLarge'>What is a Gift Suite?</Typography.Text>
                            </InfoButton>
                        </Fragment>
                    )}
                </Styles>
            </Animated>
        );
    })
);

export default SuiteClosed;
