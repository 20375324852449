import React from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../shared';
import ApplyReward from '../components/ApplyReward';
import ChallengesPage from '../components/ChallengesPage';
import CouponsPage from '../components/CouponsPage';
import RewardsInfo from '../components/RewardsInfo';

export const ROUTES = [
    {
        path: ROUTE_PATHS.rewards,
        element: <Navigate to={ROUTE_PATHS.coupons} replace />,
        index: true
    },
    {
        path: ROUTE_PATHS.rewardsApply,
        element: <ApplyReward />
    },
    {
        path: ROUTE_PATHS.coupons,
        element: <CouponsPage />
    },
    {
        path: ROUTE_PATHS.challenges,
        element: <ChallengesPage />
    },
    {
        path: ROUTE_PATHS.rewardsInfo,
        element: <RewardsInfo />
    }
];
