import styled, { css } from 'styled-components';

export const StyledWrap = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 305px);
    grid-gap: 24px;
    justify-content: center;

    ${({ $twoRows }) =>
        $twoRows
            ? css`
                  grid-template-columns: repeat(2, 305px);
              `
            : ''}

    ${({ $simple }) =>
        $simple
            ? css`
                  grid-template-columns: repeat(2, 154px);
                  grid-column-gap: 16px;
                  grid-row-gap: 24px;
              `
            : ''}
`;

export const StyledPromotionWrap = styled.div`
    position: relative;
    padding-top: 24px;
`;

export const StyledLoaderWrapper = styled.div`
    position: relative;
    height: 300px;
`;
