import { types } from 'mobx-state-tree';

export const PDPModel = types.model({
    _id: types.identifier,
    externalId: types.string,
    title: types.maybeNull(types.string),
    account: types.maybeNull(types.string),
    campaign: types.maybeNull(types.string),
    campaignName: types.maybeNull(types.string),
    shopDomain: types.maybeNull(types.string),
    brandName: types.maybeNull(types.string)
});
