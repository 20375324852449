import React from 'react';
import { BOTTOM_INDENTS, CircleActionsCloseIcon, TOP_INDENTS, Typography } from '@klickly/front-packages';
import { $primaryBackground } from '../../../../../../../../../../../../../../../../../../shared';
import { ButtonsWrap, KliStyledIcon, StyledButton, Styles } from './styles';

export const CancelOrderConfirmation = ({ goToCancellationReason, onClose }) => {
    return (
        <Styles>
            <KliStyledIcon
                size='40px'
                color='primary'
                roundedSize='100%'
                roundedColor={$primaryBackground}
                component={() => <CircleActionsCloseIcon />}
            />
            <Typography.Text style={{ ...BOTTOM_INDENTS.XL, ...TOP_INDENTS.SM }} align='center' type='body1'>
                Are you sure you want to cancel your order? This action cannot be undone.
            </Typography.Text>
            <ButtonsWrap>
                <StyledButton type='secondary' onClick={onClose} data-e2e='giftly-cancel-order-mod1-close-btn'>
                    <Typography.Text type='badgeLarge'>Close</Typography.Text>
                </StyledButton>
                <StyledButton
                    type='primary'
                    onClick={goToCancellationReason}
                    data-e2e='giftly-cancel-order-mod1-confirm-btn'
                >
                    <Typography.Text type='badgeLarge'>Confirm</Typography.Text>
                </StyledButton>
            </ButtonsWrap>
        </Styles>
    );
};
