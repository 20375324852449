import { Button } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledWrap = styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledButton = styled(Button)`
    && {
        padding: 14px 0 14px;
    }
`;

export const StyledValueWrap = styled.div`
    margin-bottom: 8px;
    white-space: nowrap;
`;
