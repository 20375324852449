import React from 'react';
import { Button, Image } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import entrepreneurLogo from '../../../../assets/img/landing/companies/Entrepreneur.svg';
import forbesLogo from '../../../../assets/img/landing/companies/Forbes.svg';
import huffpostLogo from '../../../../assets/img/landing/companies/Huffpost.svg';
import incLogo from '../../../../assets/img/landing/companies/Inc.svg';
import mashableLogo from '../../../../assets/img/landing/companies/Mashable.svg';
import landingMainImg from '../../../../assets/img/landing/intro/landing-main.png';
import landingMainMobile4Img from '../../../../assets/img/landing/intro/large.png';
import landingMainMobile1Img from '../../../../assets/img/landing/intro/small-1.png';
import landingMainMobile2Img from '../../../../assets/img/landing/intro/small-2.png';
import landingMainMobile3Img from '../../../../assets/img/landing/intro/small-3.png';

export const StyledIntroWrap = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
`;

export const IntroStyledContent = styled.div`
    width: 100%;
    max-width: 414px;
    min-height: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;

    ${({ $simple }) =>
        $simple
            ? css`
                  min-height: auto;
                  padding: 113px 24px 24px;
              `
            : ''}
`;

export const StyledMainImage = styled(Image)`
    width: 100%;
    min-height: 500px;
    object-fit: cover;
    position: relative;
`;

export const LandingMainDesktopImg = () => <StyledMainImage src={landingMainImg} alt='main' />;

export const StyledMainSmallImage = styled(Image)`
    width: 100%;
    height: auto;
    object-fit: contain;
`;

export const StyledMainLargeImage = styled(Image)`
    width: 100%;
    height: 401px;
    object-fit: cover;
`;

export const LandingMainMobileSmallImg1 = () => <StyledMainSmallImage src={landingMainMobile1Img} alt='main-1' />;
export const LandingMainMobileSmallImg2 = () => <StyledMainSmallImage src={landingMainMobile2Img} alt='main-2' />;
export const LandingMainMobileSmallImg3 = () => <StyledMainSmallImage src={landingMainMobile3Img} alt='main-3' />;
export const LandingMainMobileLargeImg = () => <StyledMainLargeImage src={landingMainMobile4Img} alt='main-4' />;

export const StyledMainMobileImgWrap = styled.div`
    width: 100%;
`;

export const StyledMainMobileImgRow = styled.div`
    display: flex;
    width: 100%;
`;

export const StyledButtonWrap = styled.div`
    display: flex;
    margin-bottom: 32px;

    ${({ $simple }) =>
        $simple
            ? css`
                  width: 100%;
                  flex-direction: column;
              `
            : ''}
`;

export const StyledApplyButton = styled(Button)`
    && {
        padding-left: 60px;
        padding-right: 60px;
        margin-right: 8px;

        ${({ $simple }) =>
            $simple
                ? css`
                      width: 100%;
                      margin-right: 0;
                      margin-bottom: 16px;
                  `
                : ''}
    }
`;

export const StyledDiscoverButton = styled(Button)`
    && {
        border-color: #ffffff;
        padding-left: 60px;
        padding-right: 60px;
        margin-left: 8px;

        ${({ $simple }) =>
            $simple
                ? css`
                      width: 100%;
                      margin-left: 0;
                  `
                : ''}
    }
`;

export const StyledCompaniesWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ $simple }) =>
        $simple
            ? css`
                  img {
                      height: 8px;
                  }
              `
            : ''}
`;

export const StyledCompanyImg = styled(Image)`
    width: auto;
    height: 10px;
    object-fit: contain;
`;

export const Entrepreneur = ({ ...props }) => <StyledCompanyImg {...props} src={entrepreneurLogo} alt='Entrepreneur' />;
export const Forbes = () => <StyledCompanyImg src={forbesLogo} alt='Forbes' />;
export const Huffpost = () => <StyledCompanyImg src={huffpostLogo} alt='Huffpost' />;
export const Inc = () => <StyledCompanyImg src={incLogo} alt='Inc' />;
export const Mashable = () => <StyledCompanyImg src={mashableLogo} alt='Mashable' />;
