import React, { useState } from 'react';
import { BackArrowIcon, Icon, Taxonomy, TOP_INDENTS } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { Filter } from './components/Filter';
import { PriceRange } from './components/PriceRange';
import { StyledWrap } from './styled';

export const Filters = inject('store')(
    observer(({ store, simple }) => {
        const {
            isOpenOnMobile,
            updateIsOpenOnMobile,
            query,
            updateQuery,
            updatePromotions,
            brandData,
            brandId
        } = store.brandPageStore;
        const [selectedSubCategory, setSelectedSubCategory] = useState(null);
        const { price } = query;

        return (
            <StyledWrap simple={simple} mobileHeight={window?.innerHeight} isOpenOnMobile={isOpenOnMobile}>
                {simple && (
                    <div style={TOP_INDENTS.SM}>
                        <Icon
                            size='24px'
                            onClick={() => updateIsOpenOnMobile(false)}
                            component={() => <BackArrowIcon />}
                        />
                    </div>
                )}
                <Taxonomy
                    taxonomyType='brand'
                    brandId={brandId}
                    brandData={brandData}
                    selectedSubCategory={selectedSubCategory}
                    setSelectedSubCategory={setSelectedSubCategory}
                    onAfterCategorySelect={(gt) => {
                        updateQuery({ gt });
                        updatePromotions({ isNewSearch: true });
                        if (simple) updateIsOpenOnMobile(false);
                    }}
                />

                {price && (
                    <Filter title='Price $' simple={simple}>
                        <PriceRange />
                    </Filter>
                )}
            </StyledWrap>
        );
    })
);
