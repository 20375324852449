import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripe } from '../../../../../../../../config';
import { STRIPE_APPEARANCE } from '../../../../../../../../shared/constants/stripeElements';
import { StripeForm } from '../StripeForm';
const stripePromise = loadStripe(stripe.publishKey);

export const StripeAddCard = ({
    stripeClientSecret,
    defaultShippingAddress,
    onSubmit,
    onError,
    error,
    setBillingPending
}) => {
    const returnUrl = `${window.location.href}`;
    const stripeOptions = { clientSecret: stripeClientSecret, appearance: STRIPE_APPEARANCE };

    return (
        <Elements stripe={stripePromise} options={stripeOptions}>
            <StripeForm
                defaultShippingAddress={defaultShippingAddress}
                onSubmit={onSubmit}
                onError={onError}
                returnUrl={returnUrl}
                error={error}
                setBillingPending={setBillingPending}
            />
        </Elements>
    );
};
