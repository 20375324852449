import React from 'react';
import { Divider, GRID_POINTS, Icon, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import qs from 'qs';
import { ROUTE_PATHS } from '../../../../shared';
import PromotionsSlider from '../../../../shared/components/PromotionsSlider';
import { StyledWrapper } from './styled';

const HolidayDeals = inject('store')(
    observer(({ store }) => {
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { holidayDealsPromotionsLoading, holidayDealsPromotions, strategyInfo } = store.dealsStore;
        return (
            <StyledWrapper data-e2e='holiday-deals-promotions-block'>
                {holidayDealsPromotionsLoading ? (
                    <Icon.Loader />
                ) : (
                    <PromotionsSlider
                        promotions={holidayDealsPromotions}
                        title={strategyInfo?.name || 'Holiday Deals'}
                        seAllBtnText={simple ? 'See all Deals' : 'See all'}
                        seAllBtnAction={() =>
                            window.open(`${ROUTE_PATHS.shop}?${qs.stringify({ strategy: strategyInfo.name })}`, '_self')
                        }
                        promotionsHeight={simple ? '315px' : '370px'}
                        divider={simple ? null : <Divider height={4} margin='24px 0 0' />}
                    />
                )}
            </StyledWrapper>
        );
    })
);

export default HolidayDeals;
