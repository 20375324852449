import React from 'react';
import Body from './components/Body';
import Header from './components/Header';
import { StyledWrapper } from './styled';

const ErrorPage = () => {
    return (
        <StyledWrapper>
            <Header />
            <Body />
        </StyledWrapper>
    );
};

export default ErrorPage;
