import styled from 'styled-components';
import { $primary, $tartRed } from '../../../../shared';
import { Logo } from '../../../../shared/components/Logo';

export const StyledWrapper = styled.div`
    height: 232px;
    padding-top: 60px;
    background: ${$tartRed};

    .slick-active,
    .slick-dots li {
        button {
            background: transparent;
            &:after {
                background: transparent;
            }
        }
    }

    .ant-carousel .slick-dots li.slick-active button {
        background: transparent;
    }

    .slick-dots li.slick-active button:before {
        color: ${$primary};
        opacity: 1;
    }

    .slick-dots li button:before {
        color: ${$primary};
        opacity: 0.4;
    }

    && {
        .slick-dots-bottom {
            bottom: -20px;
        }
    }
`;

export const StyledLogo = styled(Logo)`
    margin: 0 auto 24px;
`;
