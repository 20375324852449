import React from 'react';
import { ShadowCardStyles } from './ShadowCard.styles';

export const ShadowCard = ({ children, borderColor, ...rest }) => {
    return (
        <ShadowCardStyles borderColor={borderColor} {...rest}>
            {children}
        </ShadowCardStyles>
    );
};
