import React from 'react';
import {
    BOTTOM_INDENTS,
    Button,
    Col,
    Form,
    GRID_POINTS,
    Icon,
    Input,
    Row,
    TOP_INDENTS,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { VALIDATION_RULES } from '../../../../../../shared';
import { ApplyNow } from '../../../../../../shared/components/ApplyNow';
import { ErrorField } from '../../../../../../shared/components/ErrorField';
export const ForgotPasswordForm = inject('store')(
    observer(({ store, pending }) => {
        const [form] = Form.useForm();
        const { forgotPassword: onSubmit, errorMessage } = store.authStore;
        const simple = useSimpleInterface(GRID_POINTS.lg);

        const submitted = (data) => {
            if (typeof onSubmit !== 'undefined') {
                onSubmit(data);
            }
        };

        return (
            <Form form={form} onFinish={submitted} validateTrigger={['onBlur']}>
                <Row gutter={20}>
                    <Col span={24}>
                        <Form.Item name='email' rules={VALIDATION_RULES.EMAIL}>
                            <Input size='large' showWarningIcon type='email' label='Email' autoComplete='on' />
                        </Form.Item>
                    </Col>
                </Row>
                {errorMessage && (
                    <Row>
                        <Col>
                            <ErrorField hasBottomIndent error={errorMessage} />
                        </Col>
                    </Row>
                )}
                <Row style={TOP_INDENTS.SM}>
                    <Col span={24} lg={{ span: 14 }}>
                        <Form.Item noStyle shouldUpdate>
                            {() => (
                                <Button
                                    data-e2e='auth_resetToken-button'
                                    htmlType='submit'
                                    type='primary'
                                    size='large'
                                    block
                                    disabled={
                                        !form.isFieldsTouched(true) ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                >
                                    Send reset token
                                </Button>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                {simple && (
                    <Row justify='center'>
                        <Col style={BOTTOM_INDENTS.XL} width='fit-content'>
                            <ApplyNow />
                        </Col>
                    </Row>
                )}
                {pending && <Icon.Loader />}
            </Form>
        );
    })
);

export default ForgotPasswordForm;
