import React, { Fragment, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    Button,
    GRID_POINTS,
    Icon,
    MailIcon,
    notification,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS, TASK_STATUSES } from '../../../../../../../../../../shared';
import NotificationContent from '../../../../../../../../../../shared/components/NotificationContent';
import { InviteFriendTaskHeader } from '../InviteFriendTaskHeader';
import { StyledKliText, TaskThanksContentStyles } from './styles';

export const InviteFriendTaskThanks = inject('store')(
    observer(({ store, onClose, setSubmitted }) => {
        const navigate = useNavigate();
        const { pathname } = useLocation();
        const challenge = store.tasksStore?.inviteFriendTask;
        const { isApprovedCustomer } = store.accountStore;
        const { getInviteFriendTaskData, inviteFriendTask } = store.tasksStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const fromGS = pathname.includes(ROUTE_PATHS.suiteGs);

        const sendAnother = async () => {
            await getInviteFriendTaskData();
            setSubmitted(false);
        };

        useEffect(() => {
            if (inviteFriendTask?.data.status !== TASK_STATUSES.completed) {
                store.trackingStore.trackChallengeNotification(
                    challenge,
                    TASK_STATUSES.completed,
                    `${challenge?.data?.title} completed!`
                );
                notification.open({
                    key: challenge?.alias,
                    duration: null,
                    description: <NotificationContent.Completed completedActionName={challenge?.data?.title} />,
                    bottom: simple ? '0' : '8px'
                });
                notification.open({
                    duration: null,
                    description: <NotificationContent.RewardEarned value={50} navigate={navigate} />,
                    bottom: simple ? '0' : '8px'
                });
            }
        }, [inviteFriendTask?.data.status, isApprovedCustomer]); //eslint-disable-line

        return (
            <Fragment>
                <InviteFriendTaskHeader title='Invite sent' showSubtitle={false} simple={simple} onClose={onClose} />
                <TaskThanksContentStyles>
                    {fromGS ? (
                        <StyledKliText style={BOTTOM_INDENTS.SM} color='primaryText' type='body1' as='div'>
                            We’ve reset your Suite timer and added an additional
                            <Typography.Text
                                color='primary'
                                style={{
                                    display: 'inline-block',
                                    font: 'inherit',
                                    margin: '0 4px'
                                }}
                            >
                                5 Rewards
                            </Typography.Text>
                            for you to choose from
                        </StyledKliText>
                    ) : (
                        <StyledKliText style={BOTTOM_INDENTS.SM} color='primaryText' type='body1' as='div'>
                            Your invite has been sent. We’ll notify you when it’s accepted.
                        </StyledKliText>
                    )}
                    <Button type='primary' size='large' block onClick={fromGS ? onClose : sendAnother}>
                        {fromGS ? (
                            <Typography.Text type='badgeLarge' as='span'>
                                Back to Gifting Suite
                            </Typography.Text>
                        ) : (
                            [
                                <Icon stylePriority size='20px' key='btn-icon' component={() => <MailIcon />} />,
                                <Typography.Text key='btn-text' type='badgeLarge' as='span'>
                                    Send another
                                </Typography.Text>
                            ]
                        )}
                    </Button>
                </TaskThanksContentStyles>
            </Fragment>
        );
    })
);
