import React, { Fragment } from 'react';
import { CouponDetails, CouponTitle } from './styles';

export const KliDeletedCouponContent = ({ title }) => {
    return (
        <Fragment>
            <CouponDetails>
                <CouponTitle level={3} color='primaryText'>
                    {title}
                </CouponTitle>
            </CouponDetails>
        </Fragment>
    );
};
