import { Button } from '@klickly/front-packages';
import styled from 'styled-components';
import { $background } from '../../../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../../../utils';

export const StyledWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${({ $simple }) => ($simple ? '0 24px' : '0 40px')};
`;

export const StyledIconWrap = styled.div`
    width: 120px;
    height: 120px;
    background-color: ${$background};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
`;

export const StyledButton = styled(Button)`
    && {
        padding: 18px 91px;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        margin-bottom: 64px;
    }
`;
