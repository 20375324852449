import React from 'react';
import { BOTTOM_INDENTS, CopyIcon, Icon, RIGHT_INDENTS } from '@klickly/front-packages';
import { StyledButton, StyledContent, StyledLabel, StyledValue, StyledWrap } from './styled';

export const InfoRow = ({ label, value }) => {
    const handleButtonClick = () => {
        return navigator.clipboard.writeText(value);
    };

    return (
        <StyledWrap style={{ ...BOTTOM_INDENTS.S }}>
            <StyledContent>
                <StyledLabel type='descriptor1' style={{ ...RIGHT_INDENTS.XXS }}>
                    {label}:
                </StyledLabel>
                <StyledValue type='descriptor1'>{value}</StyledValue>
            </StyledContent>
            <StyledButton type='unstyled' onClick={handleButtonClick}>
                <Icon size='24px' component={() => <CopyIcon />} />
            </StyledButton>
        </StyledWrap>
    );
};
