import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowChevronForwardIcon, Button, KliIcon, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../../../shared';
import { GRID_POINTS } from '../../../../../../../../utils';
import { Header } from '../Header';
import { ShippingContent, ShippingStyles } from './styles';

export const ShippingModalContainer = inject('store')(
    observer(({ defaultShippingAddress, onClose }) => {
        const navigate = useNavigate();
        const simple = useSimpleInterface(GRID_POINTS.sm);

        const { firstName, lastName, address1, city, zipCode } = defaultShippingAddress || {};
        const titleLine = defaultShippingAddress
            ? 'Shipping was estimated using your default address:'
            : 'Shipping will be estimated using your default address';
        const addressLine = defaultShippingAddress
            ? `${firstName} ${lastName}. ${address1}, ${city}, ${zipCode}`
            : 'No Address set';

        const onChangeDefaultAddress = () => {
            navigate(ROUTE_PATHS.shippingBilling);
            onClose();
        };

        const onAddShippingAddress = () => {
            navigate(ROUTE_PATHS.shippingAdd);
            onClose();
        };

        return (
            <ShippingStyles>
                <Fragment>
                    <Header title='Estimated Shipping' subtitle='' simple={simple} />
                    <ShippingContent>
                        <Typography.Text type='body1' align='center' style={{ marginBottom: '24px' }}>
                            {titleLine}
                        </Typography.Text>
                        <Typography.Text type='badgeLarge' align='center' style={{ marginBottom: '24px' }}>
                            {addressLine}
                        </Typography.Text>
                        {defaultShippingAddress ? (
                            <Typography.Text
                                type='descriptor1'
                                align='center'
                                color='primary'
                                style={{ marginBottom: '24px' }}
                            >
                                Final shipping & taxes will be calculated during checkout
                            </Typography.Text>
                        ) : null}
                        {defaultShippingAddress ? (
                            <Fragment>
                                <Button
                                    type='primary'
                                    size='large'
                                    onClick={onClose}
                                    block
                                    style={{ marginBottom: '16px' }}
                                >
                                    <Typography.Text type='badgeLarge'>Return back</Typography.Text>
                                </Button>
                                <Button type='unstyled' size='large' block onClick={onChangeDefaultAddress}>
                                    <Typography.Text type='badgeMedium'>Change my Default Address</Typography.Text>
                                    <KliIcon>
                                        <ArrowChevronForwardIcon />
                                    </KliIcon>
                                </Button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Button
                                    type='primary'
                                    size='large'
                                    onClick={onAddShippingAddress}
                                    block
                                    style={{ marginBottom: '16px' }}
                                >
                                    <Typography.Text type='badgeLarge'>Add Shipping Address</Typography.Text>
                                </Button>
                                <Button type='unstyled' size='large' block onClick={onClose}>
                                    <Typography.Text type='badgeMedium'>Return back</Typography.Text>
                                </Button>
                            </Fragment>
                        )}
                    </ShippingContent>
                </Fragment>
            </ShippingStyles>
        );
    })
);
