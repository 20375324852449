import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Icon, Input, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS, useModal, VALIDATION_RULES } from '../../../../shared';
import { ErrorField } from '../../../../shared/components/ErrorField';
import OrderUpdatedModalContent from '../SignInForm/components/OrderUpdatedModalContent';
import { StyledRow } from './styled';

const EmailForm = inject('store')(
    observer(({ store, onAfterEmailWasFound, checkoutKey }) => {
        const { checkIfCustomerExists, error, pending } = store.preCheckoutStore;
        const { openModal, closeModal } = useModal();
        const [form] = Form.useForm();
        const navigate = useNavigate();

        const handleOpenModal = (updatedCartId, updatedCheckoutKey) =>
            openModal({
                title: 'Order updated',
                children: (
                    <OrderUpdatedModalContent
                        onCloseModal={closeModal}
                        cartId={updatedCartId}
                        checkoutKey={updatedCheckoutKey}
                    />
                )
            });

        const onSubmit = (data) =>
            checkIfCustomerExists(data, onAfterEmailWasFound, checkoutKey, handleOpenModal, () =>
                navigate(ROUTE_PATHS.blocked)
            );

        return (
            <Form form={form} onFinish={onSubmit} validateTrigger={['onBlur']}>
                <StyledRow>
                    <Col span={24}>
                        <Typography.Text type='body1'>Contact information</Typography.Text>
                    </Col>
                </StyledRow>
                <StyledRow gutter={20}>
                    <Col span={24}>
                        <Form.Item name='email' rules={VALIDATION_RULES.EMAIL}>
                            <Input size='large' showWarningIcon type='email' label='Email' autoComplete='on' />
                        </Form.Item>
                    </Col>
                </StyledRow>
                {error && (
                    <StyledRow>
                        <Col span={24}>
                            <ErrorField error={error} showIcon />
                        </Col>
                    </StyledRow>
                )}
                <StyledRow $marginTop='80px'>
                    <Col span={24} xl={{ span: 12 }}>
                        <Form.Item noStyle shouldUpdate>
                            {() => (
                                <Button
                                    htmlType='submit'
                                    type='primary'
                                    size='large'
                                    block
                                    disabled={
                                        !form.isFieldsTouched(true) ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                >
                                    Continue to shipping
                                </Button>
                            )}
                        </Form.Item>
                    </Col>
                </StyledRow>
                {pending && <Icon.Loader />}
            </Form>
        );
    })
);

export default EmailForm;
