import React, { useEffect, useState } from 'react';
import nextId from 'react-id-generator';
import { Item, ItemValue, Rating } from './styles';

const StarRatingItems = ({ rate, maxRate = 5, onItemClick }) =>
    Array.from(new Array(maxRate).keys()).map((index) => (
        <Item
            size='16px'
            roundedSize='46px'
            roundedOpacity={1}
            roundedColor={rate === index + 1 ? 'primary' : 'background'}
            color={rate === index + 1 ? 'canvas' : 'primaryText'}
            hoverRoundedColor='primary'
            onClick={() => onItemClick(index + 1)}
            key={nextId()}
            component={() => (
                <ItemValue color='inherit' as='span' type='badgeMedium'>
                    {index + 1}
                </ItemValue>
            )}
        />
    ));

export const NumberRating = ({ maxRate, value, onChange, align = 'flex-start' }) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [value, setInputValue]);

    const onRateSelect = (_value) => {
        setInputValue(_value);

        if (typeof onChange !== 'undefined') {
            onChange(_value);
        }
    };

    return (
        <Rating $align={align}>
            <StarRatingItems maxRate={maxRate} rate={inputValue} onItemClick={onRateSelect} />
        </Rating>
    );
};
