import React from 'react';
import { GiftIcon, PointsIcon, Typography } from '@klickly/front-packages';
import { Stepper } from './components/Stepper';
import { Step } from './components/Stepper/components/Step';
import { StyledContent, StyledDivider, StyledHeadline2, StyledTextWrap, StyledWrap } from './styles';

export const PersonalizationHead = ({ simple, step = 1, title, description, button = null }) => {
    return (
        <StyledWrap>
            <Stepper activeStep={step} simple={simple}>
                <Step
                    description='50 points'
                    icon={<PointsIcon />}
                    popoverProps={{
                        popoverTitle: 'What are Giftly points?',
                        popoverContent:
                            'You can spend these bonuses on future purchases. Points that have been credited to you are available in the Rewards section. 100 points = 1 USD',
                        placement: simple ? 'bottomLeft' : 'bottom'
                    }}
                />
                <Step
                    description='50 points'
                    icon={<PointsIcon />}
                    popoverProps={{
                        popoverTitle: 'What are Giftly points?',
                        popoverContent:
                            'You can spend these bonuses on future purchases. Points that have been credited to you are available in the Rewards section. 100 points = 1 USD',
                        placement: 'bottom'
                    }}
                />
                <Step
                    description='1 Gifting Suite'
                    icon={<GiftIcon />}
                    popoverProps={{
                        popoverTitle: 'What is a Gifting Suite?',
                        popoverContent:
                            'Gifting Suites are an exclusive reward for Giftly members. Each Gifting Suite contains up to 10 high-value gifts just for you.',
                        placement: simple ? 'bottomRight' : 'bottom'
                    }}
                />
            </Stepper>
            {simple ? <StyledDivider /> : null}
            <StyledContent>
                <StyledTextWrap>
                    {simple ? (
                        <Typography.Text type='body2' style={{ marginBottom: '4px' }}>
                            Step {step}
                        </Typography.Text>
                    ) : null}
                    <StyledHeadline2 level={2}>{simple ? `${title}` : `Step ${step}. ${title}`}</StyledHeadline2>
                    <Typography.Text>{description}</Typography.Text>
                </StyledTextWrap>
                {button}
            </StyledContent>
        </StyledWrap>
    );
};
