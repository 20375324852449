import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $white } from '../../constants';

export const Styles = styled.div`
    display: flex;
    align-items: center;
    color: ${$white};
`;

export const Text = styled(Typography.Text)`
    width: 100%;
    line-height: 0.8;
    color: ${({ color = 'inherit' }) => color};
`;

export const Title = styled(Typography.Title)`
    width: 100%;
    color: ${({ color = 'inherit' }) => color};

    p {
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
    }
`;
