import { GRID_DEVICES_MAX } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    margin-top: 96px;
    padding: 0 44px;

    @media (${GRID_DEVICES_MAX.md}) {
        margin-top: 40px;
    }

    .anticon {
        cursor: initial;
        justify-content: center;
    }
`;
