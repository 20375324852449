import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $primary } from '../../../../shared';

export const StyledText = styled(Typography.Text)`
    a {
        text-decoration: none;
        color: ${$primary};
    }

    & ul {
        margin-bottom: 10px;
        margin-left: 30px;
        padding: 0;
        list-style-type: disc;
    }

    & ul > li {
        padding-top: 5px;
        line-height: 24px;
    }

    & ul > li:first-child {
        padding-top: 0;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;

        tr td {
            border: ${({ $noTableBorders }) => ($noTableBorders ? '' : '1px solid #4a4a4a')};
            padding: 5px;
        }
    }
`;
