import React from 'react';
import { Icon } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { getColorsByStatus, getIconByAlias } from '../../../../../components/SeasonalChallenge/utils';
import { $primary, TASK_STATUSES } from '../../../../index';
import StepPopover from './components/StepPopover';
import { StyledCol, StyledStep, StyledStepTitle, StyledStepWrapper } from './styled';

const Step = observer(({ status, isLastStep, alias, position, name, background, withTitle }) => {
    const colors = getColorsByStatus(status);
    const StepIcon = getIconByAlias(alias);
    const step = (
        <StyledStep $colors={colors} $withTitle={withTitle}>
            {status === TASK_STATUSES.active && withTitle ? (
                <StyledStepTitle type='badgeSmall' color={$primary}>
                    {name}
                </StyledStepTitle>
            ) : null}
            <Icon size='24px' component={() => <StepIcon />} />
        </StyledStep>
    );
    return (
        <StyledCol {...(isLastStep ? {} : { flex: 'auto' })}>
            <StyledStepWrapper $colors={colors} $isActive={status === TASK_STATUSES.active} $background={background}>
                {status === TASK_STATUSES.completed ? (
                    <StepPopover position={position} name={name} key={name}>
                        {step}
                    </StepPopover>
                ) : (
                    step
                )}
            </StyledStepWrapper>
        </StyledCol>
    );
});

export default Step;
