import styled from 'styled-components';

export const InfoPageStyles = styled.div`
    padding-bottom: 30px;
`;

export const ItemPointWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 22px;
`;

export const StyledIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
`;
