import React from 'react';
import { Icon } from '@klickly/front-packages';
import { iconsMap } from './iconsMap';
import { StyledWrap } from './styled';

export const ChallengeIcon = ({ alias, backgroundSize, backgroundColor, color, size, ...props }) => {
    return (
        <StyledWrap $backgroundSize={backgroundSize} $backgroundColor={backgroundColor} {...props}>
            <Icon component={() => iconsMap[alias]} color={color} size={size} />
        </StyledWrap>
    );
};
