import { Icon } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const StyledIcon = styled(Icon)`
    margin-right: 16px;
`;
