import React from 'react';
import { StyledBorderLeft, StyledBorderRight, StyledWavesBottom, StyledWavesTop, StyledWrap } from './styled';

export const CouponWrapper = ({ children, ...props }) => (
    <StyledWrap {...props}>
        <StyledBorderLeft />
        <StyledWavesTop />
        {children}
        <StyledWavesBottom />
        <StyledBorderRight />
    </StyledWrap>
);
