import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { BOTTOM_INDENTS, Col, Row, Typography } from '@klickly/front-packages';
import { ROUTE_PATHS } from '../../index';
import Footer from '../Footer';
import { LegalHeader } from '../LegalHeader';
import {
    LegalContentBody,
    LegalContentHead,
    LegalContentStyles,
    LegalNavbarLink,
    LegalNavbarWrap,
    LegalStyles,
    StyledHeadLine1,
    StyledHeadLine2
} from './styles';

const LegalNavbar = () => {
    const { pathname } = useLocation();
    const isActivePage = (path) => path === pathname;

    return (
        <Fragment>
            <LegalNavbarLink to={ROUTE_PATHS.betaTerms}>
                <StyledHeadLine2 level={2} active={isActivePage('/help/betaterms')}>
                    Beta Terms
                </StyledHeadLine2>
            </LegalNavbarLink>
            <LegalNavbarLink to={ROUTE_PATHS.terms}>
                <StyledHeadLine2 level={2} active={isActivePage('/help/terms')}>
                    Terms & Conditions
                </StyledHeadLine2>
            </LegalNavbarLink>
            <LegalNavbarLink to={ROUTE_PATHS.privacy}>
                <StyledHeadLine2 level={2} active={isActivePage('/help/privacy')}>
                    Privacy Policy
                </StyledHeadLine2>
            </LegalNavbarLink>
            <LegalNavbarLink to={ROUTE_PATHS.privacyOptOut}>
                <StyledHeadLine2 level={2} active={isActivePage('/help/privacy-opt-out')}>
                    Right to Opt Out of Sale of Information
                </StyledHeadLine2>
            </LegalNavbarLink>
            <LegalNavbarLink to={ROUTE_PATHS.helpPageDisclosureSensitiveInfo}>
                <StyledHeadLine2 level={2} active={isActivePage('/help/disclosure-sensitive-info')}>
                    Right to Limit Use/Disclosure of Sensitive Information
                </StyledHeadLine2>
            </LegalNavbarLink>
        </Fragment>
    );
};

export const LegalContent = ({ title = '', date = '', children }) => {
    return (
        <LegalStyles>
            <LegalHeader />
            <LegalContentStyles>
                <Row>
                    <Col span={24} lg={{ span: 8 }}>
                        <LegalNavbarWrap>
                            <LegalNavbar />
                        </LegalNavbarWrap>
                    </Col>
                    <Col span={24} lg={{ span: 16 }}>
                        <LegalContentHead style={BOTTOM_INDENTS.SM}>
                            <StyledHeadLine1>{title}</StyledHeadLine1>
                            <Typography.Title level={3}>{date}</Typography.Title>
                        </LegalContentHead>
                        <LegalContentBody>{children}</LegalContentBody>
                    </Col>
                </Row>
            </LegalContentStyles>
            <Footer />
        </LegalStyles>
    );
};
