import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import {
    BOTTOM_INDENTS,
    CheckoutPDP,
    CheckoutPDPHTTPWrapper,
    Icon,
    TOP_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { app } from '../../../../../../config';
import { $primary, kcpAPI, ROUTE_PATHS, useRewardAndPointsModal } from '../../../../../../shared';
import { PageHeader } from '../../../../../../shared/components/PageHeader';
import { Timer } from '../../../../../../shared/components/Timer';
import { getMetadata } from '../../../../../../shared/utils/metadata';
import { get, GRID_POINTS } from '../../../../../../utils';
import { remove as removeEl } from '../../../../../../utils/elements/common';
import { loadPixels } from '../../../../../../utils/pixels';
import { SuiteCoupon } from '../SuiteCoupon';
import { ClaimBlock, DetailsWrapper, Divider, DividerWrap, ExtraContentStyles, StyledText, Styles } from './styles';

const CouponDetails = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { account, isLoggedIn } = store.accountStore;
        const { getCouponDetails, selectedCoupon, selectedCouponPending, isPageReloaded } = store.suitePromotionsStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const testMode = account && account.giftly && account.giftly.testMode;
        const couponId = useSearchParam('couponId');

        const { openRewardModal, openPointsModal } = useRewardAndPointsModal({
            isLoggedIn
        });

        const onBeforeAddToCartButtonClick = async (data, trackingData) => {
            const price = get(data, 'prices.price', 0);
            const productExternalId = get(trackingData, 'externalId');
            const itemName = get(trackingData, 'title');
            const qty = get(data, 'qty');
            const pixels = get(trackingData, 'pixels', []);

            store.trackingStore.trackAddToCart(trackingData, { price, qty });

            await store.trackingStore.sendAddToCartPostMessage({
                pixels,
                data: {
                    price,
                    productExternalId,
                    itemName,
                    qty
                },
                delayTime: 500
            });
        };

        const onAddToCartCallback = async () => {
            await store.shoppingCartStore.getShoppingCartState();
            await store.claimsPointsStore.getClaims();
            return navigate(ROUTE_PATHS.shoppingCart);
        };

        const links = {
            productFetch: kcpAPI.giftingSuite.getCouponPromotion(selectedCoupon?._id),
            buyNow: kcpAPI.cart.buyNow,
            cartItemsManage: kcpAPI.cart.itemsManage
        };

        useEffect(() => {
            if (!store.suiteStore.data || store.suiteStore.suiteState !== 'started') {
                removeEl('kl_tw_iframe');
                removeEl('kl_ga4_iframe');
                return navigate(ROUTE_PATHS.suiteGs, { replace: true });
            }

            const removeListener = store.trackingStore.sendGa4PostMessage({
                eventName: 'click',
                iframeId: 'kl_ga4_iframe'
            });

            return () => {
                removeEl('kl_tw_iframe');
                removeEl('kl_ga4_iframe');
                removeListener();
            };
        }, []); //eslint-disable-line

        useEffect(() => {
            if (couponId) {
                getCouponDetails(couponId);
            }
        }, [couponId]);

        const metadata = getMetadata();

        const redirects = {
            continueShoppingUrl: `${app.giftlyUrl}${ROUTE_PATHS.shop}`,
            customThankYouPageUrl: `${app.giftlyUrl}${ROUTE_PATHS.gsThankYouPage}`
        };

        const handleProductFetchSuccess = async (promotion) => {
            // Coupons works as wrapper around promotions
            // On GS use see set of coupons and only after coupon opened product viewed and clicked at the same time
            if (isPageReloaded) {
                await store.trackingStore.trackViewPromotion(promotion);
            }

            if (!promotion.pixels?.length) return;

            loadPixels(promotion.pixels);
        };

        const handleClickThroughClick = (promotion, redirectToBrandLink) => {
            store.trackingStore.trackClick(promotion, redirectToBrandLink);
        };

        return (
            <Styles $simple={simple}>
                {!selectedCouponPending ? (
                    selectedCoupon ? (
                        <Fragment>
                            <CheckoutPDPHTTPWrapper
                                links={links}
                                onClickThroughClick={handleClickThroughClick}
                                onProductFetchSuccess={handleProductFetchSuccess}
                                onBuyNowButtonClick={null}
                                onGoToMarketplaceClick={() => navigate(ROUTE_PATHS.shop)}
                                onGoToShoppingCartClick={() => navigate(ROUTE_PATHS.shoppingCart)}
                                onBeforeAddToCartButtonClick={onBeforeAddToCartButtonClick}
                                onAddToCartButtonClick={onAddToCartCallback}
                                onRewardQuestionClick={openRewardModal}
                                onPointsQuestionClick={(product) => openPointsModal([product])}
                            >
                                <CheckoutPDP
                                    testMode={testMode || false}
                                    customizations={{
                                        showAddedToCartModal: false,
                                        rewards: {
                                            showRewards: true,
                                            color: '#F25858'
                                        },
                                        buttons: {
                                            showBuyNowButton: false,
                                            showAddToCartButtonGiftIcon: true,
                                            addToCartButtonLabel: 'Claim and add to cart'
                                        }
                                    }}
                                    redirects={redirects}
                                    metadata={metadata}
                                    extraContent={
                                        <ExtraContentStyles $simple={simple}>
                                            <PageHeader hasBottomIndent title='Reward details' subTitle='' withGoBack />
                                            <SuiteCoupon
                                                defaultShippingAddress={store.accountStore.defaultShippingAddress}
                                                shippingAddresses={store.accountStore.shippingAddresses}
                                                simple
                                                data={selectedCoupon}
                                                showDescription={!simple}
                                                showButton={false}
                                                additionalDetailsContentOnTop
                                                additionalDetailsContent={
                                                    <DetailsWrapper>
                                                        <Timer
                                                            style={{
                                                                ...BOTTOM_INDENTS.XS,
                                                                ...TOP_INDENTS.XS
                                                            }}
                                                            color={$primary}
                                                            showIcon={false}
                                                            onTimeLeft={null}
                                                            endDate={store.suiteStore.nextCloseAt}
                                                            text='Expires in {time}'
                                                        />

                                                        <ClaimBlock>
                                                            <Typography.Text type='descriptor2' color='primary'>
                                                                Gift Suite Claim
                                                            </Typography.Text>
                                                        </ClaimBlock>
                                                    </DetailsWrapper>
                                                }
                                            />
                                            {simple && selectedCoupon.description ? (
                                                <Typography.Text
                                                    type='body2'
                                                    style={{
                                                        marginTop: '40px'
                                                    }}
                                                >
                                                    {selectedCoupon.description}
                                                </Typography.Text>
                                            ) : null}

                                            <DividerWrap>
                                                <Divider $left $simple={simple} />
                                                <StyledText type='badgeMedium'>Qualifying product</StyledText>
                                                <Divider $right $simple={simple} />
                                            </DividerWrap>
                                        </ExtraContentStyles>
                                    }
                                />
                            </CheckoutPDPHTTPWrapper>
                        </Fragment>
                    ) : (
                        <ExtraContentStyles>
                            <PageHeader hasBottomIndent title='Reward details' subTitle='' />
                            <Typography.Text type='badgeMedium'>No reward information founded</Typography.Text>
                        </ExtraContentStyles>
                    )
                ) : (
                    <Icon.Loader />
                )}
            </Styles>
        );
    })
);

export default CouponDetails;
