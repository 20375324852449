import React, { cloneElement } from 'react';
import {
    InfinityScrollGalleryMove,
    InfinityScrollGalleryMoveItem,
    InfinityScrollGalleryWrap
} from './KliInfinityScrollGallery.styles';

export const KliInfinityScrollGallery = ({ children, speed = 10, height = '100%', stopOnHover = false, ...rest }) => {
    return (
        <InfinityScrollGalleryWrap {...rest} height={height} stopOnHover={stopOnHover}>
            <InfinityScrollGalleryMove speed={speed}>
                {children.map((item, index) => (
                    <InfinityScrollGalleryMoveItem key={item.id || item.key || item.title || Math.random() + index}>
                        {cloneElement(item)}
                    </InfinityScrollGalleryMoveItem>
                ))}
            </InfinityScrollGalleryMove>

            <InfinityScrollGalleryMove speed={speed}>
                {children.map((item, index) => (
                    <InfinityScrollGalleryMoveItem key={item.id || item.key || item.title || Math.random() + index}>
                        {cloneElement(item)}
                    </InfinityScrollGalleryMoveItem>
                ))}
            </InfinityScrollGalleryMove>
        </InfinityScrollGalleryWrap>
    );
};
