import { Button, Icon, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $primary, $tartRed } from '../../../../shared';
import { CouponWrapper } from '../../../../shared/components/CouponWrapper';
import { Timer } from '../../../../shared/components/Timer';

export const StyledGSInfo = styled(CouponWrapper)`
    width: 100%;
    height: auto;
    padding: 40px 24px;

    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 80px;

    ${({ $simple }) =>
        $simple
            ? css`
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  padding: 16px;
                  margin-bottom: 64px;
              `
            : ''}
`;

export const StyledText = styled(Typography.Title)`
    display: inline-block;

    & > span {
        &:not(:last-child) {
            margin-right: 8px;
        }

        color: ${$primary};
    }
`;

export const StyledContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ $simple }) =>
        $simple
            ? css`
                  width: 100%;
                  justify-content: space-around;
              `
            : ''}
`;

export const StyledTaskContent = styled.div`
    display: flex;
    align-items: center;

    ${({ $simple }) =>
        $simple
            ? css`
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
              `
            : ''}
`;

export const StyledButton = styled(Button)`
    &&,
    &&:hover,
    &&:active {
        height: 48px;
        background-color: ${$tartRed};
        border: none;
        margin: 0 20px;

        ${({ $simple }) =>
            $simple
                ? css`
                      margin-left: 0;
                  `
                : ''}
    }
`;

export const StyledBadge = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    min-width: 115px;
`;

export const StyledTextWrap = styled.div``;

export const StyledTimer = styled(Timer)`
    && {
        display: inline-block;

        & > div > p {
            font-weight: 500;
            font-size: 32px;
            line-height: 44px;
        }

        ${({ $simple }) =>
            $simple
                ? css`
                      display: block;

                      & > div > p {
                          text-align: center;
                      }
                  `
                : ''}
    }
`;

export const StyledIcon = styled(Icon)`
    background-color: ${$tartRed};
    border-radius: 50%;
    width: 120px;
    height: 120px;
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
`;

export const StyledTaskIconWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${$primary};
    border-radius: 50%;
    min-width: ${({ $simple }) => ($simple ? '64px' : '120px')};
    width: ${({ $simple }) => ($simple ? '64px' : '120px')};
    height: ${({ $simple }) => ($simple ? '64px' : '120px')};
    min-height: ${({ $simple }) => ($simple ? '64px' : '120px')};
    margin: ${({ $simple }) => ($simple ? '0 0 16px 0' : '0 24px 0 0')};

    & > img {
        width: ${({ $simple }) => ($simple ? '32px' : '60px')};
        height: ${({ $simple }) => ($simple ? '32px' : '60px')};
    }
`;

export const StyledLoaderWrap = styled.div`
    position: relative;
    width: 100%;
    height: 300px;

    ${({ $simple }) =>
        $simple
            ? css`
                  height: 200px;
              `
            : ''}
`;
