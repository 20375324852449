import React, { Fragment, useEffect } from 'react';
import { PointsIcon, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../../../shared/components/Layout';
import Unauthorized from '../../../../shared/components/Unauthorized';
import { GRID_POINTS } from '../../../../utils';
import PageTitle from '../PageTitle';
import RewardsSideBar from '../RewardsSideBar';
import CouponDetails from './components/CouponDetails';
import Coupons from './components/Coupons';

const PromotionsPage = inject('store')(
    observer(({ store }) => {
        const { isLoggedIn, signUpStatusRejected } = store.accountStore;
        const { selectedRewardPromotionId, rewards } = store.rewardsStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const infoTitle = 'What are Coupons?';
        const infoText =
            'Coupons represent exclusive offers and deals on millions of Giftly products. When you see a deal you like, simply “claim it” to save it to your Giftly rewards. We’ll automatically apply rewards you’ve clipped to your next eligible order. Note that all rewards have an expiry, so be sure to use them before they run out!';

        useEffect(() => {
            store.claimsPointsStore.getPoints();
        }, []); //eslint-disable-line

        const getContent = () => {
            if (selectedRewardPromotionId) return <CouponDetails />;

            if (!isLoggedIn || (isLoggedIn && signUpStatusRejected)) {
                return (
                    <Unauthorized
                        title='Rewards'
                        contentTitle='Unlock exclusive offers & deals'
                        description='Complete challenges & access existing rewards'
                        howItWorkBtnText='How do rewards work?'
                        icon={<PointsIcon />}
                    />
                );
            }

            return (
                <Fragment>
                    <PageTitle
                        title='Coupons'
                        tooltipTitle={infoTitle}
                        tooltipText={infoText}
                        showInfoIcon={rewards?.length}
                    />
                    <Coupons />
                </Fragment>
            );
        };

        const content = getContent();

        return (
            <Layout
                hasFooter={!simple}
                sidebar={simple ? null : <RewardsSideBar title={infoTitle} text={infoText} />}
                contentFullHeight
            >
                {content}
            </Layout>
        );
    })
);

export default PromotionsPage;
