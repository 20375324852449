import React from 'react';
import { DIALOG_TRIGGER_IDS } from '../../../shared';
import { ChangeSubscription } from '../components/ChangeSubscription';
import { InviteFriendTask } from '../components/InviteFriendTask';
import { OnPremiumPromotionClaim } from '../components/OnPremiumPromotionClaim';
import { SuiteFeedback } from '../components/SuiteFeedback';
import { WriteProductReviewTask } from '../components/WriteProductReviewTask';

// eslint-disable-next-line
// TODO rewrite as provider or hooks;
export const DIALOGS = Object.freeze({
    [DIALOG_TRIGGER_IDS.INVITE_FRIEND]: {
        component: (props) => <InviteFriendTask {...props} />
    },
    [DIALOG_TRIGGER_IDS.WRITE_PRODUCT_REVIEW]: {
        component: (props) => <WriteProductReviewTask {...props} />
    },
    [DIALOG_TRIGGER_IDS.CHANGE_SUBSCRIPTION]: {
        component: (props) => <ChangeSubscription {...props} />
    },
    [DIALOG_TRIGGER_IDS.CLAIM_PREMIUM_PROMOTION]: {
        component: (props) => <OnPremiumPromotionClaim {...props} />
    },
    [DIALOG_TRIGGER_IDS.SUITE_FEEDBACK]: {
        component: (props) => <SuiteFeedback {...props} />
    }
});
