import React from 'react';
import { BagIcon, GiftIcon, PointsIcon, Typography, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { StyledContent, StyledHeadLine2, StyledIcon, StyledPoint, StyledWrap } from './styles';

export const StepsSection = observer(() => {
    const simple = useSimpleInterface(600);

    return (
        <StyledWrap $simple={simple}>
            <StyledHeadLine2 level={2} $simple={simple}>
                Exclusive deals & gifts for members
            </StyledHeadLine2>
            <StyledContent $simple={simple}>
                <StyledPoint>
                    <StyledIcon size='24px' color='canvas'>
                        <GiftIcon />
                    </StyledIcon>
                    {simple ? (
                        <Typography.Text type='badgeLarge'>1. Apply and choose your first gift</Typography.Text>
                    ) : (
                        <Typography.Title level={2}>1. Apply and choose your first gift</Typography.Title>
                    )}
                </StyledPoint>
                <StyledPoint>
                    <StyledIcon size='24px' color='canvas'>
                        <BagIcon />
                    </StyledIcon>
                    {simple ? (
                        <Typography.Text type='badgeLarge'>2. Shop secret member prices</Typography.Text>
                    ) : (
                        <Typography.Title level={2}>2. Shop secret member prices</Typography.Title>
                    )}
                </StyledPoint>
                <StyledPoint>
                    <StyledIcon size='24px' color='canvas'>
                        <PointsIcon />
                    </StyledIcon>
                    {simple ? (
                        <Typography.Text type='badgeLarge'>3. Earn cash with every purchase</Typography.Text>
                    ) : (
                        <Typography.Title level={2}>3. Earn cash with every purchase</Typography.Title>
                    )}
                </StyledPoint>
            </StyledContent>
        </StyledWrap>
    );
});
