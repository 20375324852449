import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { BOTTOM_INDENTS, Col, Icon, Row, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../shared';
import { Animated } from '../../../../shared/components/Animated';
import Challenge from '../../../../shared/components/Challenge';
import { Layout } from '../../../../shared/components/Layout';
import { GRID_POINTS } from '../../../../utils';
import PageTitle from '../PageTitle';
import RewardsSideBar from '../RewardsSideBar';
import NoChallenges from './components/NoChallenges';

const ChallengesPage = inject('store')(
    observer(({ store }) => {
        const { isLoggedIn, signUpStatusRejected } = store.accountStore;
        const { getTasks, rewardsTasks, pending, hasActiveTasks } = store.tasksStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const infoTitle = 'What are challenges?';
        const infoText =
            'Challenges are tasks you can complete to earn specific rewards, such as Points bonuses, Reward coupons, Gifts, or other items. You can view a list of your challenges in your rewards center. Rest assured that we will automatically credit you with a reward whenever you complete a task, whether or not you activate it.';

        useEffect(() => {
            getTasks(true);
        }, []);

        if (!isLoggedIn || (isLoggedIn && signUpStatusRejected)) {
            return <Navigate to={ROUTE_PATHS.coupons} />;
        }

        return (
            <Layout
                hasFooter
                sidebar={simple ? null : <RewardsSideBar title={infoTitle} text={infoText} />}
                contentFullHeight={!simple}
            >
                <PageTitle title='Challenges' tooltipTitle={infoTitle} tooltipText={infoText} />
                {hasActiveTasks ? null : <NoChallenges />}
                <Animated>
                    <Row gutter={[20, 20]} style={BOTTOM_INDENTS.XL}>
                        {pending && <Icon.Loader />}
                        {rewardsTasks?.length &&
                            rewardsTasks.map((task) =>
                                task?.isHidden ? null : (
                                    <Col key={task._id} xs={24} sm={24} md={8}>
                                        <Challenge task={task} />
                                    </Col>
                                )
                            )}
                    </Row>
                </Animated>
            </Layout>
        );
    })
);

export default ChallengesPage;
