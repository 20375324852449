import React from 'react';
import {
    BOTTOM_INDENTS,
    CommentWithLinesIcon,
    Icon,
    LEFT_INDENTS,
    RIGHT_INDENTS,
    TOP_INDENTS,
    Typography
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $grayScale60Black, $primary, $tartRed } from '../../../../../../shared';
import { StyledButton, StyledContent, StyledText, StyledWrapper } from './styled';

const SuiteFeedback = inject('store')(
    observer(({ store, simple }) => {
        const { openModal } = store.modalsStore;
        return (
            <StyledWrapper $simple={simple} style={simple ? TOP_INDENTS.S : TOP_INDENTS.XL}>
                <StyledContent $simple={simple}>
                    <Icon
                        style={{ ...LEFT_INDENTS.L, ...RIGHT_INDENTS.L }}
                        size={simple ? '32px' : '60px'}
                        roundedSize={simple ? '64px' : '120px'}
                        roundedColor={$tartRed}
                        hoverRoundedColor={$tartRed}
                        color={$primary}
                        roundedOpacity={1}
                        component={() => <CommentWithLinesIcon />}
                    />
                    <StyledText>
                        <Typography.Title
                            align={simple ? 'center' : 'left'}
                            style={BOTTOM_INDENTS.XXS}
                            level={simple ? 2 : 1}
                            color={$primary}
                        >
                            How was your last Gifting Suite?
                        </Typography.Title>
                        <Typography.Title align={simple ? 'center' : 'left'} level={3} color={$grayScale60Black}>
                            We noticed you didn’t claim any offers in your most recent Gifting Suite. Please let us know
                            if there’s anything we can do to improve.
                        </Typography.Title>
                    </StyledText>
                    <StyledButton
                        type='secondary'
                        onClick={() => openModal({ dialogId: 'gs-feedback', fromReward: false })}
                    >
                        <Typography.Text type='badgeSmall' color='primary'>
                            Leave feedback
                        </Typography.Text>
                    </StyledButton>
                </StyledContent>
            </StyledWrapper>
        );
    })
);

export default SuiteFeedback;
