import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowChevronForwardIcon, Icon } from '@klickly/front-packages';
import { Item } from './components/Item';
import { NavItemStyles } from './styles';

export const NavItem = ({ link, children, icon, title, description, ...rest }) => {
    return (
        <NavItemStyles>
            {link ? (
                <Link to={link} {...rest}>
                    <Item icon={icon} title={title} description={description}>
                        {children || <Icon component={() => <ArrowChevronForwardIcon />} />}
                    </Item>
                </Link>
            ) : (
                <Item icon={icon} title={title} description={description} {...rest}>
                    {children || <Icon component={() => <ArrowChevronForwardIcon />} />}
                </Item>
            )}
        </NavItemStyles>
    );
};
