import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BrowserStorage,
    Form,
    ForwardArrowIcon,
    GRID_POINTS,
    Icon,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../shared';
import { ErrorField } from '../../../../shared/components/ErrorField';
import { Layout } from '../../../../shared/components/Layout';
import { PersonalizationHead } from '../../../../shared/components/PersonalizationHead';
import { ShippingAddressFormFields } from '../../../../shared/components/ShippingAddressFormFields';
import { useRedirectToUnfinishedStep } from '../../hooks';
import { ButtonWrap, StyledButton, StyledWrap } from './styles';

const PersonalizationShipping = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const simple = useSimpleInterface(GRID_POINTS.md);
        const [form] = Form.useForm();
        const { addShippingAddress, errorMessage, pending } = store.accountStore;

        const handleAddShippingAddress = (data, onAfterSuccess) => {
            addShippingAddress(data, onAfterSuccess, onShippingErrorCallback);
        };

        const onAddShippingSuccessCallback = () => {
            store.trackingStore.sendEvent({
                type: 'event',
                name: 'Onboarding_Step3_Complete'
            });
            navigate({
                pathname: ROUTE_PATHS.suiteGs,
                search: 'welcomeModal=true'
            });
        };

        const onShippingErrorCallback = () => {
            store.trackingStore.sendEvent({
                type: 'event',
                name: 'Onboarding_Step3_AddressError_ZIP'
            });
        };

        const submitted = (data) => {
            handleAddShippingAddress(data, onAddShippingSuccessCallback);
        };

        useEffect(() => {
            if (!store.tasksStore.tasksExists && !store.tasksStore.pending) store.tasksStore.getTasks();
        }, []);

        useRedirectToUnfinishedStep(store);

        const fields = ['firstName', 'lastName', 'address1', 'city', 'province', 'zipCode', 'phone'];
        const firstName = BrowserStorage.get('customerFirstName');

        return (
            <Layout disabledMenu disableOnLogoClick menuBarHidden>
                <StyledWrap>
                    <Form initialValues={{ firstName }} form={form} onFinish={submitted} validateTrigger={['onBlur']}>
                        <PersonalizationHead
                            step={3}
                            title='Where are you based?'
                            description='Add your shipping address so we only show products available to you'
                            simple={simple}
                            button={
                                !simple ? (
                                    <Form.Item noStyle shouldUpdate>
                                        {() => (
                                            <StyledButton
                                                $simple={simple}
                                                htmlType='submit'
                                                type='primary'
                                                size='large'
                                                disabled={
                                                    !fields
                                                        .filter((field) => (firstName ? field !== 'firstName' : field))
                                                        .map((field) => form.isFieldTouched(field))
                                                        .every((touched) => touched) ||
                                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                }
                                            >
                                                <Typography.Text
                                                    type='badgeLarge'
                                                    style={{
                                                        marginRight: '16px'
                                                    }}
                                                >
                                                    Proceed to reward
                                                </Typography.Text>
                                                <Icon
                                                    stylePriority
                                                    size='24px'
                                                    component={() => <ForwardArrowIcon />}
                                                />
                                            </StyledButton>
                                        )}
                                    </Form.Item>
                                ) : null
                            }
                        />
                        <ShippingAddressFormFields form={form} />
                        {errorMessage ? (
                            <Fragment>
                                <ErrorField hasBottomIndent error={errorMessage} />
                            </Fragment>
                        ) : null}
                        {pending && <Icon.Loader />}

                        <Form.Item noStyle shouldUpdate>
                            {() => (
                                <ButtonWrap>
                                    <StyledButton $simple={simple} htmlType='submit' type='primary' size='large'>
                                        <Typography.Text
                                            data-e2e='onboarding_proceed-to-reward-button'
                                            type='badgeLarge'
                                            style={{ marginRight: '16px' }}
                                        >
                                            Proceed to reward
                                        </Typography.Text>
                                        <Icon stylePriority size='24px' component={() => <ForwardArrowIcon />} />
                                    </StyledButton>
                                </ButtonWrap>
                            )}
                        </Form.Item>
                    </Form>
                </StyledWrap>
            </Layout>
        );
    })
);

export default PersonalizationShipping;
