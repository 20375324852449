import { Button } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../utils';

export const Styles = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    position: relative;
    max-width: 1085px;
    margin: 0 auto;
`;

export const CloseCheckoutButtonWrap = styled.div`
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const WrapStyles = styled.div`
    width: 100%;
    height: ${({ $withoutHeader }) => ($withoutHeader ? '100%' : 'calc(100% - 64px)')};
`;

export const StyledButton = styled(Button)`
    && {
        padding: 0 !important;

        @media (${GRID_DEVICES_MAX.md}) {
            padding-left: 24px !important;
        }

        .anticon > svg {
            font-size: 24px;
        }
    }
`;

export const StyledButtonWrap = styled.div`
    display: flex;
`;
