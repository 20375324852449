import React, { Fragment } from 'react';
import { BOTTOM_INDENTS, Col, Form, Icon, Input, Row, Select, TOP_INDENTS, Typography } from '@klickly/front-packages';
import { VALIDATION_RULES } from '../../../../../../../../../../../../../../../../../../shared';
import { ErrorField } from '../../../../../../../../../../../../../../../../../../shared/components/ErrorField';
import { selectorData } from './constants';
import { ButtonsWrap, StyledButton } from './styles';

export const CancelOrderForm = ({ orderId, onSubmit, pending, errorMessage, onClose }) => {
    const [form] = Form.useForm();

    const submitted = (data) => {
        if (typeof onSubmit !== 'undefined') {
            onSubmit({ ...data, orderId, onClose });
        }
    };

    return (
        <Form form={form} onFinish={submitted} validateTrigger={['onBlur']}>
            <Row gutter={20}>
                <Col span={24}>
                    <Typography.Text style={BOTTOM_INDENTS.SM}>Please set cancellation reason</Typography.Text>
                    <Form.Item
                        name='cancellationReason'
                        rules={VALIDATION_RULES.REQUIRED}
                        data-e2e='giftly-cancel-reason-drop-down'
                    >
                        <Select
                            data-e2e='giftly-cancel-reason-drop-down-item'
                            size='large'
                            label='Select cancellation reason*'
                            options={selectorData}
                        />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => {
                            const isRequired = form.getFieldValue('cancellationReason') === 'Other';
                            return (
                                <Form.Item
                                    name='reply'
                                    rules={[
                                        ...(isRequired ? VALIDATION_RULES.REQUIRED : []),
                                        {
                                            min: 10,
                                            message: 'This field is required min 3 words (10 characters)'
                                        }
                                    ]}
                                >
                                    <Input.TextArea label={`Share a reply (min 3 words)${isRequired ? '*' : ''}`} />
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </Col>
            </Row>
            {errorMessage && (
                <Fragment>
                    <ErrorField hasBottomIndent error={errorMessage} />
                </Fragment>
            )}
            <ButtonsWrap style={TOP_INDENTS.SM}>
                <StyledButton type='secondary' onClick={onClose} data-e2e='giftly-cancel-modal2-close-btn'>
                    <Typography.Text type='badgeLarge'>Close</Typography.Text>
                </StyledButton>
                <StyledButton type='primary' htmlType='submit' data-e2e='giftly-cancel-modal2-confirm-btn'>
                    <Typography.Text type='badgeLarge'>Confirm</Typography.Text>
                </StyledButton>
            </ButtonsWrap>
            {pending && <Icon.Loader />}
        </Form>
    );
};
