import React from 'react';
import { NavLink } from 'react-router-dom';
import {
    CartIcon,
    CommentWithLinesIcon,
    Divider,
    HomeIcon,
    LogOutSquareIcon,
    PointsIcon,
    ProfileIcon
} from '@klickly/front-packages';
import CategoriesMenu from '../components/CategoriesMenu';
import { MenuLink } from '../components/MenuLink';
import { ROUTE_PATHS } from '../constants';

export const getMenuItemsDesktop = ({ store, onLogout, isLoggedIn, isApprovedCustomer }) => [
    {
        label: <NavLink to={ROUTE_PATHS.dashboard}>Home</NavLink>,
        icon: <HomeIcon />,
        key: 'Home-key',
        isHidden: !isApprovedCustomer
    },
    {
        label: <MenuLink.Dropdown>Shop</MenuLink.Dropdown>,
        icon: <CartIcon />,
        key: 'Shop-key',
        popupClassName: 'kli-main-menu-dropdown kli-shop-dropdown',
        children: [
            {
                label: <CategoriesMenu />,
                key: 'CategoriesMenu-key'
            }
        ]
    },
    {
        label: <MenuLink.Dropdown>Rewards</MenuLink.Dropdown>,
        icon: <PointsIcon />,
        key: 'Rewards-root-key',
        popupClassName: 'kli-main-menu-dropdown',
        children: [
            {
                label: <NavLink to={ROUTE_PATHS.suiteGs}>Gifting Suite</NavLink>,
                key: 'GiftingSuite-key'
            },
            {
                label: (
                    <NavLink
                        to={ROUTE_PATHS.coupons}
                        onClick={() =>
                            store.trackingStore.sendEvent({
                                type: 'event',
                                name: 'RewardsPage_GoToRewards'
                            })
                        }
                    >
                        Coupons
                    </NavLink>
                ),
                key: 'Coupons-key',
                isHidden: !isLoggedIn || (isLoggedIn && store.accountStore.signUpStatusRejected)
            },
            {
                label: (
                    <NavLink
                        to={ROUTE_PATHS.challenges}
                        onClick={() =>
                            store.trackingStore.sendEvent({
                                type: 'event',
                                name: 'RewardsPage_GoToChallenges'
                            })
                        }
                    >
                        Challenges
                    </NavLink>
                ),
                key: 'Challenges-key',
                isHidden: !isLoggedIn || (isLoggedIn && store.accountStore.signUpStatusRejected)
            },
            {
                label: (
                    <NavLink
                        to={ROUTE_PATHS.seasonalChallenge}
                        onClick={() =>
                            store.trackingStore.sendEvent({
                                type: 'event',
                                name: 'RewardsPage_GoToSeasonalChallenge'
                            })
                        }
                    >
                        {store.seasonalChallengeStore?.seasonalChallenge?.name}
                    </NavLink>
                ),
                key: 'SeasonalChallenge-key',
                isHidden: !isLoggedIn || (isLoggedIn && store.accountStore.signUpStatusRejected)
            },
            {
                label: <NavLink to={ROUTE_PATHS.inviteAndEarn}>Invite & Earn</NavLink>,
                key: 'InviteEarn-key'
            },
            {
                label: <NavLink to={ROUTE_PATHS.points}>Points</NavLink>,
                key: 'PointsPage-key',
                isHidden: !isLoggedIn || (isLoggedIn && store.accountStore.signUpStatusRejected)
            }
        ]
    },
    {
        label: <MenuLink.Dropdown>Profile</MenuLink.Dropdown>,
        icon: <ProfileIcon />,
        key: 'Profile-root-key',
        isHidden: !isLoggedIn,
        popupClassName: 'kli-main-menu-dropdown',
        children: [
            {
                key: 'MyProfileTitle-key',
                label: <NavLink to={ROUTE_PATHS.account}>My profile</NavLink>
            },
            {
                label: <NavLink to={ROUTE_PATHS.wishlist}>Wishlist</NavLink>,
                key: 'Wishlist-key'
            },
            {
                label: <NavLink to={ROUTE_PATHS.orders}>Order history</NavLink>,
                key: 'OrderHistory'
            },
            {
                label: <NavLink to={ROUTE_PATHS.shippingBilling}>Shipping and billing</NavLink>,
                key: 'ShippingAndBilling-key'
            },
            {
                label: <NavLink to={ROUTE_PATHS.favoriteCategories}>Favorite categories</NavLink>,
                key: 'FavoriteCategories-key'
            },
            {
                label: <NavLink to={ROUTE_PATHS.notifications}>Notifications</NavLink>,
                key: 'Notifications-key'
            },
            {
                label: <NavLink to={ROUTE_PATHS.extensionSettings}>Extension settings</NavLink>,
                key: 'ExtensionSettings-key'
            },
            {
                key: 'ExtensionSettingsDivider-key',
                label: <Divider margin='0 0 8px' />,
                className: 'kli-non-menu-item'
            },
            {
                label: <NavLink to={ROUTE_PATHS.sendFeedback}>Send feedback</NavLink>,
                key: 'SendFeedback-key',
                icon: <CommentWithLinesIcon />
            },
            {
                label: <MenuLink noRedirect onClick={onLogout} label='Sign out' />,
                key: 'SignOut-key',
                icon: <LogOutSquareIcon />
            }
        ]
    }
];
