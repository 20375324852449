import React from 'react';
import { KliHero } from '@klickly/front-packages';
import { HeaderStyles } from './styles';

export const Header = ({ title = '', subtitle = '', simple, onClose }) => {
    return (
        <HeaderStyles>
            <KliHero
                style={{ justifyContent: 'center' }}
                title={title}
                subTitle={subtitle}
                backgroundColor='primaryBackground'
                color='primary'
                textAlign='center'
                simple={false}
                titleOnTop
                withoutBorders
                onBack={simple ? onClose : null}
            />
        </HeaderStyles>
    );
};
