import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { notification, useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../utils';
import NotificationContent from '../components/NotificationContent';

export const useProductReviewNotification = (store, message, currentTab = 'reviews') => {
    const { isApprovedCustomer } = store.accountStore;
    const { setActiveChallengeName } = store.marketplaceStore;
    const { state } = useLocation();
    const challenge = store.tasksStore?.writeProductReviewTask;
    const isChallenge =
        state?.alias === challenge?.alias &&
        isApprovedCustomer &&
        challenge?.data?.status === 'active' &&
        currentTab === 'reviews';
    const simple = useSimpleInterface(GRID_POINTS.md);

    useEffect(() => {
        if (isChallenge) {
            setActiveChallengeName(challenge?.alias);
            if (typeof notification.destroy === 'function') notification.destroy();
            store.trackingStore.trackChallengeNotification(challenge, '1', message);
            notification.open({
                key: challenge?.alias,
                description: <NotificationContent.Reward rewardCount={50} message={message} />,
                duration: null,
                bottom: simple ? '102px' : '8px'
            });
        }
        return () => (typeof notification.destroy === 'function' ? notification.destroy() : null);
    }, [isChallenge]); //eslint-disable-line
};
