import React from 'react';
import { ModalProvider } from '../UiLibrary';
import { AppContainer } from './components/AppContainer';

export function App(props) {
    return (
        <ModalProvider>
            <AppContainer {...props} />
        </ModalProvider>
    );
}
