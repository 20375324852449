import React from 'react';
import { Link } from 'react-router-dom';
import { GRID_POINTS, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { $primaryText } from '../../../../shared';
import { ApplyNow } from '../../../../shared/components/ApplyNow';
import { Logo } from '../../../../shared/components/Logo';
import { Styles } from './styles';

export const AuthHeader = observer(() => {
    const simple = useSimpleInterface(GRID_POINTS.lg);

    return (
        <Styles>
            {simple ? (
                <Link to='/'>
                    <Logo size='32px' color={$primaryText} />
                </Link>
            ) : (
                <ApplyNow />
            )}
        </Styles>
    );
});
