import styled, { css } from 'styled-components';

export const Styles = styled.div`
    padding: 0 10px;
    transition: left 0.3s ease-out;

    ${({ simple, isOpenOnMobile, mobileHeight }) =>
        simple
            ? css`
                  width: 100%;
                  height: ${mobileHeight}px;
                  position: absolute;
                  bottom: 0;
                  left: ${!isOpenOnMobile ? '-100%' : '0'};
                  background: white;
                  overflow: auto;
              `
            : ''}
`;
