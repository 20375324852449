import { BrowserStorage } from '@klickly/front-packages';
import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI, ROUTE_PATHS } from '../shared';
import { get, GIFTLY_CUSTOMER_BLOCKED_BY_IP_OR_EMAIL, selectCredentials } from '../utils';

export const OnboardingStore = types
    .model('OnboardingStore', {
        onboardingEmail: types.maybeNull(types.string),
        pending: false,
        personalizationPending: false,
        errorMessage: types.maybeNull(types.string),
        inviteCodeErrorMessage: types.maybeNull(types.string),
        instagramErrorMessage: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const setPending = (pending) => {
            self.pending = pending;
        };

        const setError = (error) => {
            if (error && error.response && error.response.data) {
                self.errorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.errorMessage = error.message;
            }
        };

        const setInviteCodeErrorMessage = (error) => {
            if (error && error.response && error.response.data) {
                self.inviteCodeErrorMessage = error.response.data.message;
            }
        };

        const setInstagramErrorMessage = (error) => {
            if (error && error.response && error.response.data) {
                self.instagramErrorMessage = error.response.data.message;
            }
        };

        const clearError = () => {
            self.errorMessage = null;
        };

        const setOnboardingEmail = (email) => {
            self.onboardingEmail = email;
        };

        const onErrorMessage = () => {
            self.root.trackingStore.sendEvent({
                type: 'event',
                name: 'error',
                data: { message: self.errorMessage }
            });
        };

        const signUp = flow(function* signUp(data) {
            clearError();
            setPending(true);
            try {
                if (self.root.thankYouPageStore.orderId) data.orderId = self.root.thankYouPageStore.orderId;
                const { customer } = yield self.root.post(kcpAPI.auth.signUp, data);
                yield self.root.accountStore.getAccountData();
                yield self.root.tasksStore.getTasks();
                BrowserStorage.set('customerFirstName', data.firstName);
                self.root.accountStore.setAccountData(customer);
                self.root.trackingStore.sendEvent({
                    type: 'event',
                    name: 'SignUp_FirstStep_Complete'
                });
                self.root.routeNavigate(ROUTE_PATHS.connectInstagram); // to next onboarding step
            } catch (e) {
                setError(e);
                onSignUpError(e);
            } finally {
                setPending(false);
            }
        });

        const onSignUpError = (error) => {
            if (error?.response?.data?.message === GIFTLY_CUSTOMER_BLOCKED_BY_IP_OR_EMAIL) {
                self.root.accountStore.setBlockCustomer(true);
                self.root.routeNavigate(ROUTE_PATHS.blocked);
            }

            if (error?.response?.data?.message === 'This account already exists. Please sign in instead') {
                self.root.trackingStore.sendEvent({
                    type: 'event',
                    name: 'SignUp_FirstStep_AccountExistError'
                });
            }
        };

        const connectSocialAccounts = flow(function* connectSocialAccounts({
            inviteCode,
            instagram,
            facebook,
            tiktok
        }) {
            try {
                const data = { inviteCode, ...selectCredentials() };

                if (instagram.username)
                    data.instagram = {
                        username: instagram.username.replace(/\s+/g, '').replace('@', '')
                    };
                if (facebook.username) data.facebook = facebook;
                if (tiktok.username) data.tiktok = tiktok;

                const customer = yield self.root.post(kcpAPI.customer.applyFit, data);
                self.root.accountStore.setAccountData(customer);
            } catch (e) {
                setInstagramErrorMessage(e);
                return e;
            }
        });

        const saveInviteCode = flow(function* saveInviteCode({ inviteCode, instagram, facebook, tiktok }) {
            self.inviteCodeErrorMessage = null;
            setPending(true);
            try {
                let codeIsValid = true;

                if (inviteCode) {
                    const data = yield self.root.get(kcpAPI.inviteCode.check(inviteCode));
                    codeIsValid = data.isValid;
                }

                if (codeIsValid) {
                    const error = yield connectSocialAccounts({
                        inviteCode,
                        instagram,
                        facebook,
                        tiktok
                    });

                    if (!error) {
                        localStorage.removeItem('giftly-invite-code');
                        self.root.trackingStore.sendEvent({
                            type: 'event',
                            name: 'SignUp_SecondStep_Complete'
                        });
                        self.root.routeNavigate(ROUTE_PATHS.processing);
                        self.root.seasonalChallengeStore.getSeasonalChallenge();
                    }
                }
            } catch (e) {
                setInviteCodeErrorMessage(e);
                onErrorMessage();
            } finally {
                setPending(false);
            }
        });

        const applyInviteCode = flow(function* applyInviteCode(inviteCode) {
            clearError();
            setPending(true);
            try {
                const data = yield self.root.post(kcpAPI.inviteCode.submit, inviteCode);

                if (data?.inviteCodeValid) {
                    yield self.root.accountStore.getAccountData();
                    self.root.routeNavigate(ROUTE_PATHS.approved);
                }
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
            }
        });

        const sendPersonalizations = flow(function* sendPersonalizations({ body, callbackOnSuccess }) {
            clearError();
            setPending(true);
            try {
                yield self.root.put(kcpAPI.customer.giftlyUpdate, body);

                if (typeof callbackOnSuccess === 'function') {
                    callbackOnSuccess();
                }
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
            }
        });

        const sendPersonalizationInterest = flow(function* sendPersonalizationInterest(data) {
            const body = { selectedInterestToCustomer: data };
            yield sendPersonalizations({
                body,
                callbackOnSuccess: () => self.root.routeNavigate(ROUTE_PATHS.personalizationCategories)
            });
        });

        const sendPersonalizationCategories = flow(function* sendPersonalizationCategories(data) {
            const body = { selectedCategories: data };
            yield sendPersonalizations({
                body,
                callbackOnSuccess: () => self.root.routeNavigate(ROUTE_PATHS.personalizationImportance)
            });
        });

        const sendPersonalizationImportance = flow(function* sendPersonalizationImportance(data) {
            const body = { selectedImportantToCustomer: data };
            const shippingExists = self.root.accountStore.shippingAddresses.length;

            yield sendPersonalizations({
                body,
                callbackOnSuccess: !shippingExists
                    ? () => self.root.routeNavigate(ROUTE_PATHS.personalizationShipping)
                    : onSendPersonalizationsSuccess
            });
        });

        const sendZipCode = flow(function* sendZipCode(zipCode) {
            yield sendPersonalizations({
                body: { zipCode },
                callbackOnSuccess: onSendPersonalizationsSuccess
            });
        });

        const onSendPersonalizationsSuccess = flow(function* onSendPersonalizationsSuccess() {
            yield self.root.accountStore.getAccountData();
        });

        const verifyInviteCode = flow(function* verifyInviteCode(inviteCode) {
            try {
                self.pending = true;
                return yield self.root.get(kcpAPI.inviteCode.check(inviteCode));
            } catch (error) {
                self.errorMessage = error.response.data.message;
            } finally {
                self.pending = false;
            }
        });

        const addSelectedProductsToWishlist = flow(function* addSelectedProductsToWishlist({
            products,
            onComplete = () => null
        }) {
            try {
                self.personalizationPending = true;
                const { completeTask, addToWishlistTask } = self.root.tasksStore;
                const taskId = addToWishlistTask?._id;
                const isAddToWishlistTaskActive = addToWishlistTask?.data?.status === 'active';
                const wishlists = products.map(({ productId: promotion, variantId }) => ({ promotion, variantId }));
                const productId = get(wishlists, '[0].promotion', null);

                yield self.root.accountStore.updateAccountWishlist({ wishlists });

                // complete task
                if (isAddToWishlistTaskActive && taskId && productId) {
                    yield completeTask({
                        taskPayload: { productId },
                        taskId,
                        taskCompleted: isAddToWishlistTaskActive
                    });
                }
                // update SC counter
                yield self.root.shoppingCartStore.getShoppingCartState();
            } catch (error) {
                // nothing
            } finally {
                self.personalizationPending = false;
                onComplete();
            }
        });

        return {
            setOnboardingEmail,
            signUp,
            saveInviteCode,
            applyInviteCode,
            sendPersonalizationInterest,
            sendPersonalizationImportance,
            sendPersonalizationCategories,
            sendZipCode,
            verifyInviteCode,
            clearError,

            addSelectedProductsToWishlist
        };
    });
