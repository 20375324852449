import { Col } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $white } from '../../../../../../shared';

export const StyledCol = styled(Col)`
    &:last-child {
        flex: none !important;
    }
`;

export const StyledStepWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 50px;
    cursor: pointer;

    &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;

        border-bottom: solid 28px ${$white};
        border-top: solid 28px transparent;
        border-left: solid 28px transparent;
        border-right: solid 28px transparent;

        ${({ $isActive }) =>
            $isActive &&
            css`
                content: '';
            `};
    }

    &:after {
        content: '';
        position: absolute;
        top: 26px;
        left: 56px;
        width: calc(100% - 56px);
        height: 4px;
        background: ${({ $colors }) => $colors.progressColor};
        z-index: 1;
    }
`;

export const StyledStep = styled.div`
    ${({ $colors }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        border: 4px solid ${$colors.borderColor};
        border-radius: 50%;
        background: ${$colors.background};
        z-index: 2;

        .anticon {
            color: ${$colors.color};
        }
    `};
`;
