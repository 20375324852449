import React from 'react';
import { Icon, NotificationBellIcon, Switch } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { Animated } from '../../../../shared/components/Animated';
import { NavItem } from '../../../../shared/components/NavItem';
import { PageHeader } from '../../../../shared/components/PageHeader';
import { StyledContent } from './styles';

const NotificationsPage = inject('store')(
    observer(({ store }) => {
        const { hasMarketingEmailSubscription, updateMarketingEmailSubscription } = store.accountStore;

        return (
            <Animated style={{ maxWidth: '845px', margin: 'auto' }}>
                <PageHeader hasBottomIndent title='Notifications' subTitle='Account' />
                <StyledContent>
                    <NavItem
                        icon={<Icon component={() => <NotificationBellIcon />} />}
                        title='Receive exclusive offers'
                    >
                        <Switch
                            onChange={(selected) => updateMarketingEmailSubscription('marketing', selected)}
                            checked={hasMarketingEmailSubscription}
                            checkedColor='primary'
                        />
                    </NavItem>
                </StyledContent>
            </Animated>
        );
    })
);

export default NotificationsPage;
