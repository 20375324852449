import React from 'react';
import { Col, Row } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { useConfirmDeletingModal } from '../../../../../../../../shared/components/ConfirmDeletingModal/hooks';
import { ShippingItemControls } from '../../../ShippingItemControls';
import { ShippingItemPayment } from './components/ShippingItemPayment';
import { Styles } from './styles';

export const BillingItem = observer(({ payment, onAsDefault, onDelete, onEdit, showDeleteButton, simple, ...rest }) => {
    const confirmDeleting = useConfirmDeletingModal({
        title: 'Delete billing',
        bodyText: 'Are you sure you want to delete this billing information?',
        onConfirm: onDelete
    });

    const onBillingItemClick = () => {
        if (simple) {
            return onEdit();
        }

        if (!payment.isDefault) {
            return onAsDefault();
        }
    };

    return (
        <Row {...rest}>
            <Col span={24} md={{ span: 12 }}>
                <Styles $simple={simple} onClick={onBillingItemClick}>
                    <ShippingItemPayment payment={payment} simple={simple} />
                </Styles>
            </Col>
            <Col span={24} md={{ span: 12 }}>
                {!simple && (
                    <ShippingItemControls
                        onDelete={confirmDeleting}
                        onEdit={onEdit}
                        showDeleteButton={showDeleteButton}
                        simple={simple}
                    />
                )}
            </Col>
        </Row>
    );
});
