import React, { useCallback } from 'react';
import { notification, useModal, useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../../../../../../../utils';
import { SuccessModal } from './index';

export const useSuccessModal = (onCloseModalCallback = () => null) => {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    const { setModal, hideModal } = useModal();

    const onClose = () => {
        onCloseModalCallback();
        hideModal();
        if (typeof notification.destroy === 'function') notification.destroy();
    };

    return useCallback(() => {
        setModal(<SuccessModal onClose={onClose} simple={simple} />, {
            padding: '0',
            closable: false,
            fullscreen: simple
        });
    }, [setModal, onClose, simple]);
};
