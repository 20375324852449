import React from 'react';
import { Button, Image } from '@klickly/front-packages';
import styled from 'styled-components';
import Nutrition from '../../../../assets/img/landing/brands/310Nutrition.svg';
import Briogeo from '../../../../assets/img/landing/brands/Briogeo.svg';
import FourSigmatic from '../../../../assets/img/landing/brands/FourSigmatic.svg';
import MyIntent from '../../../../assets/img/landing/brands/MyIntent.svg';
import NatureLab from '../../../../assets/img/landing/brands/NatureLab.svg';
import Spongelle from '../../../../assets/img/landing/brands/Spongelle.svg';
import Usual from '../../../../assets/img/landing/brands/Usual.svg';
import Vegamour from '../../../../assets/img/landing/brands/Vegamour.svg';
import YourSuper from '../../../../assets/img/landing/brands/YourSuper.svg';
import { $primary, $white } from '../../../../shared';

export const StyledWrap = styled.div`
    width: 100%;
    background-color: ${$primary};
    padding: 32px 24px 54px;
    margin-bottom: 88px;
`;

export const StyledBrandsImgWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;

export const StyledImageWrap = styled(Image)`
    width: auto;
    object-fit: contain;
    margin: 0 25px 25px 0;
`;

export const StyledButton = styled(Button)`
    && {
        width: 413px;
        background-color: transparent;
        color: ${$white};
        border-color: ${$white};
        margin: 0 auto;

        &:hover:not(:disabled):not(:active) {
            background-color: ${$white};
            color: ${$primary};
            border-color: ${$primary};
        }

        @media (max-width: 445px) {
            width: 100%;
        }
    }
`;

export const FourSigmaticImage = () => (
    <StyledImageWrap style={{ height: '24px' }} src={FourSigmatic} alt='FourSigmatic' />
);
export const MyIntentImage = () => <StyledImageWrap style={{ height: '44px' }} src={MyIntent} alt='MyIntent' />;
export const NatureLabImage = () => <StyledImageWrap style={{ height: '39px' }} src={NatureLab} alt='NatureLab' />;
export const YourSuperImage = () => <StyledImageWrap style={{ height: '38px' }} src={YourSuper} alt='YourSuper' />;
export const NutritionImage = () => <StyledImageWrap style={{ height: '53px' }} src={Nutrition} alt='310 Nutrition' />;
export const UsualImage = () => <StyledImageWrap style={{ height: '45px' }} src={Usual} alt='Usual' />;
export const VegamourImage = () => <StyledImageWrap style={{ height: '19px' }} src={Vegamour} alt='Vegamour' />;
export const BriogeoImage = () => <StyledImageWrap style={{ height: '25px' }} src={Briogeo} alt='Briogeo' />;
export const SpongelleImage = () => <StyledImageWrap style={{ height: '22px' }} src={Spongelle} alt='Spongelle' />;
