import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { getGsRouteComponentByState, SUITE_STATES } from '../../utils';

const SuiteByState = inject('store')(
    observer(({ store }) => {
        const { suiteState } = store.suiteStore;
        const { sendMessage } = store.chromeExtensionStore;

        const [state, setState] = useState(suiteState);

        useEffect(() => {
            if (suiteState !== SUITE_STATES.closed && suiteState !== state) {
                setState(suiteState);
            }
        }, [suiteState, state, setState]);

        useEffect(() => {
            sendMessage({
                action: 'giftlySuiteStateChanged',
                data: {
                    state: suiteState === SUITE_STATES.started ? 'started' : 'closed'
                }
            });
        }, [suiteState, sendMessage]);

        return getGsRouteComponentByState(state);
    })
);

export default SuiteByState;
