import { Button, Image, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $primary, $white } from '../../../../shared';
import { CouponWrapper } from '../../../../shared/components/CouponWrapper';

export const StyledCouponWrapper = styled(CouponWrapper)`
    max-width: 880px;
    width: 100%;
    min-height: 322px;
    background-color: ${$white};
    padding: 40px 60px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    ${({ $simpleTablet }) =>
        $simpleTablet
            ? css`
                  flex-direction: column;
                  align-items: center;
              `
            : ''}

    ${({ $simple }) =>
        $simple
            ? css`
                  padding: 60px 24px;
              `
            : ''}
`;

export const StyledContent = styled.div`
    z-index: 5;
`;

export const StyledImage = styled(Image)`
    width: 245px;
    height: 245px;
    object-fit: contain;
    margin-right: 40px;

    ${({ $simpleTablet }) =>
        $simpleTablet
            ? css`
                  margin: 0 0 20px 0;
              `
            : ''}
`;

export const StyledTitle = styled(Typography.Title)`
    && {
        margin-bottom: 24px;

        & > span {
            &:not(:last-child):not(:first-child) {
                margin: 0 6px;
            }

            &:nth-child(3) {
                text-decoration: line-through;
            }

            &:nth-child(4) {
                color: ${$primary};
            }
        }

        ${({ $simpleTablet }) =>
            $simpleTablet
                ? css`
                      margin-bottom: 40px;
                  `
                : ''}
    }
`;

export const StyledDescription = styled(Typography.Text)`
    margin-bottom: 4px;
`;

export const StyledShowMoreButton = styled(Button)`
    && {
        &,
        &:hover,
        &:focus,
        &:active {
            display: inline-block;
            border: none;
            outline: none;
            padding-left: 6px;
        }
    }
`;
