import { Button, Icon } from '@klickly/front-packages';
import styled from 'styled-components';
import { $primary } from '../../../../shared';

export const StyledButton = styled(Button)`
    &&& {
        background-color: ${$primary};
        padding: 8px;
        position: absolute;
        top: -26px;
        right: 24px;
    }
`;

export const StyledIcon = styled(Icon)``;
