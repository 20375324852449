import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowChevronForwardIcon, Button, Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { ROUTE_PATHS } from '../../index';
import NoRewardsAvailable from './components/NoRewardsAvailable';
import Reward from './components/Reward';
import { StyledRewardsWrapper, StyledWrapper } from './styled';

const RewardsModalContent = inject('store')(
    observer(
        ({ uuid, store, variant, onCloseModal, notAvailableProps, notLoggedInRedirectUrl = ROUTE_PATHS.signIn }) => {
            const navigate = useNavigate();
            const { closePDP } = store.pdpStore;
            const { isLoggedIn } = store.accountStore;
            const { resetErrors, applyRewardError } = store.rewardsStore;
            const filteredRewards = variant?.rewards?.length
                ? variant.rewards.filter((reward) => reward.appliedTo !== 'taxes')
                : [];

            const getButtonText = () => {
                if (isLoggedIn) return 'Learn more';
                if (!isLoggedIn && !filteredRewards.length) return 'Log in';
            };

            return (
                <StyledWrapper>
                    {!filteredRewards.length ? (
                        <NoRewardsAvailable
                            isLoggedIn={isLoggedIn}
                            applyRewardError={applyRewardError}
                            {...notAvailableProps}
                        />
                    ) : (
                        <StyledRewardsWrapper>
                            {filteredRewards
                                .filter(({ expireAt }) => !expireAt || moment(expireAt).valueOf() > moment().valueOf())
                                .map((reward) => (
                                    <Reward reward={reward} key={`${reward.title}${reward.amount}${reward._id}`} />
                                ))}
                        </StyledRewardsWrapper>
                    )}
                    {!isLoggedIn && filteredRewards.length > 0 ? null : (
                        <Button
                            size='large'
                            type='unstyled'
                            onClick={() => {
                                onCloseModal();
                                closePDP();
                                resetErrors();
                                return navigate(isLoggedIn ? ROUTE_PATHS.rewardsInfo : notLoggedInRedirectUrl, {
                                    state: { uuid }
                                });
                            }}
                        >
                            <span>{getButtonText()}</span>
                            <Icon stylePriority component={() => <ArrowChevronForwardIcon />} />
                        </Button>
                    )}
                </StyledWrapper>
            );
        }
    )
);

export default RewardsModalContent;
