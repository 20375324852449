import React from 'react';
import { PROMOTIONS_PRICE_STATE } from '../../../../../../shared';
import { Item } from '../../styled';
import { convertPriceFilter } from './utils';

export const CheckedPrice = ({ price, onRemove }) => {
    return price.lte !== PROMOTIONS_PRICE_STATE.lte || price.gte !== PROMOTIONS_PRICE_STATE.gte ? (
        <Item
            type='secondary'
            onClose={() =>
                onRemove({
                    lte: PROMOTIONS_PRICE_STATE.lte,
                    gte: PROMOTIONS_PRICE_STATE.gte
                })
            }
        >
            Price: {convertPriceFilter(price)}
        </Item>
    ) : null;
};
