import { PROMOTIONS_PRICE_STATE } from '../../../../../../../shared';

export const convertPriceFilter = ({ gte, lte }) => {
    if (lte !== PROMOTIONS_PRICE_STATE.lte && gte === PROMOTIONS_PRICE_STATE.gte) {
        return `under $${lte}`;
    }

    if (lte === PROMOTIONS_PRICE_STATE.lte && gte !== PROMOTIONS_PRICE_STATE.gte) {
        return `over $${gte}`;
    }

    return `$${gte} to $${lte}`;
};
