import React, { Fragment } from 'react';
import {
    BOTTOM_INDENTS,
    Col,
    ForwardArrowIcon,
    Icon,
    PointsIcon,
    Row,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../../../../../utils';
import CouponButtons from '../CouponButtons';
import { StyledDescription, StyledIcon, StyledWrapper } from './styled';

const NoCoupons = observer(() => {
    const simple = useSimpleInterface(GRID_POINTS.md);
    return (
        <StyledWrapper>
            <StyledIcon
                size='37px'
                roundedSize='300%'
                roundedOpacity={1}
                roundedColor='background'
                color='secondaryText'
                component={() => <PointsIcon />}
            />
            <Typography.Title style={BOTTOM_INDENTS.M} level={2}>
                {simple ? 'No rewards yet' : 'No coupons yet'}
            </Typography.Title>
            <StyledDescription type='body1'>
                {simple ? (
                    'Earn rewards by completing tasks and challenges'
                ) : (
                    <Fragment>
                        Earn exclusive coupons by completing purchases and checking <br /> your email inbox
                    </Fragment>
                )}
            </StyledDescription>
            <CouponButtons
                goToChallengesText={
                    simple ? (
                        'Earn more rewards'
                    ) : (
                        <Row justify='center' align='middle' gutter={20}>
                            <Col>Go to Challenges</Col>
                            <Col>
                                <Icon size='24px' component={() => <ForwardArrowIcon />} />
                            </Col>
                        </Row>
                    )
                }
            />
        </StyledWrapper>
    );
});
export default NoCoupons;
