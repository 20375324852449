import React from 'react';
import { Row } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import Step from './components/Step';
import { StyledStepsWrapper } from './styled';

const Steps = inject('store')(
    observer(({ store, background, withTitle, ...props }) => {
        const { challenges, activeChallenge, isNoActiveChallenge } = store.seasonalChallengeStore;

        return (
            <StyledStepsWrapper
                $itemsCount={challenges?.length || 0}
                $activeItemPosition={activeChallenge?.position}
                $isNoActiveChallenge={isNoActiveChallenge}
                {...props}
            >
                <Row>
                    {challenges.map((challenge) => (
                        <Step
                            key={challenge.alias}
                            status={challenge.status}
                            position={challenge.position}
                            alias={challenge.alias}
                            name={challenge.name}
                            background={background}
                            withTitle={withTitle}
                        />
                    ))}
                </Row>
            </StyledStepsWrapper>
        );
    })
);

export default Steps;
