import React from 'react';
import { Button, Image } from '@klickly/front-packages';
import styled from 'styled-components';
import likeIcon from '../../../../../../assets/img/onboarding/like.svg';
import likeActiveIcon from '../../../../../../assets/img/onboarding/like_active.svg';
import { GRID_DEVICES_MAX } from '../../../../../../utils';

export const StyledButton = styled(Button)`
    && {
        width: 40px;
        height: 40px;
        cursor: pointer;
        background-color: transparent;
        border-radius: 50%;
        outline: none !important;
        padding: 0;

        position: absolute;
        left: 8px;
        top: 8px;
        z-index: 2;

        &:hover {
            background-color: ${({ $selected }) => ($selected ? '#FFEBEB' : '#D2D6DA')};
        }

        @media (${GRID_DEVICES_MAX.sm}) {
            top: 0;
            left: 0;
        }
    }
`;

const StyledImage = styled(Image)`
    width: 22px;
    height: 22px;
    object-fit: contain;
`;

export const StyledLikeIcon = () => <StyledImage src={likeIcon} alt='like' />;
export const StyledLikeActiveIcon = () => <StyledImage src={likeActiveIcon} alt='liked' />;
