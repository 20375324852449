import React from 'react';
import { Value } from './styles';

export const AnimatedTime = ({ time, text = '', align, params }) => {
    if (time && time.days > 0) {
        const prefix = time.days > 1 ? 's' : '';
        const value = `${time?.days} day${prefix}`;
        return <Value align={align}>{text ? text.replace('{time}', value) : value}</Value>;
    }

    if (time && time.hours > 0) {
        const prefix = time.hours > 1 ? 's' : '';
        const value = `${time?.hours} hour${prefix}`;
        return <Value align={align}>{text ? text.replace('{time}', value) : value}</Value>;
    }

    if (time && time.minutes > 0) {
        const prefix = time.minutes > 1 ? 's' : '';
        const value = `${time?.minutes} ${params.minutes ? params.minutes : `minute${prefix}`}`;
        return <Value align={align}>{text ? text.replace('{time}', value) : value}</Value>;
    }

    if (time && time.seconds > 0) {
        const value = `${time?.seconds} s`;
        return <Value align={align}>{text ? text.replace('{time}', value) : value}</Value>;
    }

    return null;
};
