import styled from 'styled-components';
import { $background } from '../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const WrapStyles = styled.div`
    min-width: 430px;
    width: 430px;
    height: 100%;
    overflow-y: auto;
    background-color: ${$background};

    @media (${GRID_DEVICES_MAX.xl}) {
        min-width: 400px;
        width: 400px;
    }

    @media (${GRID_DEVICES_MAX.lg}) {
        min-width: unset;
        width: 100%;
        height: fit-content;
        overflow-y: visible;
    }
`;
