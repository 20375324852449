import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { ActionsMinusIcon, ActionsPlusIcon, Icon, Slider, TOP_INDENTS } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { PROMOTIONS_PRICE_STATE } from '../../../../../../shared';
import { PriceInput } from './components/PriceInput';
import { FieldControl, FieldControls, PlusIcon, StyledButton, Styles } from './styles';

export const PriceRange = inject('store')(
    observer(({ store }) => {
        const {
            query: { price },
            updatePrice: onChange
        } = store.marketplaceStore;
        const [init, setInit] = useState(false);
        const [maxInFocus, setMaxInFocus] = useState(false);
        const [priceGte, setPriceGte] = useState(PROMOTIONS_PRICE_STATE.gte);
        const [priceLte, setPriceLte] = useState(PROMOTIONS_PRICE_STATE.lte);

        const minRef = useRef();
        const maxRef = useRef();

        const geNewValue = (price) => {
            let min = price.gte >= price.lte ? price.lte - 1 : price.gte;
            const max = price.lte <= min ? min + 1 : price.lte;
            if (min < 0) {
                min = 0;
            }

            return { gte: min, lte: max };
        };

        const onControlChanged = useCallback(
            (price) => {
                const newPriceValue = geNewValue(price);
                setPriceGte(newPriceValue.gte);
                setPriceLte(newPriceValue.lte);
            },
            [setPriceGte, setPriceLte]
        );

        const onChanged = useCallback(() => {
            onChange({ gte: priceGte, lte: priceLte });
        }, [onChange, priceGte, priceLte]);

        useEffect(() => {
            const newPriceValue = geNewValue(price);
            setPriceGte(newPriceValue.gte);
            setPriceLte(newPriceValue.lte);
        }, [price.lte, price.gte, setPriceGte, setPriceLte]);

        useEffect(() => {
            setInit(true);
        }, [setInit]);

        return (
            <Styles>
                {init && (
                    <Fragment>
                        <Slider
                            range
                            min={PROMOTIONS_PRICE_STATE.gte}
                            max={PROMOTIONS_PRICE_STATE.lte}
                            defaultValue={[priceGte, priceLte]}
                            value={[priceGte, priceLte]}
                            onChange={(val) =>
                                onControlChanged({
                                    gte: val[0],
                                    lte: val[1]
                                })
                            }
                        />

                        <FieldControls style={TOP_INDENTS.SM}>
                            <FieldControl>
                                <PriceInput
                                    ref={minRef}
                                    placeholder='From'
                                    withoutBorderColorsOnValidate
                                    value={priceGte}
                                    onClick={() => minRef.current.select()}
                                    onBlur={(e) =>
                                        onControlChanged({
                                            gte: Number(e.target.value),
                                            lte: priceLte
                                        })
                                    }
                                />
                            </FieldControl>
                            <Icon component={() => <ActionsMinusIcon />} />
                            <FieldControl>
                                <PriceInput
                                    ref={maxRef}
                                    placeholder='To'
                                    withoutBorderColorsOnValidate
                                    value={priceLte}
                                    onFocus={() => setMaxInFocus(true)}
                                    onClick={() => maxRef.current.select()}
                                    onBlur={(e) => {
                                        setMaxInFocus(false);
                                        onControlChanged({
                                            gte: priceGte,
                                            lte: Number(e.target.value)
                                        });
                                    }}
                                />
                                {!maxInFocus && priceLte === PROMOTIONS_PRICE_STATE.lte && (
                                    <PlusIcon size='11px' component={() => <ActionsPlusIcon />} />
                                )}
                            </FieldControl>
                        </FieldControls>

                        <StyledButton type='primary' onClick={onChanged}>
                            OK
                        </StyledButton>
                    </Fragment>
                )}
            </Styles>
        );
    })
);
