import styled from 'styled-components';
import { $border } from '../../../../shared';

export const StyledWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: ${({ $withBorder }) => ($withBorder ? `4px solid ${$border}` : 'none')};
`;
