import { Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../utils';

export const StyledWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;

    ${({ $simple }) =>
        $simple
            ? css`
                  flex-direction: column;
                  overflow-y: auto;
                  justify-content: flex-start;
              `
            : ''};
`;

export const StyledContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    ${({ $simple }) =>
        $simple
            ? css`
                  height: auto;
                  overflow-y: visible;
              `
            : ''};
`;

export const StyledContent = styled.div`
    width: 100%;
    max-width: 630px;
    margin: 0 auto;
    padding: 30px;
    box-sizing: content-box;

    @media (${GRID_DEVICES_MAX.lg}) {
        padding: 24px 24px 0;
    }
`;

export const StyledTitle = styled(Typography.Title)`
    margin-bottom: 36px;
`;
