import styled from 'styled-components';
import { $primary } from '../../index';

export const NavItemStyles = styled.div`
    padding-bottom: 25px;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: ${$primary};
    }
`;
