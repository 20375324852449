import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $primaryText, $white } from '../../../../../../../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../../../../../../../utils';

export const StyledImgWrapper = styled.div`
    && {
        position: relative;
        width: 76px;
        height: 76px;

        .ant-image {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            width: auto;
            max-width: 100%;
            max-height: 76px;
            object-fit: contain;
        }
    }
`;

export const StyledQuantity = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 28px;
    height: 28px;
    border-radius: 14px;
    background: ${$primaryText};
    color: ${$white};
`;

export const StyledOriginalPrice = styled(Typography.Text)`
    text-decoration: line-through;
`;

export const StyledProductTitle = styled(Typography.Text)`
    @media (${GRID_DEVICES_MAX.md}) {
        max-width: 120px;
    }
`;
