import React from 'react';
import { Col, Icon, Row, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../utils';
import { getColorsByStatus, getIconByAlias } from '../../utils';
import ChallengeAvailable from './components/ChallengeAvailable';
import ChallengeCompleted from './components/ChallengeCompleted';
import { StyledIconWrapper } from './styled';

const SeasonalChallengeBody = inject('store')(
    observer(({ store }) => {
        const { activeChallenge, isNoActiveChallenge } = store.seasonalChallengeStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const colors = getColorsByStatus('active');
        const ActiveChallengeIcon = getIconByAlias(activeChallenge?.alias);
        return (
            <Row gutter={48} justify='center' align='middle'>
                <Col flex={simple ? '0 0 100%' : '120px'}>
                    <StyledIconWrapper $colors={colors}>
                        <Icon size='48px' component={() => <ActiveChallengeIcon />} />
                    </StyledIconWrapper>
                </Col>
                <Col flex={simple ? '0 0 100%' : '1'}>
                    {isNoActiveChallenge ? <ChallengeCompleted /> : <ChallengeAvailable />}
                </Col>
            </Row>
        );
    })
);

export default SeasonalChallengeBody;
