import { Button, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $white } from '../../../../../../../../shared';

export const StyledWrap = styled.div`
    width: 100%;

    ${({ $simple }) =>
        $simple
            ? css`
                  min-height: 100%;
                  display: flex;
                  flex-direction: column;
              `
            : ''}
`;

export const StyledHead = styled.div`
    width: 100%;
    margin-bottom: 20px;
    ${({ $simple }) =>
        $simple
            ? css`
                  padding: 24px 24px 0;
              `
            : ''}
`;

export const StyledContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 900px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    ${({ $simple }) =>
        $simple
            ? css`
                  min-height: calc(100% - 72px);
                  flex-direction: column;
                  justify-content: flex-start;
                  padding: 24px 24px 0;
                  flex-grow: 1;
              `
            : ''}
`;

export const StyledImageWrap = styled.div`
    width: 100%;
    max-width: 413px;
    ${({ $simple }) =>
        $simple
            ? css`
                  max-width: 100%;
              `
            : ''}
`;

export const StyledDetails = styled.div`
    width: 100%;
    max-width: 523px;
    ${({ $simple }) =>
        $simple
            ? css`
                  max-width: 100%;
              `
            : ''}
`;

export const StyledBrandname = styled(Typography.Text)`
    && {
        margin-bottom: 4px;
    }
`;

export const StyledTitle = styled(Typography.Title)`
    && {
        margin-bottom: 36px;

        ${({ $simple }) =>
            $simple
                ? css`
                      margin-bottom: 20px;
                  `
                : ''}
    }
`;

export const StyledPriceBlock = styled.div`
    width: 100%;
    background: #f4f4f4;
    padding: 20px 24px;
    margin-bottom: 20px;
`;

export const StyledOriginalPrice = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > p:last-child {
        text-decoration: line-through;
    }
`;

export const StyledPrice = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const StyledButtonWrap = styled.div`
    ${({ $simple }) =>
        $simple
            ? css`
                  margin-top: auto;
                  background: ${$white};
                  width: calc(100% + 48px);
                  position: sticky;
                  bottom: 0;
                  padding: 24px;
                  left: 0;
                  border-top: 1px solid #d2d6da;
              `
            : ''}
`;

export const StyledDescriptionWrap = styled.div`
    padding: 40px 0;
`;

export const StyledDescription = styled.div``;

export const StyledButton = styled(Button)`
    && {
        padding: 0;

        & svg {
            width: 24px;
            height: 24px;
        }
    }
`;
