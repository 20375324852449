import { Button, device } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $border, $white } from '../../../../../../shared';

const isIos = device.iOS();
const isSafari = device.isSafari();

export const StyledCardsContent = styled.div`
    max-width: 550px;
    margin: 0 auto;
`;

export const StyledButtonWrapper = styled.div`
    display: flex;
    align-items: center;

    ${({ $simple }) =>
        $simple
            ? css`
                  position: sticky;
                  bottom: ${() => (isSafari && isIos ? '-90px' : '0')};
                  margin-left: -20px;
                  width: calc(100% + 40px);

                  background-color: ${$white};
                  flex-direction: column;
                  padding: 24px;
                  border-top: 1px solid ${$border};
              `
            : ''}
`;

export const StyledButton = styled(Button)`
    && {
        white-space: nowrap;
        margin-right: 20px;

        ${({ $simple }) =>
            $simple
                ? css`
                      margin-right: 0;
                      margin-bottom: 20px;
                      width: 100%;
                  `
                : ''}
    }
`;

export const StyledErrorMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    margin-top: 20px;

    svg {
        font-size: 22px;
        margin-right: 20px;
    }
`;
