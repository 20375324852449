export const TASK_STATUSES = Object.freeze({
    inactive: 'inactive',
    completed: 'completed',
    inProgress: 'in-progress',
    disable: 'disable',
    active: 'active'
});
export const TASK_STATUS_NAMES = Object.freeze({
    [TASK_STATUSES.inactive]: 'Disabled',
    [TASK_STATUSES.completed]: 'Completed',
    [TASK_STATUSES.inProgress]: 'In Progress',
    [TASK_STATUSES.disable]: 'Disable',
    [TASK_STATUSES.active]: 'Active'
});

export const taskNameByStatus = (status) => {
    return TASK_STATUS_NAMES[status] || status;
};
