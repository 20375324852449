import React from 'react';
import { BOTTOM_INDENTS, formatPoints, Typography, useSimpleInterface } from '@klickly/front-packages';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { $primary } from '../../../../../../../../shared';
import { GRID_POINTS } from '../../../../../../../../utils';
import { StyledHead } from '../../styled';
import ProductItem from './components/ProductItem';

const ProductsList = inject('store')(
    observer(({ store, orderNumber }) => {
        const { getDetailsByOrderNumber, getEarnedPointsByOrderNumber, orderDetails } = store.orderStore;
        const { data } = store.ordersStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const details = getDetailsByOrderNumber(orderNumber);
        const orderPoints = formatPoints(getEarnedPointsByOrderNumber(orderNumber));
        const orderData = toJS(data).filter((item) => item.orderNumber === orderNumber);
        const isPointsCalculating = orderData[0]?.isAvailableForCancel;

        return details ? (
            <div>
                <StyledHead style={BOTTOM_INDENTS.L}>
                    <Typography.Text type={simple ? 'badgeMedium' : 'badgeLarge'}>
                        {details?.merchantName}
                    </Typography.Text>
                    {orderData[0]?.klicklyStatus !== 'new' && (
                        <Typography.Text align='right' type='body2' color={$primary}>
                            {!isPointsCalculating && getEarnedPointsByOrderNumber(orderNumber) > 0
                                ? `+${orderPoints} pts`
                                : ''}
                        </Typography.Text>
                    )}
                </StyledHead>
                {details?.items?.map((product) => (
                    <ProductItem key={product._id} product={product} orderDetails={orderDetails} details={details} />
                ))}
            </div>
        ) : null;
    })
);

export default ProductsList;
