import { Button, Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $background } from '../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const WaitlistedStyles = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (${GRID_DEVICES_MAX.md}) {
        display: block;
    }
`;

export const WaitlistedContentWrap = styled.div`
    width: 100%;
    height: 100%;
    min-height: 750px;
    background-color: ${$background};
    padding: 48px 80px;
    margin: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (${GRID_DEVICES_MAX.md}) {
        max-width: unset;
        padding: 0 24px;
        height: 100%;
    }
`;

export const StyledImage = styled.img`
    width: 160px;
    height: 160px;
    object-fit: contain;
    border-radius: 50%;
`;

export const StyledText = styled(Typography.Text)`
    text-align: center;
`;

export const StyledButton = styled(Button)`
    && {
        margin-bottom: 32px;
        padding: 18px 64px;

        @media (${GRID_DEVICES_MAX.md}) {
            margin-bottom: 0;
        }
    }
`;
