import React from 'react';
import { StarIcon, Typography } from '@klickly/front-packages';
import { StyledButton, StyledIcon } from './styled';

export const ButtonBadge = ({ onClick = () => null }) => {
    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
    };

    return (
        <StyledButton type='unstyled' onClick={handleClick}>
            <StyledIcon>
                <StarIcon size='16px' color='canvas' />
            </StyledIcon>
            <Typography.Title level={3} color='canvas'>
                Special offers
            </Typography.Title>
        </StyledButton>
    );
};
