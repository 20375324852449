import styled from 'styled-components';
import { $primaryText } from '../../../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../../../utils';

export const GalleryWrapper = styled.div`
    border-left: 2px solid ${$primaryText};
    border-right: 2px solid ${$primaryText};

    @media (${GRID_DEVICES_MAX.xs}) {
        border: none;
    }
`;
