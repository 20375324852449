import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Alert,
    DiamondIcon,
    ForwardArrowIcon,
    InfoIcon,
    TOP_INDENTS,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../shared';
import { Animated } from '../../../../shared/components/Animated';
import { SuiteTimer } from '../../../../shared/components/SuiteTimer';
import { GRID_POINTS } from '../../../../utils';

const GsSideBar = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { isLoggedIn } = store.accountStore;
        const { isSuiteStateClosed } = store.suiteStore;
        const simple = useSimpleInterface(GRID_POINTS.md);

        const goToInfo = () => {
            navigate(ROUTE_PATHS.suiteGsInfo);
        };

        const goToSubscription = () => {
            navigate(ROUTE_PATHS.subscriptions);
        };

        return (
            <Animated>
                {isLoggedIn ? (
                    <Fragment>
                        {!isSuiteStateClosed ? <SuiteTimer /> : null}
                        {!simple && (
                            <div style={TOP_INDENTS.SM}>
                                <Alert
                                    onClick={goToSubscription}
                                    description='Receive a new Gifting Suite each and every week with a Premium Subscription'
                                    showIcon
                                    simple
                                    icon={<DiamondIcon />}
                                    afterIcon={<ForwardArrowIcon />}
                                />
                            </div>
                        )}
                    </Fragment>
                ) : null}
                {!simple ? (
                    <div style={TOP_INDENTS.SM}>
                        <Alert
                            onClick={goToInfo}
                            description='What is a Gifting Suite?'
                            showIcon
                            afterIcon={<ForwardArrowIcon />}
                            icon={<InfoIcon />}
                            marginBottom='20px'
                        />
                    </div>
                ) : null}
            </Animated>
        );
    })
);

export default GsSideBar;
