import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ForwardArrowIcon, GiftIcon, GRID_POINTS, Icon, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $background, $primaryText, $white, ROUTE_PATHS } from '../../../../shared';
import { BoxLoader } from '../../../../shared/components/BoxLoader';
import { Layout } from '../../../../shared/components/Layout';
import { StyledDescription, StyledSubtitle, StyledTitle, StyledWrapper } from './styled';

const OnboardingSuccess = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const simple = useSimpleInterface(GRID_POINTS.sm);
        const onClick = () => {
            store.trackingStore.sendEvent({
                type: 'event',
                name: 'Onboarding_Complete'
            });
            navigate(ROUTE_PATHS.suiteGs);
        };

        return (
            <Layout contentFullHeight withoutPadding>
                <StyledWrapper>
                    <BoxLoader
                        waveColor={simple ? $background : $white}
                        iconColor={$primaryText}
                        iconSize='55px'
                        withShadow={false}
                        icon={<GiftIcon />}
                        animationDuration='0s'
                    />
                    <StyledTitle>Onboarding complete!</StyledTitle>
                    <StyledSubtitle level={2} color='primary'>
                        You’ve earned 1 Gifting Suite
                    </StyledSubtitle>
                    <StyledDescription type='body1'>
                        To receive your gift, open your{simple && <br />} Gifting Suite{!simple && <br />} and claim a
                        reward!
                    </StyledDescription>
                    <Button type='primary' size='large ghost' onClick={onClick}>
                        <span>Claim your Reward</span>
                        <Icon stylePriority size='24px' component={() => <ForwardArrowIcon />} />
                    </Button>
                </StyledWrapper>
            </Layout>
        );
    })
);

export default OnboardingSuccess;
