import styled from 'styled-components';
import { $background, $primaryText } from '../../../../../../../../shared';

export const Styles = styled.div`
    position: relative;
    z-index: 2;
`;

export const Target = styled.div`
    padding: 15px 25px;
    background: ${$background};
    display: flex;
    align-items: center;
    border: 2px solid transparent;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        border-color: ${$primaryText};
    }
`;

export const Body = styled.div`
    min-width: 200px;
    padding: 15px 20px;
    background: ${$background};
    box-shadow: rgb(0 0 0 / 9%) 0 20px 40px;

    > ul.ant-dropdown-menu {
        box-shadow: none;
        background: none;
        padding: 0;
    }
`;

export const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0;
    cursor: pointer;
`;
