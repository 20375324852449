import React from 'react';
import { BOTTOM_INDENTS, Button, Form, Input, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { VALIDATION_RULES } from '../../../../shared';
import { DialogWithHeader } from '../DialogWithHeader';
import { StyledBody } from './styled';

export const SuiteFeedback = inject('store')(
    observer(({ store, onClose }) => {
        const [form] = Form.useForm();
        const { suiteFeedbackSend, feedbackPending } = store.suiteStore;

        const onSendFeedback = (values) => {
            suiteFeedbackSend(values, onClose);
        };

        return (
            <DialogWithHeader onClose={onClose} title='Write a review'>
                <StyledBody>
                    <Typography.Text style={BOTTOM_INDENTS.M} type='body1'>
                        How was your last Gifting Suite?
                    </Typography.Text>
                    <Form form={form} onFinish={onSendFeedback}>
                        <Form.Item style={BOTTOM_INDENTS.M} name='feedback' rules={VALIDATION_RULES.REQUIRED}>
                            <Input.TextArea label='How was your experience? Did you have any issues?' />
                        </Form.Item>
                        <Form.Item noStyle>
                            <Button loading={feedbackPending} htmlType='submit' type='primary' size='large' block>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </StyledBody>
            </DialogWithHeader>
        );
    })
);
