import React from 'react';
import { ArrowChevronDownIcon, ArrowChevronUpIcon, Icon } from '@klickly/front-packages';
import { Divider, StyledButton, StyledButtonBlock, StyledContent, StyledWrap } from './styled';

export const CustomCollapse = ({ hidden, onChange, children }) => {
    return (
        <StyledWrap>
            <StyledContent $open={!hidden}>{children}</StyledContent>
            <StyledButtonBlock>
                <Divider $position='left' />
                <StyledButton type='primary' shape='round' onClick={onChange}>
                    <Icon size='16px' component={() => (!hidden ? <ArrowChevronUpIcon /> : <ArrowChevronDownIcon />)} />
                </StyledButton>
                <Divider $position='right' />
            </StyledButtonBlock>
        </StyledWrap>
    );
};
