import React from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../shared';
import ForgotPassword from '../components/ForgotPassword';
import ResetPassword from '../components/ResetPassword';
import SignIn from '../components/SignIn';

export const ROUTES = [
    {
        index: true,
        element: <Navigate to={ROUTE_PATHS.signIn} replace />
    },
    {
        path: ROUTE_PATHS.signIn,
        element: <SignIn />
    },
    {
        path: ROUTE_PATHS.forgotPassword,
        element: <ForgotPassword />
    },
    {
        path: ROUTE_PATHS.resetPassword,
        element: <ResetPassword />
    }
];
