import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { GRID_POINTS, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../../../../../../../shared';
import { Accordion } from '../Accordion';
import { InvitationItem } from './components/InvitationItem';
import { NotificationText } from './styles';

export const InviteFriendTaskResults = inject('store')(
    observer(({ store }) => {
        const { pathname } = useLocation();
        const taskResults = store.tasksStore.inviteFriendTaskData.results;
        const { inviteFriendTaskData: data } = store.tasksStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const fromGS = pathname.includes(ROUTE_PATHS.suiteGs);
        const { attemptsRemained, results } = data;
        const showResults = results && results.length && !fromGS;

        return (
            <Fragment>
                {!showResults && (
                    <NotificationText type='descriptor2' color='secondaryText'>
                        An invitation increases the chances, but does not guarantee, that your friend will be accepted
                        into Giftly – they must still pass verification
                    </NotificationText>
                )}
                {showResults && taskResults.length ? (
                    <Accordion title='Invitation status' defaultOpen={!attemptsRemained} disableFolding={simple}>
                        {taskResults.map(({ inviteeFirstName, inviteeEmail, inviteStatus, id }) => (
                            <InvitationItem
                                key={`key-${inviteeEmail}`}
                                id={id}
                                name={inviteeFirstName}
                                email={inviteeEmail}
                                status={inviteStatus}
                            />
                        ))}
                    </Accordion>
                ) : null}
            </Fragment>
        );
    })
);
