import styled from 'styled-components';

export const Styles = styled.div`
    height: 100%;
    overflow-y: auto;
`;

export const StyledRow = styled.div`
    height: 100%;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: normal;
    justify-content: flex-start;
    flex-flow: row wrap;
`;
