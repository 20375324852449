import { types } from 'mobx-state-tree';

export const RewardModel = types.model({
    _id: types.identifier,
    title: types.string,
    description: types.string,
    image: types.string,
    status: types.string,
    expireAt: types.string,
    promotions: types.maybeNull(
        types.array(
            types.model({
                _id: types.string,
                title: types.string,
                externalId: types.string,
                account: types.string,
                campaign: types.maybeNull(types.string),
                shopDomain: types.maybeNull(types.string)
            })
        )
    )
});
