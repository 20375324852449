import { Button, Drawer } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledDrawer = styled(Drawer)`
    position: absolute;

    &.ant-drawer-left > .ant-drawer-content-wrapper {
        box-shadow: none;
    }
`;

export const StyledBurgerButton = styled(Button)`
    && {
        margin-left: -20px;
        padding: 12px 16px;
    }
`;
