import { useEffect } from 'react';
import { useClearCartPixels } from '../../../shared';

export const useOrderCompleted = ({ store, cart }) => {
    const { isLoggedIn } = store.accountStore;
    const { handleClearPixels } = useClearCartPixels(store, false, cart);

    useEffect(() => {
        return () => {
            handleClearPixels(store, !isLoggedIn && cart, cart);
        };
    }, [cart]);
};
