import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, Button, TOP_INDENTS, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS, SEASONAL_CHALLENGE_ALIASES, useSeasonalChallengeNotifications } from '../../../../../../shared';
import { Animated } from '../../../../../../shared/components/Animated';
import PopoverWithButton from '../../../../../../shared/components/PopoverWithButton';
import { GRID_POINTS } from '../../../../../../utils';
import SuiteFeedback from '../SuiteFeedback';
import SuiteGallery from '../SuiteGallery';
import { useOnboardingPopovers, usePageTextsAndCallbacks } from './hooks';
import { Styles } from './styles';

const SuiteReadyToLaunch = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { isLoggedIn, signUpStatus, isRejectedCustomer } = store.accountStore;
        const { isFeedbackBannerVisible, launchSuite } = store.suiteStore;
        const { claimsCount } = store.claimsPointsStore;
        const {
            showPopoverOnLaunchGsButton,
            toggleShowPopoverOnLaunchGsButton,
            onClosePopoverOnLaunchButton
        } = store.onboardingPopoverStore;
        const { activeChallenge, getSeasonalChallenge } = store.seasonalChallengeStore;

        const showSeasonalChallengeNotification = useSeasonalChallengeNotifications(store);

        const { title, description, launchButtonText, onLaunch } = usePageTextsAndCallbacks({
            isLoggedIn,
            signUpStatus,
            claimsCount,
            launchSuite,
            isRejectedCustomer,
            onAfterLaunchSuccess: () => {
                if (
                    activeChallenge?.alias === SEASONAL_CHALLENGE_ALIASES.LAUNCH_GS &&
                    activeChallenge.challengeAccepted
                ) {
                    showSeasonalChallengeNotification('completed');
                    getSeasonalChallenge(true);
                }
            }
        });

        useEffect(() => {
            if (
                isLoggedIn &&
                signUpStatus !== 'rejected' &&
                claimsCount > 0 &&
                activeChallenge?.alias === SEASONAL_CHALLENGE_ALIASES.LAUNCH_GS
            ) {
                showSeasonalChallengeNotification('1', 'Click Launch', {
                    duration: null,
                    bottom: simple ? '44px' : '8px'
                });
            }
        }, [isLoggedIn, signUpStatus, claimsCount]);

        const goToInfo = () => {
            navigate(ROUTE_PATHS.suiteGsInfo);
        };

        useOnboardingPopovers(store);

        return (
            <Animated>
                <Styles>
                    <Typography.Title style={BOTTOM_INDENTS.SM}>{title}</Typography.Title>
                    <Typography.Text>{description}</Typography.Text>
                </Styles>
                <SuiteGallery />
                <Styles style={BOTTOM_INDENTS.XXL}>
                    <PopoverWithButton
                        hasTopIndent={!simple}
                        content={
                            <div>
                                Claim your reward by {!simple && <br />} launching your {simple && <br />} 1st{' '}
                                {!simple && <br />} Gifting Suite!
                            </div>
                        }
                        placement={simple ? 'topLeft' : 'bottomLeft'}
                        open={showPopoverOnLaunchGsButton}
                        onTooltipButtonClick={onClosePopoverOnLaunchButton}
                    >
                        {simple && isFeedbackBannerVisible && <SuiteFeedback simple={simple} />}
                        <Fragment>
                            <Button
                                onClick={() => {
                                    toggleShowPopoverOnLaunchGsButton();
                                    return onLaunch();
                                }}
                                type='primary'
                                size='large'
                                block={simple}
                            >
                                <Typography.Text as='span' type='badgeLarge'>
                                    {launchButtonText}
                                </Typography.Text>
                            </Button>
                        </Fragment>
                    </PopoverWithButton>
                    {!simple && isFeedbackBannerVisible && <SuiteFeedback simple={simple} />}
                    {simple ? (
                        <Button style={TOP_INDENTS.SM} type='text' size='large' block onClick={goToInfo}>
                            What is a Gifting Suite?
                        </Button>
                    ) : null}
                </Styles>
            </Animated>
        );
    })
);

export default SuiteReadyToLaunch;
