import React from 'react';
import { ArrowChevronForwardIcon, Typography } from '@klickly/front-packages';
import StyledBreadcrumbs from './styled/StyledBreadcrumbs';
import StyledButton from './styled/StyledButton';
import StyledIcon from './styled/StyledIcon';

const Breadcrumbs = () => {
    return (
        <StyledBreadcrumbs>
            <StyledButton $active>
                <Typography.Text type='badgeSmall'>Contact</Typography.Text>
            </StyledButton>
            <StyledIcon size='14px' color='secondaryText' component={() => <ArrowChevronForwardIcon />} />
            <StyledButton $active={false}>
                <Typography.Text type='badgeSmall'>Shipping</Typography.Text>
            </StyledButton>
            <StyledIcon size='14px' color='secondaryText' component={() => <ArrowChevronForwardIcon />} />
            <StyledButton $active={false}>
                <Typography.Text type='badgeSmall'>Billing</Typography.Text>
            </StyledButton>
        </StyledBreadcrumbs>
    );
};

export default Breadcrumbs;
