import styled from 'styled-components';
import { GRID_DEVICES_MAX, GRID_DEVICES_MIN } from '../../../../utils';

export const TaskModalStyles = styled.div`
    background-color: #fff;
    max-width: 560px;
    min-height: auto;
    position: relative;

    @media (${GRID_DEVICES_MIN.sm}) {
        margin-top: 65px;
        margin-bottom: 20px;
    }

    @media (${GRID_DEVICES_MAX.sm}) {
        min-height: 100%;
        margin: ${({ isThanksStep }) => (isThanksStep ? '0 20px' : '0')};
    }
`;
