import React from 'react';
import { ShareIcon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { useModal } from '../../../../hooks';
import ShareProductModalContent from './components/ShareProductModalContent';
import { StyledButton, StyledIcon } from './styled';

const ShareProduct = inject('store')(
    observer(({ store, promotionId }) => {
        const { getPromotion } = store.shareProductStore;
        const { promotion } = store.pdpStore;
        const { openModal, closeModal } = useModal();

        const onShare = async (e) => {
            e.stopPropagation();
            store.trackingStore.trackClick(promotion);

            const data = await getPromotion(promotionId);
            if (data) {
                openModal({
                    title: 'Share product',
                    children: <ShareProductModalContent onCloseModal={closeModal} promotionId={promotionId} />
                });
            }
        };
        return (
            <StyledButton data-e2e='share-product-button' type='unstyled' onClick={onShare}>
                <span>Share</span>
                <StyledIcon size='20px' component={() => <ShareIcon />} />
            </StyledButton>
        );
    })
);

export default ShareProduct;
