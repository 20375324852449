import React, { useEffect } from 'react';
import { notification, useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../../../../../../../utils';
import NotificationContent from '../../../../../../NotificationContent';

export const useShareProductNotification = (store) => {
    const navigate = store.routeNavigate;
    const simple = useSimpleInterface(GRID_POINTS.md);
    const { isApprovedCustomer } = store.accountStore;
    const { activeChallengeName } = store.marketplaceStore;
    const challenge = store.tasksStore?.copyProductLinkTask;
    const isChallenge =
        activeChallengeName === challenge?.alias && challenge?.data?.status !== 'completed' && isApprovedCustomer;
    const message = 'Step 3 of 3. Copy link';
    const defaultSettings = { duration: null, bottom: simple ? '0' : '8px' };

    useEffect(() => {
        if (isChallenge) {
            store.trackingStore.trackChallengeNotification(challenge, '3', message);
            notification.open({
                key: challenge?.alias,
                description: <NotificationContent.Reward rewardCount={50} message={message} />,
                ...defaultSettings
            });
        }
        return () => (typeof notification.destroy === 'function' ? notification.destroy() : null);
    }, [isChallenge]); //eslint-disable-line

    return {
        onTaskComplete: ({ taskCompleted }) => {
            if (taskCompleted) {
                store.trackingStore.trackChallengeNotification(
                    challenge,
                    'completed',
                    `${challenge?.data?.title} completed!`
                );
                notification.open({
                    key: challenge?.alias,
                    description: <NotificationContent.Completed completedActionName={challenge?.data?.title} />,
                    ...defaultSettings
                });
                notification.open({
                    description: <NotificationContent.RewardEarned value={50} navigate={navigate} />,
                    ...defaultSettings
                });
            }
        }
    };
};
