import React from 'react';
import { Typography } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { useApplyClick } from '../../../../hooks';
import {
    BriogeoImage,
    FourSigmaticImage,
    MyIntentImage,
    NatureLabImage,
    NutritionImage,
    SpongelleImage,
    StyledBrandsImgWrap,
    StyledButton,
    StyledWrap,
    UsualImage,
    VegamourImage,
    YourSuperImage
} from './styles';

export const BrandsSection = observer(() => {
    const onApplyClick = useApplyClick();

    return (
        <StyledWrap>
            <Typography.Title level={2} align='center' color='canvas' style={{ marginBottom: '24px' }}>
                Choose from 2,000+ top trending brands
            </Typography.Title>
            <StyledBrandsImgWrap>
                <FourSigmaticImage />
                <MyIntentImage />
                <NatureLabImage />
                <YourSuperImage />
                <NutritionImage />
                <UsualImage />
                <VegamourImage />
                <BriogeoImage />
                <SpongelleImage />
            </StyledBrandsImgWrap>
            <StyledButton type='primary-inverted' size='large' onClick={onApplyClick}>
                <Typography.Text type='badgeLarge'>Apply for access</Typography.Text>
            </StyledButton>
        </StyledWrap>
    );
});
