import { Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../../utils';

export const StyledWrap = styled.div`
    position: relative;
`;

export const StyledText = styled(Typography.Text)`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;

    > span {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    @media (${GRID_DEVICES_MAX.sm}) {
        padding: 4px;
    }

    ${({ $backgroundColor }) =>
        $backgroundColor &&
        css`
            background-color: ${$backgroundColor};
        `};
`;
