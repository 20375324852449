import styled, { css } from 'styled-components';
import { $border, $primary } from '../../../../../shared';

export const StyledWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 50px;

    margin-bottom: ${({ $simple }) => ($simple ? '' : '40px')};
`;

export const StyledDivider = styled.div`
    width: 100%;
    height: 4px;

    ${({ $state }) =>
        $state === 'completed'
            ? css`
                  background-color: ${$primary};
              `
            : ''}

    ${({ $state }) =>
        $state === 'uncompleted'
            ? css`
                  background-color: ${$border};
              `
            : ''}
`;
