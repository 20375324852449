import { getParent, types } from 'mobx-state-tree';
import { ROUTE_PATHS } from '../shared';

export const OnboardingRedirectionStore = types
    .model('OnboardingRedirectionStore', {
        redirected: false
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },

        get processingRedirectLink() {
            const signUpStatus = self.root.accountStore.signUpStatus;
            return signUpStatus === 'approved' ? ROUTE_PATHS.approved : ROUTE_PATHS.waitlisted;
        },

        get shouldOnboarding() {
            const { account, giftlyData, shippingAddresses } = self.root.accountStore;
            if (!account) {
                return true;
            }

            if (account && !account.giftly) {
                return true;
            }

            return (
                !giftlyData.selectedCategories ||
                !giftlyData.selectedCategories.length ||
                !giftlyData.selectedImportantToCustomer ||
                !giftlyData.selectedImportantToCustomer.length ||
                !shippingAddresses.length
            );
        }
    }))
    .actions((self) => {
        const setRedirectionState = (state) => {
            self.redirected = state;
        };

        const onboardingInitialRedirect = () => {
            const { isLoggedIn, giftlyData } = self.root.accountStore;

            if (!isLoggedIn) {
                setRedirectionState(true);
                return self.root.routeNavigate({
                    pathname: ROUTE_PATHS.personalInfo,
                    search: self.root.routeLocation.search
                });
            }

            if (!giftlyData) {
                setRedirectionState(true);
                return self.root.routeNavigate({
                    pathname: ROUTE_PATHS.connectInstagram,
                    search: self.root.routeLocation.search
                });
            }

            const { signUpStatus, signUpApprovedLaterAt, selectedCategories } = giftlyData;

            if (signUpStatus === 'active') {
                setRedirectionState(true);
                return self.root.routeNavigate({
                    pathname: ROUTE_PATHS.suiteGs,
                    search: self.root.routeLocation.search
                });
            }

            if (signUpStatus === 'rejected') {
                setRedirectionState(true);

                return self.root.routeNavigate({
                    pathname: ROUTE_PATHS.waitlisted,
                    search: self.root.routeLocation.search
                });
            }

            if (signUpStatus === 'approved') {
                if (signUpApprovedLaterAt && (!selectedCategories || !selectedCategories.length)) {
                    setRedirectionState(true);
                    return self.root.routeNavigate({
                        pathname: ROUTE_PATHS.approved,
                        search: self.root.routeLocation.search
                    });
                }
            }
        };

        const redirectUnloggedCutomer = () => {
            self.root.routeNavigate(
                {
                    pathname: ROUTE_PATHS.personalInfo,
                    search: self.root.routeLocation.search
                },
                { replace: true }
            );
            setRedirectionState(true);
        };

        return {
            setRedirectionState,
            onboardingInitialRedirect,
            redirectUnloggedCutomer
        };
    });
