import { formatPoints } from '@klickly/front-packages';
import { get } from '../../../../../utils';

export const useCardsData = (store) => {
    const { isSuiteStateStared, pending: suitePending } = store.suiteStore;
    const { claimsCount, points, pending: claimsPending } = store.claimsPointsStore;

    // always show points
    const result = {
        points: { visible: true, value: formatPoints(points) }
    };
    let cardsAmount = 1;

    // challenges card
    const { finishedChallengesAmount, challengesAmount, inviteFriendTask } = store.tasksStore;
    const renderChallengesCard = challengesAmount && finishedChallengesAmount < challengesAmount;
    if (renderChallengesCard) {
        result.challenges = { visible: true, value: `${finishedChallengesAmount} / ${challengesAmount}` };
        cardsAmount += 1;
    }

    // suite card
    if (claimsCount && !isSuiteStateStared) {
        result.suite = { visible: true, value: claimsCount };
        cardsAmount += 1;
    }

    // invite friend card
    const inviteAttemptsRemaining = get(inviteFriendTask, 'attempts', 0);
    const showInviteFriendCard = inviteFriendTask && inviteAttemptsRemaining !== 0;

    if (showInviteFriendCard) {
        result.invite = { visible: true, value: `${inviteAttemptsRemaining}` };
        cardsAmount += 1;
    }

    return { result, cardsAmount, pending: suitePending || claimsPending };
};
