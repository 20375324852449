import React from 'react';
import { Col, Row, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { GRID_POINTS } from '../../../utils';
import FooterItem from './components/FooterItem';
import { FOOTER_ITEMS } from './constants';
import { StyledFooter, StyledFooterContent } from './styled';

const Footer = observer(() => {
    const simple = useSimpleInterface(GRID_POINTS.md);
    return (
        <StyledFooter>
            <StyledFooterContent>
                <Row gutter={[16, 16]} justify={simple ? 'center' : 'space-between'} align='middle'>
                    <FooterItem text='© Klickly, Inc' />
                    <Col>
                        <Row gutter={[16, 16]} justify='center' align='middle'>
                            {FOOTER_ITEMS.map(({ key, to, text }) => (
                                <FooterItem key={key} to={to} text={text} />
                            ))}
                        </Row>
                    </Col>
                </Row>
            </StyledFooterContent>
        </StyledFooter>
    );
});

export default Footer;
