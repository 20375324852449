import React, { forwardRef } from 'react';
import { KliField } from '@klickly/front-packages';
import { FieldErrorStyles, FieldStyles } from './Field.styles';

// eslint-disable-next-line react/display-name
export const Field = forwardRef(({ error, withoutClearButton = true, ...rest }, ref) => {
    return (
        <FieldStyles>
            <KliField invalid={Boolean(error)} withoutClearButton={withoutClearButton} ref={ref} {...rest} />
            {error && (
                <FieldErrorStyles type='descriptor2' color='error'>
                    {error}
                </FieldErrorStyles>
            )}
        </FieldStyles>
    );
});
