import styled from 'styled-components';
import { $border, $white } from '../../../../shared';

export const HeaderStyles = styled.div`
    width: 100%;
    position: ${({ simple }) => (simple ? 'sticky' : 'relative')};
    height: ${({ simple }) => (simple ? '64px' : '80px')};
    top: 0;
    left: 0;
    background-color: ${$white};
    z-index: 10;
`;

export const HeaderContent = styled.div`
    height: 100%;
    padding: ${({ simple }) => (simple ? '0 24px' : '0 80px')};
    border-bottom: 1px solid ${$border};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const HeaderContentLeft = styled.div`
    display: flex;
    align-items: center;
`;

export const HeaderLinksWrap = styled.div`
    display: flex;
    margin-left: 24px;
`;

export const TextWrap = styled.div`
    margin: 0 12px;
    cursor: pointer;
`;

export const MenuWrapper = styled.div`
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    height: calc(100% - 64px);
    padding: 40px 24px;
    background-color: ${$white};
    transform: ${({ active }) => (active ? 'translateX(0)' : 'translateX(-100%)')};
    transition: all 0.3s ease;
    z-index: 10;
`;

export const BorderLine = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ${$border};
    margin: 10px 0 24px;
`;
