import React, { Fragment } from 'react';
import { Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { SettingsFooter } from './components/SettingsFooter';
import { SettingsHeader } from './components/SettingsHeader';
import { SettingsNav } from './components/SettingsNav';

export const SettingsContainer = inject('store')(
    observer(({ store }) => {
        const { account, pending } = store.accountStore;

        return (
            <Fragment>
                {Boolean(account) && (
                    <div>
                        <SettingsHeader />
                        <SettingsNav />
                        <SettingsFooter />
                    </div>
                )}
                {pending && <Icon.Loader />}
            </Fragment>
        );
    })
);
