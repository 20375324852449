import React from 'react';
import nextId from 'react-id-generator';
import { inject, observer } from 'mobx-react';
import { BrandCard } from './components/BrandCard';

export const OrdersData = inject('store')(
    observer(({ store, simple }) => {
        const { cartData } = store.preCheckoutStore;
        return cartData.bags.map((bag, key) => {
            return (
                <BrandCard
                    key={nextId()}
                    orderLength={cartData?.bags?.length}
                    orderNumber={key + 1}
                    bag={bag}
                    cartData={cartData}
                    simple={simple}
                />
            );
        });
    })
);
