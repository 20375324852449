import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, GRID_POINTS, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../shared';
import PromotionsSlider from '../../../../shared/components/PromotionsSlider';
import { StyledWrapper } from './styled';

const DayDeals = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { dayDealsPromotions } = store.dealsStore;
        return (
            <StyledWrapper data-e2e='day-deals-promotions-block'>
                <PromotionsSlider
                    promotions={dayDealsPromotions}
                    title='Deals of the Day'
                    isRecommendation
                    seAllBtnText={simple ? 'See all Deals' : 'See all'}
                    seAllBtnAction={() => navigate(ROUTE_PATHS.shop)}
                    promotionsHeight={simple ? '315px' : '370px'}
                    divider={simple ? null : <Divider height={4} margin='24px 0 0' />}
                />
            </StyledWrapper>
        );
    })
);

export default DayDeals;
