import { useNavigate, useSearchParams } from 'react-router-dom';
import { app } from '../config';
import { ROUTE_PATHS } from '../shared';

export const useLoginClick = () => {
    const currentURL = window.location.origin;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    if (currentURL === app.giftlyUrl) {
        return () => navigate(`${ROUTE_PATHS.signIn}?${searchParams.toString()}`);
    } else {
        return () => window.open(`${app.giftlyUrl}${ROUTE_PATHS.auth}?${searchParams.toString()}`, '_self');
    }
};

export const useApplyClick = () => {
    const currentURL = window.location.origin;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    if (currentURL === app.giftlyUrl) {
        return () => navigate(`${ROUTE_PATHS.personalInfo}?${searchParams.toString()}`);
    } else {
        return () => window.open(`${app.giftlyUrl}${ROUTE_PATHS.personalInfo}?${searchParams.toString()}`, '_self');
    }
};
