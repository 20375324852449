import { Menu } from '@klickly/front-packages';
import styled from 'styled-components';
import { $background, $primary, $primaryText, $secondaryText, $white } from '../../../../../../constants';

export const StyledMenu = styled(Menu)`
    margin-left: -20px;
    background: ${$background};
    border-bottom: 1px solid #f0f0f0;

    && {
        .ant-menu-item,
        .ant-menu-submenu,
        .ant-menu-submenu-active {
            &:active,
            &.ant-menu-item-selected {
                background: ${$background};
            }

            &:before {
                display: none;
            }

            &:after {
                right: auto;
                bottom: 4px;
                width: 24px;
            }

            &:hover,
            &.ant-menu-item-active:hover,
            &.ant-menu-item-selected:hover {
                color: ${$primaryText};
            }

            &:has(.active),
            &.ant-menu-item-selected:hover,
            &.ant-menu-item-active:hover {
                &:after {
                    border-bottom: 4px solid ${$primary};
                }
            }
        }

        &.ant-menu-vertical {
            background: ${$white};

            .ant-menu-item {
                height: 80px;
                margin-bottom: 0;

                &:active,
                &.ant-menu-item-selected {
                    background: ${$white};
                }

                &.ant-menu-item-only-child {
                    height: auto;
                    margin-bottom: 0;
                    padding: 8px 0 8px 20px;

                    .ant-menu-title-content {
                        width: 100%;
                    }
                }

                .active:before {
                    top: auto;
                    left: 20px;
                    bottom: 20px;
                    width: 21.5px;
                    height: 4px;
                    background: ${$primary};
                }

                a {
                    color: ${$primaryText};
                }

                .ant-menu-title-content {
                    width: 100%;
                    margin-left: 12px;
                }
            }

            .kli-non-menu-item-mobile {
                .ant-menu-title-content {
                    margin-left: 0;
                }
            }
        }

        &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover:after,
        &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after {
            border-bottom: 4px solid ${$primary};
        }

        .ant-menu-submenu-title {
            display: flex;
            align-items: center;
            color: ${$secondaryText};

            &:hover {
                color: ${$primaryText};
            }

            > svg {
                width: 24px;
                height: 24px;
            }
        }

        .ant-menu-submenu {
            .ant-menu-item a:before {
                display: none;
            }
            .ant-menu-title-content {
                .anticon {
                    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
                        color cubic-bezier(0.645, 0.045, 0.355, 1);
                    transform: rotate(0deg);
                }
            }

            &.ant-menu-submenu-active,
            &.ant-menu-submenu-open,
            &:has(.active) {
                .ant-menu-submenu-title {
                    color: ${$primaryText};
                }

                svg {
                    color: ${$primaryText};
                }
            }

            &.ant-menu-submenu-active,
            &.ant-menu-submenu-open {
                color: ${$primaryText};

                .ant-menu-title-content {
                    .anticon {
                        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
                            color cubic-bezier(0.645, 0.045, 0.355, 1);
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open:after {
            border-bottom: 4px solid ${$primary};
        }

        .ant-menu-submenu-horizontal {
            padding: 0 20px;
        }

        &.ant-menu-horizontal .ant-menu-item,
        .ant-menu-submenu-horizontal {
            &:after {
                inset-inline: 20px;
            }
            .ant-menu-title-content {
                span,
                div {
                    font-family: 'IBM Plex Sans', sans-serif;
                }
            }
        }

        a:hover {
            color: ${$primaryText};
        }

        .ant-menu-title-content {
            * {
                font-weight: 500;
            }
        }

        .ant-menu-submenu-popup {
            &:after {
                display: none;
            }

            .ant-menu {
                background: ${$background};
                box-shadow: none;
            }

            .ant-menu-item {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 32px;
                padding: 0 48px;
                line-height: 32px;

                &:hover {
                    * {
                        color: ${$primaryText};
                    }

                    .ant-btn > div {
                        border-bottom: 4px solid ${$primary};
                    }
                }

                &.kli-non-menu-item {
                    cursor: default;
                    &:hover {
                        * {
                            color: ${$secondaryText};
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 24px;
                }

                a {
                    position: relative;
                    display: block;
                    height: 100%;
                    padding: 0;
                    color: ${$secondaryText};
                    &:after {
                        content: '';
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        border-bottom: 4px solid transparent;
                        transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    }
                }

                > svg {
                    margin-left: 8px;
                    color: ${$secondaryText};
                }

                .ant-menu-title-content {
                    margin-left: 2px;
                }

                &.ant-menu-item-selected {
                    background: transparent;
                }

                .active {
                    color: ${$primaryText};
                    &:after {
                        border-bottom: 4px solid ${$primary};
                        transform: none;
                        opacity: 1;
                    }
                }

                &.ant-menu-item-active,
                &.ant-menu-item-selected,
                &:has(.active) {
                    a {
                        &:after {
                            border-bottom: 4px solid ${$primary};
                            transform: none;
                            opacity: 1;
                        }
                    }
                    svg {
                        color: ${$primaryText};
                    }
                }

                &.kli-non-menu-item {
                    height: auto;
                    line-height: unset;
                    .ant-menu-title-content {
                        width: 100%;
                        margin-left: 0;
                        line-height: unset;
                    }
                }
            }

            .ant-menu-vertical .ant-menu-item:after {
                display: none;
            }

            &.kli-main-menu-dropdown {
                left: -50% !important;
            }
        }
    }
`;
