const PDP_REWARDS_CUSTOMIZATIONS = Object.freeze({ showRewards: true });

export const CUSTOMIZATIONS_MAP = Object.freeze({
    suite: {
        buttons: {
            buyNowButtonLabel: 'Claim this gift',
            showAddToCartButton: false,
            showBuyNowButtonGiftIcon: true
        },
        prices: {
            originalPriceLabel: 'Brand price'
        }
    },
    shop: {
        prices: {
            originalPriceLabel: 'Original price'
        },
        rewards: PDP_REWARDS_CUSTOMIZATIONS
    },
    rewards: {
        prices: {
            originalPriceLabel: 'Original price'
        },
        buttons: {
            showAddToCartButton: false,
            buyNowButtonLabel: 'Add to Cart',
            showBuyNowButtonBagIcon: true
        },
        rewards: PDP_REWARDS_CUSTOMIZATIONS
    },
    onboarding: {
        buttons: {
            showBuyNowButton: false,
            continueShoppingButtonLabel: 'Continue Onboarding'
        }
    }
});

export const POINTS_CUSTOMIZATIONS = Object.freeze({
    loggedIn: {
        points: {
            pointsLabel: 'Points you’ll earn',
            showPoints: true
        }
    },
    loggedOut: {
        points: {
            pointsLabel: 'Members earn',
            showPoints: true
        }
    }
});
