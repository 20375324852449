import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../../../../../utils';

export const Styles = styled.div`
    padding: 25px 0;

    @media (${GRID_DEVICES_MAX.sm}) {
        width: 100%;
        margin-top: auto;
    }
`;
