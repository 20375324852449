import { Image } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledImage = styled(Image)`
    &&& {
        object-fit: cover;
        object-position: center;
        margin-bottom: 24px;
        padding-bottom: 24px;
    }
`;
