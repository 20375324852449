import completed from '../../../../../assets/img/seasonalChallenge/completed.svg';
import gs from '../../../../../assets/img/seasonalChallenge/gs.png';
import invite from '../../../../../assets/img/seasonalChallenge/invite.png';
import personalize from '../../../../../assets/img/seasonalChallenge/personalize.svg';
import points from '../../../../../assets/img/seasonalChallenge/points.png';
import purchase from '../../../../../assets/img/seasonalChallenge/purchase.png';

export const ALIAS_IMAGES_MAP = {
    completed: completed,
    'complete-onboarding': personalize,
    'launch-gifting-suite': gs,
    'invite-friends': invite,
    'make-marketplace-purchase': purchase,
    'earn-points': points
};
