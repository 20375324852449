import React from 'react';
import SuiteClosed from '../components/SuiteClosed';
import SuiteOpened from '../components/SuiteOpened';
import SuiteReadyToLaunch from '../components/SuiteReadyToLaunch';

export const SUITE_STATES = Object.freeze({
    started: 'started',
    created: 'created',
    opened: 'opened',
    closed: 'closed'
});

export const getGsRouteComponentByState = (state) => {
    if (state === SUITE_STATES.started) return <SuiteOpened />;

    if (state === SUITE_STATES.created) return <SuiteClosed />;

    if (state === SUITE_STATES.opened) return <SuiteReadyToLaunch />;

    return <SuiteReadyToLaunch />;
};
