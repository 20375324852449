import React from 'react';
import { Button, Typography } from '@klickly/front-packages';
import { StyledWrap } from './styled';

export const SectionHeader = ({ title, onClick = () => null, withBorder }) => {
    return (
        <StyledWrap $withBorder={withBorder}>
            <Typography.Title level={2}>{title}</Typography.Title>
            <Button type='unstyled' onClick={onClick}>
                <Typography.Text type='badgeMedium'>View Details</Typography.Text>
            </Button>
        </StyledWrap>
    );
};
