import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { notification } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { Animated } from '../../../../shared/components/Animated';
import NotificationContent from '../../../../shared/components/NotificationContent';
import { PageHeader } from '../../../../shared/components/PageHeader';
import { ExtensionSettings } from './components/ExtensionSettings';

const ExtensionPage = inject('store')(
    observer(({ store }) => {
        const { state } = useLocation();
        const { isApprovedCustomer } = store.accountStore;
        const challenge = store.tasksStore?.installExtensionTask;
        const isChallenge = isApprovedCustomer && state === challenge?.alias;
        const message = 'Click Install to complete the Challenge';

        useEffect(() => {
            if (isChallenge && challenge?.isActive) {
                store.trackingStore.trackChallengeNotification(challenge, '1', message);
                notification.open({
                    key: challenge?.alias,
                    description: <NotificationContent.Reward rewardCount={1} rewardType='claims' message={message} />,
                    duration: null,
                    bottom: '8px'
                });
            }
            return () => (typeof notification.destroy === 'function' ? notification.destroy() : null);
        }, [isChallenge, store.tasksStore?.installExtensionTask?.isActive]); //eslint-disable-line
        return (
            <Animated style={{ maxWidth: '845px', margin: 'auto' }}>
                <PageHeader title='Extension settings' subTitle='Account' />
                <ExtensionSettings />
            </Animated>
        );
    })
);

export default ExtensionPage;
