import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, TOP_INDENTS, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $primary, $white, ROUTE_PATHS } from '../../../../shared';
import { BoxLoader } from '../../../../shared/components/BoxLoader';
import { Logo } from '../../../../shared/components/Logo';
import { ProcessingContentWrap, ProcessingStyles, StyledTitle } from './styles';

export const Processing = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const [counter, setCounter] = useState(1);

        useEffect(() => {
            const timeout = setInterval(() => setCounter(counter + 1), 1000);
            if (counter === 4) {
                const { signUpStatus } = store.accountStore;
                if (signUpStatus === 'approved') {
                    navigate(ROUTE_PATHS.approved);
                } else {
                    navigate(ROUTE_PATHS.waitlisted);
                }
            }
            return () => clearInterval(timeout);
        }, [counter]);

        return (
            <ProcessingStyles>
                <ProcessingContentWrap>
                    <Logo hasBottomIndent size='48px' color={$primary} />
                    <BoxLoader
                        waveColor={$primary}
                        iconColor={$white}
                        iconSize='70px'
                        withShadow={false}
                        animationDuration='4s'
                    />
                    <StyledTitle style={{ ...BOTTOM_INDENTS.SM, ...TOP_INDENTS.XXL }} color='primary'>
                        Processing{new Array(counter).fill('.').join('')}
                    </StyledTitle>
                    <Typography.Text type='body1' color='primary'>
                        We’re reviewing your application
                    </Typography.Text>
                </ProcessingContentWrap>
            </ProcessingStyles>
        );
    })
);

export default Processing;
