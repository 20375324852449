import React from 'react';
import { inject, observer } from 'mobx-react';
import { useMenuItems } from '../../../../../../hooks';
import { StyledMenu } from './styled';

const Menu = inject('store')(
    observer(({ store, mode = 'horizontal', isMenuOpen, onMenuClose }) => {
        const { activeChallenge } = store.seasonalChallengeStore;
        const items = useMenuItems(store, mode, isMenuOpen, onMenuClose);
        return (
            <StyledMenu
                mode={mode}
                items={items}
                getPopupContainer={() => document.querySelector('#app-wrapper')}
                onClick={() => {
                    if (activeChallenge) {
                        activeChallenge.setChallengeAccepted(false);
                    }
                }}
            />
        );
    })
);

export default Menu;
