import { notification, useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../../../../../../utils';
import { handleTaskCompleted } from '../utils';

export const useOnSubmit = (store) => {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    const { rateGiftlyTask, completeTask } = store.tasksStore;
    const { isApprovedCustomer, sendFeedback } = store.accountStore;
    return (data, callback = () => null) => {
        if (typeof notification.destroy === 'function') notification.destroy();
        const shouldCompleteTask = rateGiftlyTask && rateGiftlyTask?.data?.status === 'active' && isApprovedCustomer;
        if (shouldCompleteTask) {
            completeTask({
                taskPayload: data,
                taskId: rateGiftlyTask._id,
                onAfterSuccess: () => {
                    handleTaskCompleted(store, simple);
                    // eslint-disable-next-line promise/prefer-await-to-callbacks
                    callback();
                }
            });
        } else {
            sendFeedback({
                payload: data,
                onAfterSuccess: () => {
                    // eslint-disable-next-line promise/prefer-await-to-callbacks
                    callback();
                }
            });
        }
    };
};
