import React, { Fragment, useEffect, useState } from 'react';
import { ArrowChevronForwardIcon, Col, Icon, Row, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../../utils';
import { ROUTE_PATHS } from '../../../../constants';
import CategoriesMenu from './components/CategoriesMenu';
import { StyledLink } from './styled';

const ShopLink = inject('store')(
    observer(({ isMenuOpen, onMenuClose }) => {
        const simple = useSimpleInterface(GRID_POINTS.md);

        const [showMenu, setShowMenu] = useState(false);

        useEffect(() => {
            if (!isMenuOpen) setShowMenu(false);
        }, [isMenuOpen]);

        const onLinkClick = (e) => {
            if (simple) {
                e.preventDefault();
                e.stopPropagation();
                setShowMenu(true);
            }
        };

        return (
            <Fragment>
                <StyledLink to={ROUTE_PATHS.shop} onClick={onLinkClick}>
                    {simple ? (
                        <Row justify='space-between' align='middle'>
                            <Col>Categories</Col>
                            <Col>
                                <Icon component={() => <ArrowChevronForwardIcon />} />
                            </Col>
                        </Row>
                    ) : (
                        'Categories'
                    )}
                </StyledLink>
                <CategoriesMenu
                    showMenu={showMenu}
                    onMenuClose={onMenuClose}
                    onCategoriesClose={() => setShowMenu(false)}
                />
            </Fragment>
        );
    })
);

export default ShopLink;
