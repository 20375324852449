import React, { Fragment, useEffect } from 'react';
import { Icon, TOP_INDENTS, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../shared/components/Layout';
import SeasonalChallengeSteps from '../../shared/components/SeasonalChallengeSteps';
import { GRID_POINTS } from '../../utils';
import SeasonalChallengeBody from './components/SeasonalChallengeBody';
import SeasonalChallengeHeader from './components/SeasonalChallengeHeader';
import { StyledContainer, StyledHeader, StyledWrapper } from './styled';

const SeasonalChallenge = inject('store')(
    observer(({ store }) => {
        const { getSeasonalChallenge, pending, error } = store.seasonalChallengeStore;
        const simple = useSimpleInterface(GRID_POINTS.md);

        useEffect(() => {
            getSeasonalChallenge(true);
        }, []);

        return (
            <Layout withoutPadding hasFooter contentFullHeight={!simple}>
                <StyledContainer>
                    {pending ? (
                        <Icon.Loader />
                    ) : (
                        <Fragment>
                            {!error ? (
                                <Fragment>
                                    <StyledHeader>
                                        <StyledWrapper>
                                            <SeasonalChallengeHeader />
                                            <SeasonalChallengeSteps />
                                        </StyledWrapper>
                                    </StyledHeader>
                                    <StyledWrapper $maxWidth='1034px'>
                                        <SeasonalChallengeBody />
                                    </StyledWrapper>
                                </Fragment>
                            ) : (
                                <Typography.Text type='body1' align='center' color='primary' style={TOP_INDENTS.XXL}>
                                    {error}
                                </Typography.Text>
                            )}
                        </Fragment>
                    )}
                </StyledContainer>
            </Layout>
        );
    })
);

export default SeasonalChallenge;
