import React from 'react';
import { Button, DeleteIcon, EditIcon, Icon, RIGHT_INDENTS, Typography } from '@klickly/front-packages';
import { Styles } from './styles';

export const ShippingItemControls = ({ onEdit, onDelete, showDeleteButton = true, ...rest }) => {
    return (
        <Styles {...rest}>
            <Button type='unstyled' onClick={onEdit}>
                <Icon component={() => <EditIcon />} style={RIGHT_INDENTS.XXS} />
                <Typography.Text as='span'>Edit</Typography.Text>
            </Button>
            {showDeleteButton ? (
                <Button type='unstyled' onClick={onDelete}>
                    <Icon component={() => <DeleteIcon />} style={RIGHT_INDENTS.XXS} />
                    <Typography.Text as='span'>Delete</Typography.Text>
                </Button>
            ) : null}
        </Styles>
    );
};
