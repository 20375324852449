import styled from 'styled-components';
import { $background } from '../../shared';

export const StyledWrapper = styled.div`
    max-width: ${({ $maxWidth = '1400px' }) => $maxWidth};
    margin: 0 auto;
    padding: 0 20px;
    overflow-x: hidden;

    &&& {
        .ant-btn {
            .anticon {
                font-size: 24px;
            }
        }
    }
`;

export const StyledHeader = styled.div`
    margin-bottom: 32px;
    padding-top: 24px;
    background: ${$background};
`;

export const StyledContainer = styled.div`
    padding-bottom: 40px;
    min-height: calc(100% - 80px);
`;
