import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS, TASK_STATUSES } from '../../../shared';

export const useRedirectToUnfinishedStep = (store) => {
    const navigate = useNavigate();
    const { selectCategoriesTask, addToWishlistTask } = store.tasksStore;
    useEffect(() => {
        if (selectCategoriesTask?.data?.status === TASK_STATUSES.active) {
            return navigate(ROUTE_PATHS.personalizationCategories);
        }
        if (addToWishlistTask?.data?.status === TASK_STATUSES.active) {
            return navigate(ROUTE_PATHS.personalizationImportance);
        }
    }, [selectCategoriesTask?.data?.status, addToWishlistTask?.data?.status]);
};
