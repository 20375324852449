import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    ForwardArrowIcon,
    Icon,
    ProfileIcon,
    TOP_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $primary, ROUTE_PATHS } from '../../../../shared';
import { Logo } from '../../../../shared/components/Logo';
import {
    CloveImage,
    StyledAsideWrap,
    StyledButton,
    StyledContentWrap,
    StyledHeadLine1,
    StyledIconWrap,
    StyledImage,
    StyledTextWrap,
    StyledWrap
} from './styles';

const Approved = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { profileImageURL } = store.accountStore;
        const simple = useSimpleInterface(991);
        const simpleImage = useSimpleInterface(630);

        const goToPersonalization = () => {
            store.trackingStore.sendEvent({
                type: 'event',
                name: 'SignUp_CongratsStep_Complete'
            });
            navigate(ROUTE_PATHS.personalizationCategories);
        };

        return (
            <StyledWrap $simple={simple}>
                <StyledAsideWrap $simple={simple}>
                    {!simple ? (
                        <Fragment>
                            <Logo hasBottomIndent size='48px' color={$primary} />
                            {profileImageURL ? (
                                <StyledImage src={profileImageURL} />
                            ) : (
                                <StyledIconWrap>
                                    <Icon color='primary' size='80px' component={() => <ProfileIcon />} />
                                </StyledIconWrap>
                            )}
                            <Typography.Title style={TOP_INDENTS.XL} color='primary' align='center'>
                                You&#39;ve been accepted into Giftly!
                            </Typography.Title>
                        </Fragment>
                    ) : (
                        <Typography.Title level={2} color='primary' align='center'>
                            You&#39;ve been accepted into Giftly!
                        </Typography.Title>
                    )}
                </StyledAsideWrap>
                <StyledContentWrap $simple={simple}>
                    <StyledTextWrap>
                        <StyledHeadLine1>Let&#39;s personalize your Gifting Suite!</StyledHeadLine1>
                        <Typography.Text style={BOTTOM_INDENTS.XXL} type='body1' align='left'>
                            You&#39;re moments away from your 1st reward!
                        </Typography.Text>
                        <StyledButton
                            data-e2e='onboarding_start-button'
                            $simple={simple}
                            size='large'
                            type='primary'
                            ghost
                            onClick={goToPersonalization}
                        >
                            <Typography.Text type='badgeLarge' as='span'>
                                Get started
                            </Typography.Text>
                            <Icon stylePriority size='20px' component={() => <ForwardArrowIcon />} />
                        </StyledButton>
                    </StyledTextWrap>
                    <CloveImage simple={simpleImage} />
                </StyledContentWrap>
            </StyledWrap>
        );
    })
);

export default Approved;
