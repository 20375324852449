import { $primary, $primaryFade, $white, TASK_STATUSES } from '../../../../../shared';

export const getColorsByStatus = (status) => {
    switch (status) {
        case TASK_STATUSES.active:
            return { color: $white, background: $primaryFade, borderColor: $white, progressColor: $primaryFade };
        case TASK_STATUSES.completed:
            return { color: $primary, background: $white, borderColor: $white, progressColor: $white };
        default:
            return { color: $white, background: $primaryFade, borderColor: $primaryFade, progressColor: $primaryFade };
    }
};
