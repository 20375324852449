import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ForwardArrowIcon,
    GRID_POINTS,
    Icon,
    RIGHT_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS, TASK_STATUSES } from '../../../../shared';
import { Layout } from '../../../../shared/components/Layout';
import { PersonalizationHead } from '../../../../shared/components/PersonalizationHead';
import { useRedirectToUnfinishedStep } from '../../hooks';
import { useProductModal } from './components/ProductModal/hooks';
import { Promotions } from './components/Promotions';
import { ButtonWrap, StyledButton, StyledWrap } from './styles';
import { getPayload } from './utils';

const PersonalizationImportance = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const [selectedPromotions, setSelectedPromotions] = useState([]);
        const [taskCompleted, setTaskCompleted] = useState(false);
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { addToWishlistTask } = store.tasksStore;
        const { addSelectedProductsToWishlist, personalizationPending } = store.onboardingStore;

        const { openModal: openProductModal, closeModal } = useProductModal();

        const onClick = (promotion, selected) => {
            if (!selected) {
                const payload = getPayload(promotion);
                setSelectedPromotions((selectedPromotions) => [...selectedPromotions, payload]);
            } else {
                const data = selectedPromotions.filter((selected) => selected.productId !== promotion._id);
                setSelectedPromotions([...data]);
            }

            closeModal();
        };

        const isSelectedPromotion = (promotion = {}) => {
            return selectedPromotions.some((selected) => selected.productId === promotion._id);
        };

        const handleFinishStep = () => {
            if (taskCompleted) {
                return navigate(ROUTE_PATHS.personalizationShipping);
            }
            addSelectedProductsToWishlist({
                products: selectedPromotions,
                onComplete: onAfterSuccess
            });
        };

        const onAfterSuccess = () => {
            store.trackingStore.sendEvent({
                type: 'event',
                name: 'Onboarding_Step2_Complete'
            });
            navigate(ROUTE_PATHS.personalizationShipping);
        };

        useEffect(() => {
            if (addToWishlistTask?.data?.status === TASK_STATUSES.completed) setTaskCompleted(true);
        }, [addToWishlistTask?.data?.status]);

        useRedirectToUnfinishedStep(store);

        useEffect(() => {
            if (!store.tasksStore.tasksExists && !store.tasksStore.pending) store.tasksStore.getTasks();
        }, []);

        return (
            <Layout disabledMenu disableOnLogoClick menuBarHidden>
                <StyledWrap>
                    {personalizationPending ? <Icon.Loader /> : null}
                    <PersonalizationHead
                        step={2}
                        title='Which products are you most interested in?'
                        description='Choose up to 3 products you&#39;d love to see in your Gifting Suite'
                        simple={simple}
                        button={
                            !simple ? (
                                <StyledButton
                                    $simple={simple}
                                    htmlType='submit'
                                    type='primary'
                                    size='large'
                                    disabled={taskCompleted ? false : !selectedPromotions.length}
                                    onClick={handleFinishStep}
                                >
                                    <Typography.Text type='badgeLarge' style={RIGHT_INDENTS.S}>
                                        Next
                                    </Typography.Text>
                                    <Icon stylePriority size='24px' component={() => <ForwardArrowIcon />} />
                                </StyledButton>
                            ) : null
                        }
                    />
                    <Promotions
                        hidePrice
                        onSelectIconClick={onClick}
                        selectedPromotionIds={selectedPromotions.map(({ productId }) => productId)}
                        onBuy={(promotion) => {
                            openProductModal(promotion, isSelectedPromotion(promotion), onClick);
                            store.trackingStore.sendEvent({
                                type: 'event',
                                name: 'Onboarding_Step2_GoToPDP'
                            });
                        }}
                    />
                    <br />
                    <ButtonWrap>
                        <StyledButton
                            $simple={simple}
                            htmlType='submit'
                            type='primary'
                            size='large'
                            disabled={taskCompleted ? false : !selectedPromotions.length}
                            onClick={handleFinishStep}
                        >
                            <Typography.Text type='badgeLarge' style={RIGHT_INDENTS.S}>
                                Next
                            </Typography.Text>
                            <Icon stylePriority size='24px' component={() => <ForwardArrowIcon />} />
                        </StyledButton>
                    </ButtonWrap>
                </StyledWrap>
            </Layout>
        );
    })
);

export default PersonalizationImportance;
