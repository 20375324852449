import { useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../../../utils';
import { NOTIFICATION_MESSAGES } from '../../../constants';
import { useProductReviewNotification } from '../../../hooks/useProductReviewNotification';
import { useBuyProductNotification } from './useBuyProductNotification';
import { useShareProductNotification } from './useShareProductNotification';

export const usePdpNotifications = (store) => {
    const simple = useSimpleInterface(GRID_POINTS.md);
    const defaultSettings = {
        duration: null,
        bottom: simple ? '102px' : '8px'
    };

    useProductReviewNotification(store, NOTIFICATION_MESSAGES.PRODUCT_REVIEW[2]);
    useShareProductNotification(store, defaultSettings);
    const { isBuyProductTask } = useBuyProductNotification(store, defaultSettings);

    return { isBuyProductTask };
};
