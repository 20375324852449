import { ROUTE_PATHS } from '../../../../shared';

export const headerLinks = [
    {
        label: 'Beauty',
        link: ROUTE_PATHS.shop
    },
    {
        label: 'Nutrition',
        link: ROUTE_PATHS.shop
    },
    {
        label: 'Haircare',
        link: ROUTE_PATHS.shop
    },
    {
        label: 'Skincare',
        link: ROUTE_PATHS.shop
    },
    {
        label: 'Decor',
        link: ROUTE_PATHS.shop
    },
    {
        label: 'Wellness',
        link: ROUTE_PATHS.shop
    },
    {
        label: 'Personal care',
        link: ROUTE_PATHS.shop
    },
    {
        label: 'Other',
        link: ROUTE_PATHS.shop
    }
];
