import { flow, getParent, types } from 'mobx-state-tree';
import { strategies } from '../config/strategies';
import { kcpAPI } from '../shared';
import { models } from './constants';

export const DashboardStore = types
    .model('DashboardStore', {
        pending: types.boolean,
        error: models.ErrorModel,
        recentlyViewedPromotions: types.array(models.PromotionModel)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const getRecentlyViewedPromotions = flow(function* getRecentlyViewedPromotions() {
            self.pending = true;
            try {
                const data = yield self.root.get(
                    `${kcpAPI.marketplace.promotions.get}?strategyId=${strategies.recentlyViewed}`
                );
                self.recentlyViewedPromotions = data?.promotions || [];
            } catch (error) {
                self.error = error;
            } finally {
                self.pending = false;
            }
        });

        return { getRecentlyViewedPromotions };
    });
