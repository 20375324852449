import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const Styles = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 550px;

    @media (${GRID_DEVICES_MAX.lg}) {
        justify-content: flex-start;
        min-height: 600px;
    }
`;

export const ForgotPasswordContentStyles = styled.div`
    max-width: 413px;
    width: 100%;
    margin-left: -60px;
    margin-top: -10px;

    @media (${GRID_DEVICES_MAX.lg}) {
        margin: 0;
        padding-top: 16px;
    }

    @media (${GRID_DEVICES_MAX.xs}) {
        max-width: 90%;
        margin: 0;
    }
`;
