import React from 'react';
import { Button, Col, DeleteIcon, Icon, RIGHT_INDENTS, Row, Typography } from '@klickly/front-packages';

export const ShippingAddressFormControls = ({
    onDelete,
    showDeleteAddressButton,
    submitButtonText = 'Save changes',
    disabledSubmitButton = false,
    ...rest
}) => {
    return (
        <Row {...rest}>
            <Col span={24} md={{ span: 12 }}>
                <Button
                    htmlType='submit'
                    disabled={disabledSubmitButton}
                    type='primary'
                    size='large'
                    style={{ width: '100%' }}
                >
                    <Typography.Text type='badgeLarge'>{submitButtonText}</Typography.Text>
                </Button>
                {typeof onDelete === 'function' && showDeleteAddressButton && (
                    <Col span={24} md={{ span: 12 }}>
                        <Button
                            onClick={onDelete}
                            type='unstyled'
                            size='large'
                            style={{ width: '100%', marginTop: '20px' }}
                        >
                            <Icon
                                stylePriority
                                size='24px'
                                color='primary'
                                component={() => <DeleteIcon />}
                                style={RIGHT_INDENTS.XXS}
                            />
                            <Typography.Text type='badgeLarge' color='primary'>
                                Delete shipping address
                            </Typography.Text>
                        </Button>
                    </Col>
                )}
            </Col>
        </Row>
    );
};
