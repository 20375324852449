import React from 'react';
import { Col, Row } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { useConfirmDeletingModal } from '../../../../../../../../shared/components/ConfirmDeletingModal/hooks';
import { ShippingItemControls } from '../../../ShippingItemControls';
import { ShippingItemAddress } from './components/ShippingItemAddress';
import { Styles } from './styles';

export const ShippingItem = inject('store')(
    observer(({ address, onEdit, onDelete, onAsDefault, showDeleteButton, isDefault, simple, ...rest }) => {
        const confirmDeleting = useConfirmDeletingModal({
            title: 'Delete shipping',
            bodyText: 'Are you sure you want to delete this shipping address?',
            onConfirm: onDelete
        });

        const onShippingItemClick = () => {
            if (simple) {
                return onEdit();
            }

            if (!address.isDefault) {
                return onAsDefault();
            }
        };

        return (
            <Row {...rest}>
                <Col span={24} md={{ span: 12 }}>
                    <Styles $simple={simple} onClick={onShippingItemClick}>
                        <ShippingItemAddress address={address} isDefault={isDefault} simple={simple} />
                    </Styles>
                </Col>
                <Col span={24} md={{ span: 12 }}>
                    {!simple ? (
                        <ShippingItemControls
                            onDelete={confirmDeleting}
                            showDeleteButton={showDeleteButton}
                            onEdit={onEdit}
                            simple={simple}
                        />
                    ) : null}
                </Col>
            </Row>
        );
    })
);
