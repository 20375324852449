import React from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { GRID_POINTS, Search, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { Layout } from '../../shared/components/Layout';
import { CardsSection } from './components/CardsSection';
import { InfoSection } from './components/InfoSection';
import { LastOrderSection } from './components/LastOrderSection';
import { ProfileSection } from './components/ProfileSection';
import { RecentlyViewedSection } from './components/RecentlyViewedSection';
import SeasonalChallengeSection from './components/SeasonalChallengeSection';
import { StyledContent, StyledContentLeft, StyledContentRight, StyledSearchWrap, StyledWrap } from './styled';

const Dashboard = inject('store')(
    observer(() => {
        const location = useLocation();
        const navigationType = useNavigationType();
        const simple = useSimpleInterface(GRID_POINTS.lg);
        const withSearch = useSimpleInterface(GRID_POINTS.md);

        return (
            <Layout hasFooter contentFullHeight={false} withoutPadding={false}>
                <StyledWrap $simple={simple} id='dashboard-container'>
                    {withSearch ? (
                        <StyledSearchWrap>
                            <Search location={location} navigationType={navigationType} />
                        </StyledSearchWrap>
                    ) : null}
                    <StyledContent $simple={simple}>
                        <StyledContentLeft $simple={simple}>
                            <ProfileSection simple={simple} />
                            {!simple && <CardsSection position='side' />}
                        </StyledContentLeft>
                        <StyledContentRight $simple={simple}>
                            <SeasonalChallengeSection />
                            {simple && <CardsSection position='main' />}
                            <InfoSection simple={simple} />
                            <LastOrderSection simple={simple} />
                            <RecentlyViewedSection simple={simple} />
                        </StyledContentRight>
                    </StyledContent>
                </StyledWrap>
            </Layout>
        );
    })
);

export default Dashboard;
