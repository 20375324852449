import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { GRID_POINTS, Promotion, useSimpleInterface } from '@klickly/front-packages';
import placeholder from '../../../assets/img/image-placeholder.svg';
import SliderHeader from './components/SliderHeader';
import { useSliderSettings } from './hooks';
import { StyledProductWrapper, StyledSliderWrapper, StyledWrapper } from './styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PromotionsSlider = ({
    promotions,
    title,
    categoryKey,
    isRecommendation = false,
    settings = {},
    activeChallengeName,
    showButtonsOnTop = false,
    promotionsHeight = '370px',
    promotionsWithoutButton = false,
    seAllBtnText = null,
    seAllBtnAction = null,
    withoutHeader = false,
    divider
}) => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderSettings = useSliderSettings(settings, showButtonsOnTop);
    const slider = useRef(null);
    const isLessThan1200px = useSimpleInterface(GRID_POINTS.xl);

    return (
        <StyledWrapper>
            {!withoutHeader ? (
                <SliderHeader
                    sliderRef={slider}
                    title={title}
                    prevDisabled={currentSlide === 0}
                    nextDisabled={currentSlide + (isLessThan1200px ? 2 : 3) >= promotions.length}
                    currentSlide={currentSlide}
                    slidesCount={promotions.length}
                    isRecommendation={isRecommendation}
                    showButtonsOnTop={showButtonsOnTop}
                    seAllBtnText={seAllBtnText}
                    seAllBtnAction={seAllBtnAction}
                />
            ) : null}
            {divider}
            <StyledSliderWrapper
                $singleItem={promotions?.length < (isLessThan1200px ? 2 : 3)}
                $promotionsHeight={promotionsHeight}
            >
                <Slider
                    ref={slider}
                    {...sliderSettings}
                    beforeChange={(oldIndex, newIndex) => setCurrentSlide(newIndex)}
                >
                    {promotions.map((promotion) => (
                        <StyledProductWrapper key={promotion._id}>
                            <Promotion
                                placeholder={placeholder}
                                withoutButton={promotionsWithoutButton}
                                inSlider
                                onBuy={(promotion) =>
                                    navigate(
                                        {
                                            pathname: `/marketplace/${promotion._id}`,
                                            search
                                        },
                                        {
                                            state: activeChallengeName
                                        }
                                    )
                                }
                                promotion={promotion}
                            />
                        </StyledProductWrapper>
                    ))}
                </Slider>
            </StyledSliderWrapper>
        </StyledWrapper>
    );
};

export default PromotionsSlider;
