import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowChevronForwardIcon, Icon, Image, roundToLocaleString, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import qs from 'qs';
import { ROUTE_PATHS } from '../../../../../../../../../../../../../../shared';
import { useCancelOrderModal } from '../../../../../../../../../../hooks';
import {
    ArrowButton,
    BrandLogoPlaceholder,
    CancelButton,
    OrderContent,
    OrderContentLeft,
    OrderContentRight,
    OrderImg,
    OrderStatus,
    StyledHeadLine3,
    StyledText,
    Styles
} from './styles';

export const Order = inject('store')(
    observer(({ store, order, ...rest }) => {
        const { orderNumber, orderStatus, prices, isAvailableForCancel, checkoutId, items } = order;
        const {
            result: { total }
        } = prices;
        const openCancelOrderModal = useCancelOrderModal(order._id);
        const challenge = store.tasksStore?.writeProductReviewTask;

        const images = items.filter(({ image }) => image).map(({ image }) => image);
        const isRefunded = orderStatus === 'refunded';

        const content = (
            <Styles {...rest}>
                <OrderContentLeft>
                    <OrderImg>
                        {images.length ? <Image src={images[0]} alt='product' /> : <BrandLogoPlaceholder />}
                    </OrderImg>
                    <OrderContent>
                        <StyledHeadLine3 level={3}>Order {orderNumber}</StyledHeadLine3>
                        <StyledText type='body2'>${roundToLocaleString(total)}</StyledText>
                        <OrderStatus
                            type='descriptor2'
                            color={orderStatus !== 'refunded' ? 'secondaryText' : 'primary'}
                            data-e2e={`acc-settings-order_status-${orderStatus}_${order._id}`}
                        >
                            {orderStatus}
                        </OrderStatus>
                    </OrderContent>
                </OrderContentLeft>
                <OrderContentRight>
                    {isAvailableForCancel ? (
                        <CancelButton
                            type='secondary'
                            onClick={(e) => {
                                e.preventDefault();
                                return openCancelOrderModal();
                            }}
                            data-e2e={`acc-settings-right-cancel-btn-order_${order._id}`}
                        >
                            <Typography.Text type='badgeSmall'>Cancel</Typography.Text>
                        </CancelButton>
                    ) : null}
                    {checkoutId && !isRefunded ? (
                        <ArrowButton type='text' data-e2e={`acc-settings-order_${order._id}-right-arrow-btn`}>
                            <Icon stylePriority component={() => <ArrowChevronForwardIcon />} />
                        </ArrowButton>
                    ) : null}
                </OrderContentRight>
            </Styles>
        );

        return isRefunded ? (
            content
        ) : (
            <Link
                state={{ alias: challenge?.alias }}
                to={`${ROUTE_PATHS.orders}/${order._id}?${qs.stringify({ orderNumber })}`}
            >
                {content}
            </Link>
        );
    })
);
