import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../utils';
import { $white } from '../../constants';

export const StyledWrapper = styled.div`
    position: relative;
    height: 205px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 24px 80px;
    margin-bottom: 80px;
    cursor: pointer;
    max-width: 1025px;
    margin-left: auto;
    margin-right: auto;

    @media (${GRID_DEVICES_MAX.md}) {
        justify-content: center;
        margin-left: 0;
        margin-bottom: 64px;
        padding: 0;
    }
`;

export const StyledImgWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    img {
        width: 100%;
        height: auto;
        max-height: 100%;
        object-fit: cover;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        img {
            height: 100%;
        }
    }
`;

export const StyledTitle = styled(Typography.Title)`
    && {
        width: 50%;
        right: 75px;
        position: absolute;
        overflow: hidden;
        font-size: ${({ $fontSize = '56px' }) => $fontSize};
        line-height: 110%;
        text-align: right;
        text-overflow: ellipsis;
        color: ${$white};
        z-index: 1;

        @media (${GRID_DEVICES_MAX.md}) {
            width: calc(100% - 48px);
            right: 24px;
            text-align: center;
        }
    }
`;
