import { Image, KliButton, KliIcon, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $primaryText } from '../../../../../../shared';

export const StyledWrap = styled.div`
    width: 100%;
    border: 2px dashed ${$primaryText};
    ${({ $pointer }) =>
        $pointer
            ? css`
                  cursor: pointer;
              `
            : ''}
`;

export const StyledContent = styled.div`
    padding: 24px;
    display: flex;
    justify-content: flex-start;
`;

export const StyledImgWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
`;

export const StyledInfo = styled.div``;

export const StyledTitle = styled(Typography.Title)`
    margin-bottom: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledDescription = styled.div`
    margin: 6px 0;
    ${({ $showFullDescription }) =>
        $showFullDescription
            ? css``
            : css`
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
              `};
`;

export const StyledButton = styled(KliButton)`
    padding: 12px 0;
`;

export const StyledBottom = styled.div`
    display: flex;
    justify-content: ${({ $simple }) => ($simple ? 'flex-start' : 'center')};
    align-items: center;
    padding: ${({ $simple }) => ($simple ? '4px 0 0' : '0 0 16px')};
`;

export const StyledImg = styled(Image)`
    && {
        object-fit: contain;

        ${({ $simple }) =>
            $simple
                ? css`
                      width: 106px;
                      height: 106px;
                  `
                : css`
                      width: 106px;
                      height: 106px;
                  `}
    }
`;

export const StyledIcon = styled(KliIcon)`
    margin-left: 6px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;
