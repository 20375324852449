import styled from 'styled-components/macro';
import { $border, $secondaryText } from '../../../../../../../../shared';

export const ExpansionHeadStyles = styled.div`
    width: 100%;
    cursor: pointer;
    padding: 24px;

    border-bottom: ${({ $opened }) => ($opened ? `1px solid ${$border}` : '1px solid transparent')};
`;

export const ExpansionHeadRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const OrderSummaryWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Message = styled.div`
    display: flex;
    align-items: center;
    color: ${$secondaryText};
`;
