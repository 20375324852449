import styled from 'styled-components';
import { $background, $primary, $primaryBackground, $secondaryText } from '../../../../constants';

export const ItemWrap = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    &:hover {
        .icon-wrap {
            color: ${$primary};
            background-color: ${$primaryBackground};
        }
    }
`;

export const IconWrap = styled.span`
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
    background-color: ${$background};
    color: ${$secondaryText};

    .kli-icon,
    .anticon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
`;

export const ContentWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;
