import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowChevronBackIcon, Button, Col, Divider, Row, Taxonomy, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $border, ROUTE_PATHS } from '../../../../../../../../constants';
import { StyledBackButton, StyledDividerWrapper, StyledWrapper } from './styled';

const MenuTitle = inject('store')(
    observer(({ store, onCategoriesClose, setSelectedSubCategory, selectedSubCategory, onMenuClose }) => {
        const navigate = useNavigate();
        const { getParentCategoryByChildTitle, setSelectedCategories } = Taxonomy.store;
        const { clearAll } = store.marketplaceStore;
        const { clearAll: clearBrandsSearchParams } = store.brandPageStore;
        const isRoot = !selectedSubCategory?.children?.length;

        const handleSeeAll = () => {
            clearBrandsSearchParams({ refetch: false });
            clearAll();
            setSelectedCategories([]);
            onMenuClose();
            return navigate(ROUTE_PATHS.shop);
        };

        const handleGoBack = () =>
            isRoot
                ? onCategoriesClose()
                : setSelectedSubCategory(getParentCategoryByChildTitle(selectedSubCategory?.title));

        return (
            <StyledWrapper>
                <Row justify='space-between' align='baseline' wrap={false}>
                    <Col flex='auto'>
                        <Row align='middle' wrap={false}>
                            <Col flex='56px'>
                                <StyledBackButton
                                    type='unstyled'
                                    icon={<ArrowChevronBackIcon />}
                                    onClick={handleGoBack}
                                />
                            </Col>
                            <Col>
                                <Typography.Title>
                                    {selectedSubCategory?.children?.length ? selectedSubCategory?.title : 'Shop'}
                                </Typography.Title>
                            </Col>
                        </Row>
                    </Col>
                    <Col flex='94px'>
                        <Button type='unstyled' onClick={handleSeeAll}>
                            <Typography.Text type='descriptor1'>See All</Typography.Text>
                        </Button>
                    </Col>
                </Row>
                <StyledDividerWrapper>
                    <Divider height='1px' color={$border} />
                </StyledDividerWrapper>
            </StyledWrapper>
        );
    })
);

export default MenuTitle;
