import { NavLink } from 'react-router-dom';
import { Button } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../utils';

export const StyledButton = styled(Button)`
    && {
        margin-right: -44px;
    }
`;

export const StyledLink = styled(NavLink)`
    @media (${GRID_DEVICES_MAX.md}) {
        display: block;
        margin-right: -20px;

        .anticon {
            width: 16px;
            height: 16px;
            font-size: 16px;
        }
    }
`;
