import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { app, bugsnag } from '../../config';

const ErrorBoundary = (props) => {
    if (bugsnag.apiKey) {
        Bugsnag.start({
            apiKey: bugsnag.apiKey,
            autoTrackSessions: false,
            releaseStage: app.releaseStage,
            plugins: [new BugsnagPluginReact()]
        });

        const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

        return <BugsnagErrorBoundary>{props.children}</BugsnagErrorBoundary>;
    }

    return props.children;
};

export default ErrorBoundary;
