import React from 'react';
import { Button, ConfigureIcon, Icon, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { PROMOTIONS_PRICE_STATE } from '../../../../shared';
import { toLocalUSString } from '../../../../utils';
import { CheckedFiltersList } from './components/CheckedFiltersList';
import { CheckedPrice } from './components/CheckedPrice';
import { Sorting } from './components/Sorting';
import { Filters, Item, Styles, Total } from './styled';
import { getTotalCountViewValue } from './utils';

export const CheckedFilters = inject('store')(
    observer(({ store, simple }) => {
        const {
            totalHits,
            totalShown,
            updateIsOpenOnMobile,
            updateCategory,
            updatePrice,
            clearAll,
            query: { price, categories }
        } = store.brandPageStore;

        const checkedCategories = categories.filter((category) => category.checked);

        return (
            <Styles>
                {simple && (
                    <Button type='primary' onClick={() => updateIsOpenOnMobile(true)}>
                        <Icon stylePriority size='24px' component={() => <ConfigureIcon />} />
                        <Typography.Text type='body2'>Filter</Typography.Text>
                    </Button>
                )}

                <Sorting />

                <Filters>
                    {simple && totalShown ? (
                        <Total>
                            <Typography.Text type='descriptor2' color='secondaryText'>
                                {toLocalUSString(totalShown)} of {getTotalCountViewValue(totalHits)} results
                            </Typography.Text>
                        </Total>
                    ) : null}

                    <CheckedFiltersList list={checkedCategories} onRemove={updateCategory} />
                    <CheckedPrice price={price} onRemove={updatePrice} />
                    {(checkedCategories.length ||
                        price.lte !== PROMOTIONS_PRICE_STATE.lte ||
                        price.gte !== PROMOTIONS_PRICE_STATE.gte) && (
                        <Item cantClose onClick={() => clearAll()} type='primary'>
                            Clear All
                        </Item>
                    )}

                    {!simple && totalShown ? (
                        <Total>
                            <Typography.Text type='descriptor2' color='secondaryText'>
                                {toLocalUSString(totalShown)} of {getTotalCountViewValue(totalHits)} results
                            </Typography.Text>
                        </Total>
                    ) : null}
                </Filters>
            </Styles>
        );
    })
);
