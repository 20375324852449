import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Icon, TimeIcon } from '@klickly/front-packages';
import { getTimeLeft, msToDisplayFormat } from '../../../utils';
import { AnimatedTime } from './components/AnimatedTime';
import { Styles, Text, Title } from './styles';

export const Timer = ({
    endDate,
    speed = 1000,
    isTimerPaused,
    color,
    onTimeLeft,
    type = 'body2',
    showIcon = true,
    text,
    align = 'left',
    style,
    level,
    params = {},
    ...props
}) => {
    const [timeLeft, setTimeLeft] = useState();
    const timer = useRef(null);

    useEffect(() => {
        setTimeLeft(getTimeLeft(endDate));
    }, [setTimeLeft, endDate]);

    useEffect(() => {
        timer.current = setTimeout(() => {
            const newTimeLeft = getTimeLeft(endDate);

            if (!timeLeft && isTimerPaused) {
                clearTimeout(timer.current);
                return;
            }

            if (timeLeft && newTimeLeft > 0) {
                setTimeLeft(newTimeLeft);
            }

            if (newTimeLeft <= 0 && onTimeLeft) {
                onTimeLeft();
            }

            clearTimeout(timer.current);
        }, speed);

        return () => clearTimeout(timer.current);
    }, [speed, timeLeft, setTimeLeft, endDate, onTimeLeft, isTimerPaused]);

    const getDisplayTime = useCallback(() => {
        return msToDisplayFormat(timeLeft);
    }, [timeLeft]);

    return (
        <Styles style={style} {...props}>
            {showIcon && (
                <Fragment>
                    <Icon size='14px' color={color} component={() => <TimeIcon />} />
                    &nbsp;&nbsp;
                </Fragment>
            )}
            {level ? (
                <Title color={color} level={level}>
                    <AnimatedTime time={getDisplayTime()} text={text} align={align} params={params} />
                </Title>
            ) : (
                <Text color={color} type={type} as='div'>
                    <AnimatedTime time={getDisplayTime()} text={text} align={align} params={params} />
                </Text>
            )}
        </Styles>
    );
};
