import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthPage from '../components/AuthPage';
import BrandPage from '../components/BrandPage';
import CustomerBlockedPage from '../components/CustomerBlocked';
import Dashboard from '../components/Dashboard';
import Deals from '../components/Deals';
import HelpCenter from '../components/HelpCenter';
import { ROUTES as HELP_PAGE_ROUTES } from '../components/HelpPage/constants/routes';
import InviteAndEarnPage from '../components/InviteAndEarnPage';
import LandingPage from '../components/Landing';
import OnboardingPage from '../components/OnboardingPage';
import ProductPage from '../components/ProductPage';
import RewardRedirect from '../components/RewardRedirect';
import RewardsPage from '../components/RewardsPage';
import SeasonalChallengePage from '../components/SeasonalChallenge';
import { ROUTES as SETTINGS_ROUTES } from '../components/SettingsPage/constants/routes';
import ShareProductLink from '../components/ShareProductLink';
import ShopPage from '../components/ShopPage';
import ShoppingCartPage from '../components/ShoppingCartPage';
import SuitePage from '../components/SuitePage';
import ThankYouPage from '../components/ThankYouPage';
import UnauthorizedPreCheckout from '../components/UnauthorizedPreCheckout';
import { Upsell } from '../components/Upsell';
import Verify from '../components/Verify';
import { ROUTE_PATHS } from '../shared';
import ProtectedRoute from '../shared/components/ProtectedRoute';

const UnsubscribePage = React.lazy(() => import('../components/UnsubscribePage'));
const GsThankYouPage = React.lazy(() => import('../components/GsThankYouPage'));
const PointsInfo = React.lazy(() => import('../components/PointsInfo'));
const PointsPage = React.lazy(() => import('../components/PointsPage'));
const ExtensionUninstall = React.lazy(() => import('../components/ExtensionUninstall'));
const HelpPage = React.lazy(() => import('../components/HelpPage'));
const SettingsPage = React.lazy(() => import('../components/SettingsPage'));
export const ROUTES = [
    {
        path: ROUTE_PATHS.root,
        index: true,
        element: <LandingPage />
    },
    {
        path: ROUTE_PATHS.verify,
        element: <Verify />
    },
    {
        path: ROUTE_PATHS.blocked,
        element: <CustomerBlockedPage />
    },
    {
        path: ROUTE_PATHS.auth,
        element: <AuthPage />,
        children: AuthPage.ROUTES
    },
    {
        path: ROUTE_PATHS.onboarding,
        element: <OnboardingPage />,
        children: OnboardingPage.ROUTES
    },
    {
        path: ROUTE_PATHS.shoppingCart,
        element: <ShoppingCartPage />
    },
    {
        path: ROUTE_PATHS.preCheckout,
        index: true,
        element: <UnauthorizedPreCheckout />
    },
    {
        path: ROUTE_PATHS.upsell,
        index: true,
        element: <Upsell />
    },
    {
        path: ROUTE_PATHS.settings,
        element: (
            <ProtectedRoute>
                <SettingsPage />
            </ProtectedRoute>
        ),
        children: SETTINGS_ROUTES
    },
    {
        path: ROUTE_PATHS.suite,
        element: <SuitePage />,
        children: SuitePage.ROUTES
    },
    {
        path: ROUTE_PATHS.rewards,
        element: <RewardsPage />,
        children: RewardsPage.ROUTES
    },
    {
        path: ROUTE_PATHS.inviteAndEarn,
        element: <InviteAndEarnPage />,
        children: InviteAndEarnPage.ROUTES
    },
    {
        path: ROUTE_PATHS.rewardsApplyRedirect,
        element: <RewardRedirect />
    },
    {
        path: ROUTE_PATHS.shop,
        element: <ShopPage />,
        children: ShopPage.ROUTES
    },
    {
        path: ROUTE_PATHS.deals,
        element: <Deals />
    },
    {
        path: ROUTE_PATHS.brand,
        element: <BrandPage />
    },
    {
        path: ROUTE_PATHS.seasonalChallenge,
        element: <SeasonalChallengePage />
    },
    {
        path: ROUTE_PATHS.points,
        element: <PointsPage />
    },
    {
        path: ROUTE_PATHS.pointsInfo,
        element: <PointsInfo />
    },
    {
        path: ROUTE_PATHS.pdpProductPage,
        element: <ProductPage />
    },
    {
        path: ROUTE_PATHS.helpPage,
        element: <HelpPage />,
        children: HELP_PAGE_ROUTES
    },
    {
        path: ROUTE_PATHS.helpCenter,
        element: <HelpCenter />
    },
    {
        path: ROUTE_PATHS.betaTerms,
        element: <Navigate to={ROUTE_PATHS.helpPageBetaTerms} replace />
    },
    {
        path: ROUTE_PATHS.terms,
        element: <Navigate to={ROUTE_PATHS.helpPageTerms} replace />
    },
    {
        path: ROUTE_PATHS.privacy,
        element: <Navigate to={ROUTE_PATHS.helpPagePrivacy} replace />
    },
    {
        path: ROUTE_PATHS.privacyOptOut,
        element: <Navigate to={ROUTE_PATHS.helpPagePrivacyOptOut} replace />
    },
    {
        path: ROUTE_PATHS.checkoutThankYouPage,
        element: <ThankYouPage />
    },
    {
        path: ROUTE_PATHS.gsThankYouPage,
        element: <GsThankYouPage />
    },
    {
        path: ROUTE_PATHS.shareProductLink,
        index: true,
        element: <ShareProductLink />
    },
    {
        path: ROUTE_PATHS.extensionUninstall,
        index: true,
        element: <ExtensionUninstall />
    },
    {
        path: ROUTE_PATHS.unsubscribe,
        index: true,
        element: <UnsubscribePage />
    },
    {
        path: ROUTE_PATHS.dashboard,
        index: true,
        element: <Dashboard />
    }
];
