import { create as createIframe } from '../elements/iframe';

export const loadTW = ({ src }, options = {}) => {
    const scriptUrl = new URL(src);
    const trackViewOn = scriptUrl.searchParams.get('track_view_event_on');
    const { id, loadOn, queryParams = {} } = options;
    const iframeId = id || 'kl_tw_iframe';

    for (const [key, value] of Object.entries(queryParams)) {
        scriptUrl.searchParams.set(key, value);
    }

    const el = document.getElementById(iframeId);

    if (!el && (!loadOn || trackViewOn === loadOn)) {
        createIframe({
            id: iframeId,
            src: scriptUrl.toString()
        });
    }
};
