import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, Icon, InfoIcon, RIGHT_INDENTS, Typography } from '@klickly/front-packages';
import { ROUTE_PATHS } from '../../../../../../shared';
import { StyledBadge, StyledButton, StyledContent, StyledGSInfo, StyledText } from '../../styled';

export const SuiteReadyContent = ({ simple }) => {
    const navigate = useNavigate();

    return (
        <StyledGSInfo $simple={simple}>
            <StyledText
                level={1}
                color='secondaryText'
                align={simple ? 'center' : 'left'}
                style={simple ? { ...BOTTOM_INDENTS.M } : {}}
            >
                <span>Your Gifting Suite</span> is ready to launch!
            </StyledText>
            <StyledContent $simple={simple}>
                <StyledButton type='secondary' onClick={() => navigate(ROUTE_PATHS.suiteGs)} $simple={simple}>
                    <Typography.Text type='badgeSmall' color='primary'>
                        Launch
                    </Typography.Text>
                </StyledButton>
                <StyledBadge onClick={() => navigate(ROUTE_PATHS.suiteGsInfo)}>
                    <Icon size='16px' color='primary' component={() => <InfoIcon />} style={{ ...RIGHT_INDENTS.XXS }} />
                    <Typography.Text type='badgeSmall' color='primary'>
                        What is a Gifting Suite?
                    </Typography.Text>
                </StyledBadge>
            </StyledContent>
        </StyledGSInfo>
    );
};
