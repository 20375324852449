import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ActionsPlusIcon,
    BOTTOM_INDENTS,
    Button,
    Col,
    Icon,
    RIGHT_INDENTS,
    Row,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../shared';
import { GRID_POINTS } from '../../../../../../utils';
import { BillingItem } from './components/BillingItem';
import { StyledButton, StyledHead } from './styles';

export const BillingContainer = inject('store')(
    observer(({ store, ...rest }) => {
        const navigate = useNavigate();
        const {
            billingData: data,
            getBillingData,
            deleteBillingProfile,
            setBillingProfileAsDefault,
            billingPending
        } = store.accountStore;
        const showDeleteButton = data && data.length && data.length > 1;
        const simple = useSimpleInterface(GRID_POINTS.sm);

        useEffect(() => {
            getBillingData();
        }, []);

        const onAddBilling = () => {
            navigate(ROUTE_PATHS.billingAdd);
        };

        const onEditBilling = (billingId) => {
            navigate({
                pathname: ROUTE_PATHS.billingEdit,
                search: `id=${billingId}`
            });
        };

        return (
            <div {...rest} style={BOTTOM_INDENTS.L}>
                <StyledHead style={BOTTOM_INDENTS.SM}>
                    <Typography.Title level={3}>Billing</Typography.Title>
                    {simple && (
                        <Button onClick={onAddBilling} type='unstyled' style={{ padding: '0' }}>
                            <Icon stylePriority component={() => <ActionsPlusIcon />} style={RIGHT_INDENTS.XXS} />
                            <Typography.Text as='span'>Add</Typography.Text>
                        </Button>
                    )}
                </StyledHead>
                {billingPending && <Icon.Loader />}
                {data && data.length ? (
                    <Fragment>
                        {data.map((payment) => (
                            <BillingItem
                                key={payment._id}
                                payment={payment}
                                onEdit={() => onEditBilling(payment._id)}
                                onDelete={() => deleteBillingProfile(payment._id)}
                                onAsDefault={() => setBillingProfileAsDefault(payment._id)}
                                showDeleteButton={showDeleteButton}
                                simple={simple}
                            />
                        ))}
                        {!simple && (
                            <Button onClick={onAddBilling} type='unstyled'>
                                <Icon stylePriority component={() => <ActionsPlusIcon />} />
                                <Typography.Text as='span'>Add</Typography.Text>
                            </Button>
                        )}
                    </Fragment>
                ) : (
                    <Row>
                        <Col span={24} md={{ span: 12 }}>
                            <StyledButton onClick={onAddBilling} size='large' type='dashed'>
                                <Icon stylePriority component={() => <ActionsPlusIcon />} />
                                <Typography.Text as='span'>Add billing info</Typography.Text>
                            </StyledButton>
                        </Col>
                    </Row>
                )}
            </div>
        );
    })
);
