import { Icon } from '@klickly/front-packages';
import styled from 'styled-components';
import { $secondaryText } from '../../../../constants';

export const StyledIcon = styled(Icon)`
    svg {
        width: 16px;
        height: 16px;
        color: ${$secondaryText};
    }
`;
