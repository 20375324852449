import { GRID_DEVICES_MAX } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledLink = styled.div`
    height: 80px;
    padding: 0 80px;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12);

    @media (${GRID_DEVICES_MAX.md}) {
        height: 64px;
        padding: 0 36px;
    }
`;
