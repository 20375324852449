import { Icon } from '@klickly/front-packages';
import styled from 'styled-components/macro';

export default styled(Icon)`
    padding: 0 5px;

    &:after {
        display: none;
    }
`;
