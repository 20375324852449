import React, { useState } from 'react';
import { KliCoupon, Promotion, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import placeholder from '../../../../../../../../assets/img/image-placeholder.svg';
import { useCountDown } from '../../../../../../../../shared';
import { getRewardStatus } from './utils';

const Coupon = inject('store')(
    observer(({ store, reward, ...rest }) => {
        const [status, setStatus] = useState(getRewardStatus(reward.status));
        const { unclipReward, undoUnclipReward, getRewardDetails } = store.rewardsStore;
        const expireCountDown = useCountDown(reward?.expireAt, () => {
            if (reward?.expireAt && status !== 'redeemed') setStatus('expired');
        });

        const onRewardClick = () => {
            store.trackingStore.sendEvent({
                type: 'event',
                name: 'RewardsPage_Rewards_CouponClicked'
            });
            getRewardDetails(reward._id);
        };

        const renderTimer = () => {
            if (!reward.expireAt || status !== 'available') return null;

            const expireInMins = (new Date(reward.expireAt) - Date.now()) / 1000 / 60;

            if (expireInMins < 0) {
                return (
                    <Typography.Text type='body2' color='primary'>
                        Expired
                    </Typography.Text>
                );
            }

            return (
                <Typography.Text type='body2' color='primary'>
                    Expires {expireCountDown}
                </Typography.Text>
            );
        };

        return (
            <KliCoupon
                data-e2e='reward-coupon-block'
                status={status}
                align='center'
                data-klpixel-omit-event-click
                img={
                    <Promotion.Img
                        placeholder={placeholder}
                        height='106px'
                        width='106px'
                        images={[{ src: reward?.image, position: 1 }]}
                    />
                }
                onClick={onRewardClick}
                onUseRewardClick={onRewardClick}
                onDeleteClick={() => unclipReward(reward._id)}
                onUndoDeletingClick={() => undoUnclipReward(reward._id)}
                title={reward?.title}
                description={reward?.description}
                additionalDetailsContent={renderTimer()}
                {...rest}
            />
        );
    })
);

export default Coupon;
