import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, device, Icon, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { strategies } from '../../config/strategies';
import { ROUTE_PATHS, useBuyProductNotification, useClearCartPixels, useOnPurchaseSuccess } from '../../shared';
import { Layout } from '../../shared/components/Layout';
import { handleOrderSuccess } from '../../shared/utils';
import { get } from '../../utils';
import { useCardsData } from '../Dashboard/components/CardsSection/hooks';
import LeftContent from './components/LeftContent';
import RightContent from './components/RightContent';
import { getThankYouPageContent } from './utils/getThankYouPageContent';
import { useOrderCompleted } from './hooks';
import { StyledRow } from './styled';
import { checkOrderType } from './utils';

const ThankYouPage = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const strategyId = strategies.pointsToSpendStrategy;
        const { getStrategyInfoById, strategyInfo } = store.marketplaceStore;
        const { pending, orderNumber, gsClaims, cart, getProductTypes } = store.thankYouPageStore;
        const { isLoggedIn, signUpStatusRejected, profileEmail } = store.accountStore;
        const mobile = useSimpleInterface(768);
        const isIos = device.iOS();
        const isSafari = device.isSafari();

        const { result } = useCardsData(store);
        const pointsData = get(result, 'points', {});
        const strategyName = get(strategyInfo, 'name', '');

        const { checkoutOrderCode, checkoutOrderId } = useOnPurchaseSuccess(store);
        const { onCompleteTask } = useBuyProductNotification(store);

        const isGsClaim = getProductTypes?.gs.length;
        const isFirstClaim = gsClaims === 1 && !!isGsClaim;

        const pointsNumber = parseFloat(pointsData?.value?.replace(/,/g, '')) || 0;
        const pointsString = pointsData.value;
        const orderType = checkOrderType(store, isFirstClaim, pointsNumber, cart);

        const onClickStrategy = () => {
            navigate({
                pathname: ROUTE_PATHS.shop,
                search: `?strategy=${strategyName}`
            });
        };

        const THANK_YOU_PAGE_CONTENT = getThankYouPageContent(
            navigate,
            orderNumber,
            pointsString,
            pointsNumber,
            mobile,
            profileEmail,
            onClickStrategy
        );

        useEffect(() => {
            if (strategyId) {
                getStrategyInfoById(strategyId);
            }
        }, []);

        useOrderCompleted({ store, cart });

        useEffect(() => {
            handleOrderSuccess({ cart: store.thankYouPageStore.cart, onCompleteTask });
        }, [store.thankYouPageStore.cart]);
        useClearCartPixels(store);

        return !pending && orderType && (checkoutOrderCode || checkoutOrderId) ? (
            <Layout contentFullHeight withoutPadding withoutBottomPadding={isSafari && isIos}>
                <StyledRow>
                    <Col xs={24} md={12}>
                        <LeftContent
                            orderId={cart.bags[0]?.klicklyOrderId}
                            orderNumber={orderNumber}
                            mobile={mobile}
                            isFirstClaim={isFirstClaim}
                            content={THANK_YOU_PAGE_CONTENT[orderType]}
                            isLoggedIn={isLoggedIn}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <RightContent
                            signUpStatusRejected={signUpStatusRejected}
                            isLoggedIn={isLoggedIn}
                            mobile={mobile}
                            content={THANK_YOU_PAGE_CONTENT[orderType]}
                        />
                    </Col>
                </StyledRow>
            </Layout>
        ) : (
            <Icon.Loader />
        );
    })
);

export default ThankYouPage;
