import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Row, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../../../utils';
import ProductOrBanner from './components/ProductOrBanner';
import { StyledWrapper } from './styled';

export const Promotions = inject('store')(
    observer(({ store, queryParams = null, isNextPage = false }) => {
        const { search } = useLocation();
        const [searchParams] = useSearchParams();
        const { promotions, getPromotions, pending, getPromotionsWithBanners, updateQuery } = store.marketplaceStore;
        const { prevProductId } = store.pdpStore;
        const simple = useSimpleInterface(GRID_POINTS.md);

        const products = search ? promotions : getPromotionsWithBanners(simple);

        useEffect(() => {
            if (prevProductId) {
                const element = document.getElementById(prevProductId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }, []);

        useEffect(() => {
            const query = {};
            for (const [key, value] of searchParams.entries()) {
                query[key] = value;
            }
            const isEqual = updateQuery(query);
            if (!isEqual) getPromotions(false, null, true);

            if (!pending && !(prevProductId && promotions.length)) getPromotions(isNextPage, queryParams);
        }, []);

        return (
            <StyledWrapper>
                <Row gutter={simple ? [10, 16] : [20, 20]}>
                    {products.map((promotion) => (
                        <ProductOrBanner key={promotion._id} promotion={promotion} />
                    ))}
                </Row>
            </StyledWrapper>
        );
    })
);
