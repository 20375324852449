import { checkSelected } from './checkSelected';
import { parseQuery } from './parseQuery';

export const processBrandsList = (brandsList) => {
    const brands = brandsList.map((brandData) => {
        return {
            name: brandData.name,
            key: brandData._id,
            count: brandData.count
        };
    });

    return checkSelected(brands, parseQuery().brands);
};
