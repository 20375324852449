import styled from 'styled-components';
import { $border } from '../../../../../../../../../../shared';

export const Styles = styled.div`
    margin: 10px 0 10px;
    padding: 30px 0;

    &:not(:last-child) {
        border-bottom: 1px solid ${$border};
    }
`;
