import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    ArrowChevronForwardIcon,
    Button,
    GRID_POINTS,
    Icon,
    Promotion,
    roundToLocaleString,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import placeholder from '../../../assets/img/image-placeholder.svg';
import { ROUTE_PATHS } from '../../index';
import {
    StyledContent,
    StyledInfo,
    StyledItemWrapper,
    StyledPrice,
    StyledProductTitle,
    StyledTitle,
    StyledUserName,
    StyledWrapper
} from './styled';
import { getMaxPointsAndPrice } from './utils';

const PointsModalContent = inject('store')(
    observer(({ store, products, onCloseModal }) => {
        const navigate = useNavigate();
        const { pathname } = useLocation();
        const simple = useSimpleInterface(GRID_POINTS.sm);
        const { closePDP } = store.pdpStore;
        const { isLoggedIn, isCustomerStatusOnboarding } = store.accountStore;
        const isOnboardingFlow = pathname.includes(ROUTE_PATHS.onboarding) && isCustomerStatusOnboarding;
        const maxPoints = products.reduce((result, product) => result + getMaxPointsAndPrice(product).maxPoints, 0);
        return (
            <StyledWrapper>
                <StyledTitle type='body1'>
                    Members receive
                    {simple && <br />} <span>{roundToLocaleString(maxPoints, 0)} pts </span>
                    when they buy this product{products?.length > 1 ? 's' : ''}
                </StyledTitle>
                <StyledContent>
                    {products.map((product) => {
                        const { price } = getMaxPointsAndPrice(product);
                        return (
                            <StyledItemWrapper key={product.data.images[0]}>
                                <Promotion.Img
                                    placeholder={placeholder}
                                    height='80px'
                                    width='80px'
                                    images={[{ src: product.data.images[0], position: 1 }]}
                                />
                                <StyledInfo>
                                    <StyledUserName type='body2'>{product.data.brandName}</StyledUserName>
                                    <StyledProductTitle level={3}>{product.data.title}</StyledProductTitle>
                                    <StyledPrice type='badgeSmall'>${roundToLocaleString(price)}</StyledPrice>
                                </StyledInfo>
                            </StyledItemWrapper>
                        );
                    })}
                </StyledContent>
                {!isOnboardingFlow && (
                    <Button
                        size='large'
                        type='unstyled'
                        onClick={() => {
                            onCloseModal();
                            closePDP();
                            return navigate(isLoggedIn ? ROUTE_PATHS.rewardsInfo : ROUTE_PATHS.onboarding);
                        }}
                    >
                        <span>{isLoggedIn ? 'Learn more' : 'Apply for access'}</span>
                        <Icon stylePriority component={() => <ArrowChevronForwardIcon />} />
                    </Button>
                )}
            </StyledWrapper>
        );
    })
);

export default PointsModalContent;
