import { Button, Icon } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $primary, $tartRed, $white } from '../../../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../../../utils';

export const StyledButton = styled(Button)`
    &&& {
        &&:hover,
        &&:active {
            border-color: ${$tartRed};
            color: ${$tartRed};
        }

        @media (${GRID_DEVICES_MAX.md}) {
            &&,
            &&:hover,
            &&:active {
                color: ${$primary};
                height: 48px;
                background-color: ${$tartRed};
                border: none;
                margin-left: auto;
                margin-right: auto;

                ${({ $simple }) =>
                    $simple
                        ? css`
                              margin-left: 0;
                          `
                        : ''}
            }
        }
    }
`;

export const StyledIcon = styled(Icon)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledButtonInfo = styled(Button)`
    &&& {
        color: ${$white};

        @media (${GRID_DEVICES_MAX.md}) {
            padding: 0;
            color: ${$primary};
            font-size: 14px;
            line-height: 24px;
            display: flex;
            justify-content: flex-end;
        }
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (${GRID_DEVICES_MAX.md}) {
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
        margin-top: 24px;
        margin-bottom: 40px;
        padding: 0 23px;
    }
`;
