import React from 'react';
import { LegalContent } from '../../../../shared/components/LegalContent';
import { StyledText } from './styles';

const PrivacyOptOutPage = () => {
    return (
        <LegalContent title='Right to Opt Out of Sale of Information' date='Last Updated August 10, 2023'>
            <StyledText type='descriptor1'>
                To opt out of the sale of your personal information, please submit a request by emailing the following
                info to <a href='mailto:privacy@klickly.com'>privacy@klickly.com</a>.
            </StyledText>
            <br />
            <StyledText as='div' type='descriptor1'>
                <ul style={{ marginTop: '10px' }}>
                    <li>First name</li>
                    <li>Last name</li>
                    <li>Email</li>
                    <li>Street Address</li>
                    <li>City</li>
                    <li>State</li>
                    <li>Postal Code</li>
                    <li>Country</li>
                </ul>
            </StyledText>
            <br />
            <StyledText type='descriptor1'>
                The right to opt-out is subject to certain exemptions and exceptions. For example, it does not apply to
                personal information that we share with service providers that perform certain business functions for us
                pursuant to a written contract. Additionally, we may be unable to fulfill your request if we believe the
                request is fraudulent. <br />
                Please visit our <a href='/help/privacy'>Privacy Policy</a> for additional information about your
                rights.
            </StyledText>
        </LegalContent>
    );
};

export default PrivacyOptOutPage;
