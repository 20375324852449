import styled from 'styled-components';
import { $background } from '../../../../../../shared';

export const StyledImgWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 160px;
    margin: 0 auto 24px;
`;

export const StyledImg = styled.img`
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: cover;
`;

export const StyledNoImg = styled.div`
    display: -webkit-box;
    width: 100%;
    height: 100%;
    padding: 16px 20px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    background: ${$background};

    > div {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
`;
