import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { $tartRed } from '../../../../../../../../constants';

export const StyledLink = styled(NavLink)`
    && {
        display: block;
        margin: 0 -24px;
        padding: 16px 24px;
        background: ${$tartRed};
    }
`;
