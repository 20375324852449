import styled, { css } from 'styled-components';

export const StyledWrap = styled.div`
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding: ${({ $simple }) => ($simple ? '0' : '0 30px')};
`;

export const StyledContent = styled.div`
    display: flex;

    ${({ $simple }) =>
        $simple
            ? css`
                  flex-direction: column;
              `
            : ''}
`;

export const StyledContentLeft = styled.div`
    padding-right: 70px;

    ${({ $simple }) =>
        $simple
            ? css`
                  padding-right: 0;
              `
            : ''}
`;

export const StyledContentRight = styled.div`
    width: 100%;
    max-width: calc(100% - 310px);

    ${({ $simple }) =>
        $simple
            ? css`
                  max-width: 100%;
              `
            : ''}
`;

export const StyledSearchWrap = styled.div`
    width: 100%;
    margin-bottom: 48px;
`;
