import styled from 'styled-components';
import { GRID_DEVICES_MIN } from '../../../../utils';

export const Styles = styled.div`
    background-color: #fff;
    max-width: 630px;
    min-height: ${({ simple }) => (simple ? '100%' : 'auto')};
    width: 100%;

    @media (${GRID_DEVICES_MIN.md}) {
        margin-top: 65px;
        margin-bottom: 20px;
    }
`;
