import React from 'react';
import { observer } from 'mobx-react';
import { InviteFriendTaskContainer } from './components/InviteFriendTaskContainer';
import { TaskModalStyles } from './styles';

export const InviteFriendTask = observer(({ onClose }) => {
    return (
        <TaskModalStyles>
            <InviteFriendTaskContainer onClose={onClose} />
        </TaskModalStyles>
    );
});
