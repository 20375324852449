import { Button, Typography } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledWrap = styled.div`
    width: 100%;
    margin-bottom: 48px;
`;

export const StyledContent = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledTextWrap = styled.div`
    flex-basis: 100%;
    padding-right: 40px;
`;

export const StyledHeadline2 = styled(Typography.Title)`
    margin-bottom: 8px;
`;

export const StyledButton = styled(Button)`
    && {
        width: 100%;
        max-width: 305px;
    }
`;

export const StyledDivider = styled.div`
    width: 100%;
    height: 1px;
    margin-bottom: 24px;
`;
