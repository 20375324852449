import React, { useCallback, useState } from 'react';
import { ArrowChevronDownIcon, ArrowChevronUpIcon, Icon, Typography, useScrollable } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { useLayout } from '../../../../../../../../shared/hooks';
import { StyledButton, Styles } from './styles';

export const OrdersFooter = inject('store')(
    observer(({ store }) => {
        const { meta, loadMoreOrders: onLoadMore } = store.ordersStore;
        const { contentRef } = useLayout();
        const [scrollToTopVisible, setScrollToTopVisible] = useState(false);
        const { currentPage, hasNextPage, totalPages } = meta;
        const inTheEnd = totalPages === currentPage;

        const backToTop = useCallback(() => {
            contentRef.current.scrollTop = 0;
        }, [contentRef]);

        useScrollable(
            contentRef,
            () => {
                setScrollToTopVisible(false);
            },
            () => {
                setScrollToTopVisible(true);
            }
        );

        return (
            <Styles hasNextPage={hasNextPage}>
                {hasNextPage && (
                    <StyledButton type='unstyled' onClick={onLoadMore}>
                        <Typography.Text as='span'>Load previous</Typography.Text>
                        <Icon stylePriority component={() => <ArrowChevronDownIcon />} />
                    </StyledButton>
                )}

                {inTheEnd && (
                    <Typography.Text color='secondaryText' type='description2'>
                        You’ve reached end of the list
                    </Typography.Text>
                )}

                {scrollToTopVisible && (
                    <StyledButton type='unstyled' onClick={backToTop}>
                        <Typography.Text as='span'>Back to top</Typography.Text>
                        <Icon stylePriority component={() => <ArrowChevronUpIcon />} />
                    </StyledButton>
                )}
            </Styles>
        );
    })
);
