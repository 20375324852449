import React from 'react';
import { BOTTOM_INDENTS, Icon, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { AvatarUpload } from '../../../../shared/components/AvatarUpload';
import { StyledLoaderWrap, StyledWrap } from './styled';

export const ProfileSection = inject('store')(
    observer(({ store, simple }) => {
        const { firstName, lastName, pending } = store.accountStore;

        if (pending) {
            return (
                <StyledLoaderWrap $simple={simple}>
                    <Icon.Loader zIndex={5} />
                </StyledLoaderWrap>
            );
        }

        return (
            <StyledWrap $simple={simple}>
                <AvatarUpload justify='space-between' listType='text' withCredentials>
                    <Typography.Title
                        level={2}
                        style={simple ? {} : { ...BOTTOM_INDENTS.XL }}
                        align={simple ? 'left' : 'center'}
                    >
                        {firstName} {lastName}
                    </Typography.Title>
                </AvatarUpload>
            </StyledWrap>
        );
    })
);
