import React, { useCallback } from 'react';
import { useModal, useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../../../utils';
import { ConfirmDeletingModal } from '../index';

export const useConfirmDeletingModal = ({ title, bodyText, onConfirm }) => {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    const { setModal, hideModal } = useModal();

    return useCallback(() => {
        setModal(
            <ConfirmDeletingModal
                title={title}
                bodyText={bodyText}
                onConfirm={onConfirm}
                onClose={hideModal}
                simple={simple}
            />,
            {
                fullscreen: simple,
                closable: false
            }
        );
    }, [title, bodyText, onConfirm, setModal, hideModal, simple]);
};
