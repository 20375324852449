import React, { useEffect } from 'react';
import { Divider, Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { strategies } from '../../../../config/strategies';
import { ROUTE_PATHS } from '../../../../shared';
import PromotionsSlider from '../../../../shared/components/PromotionsSlider';
import { StyledLoaderWrap, StyledWrapper } from './styled';

export const RecentlyViewedSection = inject('store')(
    observer(({ store, simple }) => {
        const { getRecentlyViewedPromotions, recentlyViewedPromotions, pending } = store.dashboardStore;

        useEffect(() => {
            getRecentlyViewedPromotions();
        }, []);

        if (!recentlyViewedPromotions?.length && !pending) return null;

        if (pending) {
            return (
                <StyledLoaderWrap $simple={simple}>
                    <Icon.Loader zIndex={5} />
                </StyledLoaderWrap>
            );
        }

        return (
            <StyledWrapper>
                {recentlyViewedPromotions.length > 0 ? (
                    <PromotionsSlider
                        promotions={recentlyViewedPromotions}
                        title='Recently Viewed'
                        seAllBtnText='See all'
                        seAllBtnAction={() =>
                            window.open(`${ROUTE_PATHS.shop}?strategyId=${strategies.recentlyViewed}`, '_self')
                        }
                        promotionsHeight={simple ? '315px' : '370px'}
                        divider={simple ? null : <Divider height={4} margin='24px 0 0' />}
                        promotionsWithoutButton={simple}
                    />
                ) : null}
            </StyledWrapper>
        );
    })
);
