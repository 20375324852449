import React from 'react';
import { CloseXIcon, Icon, KliHero } from '@klickly/front-packages';
import { $tartRed } from '../../../../../../../../../../shared';
import { HeaderStyles, StyledSubTitle } from './styles';

export const InviteFriendTaskHeader = ({
    simple,
    title = 'Invite a friend',
    subtitle = 'Earn 50 points whenever someone you’ve invited signs up (and is approved) using your invite code',
    showSubtitle,
    onClose
}) => {
    return (
        <HeaderStyles>
            <KliHero
                style={{ justifyContent: simple ? 'flex-start' : 'center' }}
                backIcon={<Icon size='24px' component={() => <CloseXIcon />} />}
                title={title}
                subTitle={
                    showSubtitle && !simple ? (
                        <StyledSubTitle as='span' type='body1'>
                            {subtitle}
                        </StyledSubTitle>
                    ) : (
                        ''
                    )
                }
                backgroundColor={$tartRed}
                color='primary'
                textAlign={simple ? 'left' : 'center'}
                simple={false}
                titleOnTop
                withoutBorders
                onBack={simple ? onClose : null}
            />
        </HeaderStyles>
    );
};
