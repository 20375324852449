import React, { Fragment, useCallback } from 'react';
import { Button, Promotion, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import placeholder from '../../../../assets/img/image-placeholder.svg';
import { Hr } from '../../../../shared/components/Hr';
import { DialogWithHeader } from '../DialogWithHeader';
import { Bottom, Description, PriceItem, Wrap, WrapItem } from './styles';

export const OnPremiumPromotionClaim = inject('store')(
    observer(({ store, onClose }) => {
        const promotion = {};
        const { openChangeSubscriptionModal } = store.modalsStore;

        const onUpgrade = useCallback(() => {
            onClose();
            openChangeSubscriptionModal();
        }, [openChangeSubscriptionModal, onClose]);

        return (
            <DialogWithHeader
                onClose={onClose}
                title='Upgrade to claim this product'
                subTitle='This product is only available to Premium members'
            >
                <Wrap>
                    <WrapItem>
                        <Promotion.Img placeholder={placeholder} images={promotion?.promotion?.images} isStatic />
                    </WrapItem>
                    <WrapItem>
                        <Typography.Title level={2}>{promotion?.promotion?.title}</Typography.Title>
                        {promotion?.promotion?.brandName && (
                            <Fragment>
                                <Typography.Text type='body1'>{promotion?.promotion?.brandName}</Typography.Text>
                                <br />
                            </Fragment>
                        )}

                        <div>
                            <PriceItem>
                                <Typography.Text as='span'>Original price</Typography.Text>
                                <Typography.Text as='span'>${promotion?.prices?.originalPrice}</Typography.Text>
                            </PriceItem>
                            <PriceItem>
                                <Typography.Text as='span'>Giftly pays</Typography.Text>
                                <Typography.Text as='span'>
                                    ${promotion?.prices?.originalPrice - promotion?.prices?.price}
                                </Typography.Text>
                            </PriceItem>
                            <PriceItem color='primary'>
                                <Typography.Text as='span'>Giftly price</Typography.Text>
                                <Typography.Text as='span'>
                                    {promotion?.prices?.price === 0 ? 'Free' : `$${promotion?.prices?.price}`}
                                </Typography.Text>
                            </PriceItem>
                        </div>

                        <Hr />

                        <Typography.Title level={3}>Details</Typography.Title>

                        {promotion?.promotion?.description && (
                            <Description>
                                <Typography.Text
                                    dangerouslySetInnerHTML={{
                                        __html: promotion?.promotion?.description
                                    }}
                                />
                            </Description>
                        )}
                    </WrapItem>
                </Wrap>
                <Bottom>
                    <Button id='premium-checkout-upgrade-button' type='primary' block onClick={onUpgrade}>
                        Upgrade to Premium
                    </Button>
                </Bottom>
            </DialogWithHeader>
        );
    })
);
