import React from 'react';
import {
    ArrowChevronForwardIcon,
    CircleActionsAlertQuestionIcon,
    KliIcon,
    roundToLocaleString,
    Typography
} from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { useShippingModal } from '../ShippingModal/hooks';
import {
    StyledBottom,
    StyledButton,
    StyledContent,
    StyledDescription,
    StyledIcon,
    StyledImg,
    StyledImgWrap,
    StyledInfo,
    StyledTitle,
    StyledWrap
} from './styles';

export const SuiteCoupon = observer(
    ({
        data,
        additionalDetailsContent,
        additionalDetailsContentOnTop = false,
        showDescription = true,
        showButton = true,
        showPrice = true,
        onClick,
        simple,
        shippingAddresses = [],
        defaultShippingAddress
    }) => {
        const openShippingModal = useShippingModal(defaultShippingAddress);

        const showShipping = typeof data.priceDetails?.shipping === 'number';

        const showShippingModal = showShipping || shippingAddresses.length === 0;

        return (
            <StyledWrap onClick={onClick} $pointer={typeof onClick === 'function'}>
                <StyledContent>
                    <StyledImgWrap>
                        <StyledImg src={data.image} alt={data.title} />
                    </StyledImgWrap>
                    <StyledInfo>
                        <StyledTitle level={2} title={data.title}>
                            {data.title}
                        </StyledTitle>
                        {additionalDetailsContentOnTop && additionalDetailsContent ? additionalDetailsContent : null}
                        {showDescription ? (
                            <StyledDescription type='body2'>{data.description}</StyledDescription>
                        ) : null}
                        {!additionalDetailsContentOnTop && additionalDetailsContent ? additionalDetailsContent : null}
                        {showButton ? (
                            <StyledButton unstyled>
                                <Typography.Text type='badgeSmall'>See details</Typography.Text>
                                <KliIcon>
                                    <ArrowChevronForwardIcon />
                                </KliIcon>
                            </StyledButton>
                        ) : null}
                        {simple && showPrice ? (
                            <StyledBottom $simple={simple}>
                                <Typography.Text
                                    style={{ position: 'relative' }}
                                    type='descriptor2'
                                    color='secondaryText'
                                >
                                    From ${roundToLocaleString(data.priceDetails?.youPayPrice)} +{' '}
                                    {showShipping ? `$${roundToLocaleString(data.priceDetails?.shipping)} est.` : ''}{' '}
                                    shipping
                                    {showShippingModal ? (
                                        <StyledIcon onClick={openShippingModal} size='12px' color='secondaryText'>
                                            <CircleActionsAlertQuestionIcon />
                                        </StyledIcon>
                                    ) : null}
                                </Typography.Text>
                            </StyledBottom>
                        ) : null}
                    </StyledInfo>
                </StyledContent>
                {!simple && showPrice ? (
                    <StyledBottom $simple={simple}>
                        <Typography.Text type='descriptor2' color='secondaryText'>
                            From ${roundToLocaleString(data.priceDetails?.youPayPrice)} +{' '}
                            {showShipping ? `$${roundToLocaleString(data.priceDetails?.shipping)} est.` : ''} shipping
                        </Typography.Text>
                    </StyledBottom>
                ) : null}
            </StyledWrap>
        );
    }
);
