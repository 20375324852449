import styled, { css } from 'styled-components';

export const StyledOrderLink = styled.div`
    display: inline-flex;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    ${({ $isBlockElement }) =>
        $isBlockElement &&
        css`
            display: block;
        `};
`;
