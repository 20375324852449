import React, { useContext } from 'react';
import { KliThemeContext } from '../KliThemeProvider';
import { StyledAntdSwitch } from './styles';

export const Switch = ({ children, checkedColor, ...props }) => {
    const themeContext = useContext(KliThemeContext);

    return (
        <StyledAntdSwitch $theme={themeContext} $checkedColor={checkedColor} {...props}>
            {children}
        </StyledAntdSwitch>
    );
};
