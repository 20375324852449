import React from 'react';
import { Icon, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import ExclusiveDealsBanner from '../../../../shared/components/ExclusiveDealsBanner';
import { ChallengeContent } from './components/ChallengeContent';
import { SuiteReadyContent } from './components/SuiteReadyContent';
import { SuiteStartedContent } from './components/SuiteStartedContent';
import { StyledLoaderWrap } from './styled';

export const InfoSection = inject('store')(
    observer(({ store }) => {
        const simple = useSimpleInterface(660);
        const { isSuiteStateStared, pending: suitePending } = store.suiteStore;
        const { claimsCount, pending: claimsPending } = store.claimsPointsStore;
        const { randomActiveTask, pending: tasksPending } = store.tasksStore;

        if (suitePending || claimsPending || tasksPending) {
            return (
                <StyledLoaderWrap>
                    <Icon.Loader zIndex={5} />
                </StyledLoaderWrap>
            );
        }

        if (isSuiteStateStared) {
            return <SuiteStartedContent simple={simple} />;
        }

        if (claimsCount !== 0) {
            return <SuiteReadyContent simple={simple} />;
        }

        if (randomActiveTask) {
            return <ChallengeContent task={randomActiveTask} simple={simple} />;
        }

        return <ExclusiveDealsBanner />;
    })
);
