import { Checkbox, Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $primary } from '../../../../../../shared';

export const Styles = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

export const Item = styled.label`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
`;

export const StyledText = styled(Typography.Text)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const StyledCheckbox = styled(Checkbox)`
    &&&& {
        margin: 12px 0;
        &:hover {
            .ant-checkbox-checked {
                &:after {
                    border-color: ${$primary};
                }
                .ant-checkbox-inner {
                    background-color: ${$primary};
                }
            }
        }
    }

    span.ant-checkbox {
        align-self: center;
        transform: none;
    }
`;
