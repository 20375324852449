import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI } from '../shared';
import { PointItemModel } from './constants/pointItemModel';

export const PointsStore = types
    .model('PointsStore', {
        pending: false,
        error: types.maybeNull(types.string),
        pointsData: types.array(PointItemModel)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => ({
        getCustomerPointsData: flow(function* getCustomerPointsData() {
            try {
                self.pending = true;
                const data = yield self.root.get(kcpAPI.points.getCustomerPointsData, { limit: 1000 });
                self.pointsData = data.data;
            } catch (e) {
                self.error = e?.response?.message;
            } finally {
                self.pending = false;
            }
        })
    }));
