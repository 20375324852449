import { TASKS_ALIASES } from './tasks';

export const DIALOG_TRIGGER_KEY = 'dialog';

export const DIALOG_TRIGGER_IDS = Object.freeze({
    INVITE_FRIEND: TASKS_ALIASES.INVITE_FRIEND,
    WRITE_PRODUCT_REVIEW: TASKS_ALIASES.WRITE_PRODUCT_REVIEW,
    RATE_GIFTLY: TASKS_ALIASES.RATE_GIFTLY,
    SUITE_FEEDBACK: TASKS_ALIASES.SUITE_FEEDBACK,

    CHANGE_SUBSCRIPTION: 'change-subscription',
    ADD_PAYMENT_METHOD: 'add-new-payment-method',
    EDIT_PAYMENT_METHOD: 'update-payment-method',

    EDIT_SHIPPING_ADDRESS: 'edit-shipping-address',
    ADD_SHIPPING_ADDRESS: 'add-shipping-address',

    CLAIM_PROMOTION: 'claim-promotion',
    CLAIM_PREMIUM_PROMOTION: 'claim-premium-promotion',
    CLAIM_REWARD_PROMOTION: 'claim-reward-promotion'
});
