import React from 'react';
import { Button, Image } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import installedExtImg1 from '../../../../../../../../assets/img/settings/extension/installed-ext-1.svg';
import installedExtImg2 from '../../../../../../../../assets/img/settings/extension/installed-ext-2.svg';
import installedExtImg3 from '../../../../../../../../assets/img/settings/extension/installed-ext-3.svg';
import { $background, $primaryBackground } from '../../../../../../../../shared';

export const Styles = styled.div`
    width: 100%;
    padding-bottom: 100px;
`;

export const StyledHead = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 32px;
`;

export const StyledButton = styled(Button)`
    && {
        padding-left: 100px;
        padding-right: 100px;
        margin-right: 24px;
    }
`;

export const StyledContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background-color: ${$background};
    margin-bottom: 32px;

    ${({ $block }) =>
        $block === '1'
            ? css`
                  background-color: ${$primaryBackground};
              `
            : ''}
`;

export const StyledContentLeft = styled.div`
    ${({ $block }) =>
        $block === '1'
            ? css`
                  max-width: 356px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 0 48px;
              `
            : ''}

    ${({ $block }) =>
        $block === '2'
            ? css`
                  width: 389px;
                  height: 400px;
              `
            : ''}

    ${({ $block }) =>
        $block === '3'
            ? css`
                  max-width: 423px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  padding: 0 48px;
              `
            : ''}
`;

export const StyledContentRight = styled.div`
    ${({ $block }) =>
        $block === '1'
            ? css`
                  width: 389.16px;
                  height: 230px;
              `
            : ''}

    ${({ $block }) =>
        $block === '2'
            ? css`
                  max-width: 423px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  padding: 0 48px;
              `
            : ''}

    ${({ $block }) =>
        $block === '3'
            ? css`
                  width: 389px;
                  height: 400px;
              `
            : ''}
`;

export const StyledImg1 = () => (
    <Image style={{ width: '100%', height: '100%' }} src={installedExtImg1} alt='installed extension 1' />
);
export const StyledImg2 = () => (
    <Image style={{ width: '100%', height: '100%' }} src={installedExtImg2} alt='installed extension 2' />
);
export const StyledImg3 = () => (
    <Image style={{ width: '100%', height: '100%' }} src={installedExtImg3} alt='installed extension 3' />
);
