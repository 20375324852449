import styled from 'styled-components/macro';
import { $border } from '../../../../../../shared';

export const Styles = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 48px 24px 40px;
    border-bottom: 1px solid ${$border};
`;
