import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    ForwardArrowIcon,
    Icon,
    LEFT_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { getAcceptChallengeRedirect } from '../../../../../../shared/utils/getAcceptChallengeRedirect';
import { GRID_POINTS } from '../../../../../../utils';
import EarnReward from './components/EarnReward';
import { StyledButton } from './styled';

const ChallengeAvailable = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { activeChallenge } = store.seasonalChallengeStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        return (
            <Fragment>
                <Typography.Title style={BOTTOM_INDENTS.M} align={simple ? 'center' : 'left'}>
                    Challenge {activeChallenge?.position}:{simple ? <br /> : null} {activeChallenge?.name}
                </Typography.Title>
                <EarnReward />
                <Typography.Text type='descriptor1' style={BOTTOM_INDENTS.M}>
                    {activeChallenge.description}
                </Typography.Text>
                <StyledButton
                    type='primary'
                    onClick={() => {
                        activeChallenge.setChallengeAccepted();
                        return navigate(getAcceptChallengeRedirect(activeChallenge.alias));
                    }}
                >
                    Accept challenge
                    <Icon style={LEFT_INDENTS.S} size='24px' component={() => <ForwardArrowIcon />} />
                </StyledButton>
            </Fragment>
        );
    })
);

export default ChallengeAvailable;
