import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../../../shared';
import { GRID_POINTS } from '../../../../../../../../utils';
import { StyledButton, StyledButtonsWrapper } from './styled';

const CouponButtons = inject('store')(
    observer(({ store, goToChallengesText = 'Earn more rewards' }) => {
        const navigate = useNavigate();
        const simple = useSimpleInterface(GRID_POINTS.md);

        const onChallengesTabClick = () => {
            store.trackingStore.sendEvent({
                type: 'event',
                name: 'RewardsPage_GoToChallenges'
            });
            navigate(ROUTE_PATHS.challenges);
        };

        return (
            <StyledButtonsWrapper>
                <StyledButton type='primary' onClick={onChallengesTabClick}>
                    <Typography.Text as='div' type='badgeLarge'>
                        {goToChallengesText}
                    </Typography.Text>
                </StyledButton>
                {simple && (
                    <StyledButton $rewardsInfo type='unstyled' onClick={() => navigate(ROUTE_PATHS.rewardsInfo)}>
                        How do rewards work?
                    </StyledButton>
                )}
            </StyledButtonsWrapper>
        );
    })
);

export default CouponButtons;
