import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, Button, Icon, Typography, useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../../utils';
import { $background, ROUTE_PATHS } from '../../index';
import ApplyButton from './components/ApplyButton';
import { StyledBottomFixedContent, StyledContent, StyledContentWrapper, StyledWrapper } from './styled';

const Unauthorized = ({ title, contentTitle, description, howItWorkBtnText, icon }) => {
    const simple = useSimpleInterface(GRID_POINTS.md);
    const navigate = useNavigate();

    return (
        <StyledWrapper>
            <StyledContentWrapper>
                <Typography.Title level={2} style={BOTTOM_INDENTS.M}>
                    {title}
                </Typography.Title>
                <StyledContent>
                    <Icon size='36px' roundedColor={$background} roundedSize='120px' component={() => icon} />
                    <Typography.Title align='center' level={2} style={BOTTOM_INDENTS.M}>
                        {contentTitle}
                    </Typography.Title>
                    <Typography.Text style={!simple ? BOTTOM_INDENTS.XXL : {}} align='center' type='body1'>
                        {description}
                    </Typography.Text>
                    {!simple && <ApplyButton />}
                </StyledContent>
                {simple && (
                    <StyledBottomFixedContent>
                        <ApplyButton />
                        <Button type='unstyled' size='large' onClick={() => navigate(ROUTE_PATHS.inviteAndEarnRules)}>
                            <Typography.Text type='badgeMedium'>{howItWorkBtnText}</Typography.Text>
                        </Button>
                    </StyledBottomFixedContent>
                )}
            </StyledContentWrapper>
        </StyledWrapper>
    );
};

export default Unauthorized;
