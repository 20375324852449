import React from 'react';
import { Button, Image } from '@klickly/front-packages';
import styled from 'styled-components';
import installExtImg from '../../../../../../../../assets/img/settings/extension/install-extension.svg';
import { $primaryBackground } from '../../../../../../../../shared';

export const Styles = styled.div`
    width: 100%;
    padding-bottom: 100px;
`;

export const StyledHead = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 32px;
`;

export const StyledButton = styled(Button)`
    && {
        padding-left: 100px;
        padding-right: 100px;
        margin-right: 24px;
    }
`;

export const StyledContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 100px;
    background-color: ${$primaryBackground};
`;

export const StyledContentLeft = styled.div`
    max-width: 236px;
    margin-right: 40px;
`;

export const StyledContentRight = styled.div`
    width: 325px;
    height: 391px;
`;

export const StyledImg = () => (
    <Image style={{ width: '100%', height: '100%' }} src={installExtImg} alt='install extension' />
);
