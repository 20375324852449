import { types } from 'mobx-state-tree';

export const PriceDetailsModel = types.maybeNull(
    types.model({
        discounts: types.maybeNull(types.number),
        fullTotal: types.maybeNull(types.number),
        originalSubTotal: types.maybeNull(types.number),
        qty: types.maybeNull(types.number),
        shipping: types.maybeNull(types.number),
        subTotal: types.maybeNull(types.number),
        taxes: types.maybeNull(types.number),
        total: types.maybeNull(types.number)
    })
);
