import React from 'react';
import { Image, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import task1L from '../../../../assets/img/landing/tasks/task1_big.png';
import task1S from '../../../../assets/img/landing/tasks/task1_small.png';
import task2L from '../../../../assets/img/landing/tasks/task2_big.png';
import task2S from '../../../../assets/img/landing/tasks/task2_small.png';
import task3L from '../../../../assets/img/landing/tasks/task3_big.png';
import task3S from '../../../../assets/img/landing/tasks/task3_small.png';
import { $primary, $white } from '../../../../shared';

export const StyledWrap = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
`;

export const StyledContent = styled.div`
    max-width: 1064px;
`;

export const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ $reverted }) =>
        $reverted
            ? css`
                  flex-direction: row-reverse;
              `
            : ''}

    ${({ $simple }) =>
        $simple
            ? css`
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: center;
                  padding: 0 40px;
              `
            : ''}

    ${({ $mobile }) =>
        $mobile
            ? css`
                  padding: 0 24px;
              `
            : ''}
`;

export const StyledCol = styled.div`
    max-width: 50%;

    ${({ $text }) =>
        $text
            ? css`
                  max-width: 414px;
              `
            : ''}

    ${({ $simple }) =>
        $simple
            ? css`
                  max-width: 100%;
              `
            : ''}
`;

export const StyledPoint = styled(Typography.Title)`
    width: 40px;
    height: 40px;
    background-color: ${$primary};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${$white};
    margin-bottom: 24px;
`;

export const StyledHeadline1 = styled(Typography.Title)`
    margin-bottom: 24px;
`;

export const StyledHeadline2 = styled(Typography.Title)`
    margin-bottom: 24px;
`;

export const StyledText = styled(Typography.Text)`
    ${({ $simple }) =>
        $simple
            ? css`
                  margin-bottom: 24px;
              `
            : ''}
`;

export const StyledImageLarge = styled(Image)`
    width: 100%;
    height: 520px;
    object-fit: contain;
`;

export const Task1L = () => <StyledImageLarge src={task1L} alt='task 1' />;
export const Task2L = () => <StyledImageLarge src={task2L} alt='task 2' />;
export const Task3L = () => <StyledImageLarge src={task3L} alt='task 3' />;

export const StyledImageSmall = styled(Image)`
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-bottom: 40px;
`;

export const Task1S = () => <StyledImageSmall src={task1S} alt='task 1' />;
export const Task2S = () => <StyledImageSmall src={task2S} alt='task 2' />;
export const Task3S = () => <StyledImageSmall src={task3S} alt='task 3' />;
