import React from 'react';
import {
    BOTTOM_INDENTS,
    Col,
    Component1Icon,
    DiamondIcon,
    Icon,
    NotificationBellIcon,
    Row,
    ScrollIcon,
    Switch,
    TOP_INDENTS
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../../../shared';
import { NavItem } from '../../../../../../../../shared/components/NavItem';
import { Styles } from './styles';

export const SettingsNav = inject('store')(
    observer(({ store }) => {
        const {
            hasMarketingEmailSubscription: emailSubscription = false,
            updateMarketingEmailSubscription: toggleEmailSubscription
        } = store.accountStore;
        return (
            <Styles style={{ ...BOTTOM_INDENTS.XL, ...TOP_INDENTS.XL }}>
                <Row gutter={40}>
                    <Col className='wrap' span={24} md={{ span: 12 }}>
                        <NavItem
                            link={ROUTE_PATHS.orders}
                            icon={
                                <Icon>
                                    <ScrollIcon />
                                </Icon>
                            }
                            title='Orders'
                            description='List of claims and purchases'
                        />
                    </Col>
                    <Col span={24} md={{ span: 12 }}>
                        <NavItem
                            link={ROUTE_PATHS.shippingBilling}
                            icon={<Icon component={() => <Component1Icon />} />}
                            title='Shipping and billing'
                            description='Payment and delivery options'
                        />
                    </Col>
                    <Col span={24} md={{ span: 12 }}>
                        <NavItem
                            icon={<Icon component={() => <NotificationBellIcon />} />}
                            title='Get exclusive offers'
                            description='E-mail communication'
                        >
                            <Switch
                                onChange={toggleEmailSubscription}
                                checked={emailSubscription}
                                checkedColor='primary'
                            />
                        </NavItem>
                    </Col>
                    <Col span={24} md={{ span: 12 }}>
                        <NavItem
                            link={ROUTE_PATHS.subscriptions}
                            icon={<Icon component={() => <DiamondIcon />} />}
                            title='Upgrade to premium'
                            description='Get access to more products'
                        />
                    </Col>
                </Row>
            </Styles>
        );
    })
);
