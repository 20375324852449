import React, { useState } from 'react';
import { Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import placeholder from '../../../../assets/img/image-placeholder.svg';
import { get, GRID_POINTS } from '../../../../utils';
import {
    StyledContent,
    StyledCouponWrapper,
    StyledDescription,
    StyledImage,
    StyledShowMoreButton,
    StyledTitle
} from './styled';

export const UpsellCoupon = inject('store')(
    observer(({ store }) => {
        const { upsell } = store.upsellStore;
        const simple = useSimpleInterface(GRID_POINTS.xs);
        const simpleTablet = useSimpleInterface(GRID_POINTS.md);
        const shortDescriptionLength = 200;
        const [showDetails, setShowDetails] = useState(false);

        const imageSrc = get(upsell, 'promotionData.image', placeholder);
        const title = get(upsell, 'promotionData.title', '');
        const brandName = get(upsell, 'promotionData.brandName', '');
        const priceDetails = get(upsell, 'promotionData.priceDetails', {});
        const description = get(upsell, 'promotionData.description', '');
        const { originalPrice, rewardsPercent, youPayPrice } = priceDetails;

        const generateTitle = () => {
            return (
                <StyledTitle level={!simple ? 1 : 2} $simpleTablet={simpleTablet}>
                    <span>Get {brandName}</span>
                    <span>{title} for</span>
                    <span>${originalPrice}</span>
                    <span>
                        ${youPayPrice} ({rewardsPercent}% off)
                    </span>
                </StyledTitle>
            );
        };

        const markup = {
            __html: showDetails
                ? description
                : `${description.substring(0, shortDescriptionLength)}${
                      description.length > shortDescriptionLength ? '...' : ''
                  }`
        };

        return (
            <StyledCouponWrapper $simple={simple} $simpleTablet={simpleTablet}>
                <StyledImage $simpleTablet={simpleTablet} src={imageSrc} alt={title} />
                <StyledContent>
                    {generateTitle()}
                    {description ? (
                        <StyledDescription type={simple ? 'body1' : 'body2'}>
                            <span dangerouslySetInnerHTML={markup} />
                            {description && description?.length > shortDescriptionLength ? (
                                <StyledShowMoreButton type='unstyled' onClick={() => setShowDetails(!showDetails)}>
                                    <Typography.Text type={simple ? 'body1' : 'body2'} color='primary'>
                                        {!showDetails ? 'view more' : 'view less'}
                                    </Typography.Text>
                                </StyledShowMoreButton>
                            ) : null}
                        </StyledDescription>
                    ) : null}
                </StyledContent>
            </StyledCouponWrapper>
        );
    })
);
