import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { NotificationText, StyledButton } from './styles';

export const InviteFriendTaskRequestInvitations = inject('store')(
    observer(({ store }) => {
        const { inviteFriendTaskData: data, requestInvitations } = store.tasksStore;

        const { attemptsRemained, isAttemptsQuotaRequested } = data;

        return (
            <Fragment>
                {!attemptsRemained ? (
                    <StyledButton
                        onClick={requestInvitations}
                        type='primary'
                        size='large'
                        disabled={isAttemptsQuotaRequested}
                    >
                        Request more invites
                    </StyledButton>
                ) : null}
                {isAttemptsQuotaRequested && (
                    <Fragment>
                        <NotificationText type='descriptor2' color='secondaryText'>
                            Application for more invitations pending
                        </NotificationText>
                        <br />
                    </Fragment>
                )}
            </Fragment>
        );
    })
);
