import React, { Fragment } from 'react';
import { BOTTOM_INDENTS, Typography } from '@klickly/front-packages';
import { $primary, $secondaryText, ROUTE_PATHS } from '../../../constants';
import ChallengeTitle from '../components/ChallengeTitle';
import SuiteStartedTitle from '../components/SuiteStartedTitle';
import { StyledText } from '../styled';

export const getBannerContent = (navigate, profileEmail, isDealsPage) => {
    return {
        unauthorized: {
            firstBanner: {
                getTitle: ({ simple, isMobileView }) => (
                    <Typography.Title
                        level={simple || isMobileView ? 2 : 1}
                        align='center'
                        style={(simple || isMobileView) && BOTTOM_INDENTS.S}
                    >
                        <StyledText $color={$secondaryText}>
                            New members get <br />a{' '}
                        </StyledText>
                        <StyledText $color={$primary}>
                            Gifting Suite worth {!simple || isMobileView ? 'up to' : null} $50!
                        </StyledText>
                    </Typography.Title>
                ),
                primaryBtn: {
                    text: 'Apply',
                    onClick: () => navigate(ROUTE_PATHS.personalInfo)
                },
                secondaryBtn: {
                    text: 'Learn more',
                    onClick: () => navigate(ROUTE_PATHS.suiteGsInfo)
                }
            },
            secondBanner: {
                getTitle: ({ simple, isMobileView }) => (
                    <Typography.Title
                        level={simple || isMobileView ? 2 : 1}
                        align='center'
                        style={(simple || isMobileView) && BOTTOM_INDENTS.S}
                    >
                        <StyledText style={BOTTOM_INDENTS.SM} $color={$secondaryText}>
                            The Invite-Only App{' '}
                        </StyledText>
                        <StyledText $color={$secondaryText}>That Gives </StyledText>
                        <StyledText $color={$primary}>Free Gifts & Private Discounts</StyledText>
                    </Typography.Title>
                ),
                primaryBtn: {
                    text: 'Apply',
                    onClick: () => navigate(ROUTE_PATHS.personalInfo)
                },
                secondaryBtn: {
                    text: 'Learn more',
                    onClick: () => navigate(ROUTE_PATHS.suiteGsInfo)
                }
            }
        },
        authorized: {
            suiteOpened: {
                getTitle: ({ simple, isMobileView }) => (
                    <Typography.Title level={simple || isMobileView ? 2 : 1} align='center'>
                        <StyledText $color={$primary}>Your Gifting Suite</StyledText>
                        {simple || isMobileView ? <br /> : null}{' '}
                        <StyledText $color={$secondaryText}>is ready to launch!</StyledText>
                    </Typography.Title>
                ),
                primaryBtn: {
                    text: 'Launch',
                    onClick: () => navigate(ROUTE_PATHS.suiteGs)
                },
                secondaryBtn: {
                    text: 'What is a Gifting Suite?',
                    onClick: () => navigate(ROUTE_PATHS.suiteGsInfo)
                }
            },
            suiteStarted: {
                getTitle: ({ simple, isMobileView }) => (
                    <SuiteStartedTitle simple={simple || isMobileView} isMobileView={isMobileView} />
                ),
                primaryBtn: {
                    text: 'Go to Gifting Suite',
                    onClick: () => navigate(ROUTE_PATHS.suiteGs)
                }
            },
            waitListed: {
                getTitle: ({ simple, isMobileView }) => (
                    <Fragment>
                        <Typography.Title level={1} align='center'>
                            <StyledText $color={$primary}>You’re on the waitlist</StyledText>
                        </Typography.Title>
                        <Typography.Title level={!isDealsPage || simple || isMobileView ? 3 : 2} align='center'>
                            <StyledText $color={$secondaryText}>
                                As soon as a space opens{simple || isMobileView ? <br /> : null} we’ll e-mail you at:{' '}
                            </StyledText>
                            <StyledText $color={$primary}>{profileEmail}</StyledText>
                        </Typography.Title>
                    </Fragment>
                )
            },
            randomChallenge: {
                getTitle: ({ isMobileView }) => <ChallengeTitle isMobileView={isMobileView} />,
                primaryBtn: {
                    text: 'Go to Challenge',
                    onClick: () => navigate(ROUTE_PATHS.challenges)
                }
            }
        }
    };
};
