import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../../utils';
import { ROUTE_PATHS } from '../../../../constants';
import PopoverWithButton from '../../../PopoverWithButton';
import LinkContent from './LinkContent';

const LinkWithCount = inject('store')(
    observer(
        ({
            store,
            onMenuClose = () => null,
            text = 'Gifting Suite',
            isLink = true,
            type = 'gs',
            to = ROUTE_PATHS.suiteGs
        }) => {
            const ref = useRef(null);
            const simple = useSimpleInterface(GRID_POINTS.md);
            const {
                showPopoverOnLinkToGs,
                toggleShowPopoverOnLinkToGs,
                toggleShowPopoverOnLinkToGsManuallyClosed
            } = store.onboardingPopoverStore;
            const { claims } = store.claimsPointsStore;

            const wishlistCount = store.accountStore.wishlistAmount;
            const count = type === 'gs' ? claims : wishlistCount;

            const handleClick = () => {
                onMenuClose();
                if (showPopoverOnLinkToGs) {
                    toggleShowPopoverOnLinkToGsManuallyClosed(true);
                    toggleShowPopoverOnLinkToGs();
                }
            };

            return (
                <PopoverWithButton
                    open={!simple && showPopoverOnLinkToGs}
                    getPopupContainer={() => ref.current}
                    content={
                        <div>
                            Access your Gifting <br /> Suite reward here
                        </div>
                    }
                    onTooltipButtonClick={() => {
                        toggleShowPopoverOnLinkToGsManuallyClosed(true);
                        toggleShowPopoverOnLinkToGs();
                    }}
                    placement='bottomLeft'
                >
                    {isLink ? (
                        <NavLink to={to} onClick={handleClick}>
                            <LinkContent text={text} count={count} />
                        </NavLink>
                    ) : (
                        <LinkContent text={text} onClick={handleClick} count={count} />
                    )}
                </PopoverWithButton>
            );
        }
    )
);

export default LinkWithCount;
