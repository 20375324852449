import styled from 'styled-components';
import { $background } from '../../../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../../../utils';

export const StyledRewardSectionWrapper = styled.div`
    padding: 40px 64px;
    background: ${$background};

    && {
        .ant-btn:not(:hover) {
            background: transparent;
        }
    }

    @media (${GRID_DEVICES_MAX.md}) {
        margin-bottom: 40px;
        padding: 24px 16px;
    }
`;
