export const compareAddresses = (keys, address1, address2) => {
    let theSame = true;

    if (address1 && address2) {
        Object.keys(keys).forEach((key) => {
            if ((address1[key] || address2[key]) && address1[key] !== address2[key]) {
                theSame = false;
                return false;
            }
        });
    }

    return theSame;
};
