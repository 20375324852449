import React, { Fragment } from 'react';
import {
    BOTTOM_INDENTS,
    Form,
    Icon,
    Input,
    TOP_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { VALIDATION_RULES } from '../../../../../../shared';
import { ErrorField } from '../../../../../../shared/components/ErrorField';
import { GRID_POINTS } from '../../../../../../utils';
import { NumberRating } from './components/NumberRating';
import { useSuccessModal } from './components/SuccessModal/useSuccessModal';
import { useOnSubmit } from './hooks';
import { StyledButton, StyledButtonWrap, Styles } from './styles';

export const LeaveFeedback = inject('store')(
    observer(({ store }) => {
        const [form] = Form.useForm();
        const openModal = useSuccessModal(form.resetFields);
        const { processing: feedbackProcessing, errorMessage } = store.accountStore;
        const { processing: taskProcessing, taskErrorMessage } = store.tasksStore;
        const simple = useSimpleInterface(GRID_POINTS.sm);
        const processing = taskProcessing || feedbackProcessing;
        const error = taskErrorMessage || errorMessage;

        const onSubmit = useOnSubmit(store);

        const submitted = (data) => {
            if (typeof onSubmit !== 'undefined') {
                onSubmit(data, openModal);
            }
        };
        return (
            <Styles style={TOP_INDENTS.XL}>
                <Form form={form} onFinish={submitted} validateTrigger={['onBlur']}>
                    {processing ? <Icon.Loader /> : null}
                    <Form.Item name='rate' hidden rules={[{ required: true, message: 'Rate is required' }]}>
                        <Input />
                    </Form.Item>
                    <Typography.Text style={BOTTOM_INDENTS.SM} type='body1'>
                        How likely are you to recommend Giftly to a friend?
                    </Typography.Text>
                    <Form.Item style={BOTTOM_INDENTS.SM} noStyle shouldUpdate>
                        {() => (
                            <Fragment>
                                <NumberRating
                                    value={form.getFieldValue('rate')}
                                    onChange={(value) => {
                                        form.setFields([
                                            {
                                                name: 'rate',
                                                touched: true,
                                                errors: [],
                                                value
                                            }
                                        ]);
                                    }}
                                    maxRate={10}
                                />
                                {form.getFieldError('rate').length ? (
                                    <Fragment>
                                        <Typography.Text style={TOP_INDENTS.SM} type='description1' color='error'>
                                            {form.getFieldError('rate')[0]}
                                        </Typography.Text>
                                    </Fragment>
                                ) : null}
                            </Fragment>
                        )}
                    </Form.Item>
                    <Typography.Text style={BOTTOM_INDENTS.SM} type='body1'>
                        What is the main reason for your score?
                    </Typography.Text>
                    <Form.Item name='reason' rules={VALIDATION_RULES.COMMENT}>
                        <Input.TextArea label='Your answer' />
                    </Form.Item>
                    <Typography.Text style={BOTTOM_INDENTS.SM} type='body1'>
                        What else would you like to share? We love feedback.
                    </Typography.Text>
                    <Form.Item name='feedback' rules={VALIDATION_RULES.COMMENT}>
                        <Input.TextArea label='Your answer' />
                    </Form.Item>
                    {error ? <ErrorField error={error} /> : null}
                    <StyledButtonWrap style={TOP_INDENTS.SM} $simple={simple}>
                        <StyledButton htmlType='submit' type='primary' $simple={simple}>
                            <Typography.Text type='badgeLarge'>Send feedback</Typography.Text>
                        </StyledButton>
                    </StyledButtonWrap>
                </Form>
            </Styles>
        );
    })
);
