import React, { Fragment } from 'react';
import { BOTTOM_INDENTS, TOP_INDENTS, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $grayScale60Black, $primary, $white } from '../../../../shared';
import { getIconByAlias } from '../../../../shared/components/CouponBanner/components/ChallengeTitle/utils';
import { getRandomNumber } from '../../../../shared/utils';
import { StyledIcon } from '../RightContent/components/Info/styled';

const RandomChallengeContent = inject('store')(
    observer(({ store, mobile }) => {
        const { tasks } = store.tasksStore;
        const challenges = (tasks || []).filter((task) => !task.isHidden && task.data.status === 'active');
        const activeChallenge = challenges?.[getRandomNumber(0, challenges.length - 1)];

        return activeChallenge ? (
            <Fragment>
                <StyledIcon
                    style={mobile ? TOP_INDENTS.L : BOTTOM_INDENTS.XXL}
                    roundedOpacity='1'
                    size={mobile ? '32px' : '80px'}
                    roundedSize={mobile ? '64px' : '160px'}
                    hoverRoundedColor={mobile ? $primary : $white}
                    roundedColor={mobile ? $primary : $white}
                    color={mobile ? $white : $primary}
                    component={() => getIconByAlias(activeChallenge.alias)}
                />
                <Typography.Title
                    style={mobile ? TOP_INDENTS.L : TOP_INDENTS.XXL}
                    color={mobile ? $primary : $white}
                    align='center'
                    level={mobile ? 2 : 1}
                >
                    {activeChallenge.data.title}
                </Typography.Title>
                <Typography.Title
                    style={mobile ? TOP_INDENTS.S : TOP_INDENTS.M}
                    color={mobile ? $grayScale60Black : $white}
                    align='center'
                    level={3}
                >
                    {activeChallenge.data.description}
                </Typography.Title>
            </Fragment>
        ) : null;
    })
);

export default RandomChallengeContent;
