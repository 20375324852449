import React from 'react';
import { Button } from '@klickly/front-packages';
import styled from 'styled-components';
import successImg from '../../../../../../../../assets/img/success.svg';
import { GRID_DEVICES_MAX } from '../../../../../../../../utils';

export const StyledModalWrapper = styled.div`
    background-color: #fff;
    width: 630px;
    min-height: auto;
    position: relative;

    @media (${GRID_DEVICES_MAX.sm}) {
        width: 100%;
        min-height: 100%;
    }
`;

export const StyledWrap = styled.div``;

export const StyledContent = styled.div`
    padding: ${({ $simple }) => ($simple ? '24px' : '40px')};
    display: flex;
    flex-direction: column;
`;

export const StyledImg = styled.img`
    && {
        width: 80px;
        height: 80px;
        object-fit: contain;

        display: block;
        margin: 0 auto;
    }
`;

export const SuccessImg = () => <StyledImg src={successImg} alt='success' />;

export const StyledButton = styled(Button)`
    width: 100%;
`;
