export const getOffersForMobile = (offers = []) => {
    return offers.reduce((res, offer, index) => {
        if (index > 0) return res;

        const newPair = [offer];
        const hasNextProduct = offers.length > 1;
        if (hasNextProduct) newPair.push(offers[1]);
        return [...res, newPair, ...getOffersForMobile(offers.filter((_, index) => index > 1))];
    }, []);
};
