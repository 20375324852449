import React from 'react';
import {
    ArrowChevronDownIcon,
    ArrowChevronForwardIcon,
    BagIcon,
    BOTTOM_INDENTS,
    CircleActionsAlertInfoIcon,
    Icon,
    Typography
} from '@klickly/front-packages';
import { ExpansionHeadRow, ExpansionHeadStyles, Message, OrderSummaryWrap } from './styles';

export const ExpansionHead = ({ opened, summaryPrice }) => {
    return (
        <ExpansionHeadStyles $opened={opened}>
            <ExpansionHeadRow style={BOTTOM_INDENTS.SM}>
                <OrderSummaryWrap>
                    <Icon size='16px' component={() => <BagIcon />} />
                    &nbsp;
                    <Typography.Text type='badgeSmall'>Estimated total</Typography.Text>
                    &nbsp;
                    <Icon
                        size='16px'
                        component={() => (opened ? <ArrowChevronDownIcon /> : <ArrowChevronForwardIcon />)}
                    />
                </OrderSummaryWrap>
                <Typography.Text type='badgeMedium'>{summaryPrice}</Typography.Text>
            </ExpansionHeadRow>
            <ExpansionHeadRow>
                <Message>
                    <Icon size='20px' component={() => <CircleActionsAlertInfoIcon />} />
                    &nbsp;
                    <Typography.Text type='descriptor2'>Shipping and taxes calculated at billing step</Typography.Text>
                </Message>
            </ExpansionHeadRow>
        </ExpansionHeadStyles>
    );
};
