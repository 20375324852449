import React from 'react';
import { Animated } from '../../../../shared/components/Animated';
import { SettingsContainer } from './components/SettingsContainer';

const AccountSettingsPage = () => {
    return (
        <Animated style={{ maxWidth: '845px', margin: 'auto' }}>
            <SettingsContainer />
        </Animated>
    );
};

export default AccountSettingsPage;
