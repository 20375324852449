import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon, InfoIcon } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../shared';
import { AlertInfo } from '../../../../shared/components/AlertInfo';

const InviteAndEarnSidebar = observer(() => {
    const navigate = useNavigate();
    return (
        <AlertInfo
            text='How sharing works and what conditions apply'
            onClick={() => navigate(ROUTE_PATHS.inviteAndEarnRules)}
            bottomIndent='20px'
            icon={<InfoIcon />}
            beforeIcon={<Icon size='22px' component={() => <InfoIcon />} />}
            simple={false}
        />
    );
});

export default InviteAndEarnSidebar;
