import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TOP_INDENTS, Typography } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../constants';
import { StyledButton, Styles } from './styles';

export const ApplyNow = observer(({ hasTopIndent, alignContent, onClick = () => null }) => {
    const navigate = useNavigate();
    return (
        <Styles style={hasTopIndent ? TOP_INDENTS.XL : {}} $alignContent={alignContent}>
            <Typography.Text color='secondaryText'>Not a member? </Typography.Text>
            <StyledButton
                type='unstyled'
                onClick={() => {
                    onClick();
                    return navigate(ROUTE_PATHS.onboarding);
                }}
            >
                <Typography.Text data-e2e='auth_applyNow-button' color='secondaryText' type='badgeSmall'>
                    Apply now
                </Typography.Text>
            </StyledButton>
        </Styles>
    );
});
