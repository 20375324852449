import { Button, Form } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../utils';
import { $border } from '../../constants';

export const StyledForm = styled(Form)`
    @media (${GRID_DEVICES_MAX.xs}) {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: 220px;
    }
`;

export const StyledCategoriesWrap = styled.div`
    display: grid;
    grid-template-columns: 413px 413px;
    justify-content: ${({ $simple }) => ($simple ? 'start' : 'space-between')};
    grid-row-gap: 24px;

    @media (max-width: 965px) {
        grid-template-columns: ${({ $simple }) => ($simple ? '1fr' : '320px 320px')};
    }
`;

export const ButtonWrap = styled.div`
    display: flex;
    justify-content: ${({ $align }) => $align || 'flex-start'};

    @media (${GRID_DEVICES_MAX.xs}) {
        padding: 24px;
        background-color: #ffffff;
        z-index: 2;

        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;

        :after {
            content: '';
            border-top: 1px solid ${$border};
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
        }
    }
`;

export const StyledButton = styled(Button)`
    && {
        width: ${({ $simple }) => ($simple ? '100%' : '305px')};
    }
`;
