import { KliHero } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const ApplyInviteCodeStyles = styled.div`
    width: 100%;
    height: 100%;

    .ant-col {
        position: relative;
    }

    .ant-row {
        height: 100%;
    }
`;

export const ApplyInviteCodeContentStyles = styled.div`
    max-width: 480px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 100px 30px;

    @media (${GRID_DEVICES_MAX.lg}) {
        padding: 40px 14px 0;
        max-width: 550px;
    }
`;

export const StyledKliHero = styled(KliHero)`
    padding: 0 0 25px;
`;
