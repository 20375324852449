import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useOnPurchaseSuccess = (store) => {
    const { processCheckoutOrder, cart } = store.thankYouPageStore;
    const [searchParams] = useSearchParams();

    const checkoutOrderCode = searchParams.get('ch_order_code');
    const checkoutOrderId = searchParams.get('ch_order_id');

    useEffect(() => {
        const removeListener = store.trackingStore.sendGa4PostMessage({
            eventName: 'purchase'
        });

        (async () => {
            if (checkoutOrderCode) {
                await processCheckoutOrder({ code: checkoutOrderCode });
            }
        })();

        return () => {
            removeListener();
        };
    }, []);

    return { checkoutOrderCode, checkoutOrderId, cart };
};
