import { Button } from '@klickly/front-packages';
import styled from 'styled-components';
import { $grayScale60Black } from '../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const StyledButton = styled(Button)`
    &&&& {
        color: inherit;
        padding: 0;
        display: inline-block;
        text-decoration: underline;
        text-decoration-color: ${$grayScale60Black};

        span {
            color: ${$grayScale60Black};
        }
    }
`;

export const StyledWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 80px;

    @media (${GRID_DEVICES_MAX.md}) {
        margin-bottom: 40px;
    }
`;
