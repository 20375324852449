import React, { Fragment, useEffect, useState } from 'react';
import { Icon, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { DialogWithHeader } from '../DialogWithHeader';
import { ContentWrap, DialogWrap, Message } from '../DialogWrap';
import { Promotions } from '../Promotions';

export const WriteProductReviewTask = inject('store')(
    observer(({ store, onClose }) => {
        const { getWriteProductReviewTaskData, writeProductReviewTaskData } = store.tasksStore;
        const [product, setProduct] = useState();

        const onReview = (product) => {
            setProduct(product);
        };

        useEffect(() => {
            getWriteProductReviewTaskData();
        }, []);

        return writeProductReviewTaskData ? (
            <DialogWithHeader
                onClose={onClose}
                title={writeProductReviewTaskData?.data?.title}
                subTitle={writeProductReviewTaskData?.data?.description}
            >
                <DialogWrap centred={writeProductReviewTaskData?.products?.length < 2}>
                    <ContentWrap>
                        {!product && (
                            <Fragment>
                                {writeProductReviewTaskData?.products?.length > 1 && (
                                    <Message type='body1'>Choose a product to review</Message>
                                )}

                                <Promotions
                                    promotions={writeProductReviewTaskData?.productReviewList}
                                    action={onReview}
                                    onClose={onClose}
                                    emptyMessage='Claim a gift or shop Giftly to write a review'
                                    actionTitle={<Typography.Text as='span'>Review</Typography.Text>}
                                />
                                <br />
                            </Fragment>
                        )}
                    </ContentWrap>
                </DialogWrap>
            </DialogWithHeader>
        ) : (
            <Icon.Loader />
        );
    })
);
