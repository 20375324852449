import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Col, GRID_POINTS, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../shared';
import { Sidebar } from '../../shared/components/AuthSidebar';
import { ROUTES } from './constants/routes';
import { StyledRow, Styles } from './styled';

const AuthPage = inject('store')(
    observer(({ store }) => {
        const simple = useSimpleInterface(GRID_POINTS.lg);
        const navigate = useNavigate();
        const { isLoggedIn, isCustomerStatusOnboarding } = store.accountStore;

        useEffect(() => {
            if (isLoggedIn) {
                isCustomerStatusOnboarding ? navigate(ROUTE_PATHS.onboarding) : navigate(ROUTE_PATHS.suiteGs);
            }
        }, []);

        return (
            <Styles>
                <StyledRow>
                    {!simple && (
                        <Col span={8}>
                            <Sidebar
                                headText='Discover top trending products from hot brands'
                                bodyText='Access free gifts and private discounts on premium products from trending brands in your own digital, curated, weekly Gifting Suite'
                            />
                        </Col>
                    )}

                    <Col md={{ span: 24 }} lg={{ span: 16 }} style={{ position: 'relative', width: '100%' }}>
                        <Outlet />
                    </Col>
                </StyledRow>
            </Styles>
        );
    })
);

AuthPage.ROUTES = ROUTES;

export default AuthPage;
