import { useNavigate } from 'react-router-dom';
import { kcpAPI, ROUTE_PATHS } from '../../../shared';

export const useHandleGoToCheckout = (store, isLoggedIn, withUpsellCheck) => {
    const navigate = useNavigate();
    return async ({ cartId, checkoutKey, isBuyNow = false }) => {
        let showUpsell = false;

        if (withUpsellCheck) {
            showUpsell = await store.upsellStore.checkForUpsell(cartId);
        }

        if (isLoggedIn) {
            return showUpsell
                ? navigate(ROUTE_PATHS.upsell, { state: { cartId, checkoutKey } })
                : window.open(`${kcpAPI.cart.makeCheckoutUrl(cartId, checkoutKey)}`, '_self');
        }

        return navigate(ROUTE_PATHS.preCheckout, { state: { cartId, checkoutKey, isBuyNow } });
    };
};
