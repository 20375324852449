import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../utils';
import { $secondaryText } from '../../constants';

export const PlanItemContent = styled.div`
    padding: 15px 24px;

    h2 {
        margin: 5px 0;
    }

    p {
        padding: 5px 0;
    }

    ul {
        margin-left: 20px;
        color: ${$secondaryText};
        list-style-position: outside;
        padding: 10px 10px 15px;
    }
`;

export const DescriptionMessage = styled.div`
    padding: 24px 0;
    text-align: center;
`;

export const PlanItem = styled.div`
    margin: 24px 12px;
    max-width: 265px;

    @media (${GRID_DEVICES_MAX.md}) {
        max-width: 100%;
        margin: 24px;
    }
`;

export const PlanItems = styled.div`
    display: flex;
    justify-content: center;
    @media (${GRID_DEVICES_MAX.md}) {
        flex-direction: column;
    }
`;
