import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../utils';
import { animation$ } from '../../constants/animation';

export const StyledWrapper = styled.div`
    margin-top: 24px;
    margin-bottom: 80px;

    @media (${GRID_DEVICES_MAX.sm}) {
        margin-bottom: 32px;
    }
`;

export const StyledSliderWrapper = styled.div`
    position: relative;
    height: ${({ $promotionsHeight }) => $promotionsHeight};
    overflow: hidden;

    .slick-track {
        height: 100%;

        ${({ $singleItem }) =>
            $singleItem &&
            css`
                margin: 0;
            `};
    }

    .slick-slider,
    .slick-list {
        height: 100%;
    }

    .slick-slide {
        > div {
            height: 100%;
        }
    }

    .slick-next {
        top: auto;
        right: 0;
        bottom: -16px;
        width: 36px;
        height: 36px;

        &:before {
            content: none;
        }
    }

    .slick-prev {
        top: auto;
        bottom: -16px;
        left: 0;
        width: 36px;
        height: 36px;

        &:before {
            content: none;
        }
    }

    .slick-dots {
        bottom: 8px;
        left: 50%;
        transform: translate(-50%);
        width: calc(100% - 80px);

        li {
            width: 15px;
            margin: 0;
        }
    }
`;

export const StyledProductWrapper = styled.div`
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 10px;
    animation: ${animation$} 0.3s ease-in-out;
    will-change: transform, opcity;

    &:hover {
        z-index: 1;
    }

    @media (${GRID_DEVICES_MAX.sm}) {
        padding: 10px 8px;
    }
`;
