import React, { Fragment, useEffect, useState } from 'react';
import { Popover, TOP_INDENTS } from '@klickly/front-packages';
import { StyledButton, StyledContentWrapper, StyledPopoverContainer } from './styled';

const PopoverWithButton = ({
    hasTopIndent,
    children,
    content,
    buttonText = 'Got it',
    open,
    onTooltipButtonClick = () => null,
    showButton = true,
    ...props
}) => {
    const [popoverOpen, setPopoverOpen] = useState(open);

    useEffect(() => {
        if (open !== popoverOpen) setPopoverOpen(open);
    }, [open]);

    return (
        <div style={hasTopIndent ? TOP_INDENTS.XXL : {}}>
            <StyledPopoverContainer />
            <Popover
                overlayClassName='popover-with-button'
                open={popoverOpen}
                getPopupContainer={(node) => node}
                type='primary-black'
                content={
                    <Fragment>
                        <StyledContentWrapper as='div' type='descriptor1'>
                            {content}
                        </StyledContentWrapper>
                        {showButton ? (
                            <StyledButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPopoverOpen(false);
                                    onTooltipButtonClick();
                                }}
                            >
                                {buttonText}
                            </StyledButton>
                        ) : null}
                    </Fragment>
                }
                {...props}
            >
                {children}
            </Popover>
        </div>
    );
};

export default PopoverWithButton;
