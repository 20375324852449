import styled, { css } from 'styled-components';
import { $primaryText } from '../../../../shared';

export const StyledWrap = styled.div`
    && input {
        color: ${$primaryText};
    }

    span.ant-avatar {
        border: 0;
        cursor: initial;
    }

    ${({ $simple }) =>
        $simple
            ? css`
                  display: flex;
                  align-items: center;
                  margin-bottom: 48px;
              `
            : ''}
`;

export const StyledLoaderWrap = styled.div`
    position: relative;
    width: 310px;
    height: 310px;

    ${({ $simple }) =>
        $simple
            ? css`
                  width: 100%;
                  height: 200px;
              `
            : ''}
`;
