import { Button, Image, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';

export const StyledWrap = styled.div`
    position: relative;
    max-width: 305px;
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    margin: 0 20px 64px;
    cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};

    ${({ $simple }) =>
        $simple
            ? css`
                  max-width: 100%;
              `
            : ''}
`;

export const StyledContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

export const StyledImageWrap = styled.div`
    margin-right: 20px;
`;

export const StyledTitle = styled(Typography.Title)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    ${({ $disabled }) =>
        $disabled
            ? css`
                  opacity: 0.5;
              `
            : ''}
`;

export const StyledText = styled(Typography.Text)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    ${({ $disabled }) =>
        $disabled
            ? css`
                  opacity: 0.5;
              `
            : ''}
`;

export const StyledImage = styled(Image)`
    object-fit: cover;

    ${({ $disabled }) =>
        $disabled
            ? css`
                  opacity: 0.5;
              `
            : ''}
`;

export const StyledDetails = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledButtonWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const StyledButton = styled(Button)`
    &&& {
        padding: 12px 20px;
    }
`;

export const StyledOOSText = styled(Typography.Text)`
    margin-right: 12px;
`;
