import styled from 'styled-components';
import { $shadow } from '../../../../index';

export const ShadowCardStyles = styled.div`
    display: flex;
    width: 100%;
    height: 100%;

    border: 2px solid ${({ borderColor }) => borderColor || 'transparent'};

    box-shadow: ${$shadow};
`;
