import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeleteIcon, Icon, RIGHT_INDENTS, useSimpleInterface, ViewIcon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../shared';
import {
    StyledButton,
    StyledButtonWrap,
    StyledContent,
    StyledDetails,
    StyledImage,
    StyledImageWrap,
    StyledOOSText,
    StyledText,
    StyledTitle,
    StyledWrap
} from './styled';

export const Item = inject('store')(
    observer(({ data = {}, store }) => {
        const navigate = useNavigate();
        const { search } = useLocation();
        const [pending, setPending] = useState(false);
        const simple = useSimpleInterface(710);
        const { _id, title, brandName, image, promotionId, available } = data;
        const { removeWishlistItem } = store.accountStore;

        const onBuyNow = (productId) =>
            navigate(
                {
                    pathname: `/marketplace/${productId}`,
                    search: search
                },
                { state: { ref: ROUTE_PATHS.wishlist } }
            );

        const handleRemoveClick = async (e) => {
            setPending(true);
            e.preventDefault();
            e.stopPropagation();
            await removeWishlistItem(_id);
            setPending(false);
        };

        const handleBuyNow = () => {
            onBuyNow(promotionId);
        };

        return (
            <StyledWrap $disabled={!available} $simple={simple} onClick={available && !pending ? handleBuyNow : null}>
                {!pending ? (
                    <StyledContent $simple={simple}>
                        <StyledImageWrap>
                            <StyledImage width={150} height={150} src={image} $disabled={!available} />
                        </StyledImageWrap>
                        <StyledDetails>
                            <div>
                                <StyledTitle level={3} $disabled={!available}>
                                    {title}
                                </StyledTitle>
                                <StyledText type='body2' $disabled={!available}>
                                    {brandName}
                                </StyledText>
                            </div>
                            <StyledButtonWrap>
                                {available ? (
                                    <StyledButton
                                        type='secondary'
                                        onClick={handleBuyNow}
                                        style={{ ...RIGHT_INDENTS.XS }}
                                    >
                                        <Icon size='16px' component={() => <ViewIcon />} />
                                    </StyledButton>
                                ) : (
                                    <StyledOOSText type='descriptor2' align='center'>
                                        Out of Stock
                                    </StyledOOSText>
                                )}
                                <StyledButton type='secondary' onClick={handleRemoveClick}>
                                    <Icon size='16px' component={() => <DeleteIcon />} />
                                </StyledButton>
                            </StyledButtonWrap>
                        </StyledDetails>
                    </StyledContent>
                ) : (
                    <Icon.Loader />
                )}
            </StyledWrap>
        );
    })
);
