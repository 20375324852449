import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI } from '../shared';
import { seasonalChallengeName } from '../shared/utils/seasonalChallengeName';
import { models } from './constants';

export const SeasonalChallengeStore = types
    .model('SeasonalChallengeStore', {
        pending: false,
        error: types.maybeNull(types.string),
        seasonalChallenge: types.optional(models.SeasonalChallengeModel, {}),
        challenges: types.array(models.SeasonalInnerChallengeModel)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get activeChallenge() {
            return self.challenges.find((challenge) => challenge.startedAt && !challenge.completedAt);
        },
        get isNoActiveChallenge() {
            return self.challenges.every((challenge) => challenge.startedAt && challenge.completedAt);
        },
        get activeChallengeRewards() {
            return self.activeChallenge?.rewards.reduce(
                (result, reward) => ({ ...result, [reward.type]: reward.value }),
                {}
            );
        }
    }))
    .actions((self) => {
        const getSeasonalChallenge = flow(function* getSeasonalChallenge(refetchPointsAndClaims = false) {
            self.pending = true;
            try {
                const { challenges, ...rest } = yield self.root.get(kcpAPI.challenges.getChallengesGroup, {
                    alias: process.env.REACT_APP_SEASONAL_CHALLENGE_ALIAS
                });
                if (refetchPointsAndClaims) {
                    self.root.claimsPointsStore.getClaims();
                    self.root.claimsPointsStore.getPoints();
                }
                const season = seasonalChallengeName();
                self.seasonalChallenge = {
                    ...rest,
                    ...{
                        name: rest.name.replaceAll('Spring', season),
                        description: rest.description
                            .replaceAll('Spring', season)
                            .replaceAll('spring', season.toLowerCase())
                    }
                };
                self.challenges = [...(challenges || [])].sort(({ position: a }, { position: b }) => a - b);
            } catch (e) {
                self.error = e?.response?.message || e.toString();
            } finally {
                self.pending = false;
            }
        });

        return { getSeasonalChallenge };
    });
