import React from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    Col,
    GRID_POINTS,
    Icon,
    LEFT_INDENTS,
    RIGHT_INDENTS,
    Row,
    Search,
    SearchIcon,
    TOP_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $background, $primaryText } from '../../shared';
import { Layout } from '../../shared/components/Layout';
import { StyledWrapper } from './styled';

const Page404 = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const location = useLocation();
        store.setHistoryState(navigate, location);

        const simple = useSimpleInterface(GRID_POINTS.md);
        const navigationType = useNavigationType();

        return (
            <Layout>
                <StyledWrapper>
                    <Row justify='middle' align='center'>
                        <Col sm={24} md={16} xl={11}>
                            <Row justify='middle' align='center'>
                                <Col span={24} style={{ ...BOTTOM_INDENTS.XXL, ...TOP_INDENTS.XXL }}>
                                    <Icon
                                        size='40px'
                                        roundedSize='120px'
                                        roundedColor={$background}
                                        hoverRoundedColor={$background}
                                        color={$primaryText}
                                        roundedOpacity={1}
                                        component={() => <SearchIcon />}
                                    />
                                </Col>
                                <Col align='center' span={24}>
                                    <Typography.Title
                                        align='center'
                                        style={{ ...LEFT_INDENTS.M, ...BOTTOM_INDENTS.M, ...RIGHT_INDENTS.M }}
                                        color={$primaryText}
                                        level={2}
                                    >
                                        Looks like this page doesn’t exist
                                    </Typography.Title>
                                    <Typography.Text
                                        style={{ ...LEFT_INDENTS.M, ...BOTTOM_INDENTS.M, ...RIGHT_INDENTS.M }}
                                        align='center'
                                        color={$primaryText}
                                        type='body1'
                                    >
                                        Sorry, we can’t find this page. But you can explore other offers via the menu
                                        and search bar.
                                    </Typography.Text>
                                    {simple && <Search location={location} navigationType={navigationType} />}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </StyledWrapper>
            </Layout>
        );
    })
);

export default Page404;
