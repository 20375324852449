import { Button } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../utils';
import { $primaryText } from '../../../../constants';

export const StyledArrowButton = styled(Button)`
    &&&,
    &&&:active,
    &&&:focus,
    &&&:disabled {
        width: 40px;
        height: 40px;
        padding: 0;
        border: 2px solid ${$primaryText};
        border-radius: 50%;

        svg {
            width: 16px;
            height: 16px;
        }

        @media (${GRID_DEVICES_MAX.sm}) {
            width: 36px;
            height: 36px;
            color: inherit;
            z-index: 2;
        }
    }
`;
