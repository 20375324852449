import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI } from '../shared';
import { models } from './constants';

export const DealsStore = types
    .model('DealsStore', {
        holidayDealsPromotions: types.array(models.PromotionModel),
        holidayDealsPromotionsLoading: types.boolean,
        holidayDealsPromotionsError: types.maybeNull(types.string),
        dayDealsPromotions: types.array(models.PromotionModel),
        dayDealsPromotionsLoading: types.boolean,
        dayDealsPromotionsError: types.maybeNull(types.string),
        bestWelcomeGiftOffers: types.array(
            types.model('BestWelcomeGiftOfferModel', {
                brandId: types.string,
                name: types.string,
                shopDomain: types.string,
                logoImage: types.maybeNull(types.string),
                weightedAvgPoints: types.maybeNull(types.number)
            })
        ),
        bestWelcomeGiftOffersLoading: types.boolean,
        bestWelcomeGiftOffersError: types.maybeNull(types.string),
        strategyInfo: types.maybeNull(models.StrategyInfoModel),
        strategyInfoLoading: types.boolean,
        strategyInfoError: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const getHolidayDealsPromotions = flow(function* getHolidayDealsPromotions() {
            self.holidayDealsPromotionsLoading = true;
            try {
                const data = yield self.root.get(kcpAPI.marketplace.promotions.get, {
                    limit: 24,
                    strategyId: process.env.REACT_APP_HOLIDAY_DEALS_STRATEGY
                });
                self.holidayDealsPromotions = data.promotions;
            } catch (error) {
                self.holidayDealsPromotionsError = error?.message;
            } finally {
                self.holidayDealsPromotionsLoading = false;
            }
        });

        const getDayDealsPromotions = flow(function* getDayDealsPromotions() {
            self.dayDealsPromotionsLoading = true;
            try {
                self.dayDealsPromotions = yield self.root.get(kcpAPI.marketplace.bestSellers.get, { limit: 24 });
            } catch (error) {
                self.dayDealsPromotionsError = error?.message;
            } finally {
                self.dayDealsPromotionsLoading = false;
            }
        });

        const getBestWelcomeGiftOffers = flow(function* getBestWelcomeGiftOffers({ limit }) {
            self.bestWelcomeGiftOffersLoading = true;
            try {
                self.bestWelcomeGiftOffers = yield self.root.get(kcpAPI.marketplace.welcomeGiftOffers.get, { limit });
            } catch (error) {
                self.bestWelcomeGiftOffersError = error?.message;
            } finally {
                self.bestWelcomeGiftOffersLoading = false;
            }
        });

        const getStrategyInfo = flow(function* getStrategyInfo(
            strategyId = process.env.REACT_APP_HOLIDAY_DEALS_STRATEGY
        ) {
            self.strategyPending = true;
            try {
                self.strategyInfo = yield self.root.get(`${kcpAPI.marketplace.getStrategyInfoById(strategyId)}`);
            } catch (e) {
                // do nothing
            } finally {
                self.strategyPending = false;
            }
        });

        return { getHolidayDealsPromotions, getDayDealsPromotions, getBestWelcomeGiftOffers, getStrategyInfo };
    });
