import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CopyIcon, GRID_POINTS, Icon, notification, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { TASK_STATUSES } from '../../../../../../../../../../../../../../shared';
import { ErrorField } from '../../../../../../../../../../../../../../shared/components/ErrorField';
import NotificationContent from '../../../../../../../../../../../../../../shared/components/NotificationContent';
import { StyledButton, StyledInput, StyledWrap } from './styles';

const InviteFriendTaskLink = inject('store')(
    observer(({ store }) => {
        const [copied, setCopied] = useState(false);
        const { referralInviteLink, isApprovedCustomer } = store.accountStore;
        const { sendInvite, inviteFriendTask, taskErrorMessage } = store.tasksStore;
        const challenge = store.tasksStore?.inviteFriendTask;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const navigate = useNavigate();
        const error = taskErrorMessage;

        const onCopyLink = async () => {
            try {
                await navigator.clipboard.writeText(referralInviteLink);
            } catch (e) {
                // do nothing
            } finally {
                setCopied(true);
            }
        };

        useEffect(() => {
            if (inviteFriendTask?.data.status !== TASK_STATUSES.completed && copied) {
                (async () => {
                    const response = await sendInvite({ copied: true }, () => null);

                    if (!response) {
                        return;
                    }

                    store.trackingStore.trackChallengeNotification(
                        challenge,
                        TASK_STATUSES.completed,
                        `${challenge?.data?.title} completed!`
                    );
                    notification.open({
                        key: challenge?.alias,
                        duration: null,
                        description: <NotificationContent.Completed completedActionName={challenge?.data?.title} />,
                        bottom: simple ? '0' : '8px'
                    });
                    notification.open({
                        duration: null,
                        description: <NotificationContent.RewardEarned value={50} navigate={navigate} />,
                        bottom: simple ? '0' : '8px'
                    });
                })();
            }
        }, [copied, inviteFriendTask?.data.status, isApprovedCustomer]); //eslint-disable-line

        return (
            <StyledWrap>
                <StyledInput
                    size='large'
                    type='text'
                    label='Invite link'
                    defaultValue={referralInviteLink}
                    value={referralInviteLink}
                />
                {error ? <ErrorField error={error} /> : null}
                <StyledButton size='large' type='primary' onClick={onCopyLink} $copied={copied}>
                    <Icon color={copied ? 'success' : 'primaryText'} component={() => <CopyIcon />} />
                    <Typography.Text as='span' type='badgeLarge' color={copied ? 'success' : 'primaryText'}>
                        {copied ? 'Link copied to clipboard!' : 'Copy link'}
                    </Typography.Text>
                </StyledButton>
            </StyledWrap>
        );
    })
);

export default InviteFriendTaskLink;
