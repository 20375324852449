import React from 'react';
import { ROUTE_PATHS } from '../../../shared';
import InviteAndEarn from '../components/InviteAndEarn';
import InviteAndEarnRulesPage from '../components/InviteAndEarnRulesPage';

export const ROUTES = [
    {
        path: ROUTE_PATHS.inviteAndEarn,
        index: true,
        element: <InviteAndEarn />
    },
    {
        path: ROUTE_PATHS.inviteAndEarnRules,
        element: <InviteAndEarnRulesPage />
    }
];
