import { Button, Icon, Image, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $secondaryText } from '../../../../../../../../../../shared';

export const Styles = styled.div`
    width: 100%;
    margin-bottom: 36px;

    &:last-child {
        margin-bottom: 36px;
    }
`;

export const CardBody = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;
`;

export const CardBodyLeft = styled.div`
    display: flex;
    align-items: flex-start;
    padding-right: 16px;
`;

export const StyledImage = styled(Image)`
    && {
        width: 76px;
        height: 76px;
        object-fit: contain;
        margin-right: 10px;
    }
`;

export const ProductTitle = styled(Typography.Title)`
    margin-bottom: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const StyledVariantTitle = styled(Typography.Text)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const Info = styled.div``;

export const StyledPrice = styled(Typography.Text)`
    white-space: nowrap;
    text-align: right;
`;

export const StyledText = styled(Typography.Text)`
    padding-right: 16px;
`;

export const StyledInfoRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    ${({ $justify }) =>
        $justify &&
        css`
            justify-content: ${$justify};
        `};
`;

export const StyledQuestionButton = styled(Button)`
    && {
        width: 20px;
        height: 12px;
        padding: 0 4px;
        color: ${$secondaryText};

        &:hover:not(:disabled) {
            color: ${$secondaryText};
        }

        &:focus:not(:disabled) {
            outline: none;
        }

        .anticon {
            margin-left: 4px;
        }
    }
`;

export const StyledLoader = styled(Icon.Loader)`
    position: static;
    width: 20px;

    span {
        font-size: inherit;
    }

    svg {
        width: 16px;
        height: 16px;
    }
`;
