import { Button, device, Form, Icon, Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../utils';

const isIos = device.iOS();
const isSafari = device.isSafari();

export const StyledForm = styled(Form)`
    @media (${GRID_DEVICES_MAX.xs}) {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
`;

export const ButtonWrap = styled.div`
    @media (${GRID_DEVICES_MAX.xs}) {
        margin-top: ${() => (isSafari && isIos ? '0' : 'auto')};
        position: relative;
        padding-top: 24px;
        padding-bottom: ${() => (isSafari && isIos ? '70px' : 0)};

        :after {
            content: '';
            border-top: ${() => (isSafari && isIos ? 'none' : '1px solid #D2D6DA')};
            display: block;
            position: absolute;
            width: calc(100% + 48px);
            left: -24px;
            top: 0;
        }
    }
`;

export const StyledButton = styled(Button)`
    && {
        min-width: 305px;
        padding: 18px 64px;

        @media (${GRID_DEVICES_MAX.xs}) {
            width: 100%;
        }
    }
`;

export const StyledIcon = styled(Icon)`
    &.anticon {
        height: 61px;
        line-height: 61px;
    }
`;

export const StyledText = styled(Typography.Text)`
    margin-top: -12px;
`;
