import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSimpleInterface } from '@klickly/front-packages';
import { useModal } from '../../../../../shared';
import { GRID_POINTS } from '../../../../../utils';
import { WelcomeModal } from '../components/WelcomeModal';

export const useWelcomeModal = (onAfterClose = () => null) => {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const removeQueryCallback = () => {
        if (searchParams.has('welcomeModal')) {
            searchParams.delete('welcomeModal');
            navigate(
                {
                    search: searchParams.toString()
                },
                { replace: true }
            );
            onAfterClose();
        }
    };

    const { openModal, closeModal } = useModal(removeQueryCallback);

    const onClose = () => {
        closeModal();
        removeQueryCallback();
    };

    const showModal = () => {
        openModal({
            children: <WelcomeModal onClose={onClose} simple={simple} />,
            withoutCloseIcon: simple,
            modalWrapperStyles: simple
                ? {
                      display: 'flex',
                      alignItems: 'center'
                  }
                : {},
            modalContentStyles: { overflow: 'inherit' }
        });
    };

    return { showModal };
};
