import { Row } from '@klickly/front-packages';
import styled, { css } from 'styled-components';

export const StyledRow = styled(Row)`
    ${({ $disabled }) =>
        $disabled
            ? css`
                  opacity: 0.5;
                  pointer-events: none;
                  touch-action: none;
              `
            : ''}
`;
