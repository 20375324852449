import { StripeCardIcon } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledBillingForm = styled.div`
    .ant-col {
        & > svg {
            width: 100%;
            max-width: 305px;
            height: 100%;
        }
    }
`;

export const CheckboxWrap = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
`;

export const StyledContainer = styled.div`
    position: relative;
`;

export const StyledStripeCardIcon = styled(StripeCardIcon)`
    &&& {
        position: static;
        transform: none;
    }
`;
