import { useMemo } from 'react';
import { useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../utils';
import { getAvailableMenuItems, getMenuItemsDesktop, getMenuItemsMobile } from '../utils';

export const useMenuItems = (store, menuMode = null, isMenuOpen, onMenuClose = () => null) => {
    const simple = useSimpleInterface(GRID_POINTS.md);
    const { logout: onLogout } = store.authStore;
    const { isLoggedIn, isApprovedCustomer } = store.accountStore;
    const { getLinkToMarketPlace } = store.navigationStore;
    const { shouldOnboarding } = store.onboardingRedirectionStore;
    const { seasonalChallenge } = store.seasonalChallengeStore;

    return useMemo(() => {
        const items = simple
            ? getMenuItemsMobile({
                  store,
                  isMenuOpen,
                  onMenuClose,
                  onLogout,
                  isApprovedCustomer,
                  isLoggedIn
              })
            : getMenuItemsDesktop({ store, onMenuClose, onLogout, isLoggedIn, isApprovedCustomer });

        return getAvailableMenuItems(items);
    }, [
        getLinkToMarketPlace,
        shouldOnboarding,
        onLogout,
        isMenuOpen,
        isLoggedIn,
        isApprovedCustomer,
        seasonalChallenge?.name
    ]);
};
