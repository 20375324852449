import React from 'react';
import { Item } from '../../styled';

export const CheckedFiltersList = ({ list, onRemove }) => {
    return list.map((item) => (
        <Item type='secondary' key={item.key} onClose={() => onRemove({ ...item, checked: false })}>
            {item.name}
        </Item>
    ));
};
