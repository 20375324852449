import React from 'react';
import { BOTTOM_INDENTS, CloseXIcon, Icon, KliHero, TOP_INDENTS, Typography } from '@klickly/front-packages';
import {
    StyledButtonsWrapper,
    StyledCancelButton,
    StyledConfirmButton,
    StyledContent,
    StyledHeader,
    StyledIconWrap,
    StyledWrapper
} from './styles';

export const ConfirmDeleting = ({ title = '', bodyText = '', onConfirm, onClose, simple }) => {
    const onConfirmClick = () => {
        onConfirm();
        onClose();
    };

    return (
        <StyledWrapper>
            <StyledHeader>
                <KliHero
                    title={title}
                    backgroundColor='primaryBackground'
                    color='primary'
                    textAlign='center'
                    simple={false}
                    titleOnTop
                    withoutBorders
                    onBack={simple ? onClose : null}
                    style={{ justifyContent: 'center' }}
                />
                <StyledContent>
                    {!simple ? (
                        <StyledIconWrap>
                            <Icon size='20px' color='primary' component={() => <CloseXIcon />} />
                        </StyledIconWrap>
                    ) : null}
                    <Typography.Text style={{ ...BOTTOM_INDENTS.SM, ...TOP_INDENTS.SM }} type='body2' align='center'>
                        {bodyText}
                    </Typography.Text>
                    <StyledButtonsWrapper $simple={simple}>
                        <StyledConfirmButton type='primary' onClick={onConfirmClick} $simple={simple}>
                            Confirm
                        </StyledConfirmButton>
                        {simple ? (
                            <StyledCancelButton type='unstyled' onClick={onClose} $simple={simple}>
                                Cancel
                            </StyledCancelButton>
                        ) : (
                            <StyledCancelButton type='secondary' onClick={onClose} $simple={simple}>
                                Cancel
                            </StyledCancelButton>
                        )}
                    </StyledButtonsWrapper>
                </StyledContent>
            </StyledHeader>
        </StyledWrapper>
    );
};
