import React from 'react';
import { BOTTOM_INDENTS, Typography } from '@klickly/front-packages';
import { InfoItem } from '../InfoItem';

export const InfoList = ({ data }) => {
    return (
        <div style={BOTTOM_INDENTS.XL}>
            {data &&
                data.map(({ icon, question, answer }) => (
                    <InfoItem icon={icon} key={question}>
                        <Typography.Title style={BOTTOM_INDENTS.SM} level={2}>
                            {question}
                        </Typography.Title>
                        <Typography.Text type='body1' dangerouslySetInnerHTML={{ __html: answer }} />
                    </InfoItem>
                ))}
        </div>
    );
};
