import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    Button,
    Col,
    ForwardArrowIcon,
    Icon,
    LEFT_INDENTS,
    Row,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../shared';
import { GRID_POINTS } from '../../../../../../utils';
import BonusGift from '../../../BonusGift';
import { StyledRewardSectionWrapper } from './styled';

const ChallengeCompleted = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { challenges, seasonalChallenge } = store.seasonalChallengeStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const isCompleted = seasonalChallenge?.completed || !challenges?.length;
        const isClaim = seasonalChallenge?.reward?.type === 'claim';

        return (
            <Fragment>
                <Typography.Title style={BOTTOM_INDENTS.M} align={simple ? 'center' : 'left'}>
                    {seasonalChallenge.name} completed
                    {isCompleted ? '' : '!'}
                </Typography.Title>
                {seasonalChallenge?.reward ? (
                    <Fragment>
                        {isCompleted ? (
                            <Typography.Text type='descriptor1' style={BOTTOM_INDENTS.M}>
                                Congratulations on completing all {challenges?.length} challenges and earning your{' '}
                                {seasonalChallenge?.name} bonus gift! We’ve automatically added your{' '}
                                {isClaim ? (
                                    ' Gifting Suites '
                                ) : (
                                    <Fragment>
                                        {seasonalChallenge?.reward?.type === 'coupon' ? ' Coupon ' : ' Points '}
                                    </Fragment>
                                )}{' '}
                                to your account.
                            </Typography.Text>
                        ) : (
                            <Typography.Text type='descriptor1' style={BOTTOM_INDENTS.M}>
                                Congratulations on completing all {challenges?.length} challenges and earning your{' '}
                                {seasonalChallenge?.name} bonus gift! Click Claim Reward to get{' '}
                                {seasonalChallenge?.reward?.value} free
                                {isClaim ? (
                                    ' Gifting Suites'
                                ) : (
                                    <Fragment>
                                        {seasonalChallenge?.reward?.type === 'coupon' ? ' Coupon' : ' Points'}
                                    </Fragment>
                                )}
                                .
                            </Typography.Text>
                        )}
                    </Fragment>
                ) : null}
                {isCompleted ? (
                    <Button
                        type='primary'
                        block={simple}
                        style={simple ? BOTTOM_INDENTS.XL : {}}
                        onClick={() => navigate(ROUTE_PATHS.challenges)}
                    >
                        Explore other Challenges
                        <Icon style={LEFT_INDENTS.S} size='24px' component={() => <ForwardArrowIcon />} />
                    </Button>
                ) : (
                    <StyledRewardSectionWrapper>
                        <Row gutter={16} align='middle' justify='space-between'>
                            {seasonalChallenge?.reward ? (
                                <Col flex={simple ? '0 0 100%' : 'auto'} style={simple ? BOTTOM_INDENTS.M : {}}>
                                    <BonusGift isCompleted />
                                </Col>
                            ) : null}
                            <Col flex={simple ? '0 0 100%' : 'auto'}>
                                <Button
                                    type='primary'
                                    block={simple}
                                    onClick={() => navigate(`/reward/${seasonalChallenge?.reward?.uuid}`)}
                                >
                                    Claim Reward
                                    <Icon style={LEFT_INDENTS.S} size='24px' component={() => <ForwardArrowIcon />} />
                                </Button>
                            </Col>
                        </Row>
                    </StyledRewardSectionWrapper>
                )}
            </Fragment>
        );
    })
);

export default ChallengeCompleted;
