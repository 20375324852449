import styled, { css } from 'styled-components';

export const StyledIconWrapper = styled.div`
    ${({ $colors }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        margin: 0 auto 24px;
        border: 4px solid ${$colors.borderColor};
        border-radius: 50%;
        background: ${$colors.background};
        z-index: 2;

        .anticon {
            color: ${$colors.color};
        }
    `};
`;
