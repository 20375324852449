import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon, PointsIcon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS, useRewardAndPointsModal } from '../../../../shared';
import { StyledIconWrapper } from './styled';

const ApplyReward = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { state, search } = useLocation();
        const uuid = state?.uuid;
        const redirectTo = state?.redirectTo;
        const { isLoggedIn } = store.accountStore;
        const { applyRewards, applyRewardError, getRewardByUuid } = store.rewardsStore;
        const { openRewardModal } = useRewardAndPointsModal({
            uuid,
            isLoggedIn: false,
            rewardsUnauthorizedTitle: 'Coupon expired',
            rewardNotAvailableProps: {
                useApplyRewardError: true,
                subTitle: '',
                icon: (
                    <StyledIconWrapper
                        size='40px'
                        roundedSize='120px'
                        roundedColor='background'
                        roundedOpacity={1}
                        color='secondaryText'
                        component={() => <PointsIcon />}
                    />
                )
            }
        });
        useEffect(() => {
            if (isLoggedIn) applyRewards(uuid, redirectTo);
            if (!isLoggedIn) {
                navigate({
                    pathname: ROUTE_PATHS.coupons,
                    search: search
                });
                getRewardByUuid(uuid, { onError: openRewardModal });
            }
        }, []);

        useEffect(() => {
            if (applyRewardError) {
                openRewardModal();
                navigate(ROUTE_PATHS.rewards);
            }
        }, [applyRewardError]);

        return applyRewardError ? null : <Icon.Loader />;
    })
);

export default ApplyReward;
