import React, { Fragment } from 'react';
import qs from 'qs';
import OrderLink from '../components/OrderLink';
import { ROUTE_PATHS } from '../constants';

export const getOrdersNumbersView = (orderNumbers, orderId, navigate) => {
    const isMultipleOrders = orderNumbers?.length > 1;
    const prefix1 = !isMultipleOrders ? '' : 's';
    const prefix2 = !isMultipleOrders ? 'has' : 'have';

    const orderNumbersView = (orderNumbers || []).reduce((result, orderNumber, index) => {
        const link = (
            <OrderLink
                ordersLength={orderNumbers?.length}
                key={orderNumber}
                onClick={() =>
                    navigate({
                        pathname: `${ROUTE_PATHS.orders}/${orderId}`,
                        search: qs.stringify({ orderNumber })
                    })
                }
            >
                {orderNumber}
            </OrderLink>
        );
        if (index > 0 && index + 1 !== orderNumbers?.length) {
            return (
                <Fragment>
                    {result}
                    {', '}
                    {link}
                </Fragment>
            );
        }
        return (
            <Fragment>
                {result}
                {link}
            </Fragment>
        );
    }, '');

    return (
        <Fragment>
            Your order{prefix1} {orderNumbersView} {prefix2} been received
        </Fragment>
    );
};
