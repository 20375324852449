import React, { Fragment } from 'react';
import {
    BOTTOM_INDENTS,
    Col,
    Divider,
    formatPoints,
    GRID_POINTS,
    roundToLocaleString,
    Row,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject } from 'mobx-react';
import { $border, $secondaryText } from '../../../../../../../../shared';

const OrderSummary = inject('store')(({ store, orderNumber }) => {
    const { getDetailsByOrderNumber, getPointsSpentByOrderId } = store.orderStore;
    const simple = useSimpleInterface(GRID_POINTS.md);
    const details = getDetailsByOrderNumber(orderNumber);
    const pointsSpent = getPointsSpentByOrderId(details?._id);

    return details ? (
        <Fragment>
            <Typography.Text type={simple ? 'badgeMedium' : 'badgeLarge'} style={BOTTOM_INDENTS.XS}>
                Order Summary
            </Typography.Text>
            <Row gutter={8} align='middle' justify='space-between' style={BOTTOM_INDENTS.XXS}>
                <Col>
                    <Typography.Text type='body2' color={$secondaryText}>
                        Products price
                    </Typography.Text>
                </Col>
                <Col>
                    <Typography.Text type='body2' color={$secondaryText} data-e2e='giftly-order_summary-products-price'>
                        ${roundToLocaleString(details?.priceDetails?.total)}
                    </Typography.Text>
                </Col>
            </Row>
            {pointsSpent > 0 ? (
                <Row gutter={8} align='middle' justify='space-between' style={BOTTOM_INDENTS.M}>
                    <Col>
                        <Typography.Text type='body2' color={$secondaryText}>
                            Paid with points
                        </Typography.Text>
                    </Col>
                    <Col>
                        <Typography.Text type='body2' color={$secondaryText}>
                            -{formatPoints(pointsSpent * 100)} pts
                        </Typography.Text>
                    </Col>
                </Row>
            ) : null}
            <Divider height='1px' color={$border} />
        </Fragment>
    ) : null;
});

export default OrderSummary;
