import React from 'react';
import { BOTTOM_INDENTS, CircleActionsAlertInfoIcon, ExportIcon, Typography } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { InfoItem } from '../../../../../../shared/components/InfoItem';
import { PageHeader } from '../../../../../../shared/components/PageHeader';
import { InfoPageStyles, ItemPointWrapper, StyledIcon } from './styles';

const SharingRulesPage = observer(() => {
    return (
        <InfoPageStyles>
            <PageHeader title='Learn more' subTitle='Sharing Rules' />

            <InfoItem hasBottomIndent icon={<ExportIcon />}>
                <Typography.Title style={BOTTOM_INDENTS.SM} level={2}>
                    Want to share a product with your followers?
                </Typography.Title>
                <Typography.Text type='body1'>
                    We’re a community that promotes brands out of genuine love, not because we’re forced to post. To
                    that end:
                </Typography.Text>
                <ItemPointWrapper>
                    <StyledIcon>
                        <CircleActionsAlertInfoIcon color='#77838F' />
                    </StyledIcon>
                    <Typography.Text type='badgeSmall'>
                        Posting is not compulsory – do NOT post unless YOU want to
                    </Typography.Text>
                </ItemPointWrapper>
                <ItemPointWrapper>
                    <StyledIcon>
                        <CircleActionsAlertInfoIcon color='#77838F' />
                    </StyledIcon>
                    <Typography.Text type='badgeSmall'>
                        Your posts are not sponsored – do NOT mark your posts as ads or paid posts
                    </Typography.Text>
                </ItemPointWrapper>
                <ItemPointWrapper>
                    <StyledIcon>
                        <CircleActionsAlertInfoIcon color='#77838F' />
                    </StyledIcon>
                    <Typography.Text type='badgeSmall'>
                        Access to Giftly is exclusive – do NOT tag Giftly or post about the prices you have access to
                    </Typography.Text>
                </ItemPointWrapper>
            </InfoItem>
        </InfoPageStyles>
    );
});

export default SharingRulesPage;
