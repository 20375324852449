import React from 'react';
import { SubscriptionsContainer } from './components/SubscriptionsContainer';
import { Styles } from './styles';

const SubscriptionPage = () => {
    return (
        <Styles>
            <SubscriptionsContainer />
        </Styles>
    );
};

export default SubscriptionPage;
