import React, { useState } from 'react';
import { Collapse, Panel } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ExpansionHead } from './components/ExpansionHead';
import { Styles } from './styles';

export const Topbar = inject('store')(
    observer(({ store, children }) => {
        const { fullTotalPrice: summaryPrice } = store.preCheckoutStore;
        const [opened, setOpened] = useState(false);
        return (
            <Styles>
                <Collapse withoutPadding bordered={false} onChange={(props) => setOpened(props && props[0])}>
                    <Panel header={<ExpansionHead opened={opened} summaryPrice={summaryPrice} />} showArrow={false}>
                        {children}
                    </Panel>
                </Collapse>
            </Styles>
        );
    })
);
