import React, { useState } from 'react';
import { useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../../../../../../../../../../../../../../../utils';
import { CancelOrder } from '../CancelOrder';

export const CancelOrderModalContainer = inject('store')(
    observer(({ store, onClose, orderId }) => {
        const [renderReason, showReason] = useState();
        const { pending, errorMessage, cancelOrder } = store.ordersStore;

        const simple = useSimpleInterface(GRID_POINTS.sm);

        return (
            <CancelOrder
                orderId={orderId}
                renderReason={renderReason}
                goToCancellationReason={() => showReason(true)}
                pending={pending}
                cancelOrder={cancelOrder}
                errorMessage={errorMessage}
                onClose={onClose}
                simple={simple}
            />
        );
    })
);
