import React, { useEffect } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { notification, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { Animated } from '../../../../shared/components/Animated';
import { Hr } from '../../../../shared/components/Hr';
import NotificationContent from '../../../../shared/components/NotificationContent';
import { PageHeader } from '../../../../shared/components/PageHeader';
import { GRID_POINTS } from '../../../../utils';
import { BillingContainer } from './components/BillingContainer';
import { ShippingContainer } from './components/ShippingContainer';

const ShippingBillingPage = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const navigationType = useNavigationType();
        const { state } = useLocation();
        const taskCompleted = state?.taskCompleted || false;
        const challenge = store.tasksStore?.addShippingTask;
        const simple = useSimpleInterface(GRID_POINTS.sm);

        useEffect(() => {
            if (taskCompleted && navigationType === 'PUSH') {
                store.trackingStore.trackChallengeNotification(
                    challenge,
                    'completed',
                    `${challenge?.data?.title} completed!`
                );
                notification.open({
                    duration: null,
                    description: <NotificationContent.Completed completedActionName={challenge?.data?.title} />,
                    bottom: simple ? '0' : '8px'
                });
                notification.open({
                    duration: null,
                    description: (
                        <NotificationContent.RewardEarned value={1} dimension='Gifting Suite' navigate={navigate} />
                    ),
                    bottom: simple ? '0' : '8px'
                });
            }
            return () => (typeof notification.destroy === 'function' ? notification.destroy() : null);
        }, [taskCompleted]); //eslint-disable-line

        return (
            <Animated style={{ maxWidth: '845px', margin: 'auto' }}>
                <PageHeader title='Shipping and billing' />
                <ShippingContainer />
                <Hr />
                <BillingContainer />
            </Animated>
        );
    })
);

export default ShippingBillingPage;
