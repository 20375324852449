import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    Button,
    Col,
    ForwardArrowIcon,
    Icon,
    Row,
    TOP_INDENTS,
    TrophyIcon,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../shared';
import { GRID_POINTS } from '../../../../../../utils';
import { StyledWrapper } from './styled';

const NoChallenges = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const simple = useSimpleInterface(GRID_POINTS.md);
        const handleGoToDeals = () => {
            store.trackingStore.sendEvent({
                type: 'event',
                name: 'RewardsPage_GoToDeals'
            });
            return navigate(ROUTE_PATHS.deals);
        };

        return (
            <StyledWrapper>
                <Icon
                    size='37px'
                    roundedSize='300%'
                    roundedOpacity={1}
                    roundedColor='background'
                    color='secondaryText'
                    component={() => <TrophyIcon />}
                    style={{
                        ...TOP_INDENTS.L,
                        ...BOTTOM_INDENTS.XXL
                    }}
                />
                <Typography.Title level={2} align='center' style={BOTTOM_INDENTS.M}>
                    You’ve complete all challenges
                </Typography.Title>
                <Typography.Text type='body1' align='center' style={BOTTOM_INDENTS.M}>
                    But you are able to earn more rewards by purchasing Giftly {simple ? <br /> : null}offers
                </Typography.Text>
                <Button type='primary' onClick={handleGoToDeals}>
                    <Row gutter={16} align='middle' justify='center'>
                        <Col>
                            <Typography.Text type='badgeLarge'>Go to Deals</Typography.Text>
                        </Col>
                        <Col>
                            <Icon size='24px' component={() => <ForwardArrowIcon />} />
                        </Col>
                    </Row>
                </Button>
            </StyledWrapper>
        );
    })
);

export default NoChallenges;
