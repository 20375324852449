import React from 'react';
import {
    BOTTOM_INDENTS,
    Col,
    formatPoints,
    GiftIcon,
    Icon,
    PointsIcon,
    Row,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $primary } from '../../../../../../../../shared';
import { GRID_POINTS } from '../../../../../../../../utils';

const EarnReward = inject('store')(
    observer(({ store }) => {
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { activeChallengeRewards } = store.seasonalChallengeStore;
        const points = activeChallengeRewards.point;
        const claims = activeChallengeRewards.claim;
        return points || claims ? (
            <Row gutter={8} align='middle' justify={simple ? 'center' : 'start'} style={BOTTOM_INDENTS.M}>
                <Col>
                    <Typography.Text color={$primary} type='badgeMedium'>
                        And earn
                    </Typography.Text>
                </Col>
                {points ? (
                    <Col>
                        <Row gutter={8} align='middle'>
                            <Col>
                                <Icon size='32px' color={$primary} component={() => <PointsIcon />} />
                            </Col>
                            <Col>
                                <Typography.Text color={$primary} type='badgeMedium'>
                                    {formatPoints(points)} points
                                </Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                ) : null}
                {claims ? (
                    <Col>
                        <Row gutter={8} align='middle'>
                            {points ? (
                                <Col>
                                    <Typography.Text color={$primary} type='badgeMedium'>
                                        {simple ? '+' : 'and'}
                                    </Typography.Text>
                                </Col>
                            ) : null}
                            <Col>
                                <Icon size='32px' color={$primary} component={() => <GiftIcon />} />
                            </Col>
                            <Col>
                                <Typography.Text color={$primary} type='badgeMedium'>
                                    {claims} Gifting Suite
                                </Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                ) : null}
            </Row>
        ) : null;
    })
);

export default EarnReward;
