import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../utils';
import { $border } from '../../constants';

export const LegalHeaderStyles = styled.div`
    width: 100%;
    position: relative;
`;

export const LegalHeaderContent = styled.div`
    padding: 24px 80px;
    border-bottom: 1px solid ${$border};

    @media (${GRID_DEVICES_MAX.lg}) {
        padding: 24px;
    }
`;
