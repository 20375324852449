import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowChevronForwardIcon, Button, Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { kcpAPI, ROUTE_PATHS } from '../../../../../../shared';
import { StyledButton, StyledText, StyledWrapper } from './styled';

const OrderUpdatedModalContent = inject('store')(
    observer(({ store, onCloseModal, cartId, checkoutKey }) => {
        const navigate = useNavigate();

        const handleGoToCheckout = async () => {
            const showUpsell = await store.upsellStore.checkForUpsell(cartId);
            showUpsell
                ? navigate(ROUTE_PATHS.upsell, { state: { cartId, checkoutKey } })
                : window.open(`${kcpAPI.cart.makeCheckoutUrl(cartId, checkoutKey)}`, '_self');
        };

        return (
            <StyledWrapper>
                <StyledText type='body1'>
                    This email does not qualify for one or more of your applied Rewards.
                </StyledText>
                <StyledText type='body1'>Your order has been updated.</StyledText>
                <StyledButton size='large' type='primary' onClick={handleGoToCheckout}>
                    <span>Continue to shipping</span>
                </StyledButton>
                <Button
                    size='large'
                    type='unstyled'
                    onClick={() => {
                        onCloseModal();
                        return navigate(ROUTE_PATHS.shoppingCart);
                    }}
                >
                    <span>Return to cart</span>
                    <Icon component={() => <ArrowChevronForwardIcon />} />
                </Button>
            </StyledWrapper>
        );
    })
);

export default OrderUpdatedModalContent;
