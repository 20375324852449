import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../utils';

const getLeftMargin = (activeItemPosition, $isNoActiveChallenge) => {
    if ($isNoActiveChallenge) return '-72%';

    switch (activeItemPosition) {
        case 2:
            return '5%';
        case 3:
            return '-33%';
        case 4:
            return '-71.5%';
        case 5:
            return '-73%';
        default:
            return '50% - 28px';
    }
};

export const StyledStepsWrapper = styled.div`
    margin-bottom: 36px;

    @media (${GRID_DEVICES_MAX.md}) {
        width: calc(100% + 48px);
        margin-left: -24px;
        margin-bottom: 0;
        overflow: hidden;

        ${({ $itemsCount, $activeItemPosition, $isNoActiveChallenge }) => css`
            > .ant-row {
                width: ${(100 / 3) * $itemsCount}%;
                margin-left: calc(${getLeftMargin($activeItemPosition, $isNoActiveChallenge)});
            }
        `};
    }
`;
