import React from 'react';
import { DiamondIcon } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { $primaryText } from '../../../../shared';
import { StyledIcon, StyledLine, StyledWrap } from './styles';

export const Divider = observer(({ color = $primaryText, lineWeight = '1px', ...props }) => {
    return (
        <StyledWrap {...props}>
            <StyledLine $color={color} $lineWeight={lineWeight} />
            <StyledIcon size='24px' component={() => <DiamondIcon />} />
            <StyledLine $color={color} $lineWeight={lineWeight} />
        </StyledWrap>
    );
});
