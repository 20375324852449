import React, { Fragment } from 'react';
import nextId from 'react-id-generator';
import { Button, Icon, ScrollIcon, Typography, useSimpleInterface } from '@klickly/front-packages';
import placeholder from '../../../../assets/img/image-placeholder.svg';
import { Promotion } from './components/Promotion';
import { EmptyState, Item, Overlay } from './styles';

export const Promotions = ({ promotions, emptyMessage, action, actionTitle, onClose }) => {
    const simple = useSimpleInterface();
    return (
        <Fragment>
            {!simple
                ? Boolean(promotions?.length) && (
                      <Overlay dots={false} infinite={false} variableWidth bottomControls>
                          {promotions?.map((product) => (
                              <Item key={product?.order || nextId()}>
                                  <Promotion
                                      placeholder={placeholder}
                                      promotion={product?.promotion}
                                      action={
                                          <Button
                                              size='small'
                                              type='primary'
                                              style={{ width: '100%' }}
                                              onClick={() => action(product)}
                                          >
                                              {actionTitle}
                                          </Button>
                                      }
                                  >
                                      {product?.title}
                                  </Promotion>
                              </Item>
                          ))}
                      </Overlay>
                  )
                : promotions?.map((product) => (
                      <Item key={product?.order || nextId()}>
                          <Promotion
                              promotion={product?.promotion}
                              direction='row'
                              action={
                                  <Button
                                      size='small'
                                      type='primary'
                                      style={{ width: '100%' }}
                                      onClick={() => action(product)}
                                  >
                                      {actionTitle}
                                  </Button>
                              }
                          >
                              {product?.title}
                          </Promotion>
                      </Item>
                  ))}

            {!promotions?.length && (
                <EmptyState>
                    <br />
                    <br />
                    <Icon color='secondaryText' size='40px' component={() => <ScrollIcon />} />
                    <br />
                    <br />
                    <Typography.Title level={2}>No orders ...</Typography.Title>
                    <Typography.Text type='body1'>{emptyMessage}</Typography.Text>
                    <br />
                    <Button onClick={onClose} type='primary' block>
                        Close
                    </Button>
                    <br />
                </EmptyState>
            )}
        </Fragment>
    );
};
