import React from 'react';
import { GRID_POINTS, useSimpleInterface } from '@klickly/front-packages';
import { useModal } from '../../../../../../../shared';
import { ProductModalContainer } from '../components/ProductModalContainer';

export const useProductModal = () => {
    const simpleModal = useSimpleInterface(GRID_POINTS.sm);
    const { openModal, closeModal } = useModal();

    const open = (promotion, selected, onClick) =>
        openModal({
            children: (
                <ProductModalContainer
                    onClose={closeModal}
                    promotion={promotion}
                    selected={selected}
                    onClick={onClick}
                    simple={simpleModal}
                />
            ),
            withoutCloseIcon: true,
            modalWrapperStyles: !simpleModal
                ? {
                      width: '100%',
                      maxHeight: '90vh',
                      overflow: 'auto'
                  }
                : { height: '100%' },
            modalContentStyles: simpleModal
                ? {
                      padding: 0,
                      height: '100%',
                      overflow: 'auto'
                  }
                : { padding: '24px' },
            settings: !simpleModal
                ? {
                      width: '90vw',
                      style: {
                          maxWidth: '1400px'
                      }
                  }
                : {
                      fullscreen: true
                  }
        });

    return { openModal: open, closeModal };
};
