import React, { Fragment } from 'react';
import { BOTTOM_INDENTS, Col, GRID_POINTS, Row, Typography, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import OnboardingInfoSlider from '../OnboardingInfoSlider';
import SidePanel from '../SidePanel';
import { SignInButton } from '../SignInButton';
import { PersonalInfoForm } from './components/PersonalInfoForm';
import { HeaderButtonWrap, PersonalInfoContentStyles, PersonalInfoStyles } from './styles';

const PersonalInfo = observer(() => {
    const simple = useSimpleInterface(GRID_POINTS.md);
    return (
        <PersonalInfoStyles>
            {simple ? <OnboardingInfoSlider /> : null}
            <Row>
                <Col span={24} lg={{ span: 16 }}>
                    <PersonalInfoContentStyles>
                        {!simple && (
                            <HeaderButtonWrap>
                                <SignInButton />
                            </HeaderButtonWrap>
                        )}
                        <Typography.Title style={BOTTOM_INDENTS.SM}>Welcome</Typography.Title>
                        {simple && (
                            <HeaderButtonWrap>
                                <SignInButton />
                            </HeaderButtonWrap>
                        )}
                        {!simple && (
                            <Fragment>
                                <Typography.Text style={BOTTOM_INDENTS.XL} type='body1'>
                                    To start your application, please create a profile
                                </Typography.Text>
                            </Fragment>
                        )}
                        <PersonalInfoForm />
                    </PersonalInfoContentStyles>
                </Col>
                <SidePanel />
            </Row>
        </PersonalInfoStyles>
    );
});

export default PersonalInfo;
