import React from 'react';
import { BOTTOM_INDENTS, Col, Icon, Row, TOP_INDENTS, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../../utils';
import { $primary, $secondaryText, $tartRed } from '../../../../index';
import { getRandomNumber } from '../../../../utils';
import { StyledWrapper } from './styled';
import { getIconByAlias } from './utils';

const ChallengeTitle = inject('store')(
    observer(({ store, isMobileView }) => {
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { tasks } = store.tasksStore;
        const challenges = (tasks || []).filter((task) => !task.isHidden && task.data.status === 'active');
        const activeChallenge = challenges?.[getRandomNumber(0, challenges.length - 1)];

        if (!activeChallenge) return null;

        return activeChallenge ? (
            <StyledWrapper $isMobileView={isMobileView}>
                <Row gutter={64} justify={simple || isMobileView ? 'center' : 'start'} align='middle'>
                    <Col
                        style={
                            (simple || isMobileView) && {
                                ...BOTTOM_INDENTS.L,
                                ...TOP_INDENTS.S
                            }
                        }
                    >
                        <Icon
                            size={simple || isMobileView ? '32px' : '48px'}
                            roundedSize={simple || isMobileView ? '64px' : '120px'}
                            color={$primary}
                            roundedColor={$tartRed}
                            component={() => getIconByAlias(activeChallenge.alias)}
                        />
                    </Col>
                    <Col>
                        <Typography.Title
                            style={(simple || isMobileView) && BOTTOM_INDENTS.S}
                            align={simple || isMobileView ? 'center' : 'left'}
                            level={simple || isMobileView ? 2 : 1}
                            color={$primary}
                        >
                            {activeChallenge.data.title}
                        </Typography.Title>
                        <Typography.Title
                            align={simple || isMobileView ? 'center' : 'left'}
                            level={3}
                            color={$secondaryText}
                            style={{
                                ...TOP_INDENTS.XXS,
                                ...(simple || isMobileView ? BOTTOM_INDENTS.S : {})
                            }}
                        >
                            {activeChallenge.data.description}
                        </Typography.Title>
                    </Col>
                </Row>
            </StyledWrapper>
        ) : null;
    })
);

export default ChallengeTitle;
