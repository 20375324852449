import { Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../utils';
import { $primary } from '../../../../index';

export const StyledTitle = styled(Typography.Title)`
    > span {
        > div {
            display: inline-flex;
            p {
                margin-bottom: 0;
                font: normal 500 32px/40px 'IBM Plex Serif', Serif;
                color: ${$primary};

                @media (${GRID_DEVICES_MAX.md}) {
                    font-size: 24px;
                    line-height: 36px;
                }

                ${({ $isMobileView }) =>
                    $isMobileView &&
                    css`
                        font-size: 24px;
                        line-height: 36px;
                    `};
            }
        }
    }
`;

export const StyledText = styled.span`
    color: ${({ $color }) => $color || 'black'};
`;
