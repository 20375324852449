import { create as createIframe } from '../elements/iframe';

export const loadGA4 = ({ src }, options = {}) => {
    const scriptUrl = new URL(src);
    const { id, queryParams = {} } = options;
    const iframeId = id || 'kl_ga4_iframe';

    for (const [key, value] of Object.entries(queryParams)) {
        scriptUrl.searchParams.set(key, value);
    }

    const el = document.getElementById(iframeId);

    if (!el) {
        createIframe({
            id: iframeId,
            src: scriptUrl.toString()
        });
    }
};
