import React from 'react';
import { Button, Image, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import giftPointImg from '../../../../assets/img/landing/gift-points-white.svg';
import { $primary, $white } from '../../../../shared';

export const StyledWrap = styled.div`
    width: 100%;
    background-color: ${$primary};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
`;

export const StyledHead = styled.div`
    max-width: 1110px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    ${({ $simple }) =>
        $simple
            ? css`
                  flex-direction: column;
                  justify-content: flex-start;
              `
            : ''}
`;

export const StyledLogoWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${({ $simple }) =>
        $simple
            ? css`
                  margin-bottom: 12px;
              `
            : ''}
`;

export const StyledButton = styled(Button)`
    && {
        width: 413px;
        background-color: ${$white};
        color: ${$primary};
        border-color: ${$primary};
        margin: 0 auto;

        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
            color: #ffffff;
            border-color: #ffffff;
        }

        @media (max-width: 450px) {
            width: calc(100% - 48px);
        }
    }
`;

export const StyledTextWrap = styled.div``;

export const StyledUppercaseText = styled(Typography.Text)`
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
`;

export const GiftPointsImg = ({ marginBottom, simple }) => (
    <Image
        style={{
            width: simple ? '89px' : '112px',
            height: simple ? '30px' : '36px',
            marginBottom: marginBottom
        }}
        src={giftPointImg}
        alt='gift and points'
    />
);
