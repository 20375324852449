import React, { Fragment, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    Button,
    CircleActionsAlertInfoIcon,
    Form,
    GRID_POINTS,
    Icon,
    Input,
    LockIcon,
    MailIcon,
    ProfileIcon,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { VALIDATION_RULES } from '../../../../../../shared';
import { ErrorField } from '../../../../../../shared/components/ErrorField';
import { ButtonWrap, ErrorFixedWrap, StyledForm, StyledIcon, StyledLink } from './styles';

export const PersonalInfoForm = inject('store')(
    observer(({ store }) => {
        const [searchParams] = useSearchParams();
        const {
            signUp: onSubmit,
            pending,
            errorMessage,
            onboardingEmail,
            setOnboardingEmail,
            clearError
        } = store.onboardingStore;
        const simple = useSimpleInterface(GRID_POINTS.lg);

        const [form] = Form.useForm();
        const initialValues = { email: onboardingEmail };

        const invitedEmail = searchParams.get('email');

        useEffect(() => {
            if (invitedEmail) {
                const decodedEmail = decodeURIComponent(invitedEmail);
                form.setFields([{ name: 'email', value: decodedEmail, touched: true, errors: [] }]);
                setOnboardingEmail(decodedEmail);
            }
        }, [invitedEmail]);

        useEffect(() => {
            return () => clearError();
        }, []);

        const submitted = (data) => {
            if (typeof onSubmit !== 'undefined') {
                onSubmit(data);
            }
        };

        /* eslint-disable */

    return (
        <StyledForm form={form} onFinish={submitted} initialValues={initialValues} validateTrigger={['onBlur']}>
            <Form.Item name='firstName' rules={VALIDATION_RULES.NAME}>
                <Input
                    size='large'
                    showWarningIcon
                    label='First name'
                    autoComplete='off'
                    prefix={<StyledIcon component={() => <ProfileIcon />}/>}
                />
            </Form.Item>
            <Form.Item name='email' rules={VALIDATION_RULES.EMAIL}>
                <Input
                    size='large'
                    showWarningIcon
                    type='email'
                    label='E-mail'
                    autoComplete='on'
                    prefix={<StyledIcon component={() => <MailIcon />}/>}
                />
            </Form.Item>
            <Form.Item name='password' rules={VALIDATION_RULES.REQUIRED}>
                <Input
                    size='large'
                    showWarningIcon
                    type='password'
                    label='Password'
                    prefix={<StyledIcon component={() => <LockIcon />}/>}
                />
            </Form.Item>
            <Typography.Text
                style={BOTTOM_INDENTS.XL}
                as='div'
                type='descriptor2'
                color='secondaryText'
            >
                By clicking Continue you're agreeing to the <StyledLink href='/terms'
                                                                        target='_blank'>Terms</StyledLink> and <StyledLink
                href='/privacy' target='_blank'>Privacy Policy</StyledLink>
            </Typography.Text>
            {
                !simple &&
                <ErrorFixedWrap>
                    {
                        errorMessage &&
                        <Fragment>
                            <ErrorField hasBottomIndent icon={<CircleActionsAlertInfoIcon />} error={errorMessage} showIcon />
                        </Fragment>
                    }
                </ErrorFixedWrap>
            }
            <ButtonWrap>
                <ErrorFixedWrap simple={simple}>
                    {
                        simple && errorMessage &&
                        <Fragment>
                            <ErrorField hasBottomIndent textType='descriptor1' icon={<CircleActionsAlertInfoIcon />}
                                        error={errorMessage} showIcon />
                        </Fragment>
                    }
                </ErrorFixedWrap>
                <Form.Item noStyle shouldUpdate>
                    {() => (
                        <Button data-e2e='onboarding_continue-button' htmlType='submit' type='primary' size='large' block>
                            Continue
                        </Button>
                    )}
                </Form.Item>
            </ButtonWrap>

            {
                pending && <Icon.Loader />
            }
        </StyledForm>
    );
}));
