import styled from 'styled-components';
import { $tartRed } from '../../index';

export const SidebarStyles = styled.aside`
    width: 100%;
    height: 100%;
    background-color: ${$tartRed};

    .ant-col {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-row {
        width: 100%;
        height: 100%;
        flex-direction: column;
    }
`;

export const SidebarContentStyles = styled.div`
    max-width: 380px;
    width: 70%;
    margin: 0 auto;
`;
