import React, { Fragment } from 'react';
import { Typography } from '@klickly/front-packages';
import DropdownMenuWrapper from './components/DropdownMenuWrapper';
import LinkWithCount from './components/LinkWithCount';
import ShopLink from './components/ShopLink';
import SignOutBtn from './components/SignOutBtn';
import { StyledIcon, StyledMenuButton, StyledMenuLink, StyledMenuLinkTitle, StyledNoStyleButton } from './styled';

export const MenuLink = ({ hidden, label, icon: Icon, to, onClick, noRedirect = false }) => {
    if (noRedirect)
        return (
            <StyledNoStyleButton onClick={onClick} type='unstyled'>
                <div>{label}</div>
            </StyledNoStyleButton>
        );

    return (
        <Fragment>
            {typeof onClick === 'function' ? (
                <StyledMenuButton type='unstyled' $hidden={hidden} to={to} onClick={onClick}>
                    {Icon ? <StyledIcon color='secondaryText' size='20px' component={() => <Icon />} /> : null}
                    <Typography.Text type='body2' color='primaryText'>
                        {label}
                    </Typography.Text>
                </StyledMenuButton>
            ) : (
                <StyledMenuLink $hidden={hidden} to={to} onClick={onClick}>
                    {Icon ? <StyledIcon color='secondaryText' size='20px' component={() => <Icon />} /> : null}
                    <Typography.Text type='body2' color='primaryText'>
                        {label}
                    </Typography.Text>
                </StyledMenuLink>
            )}
        </Fragment>
    );
};

MenuLink.WithCount = LinkWithCount;
MenuLink.Shop = ShopLink;
MenuLink.SignOut = SignOutBtn;
MenuLink.Dropdown = DropdownMenuWrapper;
MenuLink.Title = StyledMenuLinkTitle;
