import React, { Fragment } from 'react';
import {
    CartIcon,
    Col,
    GiftIcon,
    GRID_POINTS,
    PointsIcon,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { Sidebar } from '../../../../shared/components/AuthSidebar';
import { StyledIcon, StyledItemWrap } from './styles';

const SidePanel = observer(
    ({
        headText = 'Discover trending brands. Access exclusive rewards.',
        bodyText = 'Access free gifts and private discounts on premium products from trending brands in your own digital, curated, weekly Gifting Suite'
    }) => {
        const simple = useSimpleInterface(GRID_POINTS.lg);
        return !simple ? (
            <Col span={8}>
                <Sidebar
                    headText={headText}
                    bodyText={bodyText}
                    customBodyContent={
                        <Fragment>
                            <StyledItemWrap>
                                <StyledIcon size='40px' color='primary' component={() => <GiftIcon />} />
                                <div>
                                    <Typography.Text type='badgeMedium' color='primary'>
                                        Personalized gifts
                                    </Typography.Text>
                                    <Typography.Text type='body2' color='primary'>
                                        Access free Gifting Suites with products you’ll love
                                    </Typography.Text>
                                </div>
                            </StyledItemWrap>
                            <StyledItemWrap>
                                <StyledIcon size='40px' color='primary' component={() => <PointsIcon />} />
                                <div>
                                    <Typography.Text type='badgeMedium' color='primary'>
                                        Private offers
                                    </Typography.Text>
                                    <Typography.Text type='body2' color='primary'>
                                        Earn exclusive rewards by completing purchases & challenges
                                    </Typography.Text>
                                </div>
                            </StyledItemWrap>
                            <StyledItemWrap>
                                <StyledIcon size='40px' color='primary' component={() => <CartIcon />} />
                                <div>
                                    <Typography.Text type='badgeMedium' color='primary'>
                                        Trending brands
                                    </Typography.Text>
                                    <Typography.Text type='body2' color='primary'>
                                        Shop from +120,000 exclusive offers
                                    </Typography.Text>
                                </div>
                            </StyledItemWrap>
                        </Fragment>
                    }
                />
            </Col>
        ) : null;
    }
);

export default SidePanel;
