import { Button, Icon } from '@klickly/front-packages';
import styled from 'styled-components';

export const Styles = styled.div`
    padding: 8px;
    overflow-x: hidden;
`;

export const FieldControls = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px -10px 30px;
    position: relative;

    & > * {
        margin: 0 5px;
    }
`;

export const StyledButton = styled(Button)`
    && {
        margin: 20px 0 20px -5px;
        width: 100px;
    }
`;

export const PlusIcon = styled(Icon)`
    position: absolute;
    margin: auto;
    left: 79px;
    top: 28px;
    bottom: 16px;
`;

export const FieldControl = styled.div`
    width: 100%;
    position: relative;
    & > * {
        width: 100%;
    }
`;
