import React, { Fragment, useState } from 'react';
import {
    BackArrowIcon,
    BOTTOM_INDENTS,
    Icon,
    Taxonomy,
    TOP_INDENTS,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import { Filter } from './components/Filter';
import { FilterItems } from './components/FilterItems';
import { PriceRange } from './components/PriceRange';
import { Styles } from './styles';

export const Filters = inject('store')(
    observer(({ store }) => {
        const {
            isOpenOnMobile,
            updateIsOpenOnMobile,
            updateBrand,
            query,
            updateQuery,
            updatePromotions
        } = store.marketplaceStore;
        const [selectedSubCategory, setSelectedSubCategory] = useState(null);
        const simple = useSimpleInterface();
        const { price, brands } = query;

        return (
            <Styles simple={simple} mobileHeight={window?.innerHeight} isOpenOnMobile={isOpenOnMobile}>
                {simple && (
                    <Fragment>
                        <Icon
                            style={{ ...TOP_INDENTS.M, ...BOTTOM_INDENTS.M }}
                            size='24px'
                            onClick={() => updateIsOpenOnMobile(false)}
                            component={() => <BackArrowIcon />}
                        />
                    </Fragment>
                )}
                <Taxonomy
                    taxonomyType='marketplace'
                    selectedSubCategory={selectedSubCategory}
                    setSelectedSubCategory={setSelectedSubCategory}
                    onAfterCategorySelect={(gt) => {
                        updateQuery({ gt });
                        updatePromotions({ isNewSearch: true });
                        if (simple) updateIsOpenOnMobile(false);
                    }}
                    selectCategoryOnArrowClick={!simple}
                />

                {price ? (
                    <Filter title='Price $'>
                        <PriceRange />
                    </Filter>
                ) : null}

                {brands && brands.length ? (
                    <Filter title='Brands'>
                        <FilterItems items={getSnapshot(brands)} onChange={updateBrand} />
                    </Filter>
                ) : null}
            </Styles>
        );
    })
);
