import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { notification, useSimpleInterface } from '@klickly/front-packages';
import NotificationContent from '../../../shared/components/NotificationContent';
import { GRID_POINTS } from '../../../utils';

export const useBuyProductNotification = (store) => {
    const { isApprovedCustomer } = store.accountStore;
    const { state } = useLocation();
    const challenge = store.tasksStore?.shopSuiteTask;
    const isChallenge = state === challenge?.alias && isApprovedCustomer;
    const message = 'Step 3 of 3. Proceed to Checkout and finish your order';
    const simple = useSimpleInterface(GRID_POINTS.md);

    useEffect(() => {
        if (typeof notification.destroy === 'function') notification.destroy();
        if (isChallenge) {
            store.trackingStore.trackChallengeNotification(challenge, '3', message);
            notification.open({
                key: challenge?.alias,
                description: <NotificationContent.Reward rewardCount={50} message={message} />,
                duration: null,
                bottom: simple ? '102px' : '8px'
            });
        }
        return () => (typeof notification.destroy === 'function' ? notification.destroy() : null);
    }, [isChallenge]); //eslint-disable-line
};
