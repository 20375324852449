import { types } from 'mobx-state-tree';
import { BrandModel } from './brandModel';
import { CategoryModel } from './categoryModel';
import { SortModel } from './sortModel';

export const QueryModel = types.model({
    page: types.maybe(types.union(types.string, types.number)),
    q: types.maybe(types.string),
    strategy: types.maybe(types.string),
    strategyId: types.maybe(types.string),
    categories: types.array(CategoryModel),
    gt: types.maybe(types.string),
    brands: types.array(BrandModel),
    allBrands: types.array(BrandModel),
    selectedBrands: types.array(types.string),
    price: types.maybe(
        types.model({
            gte: types.number,
            lte: types.number
        })
    ),
    suggest: types.maybe(types.union(types.string, types.number)),
    sort: types.array(SortModel)
});
