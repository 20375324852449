import { Button } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
    && {
        width: 100%;
        justify-content: flex-start;
    }
`;

export const StyledHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
