import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const ConnectInstagramStyles = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: auto;

    .ant-row {
        height: 100%;
        overflow-y: inherit;
    }

    .ant-col {
        display: flex;
        position: relative;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        height: fit-content;
        overflow-y: hidden;

        .ant-row {
            height: fit-content;
            overflow-y: hidden;
        }
    }
`;

export const ConnectInstagramContentStyles = styled.div`
    max-width: 413px;
    width: 100%;
    height: 100%;
    overflow-y: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 60px 0;

    @media (${GRID_DEVICES_MAX.xs}) {
        max-width: unset;
        justify-content: flex-start;
        padding: 40px 24px 24px;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        height: fit-content;
        overflow-y: hidden;
    }
`;
