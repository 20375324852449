import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $primaryText } from '../../../../../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../../../../../utils';

export const Styles = styled.div`
    width: 220px;
    height: 262px;
    padding: 10px;

    @media (${GRID_DEVICES_MAX.xs}) {
        width: 180px;
        height: 262px;
    }

    & > div {
        height: 100%;
        padding: 20px;
        border: 2px dashed ${$primaryText};

        @media (${GRID_DEVICES_MAX.xs}) {
            padding: 0;
        }
    }

    img {
        height: 155px;
        width: auto;

        @media (${GRID_DEVICES_MAX.xs}) {
            width: 154px;
            height: 154px;
            object-fit: cover;
            padding: 20px 0;
        }
    }

    h3 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @media (${GRID_DEVICES_MAX.xs}) {
            padding: 0 10px;
        }
    }
`;

export const StyledTitle = styled(Typography.Title)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (${GRID_DEVICES_MAX.xs}) {
        padding: 0 10px;
    }
`;
