import { types } from 'mobx-state-tree';

export const SeasonalChallengeModel = types.model('SeasonalChallengeModel', {
    _id: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    alias: types.maybeNull(types.string),
    endsAt: types.maybeNull(types.string),
    completed: types.maybeNull(types.boolean),
    description: types.maybeNull(types.string),
    reward: types.maybeNull(
        types.model({
            uuid: types.maybeNull(types.string),
            title: types.maybeNull(types.string),
            description: types.maybeNull(types.string),
            type: types.maybeNull(types.string),
            value: types.maybeNull(types.number)
        })
    )
});
