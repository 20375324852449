import React, { useEffect } from 'react';
import { notification, useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../utils';
import NotificationContent from '../components/NotificationContent';

export const useSeasonalChallengeNotifications = (store) => {
    const simple = useSimpleInterface(GRID_POINTS.md);
    const navigate = store.routeNavigate;
    const { activeChallenge } = store.seasonalChallengeStore;

    useEffect(() => {
        return () => (typeof notification.destroy === 'function' ? notification.destroy() : null);
    }, []);

    return (step = 'completed', notificationMessage, settings) => {
        const message = step === 'completed' ? `Challenge ${activeChallenge?.name} complete!` : notificationMessage;
        const defaultSettings = settings || {
            duration: null,
            bottom: simple ? '102px' : '8px'
        };
        store.trackingStore.trackSeasonalChallengeNotification(activeChallenge, step, message);
        if (step === 'completed') {
            notification.open({
                key: activeChallenge?.alias,
                duration: null,
                description: <NotificationContent.Completed completedActionName={activeChallenge?.name} />,
                bottom: simple ? '0' : '8px'
            });
            activeChallenge?.rewards?.forEach(({ value, type }) => {
                notification.open({
                    key: `${activeChallenge?.alias}-${type}`,
                    duration: null,
                    description: (
                        <NotificationContent.RewardEarned
                            value={value}
                            dimension={type === 'claim' ? 'Gifting Suite' : 'points'}
                            navigate={navigate}
                        />
                    ),
                    bottom: simple ? '0' : '8px'
                });
            });
        } else {
            activeChallenge?.rewards?.forEach(({ value, type }) => {
                notification.open({
                    key: activeChallenge?.alias,
                    description: (
                        <NotificationContent.Reward
                            rewardCount={value}
                            message={message}
                            rewardType={type === 'point' ? 'points' : 'claims'}
                        />
                    ),
                    ...defaultSettings
                });
            });
        }
    };
};
