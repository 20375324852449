import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../utils';

export const StyledIframe = styled.div`
    width: 100%;

    iframe {
        height: calc(100dvh - 160px);
        width: 100%;

        @media (${GRID_DEVICES_MAX.md}) {
            height: calc(100dvh - 80px);
        }
    }
`;
