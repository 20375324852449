import React, { Fragment } from 'react';
import { BOTTOM_INDENTS, Typography, useSimpleInterface } from '@klickly/front-packages';
import { $primary } from '../../../../../../../../../../shared';
import { ApplyNow } from '../../../../../../../../../../shared/components/ApplyNow';
import { SignInForm } from '../../../../../../../../../../shared/components/SignInForm';
import { GRID_POINTS } from '../../../../../../../../../../utils';
import { StyledContent, StyledHeader } from './styled';

const SignInModalContent = ({ onCloseModal }) => {
    const simple = useSimpleInterface(GRID_POINTS.md);
    return (
        <div>
            <StyledHeader>
                <Typography.Title color={$primary} level={simple ? 2 : 1} align={simple ? 'left' : 'center'}>
                    Sign in to access your reward
                </Typography.Title>
            </StyledHeader>
            <StyledContent>
                <SignInForm
                    alignContent='center'
                    onForgotPasswordClick={onCloseModal}
                    onApplyNowClick={onCloseModal}
                    redirectToAfterSignIn='self'
                    onAfterSignIn={onCloseModal}
                />
                {!simple ? (
                    <Fragment>
                        <div style={BOTTOM_INDENTS.M} />
                        <ApplyNow alignContent='center' onClick={onCloseModal} />
                    </Fragment>
                ) : null}
            </StyledContent>
        </div>
    );
};

export default SignInModalContent;
