import React from 'react';
import { Button, Image, Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import brandLogoPlaceholder from '../../../../../../../../../../../../../../assets/img/brandLogo-placeholder.svg';
import { $border } from '../../../../../../../../../../../../../../shared';

export const Styles = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

export const OrderImg = styled.div`
    && {
        border: 1px solid ${$border};
        height: 80px;
        min-width: 80px;
        width: 80px;
        text-align: center;
        margin-right: 20px;

        img {
            max-height: 80px;
            max-width: 100%;
            height: auto;
            width: auto;
        }
    }
`;

export const OrderContent = styled.div`
    margin-right: 10px;
`;

export const OrderContentLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const OrderContentRight = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const CancelButton = styled(Button)``;

export const ArrowButton = styled(Button)`
    && {
        padding: 14px 5px;
        margin: 0 0 0 10px;
    }
`;

export const StyledText = styled(Typography.Text)`
    margin-bottom: 4px;
`;

export const OrderStatus = styled(Typography.Text)`
    margin-bottom: 4px;
    text-transform: capitalize;
`;

export const StyledHeadLine3 = styled(Typography.Title)`
    margin-bottom: 4px;
`;

export const BrandLogoPlaceholder = () => <Image src={brandLogoPlaceholder} alt='placeholder' />;
