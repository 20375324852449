import React from 'react';
import nextId from 'react-id-generator';
import { Typography } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { getPrices } from '../../../../utils';
import { ProductCard } from './components/productCard';
import { CardBody, CardHead, CardPricesRow, StyledText, Styles, SubtotalPrice } from './styles';

export const BrandCard = observer(({ cartData, bag, orderNumber, orderLength, simple }) => {
    const { fullTotal } = getPrices({ ...bag.priceDetails });

    return bag.items && bag.items.length ? (
        <Styles $simple={simple}>
            <CardHead>
                <StyledText type='badgeMedium'>
                    Order №{orderNumber}
                    {bag.merchantName ? ` - ${bag.merchantName}` : ''}
                </StyledText>
            </CardHead>
            <CardBody $simple={simple}>
                {bag.items.map((item) => (
                    <ProductCard key={nextId()} item={item} cartData={cartData} simple={simple} />
                ))}
            </CardBody>
            <SubtotalPrice>
                <CardPricesRow $withBottomIndent>
                    <Typography.Text type='badgeLarge'>
                        Estimated {orderLength > 1 ? 'subtotal' : 'total'}
                    </Typography.Text>
                    <Typography.Text type='badgeLarge'>{fullTotal}</Typography.Text>
                </CardPricesRow>
                <Typography.Text type='descriptor2' color='secondaryText'>
                    Shipping and taxes calculated at checkout
                </Typography.Text>
            </SubtotalPrice>
        </Styles>
    ) : null;
});
