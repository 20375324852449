import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { notification, useSimpleInterface } from '@klickly/front-packages';
import { ROUTE_PATHS } from '../../../../../shared';
import { GRID_POINTS } from '../../../../../utils';
import { useBuyProductNotification } from './useBuyProductNotification';
import { useShareProductNotification } from './useShareProductNotification';

export const useMarketplaceNotifications = (store) => {
    const location = useLocation();
    const simple = useSimpleInterface(GRID_POINTS.md);
    const { setActiveChallengeName } = store.marketplaceStore;
    const defaultSettings = { duration: null, bottom: simple ? '0' : '8px' };

    useEffect(() => {
        if (location.state) {
            setActiveChallengeName(location.state);

            if (!location.pathname.includes(ROUTE_PATHS.shop)) {
                setActiveChallengeName('');
                if (typeof notification.destroy === 'function') notification.destroy();
            }
        }
    }, [location.state]);

    useShareProductNotification(store, defaultSettings);
    useBuyProductNotification(store, defaultSettings);
};
