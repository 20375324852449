import { Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $border, $primary } from '../../../../../../../../../../../../shared';

export const StyledWrap = styled.div``;

export const StyledText = styled(Typography.Text)`
    font-weight: 400;

    & > span {
        font-weight: 600;
    }
`;

export const StyledMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
`;

export const StyledMenuItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 16px 0;
    border-bottom: 1px solid ${$border};
    cursor: pointer;

    ${({ $active }) =>
        $active
            ? css`
                  border-bottom: 4px solid ${$primary};
              `
            : ''}
`;

export const StyledContent = styled.div``;
