import { Icon, Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../utils';
import { $primary, $tartRed } from '../../constants';

export const StyledModalWrapper = styled.div`
    background-color: #fff;
    min-height: auto;
    position: relative;

    @media (${GRID_DEVICES_MAX.sm}) {
        width: 100%;
        min-height: 100%;
    }
`;

export const StyledTitle = styled(Typography.Title)`
    && {
        padding: 24px;
        line-height: 48px;
        text-align: center;
        color: ${$primary};
        background: ${$tartRed};

        @media (${GRID_DEVICES_MAX.sm}) {
            height: 128px;
            line-height: 40px;
            text-align: left;
            display: flex;
            align-items: flex-end;
        }
    }
`;

export const StyledModalContent = styled.div`
    height: auto;
    padding: ${({ $simple }) => ($simple ? '24px' : '40px 40px 24px')};
`;

export const CloseModalIcon = styled(Icon)`
    position: absolute;
    bottom: 100%;
    margin-bottom: 30px;
    font-size: 24px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 2;

    @media (${GRID_DEVICES_MAX.sm}) {
        top: 24px;
        bottom: auto;
        left: 36px;
        color: ${$primary};
    }
`;
