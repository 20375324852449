import React from 'react';
import { GRID_POINTS, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { StyledImage } from './styled';

export const Banner = inject('store')(
    observer(({ store, hidden }) => {
        const { bannerImage: imageSrc } = store.brandPageStore;
        const simple = useSimpleInterface(GRID_POINTS.md);

        return !hidden && imageSrc ? (
            <StyledImage
                width='100%'
                height={simple ? '184px' : '297px'}
                $simple={simple}
                src={imageSrc}
                alt='Brand banner'
            />
        ) : null;
    })
);
