import React from 'react';
import { observer } from 'mobx-react';
import { StyledButton, StyledLikeActiveIcon, StyledLikeIcon } from './styles';

export const LikeButton = observer(({ selected, onClick }) => {
    return (
        <StyledButton $selected={selected} type='unstyled' onClick={onClick}>
            {selected ? <StyledLikeActiveIcon /> : <StyledLikeIcon />}
        </StyledButton>
    );
});
