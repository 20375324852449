import { Icon } from '@klickly/front-packages';
import styled from 'styled-components';

export const Styles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 320px;
    width: 100%;
    text-align: center;
`;

export const NoOrderIcon = styled(Icon)`
    margin: 60px 0;
`;
