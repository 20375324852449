import React from 'react';
import { Outlet } from 'react-router-dom';
import { Animated } from '../../../../shared/components/Animated';
import { ROUTES } from './constants';

const OrdersPage = () => {
    return (
        <Animated style={{ maxWidth: '845px', margin: 'auto' }}>
            <Outlet />
        </Animated>
    );
};

OrdersPage.ROUTES = ROUTES;
export default OrdersPage;
