import React, { Fragment, useState } from 'react';
import { BOTTOM_INDENTS, Icon, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ErrorField } from '../../../../../../../../../../../../shared/components/ErrorField';
import { InviteFriendTaskForm } from './components/InviteFriendTaskForm';
import InviteFriendTaskLink from './components/InviteFriendTaskLink';
import { StyledContent, StyledMenu, StyledMenuItem, StyledText, StyledWrap } from './styles';
import { getInvitationsPrefix } from './utils';

export const InviteFriendTaskSendInvite = inject('store')(
    observer(({ store, setSubmitted }) => {
        const [state, setState] = useState('email');

        const {
            inviteFriendTaskData: { attemptsRemained },
            processing: sending
        } = store.tasksStore;

        return attemptsRemained ? (
            <StyledWrap>
                <StyledText type='badgeLarge' style={{ ...BOTTOM_INDENTS.M }}>
                    You have{' '}
                    <span>
                        {attemptsRemained} invitation{getInvitationsPrefix(attemptsRemained)}
                    </span>{' '}
                    remaining
                </StyledText>
                <StyledMenu style={{ ...BOTTOM_INDENTS.M }}>
                    <StyledMenuItem onClick={() => setState('email')} $active={state === 'email'}>
                        <Typography.Title level={2}>Via e-mail</Typography.Title>
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => setState('link')} $active={state === 'link'}>
                        <Typography.Title level={2}>Via link</Typography.Title>
                    </StyledMenuItem>
                </StyledMenu>
                <StyledContent>
                    {state === 'email' ? <InviteFriendTaskForm setSubmitted={setSubmitted} /> : null}
                </StyledContent>

                <StyledContent>{state === 'link' ? <InviteFriendTaskLink /> : null}</StyledContent>
                {sending && <Icon.Loader />}
            </StyledWrap>
        ) : (
            <Fragment>
                <ErrorField error='You have 0 invites remaining' showIcon textType='descriptor1' />
                <br />
            </Fragment>
        );
    })
);
