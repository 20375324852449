import { FilledCartIcon, FilledProfileIcon, FilledTwoPeopleIcon, GiftIcon, PointsIcon } from '@klickly/front-packages';
import { SEASONAL_CHALLENGE_ALIASES } from '../../../shared';
export const getIconByAlias = (alias) => {
    switch (alias) {
        case SEASONAL_CHALLENGE_ALIASES.COMPLETE_ONBOARDING:
            return FilledProfileIcon;
        case SEASONAL_CHALLENGE_ALIASES.LAUNCH_GS:
            return GiftIcon;
        case SEASONAL_CHALLENGE_ALIASES.INVITE_FRIENDS:
            return FilledTwoPeopleIcon;
        case SEASONAL_CHALLENGE_ALIASES.MAKE_PURCHASE:
            return FilledCartIcon;
        case SEASONAL_CHALLENGE_ALIASES.EARN_POINTS:
            return PointsIcon;
        default:
            return GiftIcon;
    }
};
