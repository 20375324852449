import { Typography } from '@klickly/front-packages';
import styled, { keyframes } from 'styled-components';

const $animated = keyframes`
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

export const Dots = styled.span`
    display: inline-block;
    vertical-align: middle;
    animation: 1s infinite ${$animated};
    line-height: 1;

    &::before {
        content: ':';
    }
`;

export const Value = styled(Typography.Text)`
    text-align: ${({ align = 'left' }) => align};
`;
