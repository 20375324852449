import React from 'react';
import {
    CartIcon,
    CopyIcon,
    EmojiHappySmileIcon,
    HeartIcon,
    ProfileIcon,
    PuzzleIcon,
    StarIcon,
    TagIcon
} from '@klickly/front-packages';

export const iconsMap = {
    'write-product-review': <StarIcon />,
    'shop-suite': <CartIcon />,
    'rate-giftly': <EmojiHappySmileIcon />,
    'invite-friend': <ProfileIcon />,
    'share-product': <TagIcon />,
    'onboarding-add-shipping': <CartIcon />,
    'install-extension': <PuzzleIcon />,
    'copy-link': <CopyIcon />,
    'onboarding-select-categories': <StarIcon />,
    'onboarding-add-product-to-wishlist': <HeartIcon />
};
