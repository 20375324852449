import React, { Fragment, useState } from 'react';
import {
    BOTTOM_INDENTS,
    Button,
    Checkbox,
    Col,
    DeleteIcon,
    Form,
    Icon,
    Input,
    Row,
    TOP_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { ReactComponent as PaymentImage } from '../../../../assets/img/stripe-badge-white.svg';
import { useConfirmDeletingModal } from '../../../../shared/components/ConfirmDeletingModal/hooks';
import { ErrorField } from '../../../../shared/components/ErrorField';
import { ShippingAddressFormFields } from '../../../../shared/components/ShippingAddressFormFields';
import { compareAddresses, GRID_POINTS } from '../../../../utils';
import { CheckboxWrap, StyledBillingForm, StyledContainer, StyledStripeCardIcon } from './styles';
import { paymentAddressFields } from './utils';

export const BillingForm = ({
    payment,
    defaultAddress = {},
    onSubmit,
    error,
    submitButtonValue = payment ? 'Save changes' : 'Add billing info',
    showDeletePaymentButton,
    setDefault,
    onDelete
}) => {
    const [isTheSameAddress, setIsTheSameAddress] = useState(
        compareAddresses(paymentAddressFields, payment, defaultAddress)
    );
    const simple = useSimpleInterface(GRID_POINTS.sm);
    const [form] = Form.useForm();

    const openConfirmDeletingModal = useConfirmDeletingModal({
        title: 'Delete billing',
        bodyText: 'Are you sure you want to delete this billing information?',
        onConfirm: onDelete
    });

    return (
        <StyledBillingForm>
            <Form
                form={form}
                initialValues={payment ? { ...payment } : { ...defaultAddress }}
                onFinish={(data) => {
                    payment ? onSubmit(payment._id, data) : onSubmit(data);
                }}
                validateTrigger={['onBlur']}
            >
                <Row gutter={20}>
                    <Col span={24}>
                        {simple && !payment?.isDefault ? (
                            <Button
                                block
                                type='primary'
                                size='large'
                                onClick={setDefault}
                                style={{ ...BOTTOM_INDENTS.M }}
                            >
                                Set as default billing method
                            </Button>
                        ) : null}
                        <Form.Item noStyle shouldUpdate>
                            <div style={BOTTOM_INDENTS.SM}>
                                <Input
                                    disabled
                                    nonOpacityOnDisabled
                                    size='large'
                                    label={'Ending in ' + payment.last4}
                                    value={payment.address1}
                                    prefix={<StyledStripeCardIcon brand={payment.brand} />}
                                />
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={BOTTOM_INDENTS.SM} gutter={20}>
                    <Col span={24} md={{ span: 12 }}>
                        <PaymentImage />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <CheckboxWrap style={{ ...BOTTOM_INDENTS.SM, ...TOP_INDENTS.SM }}>
                            <Checkbox
                                data-e2e='settings_checkbox'
                                type='rounded'
                                checkedColor='primary'
                                onChange={(e) => setIsTheSameAddress(e.target.checked)}
                                checked={isTheSameAddress}
                                size='20px'
                            >
                                <Typography.Text as='span'>
                                    My billing address is the same as my shipping address
                                </Typography.Text>
                            </Checkbox>
                        </CheckboxWrap>
                    </Col>
                </Row>
                <Form.Item noStyle shouldUpdate hidden={isTheSameAddress}>
                    {() => (
                        <StyledContainer style={BOTTOM_INDENTS.XL}>
                            <ShippingAddressFormFields mode='billing' form={form} hasPhone={!isTheSameAddress} />
                        </StyledContainer>
                    )}
                </Form.Item>
                {error ? (
                    <Fragment>
                        <ErrorField hasBottomIndent error={error} />
                    </Fragment>
                ) : null}
                <Row style={BOTTOM_INDENTS.XL}>
                    <Col span={24} lg={{ span: 12 }}>
                        <Button
                            block
                            data-e2e='settings_add-billingInfo-button'
                            htmlType='submit'
                            type='primary'
                            size='large'
                        >
                            {submitButtonValue}
                        </Button>
                    </Col>
                    {typeof onDelete === 'function' && showDeletePaymentButton && (
                        <Col span={24} lg={{ span: 12 }}>
                            <Button
                                data-e2e='settings_delete-billingInfo-button'
                                onClick={openConfirmDeletingModal}
                                type='unstyled'
                                size='large'
                                style={{ ...TOP_INDENTS.M }}
                            >
                                <Icon size='24px' marginRight='8px' color='primary' component={() => <DeleteIcon />} />
                                <Typography.Text type='badgeLarge' color='primary'>
                                    Delete billing information
                                </Typography.Text>
                            </Button>
                        </Col>
                    )}
                </Row>
            </Form>
        </StyledBillingForm>
    );
};
