import React, { useContext } from 'react';
import { KliThemeContext } from '../KliThemeProvider';
import { KliButtonStyled } from './KliButton.styles';

export const KliButton = ({ type = 'button', children, ...rest }) => {
    const themeContext = useContext(KliThemeContext);

    return (
        <KliButtonStyled theme={themeContext} type={type} {...rest}>
            {children}
        </KliButtonStyled>
    );
};
