import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Taxonomy, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { SEASONAL_CHALLENGE_ALIASES, useSeasonalChallengeNotifications } from '../../shared';
import { Layout } from '../../shared/components/Layout';
import { GRID_POINTS } from '../../utils';
import { Filters } from './components/Filters';
import { useBuyProductNotification } from './components/promotions/hooks/useBuyProductNotification';
import { useShareProductNotification } from './components/promotions/hooks/useShareProductNotification';
import { ROUTES } from './contants/routes';

const ShopPage = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { search } = useLocation();
        const { id: promotionId } = useParams();
        const { getBrands, isOpenOnMobile, query } = store.marketplaceStore;
        const { activeChallenge } = store.seasonalChallengeStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const showSeasonalChallengeNotification = useSeasonalChallengeNotifications(store);
        const message = 'Step 1 of 3. Choose a product';

        useBuyProductNotification(store);
        useShareProductNotification(store);

        const onBuy = (promotion) =>
            navigate({
                pathname: `/marketplace/${promotion._id}`,
                search
            });

        useEffect(() => {
            getBrands();

            if (
                activeChallenge?.alias === SEASONAL_CHALLENGE_ALIASES.MAKE_PURCHASE &&
                activeChallenge.challengeAccepted
            ) {
                showSeasonalChallengeNotification('1', message, {
                    duration: null,
                    bottom: simple ? '0' : '8px'
                });
            }
        }, []);

        return (
            <Layout
                sidebar={(!simple || isOpenOnMobile) && !promotionId && <Filters />}
                contentFullHeight={promotionId}
                withoutPadding={promotionId}
                afterHeaderContent={
                    query?.gt && !simple && !promotionId && <Taxonomy.CategoryPageHeader onBuy={onBuy} />
                }
            >
                <Outlet />
            </Layout>
        );
    })
);

ShopPage.ROUTES = ROUTES;

export default ShopPage;
