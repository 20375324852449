import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../shared';
import { Layout } from '../../../../shared/components/Layout';
import { PersonalizationCategoriesForm } from '../../../../shared/components/PersonalizationCategoriesForm';
import { StyledWrap } from './styles';

const PersonalizationCategories = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const onAfterSuccess = () => {
            store.trackingStore.sendEvent({
                type: 'event',
                name: 'Onboarding_Step1_Complete'
            });
            navigate(ROUTE_PATHS.personalizationImportance);
        };

        useEffect(() => {
            if (!store.tasksStore.tasksExists && !store.tasksStore.pending) store.tasksStore.getTasks();
        }, []);

        return (
            <Layout disabledMenu disableOnLogoClick menuBarHidden>
                <StyledWrap>
                    <PersonalizationCategoriesForm from='onboarding' onAfterSuccess={onAfterSuccess} />
                </StyledWrap>
            </Layout>
        );
    })
);

export default PersonalizationCategories;
