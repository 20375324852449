import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { Animated } from '../../../../shared/components/Animated';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const StyledWrapper = styled(Animated)`
    max-width: 845px;
    margin: auto;
    padding-top: 28px;

    @media (${GRID_DEVICES_MAX.sm}) {
        height: 100%;
        overflow-y: auto;
    }
`;

export const StyledSectionName = styled(Typography.Text)`
    margin-bottom: 4px;
    font-weight: 500;
`;

export const StyledTitle = styled(Typography.Title)`
    margin-bottom: 48px;
`;

export const StyledFormWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 8px;
    margin-bottom: 40px;
`;
