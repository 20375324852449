import nextId from 'react-id-generator';
import {
    getFilterParamsFromUrl,
    getPriceRange,
    getUrlParamsFromFilter,
    MARKETPLACE,
    Taxonomy
} from '@klickly/front-packages';
import isEqual from 'lodash.isequal';
import { clone, flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import qs from 'qs';
import { kcpAPI, ROUTE_PATHS } from '../shared';
import { getAggregatedBrands, getAggregatedCategories, processBrandsList, scrollIntoView } from '../shared/utils';
import { models } from './constants';

const { getCategoriesArrayFromString } = Taxonomy;
const { PROMOTIONS_SORTS_STATE } = MARKETPLACE;

export const MarketplaceStore = types
    .model('MarketplaceStore', {
        promotions: types.array(models.PromotionModel),
        pending: types.boolean,
        error: types.maybeNull(types.string),
        brandsPending: types.boolean,
        brandsError: models.ErrorModel,
        search: types.maybe(types.string),
        query: types.maybe(models.QueryModel),
        page: types.number,
        nextPage: types.maybeNull(types.number),
        totalHits: types.maybeNull(types.number),
        totalShown: types.maybeNull(types.number),
        isOpenOnMobile: types.boolean,
        activeChallengeName: types.maybeNull(types.string),
        promotionsByCategoriesPage: 1,
        strategyInfo: types.maybeNull(models.StrategyInfoModel),
        strategyPending: types.boolean,
        bannerTextSizePending: true
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        getPromotionsWithBanners(isMobile) {
            return clone(self.promotions).reduce((result, item, index) => {
                const secondPositionDesktop = !self.root.accountStore.isLoggedIn ? 4 : 2;
                const secondPositionMobile = !self.root.accountStore.isLoggedIn ? 5 : 3;
                return (index === 1 && !self.root.accountStore.isLoggedIn) ||
                    index === (isMobile ? secondPositionMobile : secondPositionDesktop)
                    ? [
                          ...result,
                          item,
                          {
                              _id: nextId(),
                              isBanner: true,
                              bannerType:
                                  !self.root.accountStore.isLoggedIn && index !== 1 ? 'secondBanner' : 'firstBanner'
                          }
                      ]
                    : [...result, item];
            }, []);
        }
    }))
    .actions((self) => ({
        getPromotions: flow(function* getPromotions(isNextPage = false, customQueryParams = null, isNewSearch = false) {
            const { search } = getFilterParamsFromUrl();
            if (isNewSearch) {
                self.page = 1;
                self.nextPage = 0;
            }
            if (self.page === self.nextPage || self.nextPage === null) return false;

            const url = search ? kcpAPI.marketplace.search.basic : kcpAPI.marketplace.promotions.get;
            self.pending = true;
            try {
                const page = isNextPage ? self.nextPage : self.page;
                const queryParams = getUrlParamsFromFilter({ ...self.query, search: search }, { page });
                const data = yield self.root.get(`${url}?${qs.stringify(customQueryParams || queryParams)}`);
                self.page = data.page;
                self.promotions =
                    self.page === 1 || (!isNextPage && isNewSearch)
                        ? [...data.promotions]
                        : [...getSnapshot(self.promotions), ...data.promotions];
                self.nextPage = data.pagination.next;
                self.totalHits = data.totalHits;
                self.totalShown = data.totalShown;
                self.query.categories = getAggregatedCategories(data.aggregations?.categories, self.query.categories);
                self.query.brands = getAggregatedBrands(
                    data.aggregations?.brands,
                    self.query.brands,
                    self.query.allBrands
                );

                if (!isNextPage && !customQueryParams) scrollIntoView();

                self.root.trackingStore.trackViewPromotions(data.promotions);

                return data?.suggestions;
            } catch (error) {
                self.error = error?.message;
            } finally {
                self.pending = false;
            }
        }),
        getBrands: flow(function* getBrands() {
            const { search } = getFilterParamsFromUrl();
            self.brandsPending = true;
            try {
                const data = yield self.root.get(kcpAPI.marketplace.brands.get);
                const brands = processBrandsList(data);
                self.query.brands = brands
                    .map((brand) => ({ ...brand, checked: self.query.selectedBrands.includes(brand?.key) || false }))
                    .filter((brand) => (search ? brand.checked : brand));
                self.query.allBrands = [...brands];
                self.brandsPending = false;
            } catch (brandsError) {
                self.brandsError = brandsError;
                self.brandsPending = false;
            }
        }),
        updatePromotions: flow(function* updatePromotions(params = {}) {
            const { ...query } = getFilterParamsFromUrl();
            const { queryParams = {}, isNewSearch = false, searchValue = '' } = params;

            if (!query.gt && self.query.gt && searchValue) {
                self.query.gt = '';
            }

            self.pending = true;

            if (isNewSearch) self.promotions = [];
            self.root.pdpStore.closePDP(true);
            const search = qs.stringify(getUrlParamsFromFilter({ ...self.query, search: searchValue }, queryParams));
            self.root.routeNavigate({ pathname: ROUTE_PATHS.shop, search });

            return yield self.getPromotions(false, null, isNewSearch);
        }),
        updateIsOpenOnMobile(isOpenOnMobile) {
            self.isOpenOnMobile = isOpenOnMobile;
        },
        updateCategory(data) {
            self.query.categories = self.query.categories.map((category) =>
                category.key === data.key ? { ...category, ...data } : { ...category }
            );
            self.updatePromotions({ isNewSearch: true });
        },
        updateBrand(data) {
            self.query.brands = self.query.brands.map((brand) =>
                brand.key === data.key ? { ...brand, ...data } : brand
            );
            self.updatePromotions({ isNewSearch: true });
        },
        updatePrice(data) {
            self.query.price = { ...data };
            self.updatePromotions({ isNewSearch: true });
        },
        updateQuery(data) {
            if (data.q) return true;

            const beforeUpdate = { ...self.query };
            self.query = {
                ...self.query,
                ...data,
                sort: self.query.sort.map((item) => (item.key === data.sort ? { ...item, checked: true } : item))
            };

            return isEqual(beforeUpdate, self.query);
        },
        selectSorting(data) {
            self.query.sort = self.query.sort.map((sortItem) => ({ ...sortItem, checked: sortItem.key === data.key }));
            self.updatePromotions({ isNewSearch: true });
        },
        clearSearch() {
            self.query.q = '';
            self.updatePromotions({ isNewSearch: true });
        },
        clearAll(params) {
            const clearParams = {
                shouldClearSearch: false,
                shouldClearStrategy: false,
                sort: false,
                isCategoryPage: false,
                ...(typeof params === 'object' ? params : {})
            };

            self.query.price = { ...getPriceRange() };
            self.query.sort = clearParams.sort ? self.query.sort : [...PROMOTIONS_SORTS_STATE];
            self.query.brands = self.query.allBrands.map((brand) => ({ ...brand, checked: false }));
            self.query.gt = clearParams.isCategoryPage ? getCategoriesArrayFromString(self.query.gt)?.[0] : '';

            self.clearStrategyInfo();
            self.updatePromotions({ isNewSearch: true });
        },
        setActiveChallengeName(name) {
            self.activeChallengeName = name;
        },
        getStrategyInfoById: flow(function* getStrategyInfoById(strategyId) {
            self.strategyPending = true;
            try {
                self.strategyInfo = yield self.root.get(`${kcpAPI.marketplace.getStrategyInfoById(strategyId)}`);
            } catch (e) {
                // do nothing
            } finally {
                self.strategyPending = false;
            }
        }),
        getStrategyInfoByName: flow(function* getStrategyInfoByName(strategyName) {
            self.strategyPending = true;
            try {
                self.strategyInfo = yield self.root.get(`${kcpAPI.marketplace.getStrategyInfo(strategyName)}`);
            } catch (e) {
                // do nothing
            } finally {
                self.strategyPending = false;
            }
        }),
        clearStrategyInfo() {
            self.strategyInfo = null;
            self.query.strategy = '';
            self.query.strategyId = '';
        },
        setBannerSizePending(status) {
            self.bannerTextSizePending = status;
        },
        afterCreate() {
            const { ...query } = getFilterParamsFromUrl();
            self.query = query;
        }
    }));
