import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigationType, useParams } from 'react-router-dom';
import { GRID_POINTS, Icon, Search, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import Header from '../../shared/components/Layout/components/Header';
import { remove as removeEl } from '../../utils/elements/common';
import { loadPixels } from '../../utils/pixels';
import { Banner } from './components/Banner';
import { BestSellers } from './components/BestSellers';
import { BrandInfo } from './components/BrandInfo';
import { Promotions } from './components/Promotions';
import { usePromotionsBottomHandler } from './hooks';
import { StyledContent, StyledContentWrap, StyledSearchWrap, StyledWrap } from './styled';

const BrandPage = inject('store')(
    observer(({ store }) => {
        const location = useLocation();
        const navigationType = useNavigationType();
        const { brandId } = useParams();
        const [hidden, setHidden] = useState(false);
        const simple = useSimpleInterface(GRID_POINTS.md);
        const contentRef = useRef(null);
        const {
            nextPage: nextPageNumber,
            pending,
            promotionsPending,
            getPromotions,
            setBrandId,
            getBrandData,
            brandBestSellers,
            brandPixels
        } = store.brandPageStore;

        const nextPage = useCallback(() => {
            if (nextPageNumber && !promotionsPending) {
                getPromotions({ brandId, isNextPage: true });
            }
        }, [getPromotions, nextPageNumber]);

        useEffect(() => {
            const removeListener = store.trackingStore.sendGa4PostMessage({
                eventName: 'view',
                iframeId: 'kl_ga4_iframe'
            });

            setBrandId(brandId);
            getBrandData({ isSimple: simple });

            return () => {
                removeEl('kl_tw_iframe');
                removeEl('kl_ga4_iframe');
                removeListener();
            };
        }, []);

        useEffect(() => {
            if (!brandPixels?.length) return;

            loadPixels(brandPixels, {
                tw: {
                    loadOn: 'view'
                }
            });
        }, [brandPixels]);

        const handleToggleContent = () => {
            if (hidden) {
                setHidden(false);
            } else {
                contentRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                setHidden(true);
            }
        };

        // load next promotions page after scroll to bottom
        usePromotionsBottomHandler({ handler: nextPage, ref: contentRef });

        return (
            <StyledWrap id='app-wrapper' ref={contentRef}>
                {pending ? <Icon.Loader /> : null}
                <Header stickyPosition />
                <StyledContentWrap>
                    {simple ? (
                        <StyledSearchWrap>
                            <Search location={location} navigationType={navigationType} />
                        </StyledSearchWrap>
                    ) : null}
                    <Banner hidden={hidden} />
                    <StyledContent>
                        <BrandInfo hidden={hidden} />
                        {brandBestSellers.length ? (
                            <BestSellers hidden={hidden} onChange={handleToggleContent} />
                        ) : null}
                        <Promotions brandId={brandId} />
                    </StyledContent>
                </StyledContentWrap>
            </StyledWrap>
        );
    })
);

export default BrandPage;
