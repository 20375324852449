import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { saveOnboardingQueryData } from '../../utils/saveOnboardingQueryData';
import { ROUTES } from './constants/routes';
import { StyledWrap } from './styled';

const OnboardingPage = inject('store')(
    observer(({ store }) => {
        const location = store.routeLocation;
        const { isLoggedIn, giftlyData, signUpStatus } = store.accountStore;
        const { onboardingInitialRedirect, redirectUnloggedCutomer, redirected } = store.onboardingRedirectionStore;

        useEffect(() => {
            saveOnboardingQueryData(location.search);

            if (!isLoggedIn && !redirected) {
                redirectUnloggedCutomer();
            }

            if (isLoggedIn && !redirected) {
                onboardingInitialRedirect();
            }
        }, [isLoggedIn, giftlyData, redirected, onboardingInitialRedirect, redirectUnloggedCutomer]);

        if (isLoggedIn && signUpStatus === 'approved') return <Outlet />;

        return redirected ? (
            <StyledWrap>
                <Outlet />
            </StyledWrap>
        ) : (
            <Icon.Loader />
        );
    })
);

OnboardingPage.ROUTES = ROUTES;

export default OnboardingPage;
