import React from 'react';
import {
    CircleActionsCloseIcon,
    GRID_POINTS,
    Icon,
    MailIcon,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $background, $secondaryText } from '../../../../../../../../../../../../../../shared';
import { colorStatusMap, iconStatusMap } from './constants';
import {
    InvitationItemStyles,
    StyledCancelButton,
    StyledContentWrapper,
    StyledInfoWrapper,
    StyledLoader,
    StyledStatus
} from './styles';

export const InvitationItem = inject('store')(
    observer(({ store, name, email, status, id }) => {
        const { cancelInvite, cancelInvitePending } = store.tasksStore;
        const simple = useSimpleInterface(GRID_POINTS.md);

        const isAcceptedStatus = status === 'accepted';

        return (
            <InvitationItemStyles>
                <StyledInfoWrapper>
                    {!simple && (
                        <Icon
                            size='16px'
                            roundedSize='40px'
                            roundedColor={$background}
                            color='#77838F'
                            component={() => <MailIcon />}
                        />
                    )}
                    <div>
                        <Typography.Title level={3} title={name}>
                            {name}
                        </Typography.Title>
                        <Typography.Text type='body2' color={$secondaryText} title={email}>
                            {email}
                        </Typography.Text>
                    </div>
                </StyledInfoWrapper>
                <StyledContentWrapper>
                    {!isAcceptedStatus && (
                        <StyledCancelButton
                            type='unstyled'
                            size='small'
                            onClick={() => cancelInvite(id)}
                            disabled={cancelInvitePending}
                        >
                            {!cancelInvitePending ? (
                                <Icon size='16px' component={() => <CircleActionsCloseIcon />} />
                            ) : (
                                <StyledLoader />
                            )}

                            {!simple && <span>Cancel</span>}
                        </StyledCancelButton>
                    )}
                    <StyledStatus $color={colorStatusMap[status]}>
                        {status}
                        <Icon size='16px' component={() => iconStatusMap[status]} />
                    </StyledStatus>
                </StyledContentWrapper>
            </InvitationItemStyles>
        );
    })
);
