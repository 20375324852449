import React, { useCallback, useEffect, useState } from 'react';
import { GiftIcon, Icon, TimeIcon, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { SUITE_STATES } from '../../../shared';
import { getTimeLeft, GRID_POINTS } from '../../../utils';
import { $primary, $secondaryText } from '../../constants';
import { Timer } from '../Timer';
import { IconWrap, Message, Styles } from './styles';

const TIMER_PARAMS_BY_STATUS = {
    [SUITE_STATES.created]: {
        message: 'Your Gift Suite will unlock in',
        background: $secondaryText,
        timerField: 'nextOpenAt',
        icon: <Icon color='canvas' component={() => <TimeIcon />} />,
        showIconOnMobile: true,
        showIconOnDesktop: false
    },
    [SUITE_STATES.opened]: {
        message: 'New Gifting Suite is ready to launch!',
        icon: <Icon color='canvas' component={() => <GiftIcon />} />,
        background: $primary,
        showIconOnMobile: true,
        showIconOnDesktop: true
    },
    [SUITE_STATES.started]: {
        message: 'Your Gift Suite closes in',
        timerField: 'nextCloseAt',
        icon: <Icon color='canvas' component={() => <TimeIcon />} />,
        showIconOnMobile: true,
        showIconOnDesktop: false
    }
};

export const SuiteTimer = inject('store')(
    observer(({ store, staticPosition = false }) => {
        const [params, setParams] = useState();
        const [endDate, setEndDate] = useState();
        const { data, suiteState, getCurrentSuite } = store.suiteStore;
        const simple = useSimpleInterface(GRID_POINTS.md);

        useEffect(() => {
            if (data && TIMER_PARAMS_BY_STATUS[suiteState]) {
                const _params = TIMER_PARAMS_BY_STATUS[suiteState];

                if (_params) {
                    setParams(_params);
                    setEndDate(data[_params.timerField]);
                }
            }
        }, [data, suiteState, setParams, setEndDate]);

        const onTimeLeft = useCallback(() => {
            getCurrentSuite();
        }, [getCurrentSuite]);

        return (
            data && (
                <Styles staticPosition={staticPosition} background={params?.background}>
                    {params?.icon &&
                        (Boolean(params?.showIconOnMobile && simple) ||
                            Boolean(params?.showIconOnDesktop && !simple)) && <IconWrap>{params?.icon}</IconWrap>}

                    <Message type='body2'>{params?.message}</Message>

                    {params?.timerField && (
                        <Timer
                            showIcon={!simple}
                            onTimeLeft={getTimeLeft(data[params.timerField]) <= 0 ? null : onTimeLeft}
                            endDate={endDate}
                        />
                    )}
                </Styles>
            )
        );
    })
);
