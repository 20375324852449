import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../../../../../shared';

export const useOnboardingPopovers = (store) => {
    const {
        showPopoverOnLaunchGsButton,
        toggleShowPopoverOnLinkToGs,
        toggleShowPopoverOnCart,
        showPopoverOnLinkToGsManuallyClosed
    } = store.onboardingPopoverStore;
    const location = useLocation();

    useEffect(() => {
        if (
            location.pathname !== ROUTE_PATHS.suiteGs &&
            showPopoverOnLaunchGsButton &&
            !showPopoverOnLinkToGsManuallyClosed
        ) {
            toggleShowPopoverOnLinkToGs(true);
        }
    }, [location, showPopoverOnLaunchGsButton]);

    useEffect(() => {
        if (location.pathname !== ROUTE_PATHS.suiteGs) {
            toggleShowPopoverOnCart();
        }
    }, [location]);
};
