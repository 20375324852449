import { KliHero } from '@klickly/front-packages';
import styled, { css } from 'styled-components';

export const StyledKliHero = styled(KliHero)`
    && {
        ${({ withoutBorders }) =>
            withoutBorders
                ? css`
                      padding-left: 0;
                      padding-right: 0;
                  `
                : ''}
    }
`;
