import React from 'react';
import { inject, observer } from 'mobx-react';
import { OrderByDateListItem } from './components/OrderByDateListItem';
import { Styles } from './styles';

export const OrdersByDateList = inject('store')(
    observer(({ store }) => {
        const { ordersByDate: orders = [] } = store.ordersStore;
        return (
            <Styles>
                {Object.entries(orders).map(([date, ordersList]) => (
                    <OrderByDateListItem key={date} date={date} orders={ordersList} />
                ))}
            </Styles>
        );
    })
);
