import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { $primary, ROUTE_PATHS } from '../../../../shared';
import { BoxLoader } from '../../../../shared/components/BoxLoader';
import { Logo } from '../../../../shared/components/Logo';
import { ProcessingContentWrap, ProcessingStyles } from './styles';

const ANIMATION_DURATION = 5000;

const PersonalizationProcessing = observer(() => {
    const navigate = useNavigate();
    useEffect(() => {
        const timeout = setTimeout(() => {
            navigate({
                pathname: ROUTE_PATHS.suite,
                search: '?onboarding=1'
            });
        }, ANIMATION_DURATION);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <ProcessingStyles>
            <ProcessingContentWrap>
                <Logo size='48px' color={$primary} />
                <br />
                <br />
                <BoxLoader animationDuration={ANIMATION_DURATION / 1000 + 's'} />
                <br />
                <br />
                <br />
                <Typography.Title color='primary'>Personalizing...</Typography.Title>
                <br />
                <Typography.Text style={{ textAlign: 'center' }} type='body1' color='primary'>
                    Preparing your Gifting Suite with curated products and offers
                </Typography.Text>
            </ProcessingContentWrap>
        </ProcessingStyles>
    );
});

export default PersonalizationProcessing;
