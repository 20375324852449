import React, { useCallback, useEffect } from 'react';
import { Icon, ModalProvider, useModal, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { DIALOG_TRIGGER_KEY, tasksAliasMap } from '../../shared';
import { parseQuery } from '../../shared/utils';
import { DIALOGS } from './constants';

const ModalTrigger = inject('store')(
    observer(({ store }) => {
        const { customerTasks } = store.tasksStore;
        const { data, setData, closeModal } = store.modalsStore;
        const { setModal, hideModal } = useModal();
        const simple = useSimpleInterface();

        const onClose = useCallback(() => {
            hideModal();
            closeModal();
        }, [closeModal, hideModal]);

        useEffect(() => {
            const data = {
                ...parseQuery(),
                dialogId: parseQuery()[DIALOG_TRIGGER_KEY]
            };

            setData(data);
        }, []);

        useEffect(() => {
            if (!data) {
                return hideModal();
            }

            const { dialogId, ...props } = data;

            if (!DIALOGS[dialogId]) {
                return;
            }

            // to not open tasks modals until tasks information will be loaded
            if (Object.keys(tasksAliasMap).includes(dialogId) && !customerTasks) {
                return;
            }

            const component = DIALOGS[dialogId].component({
                onClose,
                ...props
            });

            setModal(component, {
                width: 'auto',
                padding: '0',
                closable: false,
                fullscreen: simple
            });
        }, [simple, data, customerTasks, onClose, setModal, hideModal]);

        return '';
    })
);

export const DialogsProvider = ({ children, isLoading }) => {
    return isLoading ? (
        <Icon.Loader />
    ) : (
        <ModalProvider>
            <ModalTrigger />
            {children}
        </ModalProvider>
    );
};
