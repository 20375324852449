import { Image } from '@klickly/front-packages';
import styled, { css } from 'styled-components/macro';

export const StyledSliderWrap = styled.div`
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    ${({ $simple }) =>
        $simple
            ? css`
                  padding-bottom: 30px;
              `
            : ''};
`;

export const StyledImage = styled(Image)`
    width: 100%;
    object-fit: contain;
    ${({ $simple }) =>
        $simple
            ? css`
                  max-height: 400px;
              `
            : ''};
    ${({ $simple }) =>
        $simple
            ? css`
                  max-height: 238px;
              `
            : ''};
`;

export const StyledImgWrapper = styled.div`
    width: 425px;
    padding-right: 64px;
    padding-left: 44px;
    ${({ $simple }) =>
        $simple
            ? css`
                  width: 300px;
                  padding-right: 64px;
                  padding-left: 44px;
              `
            : ''};
    ${({ $simple }) =>
        $simple
            ? css`
                  width: 100%;
                  padding: 0 38px;
              `
            : ''};
`;
