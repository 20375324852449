import { Icon } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $border, $secondaryText } from '../../../../shared';

export const StyledWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: ${({ $withoutBanner }) => ($withoutBanner ? '24px' : '0')};
    padding-bottom: 24px;
    border-bottom: 1px solid ${$border};

    ${({ $simple }) =>
        $simple
            ? css`
                  border: none;
                  flex-direction: column;
                  justify-content: center;
              `
            : ''}

    ${({ $hidden }) =>
        $hidden
            ? css`
                  margin-bottom: 0;
                  padding-bottom: 12px;
              `
            : ''}
`;

export const StyledIcon = styled(Icon)`
    &&& {
        color: ${$secondaryText} !important;
    }
`;
