import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    ArrowChevronForwardIcon,
    BOTTOM_INDENTS,
    Button,
    Icon,
    RIGHT_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { kcpAPI, useModal } from '../../shared';
import { GRID_POINTS } from '../../utils';
import { UpsellCoupon } from './components/UpsellCoupon';
import { StyledButtonsWrap, StyledContent, StyledWrap } from './styled';

export const Upsell = inject('store')(
    observer(({ store }) => {
        const { closeModal } = useModal();
        const simple = useSimpleInterface(GRID_POINTS.xs);
        const { state } = useLocation();
        const cartId = state?.cartId;
        const checkoutKey = state?.checkoutKey;
        const { upsellDataExists, upsell, getUpsellByCart, pending } = store.upsellStore;

        useEffect(() => {
            closeModal();
            if (!upsellDataExists) {
                getUpsellByCart(cartId);
            }
        }, []);

        if (pending) {
            return <Icon.Loader />;
        }

        const handleGoToCheckout = () => {
            window.open(`${kcpAPI.cart.makeCheckoutUrl(cartId, checkoutKey)}`, '_self');
        };

        const handleClaimUpsell = async () => {
            const body = {
                cartId,
                rewardId: upsell.rewardId,
                promotionId: upsell.promotionId,
                variantId: upsell.variantId
            };

            await store.upsellStore.claimUpsell(body, handleGoToCheckout);
        };

        return upsellDataExists ? (
            <StyledWrap>
                <StyledContent $simple={simple}>
                    <Typography.Title level={1} style={{ ...BOTTOM_INDENTS.XL }}>
                        You have possibility to upgrade your order
                    </Typography.Title>
                    <UpsellCoupon />
                    <StyledButtonsWrap $simple={simple}>
                        <Button type='primary' ghost block onClick={handleClaimUpsell} style={{ ...BOTTOM_INDENTS.M }}>
                            <Typography.Text type='badgeLarge'>Claim reward</Typography.Text>
                        </Button>
                        <Button type='unstyled' block onClick={handleGoToCheckout}>
                            <Typography.Text type='badgeLarge' style={{ ...RIGHT_INDENTS.XXS }}>
                                No, Thanks
                            </Typography.Text>
                            <Icon size='22px' component={() => <ArrowChevronForwardIcon />} />
                        </Button>
                    </StyledButtonsWrap>
                </StyledContent>
            </StyledWrap>
        ) : null;
    })
);
