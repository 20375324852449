import { get } from '../../../../../utils';

export const getOrderData = (order) => {
    if (!order) return {};

    const {
        items = [],
        prices = {},
        pointsEarned,
        orderNumber,
        brandName,
        externalId,
        createdAt: createdAtValue
    } = order;

    const createdAt = createdAtValue
        ? new Date(createdAtValue).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
          })
        : '';

    const originalPrice = get(prices, 'regular.total', null);
    const price = get(prices, 'result.total', null);
    const itemsBadge = items.length - 1;
    const imageSrc = get(order, 'items.[0].image', '');
    const trackingUrl = get(order, 'fulfillment.tracking_url', null);

    return {
        createdAt,
        originalPrice,
        price,
        itemsBadge,
        imageSrc,
        pointsEarned,
        orderNumber,
        brandName,
        externalId,
        trackingUrl
    };
};
