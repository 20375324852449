import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const Styles = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 48px 80px;

    @media (${GRID_DEVICES_MAX.lg}) {
        width: 100%;
        position: static;
        padding: 24px;
    }
`;
