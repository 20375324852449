import { Button, Icon } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $primary, $tartRed } from '../../../../../../../../../../shared';

export const StyledWrapper = styled.div`
    padding: 0 4px;
`;

export const StyledContent = styled.div`
    padding: 14px;
`;

export const StyledIcon = styled(Icon)`
    width: 48px;
    height: 48px;
    background-color: ${$primary};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledButton = styled(Button)`
    &&,
    &&:hover,
    &&:active {
        height: 48px;
        background-color: ${$tartRed};
        border: none;

        ${({ $simple }) =>
            $simple
                ? css`
                      margin-left: 0;
                  `
                : ''}
    }
`;

export const StyledInfoButton = styled(Button)`
    &&,
    &&:hover,
    &&:active {
        padding: 14px;

        & .anticon {
            color: ${$primary} !important;
        }
    }
`;
