import { Search, Taxonomy } from '@klickly/front-packages';
import { flow, getEnv, types } from 'mobx-state-tree';
import { axiosInstance } from '../constants/axiosInstance';
import { kcpAPI, ROUTE_PATHS } from '../shared';
import { saveOnboardingQueryData } from '../utils/saveOnboardingQueryData';
import { AccountStore } from './AccountStore';
import { AuthStore } from './AuthStore';
import { BrandPageStore } from './BrandPageStore';
import { ChromeExtensionStore } from './ChromeExtensionStore';
import { ClaimsPointsStore } from './ClaimsPointsStore';
import { INITIAL_STORES } from './constants';
import { DashboardStore } from './DashboardStore';
import { DealsStore } from './DealsStore';
import { EventListenerStore } from './EventListenerStore';
import { FavoritesPromotionsStore } from './FavoritesPromotionsStore';
import { MarketplaceStore } from './MarketplaceStore';
import { ModalsStore } from './ModalsStore';
import { NavigationStore } from './NavigationStore';
import { OnboardingPopoverStore } from './OnboardingPopoverStore';
import { OnboardingRedirectionStore } from './OnboardingRedirectionStore';
import { OnboardingStore } from './OnboardingStore';
import { OrdersStore } from './OrdersStore';
import { OrderStore } from './OrderStore';
import { PdpStore } from './PdpStore';
import { PointsStore } from './PointsStore';
import { PreCheckoutStore } from './PreCheckoutStore';
import { RecommendedPromotionsStore } from './RecommendedPromotionsStore';
import { RewardsStore } from './RewardsStore';
import { SeasonalChallengeStore } from './SeasonalChallengeStore';
import { ShareProductStore } from './ShareProductStore';
import { ShoppingCartStore } from './ShoppingCartStore';
import { SuitePromotionsStore } from './SuitePromotionsStore';
import { SuiteStore } from './SuiteStore';
import { TasksStore } from './TasksStore';
import { ThankYouPageStore } from './ThankYouPageStore';
import { TrackingStore } from './TrackingStore';
import { UnsubscribeStore } from './UnsubscribeStore';
import { UpsellStore } from './UpsellStore';

export const RootStore = types
    .model('RootStore', {
        isLoading: true,
        errorStatus: types.maybeNull(types.number),
        shoppingCartStore: types.optional(ShoppingCartStore, {}),
        pdpStore: types.optional(PdpStore, INITIAL_STORES.PDP),
        marketplaceStore: types.optional(MarketplaceStore, INITIAL_STORES.MARKETPLACE),
        trackingStore: types.optional(TrackingStore, {}),
        authStore: types.optional(AuthStore, {}),
        onboardingStore: types.optional(OnboardingStore, {}),
        onboardingRedirectionStore: types.optional(OnboardingRedirectionStore, {}),
        onboardingPopoverStore: types.optional(OnboardingPopoverStore, {}),
        suiteStore: types.optional(SuiteStore, {}),
        suitePromotionsStore: types.optional(SuitePromotionsStore, {}),
        claimsPointsStore: types.optional(ClaimsPointsStore, {}),
        thankYouPageStore: types.optional(ThankYouPageStore, {}),
        accountStore: types.optional(AccountStore, {}),
        ordersStore: types.optional(OrdersStore, {}),
        orderStore: types.optional(OrderStore, {}),
        modalsStore: types.optional(ModalsStore, {}),
        tasksStore: types.optional(TasksStore, {}),
        eventListenerStore: types.optional(EventListenerStore, {}),
        chromeExtensionStore: types.optional(ChromeExtensionStore, {}),
        rewardsStore: types.optional(RewardsStore, INITIAL_STORES.REWARDS),
        shareProductStore: types.optional(ShareProductStore, {}),
        recommendedPromotionsStore: types.optional(RecommendedPromotionsStore, INITIAL_STORES.RECOMMENDED_PROMOTIONS),
        preCheckoutStore: types.optional(PreCheckoutStore, {}),
        navigationStore: types.optional(NavigationStore, {}),
        unsubscribeStore: types.optional(UnsubscribeStore, {}),
        favoritesPromotionsStore: types.optional(FavoritesPromotionsStore, INITIAL_STORES.FAVORITES_PROMOTIONS),
        upsellStore: types.optional(UpsellStore, INITIAL_STORES.UPSELL),
        dealsStore: types.optional(DealsStore, INITIAL_STORES.DEALS),
        dashboardStore: types.optional(DashboardStore, INITIAL_STORES.DASHBOARD),
        brandPageStore: types.optional(BrandPageStore, INITIAL_STORES.BRAND_PAGE),
        seasonalChallengeStore: types.optional(SeasonalChallengeStore, {}),
        pointsStore: types.optional(PointsStore, {})
    })
    .views((self) => ({
        get get() {
            return async (url, params) => {
                try {
                    const { data } = await axiosInstance.get(url, params && { params });
                    return data;
                } catch (error) {
                    self.setErrorStatus(error.response?.status);
                    throw error;
                }
            };
        },
        get post() {
            return async (url, body = {}) => {
                try {
                    const { data } = await axiosInstance.post(url, body);
                    return data;
                } catch (error) {
                    self.setErrorStatus(error.response?.status);
                    throw error;
                }
            };
        },
        get patch() {
            return async (url, body = {}) => {
                try {
                    const { data } = await axiosInstance.patch(url, body);
                    return data;
                } catch (error) {
                    self.setErrorStatus(error.response?.status);
                    throw error;
                }
            };
        },
        get put() {
            return async (url, body = {}) => {
                try {
                    const { data } = await axiosInstance.put(url, body);
                    return data;
                } catch (error) {
                    self.setErrorStatus(error.response?.status);
                    throw error;
                }
            };
        },
        get delete() {
            return async (url, params) => {
                try {
                    const { data } = await axiosInstance.delete(url, params && { params });
                    return data;
                } catch (error) {
                    self.setErrorStatus(error.response?.status);
                    throw error;
                }
            };
        },
        get navigation() {
            return getEnv(self).navigate;
        },
        get location() {
            return getEnv(self).location;
        }
    }))
    .actions((self) => {
        const setIsLoading = (isLoading) => {
            self.isLoading = isLoading;
        };

        const setErrorStatus = (status) => {
            self.errorStatus = status;
        };

        const getAuthorizedUserData = () => {
            self.suiteStore.getCurrentSuite();
            self.ordersStore.getOrders();
            self.accountStore.getBillingData();
            self.tasksStore.getTasks(true);
            self.seasonalChallengeStore.getSeasonalChallenge();
            self.claimsPointsStore.getPoints();
        };

        const afterAuthCheck = () => {
            const navigate = self.routeNavigate;
            const { search, pathname } = window.location || {};
            const { isLoggedIn } = self.accountStore;
            const linkToMarketPlace = self.navigationStore.getLinkToMarketPlace();

            if (pathname === '/' && isLoggedIn) {
                navigate(`${linkToMarketPlace}${search}`);
            }
            setIsLoading(false);
        };

        const afterCreate = flow(function* afterCreate() {
            Taxonomy.store.setKcpAPI(kcpAPI);
            Search.store.afterInit({
                kcpAPI,
                onBeforeSearch: () => {
                    if (window.location.pathname !== ROUTE_PATHS.shop) {
                        self.routeNavigate(ROUTE_PATHS.shop);
                    }
                },
                onSearch: self.marketplaceStore.updatePromotions,
                onClear: self.marketplaceStore.clearSearch
            });
            yield self.authStore.checkAuth();
            const { isLoggedIn, giftlyData } = self.accountStore;
            saveOnboardingQueryData(self.routeLocation?.search);
            afterAuthCheck();
            self.shoppingCartStore.getShoppingCartState();

            if (isLoggedIn && giftlyData) getAuthorizedUserData();

            self.chromeExtensionStore.init();
        });

        const setHistoryState = (navigate, location) => {
            self.routeNavigate = navigate;
            self.routeLocation = location;
        };

        return { afterCreate, setHistoryState, setErrorStatus };
    });
