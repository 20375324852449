import { Icon, KliHero } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MIN } from '../../../../../../utils';

export const Styles = styled.div`
    position: relative;
    width: 100%;
`;

export const DialogsCLoseIcon = styled(Icon)`
    position: absolute;
    bottom: 100%;
    margin-bottom: 30px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
`;

export const Header = styled(KliHero)`
    @media (${GRID_DEVICES_MIN.md}) {
        justify-content: space-around;
    }
`;
