import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS } from '@klickly/front-packages';
import { StyledKliHero } from './styled';

export const PageHeader = ({
    hasBottomIndent,
    title,
    titleComponent,
    subTitle = 'Account',
    simple = false,
    withoutBorders = true,
    withGoBack = false,
    ...props
}) => {
    const navigate = useNavigate();

    const onBack = () => {
        navigate(-1);
    };

    return (
        <StyledKliHero
            style={hasBottomIndent ? BOTTOM_INDENTS.SM : {}}
            titleComponent={titleComponent}
            title={title}
            subTitle={subTitle}
            simple={simple}
            withoutBorders={withoutBorders}
            onBack={withGoBack ? onBack : null}
            {...props}
        />
    );
};
