import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../utils';
import { animation$ } from '../../constants/animation';

export const Animated = styled.div`
    animation: ${animation$} 0.3s ease-in-out;
    will-change: transform, opcity;

    .ant-alert {
        margin-bottom: 20px;
        padding: 20px;
        @media (${GRID_DEVICES_MAX.md}) {
            margin-bottom: 16px;
        }

        .ant-alert-message {
            font-weight: 500;
            font-size: 16px;
        }
    }
`;
