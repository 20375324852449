import { Button, Icon } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../utils';
import { $primaryText } from '../../../../constants';

export const StyledButton = styled(Button)`
    && {
        margin-right: 24px;
        &:hover:not(:disabled) {
            color: ${$primaryText};
        }

        @media (${GRID_DEVICES_MAX.md}) {
            margin-right: 16px;
        }
    }
`;

export const StyledIcon = styled(Icon)`
    && {
        font-size: 20px !important;
    }
`;
