import styled, { css } from 'styled-components';
import { $background } from '../../constants';

export const RewardsTasksStyles = styled.div`
    height: 100%;
    width: 100%;
    padding: 20px 20px 60px 20px;
    background: ${$background};
    position: relative;

    &:hover {
        cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
    }

    ${({ $disabled }) =>
        $disabled
            ? css`
                  pointer-events: none;
                  touch-action: none;
              `
            : ''}
`;

export const RewardTaskFooter = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    padding: 0 20px 20px;
    justify-content: space-between;
    align-items: center;

    > * {
        display: flex;
        flex-direction: row;
    }
`;
