import React from 'react';
import { NavLink } from 'react-router-dom';
import {
    CartIcon,
    CommentWithLinesIcon,
    Component1Icon,
    Divider,
    GiftIcon,
    HeartIcon,
    HomeIcon,
    LogOutSquareIcon,
    NotificationBellIcon,
    PointsIcon,
    ProfileIcon,
    ScrollIcon,
    StarIcon,
    TicketIcon,
    TrophyIcon,
    TrophyPersonal,
    TwoPeopleIcon,
    Typography
} from '@klickly/front-packages';
import { MenuLink } from '../components/MenuLink';
import { ROUTE_PATHS } from '../constants';

export const getMenuItemsMobile = ({ store, onMenuClose, isMenuOpen, onLogout, isLoggedIn, isApprovedCustomer }) => {
    const rewards = [
        {
            key: 'RewardsTitle-key',
            label: (
                <Typography.Text type='badgeLarge' color='secondaryText'>
                    Rewards
                </Typography.Text>
            ),
            className: 'kli-non-menu-item-mobile'
        },
        {
            label: <MenuLink.WithCount onMenuClose={onMenuClose} />,
            icon: <GiftIcon />,
            key: 'GiftingSuite-key'
        },
        {
            label: (
                <NavLink
                    to={ROUTE_PATHS.coupons}
                    onClick={() =>
                        store.trackingStore.sendEvent({
                            type: 'event',
                            name: 'RewardsPage_GoToRewards'
                        })
                    }
                >
                    Coupons
                </NavLink>
            ),
            icon: <TicketIcon />,
            key: 'Coupons-key',
            isHidden: !isLoggedIn || (isLoggedIn && store.accountStore.signUpStatusRejected)
        },
        {
            label: (
                <NavLink
                    to={ROUTE_PATHS.challenges}
                    onClick={() =>
                        store.trackingStore.sendEvent({
                            type: 'event',
                            name: 'RewardsPage_GoToChallenges'
                        })
                    }
                >
                    Challenges
                </NavLink>
            ),
            icon: <TrophyIcon />,
            key: 'Challenges-key',
            isHidden: !isLoggedIn || (isLoggedIn && store.accountStore.signUpStatusRejected)
        },
        {
            label: (
                <NavLink
                    to={ROUTE_PATHS.seasonalChallenge}
                    onClick={() =>
                        store.trackingStore.sendEvent({
                            type: 'event',
                            name: 'RewardsPage_GoToSeasonalChallenge'
                        })
                    }
                >
                    {store.seasonalChallengeStore?.seasonalChallenge?.name}
                </NavLink>
            ),
            icon: <TrophyPersonal />,
            key: 'SeasonalChallenge-key',
            isHidden: !isLoggedIn || (isLoggedIn && store.accountStore.signUpStatusRejected)
        },

        {
            label: (
                <NavLink onClick={onMenuClose} to={ROUTE_PATHS.inviteAndEarn}>
                    Invite & Earn
                </NavLink>
            ),
            icon: <TwoPeopleIcon />,
            key: 'InviteEarn-key'
        },
        {
            label: (
                <NavLink onClick={onMenuClose} to={ROUTE_PATHS.points}>
                    Points
                </NavLink>
            ),
            icon: <PointsIcon />,
            key: 'PointsPage-key'
        },
        {
            key: 'RewardsDivider',
            label: <Divider margin='0 0 24px' />,
            className: 'kli-non-menu-item-mobile',
            isHidden: !isLoggedIn
        }
    ];
    return [
        {
            key: 'Home',
            icon: <HomeIcon />,
            label: (
                <NavLink onClick={onMenuClose} to={ROUTE_PATHS.dashboard}>
                    Home
                </NavLink>
            ),
            isHidden: !isApprovedCustomer
        },
        {
            key: 'HomeDivider',
            label: <Divider margin='0 0 24px' />,
            className: 'kli-non-menu-item-mobile',
            isHidden: !isApprovedCustomer
        },
        {
            key: 'ShoTitle-key',
            label: (
                <Typography.Text type='badgeLarge' color='secondaryText'>
                    Shop
                </Typography.Text>
            ),
            className: 'kli-non-menu-item-mobile'
        },
        {
            label: <NavLink to={ROUTE_PATHS.deals}>Deals</NavLink>,
            icon: <StarIcon />,
            key: 'Deals-key'
        },
        {
            label: <MenuLink.Shop onMenuClose={onMenuClose} isMenuOpen={isMenuOpen} />,
            icon: <CartIcon />,
            key: 'Shop-key'
        },
        {
            key: 'ShopDivider',
            label: <Divider margin='0 0 24px' />,
            className: 'kli-non-menu-item-mobile'
        },
        ...rewards,
        {
            key: 'ProfileTitle-key',
            label: (
                <Typography.Text type='badgeLarge' color='secondaryText'>
                    Profile
                </Typography.Text>
            ),
            className: 'kli-non-menu-item-mobile',
            isHidden: !isLoggedIn
        },
        {
            key: 'MyProfile',
            icon: <ProfileIcon />,
            label: (
                <NavLink onClick={onMenuClose} to={ROUTE_PATHS.account}>
                    My Profile
                </NavLink>
            ),
            isHidden: !isLoggedIn
        },
        {
            label: (
                <MenuLink.WithCount
                    onMenuClose={onMenuClose}
                    text='Wishlist'
                    type='wishlist'
                    to={ROUTE_PATHS.wishlist}
                />
            ),
            icon: <HeartIcon />,
            key: 'Wishlist-key',
            isHidden: !isLoggedIn
        },
        {
            key: 'OrderHistory',
            icon: <ScrollIcon />,
            label: (
                <NavLink onClick={onMenuClose} to={ROUTE_PATHS.orders}>
                    Order history
                </NavLink>
            ),
            isHidden: !isLoggedIn
        },
        {
            key: 'ShippingAndBilling',
            icon: <Component1Icon />,
            label: (
                <NavLink onClick={onMenuClose} to={ROUTE_PATHS.shippingBilling}>
                    Shipping and billing
                </NavLink>
            ),
            isHidden: !isLoggedIn
        },
        {
            key: 'FavoriteCategories',
            icon: <HeartIcon />,
            label: (
                <NavLink onClick={onMenuClose} to={ROUTE_PATHS.favoriteCategories}>
                    Favorite categories
                </NavLink>
            ),
            isHidden: !isLoggedIn
        },
        {
            key: 'Notifications',
            icon: <NotificationBellIcon />,
            label: (
                <NavLink onClick={onMenuClose} to={ROUTE_PATHS.notifications}>
                    Notifications
                </NavLink>
            ),
            isHidden: !isLoggedIn
        },
        {
            key: 'SendFeedbackDivider',
            label: <Divider margin='0 0 24px' />,
            className: 'kli-non-menu-item-mobile',
            isHidden: !isLoggedIn
        },
        {
            key: 'SendFeedback',
            icon: <CommentWithLinesIcon />,
            label: (
                <NavLink onClick={onMenuClose} to={ROUTE_PATHS.sendFeedback}>
                    Send feedback
                </NavLink>
            ),
            isHidden: !isLoggedIn
        },
        {
            key: 'SignOut',
            icon: <LogOutSquareIcon />,
            label: <MenuLink.SignOut onMenuClose={onMenuClose} onLogOut={onLogout} />,
            isHidden: !isLoggedIn
        }
    ];
};
