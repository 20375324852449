import styled from 'styled-components';
import { $tartRed } from '../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const ProcessingStyles = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (${GRID_DEVICES_MAX.md}) {
        display: block;
    }
`;

export const ProcessingContentWrap = styled.div`
    max-width: 630px;
    width: 100%;
    min-height: 500px;
    background-color: ${$tartRed};
    padding: 100px 0 150px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (${GRID_DEVICES_MAX.md}) {
        max-width: unset;
        padding: 0;
        height: 100%;
    }
`;
