import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const StyledWrapper = styled.div`
    position: relative;
    margin-bottom: 80px;

    @media (${GRID_DEVICES_MAX.md}) {
        margin-bottom: 64px;
    }
`;
