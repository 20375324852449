import React, { Fragment } from 'react';
import { KliCoupon, Promotion, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import placeholder from '../../../../../../../../assets/img/image-placeholder.svg';
import { useCountDown } from '../../../../../../../../shared';
import { GRID_POINTS } from '../../../../../../../../utils';
import {
    StyledBottomText,
    StyledDescription,
    StyledExpiredText,
    StyledFooter,
    StyledRewardsBadge,
    StyledTitle,
    StyledWrapper
} from './styled';

const RewardDetailsExtraContent = observer((props) => {
    const { selectedRewardId, getRewardById, selectedReward, setSelectedRewardPromotionId, setSelectedReward } = props;

    const reward = selectedReward || getRewardById(selectedRewardId);

    const simple = useSimpleInterface(GRID_POINTS.md);

    const expireCountDown = useCountDown(reward?.expireAt, () => {
        if (reward.expireAt) {
            setSelectedRewardPromotionId(null);
            setSelectedReward(null);
        }
    });

    return (
        <StyledWrapper>
            <StyledTitle level={2}>Reward details</StyledTitle>
            <KliCoupon
                data-e2e='reward-details-extra-block'
                align={simple && 'center'}
                data-klpixel-omit-event-click
                img={
                    <Promotion.Img
                        placeholder={placeholder}
                        data-e2e='promotion-img-extra'
                        height={`${simple ? 106 : 170}px`}
                        width={`${simple ? 106 : 170}px`}
                        images={[{ src: reward?.image, position: 1 }]}
                    />
                }
                title={reward?.title}
                bottomContent={null}
                additionalDetailsContent={
                    <Fragment>
                        {reward?.expireAt && (
                            <StyledExpiredText type='descriptor2' color='secondaryText'>
                                Expires {expireCountDown}
                            </StyledExpiredText>
                        )}
                        <StyledRewardsBadge data-e2e='reward-applied-badge' type='descriptor2' color='primary'>
                            Reward Applied
                        </StyledRewardsBadge>
                        {!simple && <StyledDescription type='body2'>{reward?.description}</StyledDescription>}
                    </Fragment>
                }
            />
            <StyledFooter>
                <StyledBottomText type='body2'>Qualifying product</StyledBottomText>
            </StyledFooter>
        </StyledWrapper>
    );
});

export default RewardDetailsExtraContent;
