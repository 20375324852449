import React from 'react';
import {
    BOTTOM_INDENTS,
    CircleActionsAlertInfoIcon,
    GRID_POINTS,
    ProfileIcon,
    TagIcon,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { InfoItem } from '../../../../shared/components/InfoItem';
import { PageHeader } from '../../../../shared/components/PageHeader';
import { StyledIcon, StyledItemPoint, StyledItemPointsWrapper, StyledPageStyles } from './styles';

const InviteAndEarnRulesPage = observer(() => {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    return (
        <StyledPageStyles>
            <PageHeader title='Learn more' subTitle='Invite & Earn' withGoBack />

            <InfoItem icon={<ProfileIcon />}>
                <Typography.Title style={BOTTOM_INDENTS.SM} level={2}>
                    How do I invite a friend?
                </Typography.Title>
                <Typography.Text type='body1'>
                    Simply enter your invitee’s first name and email address, and we’ll send them an invite on your
                    behalf. <br />
                    <br />
                    Once your invitee is accepted to Giftly, you’ll automatically receive a new Gift claim. <br />
                    <br />
                    This offer is only valid for new Giftly invitees who are accepted to Giftly for the first time. An
                    invitation increases the chances, but does not guarantee, that your invitee will be accepted into
                    Giftly – they must still pass verification. This offer is subject to change at any time.
                </Typography.Text>
            </InfoItem>

            <InfoItem icon={<TagIcon />}>
                <Typography.Title style={BOTTOM_INDENTS.SM} level={2}>
                    How do I share a product?
                </Typography.Title>
                <Typography.Text type='body1'>
                    Simply open a product page and click on the “Share” button to copy a personalized link to that page.{' '}
                    <br />
                    <br />
                    Share this link with your friends and followers. <br />
                    <br />
                    Whenever a guest purchases the given product via your personalized link, you’ll receive points.{' '}
                    <br />
                    <br />
                    This offer is only valid for guest purchases (i.e. purchases made by non-Giftly members). This offer
                    is subject to change at any time.
                </Typography.Text>
            </InfoItem>

            <InfoItem hasBottomIndent icon={<CircleActionsAlertInfoIcon />}>
                <Typography.Title style={BOTTOM_INDENTS.SM} level={2}>
                    Are there any sharing rules?
                </Typography.Title>
                <Typography.Text type='body1'>
                    We’re a community that promotes brands out of genuine love, not because we’re forced to share or
                    post. To that end:
                </Typography.Text>
                <StyledItemPointsWrapper $simple={simple}>
                    <StyledItemPoint>
                        <StyledIcon>
                            <CircleActionsAlertInfoIcon color='#77838F' />
                        </StyledIcon>
                        <Typography.Text type='badgeSmall'>
                            Sharing is not compulsory – do NOT share or post unless YOU want to
                        </Typography.Text>
                    </StyledItemPoint>
                    <StyledItemPoint>
                        <StyledIcon>
                            <CircleActionsAlertInfoIcon color='#77838F' />
                        </StyledIcon>
                        <Typography.Text type='badgeSmall'>
                            Any posts you make are not sponsored – do NOT mark your posts as ads or paid posts
                        </Typography.Text>
                    </StyledItemPoint>
                    <StyledItemPoint>
                        <StyledIcon>
                            <CircleActionsAlertInfoIcon color='#77838F' />
                        </StyledIcon>
                        <Typography.Text type='badgeSmall'>
                            Access to Giftly is exclusive – do NOT tag Giftly or post about the prices you have access
                            to
                        </Typography.Text>
                    </StyledItemPoint>
                </StyledItemPointsWrapper>
            </InfoItem>
        </StyledPageStyles>
    );
});

export default InviteAndEarnRulesPage;
