import React, { Fragment, useEffect, useRef } from 'react';
import { BOTTOM_INDENTS, BrowserStorage, Col, Form, Input, Row } from '@klickly/front-packages';
import { VALIDATION_RULES } from '../../constants';
import { useKlicklyAutocomplete } from '../../hooks';
import { StyledAutocompleteContainer } from './styled';

const AUTOCOMPLETE_CONTAINER_ID = 'klickly-autocomplete-wrap-container';

export const ShippingAddressFormFields = ({
    mode = 'shipping',
    hasBottomIndent,
    hasPhone = true,
    withAutoComplete = true,
    form
}) => {
    const address1Ref = useRef(null);
    const klicklyAutocomplete = useKlicklyAutocomplete();
    const firstName = Form.useWatch('firstName', form);

    useEffect(() => {
        if (BrowserStorage.get('customerFirstName') !== firstName) {
            BrowserStorage.set('customerFirstName', firstName);
        }
    }, [firstName]);

    const onFillData = ({ address1 = '', city = '', province = '', zipCode = '' }) => {
        form.setFields([
            { name: 'address1', touched: true, errors: [], value: address1 },
            { name: 'city', touched: true, errors: [], value: city },
            { name: 'province', touched: true, errors: [], value: province },
            { name: 'zipCode', touched: true, errors: [], value: zipCode }
        ]);
    };

    useEffect(() => {
        if (withAutoComplete) {
            const autocomplete = async () => {
                await klicklyAutocomplete.loadScript();
                if (address1Ref.current) {
                    klicklyAutocomplete.initAutocomplete(
                        address1Ref.current.input,
                        AUTOCOMPLETE_CONTAINER_ID,
                        onFillData
                    );
                }
                return () => klicklyAutocomplete.unsubscribe();
            };
            autocomplete();
        }
    }, []); //eslint-disable-line
    return (
        <Fragment>
            {mode === 'billing' ? (
                <Row gutter={20}>
                    <Col span={24} md={{ span: 24 }}>
                        <Form.Item name='nameOnCard' rules={VALIDATION_RULES.REQUIRED}>
                            <Input size='large' label='Full name' autoComplete='on' />
                        </Form.Item>
                    </Col>
                </Row>
            ) : (
                <Row gutter={20}>
                    <Col span={24} md={{ span: 12 }}>
                        <Form.Item name='firstName' rules={VALIDATION_RULES.REQUIRED}>
                            <Input size='large' label='First Name' autoComplete='on' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item name='lastName' rules={VALIDATION_RULES.REQUIRED}>
                            <Input size='large' label='Last Name' autoComplete='on' />
                        </Form.Item>
                    </Col>
                </Row>
            )}

            <Row gutter={20}>
                <Col span={24} md={{ span: 12 }}>
                    <StyledAutocompleteContainer id={AUTOCOMPLETE_CONTAINER_ID}>
                        <Form.Item name='address1' rules={VALIDATION_RULES.REQUIRED}>
                            <Input size='large' label='Address line 1' autoComplete='off' ref={address1Ref} />
                        </Form.Item>
                    </StyledAutocompleteContainer>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item name='address2'>
                        <Input size='large' label='Address line 2' autoComplete='on' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={24} md={{ span: 12 }}>
                    <Form.Item name='city' rules={VALIDATION_RULES.REQUIRED}>
                        <Input size='large' label='City' autoComplete='on' />
                    </Form.Item>
                </Col>
                <Col span={12} md={{ span: 6 }}>
                    <Form.Item name='province' rules={VALIDATION_RULES.REQUIRED}>
                        <Input size='large' label='State' autoComplete='on' />
                    </Form.Item>
                </Col>
                <Col span={12} md={{ span: 6 }}>
                    <Form.Item
                        name='zipCode'
                        rules={[
                            ...VALIDATION_RULES.REQUIRED,
                            {
                                max: 5,
                                message: 'The ZIP code must be 5 digits.\n'
                            }
                        ]}
                    >
                        <Input type='number' size='large' label='ZIP code' autoComplete='on' />
                    </Form.Item>
                </Col>
            </Row>
            <Row style={hasBottomIndent ? BOTTOM_INDENTS.XL : {}} gutter={20}>
                <Col span={24} md={{ span: 12 }}>
                    <Form.Item
                        name='phone'
                        rules={
                            hasPhone
                                ? [
                                      ...VALIDATION_RULES.REQUIRED,
                                      {
                                          min: 14,
                                          message: 'The Phone must be 10 digits'
                                      }
                                  ]
                                : null
                        }
                    >
                        <Input type='tel' size='large' label='Phone' autoComplete='on' />
                    </Form.Item>
                </Col>
            </Row>
        </Fragment>
    );
};
