import { useRef } from 'react';

const { REACT_APP_GOOGLE_API_KEY } = process.env;

export const useKlicklyAutocomplete = () => {
    const abortController = useRef({});

    const loadScript = () => {
        return new Promise((resolve) => {
            const done = () => resolve();

            if (window.google || !REACT_APP_GOOGLE_API_KEY) {
                return done();
            }

            const script = document.createElement('script');
            script.onload = done;
            script.onerror = done;
            script.type = 'text/javascript';
            script.src = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_API_KEY}&libraries=places&language=en`;
            document.head.appendChild(script);
        });
    };

    const initAutocomplete = (autocompleteFormField, parentId, onFillData) => {
        if (!window.google?.maps?.places?.AutocompleteService || !onFillData) {
            return true;
        }

        const autocomplete = new window.google.maps.places.AutocompleteService();
        const predictionList = predictionListMarkup();
        const parentNode = document.getElementById(parentId);
        abortController.current = new AbortController();

        if (!autocompleteFormField || !parentNode) {
            return true;
        }

        parentNode.appendChild(predictionList);

        autocompleteFormField.addEventListener(
            'input',
            () => {
                if (autocompleteFormField.value && document.activeElement === autocompleteFormField) {
                    predictionList.style.display = 'block';
                    autocomplete.getPlacePredictions(
                        {
                            input: autocompleteFormField.value,
                            types: ['geocode'],
                            componentRestrictions: {
                                country: ['US']
                            }
                        },
                        (predictions, status) => {
                            displayPredictionSuggestions(
                                predictions,
                                status,
                                predictionList,
                                autocompleteFormField,
                                onFillData
                            );
                        }
                    );
                } else {
                    predictionList.style.display = 'none';
                }
            },
            { signal: abortController.current.signal }
        );

        autocompleteFormField.addEventListener(
            'focus',
            () => {
                if (autocompleteFormField.value) {
                    predictionList.style.display = 'block';
                }
            },
            { signal: abortController.current.signal }
        );

        window.addEventListener(
            'click',
            (e) => {
                if (!parentNode.contains(e.target)) {
                    predictionList.style.display = 'none';
                }
            },
            { signal: abortController.current.signal }
        );
    };

    const predictionListMarkup = () => {
        const predictionsWrapperDiv = document.createElement('ul');
        predictionsWrapperDiv.classList.add('klickly-autocomplete-container');
        predictionsWrapperDiv.style.display = 'none';
        return predictionsWrapperDiv;
    };

    const displayPredictionSuggestions = (predictions, status, predictionList, autocompleteFormField, onFillData) => {
        // If the service is down, do not return results.
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            predictionList.style.display = 'none';
            return;
        }

        // remove old items
        while (predictionList.firstChild) {
            predictionList.removeChild(predictionList.firstChild);
        }

        // create new items
        for (const prediction of predictions) {
            predictionBuilder(prediction, predictionList, autocompleteFormField, onFillData);
        }
    };

    const predictionBuilder = (prediction, predictionList, autocompleteFormField, onFillData) => {
        const predictionListItem = document.createElement('li');
        predictionListItem.classList.add('klickly-autocomplete-item');
        predictionListItem.appendChild(document.createTextNode(prediction.description));
        predictionListItem.addEventListener('click', () => {
            autocompleteServiceListener(prediction, predictionList, autocompleteFormField, onFillData);
        });
        predictionList.appendChild(predictionListItem);
    };

    const autocompleteServiceListener = (prediction, predictionList, autocompleteFormField, onFillData) => {
        const service = new window.google.maps.places.PlacesService(autocompleteFormField);
        service.getDetails({ placeId: prediction.place_id }, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                // hide suggestions and fill fields
                predictionList.style.display = 'none';
                onPlaceClick(place, onFillData);
            }
        });
    };

    const onPlaceClick = (place, onFillData) => {
        let city = '';
        let hasCity = false;
        let hasSubLoc = false;
        let state = '';
        let nbhd = '';
        let subLoc = '';
        let zipCode = '';
        let address1 = '';

        /*eslint-disable*/
        place.address_components.forEach(({ types, long_name }) => {
            if (types.includes("locality")) {
                city = long_name;
                hasCity = true;
            }
            if (types.includes("neighborhood")) {
                nbhd = long_name;
            }
            if (types.includes("sublocality")) {
                subLoc = long_name;
                hasSubLoc = true;
            }
            if (types.includes("administrative_area_level_1")) {
                state = long_name;
            }
            if (types.includes("postal_code")) {
                zipCode = long_name;
            }
            if (types.includes("street_number")) {
                address1 = long_name;
            }
            if (types.includes("route")) {
                address1 += " " + long_name;
            }
        });

        let realCity = "";

        if (hasCity) {
            realCity = city;
        } else if (hasSubLoc) {
            realCity = subLoc;
        } else {
            realCity = nbhd;
        }

        onFillData({
            province: state,
            city: realCity,
            zipCode,
            address1,
        });
    };

    const unsubscribe = () => {
        abortController.current.abort();
    };

    return { loadScript, initAutocomplete, unsubscribe };
};
