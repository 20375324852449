import React from 'react';
import {
    BathroomShowerTubIcon,
    BikeIcon,
    BottleIcon,
    Component5Icon,
    DiamondIcon,
    FeatherIcon,
    GymWeightIcon,
    HairDryerIcon,
    HangersIcon,
    HomeIcon,
    Icon,
    LipsIcon,
    PetDogIcon,
    Taxonomy
} from '@klickly/front-packages';

const { PSEUDO_ROOT_CATEGORIES_KEYS } = Taxonomy;
export const categories = [
    {
        icon: <Icon size='24px' component={() => <LipsIcon />} />,
        title: 'Makeup',
        value: PSEUDO_ROOT_CATEGORIES_KEYS.MAKEUP,
        text: 'Make-up, cosmetics'
    },
    {
        icon: <Icon size='24px' component={() => <HairDryerIcon />} />,
        title: 'Hair Care',
        value: PSEUDO_ROOT_CATEGORIES_KEYS.HAIR_CARE,
        text: 'Shampoos, conditioners'
    },
    {
        icon: <Icon size='24px' component={() => <FeatherIcon />} />,
        title: 'Skin Care',
        value: PSEUDO_ROOT_CATEGORIES_KEYS.SKIN_CARE,
        text: 'Facial creams, treatments'
    },
    {
        icon: <Icon size='24px' component={() => <BathroomShowerTubIcon />} />,
        title: 'Personal care',
        value: PSEUDO_ROOT_CATEGORIES_KEYS.BATH_BODY,
        text: 'Bath & body'
    },
    {
        icon: <Icon size='24px' component={() => <BottleIcon />} />,
        title: 'Wellness',
        value: 'Health & Beauty > Health Care > Fitness & Nutrition',
        text: 'Vitamins, immune support'
    },
    {
        icon: <Icon size='24px' component={() => <BikeIcon />} />,
        title: 'Baby',
        value: 'Baby & Toddler',
        text: 'Clothes & toys'
    },
    {
        icon: <Icon size='24px' component={() => <GymWeightIcon />} />,
        title: 'Fitness',
        value: 'Sporting Goods',
        text: 'Workout accessories'
    },
    {
        icon: <Icon size='24px' component={() => <DiamondIcon />} />,
        title: 'Jewelry',
        value: PSEUDO_ROOT_CATEGORIES_KEYS.JEWELRY,
        text: 'Necklaces, rings, bracelets'
    },
    {
        icon: <Icon size='24px' component={() => <Component5Icon />} />,
        title: 'Gourmet',
        value: 'Food, Beverages & Tobacco',
        text: 'Food & beverage'
    },
    {
        icon: <Icon size='24px' component={() => <HomeIcon />} />,
        title: 'Home & Garden',
        value: 'Home & Garden',
        text: 'Frames, plants, throws'
    },
    {
        icon: <Icon size='24px' component={() => <PetDogIcon />} />,
        title: 'Pet',
        value: 'Animals & Pet Supplies',
        text: 'Food, toys, treats'
    },
    {
        icon: <Icon size='24px' component={() => <HangersIcon />} />,
        title: 'Fashion',
        value: PSEUDO_ROOT_CATEGORIES_KEYS.CLOTHING,
        text: 'Clothing, hats, & handbags'
    }
];
