import { PROMOTIONS_PRICE_STATE } from '../../shared';

export const INITIAL_STORES = {
    PDP: {
        showPDP: false,
        customizationsKey: 'suite'
    },
    MARKETPLACE: {
        promotions: [],
        pending: false,
        brandsPending: false,
        error: null,
        brandsError: null,
        page: 1,
        nextPage: 0,
        price: { ...PROMOTIONS_PRICE_STATE },
        isOpenOnMobile: false,
        strategyInfo: null,
        strategyPending: false
    },
    REWARDS: {
        pending: false,
        error: null,
        rewards: null,
        hasNextPage: false,
        updateRewardPending: false,
        updateRewardError: null
    },
    RECOMMENDED_PROMOTIONS: {
        pending: false,
        error: null,
        recommendedPromotions: []
    },
    FAVORITES_PROMOTIONS: {
        pending: false,
        error: null,
        favoritesPromotions: []
    },
    UPSELL: {
        pending: false,
        error: null,
        data: null
    },
    DEALS: {
        holidayDealsPromotionsLoading: false,
        holidayDealsPromotionsError: null,
        holidayDealsPromotions: [],
        dayDealsPromotionsLoading: false,
        dayDealsPromotionsError: null,
        dayDealsPromotions: [],
        bestWelcomeGiftOffersLoading: false,
        bestWelcomeGiftOffersError: null,
        bestWelcomeGiftOffers: [],
        strategyInfoLoading: false,
        strategyInfoError: null,
        strategyInfo: null
    },
    DASHBOARD: {
        pending: false,
        error: null,
        recentlyViewedPromotions: []
    },
    BRAND_PAGE: {
        pending: false,
        error: null,
        brandData: null,
        promotionsPending: false,
        promotions: [],
        page: 1,
        nextPage: 0,
        isOpenOnMobile: false
    }
};
