import { Button } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $background, $primaryText, $secondaryText, $white } from '../../../../../../../../shared';

export const StyledButton = styled(Button)`
    && {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 51px;
        padding: 8px 16px;
        background: ${$background};

        > .ant-row {
            width: 100%;
            flex-wrap: nowrap;
        }

        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .anticon {
            width: 24px;
            height: 24px;
            svg {
                font-size: 24px;
                color: ${$secondaryText};

                ${({ $type }) =>
                    $type === 'delete' &&
                    css`
                        color: ${$primaryText};
                    `}
            }
        }

        &:hover,
        &:focus,
        &:active {
            background: ${$background};
        }
    }
`;

export const StyledTextWrapper = styled.div`
    width: calc(100% - 30px);
`;

export const StyledCopiedMessage = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${$white};
    opacity: 0.85;
`;
