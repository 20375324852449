import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../../../utils';

export const StyledWrapper = styled.div`
    margin-bottom: 88px;

    @media (${GRID_DEVICES_MAX.md}) {
        margin-bottom: 72px;
    }

    && {
        .ant-btn {
            padding-left: 0;

            .anticon {
                width: 24px;
                height: 24px;

                > svg {
                    font-size: 24px;
                }
            }
        }
    }
`;
