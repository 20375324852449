import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../shared';
import { shopBlocks } from '../../contants';
import {
    StyledBlockContent,
    StyledBlockImg,
    StyledBlockTextWrap,
    StyledBlockWrap,
    StyledContent,
    StyledWrapper
} from './styles';

export const ShopSection = observer(() => {
    const navigate = useNavigate();
    const onButtonClick = () => {
        navigate(ROUTE_PATHS.shop);
    };

    return (
        <StyledWrapper>
            <StyledContent>
                {shopBlocks.map(({ title, subtitle, src }) => (
                    <StyledBlockWrap key={title}>
                        <StyledBlockImg src={src} />
                        <StyledBlockContent>
                            <StyledBlockTextWrap>
                                <Typography.Text type='badgeMedium' color='canvas'>
                                    {subtitle}
                                </Typography.Text>
                                <Typography.Title level={2} color='canvas'>
                                    {title}
                                </Typography.Title>
                            </StyledBlockTextWrap>
                            <Button type='primary-inverted' ghost onClick={onButtonClick}>
                                <Typography.Text type='badgeMedium'>Shop now</Typography.Text>
                            </Button>
                        </StyledBlockContent>
                    </StyledBlockWrap>
                ))}
            </StyledContent>
        </StyledWrapper>
    );
});
