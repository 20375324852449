import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../../../../../shared';
import { TEXTS } from '../constants';

export const usePageTextsAndCallbacks = ({
    isLoggedIn,
    signUpStatus,
    claimsCount,
    launchSuite,
    isRejectedCustomer,
    onAfterLaunchSuccess
}) => {
    const navigate = useNavigate();

    if (isLoggedIn && signUpStatus !== 'rejected') {
        return claimsCount === 0
            ? {
                  ...TEXTS.CLOSED_SUITE,
                  onLaunch: () => navigate(ROUTE_PATHS.subscriptions)
              }
            : { ...TEXTS.AUTHORIZED, onLaunch: () => launchSuite(onAfterLaunchSuccess) };
    }

    return {
        ...TEXTS.UNAUTHORIZED,
        onLaunch: () => navigate(isRejectedCustomer ? ROUTE_PATHS.waitlisted : ROUTE_PATHS.onboarding)
    };
};
