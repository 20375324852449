import styled, { keyframes } from 'styled-components';
import { $primary } from '../../index';

const rotate1 = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(540deg); }
`;

const rotate2 = keyframes`
    0% { transform: rotate(90deg); }
    100% { transform: rotate(540deg); }
`;

const move = keyframes`
    0% { transform: translateY(100%); }
    100% { transform: translateY(0%); }
`;

export const BoxLoaderWrapperStyles = styled.div`
    width: ${({ size }) => size || '160px'};
    height: ${({ size }) => size || '160px'};
    min-height: ${({ size }) => size || '160px'};
    background-color: #ffffff;
    z-index: 5;

    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: ${({ $withShadow }) =>
        $withShadow ? '0px 8px 48px rgba(0, 0, 0, 0.16), 0px 4px 8px rgba(0, 0, 0, 0.12)' : 'none'};
    border-radius: 50%;
`;

export const Wave1WrapStyles = styled.div`
    width: 200%;
    height: 200%;
    position: absolute;
    left: -50%;
    bottom: -50%;
    animation-name: ${move};
    animation-duration: ${({ $animationDuration }) => $animationDuration || '5s'};
    animation-iteration-count: ${({ $infinite }) => ($infinite ? 'infinite' : '1')};
`;

export const Wave1Styles = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${({ color }) => color || $primary};
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 30%;
    animation: ${rotate1} 6s linear infinite;
    z-index: 1;
`;

export const Wave2WrapStyles = styled.div`
    width: 200%;
    height: 200%;
    position: absolute;
    left: -50%;
    bottom: -50%;
    animation-name: ${move};
    animation-duration: ${({ $animationDuration }) => $animationDuration || '5s'};
    animation-iteration-count: ${({ $infinite }) => ($infinite ? 'infinite' : '1')};
`;

export const Wave2Styles = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${({ color }) => color || $primary};
    position: absolute;
    opacity: 0.2;
    left: 0;
    bottom: 0;
    border-radius: 30%;
    animation: ${rotate2} 6s linear infinite;
    z-index: 2;
`;
