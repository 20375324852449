import { types } from 'mobx-state-tree';
import { PriceDetailsModel } from './priceDetailsModel';

export const OrderDetailsModel = types.maybeNull(
    types.model({
        _id: types.identifier,
        account: types.maybeNull(types.string),
        cartId: types.maybeNull(types.string),
        createdAt: types.maybeNull(types.string),
        updatedAt: types.maybeNull(types.string),
        totalAmount: types.maybeNull(types.number),
        totalAmountRefunded: types.maybeNull(types.number),
        uuid: types.maybeNull(types.string),
        cart: types.maybeNull(
            types.model({
                _id: types.identifier,
                createdAt: types.maybeNull(types.string),
                updatedAt: types.maybeNull(types.string),
                bags: types.array(
                    types.model({
                        _id: types.identifier,
                        priceDetails: PriceDetailsModel,
                        createdAt: types.maybeNull(types.string),
                        updatedAt: types.maybeNull(types.string),
                        orderNumber: types.maybeNull(types.string),
                        merchantName: types.maybeNull(types.string),
                        merchantId: types.maybeNull(types.string),
                        pointsEarned: types.maybeNull(types.number),
                        items: types.array(
                            types.model({
                                _id: types.identifier,
                                productId: types.maybeNull(types.string),
                                image: types.maybeNull(types.string),
                                qty: types.maybeNull(types.number),
                                variantTitle: types.maybeNull(types.string),
                                productTitle: types.maybeNull(types.string),
                                priceDetails: PriceDetailsModel,
                                metadata: types.array(
                                    types.model({
                                        name: types.maybeNull(types.string),
                                        value: types.frozen(),
                                        details: types.maybeNull(
                                            types.model({
                                                list: types.array(
                                                    types.model({
                                                        type: types.maybeNull(types.string),
                                                        value: types.maybeNull(types.union(types.string, types.number))
                                                    })
                                                )
                                            })
                                        )
                                    })
                                )
                            })
                        )
                    })
                ),
                discountsAllocation: types.maybeNull(types.frozen())
            })
        )
    })
);
