import styled from 'styled-components';
import { $white } from '../../constants';

export const StyledButtonsWrapper = styled.div`
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.12);
    background: ${$white};
    z-index: 10;
`;
