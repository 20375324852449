import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Icon, Promotion, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import placeholder from '../../../../../../../../assets/img/image-placeholder.svg';
import { useModal } from '../../../../../../../../shared';
import CouponBanner from '../../../../../../../../shared/components/CouponBanner';
import { GRID_POINTS } from '../../../../../../../../utils';
import RewardNotAvailableModalContent from './components/RewardNotAvailableModalContent';
import SignInModalContent from './components/SignInModalContent';
import { MODAL_PARAMS } from './constants';
import { StyledBannerWrapper } from './styled';

const ProductOrBanner = inject('store')(
    observer(({ store, promotion }) => {
        const navigate = useNavigate();
        const { search } = useLocation();
        const { activeChallengeName } = store.marketplaceStore;
        const { isLoggedIn } = store.accountStore;
        const { setIsPageReloaded } = store.pdpStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { openModal, closeModal } = useModal();

        const goToPdp = () => {
            setIsPageReloaded(false);
            navigate(
                {
                    pathname: `/marketplace/${promotion._id}`,
                    search: search
                },
                { state: { activeChallengeName, couponUUID: promotion?.couponUUID } }
            );
        };

        const handleOnBuy = () => {
            store.trackingStore.trackClick(promotion);

            if (!isLoggedIn && promotion.hasReward) {
                return promotion.getIsRewardAvailable((isAvailable) =>
                    isAvailable
                        ? goToPdp()
                        : openModal({
                              children: <SignInModalContent onCloseModal={closeModal} />,
                              ...MODAL_PARAMS
                          })
                );
            }
            if (promotion.hasReward) {
                return promotion.getIsRewardAvailable((isAvailable) =>
                    isAvailable
                        ? goToPdp()
                        : openModal({
                              children: <RewardNotAvailableModalContent onCloseModal={closeModal} />,
                              ...MODAL_PARAMS
                          })
                );
            }

            return goToPdp();
        };

        return promotion.isBanner ? (
            <Col xs={24} sm={24} md={12} xl={8} key={promotion._id}>
                <StyledBannerWrapper>
                    <CouponBanner height={simple ? null : '100%'} isMobileView type={promotion?.bannerType} />
                </StyledBannerWrapper>
            </Col>
        ) : (
            <Col xs={12} sm={12} md={12} xl={8} key={promotion._id}>
                {promotion.pending ? <Icon.Loader /> : null}
                <Promotion placeholder={placeholder} height='100%' onBuy={handleOnBuy} promotion={promotion} />
            </Col>
        );
    })
);

export default ProductOrBanner;
