import React, { Fragment } from 'react';
import { CouponDescription, CouponDetails, CouponImgWrap, CouponTitle } from './styles';

export const KliActiveCouponContent = ({
    img,
    title,
    description,
    showFullDescription,
    additionalDetailsContent = null,
    additionalDetailsContentOnTop,
    primary = false,
    themeContext
}) => {
    return (
        <Fragment>
            {img ? <CouponImgWrap>{img}</CouponImgWrap> : null}
            <CouponDetails>
                <div>
                    <CouponTitle level={3} color={themeContext.colors.primaryText}>
                        {title}
                    </CouponTitle>
                    {additionalDetailsContentOnTop ? additionalDetailsContent : null}
                    <CouponDescription $showFullDescription={showFullDescription} type='body2'>
                        {description}
                    </CouponDescription>
                </div>
                {!additionalDetailsContentOnTop ? additionalDetailsContent : null}
            </CouponDetails>
        </Fragment>
    );
};
