import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $background, $white } from '../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const StyledWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${$background};

    @media (${GRID_DEVICES_MAX.sm}) {
        background: ${$white};
    }
`;

export const StyledTitle = styled(Typography.Title)`
    margin: 40px 0;

    @media (${GRID_DEVICES_MAX.sm}) {
        margin: 24px 0 4px;
        font-size: 24px;
        line-height: 36px;
    }
`;

export const StyledSubtitle = styled(Typography.Title)`
    margin-bottom: 40px;

    @media (${GRID_DEVICES_MAX.sm}) {
        margin-bottom: 24px;
        font-size: 20px;
        line-height: 28px;
    }
`;

export const StyledDescription = styled(Typography.Text)`
    margin-bottom: 40px;
    font-weight: 500;
    text-align: center;

    @media (${GRID_DEVICES_MAX.sm}) {
        font-weight: normal;
    }
`;
