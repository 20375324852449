import styled from 'styled-components';
import { $tartRed } from '../../../../../../../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../../../../../../../utils';

export const StyledHeader = styled.div`
    padding: 24px;
    background: ${$tartRed};
`;

export const StyledContent = styled.div`
    padding: 48px;

    &&& {
        .ant-btn {
            .anticon {
                width: 24px;
                height: 24px;
                margin-left: 16px;

                > svg {
                    font-size: 24px;
                }
            }
        }
    }

    @media (${GRID_DEVICES_MAX.md}) {
        padding: 40px 24px;
    }
`;
