import React from 'react';
import { ActionsMinusIcon, ActionsPlusIcon, Collapse, Panel, Typography } from '@klickly/front-packages';
import { Body, StyledIcon, StyledPanelHead, StyledWrap } from './styled';

export const Filter = ({ title, children, simple }) => {
    return (
        <StyledWrap>
            <Collapse
                withoutPadding
                bordered={false}
                expandIcon={({ isActive }) => (
                    <StyledIcon component={() => (isActive ? <ActionsMinusIcon /> : <ActionsPlusIcon />)} />
                )}
                expandIconPosition='end'
                defaultActiveKey={simple ? ['0'] : []}
            >
                <Panel
                    header={
                        <StyledPanelHead>
                            <Typography.Text type='headline3' style={{ padding: '16px 0' }}>
                                {title}
                            </Typography.Text>
                        </StyledPanelHead>
                    }
                >
                    <Body>{children}</Body>
                </Panel>
            </Collapse>
        </StyledWrap>
    );
};
