import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI } from '../shared';

export const ClaimsPointsStore = types
    .model('ClaimsPointsStore', {
        claims: types.maybeNull(types.number),
        prevPoints: types.maybeNull(types.number),
        points: types.maybeNull(types.number),
        pending: false,
        claimsErrorMessage: types.maybeNull(types.string),
        pointsErrorMessage: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },

        get claimsCount() {
            return typeof self.claims === 'number' ? self.claims : 0;
        }
    }))
    .actions((self) => {
        const setPending = (pending) => {
            self.pending = pending;
        };

        const setClaimsError = (error) => {
            if (error && error.response && error.response.data) {
                self.errorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.errorMessage = error.message;
            }
        };

        const setPointsError = (error) => {
            if (error && error.response && error.response.data) {
                self.errorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.errorMessage = error.message;
            }
        };

        const getClaims = flow(function* getClaims() {
            setPending(true);
            try {
                const { customerId, isApprovedCustomer } = self.root.accountStore;
                if (customerId && isApprovedCustomer) {
                    const { claims } = yield self.root.get(kcpAPI.customerData.getBalance('claims'));
                    self.claims = claims;
                }
            } catch (e) {
                setClaimsError(e);
            } finally {
                setPending(false);
            }
        });

        const getPoints = flow(function* getPoints(initialFetch = true) {
            setPending(true);
            try {
                const { points } = yield self.root.get(kcpAPI.customerData.getBalance('points'));
                self.points = points;
                if (!self.prevPoints && initialFetch) self.prevPoints = points;
            } catch (e) {
                setPointsError(e);
            } finally {
                setPending(false);
            }
        });

        const setPrevPoints = (points) => {
            self.prevPoints = points;
        };

        return {
            getClaims,
            getPoints,
            setPrevPoints
        };
    });
