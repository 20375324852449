import React, { useCallback, useEffect, useState } from 'react';
import { ArrowChevronDownIcon, CheckIcon, Icon, Menu, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { Body, Item, Styles, Target } from './styled';

export const Sorting = inject('store')(
    observer(({ store }) => {
        const [state, setState] = useState();
        const {
            query: { sort },
            selectSorting
        } = store.brandPageStore;

        useEffect(() => {
            const selected = sort.find((item) => item.checked);

            setState(selected);
        }, [sort, state, setState]);

        const onSelected = useCallback(
            (newState) => {
                if (newState.key === state.key) {
                    return;
                }

                setState(newState);
                selectSorting(newState);
            },
            [state, selectSorting]
        );

        return (
            <Styles>
                <Menu.Dropdown
                    dropdownRender={(menus) => <Body>{menus}</Body>}
                    items={sort.map((item) => ({
                        key: item.key,
                        label: (
                            <Item onClick={() => onSelected({ ...item, checked: true })}>
                                <div>
                                    <Typography.Text type='badgeMedium' as='span'>
                                        {item.type}
                                    </Typography.Text>
                                    &nbsp;
                                    <Typography.Text type='descriptor1' as='span'>
                                        {item.name}
                                    </Typography.Text>
                                    &nbsp;
                                </div>
                                {item.checked && <Icon size='14px' component={() => <CheckIcon />} />}
                            </Item>
                        )
                    }))}
                >
                    <Target>
                        <Typography.Text type='badgeMedium' as='span'>
                            {state?.type}
                        </Typography.Text>
                        &nbsp;
                        <Typography.Text type='descriptor1' as='span'>
                            {state?.name}
                        </Typography.Text>
                        &nbsp;
                        <Icon size='14px' component={() => <ArrowChevronDownIcon />} />
                    </Target>
                </Menu.Dropdown>
            </Styles>
        );
    })
);
