import React, { Fragment } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { EmojiSadFrownIcon, Icon, Search, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../utils';
import { CheckedFilters } from './components/CheckedFilters';
import { Promotions } from './components/Promotions';
import { StrategyBlock } from './components/StrategyBlock';
import { useLayoutOnBottomHandler, useMarketplaceNotifications } from './hooks';
import { Message, StyledMobileSearchWrapper, Styles } from './styles';

const { Typos } = Search;

const PromotionsPage = inject('store')(
    observer(({ store }) => {
        const location = useLocation();
        const navigationType = useNavigationType();
        const { getPromotions, nextPage, pending, totalHits } = store.marketplaceStore;

        const simple = useSimpleInterface(GRID_POINTS.md);

        useLayoutOnBottomHandler(getPromotions, nextPage, pending);

        useMarketplaceNotifications(store);

        return (
            <Fragment>
                {pending && <Icon.Loader style={{ zIndex: 999 }} />}
                <Styles id='shop-promotions-container'>
                    {simple ? (
                        <StyledMobileSearchWrapper>
                            <Search location={location} navigationType={navigationType} />
                        </StyledMobileSearchWrapper>
                    ) : null}
                    <StrategyBlock />

                    <Typos />
                    <CheckedFilters simple={simple} />
                    <Promotions />
                    {!pending && totalHits === 0 && (
                        <Message>
                            <Icon size='24px' component={() => <EmojiSadFrownIcon />} />
                            <Typography.Text>Sorry, nothing found</Typography.Text>
                        </Message>
                    )}
                </Styles>
            </Fragment>
        );
    })
);

export default PromotionsPage;
