import React from 'react';
import { CloseModalIcon } from '../CloseModalButton';
import { ConfirmDeleting } from './components/ConfirmDeleting';
import { StyledModalWrapper } from './styles';

export const ConfirmDeletingModal = ({ title, bodyText, onConfirm, onClose, simple, ...rest }) => {
    return (
        <StyledModalWrapper {...rest}>
            {!simple ? <CloseModalIcon onClick={onClose} /> : null}
            <ConfirmDeleting
                title={title}
                bodyText={bodyText}
                onConfirm={onConfirm}
                onClose={onClose}
                simple={simple}
            />
        </StyledModalWrapper>
    );
};
