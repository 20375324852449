import React from 'react';
import { Col, Icon, InfoIcon, Row, Typography } from '@klickly/front-packages';
import { StyledButton } from './styled';

const ActionButton = ({ onClick, icon = false, isRed, hasBackground, children, gutter = 8, ...props }) => {
    const IconComponent = typeof icon === 'function' ? icon : InfoIcon;
    return (
        <StyledButton type='unstyled' onClick={onClick} $isRed={isRed} $hasBackground={hasBackground} {...props}>
            <Row align='middle' justify='center' gutter={gutter}>
                {icon ? (
                    <Col>
                        <Icon size='16px' component={() => <IconComponent />} />
                    </Col>
                ) : null}
                <Col>
                    <Typography.Text type='badgeSmall'>{children}</Typography.Text>
                </Col>
            </Row>
        </StyledButton>
    );
};

export default ActionButton;
