import React from 'react';
import { CloseXIcon } from '@klickly/front-packages';
import { StyledCloseModalIcon } from './styles';

export const CloseModalIcon = (props) => {
    return (
        <StyledCloseModalIcon size='24px' roundedSize='200%' color='canvas' component={() => <CloseXIcon />} {...props}>
            <CloseXIcon />
        </StyledCloseModalIcon>
    );
};
