import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { GRID_POINTS, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../../../../../shared';
import { InviteFriendTaskHeader } from '../InviteFriendTaskHeader';
import { InviteFriendTaskRequestInvitations } from './components/InviteFriendTaskRequestInvitations';
import { InviteFriendTaskResults } from './components/InviteFriendTaskResults';
import { InviteFriendTaskSendInvite } from './components/InviteFriendTaskSendInvite';
import { TaskInitialContentStyles } from './styles';

export const InviteFriendTaskInitialContent = observer(({ onClose, setSubmitted }) => {
    const { pathname } = useLocation();
    const simple = useSimpleInterface(GRID_POINTS.md);
    const fromGS = pathname.includes(ROUTE_PATHS.suiteGs);

    return (
        <Fragment>
            <InviteFriendTaskHeader showSubtitle={!fromGS} simple={simple} onClose={onClose} />
            <TaskInitialContentStyles>
                <InviteFriendTaskSendInvite setSubmitted={setSubmitted} />
                <InviteFriendTaskRequestInvitations />
                <InviteFriendTaskResults />
            </TaskInitialContentStyles>
        </Fragment>
    );
});
