import { ROUTE_PATHS } from '../../../constants';

export const FOOTER_ITEMS = [
    {
        text: 'Terms',
        to: ROUTE_PATHS.terms,
        key: 'kli-fi-2'
    },
    {
        text: 'Privacy',
        to: ROUTE_PATHS.privacy,
        key: 'kli-fi-3'
    },
    {
        text: 'Do Not Sell/Share My Personal Information',
        to: ROUTE_PATHS.helpPagePrivacyOptOut,
        key: 'kli-fi-4'
    },
    {
        text: 'Limit the Use of My Sensitive Personal Information',
        to: ROUTE_PATHS.helpPageDisclosureSensitiveInfo,
        key: 'kli-fi-5'
    },
    {
        text: 'FAQ',
        to: ROUTE_PATHS.helpCenter,
        key: 'kli-fi-6'
    }
];
