import styled from 'styled-components';

export const StyledTaskRow = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 24px;
`;

export const StyledWrapper = styled.div`
    position: relative;
    height: 100%;
    overflow: hidden;
`;
