import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI } from '../shared';

export const ShoppingCartStore = types
    .model('ShoppingCartStore', {
        amount: types.optional(types.number, 0),
        pending: false,
        errorMessage: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const setPending = (pending) => {
            self.pending = pending;
        };

        const setError = (error) => {
            if (error && error.response && error.response.data) {
                self.errorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.errorMessage = error.message;
            }
        };

        const getShoppingCartState = flow(function* getCartState(onAfterSuccess) {
            setPending(true);
            try {
                const { amount } = yield self.root.get(kcpAPI.cart.getItemsAmount);
                self.amount = amount;
                if (typeof onAfterSuccess === 'function') onAfterSuccess();
            } catch (e) {
                setError(e);
            } finally {
                setPending(false);
            }
        });

        return {
            getShoppingCartState
        };
    });
