import { Button, Icon } from '@klickly/front-packages';
import styled from 'styled-components';
import { $grayScale40Black } from '../../../../../../../../../../../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../../../../../../../../../../../utils';

export const InvitationItemStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
`;

export const StyledContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: fit-content;
`;

export const StyledCancelButton = styled(Button)`
    && {
        margin-right: 20px;
        color: ${$grayScale40Black};

        @media (${GRID_DEVICES_MAX.md}) {
            padding: 0;

            span {
                font-size: 30px;
            }

            svg {
                width: 30px;
                height: 30px;
            }
        }
    }
`;

export const StyledStatus = styled.div`
    display: flex;
    align-items: center;
    color: ${({ $color }) => $color || 'inherit'};
    font-weight: 500;
    text-transform: capitalize;

    > span {
        margin-left: 12px;
    }
`;

export const StyledInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 200px);
    overflow: hidden;

    .anticon {
        margin: 0 32px 0 12px;
    }

    h3,
    p {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    > div {
        width: calc(100% - 60px);
    }

    @media (${GRID_DEVICES_MAX.md}) {
        width: calc(100% - 130px);

        > div {
            width: 100%;
        }
    }
`;

export const StyledLoader = styled(Icon.Loader)`
    position: static;

    > span {
        font-size: inherit;
    }
`;
