import styled, { css } from 'styled-components';

export const StyledWrapper = styled.div`
    width: 413px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: ${({ $pointer }) => ($pointer ? 'pointer' : 'auto')};
    padding: 20px 0;

    ${({ $disabled }) =>
        $disabled
            ? css`
                  opacity: 0.7;
                  pointer-events: none;
              `
            : ''}
`;

export const StyledLeftContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 8px;
`;

export const StyledTextWrapper = styled.div``;

export const StyledIconWrap = styled.div`
    padding: 20px;

    ${({ $disabled }) =>
        $disabled
            ? css`
                  -webkit-filter: grayscale(100%);
                  filter: grayscale(100%);
              `
            : ''}
`;
