import { Button } from '@klickly/front-packages';
import styled, { css } from 'styled-components';

export const Styles = styled.div`
    display: flex;
    align-items: center;

    ${({ $alignContent }) =>
        $alignContent &&
        css`
            justify-content: ${$alignContent};
        `};
`;

export const StyledButton = styled(Button)`
    && {
        padding: 0;
        margin: 0 5px;
    }
`;
