import styled, { css } from 'styled-components';

export const StyledWrap = styled.div`
    width: 100%;
    display: flex;

    & > div {
        margin-bottom: 16px;
    }

    ${({ $position }) =>
        $position === 'side'
            ? css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
              `
            : ''}

    ${({ $position }) =>
        $position === 'main'
            ? css`
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
              `
            : ''}
`;

export const StyledLoaderWrap = styled.div`
    position: relative;
    width: 100%;
    height: 300px;
`;
