import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatPoints, PointsIcon, Typography } from '@klickly/front-packages';
import { $secondaryText } from '../../../../../../shared';
import ActionButton from '../../../ActionButton';
import { StyledImg, StyledImgWrapper, StyledNoImg } from './styled';

const BrandCard = ({ imgSrc, brandName = '', pointsValue = 50, id }) => {
    const navigate = useNavigate();
    return (
        <Fragment>
            <StyledImgWrapper>
                {imgSrc ? (
                    <StyledImg src={imgSrc} alt={brandName} />
                ) : (
                    <StyledNoImg>
                        <div>
                            <Typography.Title align='center' color={$secondaryText}>
                                {brandName}
                            </Typography.Title>
                        </div>
                    </StyledNoImg>
                )}
            </StyledImgWrapper>
            <ActionButton
                isRed
                hasBackground
                icon={PointsIcon}
                gutter={2}
                block
                onClick={() => navigate(`/brands/${id}`)}
            >
                Up to {formatPoints(pointsValue)}
            </ActionButton>
        </Fragment>
    );
};

export default BrandCard;
