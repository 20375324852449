import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../shared';
import { StyledButton } from './styled';

const HowItWorksBtn = observer(() => {
    const navigate = useNavigate();
    return (
        <div>
            <StyledButton onClick={() => navigate(ROUTE_PATHS.inviteAndEarnRules)} type='unstyled'>
                <Typography.Text type='body1'>How Refer & Earn works?</Typography.Text>
            </StyledButton>
        </div>
    );
});

export default HowItWorksBtn;
