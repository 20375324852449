import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../../../utils';

export const StyledBannerWrapper = styled.div`
    height: 100%;
    padding: 20px;

    @media (${GRID_DEVICES_MAX.md}) {
        padding: 0;
    }
`;
