import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const FaqStyles = styled.div`
    max-width: 846px;
    margin: 120px auto;

    @media (${GRID_DEVICES_MAX.md}) {
        margin: 40px auto;
    }
`;

export const FaqContentStyles = styled.div`
    padding: 0 24px;
`;

export const StyledText = styled(Typography.Text)`
    padding: 44px 0;

    @media (${GRID_DEVICES_MAX.md}) {
        padding: 24px 0;
    }
`;
