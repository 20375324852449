import { Button } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $primary, $tartRed } from '../../../../shared';

export const StyledButton = styled(Button)`
    && {
        padding: 14px 16px;
        ${({ $isRed }) =>
            $isRed &&
            css`
                color: ${$primary};
                z-index: 5;

                &:hover {
                    color: ${$primary};
                }

                * {
                    color: ${$primary};
                }

                ${({ $hasBackground }) =>
                    $hasBackground &&
                    css`
                        background: ${$tartRed};

                        &:hover {
                            color: ${$primary};
                            background: ${$tartRed};
                        }
                    `};
            `}
    }
`;
