import { Button, Form, Icon } from '@klickly/front-packages';
import styled from 'styled-components';
import { $border, $white } from '../../../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../../../utils';

export const StyledForm = styled(Form)`
    @media (${GRID_DEVICES_MAX.xs}) {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
`;

export const StyledContainer = styled.div`
    width: 100%;
    @media (${GRID_DEVICES_MAX.xs}) {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
`;

export const ButtonWrap = styled.div`
    @media (${GRID_DEVICES_MAX.xs}) {
        margin-top: auto;
        position: sticky;
        bottom: 0;
        padding: 24px 0;
        background-color: ${$white};
        z-index: 3;

        :after {
            content: '';
            border-top: 1px solid ${$border};
            display: block;
            position: absolute;
            width: calc(100% + 48px);
            left: -24px;
            top: 0;
        }
    }
`;

export const StyledButton = styled(Button)`
    && {
        padding-left: 50px;
        padding-right: 50px;
        @media (${GRID_DEVICES_MAX.xs}) {
            width: 100%;
        }
    }
`;

export const StyledIcon = styled(Icon)`
    &.anticon {
        height: 61px;
        line-height: 61px;
    }
`;
