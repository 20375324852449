import { Button } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../../../utils';

export const Styles = styled.div`
    padding: 0 5px 50px 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @media (${GRID_DEVICES_MAX.md}) {
        text-align: center;
        align-items: center;
    }
`;

export const StyledButton = styled(Button)`
    && {
        padding: 0;
        margin: 10px auto;

        &:focus,
        &:active {
            border: none;
        }
    }
`;
