import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX, GRID_DEVICES_MIN } from '../../../../../utils';
import { $bordered, $white } from '../../../../constants';

export const StyledHeader = styled.header`
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: ${({ $singleBar }) => ($singleBar ? 80 : 160)}px;

    @media (${GRID_DEVICES_MAX.md}) {
        height: 80px;
    }

    ${({ $stickyPosition }) =>
        $stickyPosition
            ? css`
                  position: sticky;
                  top: 0;
              `
            : ''}
`;

export const StyledBar = styled.div`
    height: 80px;
    background: ${({ $background = $white }) => $background};
    border-bottom: ${({ $singleBar }) => ($singleBar ? `1px solid ${$bordered}` : 'none')};
`;

export const LinkWrap = styled.div`
    width: 100%;

    a {
        width: 80px;
    }

    @media (${GRID_DEVICES_MIN.md}) {
        max-width: 315px;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        width: 80px;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 0 20px;

    @media (${GRID_DEVICES_MAX.md}) {
        border-bottom: 1px solid ${$bordered};
    }
`;

export const StyledMenuWrapper = styled.div`
    flex-grow: 1;
`;
