import { Image } from '@klickly/front-packages';
import styled, { createGlobalStyle, css } from 'styled-components';

export const StyledWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

export const StyledImage = styled(Image)`
    && {
        width: 80px;
        height: 80px;
        object-fit: contain;
        margin-right: 12px;
    }
`;

export const StyledContent = styled.div`
    ${({ $simple }) =>
        $simple
            ? css`
                  display: flex;
                  align-items: center;
              `
            : ''}
`;

export const StyledNotification = createGlobalStyle`
    .ant-notification .ant-notification-notice .ant-notification-notice-close {
       top: 50%;
        inset-inline-end: 12px;
        
    }
`;
