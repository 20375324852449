import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI } from '../shared';
import { models } from './constants';

export const RecommendedPromotionsStore = types
    .model('RecommendedPromotionsStore', {
        pending: types.boolean,
        error: models.ErrorModel,
        recommendedPromotions: types.array(models.PromotionModel)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const getRecommendedPromotions = flow(function* getRecommendedPromotions(promotionId) {
            self.pending = true;
            try {
                self.recommendedPromotions = yield self.root.get(
                    kcpAPI.marketplace.promotions.recommendations(promotionId)
                );
                self.pending = false;
            } catch (error) {
                self.pending = false;
                self.error = error;
            }
        });

        return { getRecommendedPromotions };
    });
