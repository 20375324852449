import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../shared';
import Footer from '../../shared/components/Footer';
import {
    ApplyNowSection,
    BrandsSection,
    CauseSection,
    Divider,
    FaqSection,
    GiftSuiteSection,
    Header,
    IntroSection,
    ReviewsSection,
    ShopSection,
    StepsSection,
    TasksSection
} from './components';

const Landing = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { search } = useLocation();
        const { isLoggedIn } = store.accountStore;
        const inviteCode = new URLSearchParams(search).get('inviteCode');

        useEffect(() => {
            if (!isLoggedIn && inviteCode) navigate({ pathname: ROUTE_PATHS.verify, search });
        }, [isLoggedIn, inviteCode]);

        return (
            <div>
                <Header />
                <IntroSection />
                <StepsSection />
                <Divider />
                <GiftSuiteSection />
                <ShopSection />
                <BrandsSection />
                <TasksSection />
                <CauseSection />
                <Divider />
                <ReviewsSection />
                <FaqSection />
                <ApplyNowSection />
                <Footer />
            </div>
        );
    })
);

export default Landing;
