import { Button, Typography } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledText = styled(Typography.Text)`
    width: 100%;
    margin-bottom: 24px;
`;

export const StyledButton = styled(Button)`
    && {
        width: 100%;
        margin-bottom: 4px;
    }
`;
