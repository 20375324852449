import React from 'react';
import { Row } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import Step from '../Step';
import StepInfo from '../StepInfo';
import { StyledStepsWrapper } from './styled';

const Steps = inject('store')(
    observer(({ store }) => {
        const { challenges, activeChallenge, isNoActiveChallenge } = store.seasonalChallengeStore;

        return (
            <StyledStepsWrapper
                $itemsCount={challenges?.length || 0}
                $activeItemPosition={activeChallenge?.position}
                $isNoActiveChallenge={isNoActiveChallenge}
            >
                <Row>
                    {challenges.map((challenge) => (
                        <Step
                            key={challenge.alias}
                            status={challenge.status}
                            position={challenge.position}
                            alias={challenge.alias}
                            name={challenge.name}
                        />
                    ))}
                </Row>
                <StepInfo />
            </StyledStepsWrapper>
        );
    })
);

export default Steps;
