import React from 'react';
import { Button, Icon, LogInSquareIcon, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { SettingsFooterWrap } from './styles';

export const SettingsFooter = inject('store')(
    observer(({ store }) => {
        const { logout: onLogout } = store.authStore;
        return (
            <SettingsFooterWrap>
                <Button type='text' onClick={onLogout} style={{ padding: 0 }}>
                    <Icon stylePriority color='secondaryText' component={() => <LogInSquareIcon />} />
                    <Typography.Text as='span' color='secondaryText'>
                        Log out
                    </Typography.Text>
                </Button>
            </SettingsFooterWrap>
        );
    })
);
