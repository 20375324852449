import React from 'react';
import { Navigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../constants';

const ProtectedRoute = inject('store')(
    observer(({ store, children }) => {
        return store?.accountStore?.isLoggedIn ? children : <Navigate to={ROUTE_PATHS.auth} />;
    })
);

export default ProtectedRoute;
