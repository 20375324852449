import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from '@klickly/front-packages';
import CancelOrder from '../CancelOrder';
import Info from './components/Info';
import { StyledCol, StyledWrapper } from './styled';

const RightContent = ({ content, mobile, isLoggedIn, signUpStatusRejected }) => {
    const navigate = useNavigate();

    return (
        <StyledWrapper>
            <Row justify='center'>
                {!mobile && (
                    <Col>
                        <Info mobile={mobile} content={content} />
                    </Col>
                )}
                {mobile && (
                    <Fragment>
                        <StyledCol>
                            <Info mobile={mobile} content={content} />
                        </StyledCol>
                        <CancelOrder
                            signUpStatusRejected={signUpStatusRejected}
                            navigate={navigate}
                            content={content}
                            mobile={mobile}
                            isLoggedIn={isLoggedIn}
                        />
                    </Fragment>
                )}
            </Row>
        </StyledWrapper>
    );
};

export default RightContent;
