import React from 'react';
import { StyledOrderLink } from './styled';

const OrderLink = ({ ordersLength = 1, children, ...props }) => {
    return (
        <StyledOrderLink $isBlockElement={ordersLength > 1} {...props}>
            {children}
        </StyledOrderLink>
    );
};

export default OrderLink;
