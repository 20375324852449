import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../../../utils';

export const StyledWrapper = styled.div`
    margin-bottom: 76px;

    @media (${GRID_DEVICES_MAX.md}) {
        margin-bottom: 48px;
    }
`;
