import React, { useContext } from 'react';
import {
    CircleActionsAlertInfoIcon,
    CircleActionsCloseIcon,
    CircleActionsPlaceholderIcon,
    CircleActionsSuccessIcon,
    CloseXIcon
} from '../../../icons';
import { KliThemeContext } from '../KliThemeProvider';
import { IconWrap, KliNotificationWrapper, NotificationContent } from './KliNotification.styles';
import { KliHintersType } from './types';

const defaultIcon = (type) => {
    if (type === KliHintersType.general) {
        return <CircleActionsPlaceholderIcon />;
    }
    if (type === KliHintersType.success) {
        return <CircleActionsSuccessIcon />;
    }
    if (type === KliHintersType.error) {
        return <CircleActionsCloseIcon />;
    }
    if (type === KliHintersType.alert) {
        return <CircleActionsAlertInfoIcon />;
    }
};

export const KliNotification = ({
    type = KliHintersType.general,
    canClose = false,
    withoutIcon = false,
    icon = defaultIcon(type),
    align = 'center',
    justify = 'flex-start',
    onClose = () => {},
    id,
    children,
    ...rest
}) => {
    const themeContext = useContext(KliThemeContext);

    return (
        <KliNotificationWrapper theme={themeContext} type={type} {...rest}>
            {!withoutIcon && (
                <IconWrap left type={type} theme={themeContext}>
                    {icon}
                </IconWrap>
            )}

            <NotificationContent align={align} justify={justify}>
                {children}
            </NotificationContent>

            {canClose && (
                <IconWrap right type={type} theme={themeContext}>
                    <CloseXIcon onClick={onClose} />
                </IconWrap>
            )}
        </KliNotificationWrapper>
    );
};
