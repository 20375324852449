import React from 'react';
import { Popover, Typography, useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../../../../../../utils';
import { StyledDescription, StyledIcon, StyledWrap } from './styles';

export const Step = ({ description, icon, state, firstStep, lastStep, popoverProps = {} }) => {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    const { popoverTitle, popoverContent, placement } = popoverProps;

    const renderContent = () => (
        <StyledWrap>
            <StyledIcon $state={state} size='24px' component={() => icon} />
            {description && state === 'active' ? (
                <StyledDescription type='badgeSmall' color='primary' $firstStep={firstStep} $lastStep={lastStep}>
                    {description}
                </StyledDescription>
            ) : (
                ''
            )}
        </StyledWrap>
    );

    return popoverTitle || popoverContent ? (
        <Popover
            trigger={simple ? 'click' : 'hover'}
            placement={placement}
            title={popoverTitle}
            content={
                <Typography.Text type='body2' color='canvas'>
                    {popoverContent}
                </Typography.Text>
            }
            maxWidth='273px'
            type='primary'
        >
            {renderContent()}
        </Popover>
    ) : (
        renderContent()
    );
};
