import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../../../utils';

export const StyledWrapper = styled.div`
    margin-top: 16px;

    @media (${GRID_DEVICES_MAX.xxl}) {
        margin-bottom: 100px;
    }
`;
