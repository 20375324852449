import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackArrowIcon, BOTTOM_INDENTS, TOP_INDENTS, Typography } from '@klickly/front-packages';
import { $primaryText } from '../../../../shared';
import { AuthHeader } from '../AuthHeader';
import { NavigationIcon } from '../NavigationIcon';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import { ForgotPasswordContentStyles, Styles } from './styles';

export const ForgotPassword = () => {
    const navigate = useNavigate();
    return (
        <Styles>
            <AuthHeader />
            <ForgotPasswordContentStyles>
                <NavigationIcon size='24px' color={$primaryText}>
                    <BackArrowIcon onClick={() => navigate(-1)} />
                </NavigationIcon>
                <Typography.Title style={{ ...BOTTOM_INDENTS.SM, ...TOP_INDENTS.XL }}>Reset password</Typography.Title>
                <Typography.Text style={BOTTOM_INDENTS.XL} type='body1' color='primaryText'>
                    Enter your Giftly email address and we’ll send you a token so you can reset your password
                </Typography.Text>
                <ForgotPasswordForm />
            </ForgotPasswordContentStyles>
        </Styles>
    );
};

export default ForgotPassword;
