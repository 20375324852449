import React from 'react';
import { ScrollIcon, Typography } from '@klickly/front-packages';
import { NoOrderIcon, Styles } from './styles';

export const NoOrdersMessage = () => {
    return (
        <Styles>
            <NoOrderIcon size='40px' color='secondaryText' component={() => <ScrollIcon />} />
            <Typography.Title level={2}>No orders yet ...</Typography.Title>
            <Typography.Text type='body1'>Discover new products and brands in the Giftly Marketplace</Typography.Text>
        </Styles>
    );
};
