import React from 'react';
import { BOTTOM_INDENTS, KliHero, Typography } from '@klickly/front-packages';
import { CloseModalIcon } from '../../../../../../../../shared/components/CloseModalButton';
import { StyledButton, StyledContent, StyledModalWrapper, StyledWrap, SuccessImg } from './styles';

export const SuccessModal = ({ onClose, simple, ...rest }) => {
    return (
        <StyledModalWrapper {...rest}>
            {!simple ? <CloseModalIcon onClick={onClose} /> : null}
            <StyledWrap>
                <KliHero
                    title='Feedback sent'
                    backgroundColor='primaryBackground'
                    color='primary'
                    textAlign='center'
                    simple={false}
                    titleOnTop
                    withoutBorders
                    onBack={simple ? onClose : null}
                    style={{ justifyContent: simple ? 'flex-start' : 'center' }}
                />
                <StyledContent $simple={simple}>
                    <SuccessImg style={BOTTOM_INDENTS.SM} />
                    <Typography.Title style={BOTTOM_INDENTS.XL} level={3} align='center'>
                        Thank you for sharing your feedback about Giftly
                    </Typography.Title>
                    <StyledButton block type='primary' size='large' onClick={onClose}>
                        <Typography.Text type='badgeLarge'>Close</Typography.Text>
                    </StyledButton>
                </StyledContent>
            </StyledWrap>
        </StyledModalWrapper>
    );
};
