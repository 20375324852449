import React from 'react';
import { BOTTOM_INDENTS, TOP_INDENTS, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $primary, $secondaryText, $white } from '../../../../shared';
import { Timer } from '../../../../shared/components/Timer';
import { StyledText, StyledTitle } from './styled';

const SuiteOpenTitle = inject('store')(
    observer(({ store }) => {
        const mobile = useSimpleInterface(768);

        return (
            <StyledTitle level={1} style={mobile ? { ...BOTTOM_INDENTS.SM, ...TOP_INDENTS.M } : BOTTOM_INDENTS.SM}>
                <StyledText $color={$primary}>Your Gifting Suite </StyledText>
                <StyledText $color={$secondaryText}>will expire in</StyledText>{' '}
                <StyledText $color={$primary}>
                    <Timer
                        level={1}
                        text='{time}'
                        color={$white}
                        showIcon={false}
                        align='center'
                        onTimeLeft={null}
                        endDate={store.suiteStore.nextCloseAt}
                        params={{ minutes: 'min' }}
                    />
                </StyledText>
            </StyledTitle>
        );
    })
);

export default SuiteOpenTitle;
