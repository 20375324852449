import { Button, Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $background, $primaryText, $secondaryText } from '../../../../../../shared';

export const StyledWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    background-color: ${$background};
`;

export const StyledContent = styled.div`
    display: flex;
    align-items: center;
    width: calc(100% - 24px);
`;

export const StyledValue = styled(Typography.Text)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 30px;
`;

export const StyledLabel = styled(Typography.Text)`
    white-space: nowrap;
`;

export const StyledButton = styled(Button)`
    &&& {
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: none;
        color: ${$secondaryText};

        &:hover {
            color: ${$primaryText};
        }
    }
`;
