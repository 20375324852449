import { Button, KliNotification, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../../utils';
import { $primary, $secondaryText, $success, $white } from '../../../../../../constants';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledContent = styled.div`
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const StyledInfo = styled.div`
    flex-grow: 1;
    margin-left: 16px;
`;

export const StyledUserName = styled(Typography.Text)`
    font-weight: normal;
    color: ${$secondaryText};
    margin-bottom: 4px;
`;

export const StyledProductTitle = styled(Typography.Title)`
    margin-bottom: 4px;
`;

export const StyledPrice = styled(Typography.Text)`
    font-weight: 500;
`;

export const CopiedMessage = styled(KliNotification)`
    margin: 10px -40px 0;
    z-index: 1;

    @media (${GRID_DEVICES_MAX.md}) {
        margin: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
    }
`;

export const StyledButton = styled(Button)`
    && {
        width: 100%;
        margin-bottom: 8px;

        ${({ $copied }) =>
            $copied
                ? css`
                      touch-action: none;
                      pointer-events: none;
                      border-color: ${$success};
                      background-color: #ffffff !important;

                      &:focus:not(:hover) {
                          border-color: ${$success};
                      }
                  `
                : css`
                      &:hover:not(:disabled):not(:active) {
                          > span {
                              color: ${$white};
                          }
                      }
                  `}
    }
`;

export const StyledTitle = styled(Typography.Text)`
    margin-bottom: 24px;
    text-align: center;

    > span {
        color: ${$primary};
        font-weight: 500;
    }
`;
