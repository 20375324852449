import { Icon, Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $white } from '../../../../../../../../shared';

export const Item = styled(Icon)`
    margin: 20px 4px 20px 0;
    text-align: center;

    &:hover {
        color: ${$white};
    }
`;

export const ItemValue = styled(Typography.Text)`
    position: relative;
    z-index: 2;
    display: block;
    width: 50px;
    text-align: center;
`;

export const Rating = styled.div`
    display: flex;
    justify-content: ${({ $align }) => $align || 'flex-start'};
    flex-wrap: wrap;
    align-items: center;
`;
