import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI } from '../shared';

export const UpsellStore = types
    .model('UpsellStore', {
        pending: true,
        data: types.maybeNull(
            types.model({
                rewardId: types.maybeNull(types.string),
                promotionId: types.maybeNull(types.string),
                variantId: types.maybeNull(types.number),
                rewardData: types.maybeNull(
                    types.model({
                        title: types.maybeNull(types.string),
                        description: types.maybeNull(types.string),
                        uuid: types.maybeNull(types.string)
                    })
                ),
                promotionData: types.maybeNull(
                    types.model({
                        title: types.maybeNull(types.string),
                        brandName: types.maybeNull(types.string),
                        image: types.maybeNull(types.string),
                        description: types.maybeNull(types.string),
                        priceDetails: types.maybeNull(
                            types.model({
                                originalPrice: types.maybeNull(types.number),
                                rewardsPrice: types.maybeNull(types.number),
                                rewardsPercent: types.maybeNull(types.number),
                                youPayPrice: types.maybeNull(types.number)
                            })
                        )
                    })
                )
            })
        )
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },

        get upsell() {
            return self.data;
        },

        get upsellDataExists() {
            return self.data?.rewardId;
        }
    }))
    .actions((self) => {
        const getUpsellByCart = flow(function* getUpsellByCart(cartId) {
            self.pending = true;
            try {
                self.data = yield self.root.get(kcpAPI.upsell.getByCartId(cartId));
                return self.data;
            } catch (error) {
                if (error.response) {
                    self.error = error.response.data.message;
                }
            } finally {
                self.pending = false;
            }
        });

        const checkForUpsell = flow(function* checkForUpsell(cartId) {
            self.pending = true;
            try {
                const data = yield getUpsellByCart(cartId);
                return Boolean(data?.rewardId);
            } catch (error) {
                if (error.response) {
                    self.error = error.response.data.message;
                }
            } finally {
                self.pending = false;
            }
        });

        const claimUpsell = flow(function* claimUpsell(data, onSuccessCallback = () => null) {
            self.pending = true;
            try {
                yield self.root.post(kcpAPI.upsell.claim, data);
                onSuccessCallback();
            } catch (error) {
                if (error.response) {
                    self.error = error.response.data.message;
                }
            } finally {
                self.pending = false;
            }
        });

        return {
            getUpsellByCart,
            claimUpsell,
            checkForUpsell
        };
    });
