import React from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../shared';
import GsPage from '../components/GsPage';

export const ROUTES = [
    {
        path: ROUTE_PATHS.suite,
        element: <Navigate to={ROUTE_PATHS.suiteGs} replace />,
        index: true
    },
    {
        path: ROUTE_PATHS.suiteGs,
        element: <GsPage />,
        children: GsPage.ROUTES
    }
];
