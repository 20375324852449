import { types } from 'mobx-state-tree';
import { TASK_STATUSES } from '../../shared';

export const SeasonalInnerChallengeModel = types
    .model('SeasonalInnerChallenge', {
        position: types.number,
        alias: types.string,
        name: types.string,
        description: types.maybeNull(types.string),
        rewards: types.array(
            types.model({
                type: types.maybeNull(types.string),
                value: types.maybeNull(types.number)
            })
        ),
        startedAt: types.maybeNull(types.string),
        completedAt: types.maybeNull(types.string),
        challengeAccepted: types.maybeNull(types.boolean)
    })
    .views((self) => ({
        get status() {
            if (self.startedAt && self.completedAt) return TASK_STATUSES.completed;
            return self.startedAt ? TASK_STATUSES.active : TASK_STATUSES.inactive;
        }
    }))
    .actions((self) => {
        const setChallengeAccepted = (accepted = true) => {
            self.challengeAccepted = accepted;
        };

        return { setChallengeAccepted };
    });
