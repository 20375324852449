import { Button } from '@klickly/front-packages';
import styled from 'styled-components';
import { $primary, $primaryBackground, $white } from '../../../../constants';

export const StyledWrapper = styled.div`
    width: 100%;
    background-color: ${$white};
`;

export const StyledHeader = styled.div`
    width: 100%;
`;

export const StyledContent = styled.div`
    padding: 40px;
`;

export const StyledIconWrap = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid ${$primary};
    background-color: ${$primaryBackground};
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledButtonsWrapper = styled.div`
    display: flex;
    flex-direction: ${({ $simple }) => ($simple ? 'column' : 'row-reverse')};
    align-items: center;
    justify-content: space-between;
`;

export const StyledCancelButton = styled(Button)`
    && {
        width: 100%;
        margin-right: ${({ $simple }) => ($simple ? '0' : '10px')};
        margin-bottom: ${({ $simple }) => ($simple ? '20px' : '0')};
    }
`;

export const StyledConfirmButton = styled(Button)`
    && {
        width: 100%;
        margin-left: ${({ $simple }) => (!$simple ? '0' : '10px')};
    }
`;
