import { useEffect } from 'react';

export const useHandleKlicklyEvents = (store) => {
    const { addEventListener, removeEventListener, onEventMessage } = store.eventListenerStore;
    useEffect(() => {
        function eventHandler(event) {
            // check only for klickly events
            const { origin = '', data = {} } = event || {};

            const isMsgFromKlickly = origin.includes('klickly');
            const isMsgFromDevTools = data.source === '@devtools-page';
            const isMsgFromIframeOrExtension = data.action || (data.payload && data.payload.action);

            if (isMsgFromKlickly && !isMsgFromDevTools && isMsgFromIframeOrExtension) {
                onEventMessage(data);
            }
        }

        addEventListener({ eventName: 'message', eventHandler });
        return () => removeEventListener({ eventName: 'message', eventHandler });
    }, [addEventListener, removeEventListener, onEventMessage]);
};
