import { Button } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $border, $primaryText } from '../../../../../../shared';

export const StyledWrap = styled.div``;

export const StyledContent = styled.div`
    padding-top: ${({ $open }) => ($open ? '24px' : '0')};
    border-top: ${({ $open }) => ($open ? `1px solid ${$border}` : 'none')};
    overflow: hidden;
    max-height: ${({ $open }) => ($open ? '500px' : '0')};
    transition: max-height 0.15s ease-out;
`;

export const StyledButtonBlock = styled.div`
    width: 100%;
    position: relative;
`;

export const Divider = styled.div`
    position: absolute;
    width: calc(50% - 18px);
    height: 1px;
    border-bottom: 1px solid ${$primaryText};
    top: 50%;

    ${({ $position }) =>
        $position === 'left'
            ? css`
                  left: 0;
              `
            : css`
                  right: 0;
              `}
`;

export const StyledButton = styled(Button)`
    &&& {
        width: 36px;
        height: 36px;
        padding: 0;
        margin: 0 auto;
    }
`;
