import React from 'react';
import PointsModalContent from '../components/PointsModalContent';
import RewardsModalContent from '../components/RewardsModalContent';
import { useModal } from './useModal';

export const useRewardAndPointsModal = ({
    uuid,
    isLoggedIn,
    rewardsAuthorizedTitle = 'Rewards applied',
    rewardsUnauthorizedTitle = 'Rewards applied',
    pointsAuthorizedTitle = 'Earn Points',
    pointsUnauthorizedTitle = 'Members earn',
    rewardNotAvailableProps = {},
    additionalProps = {}
}) => {
    const { openModal, closeModal } = useModal();

    const openRewardModal = (variant) =>
        openModal({
            title: isLoggedIn ? rewardsAuthorizedTitle : rewardsUnauthorizedTitle,
            children: (
                <RewardsModalContent
                    uuid={uuid}
                    variant={variant}
                    onCloseModal={closeModal}
                    notAvailableProps={rewardNotAvailableProps}
                    {...additionalProps}
                />
            )
        });

    const openPointsModal = (products) =>
        openModal({
            title: isLoggedIn ? pointsAuthorizedTitle : pointsUnauthorizedTitle,
            children: <PointsModalContent products={products} onCloseModal={closeModal} {...additionalProps} />
        });

    return { openRewardModal, openPointsModal, closeModal };
};
