import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Icon, Row, Taxonomy, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import qs from 'qs';
import { $primary, $primaryText, ROUTE_PATHS } from '../../../../constants';
import { StyledMenuItem } from '../../styled';

const CategoriesList = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { updateQuery, updatePromotions } = store.marketplaceStore;
        const { getTaxonomyCategoryDetails, selectCategory } = Taxonomy.store;
        return (
            <Row>
                {[...Taxonomy.TAXONOMY_ROOT_CATEGORIES]
                    .sort(({ order: a }, { order: b }) => a - b)
                    .map(({ title, key, icon: CategoryIcon }) => (
                        <Col key={key} span={8}>
                            <StyledMenuItem
                                role='button'
                                onClick={async () => {
                                    await getTaxonomyCategoryDetails(key);
                                    selectCategory(key, true, (gt) => {
                                        updateQuery({ gt });
                                        updatePromotions({ isNewSearch: true });
                                    });
                                    navigate({
                                        pathname: ROUTE_PATHS.shop,
                                        search: qs.stringify({ gt: key })
                                    });
                                }}
                            >
                                <Row gutter={20} align='middle'>
                                    <Col flex='24px'>
                                        <Icon size='24px' color={$primary} component={() => <CategoryIcon />} />
                                    </Col>
                                    <Col>
                                        <Typography.Text color={$primaryText} type='badgeMedium'>
                                            {title}
                                        </Typography.Text>
                                    </Col>
                                </Row>
                            </StyledMenuItem>
                        </Col>
                    ))}
            </Row>
        );
    })
);

export default CategoriesList;
