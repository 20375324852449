import React from 'react';
import { Image } from '@klickly/front-packages';
import styled from 'styled-components';
import causeImg1 from '../../../../assets/img/landing/causes/cause-1.png';
import causeImg2 from '../../../../assets/img/landing/causes/cause-2.png';
import causeImg3 from '../../../../assets/img/landing/causes/cause-3.png';
import causeImg4 from '../../../../assets/img/landing/causes/cause-4.png';
import causeBadgeImg1 from '../../../../assets/img/landing/causes/cause-badge-1.svg';
import causeBadgeImg2 from '../../../../assets/img/landing/causes/cause-badge-2.png';
import causeBadgeImg3 from '../../../../assets/img/landing/causes/cause-badge-3.svg';
import causeBadgeImg4 from '../../../../assets/img/landing/causes/cause-badge-4.png';
import { $white } from '../../../../shared';

export const StyledWrap = styled.div`
    width: 100%;
    margin-bottom: 32px;
    padding: 0 24px;

    @media (max-width: 650px) {
        margin-bottom: 0;
    }
`;

export const StyledContent = styled.div`
    display: flex;
    justify-content: center;
    margin-top: ${({ $simple }) => ($simple ? '24px' : '64px')};
`;

export const StyledCardsWrap = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 306px);
    grid-column-gap: 18px;

    @media (max-width: 1310px) {
        grid-template-columns: repeat(2, 306px);
        grid-row-gap: 4px;
    }

    @media (max-width: 650px) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 4px;
    }
`;

export const StyledCard = styled.div`
    position: relative;
    width: 306px;
    height: 464px;

    @media (max-width: 650px) {
        width: 164px;
        height: 248px;
    }

    @media (max-width: 375px) {
        width: 140px;
        height: 216px;
    }
`;

export const StyledCauseImg = styled(Image)`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

export const StyledBadgeWrap = styled.div`
    width: 100%;
    height: 45px;

    position: absolute;
    bottom: 35px;
    z-index: 3;
    background-color: ${$white};
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 650px) {
        height: 38px;
    }

    @media (max-width: 375px) {
        height: 28px;
    }
`;

export const StyledCauseBadgeImg = styled(Image)`
    && {
        width: auto;
        height: 45px;
        object-fit: contain;
    }
`;

export const CauseImg1 = () => <StyledCauseImg src={causeImg1} alt='cause-1' />;
export const CauseImg2 = () => <StyledCauseImg src={causeImg2} alt='cause-2' />;
export const CauseImg3 = () => <StyledCauseImg src={causeImg3} alt='cause-3' />;
export const CauseImg4 = () => <StyledCauseImg src={causeImg4} alt='cause-4' />;

export const CauseBadgeImg1 = () => <StyledCauseBadgeImg src={causeBadgeImg1} alt='cause-badge-1' />;
export const CauseBadgeImg2 = () => <StyledCauseBadgeImg src={causeBadgeImg2} alt='cause-badge-2' />;
export const CauseBadgeImg3 = () => <StyledCauseBadgeImg src={causeBadgeImg3} alt='cause-badge-3' />;
export const CauseBadgeImg4 = () => <StyledCauseBadgeImg src={causeBadgeImg4} alt='cause-badge-4' />;
