export const saveOnboardingQueryData = (searchString) => {
    const urlParams = new URLSearchParams(searchString);
    const giftlyToken = urlParams.get('token');
    const inviteCode = urlParams.get('inviteCode');

    if (giftlyToken) {
        localStorage.setItem('giftly-extension-token', giftlyToken);
    }

    if (inviteCode) {
        localStorage.setItem('giftly-invite-code', inviteCode);
    }
};
