import React from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    Col,
    GRID_POINTS,
    Image,
    roundToLocaleString,
    Row,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { $secondaryText, $white } from '../../../../../../../../../../shared';
import { StyledImgWrapper, StyledOriginalPrice, StyledProductTitle, StyledQuantity } from './styled';

const ProductItem = inject('store')(
    observer(({ store, product, orderDetails, details }) => {
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { image, variantTitle, productTitle, qty } = product;
        const challenge = store.tasksStore?.writeProductReviewTask;

        const { id } = useParams();

        return (
            <Row gutter={simple ? 4 : 16} align='middle' justify='space-between' style={BOTTOM_INDENTS.XL}>
                <Col>
                    <Link
                        state={{
                            ...toJS(product),
                            ...{ alias: challenge?.alias },
                            ...toJS(details),
                            ...toJS(orderDetails),
                            orderId: id
                        }}
                        to={`/marketplace/${product.productId}`}
                    >
                        <Row gutter={8} align='middle' justify='flex-start'>
                            <Col>
                                <StyledImgWrapper>
                                    <Image src={image} />
                                    {qty > 1 ? (
                                        <StyledQuantity>
                                            <Typography.Text type='badgeLarge' color={$white}>
                                                {qty}
                                            </Typography.Text>
                                        </StyledQuantity>
                                    ) : null}
                                </StyledImgWrapper>
                            </Col>
                            <Col>
                                <StyledProductTitle type='badgeMedium'>{productTitle}</StyledProductTitle>
                                <StyledProductTitle type='descriptor2' color={$secondaryText}>
                                    {variantTitle}
                                </StyledProductTitle>
                            </Col>
                        </Row>
                    </Link>
                </Col>
                <Col>
                    <Row gutter={8} align='middle' style={BOTTOM_INDENTS.XXXS}>
                        {product?.priceDetails.fullTotal !== product?.priceDetails.originalSubTotal ? (
                            <Col>
                                <StyledOriginalPrice
                                    type='descriptor2'
                                    color={$secondaryText}
                                    data-e2e='giftly-order_summary-product-old-price'
                                >
                                    ${roundToLocaleString(product?.priceDetails.originalSubTotal)}
                                </StyledOriginalPrice>
                            </Col>
                        ) : null}
                        <Col>
                            <Typography.Text
                                ype='body2'
                                color={$secondaryText}
                                data-e2e='giftly-order_summary--product-final-price'
                            >
                                ${roundToLocaleString(product?.priceDetails.fullTotal)}
                            </Typography.Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    })
);

export default ProductItem;
