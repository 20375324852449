export const buildPromotionData = (promotion, additionalData = {}) => ({
    _id: promotion._id,
    externalId: promotion.externalId,
    title: promotion.title,
    accountId: promotion.account,
    campaignId: promotion.campaign,
    campaignName: promotion.campaignName,
    shopDomain: promotion.shopDomain,
    brandName: promotion.brandName,
    ...additionalData
});
