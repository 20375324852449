import styled from 'styled-components';

export const StyledPageStyles = styled.div`
    padding-bottom: 30px;
`;

export const StyledItemPointsWrapper = styled.div`
    display: grid;
    grid-template-columns: ${({ $simple }) => ($simple ? '1ft' : 'repeat(2, 1fr)')};
    grid-column-gap: 20px;
    grid-row-gap: 20px;
`;

export const StyledItemPoint = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 22px;
`;

export const StyledIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
`;
