import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { app } from '../../config';
import Header from '../../shared/components/Layout/components/Header';
import { PDPComponent } from '../../shared/components/PDP';
import { StyledPDPWrap, StyledWrap } from './styled';

const ProductPage = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const [searchParams] = useSearchParams();
        const { state } = useLocation();
        const simple = useSimpleInterface(695);
        const { setPrevProductId } = store.brandPageStore;
        const couponUUID = state?.couponUUID;
        const { productId } = useParams();

        const onClose = () => {
            searchParams.delete('referrer');
            navigate(-1);
        };

        const handleBrandNameClick = (brandId) => {
            window.open(`${app.giftlyUrl}/brands/${brandId}`, '__blank');
        };

        useEffect(() => {
            if (productId) setPrevProductId(productId);
        }, [productId]);

        return (
            <StyledWrap $simple={simple}>
                <Header stickyPosition />
                <StyledPDPWrap $simple={simple}>
                    <PDPComponent
                        key={productId}
                        id={productId}
                        customizationsKey='shop'
                        rewardUuid={couponUUID}
                        onClose={onClose}
                        onBrandNameButtonClick={handleBrandNameClick}
                    />
                </StyledPDPWrap>
            </StyledWrap>
        );
    })
);

export default ProductPage;
