import React, { useEffect, useState } from 'react';
import { useSearchParam } from 'react-use';
import { Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ShippingAddressForm } from '../../../../../../shared/components/ShippingAddressForm';
import { StyledWrapper } from './styles';

export const EditShipping = inject('store')(
    observer(({ store }) => {
        const {
            shippingAddresses,
            updateShippingAddress,
            deleteShippingAddress,
            setShippingAddressAsDefault,
            errorMessage,
            pending
        } = store.accountStore;
        const showDeleteAddressButton = shippingAddresses && shippingAddresses.length > 1;
        const [address, setAddress] = useState();
        const addressId = useSearchParam('id');

        useEffect(() => {
            if (!shippingAddresses) {
                return;
            }

            if (!addressId) {
                return;
            }

            // eslint-disable-next-line
            // TODO: rewrite with selector;
            const currentAddress = shippingAddresses.find((address) => address._id === addressId);

            if (currentAddress) {
                setAddress(currentAddress);
            }
        }, [shippingAddresses, addressId, setAddress]);

        return (
            <StyledWrapper>
                {address ? (
                    <ShippingAddressForm
                        address={address}
                        isDefault={address.isDefault}
                        error={errorMessage}
                        onSubmit={updateShippingAddress}
                        showDeleteAddressButton={showDeleteAddressButton}
                        setDefault={() => setShippingAddressAsDefault(addressId)}
                        onDelete={() => deleteShippingAddress(addressId)}
                    />
                ) : (
                    <Icon.Loader />
                )}
                {pending && <Icon.Loader />}
            </StyledWrapper>
        );
    })
);
