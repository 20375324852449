import React from 'react';
import { BOTTOM_INDENTS, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { Animated } from '../../../../shared/components/Animated';
import { PageHeader } from '../../../../shared/components/PageHeader';
import { EmptyContent } from './components/EmptyContent';
import { Item } from './components/Item';
import { StyledWishlistWrap } from './styled';

const Wishlist = inject('store')(
    observer(({ store }) => {
        const simple = useSimpleInterface(710);
        const { wishlist } = store.accountStore;

        return (
            <Animated style={{ maxWidth: '1035px', margin: 'auto' }}>
                <PageHeader title='Wishlist' style={{ ...BOTTOM_INDENTS.XL }} />
                {wishlist?.length ? (
                    <StyledWishlistWrap $simple={simple}>
                        {wishlist.map((item) => (
                            <Item data={item} key={item.variantId} />
                        ))}
                    </StyledWishlistWrap>
                ) : (
                    <EmptyContent />
                )}
            </Animated>
        );
    })
);

export default Wishlist;
