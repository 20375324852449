import { getParent, types } from 'mobx-state-tree';
import qs from 'qs';
import { ROUTE_PATHS } from '../shared';

export const NavigationStore = types
    .model('NavigationStore', {})
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const getLinkToMarketPlace = (params = {}) => {
            const { withStrategy = true } = params;
            const { strategy } = self.root.marketplaceStore.query;
            const strategyQuery = withStrategy && strategy ? `?${qs.stringify({ strategy })}` : '';
            if (!strategyQuery) return ROUTE_PATHS.dashboard;
            return `${ROUTE_PATHS.shop}${strategyQuery}`;
        };

        const getHomePageLink = () => {
            const { isLoggedIn, isApprovedCustomer } = self.root.accountStore;

            if (isLoggedIn && isApprovedCustomer) {
                return ROUTE_PATHS.dashboard;
            }

            return ROUTE_PATHS.root;
        };

        return { getHomePageLink, getLinkToMarketPlace };
    });
