import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';

export const HeaderStyles = styled.div`
    width: 100%;
`;

export const StyledSubTitle = styled(Typography.Text)`
    padding: 0 16px;
    text-align: center;
`;
