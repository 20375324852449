import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI } from '../shared';
import { models } from './constants';

export const OrderStore = types
    .model('OrderStore', {
        pending: false,
        error: types.maybeNull(types.string),
        orderDetails: models.OrderDetailsModel
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        getDetailsByOrderNumber(orderNumber) {
            return self?.orderDetails?.cart.bags.find((order) => order.orderNumber === orderNumber);
        },
        getPointsSpentByOrderId(orderId) {
            return (
                self.orderDetails?.cart?.discountsAllocation?.find(
                    (discount) => discount.levelId === orderId && discount?.label === 'Points'
                )?.amount || 0
            );
        },
        getEarnedPointsByOrderNumber(orderNumber) {
            return self.getDetailsByOrderNumber(orderNumber)?.pointsEarned || 0;
        },
        getEarnedPointsByProduct(orderNumber, productId) {
            return (
                self
                    .getDetailsByOrderNumber(orderNumber)
                    ?.items.find((item) => item._id === productId)
                    ?.metadata?.find((metaItem) => metaItem.name === 'pointsEarned')?.value || 0
            );
        },
        getOrderRewards(orderNumber) {
            return self.getDetailsByOrderNumber(orderNumber)?.items.reduce(
                (result, item) => {
                    const itemRewards = item?.metadata?.find((metaItem) => metaItem.name === 'rewardsDetails')?.value
                        ?.rewards;
                    if (itemRewards?.length) {
                        result.rewardsCount = result.rewardsCount + itemRewards.length;
                        result.rewardsAmount =
                            result.rewardsAmount +
                            itemRewards.filter((item) => item.amount).reduce((acc, reward) => acc + reward.amount, 0) *
                                item.qty;
                    }
                    return result;
                },
                { rewardsCount: 0, rewardsAmount: 0 }
            );
        }
    }))
    .actions((self) => ({
        getOrderDetails: flow(function* getOrderDetails(orderId) {
            self.pending = true;
            try {
                self.orderDetails = yield self.root.get(`${kcpAPI.customerData.orders.getById}/${orderId}`);
            } catch (error) {
                self.error = error.response?.data?.message || error.message;
            } finally {
                self.pending = false;
            }
        })
    }));
