import React from 'react';
import { Typography, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import {
    CauseBadgeImg1,
    CauseBadgeImg2,
    CauseBadgeImg3,
    CauseBadgeImg4,
    CauseImg1,
    CauseImg2,
    CauseImg3,
    CauseImg4,
    StyledBadgeWrap,
    StyledCard,
    StyledCardsWrap,
    StyledContent,
    StyledWrap
} from './styles';

export const CauseSection = observer(() => {
    const simple = useSimpleInterface(650);

    return (
        <StyledWrap>
            {simple ? (
                <Typography.Title align='center' style={{ marginBottom: '24px' }}>
                    Support your favorite cause
                </Typography.Title>
            ) : (
                <Typography.Text type='em0' align='center' style={{ marginBottom: '24px' }}>
                    Support your favorite cause
                </Typography.Text>
            )}
            <Typography.Text type={simple ? 'descriptor' : 'body1'} align='center'>
                By shopping on Giftly, you’ll be supporting the causes below
            </Typography.Text>
            <StyledContent $simple={simple}>
                <StyledCardsWrap>
                    <StyledCard>
                        <CauseImg1 />
                        <StyledBadgeWrap>
                            <CauseBadgeImg1 />
                        </StyledBadgeWrap>
                    </StyledCard>
                    <StyledCard>
                        <CauseImg2 />
                        <StyledBadgeWrap>
                            <CauseBadgeImg2 />
                        </StyledBadgeWrap>
                    </StyledCard>
                    <StyledCard>
                        <CauseImg3 />
                        <StyledBadgeWrap>
                            <CauseBadgeImg3 />
                        </StyledBadgeWrap>
                    </StyledCard>
                    <StyledCard>
                        <CauseImg4 />
                        <StyledBadgeWrap>
                            <CauseBadgeImg4 />
                        </StyledBadgeWrap>
                    </StyledCard>
                </StyledCardsWrap>
            </StyledContent>
        </StyledWrap>
    );
});
