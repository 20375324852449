import { AvailableRewardsModel } from './availableRewardsModel';
import { BillingProfileModel } from './billingProfileModel';
import { BrandModel } from './brandModel';
import { CategoryModel } from './categoryModel';
import { ClaimModel } from './claimModel';
import { ErrorModel } from './errorModel';
import { OrderDetailsModel } from './orderDetailsModel';
import { OrderModel } from './orderModel';
import { PDPModel } from './pdpModel';
import { PriceDetailsModel } from './priceDetailsModel';
import { PromotionModel } from './promotionModel';
import { QueryModel } from './queryModel';
import { RewardModel } from './rewardModel';
import { SeasonalChallengeModel } from './seasonalChallengeModel';
import { SeasonalInnerChallengeModel } from './seasonalInnerChallengeModel';
import { ShippingAddressModel } from './shippingAddressModel';
import { SortModel } from './sortModel';
import { StrategyInfoModel } from './strategyInfoModel';
import { SuiteCouponModel } from './suiteCouponModel';

export * from './initialStores';
export * from './tasks';
export * from './tasksData';

export const models = {
    ErrorModel,
    PromotionModel,
    BrandModel,
    CategoryModel,
    SortModel,
    QueryModel,
    SuiteCouponModel,
    ClaimModel,
    OrderModel,
    ShippingAddressModel,
    BillingProfileModel,
    RewardModel,
    AvailableRewardsModel,
    StrategyInfoModel,
    SeasonalInnerChallengeModel,
    SeasonalChallengeModel,
    PriceDetailsModel,
    OrderDetailsModel,
    PDPModel
};
