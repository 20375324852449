/* eslint-disable */
import React from "react";
import { LegalContent } from "../../../../shared/components/LegalContent";
import { StyledText } from "./styles";

const TermsPage = () => {
    return (
        <LegalContent title="Terms of Use" date="Last Updated May 02, 2023">
            <StyledText type="descriptor1">
                These Terms of Use and any terms or agreements incorporated by reference herein (collectively, the "Terms”)
                apply to your use of the websites of Klickly, Inc. ("Klickly", "Us" or "We"), available at{' '}
                <a href="www.klickly.com">www.klickly.com</a> or any wholly or partially owned subdomain (the "Site"), and the
                real-time commerce solution, application, or services provided by Klickly (together with the Site, the "Services").
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                PLEASE READ THESE TERMS CAREFULLY. BY CREATING AN ACCOUNT OR BY USING THE SERVICES, YOU AGREE TO BE
                BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, DO NOT CREATE AN ACCOUNT OR
                ACCESS OR USE THE SERVICES.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                We reserve the right to make changes or modifications to these Terms from time to time.
                If we make changes to these Terms, we will provide notice of such changes by posting the amended
                Terms via the Site and updating the "Last Updated" date at the top of these Terms.
                The amended Terms will be deemed effective immediately upon such posting for any new user of the Services.
                In our sole discretion, we may provide preexisting users with additional notice of changes, such as
                by sending an email or by posting additional notice on the homepage of our Site. Unless accepted
                earlier pursuant to a separate assent procedure to the amended Terms, all amended Terms will become
                effective for preexisting users 30 days after the amended Terms are posted to the Site
                (as indicated by the Last Updated date). Continued use of the Services by such users following
                the effective date of such changes will constitute acceptance of such changes. Any amended Terms
                will only apply prospectively to use of the Services after such changes become effective,
                and will not apply retroactively. If you do not agree to any amended Terms, you must discontinue using the Services.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                As used in these Terms, references to our "Affiliates" include our owners, subsidiaries,
                affiliated companies, officers, directors, suppliers, partners, sponsors,
                and advertisers, and includes (without limitation) all parties involved in
                creating, producing, and/or delivering the Services on our behalf.
            </StyledText>
            <br/>
            <StyledText type="badgeLarge">1. Services.</StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>1.1 Description of Services. </strong>
                Our Services provide a platform for individuals (“Individuals”) to purchase goods or
                services from, donate to or engage with (“Transact”) advertisers who have established
                a registered account with Klickly (“Sellers”). Individuals are able to Transact with
                Sellers via the Site, via third-party websites, third-party social media services,
                emails, and other communication services ("Third-Party Publishers") and via Sellers’
                websites (collectively, “Publishers”). Individuals who Transact with Sellers are
                referred to in these Terms as "Buyers". Individuals, Buyers, Sellers and Third-Party
                Publishers are referred to in these Terms as “Users”. In addition to providing a
                platform to enable Transactions, Klickly may, but is in no way obligated to, provide
                ancillary Services, such as loyalty programs for Buyers, product placement and
                advertising for Sellers, and website and social media monetization for Third-Party Publishers.
                In some instances, Klickly may contract directly with Buyers to deliver Merchandise under
                a Drop-Ship Transaction (as defined herein). In such instances, references to “Seller”
                in Section 5 of these Terms shall refer to Klickly.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>1.2 Klickly’s Role. </strong>
                As a User, you acknowledge and agree that other than a Drop-Ship Transaction, the Services only
                help facilitate transactions between Buyers and Sellers, and that Klickly never acts as a Buyer
                or a Seller. The terms and conditions of the purchase of Seller products is solely between Buyer
                and Seller. Sellers must resolve any issues, disputes or differences directly with Buyers.
                Klickly is not a party to and has no responsibility or liability with respect to any communications,
                transactions, interactions, disputes or any relations whatsoever between Sellers and Buyers.
                For certain transactions, Klickly may fulfill orders from Buyers by purchasing the Seller Merchandise
                on Buyer’s behalf and arranging shipment directly to Buyer (a “Drop-Ship Transaction”).
                In such instances, Buyer may register any issues, disputes or differences directly with Klickly.
                Each party agrees to work in good faith for a period of sixty (60) days to resolve any such issue,
                dispute or difference.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <span>
                    <strong>2. Eligibility; Representations </strong>
                </span>
                Use of the Services is limited to parties that lawfully can enter into and form contracts under applicable law.
                Individuals who are under the age of 18, or who are otherwise considered minors under applicable laws, are not
                allowed to use the Services. By registering to use the Services, you represent and warrant that you: (a) are
                at least 18 years old, or the age of legal majority under applicable laws; (b) have not previously been
                suspended or removed from the Services; (c) have full power and authority to enter into these Terms and in
                doing so will not violate any other agreement to which you are a party; and (d) will not use the Services
                if the laws of your country prohibit you from doing so in accordance with these Terms. You further represent
                and warrant that if you are acting on behalf of a legal entity, that (x) you are authorized to accept these
                Terms on behalf of such entity, (y) such entity is duly organized, validly existing and in good standing
                in the laws of the country or state in which such entity is registered, and (z) such entity will be
                responsible for your violation of these Terms.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <span>
                    <strong>3. Registration and Account </strong>
                </span>
                In order to access Klickly’s real-time commerce platform made available via the Services, you
                may be required to create an account and provide certain registration information (“Registration Data”).
                By registering for an account via the Services, you agree (a) to create a strong password,
                (b) that all information provided in the Registration Data is true and accurate,
                (c) that you will maintain and update the Registration Data in order to keep it current,
                complete, and accurate, (d) to maintain the security of your account by protecting your password
                and restricting access to your account and any social media or other authentication provider
                account linked to your Klickly account, (e) to promptly notify Klickly if you discover or otherwise
                suspect any security breaches related to your account or any social media or other authentication
                provider account linked to your account, and (f) to take responsibility for all activities that occur
                under your account and accept all risks of any authorized or unauthorized access.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>4. Privacy Policy. </strong>
                As a User, you agree that our <a href="/help/privacy">Privacy Policy</a> and these Terms govern how
                Klickly collects, uses and shares your information.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">5. Buyer Obligations.</StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>5.1 Access </strong>
                As a Buyer, you are responsible for providing, at your own expense, all equipment necessary
                to use the Services, including a computer, modem, internet access, and payment of all fees
                associated with such access.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>5.2 Committing to a Transaction </strong>
                As a Buyer, you agree that you are responsible for reading the full description of any good,
                service or other item promoted by a Seller via the Services (collectively the "Promotion”),
                including any terms and conditions applicable to the Promotion (“Seller Terms”), before committing
                to any Transaction via the Services to any Seller. When you make a Transaction via the Services
                by responding to a Seller's call-to-action in the manner prescribed on the applicable Publisher
                platform, you enter into a legally binding contract with a) the applicable Seller to complete such
                Transaction and to receive any Promotion subject to the Seller Terms, and in each instance,
                b) with Klickly to create and/or update your Klickly account with accurate information.
                All shipments of Merchandise are limited to the United States only.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>5.3 Processing and Fulfilling a Transaction </strong>
                All processing and fulfillment activities are the responsibility of the Seller, including
                those activities that have been delegated to third-parties and/or to Klickly. As a Buyer,
                you agree to look solely to the applicable Seller to resolve all questions, claims, and
                disputes regarding any Transactions you make to a Seller via the Services.
                For Drop-Ship Transactions, you, as Buyer, consent to having the ultimate seller of
                the Merchandise contract you directly regarding order fulfilment and on other issues
                related to the Merchandise. With the exception of Drop-Ship Transactions,
                KLICKLY IS NOT RESPONSIBLE FOR ANY CLAIMS REGARDING ANY TRANSACTION OR RESOLVING
                ANY DISPUTES BETWEEN BUYERS AND SELLERS.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>5.4 Loyalty Program. </strong>
                Klickly may, at its discretion, make a loyalty program available to you that can include
                benefits such as cash back, rebates, discounts, credits, or other benefits (“Loyalty Benefits”).
                In order to obtain any available Loyalty Benefits you must comply with the applicable requirements
                of the Loyalty Benefits offer. If you qualify for any Loyalty Benefits you shall be solely
                responsible for any taxes that may apply to your receipt of the Loyalty Benefits.
                Klickly may terminate any loyalty program or the provision of Loyalty Benefits at any
                time with or without notice. Certain loyalty programs may require Buyers to write and
                publish reviews for Merchandise and/or the loyalty program, or allow Klickly to
                publish such reviews. You, as Buyer, hereby grant to Klickly a nonexclusive, royalty free,
                worldwide license to use, reproduce, display, distribute and publish such reviews.
                You grant Klickly a nonexclusive, royalty free, worldwide, license to use, reproduce,
                display, distribute, publish, adapt, modify and re-format any and all images,
                photographs, text, copy, content, descriptions, submissions, video, audio, and
                any other material included by you as part of such review.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">6 Seller Obligations</StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.1 Access </strong>
                As a Seller, you are responsible for providing, at your own expense,
                all equipment necessary to use the Services, including a computer,
                modem, internet access, and payment of all fees associated with such access.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.2 Publishing Promotions. </strong>
                As a Seller, you are responsible for (a) publishing Promotions via the Services;
                (b) establishing the price, inclusive of shipping, taxes (if applicable)
                and any other fees or charges, at which products, services, or items covered
                by the Promotion will be sold or made available through the Services;
                (c) providing and/or uploading Promotion information accurately, including images,
                dimensions, weight, text, warranty, disclaimers, warnings and any other information
                required; (d) providing and uploading availability information accurately; and (e)
                providing and/or uploading customer service information accurately, including contact
                information, email and phone number.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.3 Prohibited Promotions. </strong>
                As a Seller, you agree that you will not use the Services to post or link to any
                Promotion that:
            </StyledText>
            <StyledText as="div" type="descriptor1">
                <ul>
                    <li>
                        (a) infringes any third-party intellectual property rights (including copyright,
                        trademark, patent and trademark secrets) or other proprietary rights (including
                        rights of publicity or privacy);
                    </li>
                    <li>(b) constitutes libel or slander or is otherwise defamatory;</li>
                    <li>(c) is counterfeited, illegal, stolen, or fraudulent;</li>
                    <li>(d) breaches any agreements you have entered into with any third parties; or</li>
                    <li>(e) constitutes any of the following prohibited uses:</li>
                    <ul>
                        <li>(i) Direct money transmission</li>
                        <li>(ii) Adult-oriented products or services (of any media type)</li>
                        <li>
                            (iii) Collection agencies engaged in the collection of Uncollectible Debt (as
                            defined by the Payment Brands)
                        </li>
                        <li>(iv) Bankruptcy attorneys</li>
                        <li>(v) Credit repair agencies</li>
                        <li>(vi) Sports forecasting or odds making</li>
                        <li>(vii) Credit counseling or credit repair services</li>
                        <li>(viii) Credit protection / Identity theft protection</li>
                        <li>
                            (ix) Direct marketing - subscription merchants (those selling subscriptions
                            through negative option billing)
                        </li>
                        <li>(x) Infomercial merchants Internet/MOTO pharmacies</li>
                        <li>(xi) Internet/MOTO pharmacy referral sites</li>
                        <li>(xii) Internet/MOTO Firearm or Weapon Sales</li>
                        <li>(xiii) Internet/MOTO Tobacco Sales</li>
                        <li>(xiv) Drug Paraphernalia</li>
                        <li>(xv) Occult Materials</li>
                        <li>(xvi) Multi-level marketing businesses (e.g. pyramid selling)</li>
                        <li>(xvii) Inbound telemarketers</li>
                        <li>(xviii) Outbound telemarketers</li>
                        <li>(xix) Prepaid phone cards</li>
                        <li>(xx) Prepaid phone services</li>
                        <li>(xxi) Rebate-based businesses</li>
                        <li>(xxii) "Up-Sell" merchants</li>
                        <li>(xxiii) Bill payments</li>
                        <li>(xxiv) Escort services</li>
                        <li>
                            (xxv) Gambling or betting, including lottery tickets, raffles, casino gaming
                            chips, off-track betting, and wagers at race tracks
                        </li>
                        <li>
                            (xxvi) Financial Institutions - Manual Cash Disbursements* Financial
                            Institutions - Automated Cash Disbursements
                        </li>
                        <li>(xxvii) Financial Institutions - Promotion and Services</li>
                        <li>(xxviii) Non-Financial Institutions - Money Orders, Foreign Currency, etc.</li>
                        <li>(xxix) Wire Transfer Money Orders</li>
                        <li>(xxx) High-Risk Telemarketing Merchants</li>
                        <li>(xxxi) Service Station Merchants</li>
                        <li>(xxxii) Automated Fuel Dispensers (AFD)</li>
                    </ul>
                </ul>
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                Klickly reserves the right to add to or otherwise modify the foregoing prohibited uses.
                Klickly also reserves the right to remove or prohibit any Promotion from the Services at
                any time without notice.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.4 Seller Warranties </strong>
                As a Seller, you warrant that (a) all products, goods, or items in your Promotion
                (“Merchandise”) are genuine, authentic and not counterfeit; (b) unless otherwise agreed
                in writing, all Merchandise is new, first-quality Merchandise, free from all material
                defects in workmanship and design and are in the original manufacturers’ packaging;
                (c) where Klickly permits Seller to supply refurbished or reconditioned Merchandise,
                such Merchandise will be free from all material defects in workmanship and design and
                their appearance and functionality will be in "like-new" condition; (d) packaging and
                product descriptions for refurbished or reconditioned Merchandise specify that such
                Merchandise has been refurbished; (e) all documentation, images and copy relating to
                the Merchandise are materially accurate; and (f) the Merchandise is available such that,
                when a Buyer or Klickly commits to a Transaction, you commit to fulfill that Transaction
                in accordance with the shipping timeline stipulated in the Seller Terms. For Promotions
                that include services (“Seller Services”), you warrant that (a) all Seller Services will
                be provided in a professional and workmanlike manner; and (b) the Seller Services shall
                comply in all respects with applicable laws or regulations. Seller represents and warrants
                that the Seller Site Data (as defined below) and other data collected by Klickly in connection
                with Seller's websites (a) was collected with the proper notice and opt-out opportunities
                provided in accordance with applicable laws, and any applicable self-regulatory standards/guidance,
                and that any opt-out records have been properly identified; and (b) was not collected for use as
                a factor in establishing any consumer eligibility for (i) credit or insured used primarily for
                person, family or household purposes or (ii) employment purposes; or (iii) other purposes
                authorized under Section 604 of the Fair Credit Reporting Act (15 USC §168 1b) (“FCRA”);
                or (d) is not regulated by the FCRA or Gramm-Leach-Biley Act (15 USCA Section 6801 et. seq. (2000),
                (“GLBA”). Seller represents and warrants that it has legally compliant consent where required from
                each User for the collection, use and disclosure of the Seller Site Data and other data collected
                by Klickly in connection with Seller's websites, including any consent necessary for processing
                by downstream third parties.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.5 Publication Rights </strong>
                Once published via the Services, you, as a Seller, acknowledge that Klickly is able to
                display, promote and sell such Promotion and other creative advertising assets from your
                website (the “Advertising Assets”). You grant Klickly a nonexclusive, royalty free,
                worldwide license to use, reproduce, display, distribute and publish the intellectual
                property associated with the Promotion and Advertising Assets with any advertisement,
                display, promotion or sale of Promotion. You grant Klickly a nonexclusive, royalty free,
                worldwide, license to use, reproduce, display, distribute, publish, adapt, modify and
                re-format any and all images, photographs, text, copy, content, descriptions, submissions,
                video, audio, advertisements, and any other material submitted by Seller to Klickly ("Materials").
                You agree that the grant of the licenses set forth herein shall extend to Publishers and to
                third parties, including Affiliates, who publish and promote the Promotion via Publishers.
                Notwithstanding anything to the contrary, nothing in these Terms will prevent or impair
                Klickly's right to advertise, promote or sell Promotions without a license or permission
                as permitted under applicable law (e.g., fair use and first sale doctrine under copyright law,
                referential use under trademark law, or a valid license from a third party).
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.6 Setting Terms </strong>
                As a Seller, you agree to provide clear and conspicuous notice to Buyers of any terms (“Seller Terms”).
                The Seller Terms applicable to any Promotion will form a contract between you and any Buyer who commits
                to a Transaction with you. In fulfilling any Transaction, you will not require any payments or impose
                any conditions that were not disclosed in the Seller Terms.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.7 Processing a Transaction </strong>
                As a Seller, you agree that payment processing (e.g. card acceptance, merchant settlement,
                and related services) enabled by the Services for Transactions occurring via both the Site
                and Third-Party Publishers will be provided by either: (a) Stripe Payments ("Stripe"),
                that you are bound by Stripe’s Terms and Privacy Policy (
                <a href='https://stripe.com/us/terms'>https://stripe.com/us/terms</a> and{' '}
                <a href='https://stripe.com/us/privacy'>https://stripe.com/us/privacy</a> respectively),
                and that you consent and authorize Klickly and Stripe to share any information and payments
                instructions you provide with Third Party Service Provider(s) to the minimum extent required
                to complete your transactions; or b) a ACH payment.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.8 Seller Fees </strong>
                As a Seller, you agree to pay Klickly the fees for transactions completed via the Services
                ("Seller Fees") as set by Klickly in the applicable pricing document, platform, site page,
                and/or schedule (“Seller Price Schedule”) and consisting of fees paid to Klickly for use
                of the Services (“Service Fees”), fees paid to Klickly’s payment processing provider for
                payment processing (“Payment-Processing Fees”), and fees paid to Klickly and/or one or more
                third-party for any other reason (“Other Fees”). Any taxes collected by Klickly, if applicable,
                will be in addition to the Seller Fees. Klickly reserves the right to change the Seller Price
                Schedule at any time. Changes to the Seller Price Schedule are effective immediately upon
                communicating the revised Seller Price Schedule to you, and will apply prospectively to any
                transactions that take place following the effective date of such revised Seller Price Schedule.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.9 Errors; Refunds </strong>
                If we make an error in charging any Seller Fees, we will correct it promptly after you notify
                us and we have investigated and verified the error. As a Seller, you must notify us in writing
                within 30 days after an error first appears on your bill or online statement. If you do not
                report the error to us within such 30-day period, we will not be required to correct the error
                and you agree to pay the amount charged or debited. You release us from all liability and claims
                of loss resulting from any error that you do not report to us within 30 days after the error
                first appears on your bill or online statement. Other than as described in this section, or as
                required by law, we will not provide any refunds for Seller Fees.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.10 Chargebacks </strong>
                When a Buyer makes a payment as part of a commitment to Transact with you (as a Seller), and
                in the event of Drop-Ship Transactions, you are liable to the Buyer or Klickly for the full
                amount of their payment, plus any Seller Fees, if the payment is later invalidated for any reason.
                This means that you are responsible for reimbursing the Buyer or Klickly if you lose a chargeback
                claim or if there is a reversal of the payment; Klickly is under no obligation to reimburse any
                applicable Seller Fees as part of this process. For any transaction resulting in a chargeback,
                we may withhold the chargeback in a reserve or deduct the amount and any associated Seller Fees,
                including fines assessed by our processor or the card associations from your Bank Account or other
                funding source registered with us. You agree to allow us to share information about a chargeback
                with the payer and payer's financial institution and your financial institution to investigate or
                otherwise resolve a chargeback.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.11 Payment Method </strong>
                As a Seller, you are able to store payment method information through our Services via one
                or more Financial Accounts (“Payment Methods"). You represent and warrant that you are
                authorized to use such designated Payment Methods and you authorize us, or our designated
                payment processor, to store your Payment Method information and any other related personal
                information. If the Payment Methods cannot be verified, are invalid or are otherwise not
                acceptable to us, or our designated payment processor, your ability to accept payments from
                Buyers via the Services may be suspended immediately. You must resolve any problem Klickly,
                or our designated payment processor, encounters to continue accepting payments from Buyers.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.12 Transaction Payments and Payment of Sellers Fees. </strong>
                For payments made by Buyers (or Klickly, for Drop-Ship Transactions) for your Promotion(s)
                as part of a Transaction, whether via the Site or via a Third-Party Publisher, Klickly shall
                pass the payment on to you (minus any Payment-Processing Fees and associated taxes) via the
                Payment Method you provided to Klickly for the disbursement of funds. This payment to you
                shall be referred to as a “Transaction Payment”. Any Service Fees, Other Fees, or Chargebacks
                owed by you to Klickly, including associated taxes, will be drawn or debited by Klickly from
                the Payment Method you provided to Klickly for the payment of fees. Transaction Payments will
                be made upon the frequency set forth on Seller’s dashboard on the Site, except where Service Fees,
                Other Fees, or Chargebacks are owed, and in which case disbursement may be withheld until all
                amounts owed are paid in full. Klickly may, at its sole discretion, offset any Transaction Payments
                by any amounts you owe to Klickly. Klickly reserves the right to require a reserve amount to cover
                chargebacks, suspected fraudulent transactions or any breach of these Terms. Klickly reserves the
                right to suspend Buyer access to Seller’s Promotions and to suspend all Seller activity related to
                the Site in the event any fees due and owing to Klickly are delinquent or if the Payment Method is
                inoperable or invalid.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.13 Fulfilling a Transaction </strong>
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.13.1 Sales to Buyer. </strong>
                As a Seller, you are solely responsible for:
            </StyledText>
            <StyledText as="div" type="descriptor1">
                <ul>
                    <li>
                        (a) promptly fulfilling any Promotion purchased by any Buyer via the Services in
                        accordance with the Seller Terms, including shipping and ship-confirming each order
                        by the immediately succeeding business day from receipt of the order;
                    </li>
                    <li>
                        (b) collecting and remitting any value added, use, sales or other transfer taxes
                        owed to any national, state, or local taxing authorities in connection with the sale
                        of any Promotion;
                    </li>
                    <li>
                        (c) paying all costs (shipping, freight, handling, coordination, replacement, etc.)
                        related to the purchase, fulfillment and return of your Merchandise or Seller
                        Service;
                    </li>
                    <li>
                        (d) all customer service related to Transactions involving your Promotion, including
                        resolving any disputes with Buyers regarding any purchased Merchandise or Seller
                        Service, such as chargebacks or refunds;
                    </li>
                    <li>(e) paying for all costs related to customer service; and</li>
                    <li>
                        (f) any and all injuries, illnesses, damages, claims, liabilities and costs suffered
                        by you or any Buyer that purchases your Promotion, which are caused in whole or in
                        part by you or your Promotion, Merchandise, or Seller Service.
                    </li>
                </ul>
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.13.2 Drop-Ship Transactions. </strong>
                As a Seller, you are solely responsible for:
            </StyledText>
            <StyledText as="div" type="descriptor1">
                <ul>
                    <li>
                        (a) promptly fulfilling any Promotion purchased by Klickly in accordance with the
                        Seller Terms, including shipping and ship-confirming each order by the immediately
                        succeeding business day from receipt of the order;
                    </li>
                    <li>
                        (b) collecting and remitting any value added, use, sales or other transfer taxes
                        owed to any national, state, or local taxing authorities in connection with the sale
                        of any Promotion (if applicable);
                    </li>
                    <li>
                        (c) paying all costs (shipping, freight, handling, coordination, replacement, etc.)
                        related to the purchase, fulfillment and return of your Merchandise or Seller
                        Service;
                    </li>
                    <li>
                        (d) all customer service required by Klickly related to Transactions involving all
                        Merchandise purchased by Klickly, including resolving any disputes regarding any
                        purchased Merchandise or Seller Service, such as chargebacks or refunds (all
                        customer service required by Buyers for Drop-Ship Transactions shall be provided
                        directly by Klickly);
                    </li>
                    <li>(e) paying for all costs related to customer service you provide; and</li>
                    <li>
                        (f) any and all injuries, illnesses, damages, claims, liabilities and costs suffered
                        by you, Klickly or any Buyer which are caused in whole or in part by you or your
                        Promotion, Merchandise or Seller Service.
                    </li>
                </ul>
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.14 Shipping </strong>
                As a Seller, you are required to provide a standard shipping method for Merchandise.
                Shipping can be provided with a carrier of your choice, but delivery must be offered to Post
                Office Boxes, APO and FPO addresses. You agree to pay the carrier directly for all shipping charges.
                If the Merchandise is damaged or lost, you acknowledge that you are responsible for filing claims
                with the carrier to recover the cost of the Merchandise and freight.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.15 Ownership of User Information and License to Seller Site Data </strong>
                Klickly retains all rights and is the exclusive owner of all information regarding Users,
                including but not limited to Users names, addresses, telephone numbers, email addresses,
                purchase histories, payment methods and browsing histories ("User Information").
                As a Seller, you agree that you shall not use, publish, display, disseminate or copy the
                User Information without written permission, except to: (a) fulfill delivery of any Promotion;
                (b) perform internal analytics regarding Buyer engagement via the Services; and (c) contact
                Buyers via direct communications either (i) as necessary for the Drop-Ship Transaction;
                or (ii) in order to promote your Merchandise and Seller Services in accordance with applicable
                laws, provided that the User Information is not from a Drop-Ship Transaction.
                You expressly agree not to share User Information with any third party, except as required
                to comply with applicable laws or with any third party who conducts services on your behalf
                and who agrees to maintain the confidentiality and security of such information.
                You are fully responsible for any authorized or unauthorized collection, use, storage
                and disclosure of any User Information you receive or otherwise have access to via the Services.
                You agree to comply with all applicable data protection laws in connection with the User Information,
                to not cause by any act or omission Klickly to violate any such laws, and to upon request enter
                into an addendum containing reasonable terms as necessary for compliance with such laws.
                As a Seller, you acknowledge and agree that Klickly may receive or collect User data concerning
                User’s visits to your site (“Seller Site Data”). Seller Site Data includes but is not limited
                to precise User location data, personal directory data, statistical identification, pages viewed
                or visited by Users on your site, User experience data, data captured by cookies, eTags, web cache,
                and other non-cookie technologies. You hereby grant a perpetual, irrevocable, transferable,
                world-wide, royalty free license to Klickly to use Seller Site Data for any lawful purpose including
                but not limited to use for any internal processing, marketing and distribution/sub-licensing,
                interest-based advertising, cross-application advertising purposes, or sharing with service providers
                and other third parties, and agree to be solely responsible for providing a privacy policy on your
                site that provides notice and consent for the capture and use by Klickly of Seller Site Data as set
                out in this section and a notice that browser settings that block cookies may have no or limited effect
                on capture of Seller Site Data.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.16 Use of Marks; Publicity </strong>
                As a Seller, you grant Klickly a limited, revocable, royalty-free, non-transferable right to
                identify you as a customer or User of the Service and to use your name, marks and logos in
                marketing materials, in group press releases with other customers and on Klickly’s website.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.17 Applicable Laws and Rules. </strong>
                You, as a Seller, are solely responsible for, and agree to comply with:
                (a) all applicable laws; (b) any applicable rules of any payment network
                or association, including, without limitation, the Payment Card Industry
                Data Security Standard; and (c) any applicable rules of any advertising network or association.
                You acknowledge that you will notify Klickly immediately in writing if you receive notice of non-compliance of any applicable laws.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.18 Loyalty Program Participation. </strong>
                Klickly may, at its discretion, make a loyalty program available to Buyers that can include
                benefits such as cash back, rebates, discounts, credits, or other benefits (“Loyalty Benefits”).
                The Loyalty Benefits may result in your Merchandise or Seller Service being promoted at a reduced or
                discounted price. Unless otherwise agreed to by Klickly and Seller, any discounts in price associated
                with Klickly Loyalty Benefits shall be remitted to Seller by Klickly. Klickly may terminate any
                loyalty program or the provision of Loyalty Benefits at any time with or without notice.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>6.19 Seller Data Protection. </strong>
                Seller agrees that it has and will maintain an easily accessible privacy notice that (i) is linked to
                conspicuously from Seller's home page (with a link that contains the word "Privacy"); (ii) conveys that
                Supplier collects data regarding users’ online activities over time and across non-affiliated sites for
                the purpose of using such data to predict user preferences or interests to deliver advertising to a
                computer or device based on the preferences or interests inferred from such users’ online activities;
                (iii) conveys that cross-device data collection is taking place (including the purpose of cross-device data collection,
                the mechanisms available to end users to opt out of such data collection in browsers and mobile apps);
                (iv) links to industry-developed websites or other tools that permit users to opt out of such data collection practices;
                (v) complies with all applicable laws; and (vi) expressly identifies a link to its opt-out page(s);
                and discloses how Seller and Klickly collect, use and share data, including (a) categories collected,
                purposes collected or used, and whether such information is sold or shared;
                (b) Sensitive Personal Information (as defined in any applicable law) collected, purposes collected or used,
                and whether such information is sold or shared; and (c) the length of time of retention of each category
                of personal information. Seller shall obtain legally compliant consent where required from each User
                for the collection, use and disclosure of data by Seller and Seller Affiliates, including third parties
                to further process the data. Seller is making California personal information available to Klickly only
                for the limited and specified purposes set forth within these Terms. Klickly shall comply with all applicable
                sections of the CCPA and provide the same level of privacy protection as required of businesses by the CCPA.
                Seller may require Klickly to attest that it treats the personal information the business made available
                to it in the same manner that the business is obligated to treat it under the CCPA. Seller may require
                Klickly to provide documentation that verifies that Klickly honors requests to opt-out of sale/sharing
                forwarded to them it by Seller. Klickly shall promptly notify Seller if Klickly makes a determination
                that it can no longer meet its obligations under the CCPA. Seller shall disclose that it sells/shares
                personal information with third-parties and promptly forward any opt-outs or deletion requests received to Klickly.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                7. Third-Party Publisher Obligations
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>7.1 Access </strong>
                As a Third-Party Publisher, you are responsible for providing, at your own expense, all
                equipment necessary to use the Services, including a computer, modem, internet access, and
                payment of all fees associated with such access.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>7.2 Publishing Promotions. </strong>
                As a Third-Party Publisher, you are responsible for publishing Promotions made available via
                the Services, on your and your partners’ respective websites, social media services, emails,
                other communication services, Internet-powered applications, video, mobile, smart phone,
                tablet and other wireless devices and any other Internet-accessible material (the “Publisher
                Properties”).
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>7.3 Prohibited Content. </strong>
                As a Third-Party Publisher, you agree that you will not publish Promotions, made available via the Services,
                on Publisher Properties that contain, promote, reference or have links to indecent, obscene or
                pornographic or sexually explicit materials, hate material or hate speech, violence, discrimination
                based on race, sex, religion or nationality, hacking material, promotion of any internet abuse,
                illegal activities, material infringing intellectual property, infringement of privacy, defamation,
                or any other materials deemed inappropriate or harmful to the reputation of Klickly, to Partners
                or to third-parties (“Prohibited Content”).
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>7.4 Third-Party Publisher Warranties. </strong>
                As a Third-Party Publisher, you represent and warrant that (a) each Publisher Property that
                displays Promotions via the Services shall not violate any applicable laws or regulations,
                shall not contain Prohibited Content, and has not been previously rejected by Klickly;
                (b) you will not and will not permit any third party to, directly or indirectly: (i) access,
                launch or activate the Klickly Services through or from, or otherwise incorporate the Klickly
                Services in, any software application, website or other means other than the Publisher
                Properties; (ii) transfer, sell, lease, syndicate or otherwise sublicense the Klickly Services;
                (iii) generate queries, or impressions of or clicks on ads, through any automated, deceptive,
                fraudulent or other invalid means; (iv) encourage or require end users or any other persons,
                either with or without their knowledge, to click on the advertisements displayed through the Klickly
                Services through offering methods that are manipulative, deceptive, malicious or fraudulent;
                (v) pass any data to Klickly that could be used to personally identify any person without obtaining
                such person’s consent to pass such data to Klickly; and (c) you have and will maintain all rights,
                authorizations and licenses (including without limitation any copyright, trademark, patent,
                publicity or other rights) that are required with respect to the Publisher Properties and to
                permit Klickly to provide the Klickly Services to Publisher as contemplated under these Terms.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>7.5 Inventory Quality. </strong>
                Klickly tests the quality of advertising impressions that come to the Klickly platform from
                Publisher Properties. This testing includes, without limitation, (i) risk of fraud,
                (ii) brand safety violations and (iii) advertisement viewability constraints with respect
                to inventory of publisher impressions (“Inventory Quality”), and is undertaken by the use
                of proprietary tools which provide scoring and monitoring services focused on Inventory Quality.
                If Klickly detects a high risk of quality violation on Publisher Properties through use of
                such tools and services or otherwise (“Quality Risk”), then Klickly may either (i) reject that
                impression, and/or (ii) immediately suspend traffic for such Publisher Properties on written
                notice, including email, to the Third-Party Publisher. In the event that Klickly becomes aware
                of Inventory Quality violations with respect to Impressions that have been served on a
                Publisher Property, then Klickly shall have the right to (a) withhold Third-Party Publisher’s
                monthly payments until Klickly has completed its good faith investigation with respect to such
                Impressions, and (b) where Klickly determines that there have been Inventory Quality violations
                for all or a portion of such Impressions (the “Fraudulent Impressions”), Klickly may reduce
                pending monthly payments to Third-Party Publisher by the amount, or by any amount less than the
                amount, otherwise payable to Third-Party Publisher for the Fraudulent Impressions, and pay
                Publisher the resulting net amount in the ordinary course. If there are Fraudulent Impressions
                served to Klickly on a repeated basis with respect to one or more Publisher Properties,
                Klickly shall have the right to immediately suspend traffic for some or all Publisher Properties
                on written notice, including email, until the levels of Fraudulent Impressions have been reduced
                to acceptable levels. In accordance therewith, Third-Party Publisher shall provide Klickly with
                complete transparency with respect to the domain for each such Publisher Property at all times
                during the Term, and shall comply, and reasonably assist upon request, with the Inventory Quality
                controls and procedures (including whitelisting and blacklisting) that Klickly may institute.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>7.6 Publisher Revenue. </strong>
                As a Third-Party Publisher, you can generate revenue in exchange for publishing Promotions
                on Publisher Properties (“Publisher Revenue”) as set by Klickly in the applicable insertion
                order, platform, site page, or schedule (“Publisher Price Schedule”). Changes to the Publisher
                Price Schedule are effective immediately upon communicating the revised Publisher Price
                Schedule to you, and will apply prospectively to any transactions that take place following
                the effective date of such revised Publisher Price Schedule.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>7.7 Taxes. </strong>
                As a Third-Party Publisher, you agree to pay all taxes (including excise, sales, use, consumption,
                value-added or withholding taxes), customs or import duties, or any other taxes, levies, tariffs,
                duties or governmental fees that are due or payable in connection with your publishing of Promotions
                made available via the Services or in connection with any Publisher Revenue, with the exception of
                taxes on Klickly’s net income. You agree to cooperate in good faith with respect to reasonable
                requests from Klickly regarding Tax-related forms, documentation or other information that may be
                necessary or appropriate.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>7.8 Publisher Reporting. </strong>
                As a Third-Party Publisher, you may receive a password-protected web page on the Klickly website
                where you, through Klickly’s proprietary user interface, shall be able to access all records of
                Klickly delivering advertising to you, including impression reports, revenue reports, reports on
                eCPM (i.e., effective CPM) and other applicable measures (“Publisher Reporting”). You acknowledge
                that any and all numbers displayed in Publisher Reporting are estimates and do not represent actual
                numbers for billing purposes.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>7.9 Errors; Refunds. </strong>
                If we make an error in calculating or disbursing any Publisher Revenue, we will correct it promptly
                after you notify us and we have investigated and verified the error. As a Third-Party Publisher,
                you must notify us in writing within 30 days after an error first appears on your bill or online statement.
                If you do not report the error to us within such 30-day period, we will not be required to correct the error.
                You release us from all liability and claims of loss resulting from any error that you do not report to
                us within 30 days after the error first appears on your bill or online statement.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>7.10 License to Publisher Site Data. </strong>
                As a Third-Party Publisher, you acknowledge and agree that Klickly may receive or collect User data concerning
                User’s visits to your site (“Publisher Site Data”). Publisher Site Data includes but is not limited to precise
                User location data, personal directory data, statistical identification, pages viewed or visited by Users on your
                site, User experience data, data captured by cookies, eTags, web cache, and other non-cookie technologies.
                You hereby grant a perpetual, irrevocable, world-wide, royalty free license to Klickly to use Publisher Site Data
                for any lawful purpose including but not limited to use for interest-based advertising or cross-application
                advertising purposes, and agree to be solely responsible for providing a privacy policy on your site that provides
                notice and consent for the capture and use by Klickly of Publisher Site Data as set out in this section and a
                notice that browser settings that block cookies may have no or limited effect on capture of Publisher Site Data.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>7.11 Use of Marks; Publicity. </strong>
                As a Third-Party Publisher, you grant Klickly a world-wide, limited, revocable, royalty-free, non-transferable
                right to identify you as a customer or User of the Service and to use your name, marks and logos in marketing
                materials, in group press releases with other customers and on Klickly’s website.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                <strong>7.12 Monetization Services. </strong>
                Klickly may, at its discretion, make website and social media monetization available to Third-Party
                Publishers in a way that can involve Promotion sources other than Sellers, including but not limited
                to demand side platforms, ad exchanges, agencies, agency trading desks and ad networks
                (each a “Demand Partner”), and resulting in CPM (i.e., Cost Per Thousand Impressions),
                CPC (i.e., Cost Per Click), CPV (i.e., Cost Per View), CPE (i.e., Cost Per Engagement) and
                CPA (i.e., Cost Per Acquisition) campaigns, revenue share campaigns, real-time bidding campaigns,
                and hybrid variations thereof to accommodate the specific needs of buy-side parties with the view
                to ensuring the highest yield possible for the Third-Party Publisher (“Monetization Strategies”).
                These Monetization Strategies may result in third-party promotions being displayed on your
                Publisher Properties. Where this Service is enabled, any reference to Sellers throughout these
                Terms shall include Demand Partners, and any reference to Promotions throughout these Terms shall
                include promotions sourced by Demand Partners. Klickly may terminate any website or social media
                monetization or the provision of Monetization Strategies at any time with or without notice.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">8. User Content</StyledText>
            <br />
            <StyledText type="descriptor1">
                The Services may include interactive areas where you or other Users can create, post or store messages,
                text, files, images, photos, video, sounds, Promotion descriptions or other materials via the Services
                (“User Content"). You are entirely responsible for any User Content that you post, email or otherwise
                make available via the Services. You understand that Klickly does not control, and is not responsible
                for User Content, and that by using the Services, you may be exposed to User Content that is offensive,
                indecent, inaccurate, misleading, or otherwise objectionable. In addition, the User Content may
                contain links to other websites, which are completely independent of Klickly, and Klickly makes no
                warranty as to the accurateness, completeness or authenticity of the information contained in any such
                third-party site. You agree that you must evaluate, and bear all risks associated with, the use of any
                User Content, that you may not rely on said User Content, and that under no circumstances will Klickly
                be liable in any way for any User Content or any loss or damage of any kind incurred as a result of the
                use of any User Content. You acknowledge that Klickly does not pre-screen or approve User Content,
                but that Klickly shall have the right (but not the obligation) in its sole discretion to refuse, delete
                or move any User Content, for violating the letter or spirit of these Terms or for any other reason.
                You agree not to post, create or otherwise publish through the Services, User Content that we believe,
                in our sole discretion:
            </StyledText>
            <br />
            <StyledText as="div" type="descriptor1">
                <ul>
                    <li>
                        (a) is unlawful, libelous, defamatory, harassing, threatening, invasive of privacy
                        or publicity rights, or that would otherwise create liability or violate any local,
                        state, national or international law;
                    </li>
                    <li>
                        (b) contains nudity, sexually explicit content or is otherwise obscene,
                        pornographic, indecent, lewd, suggestive or sexually exploitative of minors;
                    </li>
                    <li>
                        (c) may disparage any ethnic, racial, sexual or religious group by stereotypical
                        depiction or is otherwise abusive or inflammatory;
                    </li>
                    <li>(d) depicts, promotes the use of, or offers the sale of illicit drugs;</li>
                    <li>(e) contains offensive language or images or is otherwise objectionable;</li>
                    <li>
                        (f) incites violence or characterizes violence as acceptable, glamorous or
                        desirable;
                    </li>
                    <li>
                        (g) contains private or personal information about another person, unless such
                        person has agreed to the disclosure of this information;
                    </li>
                    <li>
                        (h) contains viruses, corrupted data or other harmful, disruptive or destructive
                        files viruses, cancel bots, Trojan horses, harmful code, flood pings,
                        denial-of-service attacks, packet or IP spoofing, forged routing or electronic mail
                        address information or similar methods or technology;
                    </li>
                    <li>
                        (i) may infringe any patent, trademark, trade secret, copyright or other
                        intellectual or proprietary right of any party; or
                    </li>
                    <li>
                        (j) would constitute, encourage or provide instructions for a criminal
                        offense in any jurisdiction or violate the rights of any third party.
                        By submitting or posting User Content via the Services, you grant Klickly
                        a nonexclusive, royalty-free, perpetual, irrevocable and fully sublicensable
                        right to use, reproduce, modify, publish, translate, create derivative works
                        from, distribute, perform and display such User Content on the Services and
                        on third-party sites to maintain, improve, operate and provide the Services.
                        We will contact you directly to obtain permission before we use your User Content
                        for our own commercial purposes, unless you expressly grant these rights to
                        Klickly at the time you upload such content. If you choose to remove your
                        User Content, the license granted above will automatically expire; however,
                        you acknowledge that there may be exceptions (for example, you cannot delete
                        your purchase history). In addition, we may retain archived copies of your
                        User Content and cached copies of your User Content may still be available
                        for some period of time. By posting User Content to the Services, you represent
                        and warrant that: (i) such User Content is non-confidential; and (ii) you own
                        and control all of the rights, title and interest in and to the User Content
                        or you otherwise have all necessary rights to post such User Content to the
                        Services and to grant the rights to Klickly that you grant in these Terms.
                    </li>
                </ul>
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                Klickly reserves the right to add to or otherwise modify the foregoing unacceptable list of
                User Content in its sole discretion. By submitting or posting User Content via the Services,
                you grant Klickly a nonexclusive, royalty-free, perpetual, irrevocable and fully sublicensable
                right to use, reproduce, modify, publish, translate, create derivative works from, distribute,
                perform and display such User Content on the Services and on third-party sites to maintain,
                improve, operate and provide the Services and for Klickly’s commercial purposes.
                If you choose to remove your User Content, the license granted above will automatically expire;
                however, you acknowledge that there may be exceptions (for example, you cannot delete your
                purchase history, and the license remains on User Content Klickly has shared with others in
                reliance on such license). In addition, we may retain archived copies of your User Content
                and cached copies of your User Content may still be available for some period of time.
                By posting User Content to the Services, you represent and warrant that: (i) such User
                Content is non-confidential; and (ii) you own and control all of the rights, title and
                interest in and to the User Content or you otherwise have all necessary rights to post such
                User Content to the Services and to grant the rights to Klickly that you grant in these Terms.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">9. User Conduct</StyledText>
            <br />
            <StyledText type="descriptor1">
                You must use the Services in a manner consistent with any and all applicable laws and regulations,
                including any local, state, national or international laws and regulations, and you are solely
                responsible for your conduct while using the Services. In addition, you agree not to:
            </StyledText>
            <br />
            <StyledText as="div" type="descriptor1">
                <ul>
                    <li>
                        (a) use the Services to victimize, harass, degrade, or intimidate anyone on the
                        basis of religion, gender, sexual orientation, race, ethnicity, age, or disability;
                    </li>
                    <li>
                        (b) infringe on any patent, trademark, trade secret, copyright, right of publicity,
                        or other proprietary right of any party;
                    </li>
                    <li>
                        (c) use the Services in connection with the distribution of any unauthorized or
                        unsolicited advertising, junk or bulk email (also known as "spamming"), chain
                        letters, any other form of unauthorized solicitation, or any form of lottery or
                        gambling;
                    </li>
                    <li>
                        (d) impersonate any person or entity, including any of our employees or
                        representatives, or falsely state or otherwise misrepresent your affiliation with a
                        person or entity;
                    </li>
                    <li>
                        (e) use any data mining, robots, or other data extraction or collection methods;
                    </li>
                    <li>(f) reverse engineer or decompile any parts of the Services;</li>
                    <li>
                        (g) aggregate, copy or duplicate any User Content other than with written permission
                        of the applicable User or as otherwise permitted by these Terms;
                    </li>
                    <li>(h) create more than one account for the same User;</li>
                    <li>
                        (i) employ misleading email addresses, or forged headers or otherwise manipulate
                        identifiers in order to disguise the origin of User Content transmitted through the
                        Services;
                    </li>
                    <li>(j) share your or any other User's login credentials with any third party;</li>
                    <li>
                        (k) Use the Services in connection with any "affiliate marketing," "link referral
                        code," "junk mail," "spam," "pyramid schemes," or "unsolicited commercial
                        advertisement";
                    </li>
                    <li>(l) stalk or otherwise harass anyone;</li>
                    <li>
                        (m) collect any personal information about other Users for commercial or unlawful
                        purposes, unless expressly permitted under these Terms; and
                    </li>
                    <li>
                        (n) attempt to gain unauthorized access to Klickly's computer systems or another
                        User's account or engage in any activity that disrupts, diminishes the quality of,
                        interferes with the performance of, or impairs the functionality of, the Services.
                    </li>
                </ul>
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                We reserve the right, but have no obligation, to monitor interactions between you and
                other Users of the Services and to add to or otherwise modify the list of prohibited
                activities regarding the Services.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">10. Third-Party Content</StyledText>
            <br />
            <StyledText type="descriptor1">
                The Services may link you to other sites on the Internet or otherwise include references
                to information, documents, software, materials and/or services provided by other parties
                ("Third-Party Content"). Third-Party Content may contain information or material that some
                people may find inappropriate or offensive. Third Party Content is not under our control,
                and you acknowledge that we are not responsible for the accuracy, copyright compliance,
                legality, decency, or any other aspect of the content of such Third-Party Content, nor are
                we responsible for errors or omissions in any references to other parties or their products
                and services. The inclusion of Third-Party Content is provided merely as a convenience and
                does not imply endorsement by Klickly or association of the Services with such content.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">11. Confidentiality.</StyledText>
            <br />
            <StyledText type="descriptor1">
                As a Seller or Publisher, you may exchange Information with Klickly or receive information
                from Klickly, which includes information regarding a Seller, Publisher, Klickly or third party
                not generally known to the public (“Confidential Information”). Confidential Information shall
                include without limitation information regarding the Klickly Services not generally known to the public.
                Confidential Information shall not include User Content. Each will treat such Confidential Information
                as confidential and proprietary both during the Term and for a period of 3 years after the Term.
                Notwithstanding the foregoing, the confidentiality obligations relating to trade secrets shall last
                in perpetuum. Each Party agrees it will not use the Confidential Information for any purpose other
                than in accordance with these Terms. Each Party agrees it will take reasonable precautions to
                maintain the confidentiality of the Confidential Information. Each Party agrees it will not disclose
                or otherwise furnish the Confidential Information to any third party other than such Party's
                employees who need to know the Confidential Information to perform such Party's obligations under
                these Terms, provided such employees are contractually obligated to such Party to maintain the
                confidentiality of the other Party's Confidential Information. Notwithstanding the foregoing,
                a Party may disclose Confidential Information if such Confidential Information: (i) was lawfully
                obtained by it other as a result of a violation of these Terms and on a non-confidential basis
                from a third-party under no obligation of confidentiality; (ii) is independently developed by a
                Party without use of any Confidential Information as long as the development of such information
                not based on, and is not a result of, whether directly or indirectly, obtaining any Confidential
                Information or violating these Terms; (iii) is or becomes general public knowledge other than as
                a result of a violation of these Terms or other obligation of confidentiality, all of the foregoing
                only as demonstrated by written record. Each Party agrees to be responsible for the violation of
                this clause by its employees and agents. Upon the termination or expiration of these Terms, each
                Party shall return or destroy all of the other Party's Confidential Information in such Party's
                possession. Each Party acknowledges that any unauthorized disclosure or use of the Confidential
                Information would cause the other Party imminent irreparable injury and that such Party will be
                entitled to, in addition to any other remedies available at law or in equity, temporary, preliminary,
                and permanent injunctive relief in the event the other Party does not fulfill its obligations under
                this Section.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                12. Intellectual Property Rights
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                Unless otherwise indicated, the Services and all content and other materials on the Services,
                including, without limitation, the Klickly logo, and all designs, text, graphics, pictures, videos,
                data, software, and other files, and the selection and arrangement thereof, (collectively, the
                "Klickly Materials”) are the proprietary property of Klickly or our licensors and are protected
                to the maximum extent permitted by U.S. and international copyright laws and international treaties.
                The Services are ©2021 Klickly, Inc. All Rights Reserved.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                Any technology or code used to create or display any User Content or the pages making up the Services
                is also protected by copyright and you may not copy or adapt such code. You agree that Klickly's technology
                is proprietary and shall not be used in any way other than the methods and practices stipulated in these Terms.
                To this end, you agree not to reverse engineer or decompile any part of this technology. Should you develop,
                either solely or jointly with others or with Klickly, any improvements in the technology or any intellectual
                property related thereto (collectively, “Developments”), where these Developments are based on or result from
                access to Confidential Information, you agree to assign and do hereby assign to Klickly, for consideration
                herein acknowledged, all of Recipient’s right, title, and interest in and to said Developments.
                Any such Developments shall be included within the definition of Confidential Information for purposes
                of these Terms.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                We hereby grant you a limited, non-exclusive and non-sublicensable license to access
                and use the Services and the Klickly Materials; however, such license is subject to
                these Terms and does not include: (a) any resale or commercial use of the Services or
                the Klickly Materials, except as expressly permitted under these Terms; (b) the distribution,
                public performance or public display of any Klickly Materials; (c) modifying or otherwise
                making any derivative uses of the Services and the Klickly Materials, or any portion thereof,
                unless modification or derivative use is specifically provided for under separate terms;
                (d) downloading (other than the page caching) of any portion of the Services, the Klickly
                Materials or any information contained therein, except as expressly permitted on the Services;
                or (e) any use of the Services or the Klickly Materials other than for their intended purposes.
                Any use of the Services or the Klickly Materials other than as specifically authorized herein,
                without the prior written permission of Klickly, is strictly prohibited and will terminate the
                license granted herein. Unless explicitly stated herein, nothing in these Terms shall be
                construed as conferring any license to intellectual property rights, whether by estoppel,
                implication or otherwise. This license is revocable at any time.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                Neither we or our Affiliates warrant or represent that your use of Klickly Materials displayed on,
                or obtained through, the Services will not infringe the rights of third parties. You agree that you
                will not assert, nor will you authorize, assist, or encourage any third party to assert, now or in
                the future, against Klickly or its Affiliates, any patent infringement or other intellectual property
                infringement claim regarding the Services including, without limitation, any products or services
                ordered from Sellers.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                "Klickly," the Klickly logo, and any other service name or slogan contained on the Services are
                trademarks of Klickly or its Affiliates, and may not be copied, imitated or used, in whole or in
                part, without the prior written permission of Klickly or the applicable trademark holder.
                You may not use any metatags or any other "hidden text" utilizing "Klickly" or any other name,
                trademark or product or service name of Klickly in a manner that may create a likelihood of
                consumer confusion without our prior written permission. In addition, the look and feel of the
                Services, including all page headers, custom graphics, button icons and scripts, is the service
                mark, trademark and/or trade dress of Klickly and may not be copied, imitated or used, in whole
                or in part, without our prior written permission. All other trademarks or service marks, trade
                names or logos appearing on the Services or in these Terms are property of their respective owners.
                Nothing in these Terms grants you any right to use any trademark, service mark, logo, and/or the name of Klickly.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                13. Discontinuance of Services
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                Klickly retains the right to determine the content, appearance, design, functionality and all
                other aspects of the Services (including the right to re-design, modify, remove and alter the
                content appearance, design, functionality, and other aspects of the Services and any element,
                aspect, portion, or feature thereof, from time to time), and to delay, suspend, or refuse access
                to the Services or to require a User not to use the Services in our sole discretion, without
                liability to you. We may in our sole discretion withhold for investigation, refuse to process,
                stop or cancel any of your Transactions processed through the Services.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                14. Infringers; Copyright Complaints
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                In accordance with the Digital Millennium Copyright Act and other applicable law, Klickly
                has adopted a policy of terminating, in appropriate circumstances and in Klickly's sole
                discretion, Users who are deemed to be infringers. Klickly may also, in our sole discretion,
                limit access to the Services and/or terminate the accounts of any Users who we believe may
                infringe any intellectual property rights of others.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                If you believe that anything on the Services infringes upon any copyright which you own or
                control, you may file a notification of such infringement with our Designated Agent as set forth below.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                15. Designated Agent for Claimed Infringement:
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                Contact: JD Harriman Address: Klickly, Inc., 2219 Main St., #166, Santa Monica, CA 90405
                Phone: <a href='tel:4242726573'>424-272-6573</a> Email: <a href='mailto:privacy@klickly.com'>privacy@klickly.com</a>
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                Please see 17 U.S.C. §512(c)(3) for the requirements of a proper notification.
                You should note that if you knowingly misrepresent in your notification that the
                material or activity is infringing, you will be liable for any damages, including
                costs and attorneys' fees, incurred by us or the alleged infringer as the result
                of our relying upon such misrepresentation in removing or disabling access to the
                material or activity claimed to be infringing. Klickly also reserves the right to
                forward the information in the copyright-infringement notice to the user who allegedly
                provided the infringing content.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                16. Disclaimer of Warranties
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                ALL KLICKLY MATERIALS AND THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS
                WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE
                WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT
                (A) THE SERVICES AND KLICKLY MATERIALS WILL MEET YOUR REQUIREMENTS, (B) THE SERVICES
                AND KLICKLY MATERIALS WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE,
                (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES OR KLICKLY MATERIALS
                WILL BE EFFECTIVE, ACCURATE OR RELIABLE, OR (D) THE QUALITY OF ANY PROMOTION, PRODUCTS,
                SERVICES, OR INFORMATION PURCHASED OR OBTAINED BY YOU VIA THE SERVICES WILL MEET YOUR
                EXPECTATIONS OR BE FREE FROM MISTAKES, ERRORS OR DEFECTS.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                THE SERVICES COULD INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES OR TYPOGRAPHICAL ERRORS.
                WE MAY MAKE CHANGES TO THE KLICKLY MATERIALS AND THE SERVICES, INCLUDING THE PRICES AND
                DESCRIPTIONS OF ANY PROMOTION OR LOYALTY PROGRAM, AT ANY TIME WITHOUT NOTICE. THE MATERIALS
                OR THE SERVICES MAY BE OUT OF DATE, AND WE MAKE NO COMMITMENT TO UPDATE SUCH KLICKLY
                MATERIALS OR THE SERVICES.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                THE USE OF THE SERVICES OR THE DOWNLOADING OR OTHER ACQUISITION OF ANY KLICKLY MATERIALS IS
                DONE AT YOUR OWN DISCRETION AND RISK AND WITH YOUR AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE
                FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH ACTIVITIES.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                17. Limitation of Liability
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                IN NO EVENT SHALL WE OR OUR AFFILIATES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY SPECIAL,
                PUNITIVE, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING, WITHOUT LIMITATION,
                THOSE RESULTING FROM LOSS OF USE, LOSS OF DATA OR PROFITS, LOSS OF OPPORTUNITY, LOSS OF GOODS OR
                ANY ASSOCIATED EQUIPMENT, COST OF SUBSTITUTE OR REPLACEMENT GOODS OR ANY ASSOCIATED EQUIPMENT,
                COST OF CAPITAL, LOST DATA, FACILITIES OR SERVICES, DOWN TIME, OR SUMS PAID BY USERS, WHETHER
                OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND ON ANY THEORY OF LIABILITY,
                ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE SERVICES OR OF ANY WEB SITE REFERENCED OR
                LINKED TO FROM THE SERVICES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY WHETHER ANY CLAIM
                IS BASED UPON THE PRINCIPLES OF CONTRACT, WARRANTY, NEGLIGENCE OR OTHER TORT, BREACH OF ANY
                STATUTORY DUTY, PRINCIPLES OF INDEMNITY OR CONTRIBUTION, THE FAILURE OF ANY LIMITED OR EXCLUSIVE
                REMEDY TO ACHIEVE ITS ESSENTIAL PURPOSE, OR OTHERWISE
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                FURTHER, WE SHALL NOT BE LIABLE IN ANY WAY FOR SELLER PROMOTIONS OR THIRD-PARTY CONTENT OFFERED
                OR ACCESSIBLE THROUGH THE SERVICES OR FOR ASSISTANCE IN CONDUCTING COMMERCIAL TRANSACTIONS
                THROUGH THE SERVICES, INCLUDING WITHOUT LIMITATION THE PROCESSING OF ORDERS. IN NO EVENT SHALL
                KLICKLY OR ANY AFFILIATE BE LIABLE TO ANY USER (OR ANY OTHER PARTY) FOR LOSS, DAMAGE OR INJURY
                OF ANY KIND OR NATURE ARISING OUT OF OR IN CONNECTION WITH THESE TERMS IN EXCESS OF THE SELLER
                FEES PAID TO KLICKLY FOR ACCESS TO OR USE OF THE SERVICES. IN THE EVENT NO SELLER FEES HAVE BEEN PAID,
                THE LIMITATION OF LIABILITY FOR KLICKLY UNDER THIS SECTION SHALL BE FIVE HUNDRED DOLLARS ($500.00).
                FOR ALL BETA PROGRAMS AND RELATED SERVICES, THE LIABILITY OF KLICKLY UNDER THIS SECTION SHALL
                BE FIFTY DOLLARS ($50.00).
            </StyledText>
            <br />
            <StyledText type="badgeLarge">18. Release</StyledText>
            <br />
            <StyledText type="descriptor1">
                If you are a California resident, you waive California Civil Code section 1542, which says:
                "A general release does not extend to claims which the creditor does not know or suspect
                to exist in his favor at the time of executing the release, which, if known my him must
                have materially affected his settlement with the debtor."
            </StyledText>
            <br />
            <StyledText type="badgeLarge">19. Indemnification</StyledText>
            <br />
            <StyledText type="descriptor1">
                You agree to, at your own cost and expense, defend, indemnify, and hold us and our
                Affiliates harmless from all claims, allegations, litigation, lawsuits, causes of action,
                inquiries, investigations, fines, fees, penalties and expenses (including but not limited
                to attorney fees and costs of court) ("Claims") arising out of or related to (a) your use
                or conduct in connection with the Services, (b) any Feedback (as defined in Section 26 below)
                you provide; (c) your violation of these Terms; (d) your violation of any rights of any other
                person or entity; (e) claims of copyright, patent, trademark or other intellectual property
                right related to the Merchandise or use of trademarks or copyrighted material; (f) the death
                of or injury to a person or property arising out of or related to the Merchandise; (g) the
                failure of the Merchandise to comply with any express or implied warranties; (h) the packaging,
                packing, tagging, labeling or advertising of such Merchandise; (i) the failure to provide
                adequate warnings and/or instructions related to the use, assembly, service or installation
                of such Merchandise; (j) any Tax imposed or assessed on Klickly which is legally payable by you;
                (k) the Publisher Site Data, services or products supplied by a Publisher to Klickly pursuant
                to these Terms, including but not limited to patent, copyright, trademark or other intellectual
                property infringement claims; (l) violation by other Users or the Merchandise of any applicable
                law, rule, regulation or order; (m) the placement and use of User Content supplied to Klickly
                pursuant to these terms, including, but not limited to patent, copyright, trademark or other
                intellectual property infringement claims; (n) the failure to ship the Merchandise in accordance
                with the terms of shipping between a Seller and Buyer or Seller and Klickly, as applicable.
            </StyledText>
            <StyledText type="descriptor1">
                We reserve the right, at our own expense, to assume the exclusive defense and control of any
                matter otherwise subject to indemnification by you, in which event you will cooperate with us in
                asserting any available defenses.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                We will promptly notify you of all Claims. Where Claims implicate Merchandise sourced by a
                Seller only ("Single Source Claims"), Klickly will tender the defense of such Single Source
                Claims to the Seller. Seller's choice of counsel shall be reasonably satisfactory to Klickly.
                Seller shall have sole control over the defense of such Single Source Claims; except that Seller
                shall not settle or compromise Single Source Claims that impair or impose any obligation on
                Klickly without Klickly's prior written consent. Klickly may participate in the defense of all
                Single Source Claims with counsel of its own choice at its own expense. Where Claims implicate
                products sourced by multiple Sellers ("Multi Source Claims"), Klickly, in its sole discretion,
                may tender the defense of Seller's portion of such Multi Source Claims to a Seller or group of
                Sellers, or upon notice, defend, settle or compromise such Multi Source Claims for all Sellers.
                If Klickly elects to defend Multi Source Claims for all Sellers, Seller shall pay its proportionate
                share of all costs and fees related to the defense, settlement or compromise of such Multi Source
                Claims, and pay its proportionate share of damages, if any. Seller agrees to reasonably cooperate
                with Klickly in its defense of such Multi Source Claims and periodically pay the assessed defense
                costs during such defense. If a judgment is rendered against Klickly, Seller shall promptly pay
                such judgment or appeal as permitted under applicable law.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                20. Participation in Third-Party Promotions
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                From time to time, the Services may include advertisements for promotions offered by third parties.
                You may enter into correspondence with or participate in promotions of the advertisers showing their
                products on the Services. Any such correspondence or promotions, including the delivery of and the
                payment for goods and services, and any other terms, conditions, warranties or representations
                associated with such correspondence or promotions, are solely between you and the advertiser.
                We assume no liability, obligation or responsibility for any part of any such correspondence
                or promotion.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">21. International Use</StyledText>
            <br />
            <StyledText type="descriptor1">
                Although the Services may be accessible worldwide, we make no representation that materials on
                the Services are appropriate or available for use in locations outside the continental United
                States, and accessing them from territories where their contents are illegal is prohibited.
                Those who choose to access the Services from other locations do so on their own initiative
                and are responsible for compliance with applicable laws. Any offer for any Promotion, product,
                service, and/or information made in connection with the Services is void where prohibited.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">22. Termination</StyledText>
            <br />
            <StyledText type="descriptor1">
                You agree that we may, in our sole discretion, terminate or suspend your access to all or part
                of the Services with or without notice for any reason or no reason. Any suspected fraudulent,
                abusive or illegal activity may be grounds for terminating your relationship and may be referred
                to appropriate law enforcement authorities. Upon termination or suspension, regardless of the
                reasons thereof, your right to use the Services immediately ceases, and you acknowledge and agree
                that we may immediately deactivate or delete your Klickly account and all related information
                and files in your account and/or bar any further access to such files or the Services.
                We shall not be liable to you or any third party for any claims or damages arising out of any
                termination or suspension or any other actions taken by us in connection with such termination
                or suspension.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">23. Disputes</StyledText>
            <br />
            <StyledText type="descriptor1">
                Where there is a dispute, controversy, or claim arising under, out of, or relating to these Terms,
                the aggrieved Party shall notify the other Party in writing of the nature of such dispute with
                as much detail as possible about the alleged deficient performance of the other Party.
                A representative from senior management of each of the parties (or an individual Buyer, if applicable)
                shall meet in person or communicate by telephone or email within ten (10) business days of the date
                of the written notification in order to reach an agreement about the nature of the alleged deficiency
                and the corrective action to be taken by the respective Parties.
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                Any dispute, controversy, or claim arising under, out of, or relating to these Terms and any
                subsequent amendments of these Terms, including, without limitation, its formation, validity,
                binding effect, interpretation, performance, breach, or termination, as well as non-contractual
                claims, and any claims with respect to the validity of this mediation agreement (hereinafter the “Dispute”),
                shall be submitted to mediation in accordance with the then- current WIPO Mediation Rules.
                The language to be used in the mediation will be English.
            </StyledText>
            <StyledText type="descriptor1">
                Notwithstanding anything contained hereunder, you agree and acknowledge that no dispute resolution or
                litigation will be pursued by you for any breach of these Terms until and unless Klickly has had an
                opportunity to cure any alleged breach. You agree to provide Klickly with a detailed description of
                any alleged failure and a description of the steps that You understand must be taken by Klickly to
                resolve the failure. Klickly shall have sixty (60) days from Klickly’s receipt of your notice to
                complete the cure.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">24. Governing Law</StyledText>
            <br />
            <StyledText type="descriptor1">
                These Terms, and all matters and disputes arising from or relating to these Terms or the use of the Services,
                shall be governed by the laws of the State of California, without regard to the principles of conflict of
                laws and the United Nations Convention on the International Sales of Goods. You agree to the exclusive
                jurisdiction of, and agree that venue is proper in, the state and federal courts located in Los Angeles,
                California, USA. The prevailing Party shall be entitled to recover its reasonable attorney fees
                (including in-house counsel fees at customary rates) and costs from the non-prevailing Party.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                25. Entire Agreement; Order of Precedence
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                These Terms constitute the entire agreement and understanding between you and us concerning the subject
                matter of this agreement and supersede all prior agreements and understandings of the parties with respect
                to that subject matter. These Terms do not alter the terms or conditions of any other agreement you may
                have with Klickly for the Services or for any other Klickly product or service or otherwise.
                In the event of any conflict between these Terms and any other agreement you may have with Klickly,
                the terms of that other agreement will control only if the other agreement specifically declares that
                its terms override these Terms.
            </StyledText>
            <br />
            <StyledText type="badgeLarge">26. Miscellaneous</StyledText>
            <br />
            <StyledText as="div" type="descriptor1">
                <ul>
                    <li>
                        (a) Limitations Period. Any cause of action brought by you against us or our Affiliates
                        must be instituted within one year after the cause of action arises or be deemed forever
                        waived and barred.
                    </li>
                    <li>
                        (b) Assignment. You may not assign your rights and obligations under these Terms to any
                        party, and any purported attempt to do so will be null and void. We may free assign our
                        rights and obligations under these Terms.
                    </li>
                    <li>
                        (c) Events Beyond Our Control. In addition to any excuse provided by applicable law, we
                        shall be excused from liability for non- delivery or delay in delivery of products and
                        services available through the Services arising from any event beyond our reasonable control,
                        whether or not foreseeable by either party, including but not limited to, pandemic, epidemic,
                        labor disturbance, war, fire, accident, adverse weather, inability to secure transportation,
                        governmental act or regulation, and other causes or events beyond our reasonable control,
                        whether or not similar to those which are enumerated above.
                    </li>
                    <li>
                        (d) Severability. If any part of these Terms is held invalid or unenforceable, that
                        portion shall be construed in a manner consistent with applicable law to reflect, as
                        nearly as possible, the original intentions of the parties, and the remaining portions
                        shall remain in full force and effect.
                    </li>
                    <li>
                        (e) Waiver. Any failure by us to enforce or exercise any provision of these Terms or
                        related rights shall not constitute a waiver of that right or provision.
                    </li>
                    <li>(f) Modifications. All modifications must be in writing, signed by both Parties.</li>
                    <li>
                        (g) Survival. The provisions of these Terms relating to delivery of Merchandises,
                        payment of fees; right of offset; confidentiality; disclaimers; warranties;
                        limitation of liability; indemnification; law; severability; and this paragraph
                        shall survive any termination or expiration of these Terms.
                    </li>
                    <li>
                        (h) Non-Solicitation. As a Seller or Publisher, you agree that you will not, directly
                        or indirectly, solicit or employ any person employed by Klickly.
                        Notwithstanding anything to the contrary, this non-solicitation provision shall
                        not restrict you from soliciting or employing a former employee of Klickly who has
                        not been an employee for at least 6 months.
                    </li>
                    <li>
                        (i) Limited Non-Compete. As a Seller or Publisher, you agree that you will not,
                        directly or indirectly, use any Klickly Confidential Information (or your knowledge thereof)
                        to create, develop, or promote any product, service, or offering that competes with the Services.
                    </li>
                    <li>
                        (j) Relationship. The relationship between Klickly and any User is not an employment
                        relationship, joint venture or partnership. Neither Party has the authority to act on
                        behalf of the other or bind the other.
                    </li>
                </ul>
            </StyledText>
            <br />
            <StyledText type="badgeLarge">
                27. Contact Information for Feedback
            </StyledText>
            <br />
            <StyledText type="descriptor1">
                We welcome your questions, feedback and comments and encourage you to provide the same to
                Klickly. Our telephone number is <a href='tel:4242726573'>424-272-6573</a>, or you can email to{' '}
                <a href='mailto:info@klickly.com'>info@klickly.com</a>, if you have feedback or wish to report
                on any violation of these Terms. Please note that we will own exclusive rights, including all
                intellectual property rights, to any feedback, suggestions, ideas or other information or
                materials regarding Klickly or our Services that you provide, whether by email, posting through
                our Services or otherwise (“Feedback”). Do not send us Feedback if you are expecting to be paid
                or want to continue to own or claim rights in them; your idea might be amazing, but we may have
                already had the same or a similar idea and we want to avoid disputes. Feedback you submit is
                non-confidential and shall become the sole property of Klickly. We will be entitled to the
                unrestricted use and dissemination of such Feedback for any purpose, commercial or otherwise,
                without acknowledgment or compensation to you.
            </StyledText>
        </LegalContent>
    );
};

export default TermsPage;
