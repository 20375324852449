import React, { useEffect } from 'react';
import { notification } from '@klickly/front-packages';
import NotificationContent from '../../NotificationContent';

export const useShareProductNotification = (store, defaultSettings) => {
    const { isApprovedCustomer } = store.accountStore;
    const { activeChallengeName } = store.marketplaceStore;
    const challenge = store.tasksStore?.copyProductLinkTask;
    const isChallenge =
        activeChallengeName === challenge?.alias && challenge?.data?.status !== 'completed' && isApprovedCustomer;
    const message = 'Step 2 of 3. Click on Share';

    useEffect(() => {
        if (isChallenge) {
            store.trackingStore.trackChallengeNotification(challenge, '2', message);
            notification.open({
                key: challenge?.alias,
                description: <NotificationContent.Reward rewardCount={50} message={message} />,
                ...defaultSettings
            });
        }
        return () => (typeof notification.destroy === 'function' ? notification.destroy() : null);
    }, [isChallenge]); //eslint-disable-line
};
