import React, { useEffect, useState } from 'react';
import { ArrowChevronDownIcon, ArrowChevronUpIcon, Typography } from '@klickly/front-packages';
import { AccordionContentStyles, AccordionHeadStyles, AccordionSectionStyles } from './Accordion.styles';

export const Accordion = ({ title, defaultOpen = false, disableFolding = false, children }) => {
    const [active, setActive] = useState(false);

    const toggleAccordion = () => {
        if (!disableFolding) {
            setActive(!active);
        }
    };

    useEffect(() => {
        if (defaultOpen || disableFolding) {
            setActive(true);
        }
    }, [setActive, defaultOpen, disableFolding]);

    return (
        <AccordionSectionStyles>
            <AccordionHeadStyles active={active} onClick={toggleAccordion}>
                <Typography.Text type='body1' color='primaryText'>
                    {title}
                </Typography.Text>
                {active && !disableFolding && <ArrowChevronUpIcon />}
                {!active && !disableFolding && <ArrowChevronDownIcon />}
            </AccordionHeadStyles>
            <AccordionContentStyles active={active}>{children}</AccordionContentStyles>
        </AccordionSectionStyles>
    );
};
