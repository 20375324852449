const KCP_API_URL = process.env.REACT_APP_KCP_API_URL;
const SECURE_CHECKOUT_API_URL = process.env.REACT_APP_SECURE_CHECKOUT_URL;

const makeURL = (pathname) => {
    return `${KCP_API_URL}${pathname}`;
};

export const kcpAPI = {
    auth: {
        me: makeURL('/auth/me'),
        signUp: makeURL('/auth/sign-up'),
        login: makeURL('/auth/login'),
        logout: makeURL('/auth/logout'),
        passwordForgot: makeURL('/auth/password-forgot'),
        passwordReset: makeURL('/auth/password-reset')
    },
    cart: {
        get: makeURL('/cart'),
        update: (cartId) => makeURL(`/cart/${cartId}`),
        getItemsAmount: makeURL('/cart/items-amount'),
        itemsManage: makeURL('/cart/items'),
        buyNow: makeURL('/cart/buy-now'),
        toCheckout: makeURL('/cart/to-checkout'),
        mergeGuestCart: makeURL('/cart/merge'),
        makeCheckoutUrl: (cartId, checkoutKey) => `${SECURE_CHECKOUT_API_URL}/${cartId}/${checkoutKey}`,

        points: {
            get: makeURL('/cart/points'),
            apply: makeURL('/cart/points/apply'),
            remove: makeURL('/cart/points/remove')
        }
    },
    customer: {
        update: makeURL('/customer'),
        uploadAvatar: makeURL('/customer/avatar'),
        removeAvatar: makeURL('/customer/avatar'),
        giftlyUpdate: makeURL('/customer/giftly'),
        applyFit: makeURL('/customer/apply-fit'),
        exists: makeURL('/customer/exists'),
        addToWishlist: makeURL('/customer/wishlists'),
        removeItemWishlist: (productId) => makeURL(`/customer/wishlists/${productId}`)
    },
    customerData: {
        getBalance: (sources) => makeURL(`/customer-data/balance?sources=${sources}`),
        address: (addressId = null) => makeURL(`/customer-data/address${addressId ? `/${addressId}` : ''}`),
        payment: (stripeCardId = null) => makeURL(`/customer-data/payment${stripeCardId ? `/${stripeCardId}` : ''}`),
        orders: {
            list: makeURL('/customer-data/orders'),
            cancelOrder: (orderId = null) => makeURL(`/customer-data/orders/refund${orderId ? `/${orderId}` : ''}`),
            getByCode: makeURL('/customer-data/orders/by-code'),
            getById: makeURL('/customer-data/orders/by-id')
        },
        privacy: {
            // not used ??? list: makeURL('/customer-data/privacy')
        }
    },
    feedback: {
        send: makeURL('/feedback'),
        reportProblem: makeURL('/feedback/report-problem')
    },
    giftingSuite: {
        get: makeURL('/gifting-suite/suite'),
        feedback: makeURL('/gifting-suite/feedback'),
        getCoupons: (suiteId) => makeURL(`/gifting-suite/suite/${suiteId}/coupons`),
        launch: (suiteId) => makeURL(`/gifting-suite/suite/${suiteId}/launch`),

        getCouponDetails: (giftCouponId) => makeURL(`/gifting-suite/coupon/${giftCouponId}`),
        getCouponPromotion: (giftCouponId) => makeURL(`/gifting-suite/coupon/${giftCouponId}/promotion`)
    },
    inviteCode: {
        submit: makeURL('/invite-code/submit'),
        check: (inviteCode) => makeURL(`/invite-code/check?inviteCode=${inviteCode}`)
    },
    orders: {
        createCheckoutOrder: makeURL('/orders')
    },
    promotions: {
        fetchUrl: (promotionId) => makeURL(`/promotions/${promotionId}`),
        reviewsUrl: (promotionId) => makeURL(`/promotions/${promotionId}/reviews`)
    },
    rewards: {
        apply: makeURL('/rewards/apply'),
        list: makeURL('/rewards'),
        getByUUID: makeURL('/rewards/by-uuid'),
        get: (rewardId) => makeURL(`/rewards/${rewardId}`),
        unclip: (rewardId) => makeURL(`/rewards/${rewardId}/unclip`),
        unclipUndo: (rewardId) => makeURL(`/rewards/${rewardId}/unclip-undo`),
        getIsRewardApplicable: (rewardUUID) => makeURL(`/rewards/${rewardUUID}/is-applicable`)
        // not used ??? productRewards: (productId) => makeURL(`/rewards/product-rewards/${productId}`)
    },
    points: {
        getCustomerPointsData: makeURL('/customer-data/points')
    },
    challenges: {
        getChallengesGroup: makeURL('/challenges-group')
    },
    tasks: {
        list: makeURL('/tasks'),
        get: (taskId) => makeURL(`/tasks/${taskId}`),
        patch: (taskId) => makeURL(`/tasks/${taskId}`),
        invitationsRequest: (taskId) => makeURL(`/tasks/${taskId}/invitations-requested`),
        invitationsCancel: (taskId, inviteId) => makeURL(`/tasks/${taskId}/invitations-cancel/${inviteId}`)
    },
    notifications: {
        getUnsubscribe: makeURL('/notifications/unsubscribe'),
        submitUnsubscribe: makeURL('/notifications/unsubscribe'),
        submitSubscribe: makeURL('/notifications/subscribe')
    },
    reviews: {
        writeUrl: makeURL('/reviews')
    },
    marketplace: {
        brands: {
            get: makeURL('/marketplace/brands'),
            getBrandData: (brandId) => makeURL(`/marketplace/brands/${brandId}`)
        },
        promotions: {
            get: makeURL('/marketplace/promotions'),
            recommendations: (promotionId) => makeURL(`/marketplace/promotions/${promotionId}/recommendations`)
        },
        bestSellers: {
            get: makeURL('/marketplace/promotions/best-sellers')
        },
        welcomeGiftOffers: {
            get: makeURL('/marketplace/promotions/best-welcome-gift-offers')
        },
        search: {
            basic: makeURL('/marketplace/search'),
            autoSuggestions: makeURL('/marketplace/search/autosuggestions')
        },
        getStrategyInfo: (strategyName) => makeURL(`/marketplace/strategy?name=${strategyName}`),
        getStrategyInfoById: (strategyId) => makeURL(`/marketplace/strategy?id=${strategyId}`),
        taxonomy: {
            getDetails: makeURL('/marketplace/taxonomy/details'),
            list: makeURL('/marketplace/taxonomy')
        }
    },
    upsell: {
        getByCartId: (cartId) => makeURL(`/upsells/by-cart/${cartId}`),
        claim: makeURL('/upsells/claim')
    },
    stripe: {
        getStripeClientSecret: makeURL('/stripe/setup-intent')
    }
};
