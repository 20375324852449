const validMimeTypes = ['image/jpeg', 'image/png'];

export const upload = (file, options) => {
    if (!validMimeTypes.includes(file.type)) {
        return `Valid image formats are: ${validMimeTypes.join(', ')}`;
    }

    const isLtMb = file.size / 1000 / 1000 < options.maxFileSize;

    if (!isLtMb) {
        return `Maximum file size for profile photos is ${options.maxFileSize}MB`;
    }

    return true;
};
