import { getParent, types } from 'mobx-state-tree';

export const EXTENSION_PANEL_MUTE_TOGGLED = 'toggle_mute';

export const EventListenerStore = types
    .model('EventListenerStore', {})
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const addEventListener = ({ eventName, eventHandler }) => window.addEventListener(eventName, eventHandler);

        const removeEventListener = ({ eventName, eventHandler }) =>
            window.removeEventListener(eventName, eventHandler);

        const extensionPanelMuteToggle = () => {
            self.root.chromeExtensionStore.toggleExtensionPanelMute();
        };

        const getEventHandlerMapper = () => ({
            [EXTENSION_PANEL_MUTE_TOGGLED]: extensionPanelMuteToggle
        });

        const onEventMessage = ({ action, data }) => {
            const eventMapper = getEventHandlerMapper();
            const handler = eventMapper[action];
            if (handler) {
                handler(data);
            }
        };

        return {
            addEventListener,
            removeEventListener,
            onEventMessage
        };
    });
