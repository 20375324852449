import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../utils';

export const Styles = styled.div`
    display: flex;
    padding: 6px 0;
    justify-content: flex-start;

    @media (${GRID_DEVICES_MAX.sm}) {
        justify-content: space-between;
    }
`;
