export const TEXTS = {
    AUTHORIZED: {
        title: 'Your Gifting Suite is ready',
        description: 'Click launch to choose your gift',
        launchButtonText: 'Launch'
    },
    UNAUTHORIZED: {
        title: 'Welcome to your Gifting Suite',
        description: 'Access free gifts and private discounts on premium products from trending brands',
        launchButtonText: 'Apply for access'
    },
    CLOSED_SUITE: {
        title: 'Welcome to your Gifting Suite',
        description: 'Upgrade to premium and pick a new gift, just for you, each and every week.',
        launchButtonText: 'Apply for access'
    }
};
