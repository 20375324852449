import React from 'react';
import { BOTTOM_INDENTS, Col, Row, TOP_INDENTS, Typography } from '@klickly/front-packages';
import { $primary } from '../../index';
import { Logo } from '../Logo';
import { SidebarContentStyles, SidebarStyles } from './Sidebar.styles';

export const Sidebar = ({ headText = '', bodyText = '', customBodyContent = null }) => {
    return (
        <SidebarStyles>
            <Row>
                <Col span={24}>
                    <SidebarContentStyles>
                        <Logo size='40px' color={$primary} />
                        <Typography.Title style={{ ...BOTTOM_INDENTS.XL, ...TOP_INDENTS.XL }} color='primary'>
                            {headText}
                        </Typography.Title>
                        {customBodyContent || (
                            <Typography.Text color='primary' type='body1'>
                                {bodyText}
                            </Typography.Text>
                        )}
                    </SidebarContentStyles>
                </Col>
            </Row>
        </SidebarStyles>
    );
};
