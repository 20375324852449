import React from 'react';
import {
    ArrowGoingUpIcon,
    BOTTOM_INDENTS,
    BrowserStorage,
    Button,
    GRID_POINTS,
    RIGHT_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { loadTW } from '../../../../utils/pixels/tw';
import { StyledIcon, StyledWrap } from './styled';

export const BrandInfo = inject('store')(
    observer(({ store, hidden }) => {
        const { bannerImage, brandName: name, brandUrl: shopUrl, brandTwPixel } = store.brandPageStore;
        const simple = useSimpleInterface(GRID_POINTS.md);

        const withoutBanner = !bannerImage;
        const handleViewInStoreClick = () => {
            if (shopUrl) {
                if (brandTwPixel) loadTW(brandTwPixel, { loadOn: 'click' });

                const brandUrl = new URL(shopUrl);
                brandUrl.searchParams.set('_klpixel_cid', BrowserStorage.get('_klpixel_cid'));

                window.open(brandUrl.toString(), '_blank');
            }
        };

        return (
            <StyledWrap $simple={simple} $hidden={hidden} $withoutBanner={withoutBanner}>
                {simple ? (
                    <Typography.Title level={1} align='center' style={{ ...BOTTOM_INDENTS.S }}>
                        {name}
                    </Typography.Title>
                ) : (
                    <Typography.Text type='em0'>{name}</Typography.Text>
                )}
                {!hidden ? (
                    <Button type='unstyled' onClick={handleViewInStoreClick}>
                        <Typography.Text type='badgeSmall' color='secondaryText' style={{ ...RIGHT_INDENTS.XXS }}>
                            View in brand’s store
                        </Typography.Text>
                        <StyledIcon size='16px' color='secondaryText' component={() => <ArrowGoingUpIcon />} />
                    </Button>
                ) : null}
            </StyledWrap>
        );
    })
);
