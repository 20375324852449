import React from 'react';
import { useSimpleInterface } from '@klickly/front-packages';
import { useModal } from '../../../../../../../shared';
import { GRID_POINTS } from '../../../../../../../utils';
import { ShippingModal } from '../components/Modal';

export const useShippingModal = (defaultShippingAddress) => {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    const { openModal, closeModal } = useModal();

    return () =>
        openModal({
            children: <ShippingModal defaultShippingAddress={defaultShippingAddress} onClose={closeModal} />,
            settings: {
                fullscreen: simple,
                padding: '0'
            },
            modalContentStyles: { padding: '0' }
        });
};
