import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $primary, $tartRed } from '../../constants';

export const StyledBadge = styled(Typography.Text).attrs({
    type: 'descriptor1'
})`
    width: 100%;
    margin-bottom: 8px;
    padding: 4px 8px;
    font-weight: 500;
    text-align: center;
    color: ${$primary};
    background: ${$tartRed};
`;

export const StyledMessage = styled(Typography.Text).attrs({
    type: 'descriptor2'
})`
    text-align: center;
`;
