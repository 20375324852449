import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';

export const TaskThanksContentStyles = styled.div`
    width: 100%;
    padding: 40px;
    max-height: 510px;
    overflow-y: unset;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const StyledKliText = styled(Typography.Text)`
    text-align: center;
`;
