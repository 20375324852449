import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX, GRID_DEVICES_MIN } from '../../../../utils';

export const DialogWrap = styled.div`
    padding: 20px 40px 0;
    overflow: hidden;
    text-align: ${({ centred }) => (centred ? 'center' : 'left')};
    position: relative;

    @media (${GRID_DEVICES_MAX.md}) {
        padding: 20px 25px;
    }
`;

export const Message = styled(Typography.Text)`
    text-align: left;
    width: 100%;
`;

export const ContentWrap = styled.div`
    @media (${GRID_DEVICES_MIN.sm}) {
        min-width: 550px;
    }

    @media (${GRID_DEVICES_MIN.sm}) and (${GRID_DEVICES_MAX.md}) {
        min-width: 580px;
    }
`;
