import React from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../shared';
import BetaTermsPage from '../components/BetaTerms';
import PrivacyOptOutPage from '../components/PrivacyOptOut';
import PrivacyPolicyPage from '../components/PrivacyPolicy';
import SensitiveInformationPage from '../components/SensitiveInformation';
import TermsPage from '../components/Terms';

export const ROUTES = [
    {
        path: ROUTE_PATHS.helpPage,
        element: <Navigate to={ROUTE_PATHS.helpPageTerms} replace />,
        index: true
    },
    {
        path: ROUTE_PATHS.helpPageBetaTerms,
        element: <BetaTermsPage />
    },
    {
        path: ROUTE_PATHS.helpPageTerms,
        element: <TermsPage />
    },
    {
        path: ROUTE_PATHS.helpPagePrivacy,
        element: <PrivacyPolicyPage />
    },
    {
        path: ROUTE_PATHS.helpPagePrivacyOptOut,
        element: <PrivacyOptOutPage />
    },
    {
        path: ROUTE_PATHS.helpPageDisclosureSensitiveInfo,
        element: <SensitiveInformationPage />
    }
];
