import React, { Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, GiftIcon, Icon, Row, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../constants';
import Points from './components/Points';

const ClaimsPointsData = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { pathname } = useLocation();
        const { claimsCount } = store.claimsPointsStore;
        const { closePDP } = store.pdpStore;
        const { getShoppingCartState } = store.shoppingCartStore;
        const { signUpStatusRejected } = store.accountStore;

        const goToGS = () => {
            closePDP();
            getShoppingCartState();
            navigate(ROUTE_PATHS.suiteGs);
        };

        return (
            <Fragment>
                <Row gutter={10} align='middle'>
                    <Col>
                        <Typography.Text
                            as='div'
                            color={pathname.includes(ROUTE_PATHS.suiteGs) ? 'primary' : 'primaryText'}
                            onClick={goToGS}
                            style={{ cursor: 'pointer' }}
                        >
                            <Row gutter={4} align='middle'>
                                <Col>
                                    <Icon size='14px' component={() => <GiftIcon />} />
                                </Col>
                                <Col>
                                    <Typography.Text type='badgeMedium' as='span'>
                                        {claimsCount}
                                    </Typography.Text>
                                </Col>
                            </Row>
                        </Typography.Text>
                    </Col>
                    <Col>
                        {!signUpStatusRejected ? (
                            <Typography.Text
                                as='div'
                                color={pathname.includes(ROUTE_PATHS.points) ? 'primary' : 'primaryText'}
                                style={{ cursor: 'pointer' }}
                            >
                                <Points />
                            </Typography.Text>
                        ) : null}
                    </Col>
                </Row>
            </Fragment>
        );
    })
);

export default ClaimsPointsData;
