import React from 'react';
import { ArrowChevronDownIcon, Col, Row } from '@klickly/front-packages';
import { StyledIcon } from './styled';

const DropdownMenuWrapper = ({ children }) => {
    return (
        <Row gutter={12} align='middle'>
            <Col>{children}</Col>
            <Col>
                <StyledIcon component={() => <ArrowChevronDownIcon />} />
            </Col>
        </Row>
    );
};

export default DropdownMenuWrapper;
