import styled, { css } from 'styled-components';
import { $border } from '../../../../shared';
import { animation$ } from '../../../../shared/constants/animation';
import { GRID_DEVICES_CUSTOM_MAX, GRID_DEVICES_CUSTOM_MIN, GRID_DEVICES_MAX } from '../../../../utils';

export const StyledWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${$border};
    margin-bottom: 64px;

    ${({ $simple }) =>
        $simple
            ? css`
                  border-bottom: none;
                  flex-direction: column;
                  justify-content: center;
                  margin-bottom: 16px;
              `
            : ''}
`;

export const StyledContentLeft = styled.div`
    width: 305px;
`;

export const StyledContentRight = styled.div`
    width: ${({ $simple }) => ($simple ? '100%' : 'calc(100% - 305px)')};
    display: ${({ $simple }) => ($simple ? 'static' : 'flex')};
`;

export const StyledSliderWrap = styled.div`
    position: relative;
    height: ${({ $promotionsHeight }) => $promotionsHeight};
    overflow: hidden;

    .slick-track {
        height: 100%;

        ${({ $singleItem }) =>
            $singleItem &&
            css`
                margin: 0;
            `};
    }

    .slick-slider,
    .slick-list {
        height: 100%;
    }

    .slick-slide {
        > div {
            height: 100%;
        }
    }

    .slick-next {
        top: auto;
        right: 0;
        bottom: -16px;
        width: 36px;
        height: 36px;

        &:before {
            content: none;
        }
    }

    .slick-prev {
        top: auto;
        bottom: -16px;
        left: 0;
        width: 36px;
        height: 36px;

        &:before {
            content: none;
        }
    }

    .slick-dots {
        bottom: 8px;
        left: 50%;
        transform: translate(-50%);
        width: calc(100% - 80px);

        li {
            width: 15px;
            margin: 0;
        }
    }
`;

export const StyledContentListItem = styled.div`
    display: flex;
    position: relative;
    padding: 10px;
    animation: ${animation$} 0.3s ease-in-out;
    will-change: transform, opcity;

    &:hover {
        z-index: 1;
    }

    @media (${GRID_DEVICES_MAX.sm}) {
        padding: 10px 8px;
    }

    ${({ $fullHeight }) =>
        $fullHeight &&
        css`
            height: 100%;
        `};

    ${({ $fullWidth }) =>
        $fullWidth
            ? css`
                  width: 100%;
              `
            : ''};

    ${({ $fullWidth }) =>
        !$fullWidth &&
        css`
            @media (${GRID_DEVICES_CUSTOM_MAX.small}) {
                width: ${({ $inSlider }) => ($inSlider ? 100 : 50)}%;
            }

            @media (${GRID_DEVICES_CUSTOM_MIN.small}) {
                width: 50%;
            }

            @media (${GRID_DEVICES_CUSTOM_MIN.medium}) {
                width: ${({ $inSlider }) => ($inSlider ? 100 : 50)}%;
            }

            @media (${GRID_DEVICES_CUSTOM_MIN.large}) {
                width: 50%;
            }

            @media (${GRID_DEVICES_CUSTOM_MIN.biggest}) {
                width: 33%;
            }
        `};
`;
