import React, { Fragment } from 'react';
import { useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../../utils';
import ArrowButton from '../ArrowButton';
import { StyledControls, StyledHeader, StyledSeeAllButton, StyledSlideCounter, StyledTitle } from './styled';

const SliderHeader = inject('store')(
    observer(
        ({
            title,
            sliderRef,
            isRecommendation,
            currentSlide,
            slidesCount,
            showButtonsOnTop,
            seAllBtnText,
            seAllBtnAction = () => null
        }) => {
            const simple = useSimpleInterface(GRID_POINTS.sm);
            const isLessThan1200px = useSimpleInterface(GRID_POINTS.xl);
            const prevDisabled = currentSlide === 0;
            const nextDisabled = currentSlide + (isLessThan1200px ? 2 : 3) >= slidesCount;

            return (
                <StyledHeader>
                    <StyledTitle level={2} $simple={simple} $isRecommendation={isRecommendation}>
                        {title}
                    </StyledTitle>
                    <StyledControls>
                        {(!simple || showButtonsOnTop) && (
                            <ArrowButton
                                disabled={prevDisabled}
                                onClick={() => sliderRef.current && sliderRef.current.slickPrev()}
                            />
                        )}
                        {isRecommendation ? (
                            <Fragment>
                                {!simple || showButtonsOnTop ? (
                                    <StyledSlideCounter type='descriptor1'>
                                        {nextDisabled ? slidesCount : currentSlide + 1}/{slidesCount}
                                    </StyledSlideCounter>
                                ) : null}
                            </Fragment>
                        ) : (
                            <StyledSeeAllButton type='unstyled' $simple={simple} onClick={seAllBtnAction}>
                                {seAllBtnText || `See All ${title}`}
                            </StyledSeeAllButton>
                        )}
                        {(!simple || showButtonsOnTop) && (
                            <ArrowButton
                                type='next'
                                disabled={nextDisabled}
                                onClick={() => sliderRef.current && sliderRef.current.slickNext()}
                            />
                        )}
                    </StyledControls>
                </StyledHeader>
            );
        }
    )
);

export default SliderHeader;
