import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX, GRID_DEVICES_MIN } from '../../../utils';
import { $primaryText, $white } from '../../constants';

export const Styles = styled.div`
    padding: 20px;
    color: ${$white};
    background: ${({ background }) => background || $primaryText};

    @media (${GRID_DEVICES_MAX.md}) {
        position: ${({ staticPosition }) => (staticPosition ? 'static' : 'absolute')};
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 17;
        margin: auto;

        display: flex;
        justify-content: center;
        padding: 10px;
    }
`;

export const IconWrap = styled.span`
    display: block;

    @media (${GRID_DEVICES_MIN.md}) {
        margin-bottom: 10px;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        margin-right: 8px;
    }
`;

export const Message = styled(Typography.Text)`
    @media (${GRID_DEVICES_MIN.md}) {
        margin-bottom: 15px !important;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        margin-right: 6px;
    }
`;
