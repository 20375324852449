import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CartIcon, Col, Icon, Row, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../constants';
import PopoverWithButton from '../../../PopoverWithButton';

const CartData = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { pathname } = useLocation();
        const { amount, getShoppingCartState } = store.shoppingCartStore;
        const { closePDP } = store.pdpStore;
        const { showPopoverOnCart } = store.onboardingPopoverStore;

        const onClick = () => {
            closePDP();
            getShoppingCartState();
            navigate(ROUTE_PATHS.shoppingCart);
        };

        return (
            <PopoverWithButton
                open={showPopoverOnCart}
                content={
                    <div>
                        Your cart contains products <br /> you liked (in case you’d like <br /> to buy them later)
                    </div>
                }
                placement='bottomRight'
            >
                <Typography.Text
                    as='div'
                    style={{ cursor: 'pointer' }}
                    color={pathname.includes(ROUTE_PATHS.shoppingCart) ? 'primary' : 'primaryText'}
                    onClick={onClick}
                >
                    <Row gutter={4} align='middle'>
                        <Col>
                            <Icon size='14px' component={() => <CartIcon />} />
                        </Col>
                        <Col>
                            <Typography.Text type='badgeMedium' as='span'>
                                {amount}
                            </Typography.Text>
                        </Col>
                    </Row>
                </Typography.Text>
            </PopoverWithButton>
        );
    })
);

export default CartData;
