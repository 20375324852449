import React from 'react';
import { GiftIcon, PointsIcon, TimeIcon } from '@klickly/front-packages';
import blanket from '../../assets/img/premium-products/365-blanket.png';
import blanketS from '../../assets/img/premium-products/365-blanket_s.png';
import basicsSet from '../../assets/img/premium-products/basics-set.png';
import basicsSetS from '../../assets/img/premium-products/basics-set_s.png';
import biaggi from '../../assets/img/premium-products/biaggi.jpg';
import biaggiS from '../../assets/img/premium-products/biaggi_s.png';
import bigBundleS from '../../assets/img/premium-products/big-bundle_s.png';
import collagenEyeS from '../../assets/img/premium-products/collagen-eye_s.png';
import completeSystem from '../../assets/img/premium-products/complete-system.jpg';
import completeSystemS from '../../assets/img/premium-products/complete-system_s.png';
import ellaMila from '../../assets/img/premium-products/ella-mila.png';
import ellaMilaS from '../../assets/img/premium-products/ella-mila_s.png';
import extremeKit from '../../assets/img/premium-products/extreme-kit.jpg';
import extremeKitS from '../../assets/img/premium-products/extreme-kit_s.png';
import eyeCream from '../../assets/img/premium-products/eye-cream.jpg';
import eyeCreamS from '../../assets/img/premium-products/eye-cream_s.png';
import facialCleasing from '../../assets/img/premium-products/facial-cleasing.jpg';
import facialCleasingS from '../../assets/img/premium-products/facial-cleasing_s.png';
import healthProgram from '../../assets/img/premium-products/health-program.png';
import healthProgramS from '../../assets/img/premium-products/health-program_s.png';
import healthspanSystem from '../../assets/img/premium-products/healthspan-system.jpg';
import healthspanSystemS from '../../assets/img/premium-products/healthspan-system_s.png';
import hivePharmacyS from '../../assets/img/premium-products/hive-pharmacy_s.png';
import kanibi from '../../assets/img/premium-products/kanibi.png';
import kanibiS from '../../assets/img/premium-products/kanibi_s.png';
import luvvEnegryPack from '../../assets/img/premium-products/luvv-enegry-pack.jpg';
import luvvEnegryPackS from '../../assets/img/premium-products/luvv-enegry-pack_s.jpg';
import maxout4 from '../../assets/img/premium-products/maxout-4.jpg';
import maxout4S from '../../assets/img/premium-products/maxout-4_s.png';
import motherDirt from '../../assets/img/premium-products/mother-dirt.jpg';
import motherDirtS from '../../assets/img/premium-products/mother-dirt_s.png';
import sleepphones from '../../assets/img/premium-products/sleepphones.jpg';
import sleepphonesS from '../../assets/img/premium-products/sleepphones_s.jpg';
import spectrum from '../../assets/img/premium-products/spectrum.png';
import spectrumS from '../../assets/img/premium-products/spectrum_s.png';
import tequila from '../../assets/img/premium-products/tequila.png';
import tequilaS from '../../assets/img/premium-products/tequila_s.png';
import BakedGoods from '../../assets/img/products/baked-goods.png';
import basicsset from '../../assets/img/products/basics-set.png';
import bigBundle from '../../assets/img/products/big-bundle.png';
import Clase from '../../assets/img/products/ClaseAzulAnejo.png';
import Cleansing from '../../assets/img/products/cleansing-gel.png';
import collagenEye from '../../assets/img/products/collagen-eye.jpg';
import Dark from '../../assets/img/products/dark-amber.png';
import DePuffing from '../../assets/img/products/DePuffing.jpg';
import EyeMakeup from '../../assets/img/products/eye-makeup.png';
import girlEyeKit from '../../assets/img/products/girl-eye-kit.png';
import goldLust from '../../assets/img/products/gold-lust.png';
import goldVermeil from '../../assets/img/products/gold-vermeil.png';
import goodToGroKit from '../../assets/img/products/good-to-gro-kit.png';
import gromoreFoamKit from '../../assets/img/products/gro-more-foam-kit.png';
import gucciBloom from '../../assets/img/products/gucci-bloom.png';
import healthyGlow from '../../assets/img/products/healthy-glow.png';
import hersBracelet from '../../assets/img/products/hers-bracelet.png';
import hivePharmacy from '../../assets/img/products/hive-pharmacy.png';
import intenseKescue from '../../assets/img/products/intense-rescue.png';
import LeatherBag from '../../assets/img/products/LeatherBag.png';
import luvvenegrypack from '../../assets/img/products/luvv-enegry-pack.png';
import medusaBracelet from '../../assets/img/products/medusa-bracelet.png';
import naturalGlow from '../../assets/img/products/natural-glow.png';
import OpalRainbow from '../../assets/img/products/OpalRainbow.png';
import revitalizingConditioner from '../../assets/img/products/revitalizing-conditioner.png';
import VanityPlanet from '../../assets/img/products/VanityPlanet.png';
import Velvet from '../../assets/img/products/velvet-perfume.png';

export const READY_TO_LUNCH_PRODUCTS = Object.freeze([
    {
        imageSRC: Velvet,
        title: 'Velvet Orchid Perfume',
        brandName: 'Tom Ford'
    },
    {
        imageSRC: Dark,
        title: 'Dark Amber & Ginger Lily Scented Home Candle',
        brandName: 'Jo Malone London'
    },
    {
        imageSRC: BakedGoods,
        title: 'Baked goods for soul ...',
        brandName: 'Nunbelievable'
    },
    {
        imageSRC: Cleansing,
        title: 'The Cleansing Gel 200ml',
        brandName: 'La Mer'
    },
    {
        imageSRC: EyeMakeup,
        title: 'Gentle Bi-Phase Eye Makeup Remover',
        brandName: 'Chanel'
    },
    {
        imageSRC: intenseKescue,
        title: 'Intense rescue age-delay revitalizing oil-serum',
        brandName: 'Dior'
    },
    {
        imageSRC: healthyGlow,
        title: 'Healthy Glow Foundation Hydration and Longwear',
        brandName: 'Chanel'
    },
    {
        imageSRC: naturalGlow,
        title: 'Natural glow custom color reviving lip balm',
        brandName: 'Dior'
    },
    {
        imageSRC: girlEyeKit,
        title: 'The uptown girl eye kit',
        brandName: 'Charlotte Tilbury'
    },
    {
        imageSRC: gucciBloom,
        title: 'Gucci Bloom Eau de Parfum For Her',
        brandName: 'Gucci'
    },
    {
        imageSRC: revitalizingConditioner,
        title: 'Revitalizing Conditioner, 300ml',
        brandName: 'Balmain Paris Hair Couture'
    },
    {
        imageSRC: goldLust,
        title: 'Gold Lust Nourishing Hair Oil, 50ml',
        brandName: 'Oribe'
    },
    {
        imageSRC: hersBracelet,
        title: 'HERS Bracelet Gift Set',
        brandName: 'Stella Valle'
    },
    {
        imageSRC: goldVermeil,
        title: '14K Gold Vermeil Y-Shape Cube Necklace - Blue/Pink',
        brandName: 'Ahed Project'
    },
    {
        imageSRC: goodToGroKit,
        title: 'Good to GRO Kit',
        brandName: 'Vegamour'
    },
    {
        imageSRC: gromoreFoamKit,
        title: 'GRO More Foam Kit',
        brandName: 'Vegamour'
    },
    {
        imageSRC: medusaBracelet,
        title: 'Medusa Bracelet',
        brandName: 'Awe Inspired'
    },
    {
        imageSRC: bigBundle,
        title: 'Big Bundle',
        brandName: 'Mounia Haircare'
    },
    {
        imageSRC: luvvenegrypack,
        title: 'The LUVV Energy Combo Pack',
        brandName: 'LUVV Labs'
    },
    {
        imageSRC: basicsset,
        title: 'Basics Set',
        brandName: 'Disco'
    },
    {
        imageSRC: collagenEye,
        title: 'Insight Collagen Eye Treatment Mask',
        brandName: 'Circcell Skincare'
    },
    {
        imageSRC: hivePharmacy,
        title: 'Hive Pharmacy',
        brandName: "Beekeeper's Naturals", // eslint-disable-line
    }
]);

export const READY_TO_LUNCH_PRODUCTS_V1 = Object.freeze([
    {
        imageSRC: Velvet,
        title: 'Velvet Orchid Perfume',
        brandName: 'Tom Ford'
    },
    {
        imageSRC: OpalRainbow,
        title: 'Perfect Opal Rainbow With Turquoise Necklace',
        brandName: 'Andrea Fohrman'
    },
    {
        imageSRC: DePuffing,
        title: 'De-Puffing, Anti-Aging System',
        brandName: 'Dermaclara'
    },
    {
        imageSRC: Cleansing,
        title: 'The Cleansing Gel 200ml',
        brandName: 'La Mer'
    },
    {
        imageSRC: EyeMakeup,
        title: 'Gentle Bi-Phase Eye Makeup Remover',
        brandName: 'Chanel'
    },
    {
        imageSRC: intenseKescue,
        title: 'Intense rescue age-delay revitalizing oil-serum',
        brandName: 'Dior'
    },
    {
        imageSRC: healthyGlow,
        title: 'Healthy Glow Foundation Hydration and Longwear',
        brandName: 'Chanel'
    },
    {
        imageSRC: naturalGlow,
        title: 'Natural glow custom color reviving lip balm',
        brandName: 'Dior'
    },
    {
        imageSRC: girlEyeKit,
        title: 'The uptown girl eye kit',
        brandName: 'Charlotte Tilbury'
    },
    {
        imageSRC: gucciBloom,
        title: 'Gucci Bloom Eau de Parfum For Her',
        brandName: 'Gucci'
    },
    {
        imageSRC: revitalizingConditioner,
        title: 'Revitalizing Conditioner, 300ml',
        brandName: 'Balmain Paris Hair Couture'
    },
    {
        imageSRC: goldLust,
        title: 'Gold Lust Nourishing Hair Oil, 50ml',
        brandName: 'Oribe'
    },
    {
        imageSRC: hersBracelet,
        title: 'HERS Bracelet Gift Set',
        brandName: 'Stella Valle'
    },
    {
        imageSRC: goldVermeil,
        title: '14K Gold Vermeil Y-Shape Cube Necklace - Blue/Pink',
        brandName: 'Ahed Project'
    },
    {
        imageSRC: LeatherBag,
        title: 'Python Leather Handbag',
        brandName: 'Moda Marjon'
    },
    {
        imageSRC: gromoreFoamKit,
        title: 'GRO More Foam Kit',
        brandName: 'Vegamour'
    },
    {
        imageSRC: Clase,
        title: 'Clase Azul Anejo',
        brandName: 'ST Online, Inc'
    },
    {
        imageSRC: bigBundle,
        title: 'Big Bundle',
        brandName: 'Mounia Haircare'
    },
    {
        imageSRC: VanityPlanet,
        title: 'Ultimate Skin Spa | Facial Cleansing System.',
        brandName: 'Vanity Planet'
    },
    {
        imageSRC: basicsset,
        title: 'Basics Set',
        brandName: 'Disco'
    },
    {
        imageSRC: collagenEye,
        title: 'Insight Collagen Eye Treatment Mask',
        brandName: 'Circcell Skincare'
    },
    {
        imageSRC: hivePharmacy,
        title: 'Hive Pharmacy',
        brandName: "Beekeeper's Naturals", // eslint-disable-line
    }
]);

export const DUMMY_PREMIUM_PRODUCTS_POSITIONS = [3, 6, 9];

export const DUMMY_PREMIUM_PRODUCTS = Object.freeze([
    {
        _id: '__000',
        imageSRC: eyeCreamS,
        imageFullSRC: eyeCream,
        title: 'ELASTIderm Eye Cream',
        brandName: 'Obagi',
        prices: {
            originalPrice: 115,
            price: 19,
            shippingTaxesPrice: 0
        },
        description: `
            One of Obagi’s most popular products is the Elastiderm Eye Treatment Cream.
            Used by many as their go-to all-purpose solution to aging eye concerns,
            the Obagi Elastiderm Eye Treatment Cream is an effective and proven formula,
            working to improve the appearance of the skin around your eyes in as little as 2 weeks!
            The Obagi Elastiderm Eye Treatment Cream’s advanced formula has been clinically proven
            to help alleviate sagging under the eye area, loss of elasticity, dullness in complexion,
            and dryness. It makes use of a breakthrough bi mineral complex that replenishes elasticity
            and accelerates the production of collagen. Not only this, its helps to improve skin elasticity,
            providing instant life and resilience to the skin, and works as a non irritating anti aging
            solution for the skin around the eye area. Other natural ingredients comprise the formula
            including algae extract and blueberry extract to help provide antioxidant power against harmful
            free radicals in the environment that can cause signs of premature aging such as fine lines,
            wrinkles, and crow’s feet to form. Introducing the Obagi Elastiderm Eye Treatment Cream
            in a daily skin care regiment will help lift, firm and brighten the skin around the eye area,
            without any worries of irritation as the gentle cream’s advanced technology specifically adheres
            to the needs of the delicate eye area.<br/>
            <br/>
            Improves the appearance of the skin around the eyes in as little as 2 weeks!<br/>
            Gentle for the delicate eye area<br/>
            Protects against harmful free radical damage<br/>
            <br/>
            How To Use:<br/>
            <br/>
            Use the Obagi Elastiderm Eye Treatment Cream twice daily, once in the morning and again at night for best results.
            Apply several few dots of cream onto the side creases of the eye, under the eye, and on the lid.
            Gently massage in until fully absorbed. Be careful not to get any inside the eye.`
    },
    {
        _id: '__001',
        imageSRC: hivePharmacyS,
        imageFullSRC: hivePharmacy,
        title: 'Hive Pharmacy',
        brandName: "Beekeeper's Naturals", // eslint-disable-line
        prices: {
            originalPrice: 93,
            price: 3,
            shippingTaxesPrice: 8.99
        },
        description: `
            With propolis to support immunity, B.Powered to nourish and restore, and B.Smart to fuel brain power, 
            you’ll never be sidelined from living your life.<br/>
            <br/>
            Includes: 1 x 330g B.Powered Superfood Honey, 2 x 30mL B.Immune Throat Spray, 6 x 10mL vials B.Smart Brain Fuel
        `
    },
    {
        _id: '__002',
        imageSRC: completeSystemS,
        imageFullSRC: completeSystem,
        title: 'Complete System',
        brandName: 'Uqora',
        prices: {
            originalPrice: 80,
            price: 0,
            shippingTaxesPrice: 0
        },
        description: `
            Contains 1 month supply of Control and Promote (1 bottle each) and 10, 20, or 30 packets of Target.`
    },
    {
        _id: '__003',
        imageSRC: blanketS,
        imageFullSRC: blanket,
        title: 'The 365 Blanket™',
        brandName: 'Muslin Comfort',
        prices: {
            originalPrice: 170,
            price: 49,
            shippingTaxesPrice: 0
        },
        description: `
            The “Magic Blanket” according to our customers!
            Find out yourself why our 365 Blanket™ is a “Sleeping Game Changer”!
            It’s a no-brainer with our 30-night, risk-free trial with free returns!<br/>
            <br/>
            Our 365 Blanket™ is ultra breathable, temperature-regulating (no more night sweats!),
            hugely OVERSIZED (no more cover fighting!), and ALL NATURAL (4ply/8 layers of 100% Muslin Cotton)!
            PRE-SHRUNK. Intended to be used “European Style” - in place of a comforter and top sheet for maximum breathability.<br/>
            <br/>
            Woven from hypoallergenic, natural 100% cotton. Safe for kids & pets.<br/>
            Easy to wash at home. Fits in your home washing machine.<br/>
            Hugely OVERSIZED. No more fighting over the covers.<br/>
            Recommended for any season, 365 days a year!<br/>
            SIZING:<br/>
            <br/>
            Oversized King: 120" x 120"*<br/>
            Oversized Queen: 110" x 110"*<br/>
            Oversized Full/Double: 96" x 96"*<br/>
            Oversized Twin: 96" x 71"*<br/>
            <br/>
            *PLEASE NOTE: Our products are comprised of 4 ply/8 layers of breathable Muslin cotton.
            Each ply is comprised of 2 intricately woven pieces of breathable Muslin cotton (like gauze)
            creating a breathable yet cozy blanket for all seasons.<br/>
            <br/>
            Everything is preshrunk, but due to it being 100% cotton, the sizing is not absolute and may vary slightly from piece to piece.<br/>
            <br/>
            For reference: On a 25" high king bed (floor to top of mattress) the King 365 Blanket will hang a little more than
            half way down on the sides of the bed.<br/>
            <br/>
            Muslin Blanket for Adults - Oversized King Blanket `
    },
    {
        _id: '__004',
        imageSRC: facialCleasingS,
        imageFullSRC: facialCleasing,
        title: 'MINTSONIC Luxury Facial Cleansing Brush',
        brandName: 'MINTBIOLOGY',
        prices: {
            originalPrice: 150,
            price: 19,
            shippingTaxesPrice: 0
        },
        description: `
            Experience MINTSONIC's Advanced Sonic technology with 15 adjustable intensities for a complete customizable
            skincare routine. Deep cleanse, massage, and apply your favorite skincare products for more luminous,
            youthful skin you and everyone will notice.<br/>
            <br/>
            Designed and optimized for all skin types.<br/>`
    },
    {
        _id: '__005',
        imageSRC: biaggiS,
        imageFullSRC: biaggi,
        title: 'Expandable Under-Seat Carry-On',
        brandName: 'Biaggi',
        prices: {
            originalPrice: 170,
            price: 22,
            shippingTaxesPrice: 0
        },
        description: `
            Our expandable under-seat carry-on is an extremely versatile bag that starts off as a small easy-to-store pouch,
            and opens to become a 4-wheeled carry-on that slides comfortably under the airplane seat in front of you.
            Need more space? No problem! Your under-seater expands a full 5" upwards, to become a full sized TSA
            approved carry-on! An extra-deep packing cube is included, so you can pack your clothes in the cube, and
            still have plenty of room for your other carry on items.<br/>
            <br/>
            Details:<br/>
            <br/>
            Dimensions: 16"x15"x 6.7" and expands to 21"x15"x 6.7", weighs only 3.85 lbs!<br/>
            Folds into a 15"x9"x 4" pouch for easy storage<br/>
            Item comes with a custom fitted and extra-deep packing cube (dimensions: 14"x9.5"x5")<br/>
            Durable and water resistant nylon fabric<br/>
            Rear trolley sleeve, allows you to slide the bag over the trolley handle of a suitcase<br/>
            4 multi-directional spinner wheels<br/>
            3 exterior zippered pockets, and two interior mesh zippered pockets<br/>
            The carry-on can be wheeled or carried using its adjustable cushioned shoulder strap.<br/>
            Two year warranty`
    },
    {
        _id: '__006',
        imageSRC: healthProgramS,
        imageFullSRC: healthProgram,
        title: 'Thryve Gut Health Program™',
        brandName: 'Thryve',
        prices: {
            originalPrice: 199,
            price: 37,
            shippingTaxesPrice: 0
        },
        description: `
            Thryve's Gut Health Program analyzes poop to target the good and bad bacteria in your gut to treat the root cause
            of your symptoms. Comes with Digital Health Report, Customized Probiotics, and Personalized Food Plan.`
    },
    {
        _id: '__007',
        imageSRC: healthspanSystemS,
        imageFullSRC: healthspanSystem,
        title: 'AM PM Healthspan System',
        brandName: 'Healthycell',
        prices: {
            originalPrice: 100,
            price: 9,
            shippingTaxesPrice: 3.99
        },
        description: `
            The only nutrition system that optimizes the “building block” of your body – the cell – with 90+ nutrients 24 hours
            per day so you can feel healthier, perform better and improve healthspan.`
    },
    {
        _id: '__008',
        imageSRC: sleepphonesS,
        imageFullSRC: sleepphones,
        title: 'SleepPhones® Wireless (Bluetooth® Sleep Headphones)',
        brandName: 'SleepPhones',
        prices: {
            originalPrice: 100,
            price: 6,
            shippingTaxesPrice: 5.99
        },
        description: `
            The SleepPhones® Wireless you love; now with a better battery.<br/>
            <br/>
            SleepPhones® Wireless sleep headphones are traditional headphones made bed-friendly and cord-free.<br/>
            <br/>
            Ideal for sleep, travel, yoga and leisure time, our ultra-slim, patented soft and comfortable “headphones in a headband” 
            make listening to audio a dream. Unplug and surround yourself in an ultimate sound experience – without disturbing, or
            being disturbed by, the person next to you.<br/>
            <br/>
            SleepPhones® were designed by a family doctor and provide wearable comfort that's literally music to your ears.<br/>
            <br/>
            Benefits
            <br/>
            Stream audio via Bluetooth® wireless technology<br/>
            Fall asleep faster, stay asleep longer, and wake up refreshed<br/>
            Get a good night’s sleep naturally, without medications<br/>
            Reduce ambient noise, conversations, traffic sounds, and snoring<br/>
            Relieve stress by listening to your favorite tunes, soothing meditation sounds, radio, sleep-enhancing rhythms,
            audiobooks, or any other preferred music<br/>
            No uncomfortable earbuds or bulky ear enclosures<br/>
            Features<br/>
            <br/>
            Sound: Superior sound quality with eco-friendly, lead-free electronics<br/>
            Bluetooth®: Easily pair/sync with Bluetooth® enabled devices and stream audio wirelessly within 15-30 feet (5-10 meters)<br/>
            Controls: Volume and play/pause controls built into the Bluetooth® module<br/>
            Headphones: Removable speakers in a hypoallergenic, machine washable fabric headband`
    },
    {
        _id: '__009',
        imageSRC: kanibiS,
        imageFullSRC: kanibi,
        title: 'Isolate CBD Oil Tincture',
        brandName: 'KANIBI',
        prices: {
            originalPrice: 59,
            price: 0,
            shippingTaxesPrice: 0
        },
        description: ''
    },
    {
        _id: '__010',
        imageSRC: motherDirtS,
        imageFullSRC: motherDirt,
        title: 'The Peacekeeper Probiotic Starter Kit',
        brandName: 'Mother Dirt',
        prices: {
            originalPrice: 99,
            price: 9,
            shippingTaxesPrice: 5.5
        },
        description: `
            Three steps to total skin wellness.
            The PeaceKeeper features the foundations of our Active Probiotic System to restore the skin microbiome,
            for your most biologically balanced and radiant skin.<br/>
            <br/>
            Comes complete with:<br/>
            <br/>
            Probiotic Foaming Cleanser<br/>
            Probiotic Moisturizing Serum<br/>
            AO+ Restorative Mist<br/>
            Sample size Probiotic Hydrating Hair Wash & Conditioner`
    },
    {
        _id: '__011',
        imageSRC: ellaMilaS,
        imageFullSRC: ellaMila,
        title: 'Founder’s Faves!',
        brandName: 'ella+mila',
        prices: {
            originalPrice: 119,
            price: 24,
            shippingTaxesPrice: 0
        },
        description: `
            This carefully-curated bundle arranged by the founder herself includes many of Ella+Mila’s best sellers.
            Grab it while it’s hot! Limited quantities available.`
    },
    {
        _id: '__012',
        imageSRC: spectrumS,
        imageFullSRC: spectrum,
        title: 'Full Spectrum CBD Softgels 60 ct',
        brandName: 'Ananda Hemp',
        prices: {
            originalPrice: 90,
            price: 5,
            shippingTaxesPrice: 2.74
        },
        description: ''
    },
    {
        _id: '__013',
        imageSRC: tequilaS,
        imageFullSRC: tequila,
        title: 'Cava de Oro Extra Añejo',
        brandName: 'Cava de Oro',
        prices: {
            originalPrice: 120,
            price: 10,
            shippingTaxesPrice: 0
        },
        description: `
            Rested for 5 years in American and French Oak red wine barrels.<br/>
            Cava de Oro Extra Añejo Tequila has a dark amber color with a mixture of aromas including cinnamon, caramel, vanilla, and nutmeg.<br/>
            Its smooth entry quickly expands revealing a delicious range of toasted flavors, cinnamon, and maple syrup,
            all of which linger on the palate for a pleasant amount of time.<br/>
            100% agave<br/>
            Fermented in stainless steel tanks<br/>
            NOM #1477<br/>
            Bottle size: 750ml`
    },
    {
        _id: '__014',
        imageSRC: extremeKitS,
        imageFullSRC: extremeKit,
        title: 'Extreme Whitening Kit',
        brandName: 'Dr. Brite',
        prices: {
            originalPrice: 250,
            price: 55,
            shippingTaxesPrice: 0
        },
        description: `
            Your smile is the first thing people notice, make sure you have one to remember for all the right reasons!<br/>
            <br/>
            Dentist-created, Extreme Whitening Kit is proven to show the same results as a $600 professional whitening treatment* 
            administered by your dentist.<br/>
            <br/>
            Erase smoking, coffee, wine, green juice and other stain filled habits in minutes from the comfort of your home.
            Safe to use on caps, veneers, braces, crowns and bridges.`
    },
    {
        _id: '__015',
        imageSRC: bigBundleS,
        imageFullSRC: bigBundle,
        title: 'Big Bundle',
        brandName: 'Mounia Haircare',
        prices: {
            originalPrice: 89,
            price: 3,
            shippingTaxesPrice: 5.99
        },
        description: `
            Our big bundle has the clean haircare system with a larger serum travel sized Rinse and Masque.<br/>
            Free of parabens, sulfates, paraffin, phthalates, synthetic dyes, and artificial fragrances.<br/>
            We’re cruelty-free, vegan, color-safe and made from organic ingredients.<br/>
            Contains: 1 fl/oz Serum, 11.5 fl/oz Rinse, 11.5 fl/oz Masque, 2 fl/oz Rinse, and a 2 fl/oz Masque`
    },
    {
        _id: '__016',
        imageSRC: luvvEnegryPackS,
        imageFullSRC: luvvEnegryPack,
        title: 'The LUVV Energy Combo Pack',
        brandName: 'LUVV Labs',
        prices: {
            originalPrice: 60,
            price: 0,
            shippingTaxesPrice: 0
        },
        description: `
            Get an energizing combo pack of 3 refreshing LUVV Air supplement diffusers at 10% off the price of purchasing them individually.<br/>
            3 zesty options to support energy and focus:<br/>
            RISE - A berry and mint flavored combination of B vitamins and more<br/>
            WAKE - A citrus flavored blend of energizing ingredients<br/>
            VITA - A citrus and mint flavored combination of essential vitamins<br/>`
    },
    {
        _id: '__017',
        imageSRC: basicsSetS,
        imageFullSRC: basicsSet,
        title: 'Basics Set',
        brandName: 'Disco',
        prices: {
            originalPrice: 72,
            price: 0,
            shippingTaxesPrice: 0
        },
        description: `
            This Moisturizer-Face Cleanser Stick-Body Wash Deodorant cleansing set is the formula for full-body wellness 
            and a top-to-bottom clean. A balancing blend of essentials, this combo of must-haves keeps you cleansed, 
            moisturized, refreshed, and smelling your best.`
    },
    {
        _id: '__018',
        imageSRC: collagenEyeS,
        imageFullSRC: collagenEye,
        title: 'Insight Collagen Eye Treatment Mask',
        brandName: 'Circcell Skincare',
        prices: {
            originalPrice: 90,
            price: 8,
            shippingTaxesPrice: 2.99
        },
        description: `
            The luxury of refreshed eyes, in a flash.
            When the delicate under-eye area is in need of a wake-up call, this smart sheet mask rises to the challenge.
            Made using live, freeze dried collagen, and the highest concentration of stable collagen on the market, fibers 
            and peptides mimic skin’s own collagen to offer visible restorative results. In just 15 minutes signs of fatigue, 
            damage and stress are diminished, with lasting results following regular use. Seeing really is believing.`
    },
    {
        _id: '__019',
        imageSRC: maxout4S,
        imageFullSRC: maxout4,
        title: 'MAXOUT 4-Pack',
        brandName: 'MAXPRO Fitness',
        prices: {
            originalPrice: 100,
            price: 10,
            shippingTaxesPrice: 6.99
        },
        description: `
            Enhance your workout with the MAXOUT 4-Pack.<br/>
            <br/>
            Your Pack includes:<br/>
            <br/>
            • Foam Roller<br/>
            • Exercise Mat<br/>
            • Suspension Handles<br/>
            • Jump Belt`
    }
]);

export const SUITE_INFO_DATA = Object.freeze([
    {
        icon: <GiftIcon />,
        question: 'What is a Gifting Suite?',
        answer: `A Gifting Suite gives our trendsetters access to 10+ private coupons (for FREE or 75-99% off) so you can discover new, amazing brands at zero (or little) cost. <br/><br/>
Each trendsetter receives a Gifting Suite simply for joining Giftly! Once opened, a Gifting Suites lasts 20 mins — during this time you can claim any coupon you’d like, and use it to unlock a FREE or 75-99% off gift. <br/><br/>
You can earn more Gifting Suites by completing Challenges. Gifting Suites are also randomly emailed to our most active users, for free (so keep an eye out for our emails).`
    },
    {
        icon: <PointsIcon />,
        question: 'What are Giftly Points?',
        answer: `Giftly offers the highest Reward Points of any online shopping site. Points can be used instead of cash when shopping with Giftly. <br/><br/>
Earn Points by either shopping or completing challenges, such as sharing products with followers or inviting friends to Giftly. <br/><br/>
Use Points during checkout to reduce the cost of your order. (Please note, Points you apply to an order cannot be refunded.) <br/><br/>
Note that your Points balance will expire after 12 months of Points inactivity, so be sure to either Earn or Use your Points before they run out!`
    },
    {
        icon: <TimeIcon />,
        question: 'Do my Gifts and Gift claims expire?',
        answer: `If not otherwise marked, unopened Gifting Suites expire 3 months from when they were added to your account. And if you do not use Giftly within a 6 month period, your Giftly account will be closed to make room for new members. <br/><br/>
Once opened, your Gifting Suite will stay open for 20 mins — you will have 20 mins in which to claim a Coupon Reward. <br/><br/>
Some Coupon Rewards will have an expiration and can only be claimed once — you must use your claimed Coupon Reward within the marked time or it will expire.`
    }
]);

export const REWARDS_INFO_DATA = Object.freeze([
    {
        icon: <PointsIcon />,
        question: 'What are Points?',
        answer:
            'Points can be used instead of cash when shopping with Giftly.<br/><br/>' +
            'Earn Points by either shopping or completing challenges, such as sharing products with followers or inviting friends to Giftly.<br/><br/>' +
            'Use Points during checkout to reduce the cost of your order. (Please note, Points you apply to an order cannot be refunded.)<br/><br/>' +
            'Note that your Points balance will expire after 12 months of Points inactivity, so be sure to either Earn or Use your Points before they run out!'
    },
    {
        icon: <PointsIcon />,
        question: 'What are Rewards?',
        answer:
            'Rewards represent exclusive offers and deals on millions of Giftly products. <br/><br/>' +
            'When you see a deal you like, simply “claim it” to save it to your Giftly rewards.<br/><br/>' +
            'We’ll automatically apply rewards you’ve clipped to your next eligible order. <br/><br/>' +
            'Note that all rewards have an expiry, so be sure to use them before they run out!'
    },
    {
        icon: <PointsIcon />,
        question: 'What are challenges?',
        answer:
            'Challenges are tasks you can complete to earn a specific reward, be it a Points bonus, Reward coupon, Gift, or something else.<br/><br/>' +
            'You can see a list of your challenges in your rewards center, but rest assured that we’ll automatically credit you with a reward whenever you complete a task, whether or not you activate it.'
    }
]);

export const SUBSCRIPTION_PLANS = Object.freeze([
    {
        descriptor: 'Your current membership',
        name: 'Free',
        pricingPlan: 'For those who just want to try it out. Access standard products and rewards',
        pricingItems: ['1 free Gifting Suite', 'Basic shop', 'Rewards Basic sharing Rewards']
    },
    {
        descriptor: 'Our Premium membership\n',
        name: '$29 / mo',
        pricingPlan: 'For those who want it all. Exclusive access to products, rewards and more',
        pricingItems: ['4 Gifting Suites per month', '2x shop Rewards', '2x sharing Rewards']
    }
]);

export const CATEGORIES = [
    {
        key: 'clothing, shoes & jewelry',
        name: 'Clothing'
    },
    {
        key: 'beauty',
        name: 'Beauty'
    },
    {
        key: 'health & personal care',
        name: 'Personal'
    },
    {
        key: 'electronics',
        name: 'Electronics'
    },
    {
        key: 'sports & outdoors',
        name: 'Outdoors'
    },
    {
        key: 'pet supplies',
        name: 'Pets'
    },
    {
        key: 'baby',
        name: 'Baby'
    },
    {
        key: 'home & kitchen',
        name: 'Home'
    },
    {
        key: 'grocery & gourmet food',
        name: 'Grocery'
    },
    {
        key: 'cell phones & accessories',
        name: 'Phones'
    },
    {
        key: 'office products',
        name: 'Office'
    },
    {
        key: 'patio, lawn & garden',
        name: 'Garden'
    },
    {
        key: 'tools & home improvement',
        name: 'Tools'
    },
    {
        key: 'movies & tv',
        name: 'Movies'
    },
    {
        key: 'musical instruments',
        name: 'Music'
    },
    {
        key: 'toys & games',
        name: 'Games'
    },
    {
        key: 'automotive',
        name: 'Auto'
    },
    {
        key: 'arts, crafts & sewing',
        name: 'Arts & crafts'
    }
];
