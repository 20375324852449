import React, { Fragment } from 'react';
import { Button, ConfigureIcon, getFilterParamsFromUrl, Icon, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { PROMOTIONS_PRICE_STATE } from '../../../../../../shared';
import { toLocalUSString } from '../../../../../../utils';
import { CheckedFiltersList } from './components/CheckedFiltersList';
import { CheckedPrice } from './components/CheckedPrice';
import { Sorting } from './components/Sorting';
import { Filters, Item, Styles, Total } from './styles';
import { getTotalCountViewValue } from './utils';

export const CheckedFilters = inject('store')(
    observer(({ store, simple }) => {
        const {
            totalHits,
            totalShown,
            updateIsOpenOnMobile,
            updateCategory,
            updateBrand,
            updatePrice,
            clearAll,
            query: { price, categories, brands }
        } = store.marketplaceStore;

        const checkedBrands = brands.filter((brand) => brand.checked);
        const checkedCategories = categories.filter((category) => category.checked);

        return (
            <Fragment>
                <Styles>
                    {simple && (
                        <Button type='primary' onClick={() => updateIsOpenOnMobile(true)}>
                            <Icon stylePriority size='24px' component={() => <ConfigureIcon />} />
                            <Typography.Text type='body2'>Filter</Typography.Text>
                        </Button>
                    )}

                    <Sorting />

                    <Filters>
                        {simple && totalShown ? (
                            <Total>
                                <Typography.Text type='descriptor2' color='secondaryText'>
                                    {toLocalUSString(totalShown)} of {getTotalCountViewValue(totalHits)} results
                                </Typography.Text>
                            </Total>
                        ) : null}

                        <CheckedFiltersList list={checkedCategories} onRemove={updateCategory} />
                        <CheckedFiltersList list={checkedBrands} onRemove={updateBrand} />
                        <CheckedPrice price={price} onRemove={updatePrice} />
                        {(checkedCategories.length ||
                            checkedBrands.length ||
                            price.lte !== PROMOTIONS_PRICE_STATE.lte ||
                            price.gte !== PROMOTIONS_PRICE_STATE.gte) && (
                            <Item
                                cantClose
                                onClick={() => clearAll({ isCategoryPage: getFilterParamsFromUrl().gt, sort: true })}
                                type='primary'
                            >
                                Clear All
                            </Item>
                        )}

                        {!simple && totalShown ? (
                            <Total>
                                <Typography.Text type='descriptor2' color='secondaryText'>
                                    {toLocalUSString(totalShown)} of {getTotalCountViewValue(totalHits)} results
                                </Typography.Text>
                            </Total>
                        ) : null}
                    </Filters>
                </Styles>
            </Fragment>
        );
    })
);
