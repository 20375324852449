import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, Button, ForwardArrowIcon, Icon, TOP_INDENTS, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $secondaryText, $white, ROUTE_PATHS } from '../../../../shared';
import { BoxLoader } from '../../../../shared/components/BoxLoader';
import { Logo } from '../../../../shared/components/Logo';
import { StyledButton, StyledImage, StyledText, WaitlistedContentWrap, WaitlistedStyles } from './styles';

const Waitlisted = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const { profileImageURL, profileEmail, isValidInviteCodeUsed } = store.accountStore;

        const goToApplyInviteCode = () => {
            store.trackingStore.sendEvent({
                type: 'event',
                name: 'SignUp_Waitlisted_InviteCode'
            });
            navigate(ROUTE_PATHS.applyInviteCode);
        };

        const continueAsGuest = () => {
            store.trackingStore.sendEvent({
                type: 'event',
                name: 'SignUp_Waitlisted_ContinuedAsGuest'
            });
            navigate(ROUTE_PATHS.shop);
        };

        return (
            <WaitlistedStyles>
                <WaitlistedContentWrap>
                    <Logo hasBottomIndent size='48px' color={$secondaryText} />
                    {profileImageURL ? (
                        <StyledImage src={profileImageURL} />
                    ) : (
                        <BoxLoader waveColor={$white} iconColor={$secondaryText} iconSize='70px' withShadow={false} />
                    )}
                    <Typography.Title color='primaryText' style={{ textAlign: 'center', ...TOP_INDENTS.XL }}>
                        You’re on the waitlist
                    </Typography.Title>
                    <StyledText style={{ ...BOTTOM_INDENTS.XL, ...TOP_INDENTS.SM }} type='body1' color='primaryText'>
                        As soon as a space opens we’ll e-mail you at:
                    </StyledText>
                    {profileEmail && (
                        <StyledText
                            style={BOTTOM_INDENTS.XL}
                            data-e2e='onboarding_email'
                            type='badgeLarge'
                            color='primaryText'
                        >
                            {profileEmail}
                        </StyledText>
                    )}
                    {!isValidInviteCodeUsed ? (
                        <StyledButton
                            data-e2e='onboarding_add-inviteCode-button'
                            type='primary'
                            size='large'
                            ghost
                            onClick={goToApplyInviteCode}
                        >
                            I have an invite code
                        </StyledButton>
                    ) : null}
                    <Button
                        data-e2e='onboarding_continueAsGuest-button'
                        size='large'
                        type='unstyled'
                        onClick={continueAsGuest}
                    >
                        <Typography.Text type='badgeLarge' as='span'>
                            Continue as guest
                        </Typography.Text>
                        <Icon stylePriority size='20px' component={() => <ForwardArrowIcon />} />
                    </Button>
                </WaitlistedContentWrap>
            </WaitlistedStyles>
        );
    })
);

export default Waitlisted;
