import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $primaryBackground, $primaryText } from '../../../../../../shared';

export const Styles = styled.div`
    height: ${({ $simple }) => ($simple ? 'calc(100% - 44px)' : '100%')};
    padding: ${({ $simple }) => ($simple ? '' : '0 24px')};
`;

export const ExtraContentStyles = styled.div`
    padding: ${({ $simple }) => ($simple ? '0 24px' : '0 24px 0 0')};
`;

export const DividerWrap = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 48px 0;
`;

export const Divider = styled.div`
    display: ${({ $simple }) => ($simple ? 'none' : 'block')};
    width: calc(100% - 24px);
    height: 1px;
    border-top: 2px dashed ${$primaryText};
    margin-left: ${({ $left }) => ($left ? '0' : '24px')};
    margin-right: ${({ $right }) => ($right ? '0' : '24px')};
`;

export const StyledText = styled(Typography.Text)`
    white-space: nowrap;
`;

export const DetailsWrapper = styled.div`
    margin: 4px 0 16px;
`;

export const StyledDetailsText = styled(Typography.Text)`
    margin-bottom: 6px;
`;

export const ClaimBlock = styled.div`
    width: fit-content;
    padding: 12px;
    background-color: ${$primaryBackground};
`;
