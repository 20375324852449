import React from 'react';
import { useSimpleInterface } from '@klickly/front-packages';
import { useModal } from '../../../../../shared';
import { GRID_POINTS } from '../../../../../utils';
import { CancelOrderModal } from '../components/OrdersContainer/components/OrdersByDateList/components/OrderByDateListItem/components/OrdersList/components/Order/components/cancelOrderModal/components/CancelOrderModal';

export const useCancelOrderModal = (orderId, onAfterSuccess = () => null) => {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    const { openModal, closeModal } = useModal();

    return () =>
        openModal({
            children: (
                <CancelOrderModal
                    onClose={() => {
                        onAfterSuccess();
                        closeModal();
                    }}
                    orderId={orderId}
                    onAfterSuccess={onAfterSuccess}
                />
            ),
            settings: {
                fullscreen: simple,
                padding: '0'
            },
            modalContentStyles: { padding: '0' }
        });
};
