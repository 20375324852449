import { getMetadata } from '../../../../../shared/utils/metadata';
import { get } from '../../../../../utils';

export const getMetaDataByPromotion = (promotion) => [
    ...getMetadata(),
    {
        name: 'campaignId',
        value: get(promotion, 'campaign') || ''
    },
    {
        name: 'externalPromotionId',
        value: get(promotion, 'externalId') || ''
    }
];
