import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $primary, $secondaryText, $white } from '../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const StyledTitle = styled(Typography.Title)`
    && {
        > span {
            display: block;
            color: ${$white};
            text-align: center;

            @media (${GRID_DEVICES_MAX.md}) {
                color: ${$primary};
            }

            h1 {
                text-align: center;
                @media (${GRID_DEVICES_MAX.md}) {
                    color: ${$primary};
                }
            }

            &:nth-of-type(2) {
                @media (${GRID_DEVICES_MAX.md}) {
                    color: ${$secondaryText};
                }
            }
        }
    }
`;

export const StyledText = styled.span``;
