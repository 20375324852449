import { flow, getParent, types } from 'mobx-state-tree';
import { strategies } from '../config/strategies';
import { kcpAPI } from '../shared';
import { models } from './constants';

export const FavoritesPromotionsStore = types
    .model('FavoritesPromotionsStore', {
        pending: types.boolean,
        error: models.ErrorModel,
        favoritesPromotions: types.array(models.PromotionModel)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const getFavoritesPromotionsByStrategy = flow(function* getFavoritesPromotionsByStrategy(
            strategy = strategies.customerWishlist
        ) {
            self.pending = true;
            try {
                const data = yield self.root.get(`${kcpAPI.marketplace.promotions.get}?strategyId=${strategy}`);
                self.favoritesPromotions = data?.promotions || [];
            } catch (error) {
                self.error = error;
            } finally {
                self.pending = false;
            }
        });

        return { getFavoritesPromotionsByStrategy };
    });
