import { Button, Icon } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $border, $white } from '../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const StyledWrap = styled.div`
    max-width: 969px;
    width: 100%;
    height: 100%;
    padding-top: 8px;
    margin: 0 auto;

    @media (${GRID_DEVICES_MAX.sm}) {
        padding-bottom: 160px;
    }
`;

export const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;

    @media (${GRID_DEVICES_MAX.sm}) {
        padding: 24px;
        background-color: ${$white};
        z-index: 2;

        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;

        :after {
            content: '';
            border-top: 1px solid ${$border};
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
        }
    }
`;

export const StyledButton = styled(Button)`
    && {
        width: ${({ $simple }) => ($simple ? '100%' : '305px')};
        justify-content: center;
    }
`;

export const StyledPDPButtonsWrap = styled.div`
    padding: 24px;
`;

export const StyledPDPButton = styled(Button)`
    && {
        width: 100%;
        justify-content: center;

        ${({ $simple }) =>
            $simple
                ? css`
                      width: 100%;
                  `
                : ''}
    }
`;

export const StyledPDPWrapper = styled.div`
    width: 100%;
    margin: 0 auto;

    ${({ $simple }) =>
        $simple
            ? css`
                  height: calc(100% - 72px);
              `
            : ''}
`;

export const StyledIcon = styled(Icon)`
    padding: ${({ $simple }) => ($simple ? '24px' : '0 0 24px')};
    box-sizing: content-box;
`;
