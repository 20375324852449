import React, { useState } from 'react';
import { BOTTOM_INDENTS, Button, Checkbox, TOP_INDENTS, Typography } from '@klickly/front-packages';
import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ErrorField } from '../../../../../../../../shared/components/ErrorField';
import { CheckboxWrap } from '../../../../../BillingForm/styles';
import { StyledButtonsWrap } from './styled';

export const StripeForm = ({ defaultShippingAddress, error, onSubmit, onError, returnUrl, setBillingPending }) => {
    const [isTheSameAddress, setIsTheSameAddress] = useState(Boolean(defaultShippingAddress));
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setBillingPending(true);

        if (!stripe || !elements) return;

        const confirmParams = { return_url: returnUrl };

        if (isTheSameAddress) {
            confirmParams.payment_method_data = {
                billing_details: {
                    address: {
                        city: defaultShippingAddress.city,
                        country: defaultShippingAddress.countryCode,
                        line1: defaultShippingAddress.address1,
                        line2: defaultShippingAddress.address2,
                        postal_code: defaultShippingAddress.zipCode,
                        state: defaultShippingAddress.state
                    },
                    name: `${defaultShippingAddress.firstName} ${defaultShippingAddress.lastName}`,
                    phone: defaultShippingAddress.phone
                }
            };
        }

        const result = await stripe.confirmSetup({
            elements,
            confirmParams,
            redirect: 'if_required'
        });

        if (result.error) {
            onError(result.error);
            setBillingPending(false);
        }

        return onSubmit(result.setupIntent);
    };

    const paymentElementOptions = {
        layout: 'tabs',
        wallets: { applePay: 'never', googlePay: 'never' }
    };

    return (
        <form id='payment-form' onSubmit={handleSubmit}>
            <PaymentElement id='payment-element' options={paymentElementOptions} />
            <div style={BOTTOM_INDENTS.XXS} />
            <CheckboxWrap style={{ ...BOTTOM_INDENTS.SM, ...TOP_INDENTS.SM }}>
                <Checkbox
                    data-e2e='settings_checkbox'
                    type='rounded'
                    checkedColor='primary'
                    onChange={(e) => setIsTheSameAddress(e.target.checked)}
                    checked={isTheSameAddress}
                    size='20px'
                >
                    <Typography.Text as='span'>My billing address is the same as my shipping address</Typography.Text>
                </Checkbox>
            </CheckboxWrap>
            {error ? <ErrorField hasBottomIndent error={error} /> : null}
            {!isTheSameAddress ? <AddressElement id='address-element' options={{ mode: 'billing' }} /> : null}
            <StyledButtonsWrap>
                <Button block data-e2e='settings_add-billingInfo-button' htmlType='submit' type='primary' size='large'>
                    Add billing info
                </Button>
            </StyledButtonsWrap>
        </form>
    );
};
