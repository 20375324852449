import { ROUTE_PATHS, SEASONAL_CHALLENGE_ALIASES } from '../constants';

export const getAcceptChallengeRedirect = (alias) => {
    switch (alias) {
        case SEASONAL_CHALLENGE_ALIASES.COMPLETE_ONBOARDING:
            return ROUTE_PATHS.personalizationCategories;
        case SEASONAL_CHALLENGE_ALIASES.LAUNCH_GS:
            return ROUTE_PATHS.suiteGs;
        case SEASONAL_CHALLENGE_ALIASES.INVITE_FRIENDS:
            return ROUTE_PATHS.inviteAndEarn;
        case SEASONAL_CHALLENGE_ALIASES.MAKE_PURCHASE:
            return ROUTE_PATHS.shop;
        case SEASONAL_CHALLENGE_ALIASES.EARN_POINTS:
        default:
            return ROUTE_PATHS.deals;
    }
};
