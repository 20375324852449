import React from 'react';
import { Button, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import cloveImage from '../../../../assets/img/onboarding/clove.png';
import cloveImageSimple from '../../../../assets/img/onboarding/clove_simple.png';
import { $primaryBackground, $white } from '../../../../shared';

export const StyledWrap = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: ${({ $simple }) => ($simple ? 'column' : 'row')};
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 3;
    overflow-x: hidden;
`;

export const StyledAsideWrap = styled.aside`
    width: ${({ $simple }) => ($simple ? '100%' : '50%')};
    height: ${({ $simple }) => ($simple ? 'auto' : '100%')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${$primaryBackground};
    padding: ${({ $simple }) => ($simple ? '24px' : '0 40px')};
`;

export const StyledIconWrap = styled.div`
    width: 160px;
    height: 160px;
    background-color: ${$white};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledContentWrap = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${$white};
    padding: ${({ $simple }) => ($simple ? '24px' : '48px 80px')};
    margin: auto;

    -webkit-flex: auto;
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const StyledTextWrap = styled.div`
    max-width: 413px;
    position: relative;
    z-index: 2;
`;

export const StyledImage = styled.img`
    width: 160px;
    height: 160px;
    object-fit: contain;
    border-radius: 50%;
`;

export const StyledHeadLine1 = styled(Typography.Title)`
    margin-bottom: 40px;
`;

export const StyledText = styled(Typography.Text)`
    text-align: center;
`;

export const StyledButton = styled(Button)`
    && {
        padding-right: 83px;
        padding-left: 83px;

        ${({ $simple }) =>
            $simple
                ? css`
                      width: 100%;
                  `
                : ''};
    }
`;

export const StyledBackgroundImage = styled.img`
    height: ${({ $simple }) => ($simple ? '369px' : '572px')};
    position: absolute;
    right: -5px;
    z-index: 1;
`;

export const CloveImage = ({ simple }) => (
    <StyledBackgroundImage $simple={simple} src={simple ? cloveImageSimple : cloveImage} alt='Clove' />
);
