import styled, { css } from 'styled-components';
import { $tartRed } from '../../../../../../shared';
import { CouponWrapper } from '../../../../../../shared/components/CouponWrapper';

export const StyledWrapper = styled(CouponWrapper)`
    width: 100%;
    height: auto;
    padding: 30px 48px;

    display: flex;
    align-items: center;
    justify-content: space-around;

    span.anticon {
        cursor: initial;
    }

    ${({ $simple }) =>
        $simple
            ? css`
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  padding: 32px 16px;
                  margin-bottom: 32px;
              `
            : ''}
`;

export const StyledContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    ${({ $simple }) =>
        $simple
            ? css`
                  gap: 16px;
                  flex-direction: column;
                  width: 100%;
                  justify-content: space-around;

                  h2 {
                      padding: 0 10px;
                  }
              `
            : ''}
`;

export const StyledText = styled.div`
    && {
        h3 {
            margin: 0;
        }
    }
`;

export const StyledButton = styled.div`
    &&,
    &&:hover,
    &&:active {
        min-width: 140px;
        border-radius: 2px;
        width: auto;
        padding: 14px 20px;
        height: 48px;
        background-color: ${$tartRed};
        border: none;
        cursor: pointer;
    }
`;
