import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import qs from 'qs';
import { app } from '../../../../config';
import { ROUTE_PATHS, SEASONAL_CHALLENGE_ALIASES, useSeasonalChallengeNotifications } from '../../../../shared';
import { PDPComponent } from '../../../../shared/components/PDP';

const ProductPage = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const [searchParams] = useSearchParams();
        const { state } = useLocation();
        const couponUUID = state?.couponUUID;
        const backClickRef = state?.ref;
        const { id } = useParams();
        const { setPrevProductId } = store.pdpStore;
        const { activeChallenge } = store.seasonalChallengeStore;
        const showSeasonalChallengeNotification = useSeasonalChallengeNotifications(store);
        const message = 'Step 2 of 3. Add product to cart';

        const onClose = () => {
            searchParams.delete('referrer');
            const params = searchParams.toString();
            return navigate({
                pathname: backClickRef || ROUTE_PATHS.shop,
                search: params ? `?${qs.stringify(qs.parse(params))}` : ''
            });
        };

        const handleBrandNameClick = (brandId) => {
            window.open(`${app.giftlyUrl}/brands/${brandId}`, '__blank');
        };

        useEffect(() => {
            if (id) setPrevProductId(id);
        }, [id]);

        useEffect(() => {
            if (
                activeChallenge?.alias === SEASONAL_CHALLENGE_ALIASES.MAKE_PURCHASE &&
                activeChallenge.challengeAccepted
            ) {
                showSeasonalChallengeNotification('2', message);
            }
        }, []);

        return (
            <PDPComponent
                key={id}
                id={id}
                customizationsKey='shop'
                rewardUuid={couponUUID}
                onClose={onClose}
                onBrandNameButtonClick={handleBrandNameClick}
            />
        );
    })
);

export default ProductPage;
