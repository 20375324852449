import React from 'react';
import { AccountContainer } from './components/AccountContainer';
import { Styles } from './styles';

const AccountPage = () => {
    return (
        <Styles>
            <AccountContainer />
        </Styles>
    );
};

export default AccountPage;
