import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilledHeartIcon, HeartIcon, Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../constants';
import { ButtonLoaderWrap, StyledButton, StyledIcon, StyledLabel, StyledWrap } from './styled';

export const WishlistButton = inject('store')(
    observer(({ store, promotionId, selectedVariantId }) => {
        const navigate = useNavigate();
        const { pathname } = useLocation();
        const [pending, setPending] = useState(false);
        const { isLoggedIn, wishlist, updateAccountWishlist, removeWishlistItem, getAccountData } = store.accountStore;
        const { promotion } = store.pdpStore;
        const isProductInWishlist = wishlist.some((item) => item.promotionId === promotionId);

        const handleItemAdd = async () => {
            setPending(true);
            await updateAccountWishlist({
                wishlists: [{ promotion: promotionId, variantId: selectedVariantId }],
                onAfterSuccess: getAccountData
            });
            setPending(false);
        };

        const handleItemRemove = async () => {
            const item = wishlist.find((item) => item.promotionId === promotionId);
            const itemId = item?._id;

            setPending(true);
            await removeWishlistItem(itemId);
            setPending(false);
        };

        const handleClick = async (e) => {
            e.stopPropagation();
            store.trackingStore.trackClick(promotion);

            if (isLoggedIn) {
                !isProductInWishlist ? await handleItemAdd() : await handleItemRemove();
            } else {
                navigate(`${ROUTE_PATHS.signIn}?redirectTo=${pathname}`);
            }
        };

        return (
            <StyledWrap>
                <StyledButton
                    type='unstyled'
                    $isProductInWishlist={isProductInWishlist}
                    onClick={!pending ? handleClick : null}
                >
                    <StyledLabel $isProductInWishlist={isProductInWishlist}>
                        {isProductInWishlist ? 'In Wishlist' : 'Add to wishlist'}
                    </StyledLabel>
                    {!pending ? (
                        <StyledIcon
                            size='20px'
                            component={() => (isProductInWishlist ? <FilledHeartIcon /> : <HeartIcon />)}
                        />
                    ) : (
                        <ButtonLoaderWrap>
                            <Icon.Loader size='24px' />
                        </ButtonLoaderWrap>
                    )}
                </StyledButton>
            </StyledWrap>
        );
    })
);
