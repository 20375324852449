import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon, InfoIcon, RIGHT_INDENTS, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../shared';
import { StyledWrapper } from './styled';

const Info = inject('store')(
    observer(({ store }) => {
        const { seasonalChallenge } = store.seasonalChallengeStore;
        const navigate = useNavigate();

        return (
            <StyledWrapper onClick={() => navigate(ROUTE_PATHS.seasonalChallenge)}>
                <Icon size='16px' color='canvas' component={() => <InfoIcon />} style={RIGHT_INDENTS.XS} />
                <Typography.Text type='badgeSmall' color='canvas'>
                    What is an {seasonalChallenge?.name}?
                </Typography.Text>
            </StyledWrapper>
        );
    })
);

export default Info;
