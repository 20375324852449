import React, { Fragment } from 'react';
import {
    BOTTOM_INDENTS,
    Col,
    Icon,
    InfoIcon,
    TOP_INDENTS,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { observer } from 'mobx-react';
import PopoverWithButton from '../../../../shared/components/PopoverWithButton';
import { GRID_POINTS } from '../../../../utils';
import { StyledRow } from './styled';

const PageTitle = observer(({ title, tooltipTitle, tooltipText, showInfoIcon = true }) => {
    const simple = useSimpleInterface(GRID_POINTS.md);
    return (
        <StyledRow
            gutter={16}
            align='middle'
            justify='space-between'
            style={{
                ...TOP_INDENTS.M,
                ...(simple ? BOTTOM_INDENTS.L : BOTTOM_INDENTS.M)
            }}
        >
            <Col>
                <Typography.Title level={simple ? 1 : 2}>{title}</Typography.Title>
            </Col>
            {simple && showInfoIcon ? (
                <Col>
                    <PopoverWithButton
                        placement='topRight'
                        showButton={false}
                        content={
                            <Fragment>
                                <div style={BOTTOM_INDENTS.S}>
                                    <b>{tooltipTitle}</b>
                                </div>
                                {tooltipText}
                            </Fragment>
                        }
                    >
                        <Icon size='32px' component={() => <InfoIcon />} />
                    </PopoverWithButton>
                </Col>
            ) : null}
        </StyledRow>
    );
});

export default PageTitle;
