import { Button } from '@klickly/front-packages';
import styled from 'styled-components';
import { $white } from '../../../../../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../../../../../utils';

export const StyledButton = styled(Button)`
    && {
        padding: ${({ $rewardsInfo }) => ($rewardsInfo ? 24 : 20)}px 64px;
    }

    div.ant-col {
        font: 500 20px / 24px 'IBM Plex Sans', Sans;
    }
`;

export const StyledButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (${GRID_DEVICES_MAX.md}) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 24px 24px 0;
        background: ${$white};
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        z-index: 2;
    }

    &&& {
        .ant-btn {
            &:hover {
                .anticon {
                    color: ${$white};
                }
            }

            .anticon {
                font-size: 24px;
            }
        }
    }
`;
