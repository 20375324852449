import React from 'react';
import { BOTTOM_INDENTS, EmojiSadFrownIcon, Typography } from '@klickly/front-packages';
import { GIFTLY_CUSTOMER_NOT_EXISTS_ERROR } from '../../../utils';
import { ErrorFieldIcon, ErrorFieldStyles } from './ErrorField.styles';

export const ErrorField = ({
    hasBottomIndent,
    error,
    showIcon = false,
    icon = <EmojiSadFrownIcon />,
    textType = 'descriptor2'
}) => {
    return (
        <ErrorFieldStyles style={hasBottomIndent ? BOTTOM_INDENTS.SM : {}}>
            {showIcon && <ErrorFieldIcon color='error' size='130%' component={() => icon} />}
            <Typography.Text data-e2e='errorField_customer-notExist-error' color='error' type={textType}>
                {error === GIFTLY_CUSTOMER_NOT_EXISTS_ERROR
                    ? 'We couldn’t find an account matching the username and password you entered. Please check your inputs and try again.'
                    : error}
            </Typography.Text>
        </ErrorFieldStyles>
    );
};
