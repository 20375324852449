import React from 'react';
import { DiamondIcon, Form, Icon, Input } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { VALIDATION_RULES } from '../../../../../../shared';
import { ButtonWrap, StyledButton, StyledForm, StyledIcon } from './styles';

export const ApplyInviteCodeForm = inject('store')(
    observer(({ store }) => {
        const { pending, applyInviteCode: onSubmit, errorMessage } = store.onboardingStore;

        const [form] = Form.useForm();

        const submitted = (data) => {
            if (typeof onSubmit !== 'undefined') {
                onSubmit(data);
            }
        };

        return (
            <StyledForm form={form} onFinish={submitted} validateTrigger={['onBlur']}>
                <Form.Item
                    name='inviteCode'
                    rules={VALIDATION_RULES.REQUIRED}
                    validateStatus={errorMessage && 'error'}
                    hasFeedback
                    help={errorMessage}
                >
                    <Input
                        size='large'
                        showWarningIcon
                        label='Invite code'
                        autoComplete='off'
                        prefix={<StyledIcon component={() => <DiamondIcon />} />}
                    />
                </Form.Item>
                <ButtonWrap>
                    <StyledButton data-e2e='onboarding_applyCode-button' htmlType='submit' type='primary' size='large'>
                        Apply invite code
                    </StyledButton>
                </ButtonWrap>
                {pending && <Icon.Loader />}
            </StyledForm>
        );
    })
);
