import React, { useState } from 'react';
import { ArrowChevronDownIcon, ArrowChevronUpIcon, Icon, Promotion, Typography } from '@klickly/front-packages';
import placeholder from '../../../../../assets/img/image-placeholder.svg';
import { convertDateToDefaultFormat } from '../../../../../utils';
import {
    StyledContent,
    StyledDescription,
    StyledExpiredAt,
    StyledShowMoreButton,
    StyledTitle,
    StyledWrapper
} from './styled';

const Reward = ({ reward }) => {
    const shortDescriptionLength = 100;
    const [showDetails, setShowDetails] = useState(false);
    return (
        <StyledWrapper>
            <Promotion.Img
                placeholder={placeholder}
                height='80px'
                width='80px'
                images={[{ src: reward?.image, position: 1 }]}
            />
            <StyledContent>
                <StyledTitle level={3}>{reward.title}</StyledTitle>
                {reward.description ? (
                    <StyledDescription type='body2'>
                        {showDetails
                            ? reward.description
                            : `${reward.description.substring(0, shortDescriptionLength)}${
                                  reward.description.length > shortDescriptionLength ? '...' : ''
                              }`}
                    </StyledDescription>
                ) : null}
                {reward.expireAt ? (
                    <StyledExpiredAt type='body2'>
                        Expires {convertDateToDefaultFormat(reward?.expireAt)}
                    </StyledExpiredAt>
                ) : null}
                {reward.description && reward.description?.length > shortDescriptionLength ? (
                    <StyledShowMoreButton type='unstyled' onClick={() => setShowDetails(!showDetails)}>
                        <Typography.Text type='body2'>See Details</Typography.Text>
                        <Icon
                            stylePriority
                            component={() => (showDetails ? <ArrowChevronUpIcon /> : <ArrowChevronDownIcon />)}
                        />
                    </StyledShowMoreButton>
                ) : null}
            </StyledContent>
        </StyledWrapper>
    );
};

export default Reward;
