import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI, ROUTE_PATHS } from '../shared';
import { models } from './constants';

export const RewardsStore = types
    .model('RewardsStore', {
        selectedReward: types.maybeNull(
            types.model({
                _id: types.maybe(types.maybeNull(types.string)),
                description: types.maybe(types.maybeNull(types.string)),
                image: types.maybe(types.maybeNull(types.string)),
                status: types.maybe(types.maybeNull(types.string)),
                title: types.maybe(types.maybeNull(types.string)),
                uuid: types.identifier,
                promotions: types.array(types.string),
                expireAt: types.maybeNull(types.string)
            })
        ),
        rewards: types.maybeNull(types.array(models.RewardModel)),
        hasNextPage: types.boolean,
        error: models.ErrorModel,
        applyRewardError: types.maybeNull(types.string),
        pending: types.boolean,
        updateRewardPending: types.boolean,
        updateRewardError: models.ErrorModel,
        selectedRewardId: types.maybeNull(types.string),
        newRewardUuid: types.maybeNull(types.string),
        selectedRewardPromotionId: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const resetErrors = () => {
            self.error = null;
            self.applyRewardError = null;
        };
        const getRewardByUuid = flow(function* getRewardByUuid(uuid, params = {}) {
            const { onError = () => null } = params;
            resetErrors();
            self.pending = true;
            try {
                const response = yield self.root.get(kcpAPI.rewards.getByUUID, {
                    uuid
                });
                if (response.redirect === 'signIn') {
                    return self.root.routeNavigate(ROUTE_PATHS.signIn, {
                        state: { uuid }
                    });
                }
                self.selectedReward = response;
                self.selectedRewardPromotionId = response?.promotions[0];
                self.pending = false;
                self.newRewardUuid = uuid;
            } catch (error) {
                if (error.response) {
                    self.newRewardUuid = uuid;
                    self.applyRewardError = error?.response?.data?.message;
                    onError();
                }
            }
        });

        const applyRewards = flow(function* applyRewards(uuid, redirectTo) {
            resetErrors();
            self.newRewardUuid = null;
            try {
                const response = yield self.root.post(kcpAPI.rewards.apply, {
                    uuid
                });

                yield self.root.claimsPointsStore.getPoints();
                yield self.root.claimsPointsStore.getClaims();

                if (response?.rewardKind === 'coupon') {
                    yield getRewardByUuid(uuid);
                    return self.root.routeNavigate(ROUTE_PATHS.rewards, { state: {} });
                }

                if (response?.redirect === 'gifting-suite') {
                    yield self.root.claimsPointsStore.getClaims();
                    yield self.root.suiteStore.getCurrentSuite();
                    return self.root.routeNavigate(redirectTo || ROUTE_PATHS.suiteGs, { state: {} });
                }

                return self.root.routeNavigate(ROUTE_PATHS.rewards, { state: {} });
            } catch (error) {
                self.applyRewardError = error?.response?.data?.message;
            }
        });

        const getRewards = flow(function* getRewards(limit = 6) {
            self.pending = true;
            try {
                const response = yield self.root.get(`${kcpAPI.rewards.list}?limit=${limit}`);
                self.hasNextPage = response.meta.hasNextPage;
                self.rewards = [...response.data];
                self.pending = false;
            } catch (error) {
                self.pending = false;
                self.error = error;
            }
        });

        const getRewardById = (rewardId) => self.rewards.find((reward) => reward._id === rewardId);

        const unclipReward = flow(function* unclipReward(rewardId) {
            self.updateRewardPending = true;
            try {
                yield self.root.patch(kcpAPI.rewards.unclip(rewardId));
                self.rewards = self.rewards.map((reward) =>
                    reward._id === rewardId ? { ...reward, status: 'deleted' } : reward
                );
                self.updateRewardPending = false;
            } catch (error) {
                self.updateRewardPending = false;
                self.updateRewardError = error;
            }
        });

        const undoUnclipReward = flow(function* undoUnclipReward(rewardId) {
            self.updateRewardPending = true;
            try {
                yield self.root.patch(kcpAPI.rewards.unclipUndo(rewardId));
                self.rewards = self.rewards.map((reward) =>
                    reward._id === rewardId ? { ...reward, status: 'available' } : reward
                );
                self.updateRewardPending = false;
            } catch (error) {
                self.updateRewardPending = false;
                self.updateRewardError = error;
            }
        });

        const getRewardDetails = flow(function* getRewardDetails(rewardId) {
            self.pending = true;
            try {
                const response = yield self.root.get(kcpAPI.rewards.get(rewardId));
                self.rewards = self.rewards.map((reward) => (reward._id === rewardId ? response : reward));
                self.pending = false;
                self.selectedRewardId = response._id;
                self.selectedRewardPromotionId = response?.promotions[0]?._id;
            } catch (error) {
                self.pending = false;
                self.error = error;
            }
        });

        const setSelectedRewardPromotionId = (promotionId) => {
            self.selectedRewardPromotionId = promotionId;
        };
        const setSelectedReward = (reward) => {
            self.selectedReward = reward;
        };

        return {
            getRewards,
            getRewardById,
            applyRewards,
            unclipReward,
            undoUnclipReward,
            getRewardDetails,
            getRewardByUuid,
            resetErrors,
            setSelectedRewardPromotionId,
            setSelectedReward
        };
    });
