import React, { useRef } from 'react';
import { Carousel, Typography } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { $primary } from '../../../../shared';
import { StyledLogo, StyledWrapper } from './styled';

const OnboardingInfoSlide = observer(() => {
    const settings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        swipeToSlide: true,
        onSwipe: () => ref?.current && ref.current.innerSlider.pause()
    };
    const ref = useRef(null);

    return (
        <StyledWrapper>
            <StyledLogo color={$primary} size='40px' />
            <Carousel {...settings} carouselRef={ref} initialSlide={0}>
                <Typography.Text style={{ height: '56px' }} type='body1' align='center' color='primary'>
                    Access exclusive deals on the <br /> hottest brands around
                </Typography.Text>
                <Typography.Text style={{ height: '56px' }} type='body1' align='center' color='primary'>
                    Access personalized Gifting Suites <br /> with products you&apos;ll love
                </Typography.Text>
                <Typography.Text style={{ height: '56px' }} type='body1' align='center' color='primary'>
                    Earn rewards by completing <br /> purchases & challenges
                </Typography.Text>
                <Typography.Text style={{ height: '56px' }} type='body1' align='center' color='primary'>
                    Shop from +120,000 exclusive <br /> offers
                </Typography.Text>
            </Carousel>
        </StyledWrapper>
    );
});

export default OnboardingInfoSlide;
