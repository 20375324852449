import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI } from '../../shared';

export const PromotionModel = types
    .model('PromotionModel', {
        pending: false,
        campaign: types.string,
        campaignName: types.maybeNull(types.string),
        isValidForMarketplace: types.maybe(types.boolean),
        id: types.identifier,
        externalId: types.string,
        isValidForWidget: types.maybe(types.boolean),
        account: types.string,
        title: types.string,
        description: types.maybeNull(types.string),
        brandName: types.string,
        shopDomain: types.string,
        createdAt: types.maybe(types.string),
        category: types.string,
        keywords: types.array(types.string),
        labels: types.array(types.string),
        storeType: types.maybe(types.string),
        descriptions: types.array(types.string),
        images: types.array(
            types.model('Images', {
                externalUpdatedAt: types.maybe(types.string),
                src: types.string,
                externalId: types.identifierNumber,
                position: types.number,
                externalProductId: types.number
            })
        ),
        options: types.array(
            types.model('Options', {
                values: types.array(types.string),
                name: types.string,
                externalId: types.identifierNumber,
                position: types.number,
                externalProductId: types.number
            })
        ),
        couponUUID: types.maybeNull(types.string),
        variants: types.array(
            types.model('Variants', {
                quantity: types.number,
                parentAttributesValues: types.maybeNull(types.array(types.string)),
                originalPrice: types.maybeNull(types.number),
                inventoryPolicy: types.maybeNull(types.string),
                externalId: types.identifierNumber,
                title: types.maybeNull(types.string),
                requiresShipping: types.boolean,
                price: types.maybeNull(types.number),
                fulfillmentService: types.maybeNull(types.string),
                option1: types.maybeNull(types.string),
                position: types.maybeNull(types.number),
                sku: types.maybeNull(types.string),
                externalProductId: types.number,
                priceDetails: types.maybe(
                    types.maybeNull(
                        types.model({
                            originalPrice: types.maybeNull(types.number),
                            rewardsPrice: types.maybeNull(types.number),
                            rewardsPercent: types.maybeNull(types.number),
                            youPayPrice: types.maybeNull(types.number)
                        })
                    )
                ),
                pointsDetails: types.model({
                    maxPoints: types.number,
                    isWelcome: types.boolean,
                    list: types.array(
                        types.model({
                            type: types.string,
                            value: types.number
                        })
                    )
                }),
                rewards: types.array(
                    types.model({
                        rewardUUID: types.maybeNull(types.string),
                        _id: types.maybeNull(types.string)
                    })
                )
            })
        ),
        customizableOptions: types.maybeNull(types.array(types.string)),
        _id: types.string,
        score: types.maybeNull(types.number),
        creativeSettings: types.maybe(
            types.model('CreativeSettings', {
                adImageView: types.string,
                logoImage: types.maybe(
                    types.model('logoImage', {
                        Location: types.maybeNull(types.string)
                    })
                )
            })
        )
    })
    .views((self) => ({
        get root() {
            return getParent(self, 3);
        },
        get hasReward() {
            return Boolean(self.couponUUID);
        }
    }))
    .actions((self) => ({
        getIsRewardAvailable: flow(function* getIsRewardAvailable(onAfterSuccess) {
            try {
                self.pending = true;
                const data = yield self.root.get(kcpAPI.rewards.getIsRewardApplicable(self.couponUUID), {
                    account: self.account
                });
                onAfterSuccess(data?.isApplicable);
            } catch (e) {
                onAfterSuccess(false);
            } finally {
                self.pending = false;
            }
        })
    }));
