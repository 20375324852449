import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
    position: relative;
`;

export const StyledLoaderWrap = styled.div`
    position: relative;
    width: 100%;
    height: 400px;
`;
