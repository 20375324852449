import React from 'react';
import { Image, Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import giftPointImg from '../../../../assets/img/landing/gift-points.svg';
import { $primaryText } from '../../../../shared';

export const StyledWrap = styled.div`
    width: 100%;
    padding: 40px;
    display: flex;
    justify-content: center;
    margin-bottom: 64px;

    ${({ $simple }) =>
        $simple
            ? css`
                  padding: 40px 24px;
              `
            : ''}
`;

export const StyledContentWrap = styled.div`
    width: 100%;
    max-width: 1062px;
    border: 2px solid ${$primaryText};
`;

export const StyledHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 48px 72px;

    ${({ $simple }) =>
        $simple
            ? css`
                  padding: 48px 24px;
                  flex-direction: column;
                  justify-content: center;
              `
            : ''}
`;

export const StyledContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    ${({ $withoutArrows }) =>
        $withoutArrows
            ? css`
                  justify-content: center;
              `
            : ''}
`;

export const StyledTextWrap = styled.div``;

export const StyledUppercaseText = styled(Typography.Text)`
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
`;

export const GiftPointsImg = ({ marginBottom }) => (
    <Image
        style={{ width: '112px', height: '36px', marginBottom: marginBottom }}
        src={giftPointImg}
        alt='gift and points'
    />
);

export const StyledArrowWrap = styled.div`
    padding: 24px;
`;

export const StyledProductsWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;

    ${({ $withoutArrows }) =>
        $withoutArrows
            ? css`
                  max-width: 400px;
                  flex-wrap: wrap;
                  justify-content: center;
              `
            : ''}
`;

export const StyledProductWrap = styled.div`
    margin-bottom: 20px;
`;

export const StyledImg = styled(Image)`
    && {
        width: 188px;
        height: 188px;
        margin-bottom: 6px;

        ${({ $simple }) =>
            $simple
                ? css`
                      width: 152px;
                      height: 152px;
                  `
                : ''}
    }
`;

export const StyledProductDiscount = styled(Typography.Text)`
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
`;
