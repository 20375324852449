import React from 'react';
import { BOTTOM_INDENTS, Typography } from '@klickly/front-packages';
import { convertDateToDefaultFormat, isToDayDate } from '../../../../../../../../../../utils';
import { OrdersList } from './components/OrdersList';
import { Styles } from './styles';

export const OrderByDateListItem = ({ date, orders, ...rest }) => {
    return (
        <Styles {...rest}>
            <Typography.Title style={BOTTOM_INDENTS.SM} level={3}>
                {isToDayDate(date) ? 'New' : convertDateToDefaultFormat(date)}
            </Typography.Title>
            <OrdersList orders={orders} />
        </Styles>
    );
};
