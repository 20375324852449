import React from 'react';
import { Link, useLocation, useNavigationType } from 'react-router-dom';
import { Col, Row, Search, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../../utils';
import { $background } from '../../../../constants';
import { AppMenu } from '../../../AppMenu';
import { Logo } from '../../../Logo';
import BurgerMenu from './components/BurgerMenu';
import Menu from './components/Menu';
import { HeaderContainer, LinkWrap, StyledBar, StyledHeader, StyledMenuWrapper } from './styled';

const Header = inject('store')(
    observer(
        ({
            store,
            headerRef,
            disableOnLogoClick,
            menuDisabled,
            menuHidden,
            menuBarHidden,
            stickyPosition,
            ...props
        }) => {
            const location = useLocation();
            const navigationType = useNavigationType();
            const simple = useSimpleInterface(GRID_POINTS.md);
            const { getHomePageLink } = store.navigationStore;
            const { clearAll } = store.marketplaceStore;

            const onLogoClick = (e) => {
                if (disableOnLogoClick) e.preventDefault();
            };

            return (
                <StyledHeader ref={headerRef} $singleBar={menuBarHidden} $stickyPosition={stickyPosition} {...props}>
                    <StyledBar $singleBar={menuBarHidden}>
                        <HeaderContainer>
                            <Row align='middle'>
                                {simple ? (
                                    <Col>
                                        <BurgerMenu />
                                    </Col>
                                ) : null}
                                <Col>
                                    <LinkWrap>
                                        <Link to={getHomePageLink()} onClick={onLogoClick}>
                                            <Logo
                                                onClick={() =>
                                                    clearAll({ shouldClearSearch: true, shouldClearStrategy: true })
                                                }
                                            />
                                        </Link>
                                    </LinkWrap>
                                </Col>
                            </Row>

                            {menuHidden ? null : <AppMenu disabled={menuDisabled} />}
                        </HeaderContainer>
                    </StyledBar>
                    {!simple && !menuBarHidden ? (
                        <StyledBar $background={$background}>
                            <HeaderContainer>
                                <StyledMenuWrapper>
                                    <Menu />
                                </StyledMenuWrapper>
                                <Search location={location} navigationType={navigationType} />
                            </HeaderContainer>
                        </StyledBar>
                    ) : null}
                </StyledHeader>
            );
        }
    )
);

export default Header;
