import React from 'react';
import { Typography } from '@klickly/front-packages';
import { ContentWrap, IconWrap, ItemWrap } from './styles';

export const Item = ({ icon, title, description, children, ...rest }) => {
    return (
        <ItemWrap {...rest}>
            <ContentWrap>
                {!!icon && <IconWrap className='icon-wrap'>{icon}</IconWrap>}

                <div>
                    <Typography.Title level={3}>{title}</Typography.Title>
                    <Typography.Text type='body2' color='secondaryText'>
                        {description}
                    </Typography.Text>
                </div>
            </ContentWrap>

            {children}
        </ItemWrap>
    );
};
