import React from 'react';
import { useNavigate } from 'react-router-dom';
import { KliHero } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../../../shared';

export const SettingsHeader = inject('store')(
    observer(({ store }) => {
        const { account: profile } = store.accountStore;
        const navigate = useNavigate();

        const onEdit = () => {
            navigate(ROUTE_PATHS.account);
        };

        const onBack = () => {
            navigate(-1);
        };

        return (
            <KliHero
                title={profile?.firstName}
                avatar={profile?.giftly?.instagram?.profilePicUrl}
                subTitle='Hello'
                editText='Edit profile'
                simple={false}
                onBack={onBack}
                onEdit={onEdit}
            />
        );
    })
);
