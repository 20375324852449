import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BOTTOM_INDENTS,
    Col,
    CopyIcon,
    DeleteIcon,
    Icon,
    Row,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $secondaryText, ROUTE_PATHS } from '../../../../../../../../shared';
import { copyToClipBoard } from '../../../../../../../../shared/utils';
import { GRID_POINTS } from '../../../../../../../../utils';
import { useCancelOrderModal } from '../../../../hooks';
import { StyledButton, StyledCopiedMessage, StyledTextWrapper } from './styled';

const OrderActions = inject('store')(
    observer(({ store, orderNumber, orderId }) => {
        const navigate = useNavigate();
        const { getDetailsByOrderNumber } = store.orderStore;
        const { getOrderById } = store.ordersStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const [showCopiedMessage, setShowCopiedMessage] = useState(null);

        const details = getDetailsByOrderNumber(orderNumber);
        const order = getOrderById(orderId);
        const trackingUrl = order?.fulfillment?.tracking_url || null;
        const isAvailableForCancel = order?.isAvailableForCancel || false;
        const openCancelOrderModal = useCancelOrderModal(order?._id, () => navigate(ROUTE_PATHS.orders));
        const columnsCount = [isAvailableForCancel, trackingUrl].filter(Boolean).length + 2;

        const handleButtonClick = (value) => {
            setShowCopiedMessage(value);
            return copyToClipBoard(value);
        };

        useEffect(() => {
            const timer = setTimeout(() => {
                setShowCopiedMessage(null);
            }, 1000);

            return () => {
                clearTimeout(timer);
            };
        }, [showCopiedMessage]);

        const buttonsData = [
            {
                title: 'Giftly ID:',
                text: details?.orderNumber,
                visible: true,
                onClick: () => handleButtonClick(details?.orderNumber)
            },
            {
                title: `${details?.merchantName} ID:`,
                text: order?.externalId,
                visible: true,
                onClick: () => handleButtonClick(order?.externalId)
            },
            {
                title: 'Tracking URL:',
                text: trackingUrl,
                visible: Boolean(trackingUrl),
                onClick: () => handleButtonClick(trackingUrl)
            }
        ];

        return details ? (
            <Row gutter={simple ? [16, 16] : 16} align='middle' style={BOTTOM_INDENTS.XXL}>
                {buttonsData.map(({ title, text, visible, onClick }) => {
                    if (!visible || !text) {
                        return null;
                    }
                    return (
                        <Col span={simple ? 24 : 24 / columnsCount} key={title}>
                            <StyledButton type='unstyled' block size='small' onClick={onClick}>
                                {showCopiedMessage && showCopiedMessage === text ? (
                                    <StyledCopiedMessage>
                                        <Typography.Text type='descriptor2'>Copied to clipboard</Typography.Text>
                                    </StyledCopiedMessage>
                                ) : null}
                                <StyledTextWrapper>
                                    <Typography.Text type='descriptor2'>{title}</Typography.Text>
                                    <Typography.Text type='descriptor2'>{text}</Typography.Text>
                                </StyledTextWrapper>
                                <Icon color={$secondaryText} size='24px' component={() => <CopyIcon />} />
                            </StyledButton>
                        </Col>
                    );
                })}
                {isAvailableForCancel ? (
                    <Col span={simple ? 24 : 24 / columnsCount}>
                        <StyledButton $type='delete' type='unstyled' block size='small' onClick={openCancelOrderModal}>
                            <Row gutter={12} align='middle' justify='center'>
                                <Col>
                                    <Icon size='24px' component={() => <DeleteIcon />} />
                                </Col>
                                <Col>
                                    <Typography.Text type='descriptor2'>Cancel order</Typography.Text>
                                </Col>
                            </Row>
                        </StyledButton>
                    </Col>
                ) : null}
            </Row>
        ) : null;
    })
);

export default OrderActions;
