import React from 'react';
import { BOTTOM_INDENTS, Icon } from '@klickly/front-packages';
import { Styles } from './styles';

export const InfoItem = ({ hasBottomIndent, icon, children, ...rest }) => {
    return (
        <Styles style={hasBottomIndent ? BOTTOM_INDENTS.XXL : {}} {...rest}>
            {icon && (
                <div style={BOTTOM_INDENTS.XL}>
                    <Icon size='24px' component={() => icon} />
                </div>
            )}

            {children}
        </Styles>
    );
};
