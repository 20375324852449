import { Icon } from '@klickly/front-packages';
import styled from 'styled-components';
import { $primaryText } from '../../../../shared';

export const StyledWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
`;

export const StyledContent = styled.div`
    max-width: 1144px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;

    @media (max-width: 1060px) {
        justify-content: center;
    }

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        padding: 0 24px;
    }
`;

export const StyledTextBlock = styled.div`
    width: 303px;
    margin-right: 20px;

    @media (max-width: 700px) {
        width: 100%;
        margin-right: 0;
    }
`;

export const StyledReviewsWrap = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 360px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @media (max-width: 1060px) {
        grid-template-columns: repeat(1, 360px);
    }

    @media (max-width: 700px) {
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 24px;
    }
`;

export const StyledReview = styled.div`
    width: 100%;
    min-height: 210px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 2px solid ${$primaryText};
    padding: 21px;
`;

export const StyledReviewHead = styled.div``;

export const StyledStarsWrap = styled(Icon)`
    margin-bottom: 17px;

    svg {
        margin-right: 10px;
    }
`;
