import React, { useEffect } from 'react';
import { BOTTOM_INDENTS, Col, Icon, Row, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import Info from './components/Info';
import Steps from './components/Steps';
import { StyledWrapper } from './styled';

const SeasonalChallengeSection = inject('store')(
    observer(({ store }) => {
        const {
            getSeasonalChallenge,
            pending,
            seasonalChallenge,
            challenges,
            isNoActiveChallenge
        } = store.seasonalChallengeStore;
        const isCompleted = seasonalChallenge?.completed;
        const headerText = !(isNoActiveChallenge && isCompleted)
            ? `${seasonalChallenge?.name} awaits`
            : `${seasonalChallenge?.name} completed`;

        useEffect(() => {
            getSeasonalChallenge();
        }, []);

        if ((!pending && !seasonalChallenge) || (!pending && !challenges?.length)) return null;

        return pending ? (
            <Icon.Loader />
        ) : (
            <StyledWrapper>
                <Row align='middle' justify='center' style={BOTTOM_INDENTS.L}>
                    <Col>
                        <Typography.Title level={2} color='canvas' align='center'>
                            {headerText}
                        </Typography.Title>
                    </Col>
                </Row>
                <Steps />
                <Info />
            </StyledWrapper>
        );
    })
);

export default SeasonalChallengeSection;
