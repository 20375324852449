import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { useLoginClick } from '../../../hooks';
import { ROUTE_PATHS } from '../../constants';
import CartData from './components/CartData';
import ClaimsPointsData from './components/ClaimsPointsData';
import { StyledRow } from './styles';

export const AppMenu = inject('store')(
    observer(({ store, showClaimsPointsData = true, showCartData = true, disabled = false }) => {
        const navigate = useNavigate();
        const { isLoggedIn } = store.accountStore;
        const onLoginClick = useLoginClick();

        return (
            <StyledRow gutter={10} data-e2e='appMenu_menu-block' $disabled={disabled} align='middle' justify='end'>
                {isLoggedIn && showClaimsPointsData ? (
                    <Col>
                        <ClaimsPointsData />
                    </Col>
                ) : null}
                {showCartData && (
                    <Col>
                        <CartData />
                    </Col>
                )}
                {!isLoggedIn ? (
                    <Col flex={160}>
                        <Row justify='center' align='middle'>
                            <Col>
                                <Button
                                    data-e2e='appMenu_logIn-button'
                                    size='small'
                                    type='unstyled'
                                    onClick={onLoginClick}
                                >
                                    <Typography.Text type='badgeMedium'>Log in</Typography.Text>
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    data-e2e='appMenu_apply-button'
                                    size='small'
                                    type='primary'
                                    onClick={() => navigate(ROUTE_PATHS.onboarding)}
                                >
                                    <Typography.Text type='badgeMedium'>Apply</Typography.Text>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                ) : null}
            </StyledRow>
        );
    })
);
