import { Carousel } from '@klickly/front-packages';
import styled from 'styled-components';

export const Overlay = styled(Carousel)`
    margin-left: ${({ centred }) => (centred ? 0 : '-20px')};
    padding-bottom: 10px;
    width: 100%;
`;

export const EmptyState = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const Item = styled.div`
    padding: 10px;
    text-align: left;
`;
