import CompletedNotification from './components/CompletedNotification';
import InfoNotification from './components/InfoNotification';
import OrderReceivedNotification from './components/OrderReceivedNotification';
import RewardEarnedNotification from './components/RewardEarnedNotification';
import RewardNotification from './components/RewardNotification';

const NotificationContent = () => null;

NotificationContent.Reward = RewardNotification;
NotificationContent.RewardEarned = RewardEarnedNotification;
NotificationContent.Completed = CompletedNotification;
NotificationContent.OrderReceived = OrderReceivedNotification;
NotificationContent.Info = InfoNotification;

export default NotificationContent;
