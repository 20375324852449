import React from 'react';
import { ArrowChevronBackIcon, ArrowChevronForwardIcon } from '@klickly/front-packages';
import { StyledArrowButton } from './styled';

const ArrowButton = ({ type = 'prev', ...props }) => {
    return (
        <StyledArrowButton type='unstyled' {...props}>
            {type === 'next' ? <ArrowChevronForwardIcon /> : <ArrowChevronBackIcon />}
        </StyledArrowButton>
    );
};

export default ArrowButton;
