import React from 'react';
import { Typography, useSimpleInterface } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../shared';
import { Link } from '../../../../shared/components/Link';
import { GRID_POINTS } from '../../../../utils';
import { SignInButtonStyles } from './styles';

export const SignInButton = observer(() => {
    const pathname = ROUTE_PATHS.signIn;
    const simple = useSimpleInterface(GRID_POINTS.lg);
    return (
        <SignInButtonStyles>
            <Typography.Text color={simple ? 'primaryText' : 'secondaryText'}>Already a member?</Typography.Text>
            <Link to={pathname}>
                <Typography.Text
                    data-e2e='onboarding_signIn-button'
                    color={simple ? 'primaryText' : 'secondaryText'}
                    type='badgeSmall'
                >
                    Sign in
                </Typography.Text>
            </Link>
        </SignInButtonStyles>
    );
});
