import styled from 'styled-components';
import { $white } from '../../../../../../../../../../../../shared';
import { GRID_DEVICES_MAX } from '../../../../../../../../../../../../utils';

export const AccordionSectionStyles = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
`;

export const AccordionHeadStyles = styled.button`
    background-color: transparent;
    color: #444;
    cursor: pointer;
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    outline: none;

    :focus {
        -webkit-tap-highlight-color: transparent;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        text-align: left;
    }
`;

export const AccordionContentStyles = styled.div`
    background-color: ${$white};
    overflow: hidden;
    // TODO: fix open state styles/animation
    max-height: ${({ active }) => (active ? '1000px' : '0')};
    transition: max-height 0.6s ease;
    position: relative;
`;
