import React, { Fragment } from 'react';
import {
    Avatar,
    BackArrowIcon,
    BOTTOM_INDENTS,
    DeleteIcon,
    EditIcon,
    Icon,
    ImgCrop,
    Typography,
    Upload,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import defaultAvatar from '../../../assets/img/avatar-default.svg';
import { GRID_POINTS } from '../../../utils';
import { upload } from '../../utils/upload';
import { StyledError, StyledSpin, StyledUpload, StyledWrapper } from './styled';

export const AvatarUpload = inject('store')(
    observer(({ store, justify = 'center', iconRemove, children, ...props }) => {
        const {
            setAvatarErrorMessage,
            avatarErrorMessage,
            avatarLoading,
            avatar,
            removeCustomerAvatar,
            uploadCustomerAvatar
        } = store.accountStore;

        const onDeleteAvatar = (e) => {
            e.stopPropagation();
            removeCustomerAvatar();
        };

        const onBeforeCrop = async (file) => {
            setAvatarErrorMessage('');
            try {
                const response = await upload(file, { maxFileSize: 5 });
                if (typeof response === 'string') {
                    setAvatarErrorMessage(response);
                    return false;
                }
                return response;
            } catch (error) {
                setAvatarErrorMessage(error);
            }
        };

        const simple = useSimpleInterface(GRID_POINTS.md);
        return (
            <StyledWrapper>
                <StyledUpload $justify={justify}>
                    <StyledSpin spinning={avatarLoading} indicator={<Icon.Loader />} size='large'>
                        <Avatar
                            size={simple ? 80 : 240}
                            src={avatar || defaultAvatar}
                            style={simple ? null : { ...BOTTOM_INDENTS.S }}
                        />
                    </StyledSpin>
                    <ImgCrop
                        modalClassName='crop-modal'
                        beforeCrop={onBeforeCrop}
                        cropShape='round'
                        modalWidth='602px'
                        modalProps={{
                            getContainer: () => document.querySelector('.app-wrapper')
                        }}
                        modalOk={<Typography.Text type='badgeSmall'>Apply</Typography.Text>}
                        modalCancel={
                            <Fragment>
                                <Icon component={() => <BackArrowIcon />} />
                                <Typography.Title level={3}>Edit image</Typography.Title>
                            </Fragment>
                        }
                    >
                        <Upload customRequest={uploadCustomerAvatar} showUploadList={false} {...props}>
                            {avatar && iconRemove && (
                                <Icon size='16px' onClick={onDeleteAvatar} component={() => <DeleteIcon />} />
                            )}
                            <Icon size='16px' component={() => <EditIcon />} />
                        </Upload>
                    </ImgCrop>
                    {!simple && (
                        <StyledError>
                            <Typography.Text align='center' type='descriptor1'>
                                {avatarErrorMessage}
                            </Typography.Text>
                        </StyledError>
                    )}
                    {children}
                </StyledUpload>
                {simple && avatarErrorMessage && (
                    <StyledError>
                        <Typography.Text align='center' type='descriptor1'>
                            {avatarErrorMessage}
                        </Typography.Text>
                    </StyledError>
                )}
            </StyledWrapper>
        );
    })
);
