import { device } from '@klickly/front-packages';
import { flow, getParent, types } from 'mobx-state-tree';
import { app } from '../config';
import { kcpAPI, ROUTE_PATHS, TASK_STATUSES } from '../shared';
import { getRandomArrayIndex } from '../utils';
import { TASK_MODEL, TASKS_DATA_MODELS } from './constants';

const isChrome = window.navigator.userAgent.indexOf('Chrome') !== -1;

export const TasksStore = types
    .model('TasksStore', {
        tasks: types.maybeNull(types.array(TASK_MODEL)),
        inviteFriendTaskData: types.maybeNull(TASKS_DATA_MODELS.inviteFriend),
        shareProductTaskData: types.maybeNull(TASKS_DATA_MODELS.shareProduct),
        writeProductReviewTaskData: types.maybeNull(TASKS_DATA_MODELS.writeProductReview),
        pending: false,
        loaded: false,
        dataPending: false,
        processing: false,
        errorMessage: types.maybeNull(types.string),
        taskErrorMessage: types.maybeNull(types.string),
        cancelInvitePending: false
    })
    .views((self) => {
        return {
            get root() {
                return getParent(self);
            },
            get customerTasks() {
                return self.tasksExists
                    ? self.showChromeExtensionTask
                        ? self.tasks
                        : self.tasks.filter((task) => task.alias !== 'install-extension')
                    : null;
            },
            get rewardsTasks() {
                return self.tasksExists
                    ? self.showChromeExtensionTask
                        ? self.tasks.filter((task) => task.alias !== 'share-product')
                        : self.tasks.filter(
                              (task) => task.alias !== 'install-extension' && task.alias !== 'share-product'
                          )
                    : null;
            },
            get pointsTasks() {
                const pointsTasksKeys = ['invite-friend', 'copy-link', 'rate-giftly', 'shop-suite'];
                return self.tasksExists ? self.tasks.filter((task) => pointsTasksKeys.includes(task.alias)) : [];
            },
            get isNoActivePointsTasks() {
                return self.pointsTasks?.every(
                    (task) =>
                        task.data.status === TASK_STATUSES.completed ||
                        task.data.status === TASK_STATUSES.inactive ||
                        task.data.status === TASK_STATUSES.disable
                );
            },
            get tasksExists() {
                return self.tasks && self.tasks.length;
            },
            get inviteFriendTask() {
                return self.tasksExists ? self.tasks.find((task) => task.alias === 'invite-friend') : null;
            },
            get shareProductTask() {
                return self.tasksExists ? self.tasks.find((task) => task.alias === 'share-product') : null;
            },
            get copyProductLinkTask() {
                return self.tasksExists ? self.tasks.find((task) => task.alias === 'copy-link') : null;
            },
            get writeProductReviewTask() {
                return self.tasksExists ? self.tasks.find((task) => task.alias === 'write-product-review') : null;
            },
            get rateGiftlyTask() {
                return self.tasksExists ? self.tasks.find((task) => task.alias === 'rate-giftly') : null;
            },
            get shopSuiteTask() {
                return self.tasksExists ? self.tasks.find((task) => task.alias === 'shop-suite') : null;
            },
            get selectCategoriesTask() {
                return self.tasksExists
                    ? self.tasks.find((task) => task.alias === 'onboarding-select-categories')
                    : null;
            },
            get addShippingTask() {
                return self.tasksExists ? self.tasks.find((task) => task.alias === 'onboarding-add-shipping') : null;
            },
            get addToWishlistTask() {
                return self.tasksExists
                    ? self.tasks.find((task) => task.alias === 'onboarding-add-product-to-wishlist')
                    : null;
            },
            get installExtensionTask() {
                return self.tasksExists ? self.tasks.find((task) => task.alias === 'install-extension') : null;
            },
            get showChromeExtensionTask() {
                return !device.isMobile() && isChrome;
            },
            get finishedChallengesAmount() {
                return self.tasksExists ? self.tasks.filter((task) => task?.data.status === 'completed').length : 0;
            },
            get challengesAmount() {
                return self.tasksExists ? self.tasks.length : 0;
            },
            get randomActiveTask() {
                if (self.tasksExists) {
                    const activeTasks = self.tasks.filter(
                        (task) => task?.isActive && !task?.isHidden && task?.data?.status === 'active'
                    );
                    return activeTasks?.length ? activeTasks[getRandomArrayIndex(activeTasks)] : null;
                }

                return null;
            },
            get hasActiveTasks() {
                return self.customerTasks?.some((task) => task.data.status === TASK_STATUSES.active);
            }
        };
    })
    .actions((self) => {
        const setPending = (pending) => {
            self.pending = pending;
        };

        const setError = (error) => {
            if (error && error.response && error.response.data) {
                self.errorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.errorMessage = error.message;
            }
        };

        const setTaskError = (error) => {
            if (error && error.response && error.response.data) {
                self.taskErrorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.taskErrorMessage = error.message;
            }
        };

        const clearTaskError = () => {
            self.taskErrorMessage = null;
        };

        const onErrorMessage = () => {
            self.root.trackingStore.sendEvent({
                type: 'event',
                name: 'error',
                data: { message: self.errorMessage }
            });
        };

        const getTaskId = (alias) => {
            const task = self.tasks?.length ? self.tasks.find((task) => task.alias === alias) : null;
            return task ? task._id : null;
        };

        const getTasks = flow(function* getTasks() {
            setPending(true);
            try {
                const { customerId, giftlyData } = self.root.accountStore;
                if (customerId && giftlyData) {
                    self.tasks = yield self.root.get(kcpAPI.tasks.list);
                }
            } catch (e) {
                setError(e);
                onErrorMessage();
            } finally {
                self.loaded = true;
                setPending(false);
            }
        });

        const onTaskClick = (task, fromReward = false) => {
            clearTaskError();
            const { isActive, alias } = task;
            const { routeNavigate } = self.root;

            if (alias === self.selectCategoriesTask?.alias) {
                return routeNavigate(ROUTE_PATHS.favoriteCategories, {
                    state: self.selectCategoriesTask?.alias
                });
            }

            if (
                alias === self.inviteFriendTask.alias &&
                (window.location.pathname.includes(ROUTE_PATHS.challenges) ||
                    window.location.pathname.includes(ROUTE_PATHS.pointsInfo))
            ) {
                return routeNavigate(ROUTE_PATHS.inviteAndEarn, {
                    state: self.inviteFriendTask.alias
                });
            }

            if (alias === self.addShippingTask.alias) {
                return routeNavigate(ROUTE_PATHS.shippingAdd, {
                    state: self.addShippingTask.alias
                });
            }

            if (alias === self.addToWishlistTask.alias) {
                return routeNavigate(self.root.navigationStore.getLinkToMarketPlace(), {
                    state: self.addToWishlistTask.alias
                });
            }

            if (alias === self.copyProductLinkTask.alias) {
                return routeNavigate(ROUTE_PATHS.shop, {
                    state: self.copyProductLinkTask.alias
                });
            }

            if (alias === self.shareProductTask.alias) {
                return routeNavigate(ROUTE_PATHS.shop);
            }

            if (alias === self.shopSuiteTask.alias) {
                return routeNavigate(ROUTE_PATHS.shop, {
                    state: self.shopSuiteTask.alias
                });
            }

            if (
                self.installExtensionTask &&
                alias === self.installExtensionTask.alias &&
                self.installExtensionTask.isActive
            ) {
                return routeNavigate(ROUTE_PATHS.extensionSettings, {
                    state: self.installExtensionTask.alias
                });
            }

            if (alias === self.writeProductReviewTask.alias) {
                return routeNavigate(ROUTE_PATHS.orders, {
                    state: { alias: self.writeProductReviewTask.alias }
                });
            }

            if (self.rateGiftlyTask && alias === self.rateGiftlyTask.alias && self.rateGiftlyTask.isActive) {
                return routeNavigate(ROUTE_PATHS.sendFeedback, {
                    state: self.rateGiftlyTask.alias
                });
            }

            return isActive
                ? self.root.modalsStore.openModal({
                      dialogId: alias,
                      fromReward
                  })
                : null;
        };

        const onInviteFriendTaskClick = ({ fromReward = false }) => {
            return self.inviteFriendTask ? onTaskClick(self.inviteFriendTask, fromReward) : null;
        };

        const onShareProductTaskClick = () => {
            return self.shareProductTask ? onTaskClick(self.shareProductTask) : null;
        };

        const onWriteProductReviewTaskClick = () => {
            return self.writeProductReviewTask ? onTaskClick(self.writeProductReviewTask) : null;
        };

        const onShopSuiteClick = () => {
            return self.shopSuiteTask && self.shopSuiteTask.isActive
                ? self.root.routeNavigate(self.root.navigationStore.getLinkToMarketPlace())
                : null;
        };

        const onInstallExtensionTaskClick = () => {
            return self.installExtensionTask && self.installExtensionTask.isActive
                ? self.root.routeNavigate(ROUTE_PATHS.extensionSettings)
                : null;
        };

        const getTaskData = flow(function* getTaskData(alias, field, disableLoading = false) {
            if (!disableLoading) self.dataPending = true;
            try {
                const taskId = getTaskId(alias);
                self[field] = yield self.root.get(kcpAPI.tasks.get(taskId));
            } catch (e) {
                setTaskError(e);
            } finally {
                self.dataPending = false;
            }
        });

        const getInviteFriendTaskData = flow(function* getInviteFriendTaskData(disableLoading = false) {
            yield getTaskData('invite-friend', 'inviteFriendTaskData', disableLoading);
        });

        const sendInvite = flow(function* sendInvite(taskPayload, callbackOnSuccess) {
            clearTaskError();
            self.processing = true;
            try {
                const taskId = self.inviteFriendTask?._id;
                yield self.root.patch(kcpAPI.tasks.patch(taskId), {
                    taskPayload
                });
                callbackOnSuccess();
                yield getTasks(true);
                yield self.root.claimsPointsStore.getPoints();
                return true;
            } catch (e) {
                setTaskError(e);
                return false;
            } finally {
                self.processing = false;
            }
        });

        const requestInvitations = flow(function* requestInvitations() {
            clearTaskError();
            self.processing = true;
            try {
                const taskId = self.inviteFriendTask?._id;
                yield self.root.post(kcpAPI.tasks.invitationsRequest(taskId));
                yield getInviteFriendTaskData();
            } catch (e) {
                setTaskError(e);
            } finally {
                self.processing = false;
            }
        });

        const getShareProductTaskData = flow(function* getShareProductTaskData() {
            yield getTaskData('share-product', 'shareProductTaskData');
        });

        const getWriteProductReviewTaskData = flow(function* getWriteProductReviewTaskData() {
            yield getTaskData('write-product-review', 'writeProductReviewTaskData');
        });

        const completeTask = flow(function* completeTask({
            taskPayload,
            taskId,
            onAfterSuccess = () => null,
            sources = 'points',
            taskCompleted = false,
            initialFetch = true
        }) {
            clearTaskError();
            self.processing = true;
            try {
                yield self.root.patch(kcpAPI.tasks.patch(taskId), {
                    taskPayload
                });
                yield getTasks();
                if (sources === 'points') yield self.root.claimsPointsStore.getPoints(initialFetch);
                else yield self.root.claimsPointsStore.getClaims();
                onAfterSuccess({ taskCompleted });
            } catch (e) {
                setTaskError(e);
            } finally {
                self.processing = false;
            }
        });

        const sendRateGiftlyResult = flow(function* sendRateGiftlyResult(taskPayload, onAfterSuccess) {
            try {
                const taskId = self.rateGiftlyTask?._id;
                yield completeTask({
                    taskPayload,
                    taskId,
                    onAfterSuccess,
                    sources: 'claims'
                });
            } catch (e) {
                setTaskError(e);
            }
        });

        const completeInstallExtensionTask = flow(function* completeInstallExtensionTask(
            taskPayload,
            onAfterSuccess = () => null
        ) {
            try {
                const taskId = self.installExtensionTask?._id;
                yield completeTask({
                    taskPayload,
                    taskId,
                    sources: 'claims'
                });
                yield self.root.claimsPointsStore.getClaims();
                yield getTasks();
                onAfterSuccess();
            } catch (e) {
                setTaskError(e);
            }
        });

        const cancelInvite = flow(function* cancelInvite(inviteId) {
            self.cancelInvitePending = true;
            try {
                yield self.root.delete(kcpAPI.tasks.invitationsCancel(self.inviteFriendTask._id, inviteId));
                yield getInviteFriendTaskData(true);
            } catch (e) {
                setTaskError(e);
            } finally {
                self.cancelInvitePending = false;
            }
        });

        const openChromeStore = () => {
            const routeLocation = self.root.routeLocation;
            window.open(`${app.giftlyChromeStoreLink}${routeLocation.search}`, '_blank');
        };

        return {
            getTasks,
            onTaskClick,
            onInviteFriendTaskClick,
            onShareProductTaskClick,
            onWriteProductReviewTaskClick,
            onShopSuiteClick,
            onInstallExtensionTaskClick,

            getInviteFriendTaskData,
            getShareProductTaskData,
            getWriteProductReviewTaskData,

            sendInvite,
            requestInvitations,

            sendRateGiftlyResult,

            completeTask,
            completeInstallExtensionTask,

            openChromeStore,

            clearTaskError,
            cancelInvite
        };
    });
