import React from 'react';
import { CartIcon, EmojiHappySmileIcon, ProfileIcon, PuzzleIcon, StarIcon, TagIcon } from '@klickly/front-packages';

export const getIconByAlias = (alias) => {
    switch (alias) {
        case 'invite-friend':
            return <ProfileIcon />;
        case 'share-product':
            return <TagIcon />;
        case 'rate-giftly':
            return <EmojiHappySmileIcon />;
        case 'onboarding-add-product-to-cart':
        case 'onboarding-add-shipping':
        case 'shop-suite':
            return <CartIcon />;
        case 'write-product-review':
        case 'onboarding-select-categories':
            return <StarIcon />;
        case 'install-extension':
        default:
            return <PuzzleIcon />;
    }
};
