import React, { forwardRef } from 'react';
import { KliField } from '@klickly/front-packages';
import { FieldStyles } from './styled';

// eslint-disable-next-line react/display-name
export const PriceInput = forwardRef(({ error, ...rest }, ref) => {
    return (
        <FieldStyles>
            <KliField.Currency invalid={Boolean(error)} withoutClearButton ref={ref} {...rest} />
        </FieldStyles>
    );
});
