import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../../../utils';

export const ShippingStyles = styled.div`
    width: 630px;

    @media (${GRID_DEVICES_MAX.sm}) {
        width: 100%;
    }
`;

export const ShippingContent = styled.div`
    padding: 40px 24px;
`;

export const DetailsWrapper = styled.div`
    margin: 4px 0 0;
`;

export const StyledDetailsText = styled(Typography.Text)`
    margin-bottom: 6px;
`;
