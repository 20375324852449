import React from 'react';
import { TOP_INDENTS, Typography } from '@klickly/front-packages';
import { $grayScale60Black, $primaryText, ROUTE_PATHS } from '../../../../shared';
import { StyledButton, StyledWrapper } from './styled';

const CancelOrder = ({ navigate, isLoggedIn, signUpStatusRejected }) => {
    return (
        <StyledWrapper>
            {isLoggedIn && !signUpStatusRejected && (
                <Typography.Text style={TOP_INDENTS.L} type='badgeSmall' align='center' color={$primaryText}>
                    Error with your order? You can{' '}
                    <StyledButton
                        color={$grayScale60Black}
                        size='small'
                        type='unstyled'
                        onClick={() => navigate(ROUTE_PATHS.orders)}
                        data-e2e='cancel-here-btn-giftly'
                    >
                        cancel here
                    </StyledButton>{' '}
                    in the next 5 minutes
                </Typography.Text>
            )}
            {(!isLoggedIn || signUpStatusRejected) && (
                <Typography.Text style={TOP_INDENTS.L} type='badgeSmall' align='center' color={$primaryText}>
                    <StyledButton
                        color={$grayScale60Black}
                        size='small'
                        type='unstyled'
                        onClick={() => navigate(ROUTE_PATHS.personalInfo)}
                    >
                        Apply
                    </StyledButton>{' '}
                    to Giftly to monitor your order status
                </Typography.Text>
            )}
        </StyledWrapper>
    );
};

export default CancelOrder;
