import React from 'react';
import { Typography } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { StyledTitle, Styles } from './styles';

export const SuiteProductItem = observer(({ img, title, description, ...rest }) => {
    return (
        <Styles {...rest}>
            <div>
                <img src={img} alt={title} />
                <StyledTitle level={3}>{title}</StyledTitle>
                <Typography.Text type='body2'>{description}</Typography.Text>
            </div>
        </Styles>
    );
});
