import React from 'react';
import { GiftIcon } from '@klickly/front-packages';
import { StyledIcon, StyledWrapper } from './styled';

const InfoNotification = ({ icon = <GiftIcon />, customContent = null }) => {
    return (
        <StyledWrapper>
            <StyledIcon size='24px' component={() => icon} />
            {customContent}
        </StyledWrapper>
    );
};

export default InfoNotification;
